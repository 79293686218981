import type { Dispatch, SetStateAction } from 'react'
import { useEffect } from 'react'
import styled from '@emotion/styled'
import { useMutation } from '@apollo/client'
import { LoadingDots } from '@qasa/qds-ui'
import SortableList, { SortableItem } from 'react-easy-sort'

import { ImageUploadHandler } from '../../ui-atoms/image-upload-handler'
import { RowWrapper } from '../../ui-atoms/wrapper'
import { useOptimisticUpdateOfHomeUpload } from '../listing/use-optimistic-image-upload'
import type { UploadData } from '../../types/uploads'
import type {
  DestroyUploadMutation,
  DestroyUploadMutationVariables,
} from '../../data/graphql/types/__generated__/DestroyUploadMutation'
import { DESTROY_UPLOAD } from '../../data/destroy-upload'
import { ImagesContainer } from '../../ui-atoms/object-image'

import { HomeImage } from './home-image'

type HomeImagesProps = {
  images: UploadData[]
  handleSort: (sortedImages: UploadData[]) => void
  setImagesToUpload: Dispatch<SetStateAction<File[]>>
}

const StyledSortableList = styled(SortableList)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing['2x'],
  rowGap: theme.spacing['4x'],
}))

export function HomeImages({ images, handleSort, setImagesToUpload }: HomeImagesProps) {
  const updateUpload = useOptimisticUpdateOfHomeUpload()
  const [destroyUpload, { loading: isDestroyingImages }] = useMutation<
    DestroyUploadMutation,
    DestroyUploadMutationVariables
  >(DESTROY_UPLOAD)

  const fixUploadsOrder = () => {
    images.forEach((upload, index) => {
      updateUpload({
        variables: {
          id: upload.id,
          input: {
            metadata: {
              order: index + 1,
            },
          },
        },
      })
    })
  }

  useEffect(() => {
    //NOTE - making sure uploads get a correct order as they are displayed in P2 - Ebba 18/1-23
    const isNotOrdered = images.some((d) => d.metadata?.order === null || d.metadata?.order === 0)
    if (isNotOrdered) {
      fixUploadsOrder()
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDestroyImage = (image: string) => {
    destroyUpload({
      variables: { uploadId: image },
      refetchQueries: ['ListingQuery'],
    })
  }

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    const imagesCopy = [...images]
    imagesCopy.splice(newIndex, 0, imagesCopy.splice(oldIndex, 1)[0])

    handleSort(imagesCopy)
  }

  return (
    <>
      <RowWrapper>
        <ImagesContainer>
          <StyledSortableList onSortEnd={onSortEnd}>
            {images &&
              images.map(({ id, url }) => (
                <SortableItem key={id}>
                  <HomeImage src={url} handleRemove={() => handleDestroyImage(id)} />
                </SortableItem>
              ))}
            <ImageUploadHandler
              onAdd={(images) => setImagesToUpload((prevImages) => [...prevImages, ...images])}
              onDelete={(imageToRemove) =>
                setImagesToUpload((prevImages) => prevImages.filter((image) => image !== imageToRemove))
              }
            />
          </StyledSortableList>
        </ImagesContainer>
      </RowWrapper>
      {isDestroyingImages && <LoadingDots />}
    </>
  )
}
