import styled from 'styled-components/macro'
import {
  useParams,
  useNavigate,
  Routes,
  Route,
  NavLink,
  Navigate,
  Link as RouterLink,
} from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Stack, Spacer, Button, IconButton, ChevronLeftIcon, Heading } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { palette } from '../styling'
import { TopSection } from '../ui-atoms/top-section'
import { HorizontalDivider } from '../ui-atoms/help-components'
import { queries } from '../data/apartment'
import { ListingStatus } from '../ui-feature-modules/apartment/listing-status'
import { getPixelAmount } from '../styling/pixel-amount'
import { ApartmentInfo } from '../ui-feature-modules/apartment/apartment-info'
import { BuildingInfo } from '../ui-feature-modules/apartment/building-info'
import type { ApartmentQuery } from '../data/graphql/types/__generated__/ApartmentQuery'
import { WhiteBoxWrapper } from '../ui-shared/wrapper'

const Wrapper = styled.div({
  height: '100%',
  display: 'flex',
})

const Content = styled(Stack)({
  maxWidth: '1280px',
  width: '100%',
})

const ApartmentInfoHeader = 'div'

const AddressAndStatus = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const ButtonsContainer = Stack

const ApartmentNavigation = styled.div({
  display: 'flex',
})

const StyledNavLink = styled(NavLink)({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  height: getPixelAmount(8),
  textDecoration: 'none',
  color: palette.darkGray,
  fontWeight: 500,
  ':before': {
    content: "''",
    display: 'none',
    position: 'absolute',
    height: '3px',
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: palette.primary,
  },
  '&.active': {
    color: palette.primary,
    '&:before': {
      display: 'flex',
    },
  },
})

type TParams = { apartmentId: string }

export function Apartment() {
  const params = useParams<TParams>()
  const { apartmentId } = params
  const navigate = useNavigate()
  const { t } = useTranslation(['apartment', 'commons'])

  const {
    loading: isLoading,
    error,
    data,
  } = useQuery<ApartmentQuery>(queries.GET_APARTMENT, {
    variables: { homeTemplateId: apartmentId },
  })

  if (isLoading) {
    return null
  }

  if (error) {
    if (error.message === 'not_found') {
      return <Navigate to="/homes/apartments" replace />
    } else {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  const apartmentIsListed = (): boolean => {
    if (data?.homeTemplate.publishedHomes) {
      const { publishedHomes } = data.homeTemplate
      return publishedHomes.length > 0
    }
    return false
  }

  return (
    <Wrapper>
      <Content gap="6x">
        <TopSection>
          <Stack direction="row" gap="6x" alignItems="center">
            <IconButton
              icon={ChevronLeftIcon}
              label={t('commons:ctas.back')}
              variant="tertiary"
              size="xs"
              onClick={() => navigate('/homes/apartments', { replace: true })}
            />
            <ApartmentInfoHeader>
              <Heading size="md">
                {data?.homeTemplate.location?.route} {data?.homeTemplate.location?.streetNumber}
                {', '}
                {data?.homeTemplate.apartmentNumber}
              </Heading>
              <AddressAndStatus>
                <ListingStatus isListed={apartmentIsListed()} />
              </AddressAndStatus>
            </ApartmentInfoHeader>
          </Stack>

          <ButtonsContainer direction={'row'}>
            {apartmentIsListed() ? (
              <Button
                as={RouterLink}
                variant="secondary"
                to={`/listings/${data?.homeTemplate.publishedHomes[0].id}`}
              >
                {t('actions.view_listing')}
              </Button>
            ) : (
              <Button as={RouterLink} variant="secondary" to={`/listings/add/${apartmentId}`}>
                {t('commons:actions.create_listing')}
              </Button>
            )}
          </ButtonsContainer>
        </TopSection>
        <WhiteBoxWrapper>
          <ApartmentNavigation>
            <StyledNavLink end to="">
              {t('tabs.apartment_info')}
            </StyledNavLink>
            <Spacer axis="x" size="6x" />
            <StyledNavLink to={`building`}>{t('tabs.building_info')}</StyledNavLink>
          </ApartmentNavigation>
          <HorizontalDivider />
          <Routes>
            <Route path="" element={<ApartmentInfo {...data!.homeTemplate} />} />
            <Route path="building" element={<BuildingInfo {...data!.homeTemplate.building!} />} />
          </Routes>
        </WhiteBoxWrapper>
      </Content>
    </Wrapper>
  )
}
