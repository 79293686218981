import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { Stack, Textarea } from '@qasa/qds-ui'

export function BuildingDescriptionSection() {
  const { t } = useTranslation(['building', 'edit_listing', 'commons'])
  const { control } = useFormContext()

  return (
    <Stack gap={'8x'}>
      <Controller
        name="descriptionBuilding"
        control={control}
        render={({ field: { onChange, value, name } }) => {
          return (
            <Textarea
              label={t('building_form.building_description')}
              value={value ?? undefined}
              name={name}
              onChange={onChange}
              placeholder={t('building_form.building_description_placeholder')}
              rows={5}
              resize="none"
            />
          )
        }}
      />
      <Controller
        name="descriptionTransportation"
        control={control}
        render={({ field: { onChange, value, name } }) => {
          return (
            <Textarea
              label={t('building_form.transportation')}
              value={value ?? undefined}
              name={name}
              onChange={onChange}
              placeholder={t('building_form.transportation_placeholder')}
              rows={5}
              resize="none"
            />
          )
        }}
      />
    </Stack>
  )
}
