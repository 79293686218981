import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { HOME_MATCHING_SCORE } from '../data/home-matching-score'
import type {
  HomeMatchingScoreQuery,
  HomeMatchingScoreQueryVariables,
} from '../data/graphql/types/__generated__/HomeMatchingScoreQuery'
import { ListingApplicationStats } from '../ui-feature-modules/applications/listing-application-stats'

type Props = {
  tenantUid?: string
  matchingHomeId: string
  isLarge?: boolean
}
export function MatchingScore({ tenantUid, matchingHomeId, isLarge = false }: Props) {
  const { t } = useTranslation(['miscellaneous', 'commons'])
  const { data: matchingScoreData, loading: isLoading } = useQuery<
    HomeMatchingScoreQuery,
    HomeMatchingScoreQueryVariables
  >(HOME_MATCHING_SCORE, {
    variables: {
      tenantUid: tenantUid!,
      homeId: matchingHomeId,
    },
    skip: !tenantUid,
  })

  if (isLoading)
    return (
      <ListingApplicationStats
        header={t('matching_score.label')}
        value={t('commons:loading')}
        isValueBold={true}
        isLarge={isLarge}
      />
    )
  if (!matchingScoreData || !matchingScoreData.matchingScoreAws.score)
    return (
      <ListingApplicationStats
        header={t('matching_score.label')}
        value={''}
        isValueBold={true}
        isLarge={isLarge}
      />
    )

  return (
    <ListingApplicationStats
      header={t('matching_score.label')}
      value={matchingScoreData.matchingScoreAws.score + ' %'}
      isValueBold={true}
      isLarge={isLarge}
    />
  )
}
