import { useState } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Dialog } from '@qasa/app/src/components/dialog'
import { createStyleVariants } from '@qasa/qds-ui'

type ImageVariant = 'sent' | 'received'

const ModalImage = styled.img(({ theme }) => ({
  borderRadius: theme.radii.sm,
  width: '100%',
  height: 'auto',
}))

const imageVariantStyles = createStyleVariants((theme) => ({
  sent: {
    borderTopRightRadius: theme.radii.xs,
  },
  received: {
    borderTopLeftRadius: theme.radii.xs,
  },
}))
const Image = styled.img<{ $hasLoaded: boolean; variant: ImageVariant }>(
  ({ theme, $hasLoaded, variant }) => ({
    borderRadius: theme.radii.md,
    cursor: 'pointer',
    display: $hasLoaded ? 'block' : 'none',
    maxHeight: theme.sizes['320'],
    maxWidth: theme.sizes['320'],
    overflow: 'hidden',
    ...imageVariantStyles(theme)[variant],
  }),
)

type MessageImageProps = {
  message: { title: string; url: string }
  variant: ImageVariant
}

export function MessageImage({ message, variant }: MessageImageProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const { t } = useTranslation('applications', { keyPrefix: 'chat' })

  return (
    <Dialog
      size="md"
      isOpen={isOpen}
      onOpenChange={(isOpen) => setIsOpen(isOpen)}
      trigger={
        <Image
          alt={message.title}
          src={message.url}
          $hasLoaded={isImageLoaded}
          variant={variant}
          onLoad={() => setIsImageLoaded(true)}
        />
      }
    >
      <Dialog.Body>
        <Dialog.Header>
          <Dialog.Title>
            {variant === 'sent' ? t('image_sent_by_you') : t('image_sent_by_tenant')}
          </Dialog.Title>
        </Dialog.Header>
        {message && <ModalImage alt={message.title} src={message.url} />}
      </Dialog.Body>
    </Dialog>
  )
}
