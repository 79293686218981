export const fontSize = {
  text2xs: '10px',
  textXs: '12px',
  textSm: '14px',
  textBase: '16px',
  textLg: '18px',
  textXl: '20px',
  text2xl: '24px',
  text3xl: '30px',
  text4xl: '36px',
  text5xl: '48px',
}
