import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

import type { UpdateHomeInput } from '../../data/graphql/types/__generated__/globalTypes'
import { Input } from '../../ui-atoms/input'
import { validateYearBeforeCurrent } from '../../util-functions/validate-year'

import { InputHeader, InputRow } from './input-grid-styles'

export function RenovationSection() {
  const { t } = useTranslation(['apartment', 'edit_listing'])
  const { control, watch } = useFormContext<UpdateHomeInput>()

  const buildYear = watch('buildYear') ?? ''

  const isAfterBuildYear = (value?: number | null) => {
    if (!value || !buildYear) {
      return true
    }

    const inputYear = new Date(value.toString()).getFullYear()
    const formattedBuildYear = new Date(buildYear.toString()).getFullYear()

    return Boolean(inputYear >= formattedBuildYear)
  }

  return (
    <InputRow>
      <InputHeader size="2xs">{t('apartment_form.latest_kitchen_renovation_label')}</InputHeader>
      <Controller
        name="kitchenRenovationYear"
        control={control}
        rules={{
          min: {
            value: 1800,
            message: t('edit_listing:errors.renovation_invalid'),
          },
          validate: {
            notFutureDate: (value) =>
              validateYearBeforeCurrent(value) || t('edit_listing:errors.renovation_future'),
            notBeforeBuildYear: (value) =>
              isAfterBuildYear(value) ||
              t('edit_listing:errors.renovation_before_build_year', {
                buildYear: buildYear.toString() ?? '',
              }),
          },
        }}
        render={({ field: { onChange, value, name }, fieldState }) => {
          return (
            <Input
              name={name}
              type="number"
              placeholder={t('apartment_form.renovation_placeholder')}
              onChange={(event) => {
                onChange(event.target.value !== '' ? Number(event.target.value) : null)
              }}
              value={value}
              optional
              errorMessage={fieldState?.error?.message}
            />
          )
        }}
      />

      <InputHeader size="2xs">{t('apartment_form.latest_bath_renovation_label')}</InputHeader>
      <Controller
        name="bathroomRenovationYear"
        control={control}
        rules={{
          min: {
            value: 1800,
            message: t('edit_listing:errors.renovation_invalid'),
          },
          validate: {
            notFutureDate: (value) =>
              validateYearBeforeCurrent(value) || t('edit_listing:errors.renovation_future'),
            notBeforeBuildYear: (value) =>
              isAfterBuildYear(value) ||
              t('edit_listing:errors.renovation_before_build_year', {
                buildYear: buildYear.toString() ?? '',
              }),
          },
        }}
        render={({ field: { onChange, value, name }, fieldState }) => {
          return (
            <Input
              name={name}
              type="number"
              placeholder={t('apartment_form.renovation_placeholder')}
              onChange={(event) => {
                onChange(event.target.value !== '' ? Number(event.target.value) : null)
              }}
              value={value}
              optional
              errorMessage={fieldState?.error?.message}
            />
          )
        }}
      />
    </InputRow>
  )
}
