import gql from 'graphql-tag'

const GET_UNREAD_CONVERSATIONS_COUNT = gql`
  query UnreadConversationsCountQuery(
    $onlyActiveApplications: Boolean
    $lastMessageFrom: ConversationRoleTypeEnum
  ) {
    conversations(
      read: false
      onlyActiveApplications: $onlyActiveApplications
      lastMessageFrom: $lastMessageFrom
    ) {
      totalCount
    }
  }
`

const GET_UNREAD_CONVERSATIONS = gql`
  query UnreadConversationsQuery(
    $after: String
    $read: Boolean
    $first: Int
    $status: [ConversationStatusEnum!]
    $onlyActiveApplications: Boolean
    $lastMessageFrom: ConversationRoleTypeEnum
  ) {
    conversations(
      read: $read
      first: $first
      status: $status
      after: $after
      onlyActiveApplications: $onlyActiveApplications
      lastMessageFrom: $lastMessageFrom
    ) {
      edges {
        cursor
        node {
          id
          read
          homeApplication {
            id
            status
            tenant {
              uid
              firstName
              profilePicture {
                url
              }
            }
            home {
              id
              apartmentNumber
              location {
                locality
                postalCode
                route
                streetNumber
              }
            }
          }
          messages(last: 1) {
            nodes {
              message
              createdAt
              messageType
              user {
                uid
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

const queries = {
  GET_UNREAD_CONVERSATIONS_COUNT,
  GET_UNREAD_CONVERSATIONS,
}

export { queries }
