import gql from 'graphql-tag'

const GET_BUILDING = gql`
  query BuildingQuery($buildingId: ID!) {
    building(id: $buildingId) {
      id
      buildingName
      buildYear
      energyClass
      descriptionBuilding
      descriptionTransportation
      homeTemplateCount
      updatedAt
      location {
        route
        longitude
        latitude
        postalCode
        locality
        streetNumber
        googleMapsPlaceId
      }
      uploads {
        url
        metadata {
          primary
        }
      }
    }
  }
`

export const queries = {
  GET_BUILDING,
}
