import { pixelAmount } from '.'
import { getPixelAmount } from './pixel-amount'

export const defaultStyles = {
  borderRadius: pixelAmount.sm,
  borderRadiusRound: pixelAmount.xl15,
  navbarHeight: '66px',
  footerHeight: getPixelAmount(24),
}

export const spaces = {
  extraSmall: 4,
  small: 12,
  medium: 24,
  large: 48,
  extraLarge: 64,
  extraExtraLarge: 96,
}
