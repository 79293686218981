import { useMutation, gql } from '@apollo/client'
import { useLocation, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { IconButton, TrashIcon, StarIcon, StarFilledIcon, Stack, Paragraph } from '@qasa/qds-ui'
import styled from '@emotion/styled'

import { ToolTip } from '../../../ui-atoms/tooltip'
import { useUserContext } from '../../../context/user-context'
import {
  ConversationMessageTypeEnum,
  HomeApplicationStatusEnum,
} from '../../../data/graphql/types/__generated__/globalTypes'
import { formatNumber } from '../../../util-functions/general.util'
import { mutations } from '../../../data/applications-by-board-list'
import { ListingApplicationStats } from '../listing-application-stats'
import { mutations as applicantMutations } from '../../../data/applicant'
import { placeholder } from '../../../styling'
import { formatDateYYYYMMDD, getDaysFromNow } from '../../../util-functions/date.util'
import type {
  ApplicationBoardLists,
  ApplicationBoardLists_home_applicationBoardCardInfo,
} from '../../../data/graphql/types/__generated__/ApplicationBoardLists'
import type { HomeApplicationsByBoardList_homeApplicationsByBoardList_edges_node } from '../../../data/graphql/types/__generated__/HomeApplicationsByBoardList'
import { MatchingScore } from '../../../ui-atoms/matching-score'

import { MoveApplicantMenu } from './move-applicant-menu'
import { LastMessage } from './last-message'
import { ApplicantCardVerifications } from './applicant-card-verifications'
import { ApplicantCardAvatar } from './applicant-card-avatar'
import { ApplicantCardNote } from './applicant-card-note'

const Card = styled(Stack)(({ theme }) => ({
  paddingBlock: theme.spacing['2x'],
  paddingInline: theme.spacing['2x'],
}))
const TopRow = Stack
const MiddleRow = Stack
const BottomRow = Stack
const Name = Paragraph
const DetailsWrapper = Stack
const DateStamp = Paragraph

type ApplicantCardProps = {
  data: HomeApplicationsByBoardList_homeApplicationsByBoardList_edges_node
  applicationBoardCardInfo: ApplicationBoardLists_home_applicationBoardCardInfo | null | undefined
  columns: ApplicationBoardLists
  currentColumnId: string
}

const inContact = HomeApplicationStatusEnum.in_contact

export function ApplicantCard({
  data,
  columns,
  currentColumnId,
  applicationBoardCardInfo,
}: ApplicantCardProps) {
  const { authBody } = useUserContext()
  const userId = authBody?.uid
  const isInContact = data?.status === inContact
  const { t } = useTranslation(['applications', 'applicant', 'commons'])

  //***** APPLICANT  *****/
  const {
    id,
    tenant,
    tenantFamilyName,
    conversation,
    starMarkedByLandlord: isStarmarkedByLandlord,
    home,
    createdAt: applicationCreatedAt,
  } = data || {}
  const { firstName, uid, userNotes, profilePicture, createdAt: tenantCreatedAt } = tenant
  const { messages } = conversation || {}
  const { id: homeId } = home

  const avatarSrc = profilePicture?.url || placeholder.avatar
  const avatarAlt = profilePicture?.title || placeholder.avatarAlt
  const queueDays = formatNumber(getDaysFromNow(new Date(tenantCreatedAt))) || 0
  const date = applicationCreatedAt
    ? formatDateYYYYMMDD(new Date(applicationCreatedAt))
    : placeholder.emptyString
  const lastConversationMessage = messages?.nodes?.[0]
  const conversationId = conversation?.id || ''
  const isLastMessageUnread =
    conversation &&
    lastConversationMessage?.user?.uid !== userId &&
    (lastConversationMessage?.messageType === ConversationMessageTypeEnum.text ||
      lastConversationMessage?.messageType === ConversationMessageTypeEnum.image)
      ? !conversation.read
      : false

  const shouldShowMatch = applicationBoardCardInfo ? applicationBoardCardInfo.showMatchingScore : false
  const shouldShowQueue = applicationBoardCardInfo ? applicationBoardCardInfo.showQueueDays : false
  const shouldShowLastMessage = applicationBoardCardInfo ? applicationBoardCardInfo.showLastMessage : false

  //**** PATH   ****/
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const hasListing = params.get('listing') ? true : false
  const listing = hasListing ? 'listing=' + params.get('listing')?.toString() + '&' : ''

  //**** MUTATIONS ****/
  const [addHomeApplicationToBoardList] = useMutation(mutations.ADD_HOME_APPLICATIONS_TO_BOARD_LIST, {
    refetchQueries: ['HomeApplicationsByBoardList'],
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error)
    },
  })
  const [removeHomeApplicationFromBoardList, { error }] = useMutation(
    mutations.REMOVE_HOME_APPLICATIONS_FROM_BOARD_LIST,
    {
      refetchQueries: ['HomeApplicationsByBoardList'],
    },
  )

  if (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }

  const [setStarMarked] = useMutation(applicantMutations.SET_STARMARKED, {
    update: (
      cache,
      {
        data: {
          starMarkApplication: { homeApplication },
        },
      },
    ) => {
      cache.modify({
        fields: {
          homeApplication(existingHomeApplication = {}) {
            const updatedHomeApplicationRef = cache.writeFragment({
              data: homeApplication,
              fragment: gql`
                fragment UpdatedApplication on homeApplication {
                  id
                  starMarkedByLandlord
                }
              `,
            })
            return { ...existingHomeApplication, updatedHomeApplicationRef }
          },
        },
      })
    },
  })

  //**** ACTIONS ****/
  const handleMove = (columnId: string) => {
    addHomeApplicationToBoardList({
      variables: { applicationBoardListId: columnId, homeApplicationIds: [id] },
    })
  }

  const handleStarmark = () => {
    setStarMarked({
      variables: { homeApplicationId: id, starMarkedByLandlord: !isStarmarkedByLandlord },
    })
  }

  const handleDecline = () => {
    removeHomeApplicationFromBoardList({ variables: { homeApplicationIds: [id] } })
  }

  const to = `${location.pathname}?${listing}list=${currentColumnId}&applicant=${id}`
  const fullName = `${firstName} ${tenantFamilyName}`

  return (
    <Card gap="2x">
      <Stack gap="3x" as={Link} to={to}>
        <TopRow direction="row" justifyContent="space-between">
          <Stack direction={'row'} gap="4x">
            <ApplicantCardAvatar
              id={id}
              currentColumnId={currentColumnId}
              conversationId={conversationId}
              avatarSrc={avatarSrc}
              avatarAlt={avatarAlt}
            />
            <DetailsWrapper gap="2x">
              <Name>{fullName}</Name>
              <Stack direction={'row'} gap="2x">
                {shouldShowMatch && <MatchingScore tenantUid={uid} matchingHomeId={homeId!} />}
                {shouldShowQueue && (
                  <ListingApplicationStats
                    header={t('applicant_card.queue_days')}
                    value={queueDays}
                    isValueBold={true}
                  />
                )}
              </Stack>
            </DetailsWrapper>
          </Stack>
          <DateStamp size="xs" color="subtle">
            {date}
          </DateStamp>
        </TopRow>
        <MiddleRow gap="2x">
          <ApplicantCardVerifications data={data} applicationBoardCardInfo={applicationBoardCardInfo} />
          {isInContact && shouldShowLastMessage && (
            <LastMessage
              tenantName={firstName}
              lastMessage={lastConversationMessage}
              isUnread={isLastMessageUnread}
            />
          )}
          <ApplicantCardNote userNotes={userNotes} />
        </MiddleRow>
      </Stack>
      <BottomRow direction="row" justifyContent="flex-end">
        <ToolTip content={t('applicant:applicant_actions.remove')}>
          <IconButton
            icon={TrashIcon}
            label={t('applicant:applicant_actions.remove')}
            onClick={handleDecline}
            size="xs"
          />
        </ToolTip>
        <ToolTip content={t('applicant:applicant_actions.star_mark')}>
          <IconButton
            icon={isStarmarkedByLandlord ? StarFilledIcon : StarIcon}
            label={t('applicant:applicant_actions.star_mark')}
            size="xs"
            onClick={handleStarmark}
          />
        </ToolTip>
        <MoveApplicantMenu
          currentColumnId={currentColumnId}
          columns={columns}
          handleMove={handleMove}
          isActive={true}
        />
      </BottomRow>
    </Card>
  )
}
