import { gql } from '@apollo/client'

export const CONTRACT_PREVIEW = gql`
  query ContractPreviewQuery($id: ID!, $language: ContractLanguageEnum) {
    generateContractPreviewPdf(id: $id, language: $language) {
      previewSource
      errors {
        field
        codes
      }
    }
  }
`
