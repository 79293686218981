import styled from 'styled-components/macro'

import { palette } from '../../styling'

/**
 * @deprecated Use `Divider` from `@qasa/qds-ui` instead
 */
export const HorizontalDivider = styled.div({
  borderBottom: `1px solid ${palette.grey60}`,
  // Hide adjacent dividers
  '& + &': {
    display: 'none',
  },
})
