import { Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import { EditDialog } from '@qasa/app/src/modules/edit-dialog'

import { BaseInfoItem } from '../listing/base-info-item'
import { ListingSectionEnum, useListingContext } from '../../context/listing-context'
import { LeftContainer, RightContainer } from '../listing/building-content'

export function BuildingDescription() {
  const { t } = useTranslation(['building', 'commons'])
  const { home, setActiveEditSection, setIsEditDialogOpen } = useListingContext()
  const { descriptionBuilding, descriptionTransportation } = home || {}

  return (
    <Stack gap="12x" direction="row" justifyContent="space-between">
      <LeftContainer gap="8x">
        <BaseInfoItem
          heading={t('fields.building_description')}
          value={descriptionBuilding || t('commons:placeholders.not_filled_in')}
          longText
        />
        <BaseInfoItem
          heading={t('fields.transportation_description')}
          value={descriptionTransportation || t('commons:placeholders.not_filled_in')}
          longText
        />
      </LeftContainer>
      <RightContainer>
        <EditDialog.Trigger
          onClick={() => {
            setIsEditDialogOpen(true)
            setActiveEditSection(ListingSectionEnum.BUILDING_DESCRIPTION)
          }}
        />
      </RightContainer>
    </Stack>
  )
}
