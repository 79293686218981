export enum ProfilePreferredAmenitiesEnum {
  balcony = 'balcony',
  storage = 'storage',
  dishWasher = 'dishWasher',
  washingMachine = 'washingMachine',
  tumbleDryer = 'tumbleDryer',
  bathtub = 'bathtub',
  bikeRoom = 'bikeRoom',
  parking = 'parking',
  recycling = 'recycling',
  inhomeSauna = 'inhomeSauna',
}
export enum ProfileRequiredAmenitiesEnum {
  wheelchairAccessible = 'wheelchairAccessible',
  pets = 'pets',
  smoker = 'smoker',
}
