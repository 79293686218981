import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Paragraph, Spacer } from '@qasa/qds-ui'
import styled from '@emotion/styled'

const Display = styled.div({
  display: 'flex',
  flexDirection: 'row',
})

const Icon = styled(FontAwesomeIcon)({
  alignSelf: 'center',
})

type ListingApplicationStatsProps = {
  header: string
  value?: string | number
  isValueBold?: boolean
  isLarge?: boolean
}
export function ListingApplicationStats(props: ListingApplicationStatsProps) {
  const { header, value, isLarge = false } = props

  return (
    <Display>
      <Paragraph size={isLarge ? 'sm' : 'xs'}>{`${header}: `}</Paragraph>
      <Spacer axis="x" size="1x" />
      {value ? (
        <Paragraph size={isLarge ? 'sm' : 'xs'}>{value}</Paragraph>
      ) : (
        <Icon icon={['fal', 'minus-circle']} size={isLarge ? 'sm' : 'xs'} />
      )}
    </Display>
  )
}
