import { Navigate, useLocation, useParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AddListingForm } from '../ui-feature-modules/add-listing/add-listing-form'
import { queries } from '../data/add-listing'
import type {
  ApartmentForAddListingQuery,
  ApartmentForAddListingQuery_homeTemplate_uploads,
  ApartmentForAddListingQuery_homeTemplate_building_uploads,
} from '../data/graphql/types/__generated__/ApartmentForAddListingQuery'
import type { CreateHomeInput } from '../data/graphql/types/__generated__/globalTypes'
import { HomeTypeEnum, UploadTypeEnum } from '../data/graphql/types/__generated__/globalTypes'
import type { BlockListingPayloadQuery } from '../data/graphql/types/__generated__/BlockListingPayloadQuery'
import { LoadingDots } from '../ui-shared/loading-dots'

type TParams = { apartmentId: string }
type THistory = { apartmentIds?: Array<string> }

type ApartmentUpload = ApartmentForAddListingQuery_homeTemplate_uploads
type BuildingUpload = ApartmentForAddListingQuery_homeTemplate_building_uploads

export function AddListing() {
  const location = useLocation()
  const params = useParams<TParams>()
  const { apartmentId } = params
  const { state } = location as { state: THistory | null }
  const { t } = useTranslation('commons')

  const shouldApartmentIdsNotBeProvided =
    (!apartmentId && !state) ||
    (state && !state.apartmentIds) ||
    (state && state.apartmentIds && state.apartmentIds?.length < 1)

  const isBlockListing = state !== undefined && state?.apartmentIds !== undefined
  const variables = isBlockListing ? { apartmentIds: state.apartmentIds } : { homeTemplateId: apartmentId }

  const [imageUploads, setImageUploads] = useState<(ApartmentUpload | BuildingUpload)[]>([])
  const [listingInput, setListingInput] = useState<CreateHomeInput>()

  const [getApartmentData, { data: singleData, loading: isLoadingApartment, error: apartmentError }] =
    useLazyQuery<ApartmentForAddListingQuery>(queries.GET_APARTMENT_FOR_ADD_LISTING, {
      variables,
      onCompleted: (data) => onSingleListingCompleted(data),
    })

  const [getBlockData, { data: blockData, loading: isLoadingBlock, error: blockError }] =
    useLazyQuery<BlockListingPayloadQuery>(queries.GET_BLOCK_LISTING_PAYLOAD, {
      variables,
      onCompleted: (data) => onBlockComplete(data),
    })

  useEffect(() => {
    if (isBlockListing) {
      getBlockData()
    } else {
      getApartmentData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBlockListing, getBlockData, getApartmentData])

  useEffect(() => {
    if (isBlockListing && blockData) {
      onBlockComplete(blockData)
    } else if (singleData) {
      onSingleListingCompleted(singleData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleData, blockData])

  const onSingleListingCompleted = (data: ApartmentForAddListingQuery) => {
    if (data.homeTemplate && apartmentId) {
      const { location } = data.homeTemplate

      let buildingUploads: string[] = []
      if (data.homeTemplate.building) {
        buildingUploads = data.homeTemplate.building.uploads.map((u) => u.id)
      }

      setListingInput({
        homeTemplateIds: [apartmentId],
        type: HomeTypeEnum.apartment,
        shared: false,
        squareMeters: data.homeTemplate.squareMeters,
        roomCount: data.homeTemplate.roomCount,
        firsthand: data.homeTemplate.firsthand,
        studentHome: data.homeTemplate.studentHome,
        seniorHome: data.homeTemplate.seniorHome,
        corporateHome: data.homeTemplate.corporateHome,
        tenantCount: data.homeTemplate.tenantCount
          ? data.homeTemplate.tenantCount
          : data.homeTemplate.roomCount!,
        rent: data.homeTemplate.rent ? data.homeTemplate.rent : 0,
        location: {
          country: location?.country,
          countryCode: location?.countryCode,
          latitude: location?.latitude,
          locality: location?.locality,
          longitude: location?.longitude,
          postalCode: location?.postalCode,
          route: location?.route,
          streetNumber: location?.streetNumber,
        },
        duration: {
          startAsap: true,
          startOptimal: null,
          endUfn: true,
          endOptimal: null,
        },
        description: data.homeTemplate.description,
        descriptionBuilding: data.homeTemplate.building?.descriptionBuilding,
        descriptionTransportation: data.homeTemplate.building?.descriptionTransportation,
        apartmentNumber: data.homeTemplate.apartmentNumber,
        traits: data.homeTemplate.traits.map((trait) => trait.type),
        energyClass: data.homeTemplate.building?.energyClass,
        buildYear: data.homeTemplate.building?.buildYear,
        kitchenRenovationYear:
          data.homeTemplate.kitchenRenovationYear === 0 ? null : data.homeTemplate.kitchenRenovationYear,
        bathroomRenovationYear:
          data.homeTemplate.bathroomRenovationYear === 0 ? null : data.homeTemplate.bathroomRenovationYear,
        floor: data.homeTemplate.floor,
        uploadIds: [...data.homeTemplate.uploads.map((u) => u.id), ...buildingUploads],
      })
      setImageUploads([
        ...data.homeTemplate.uploads,
        ...(data.homeTemplate.building ? data.homeTemplate.building.uploads : []),
      ])
    }
  }

  const onBlockComplete = (data: BlockListingPayloadQuery) => {
    if (data.generateBlockListingPayload) {
      const { generateBlockListingPayload } = data
      const { location, uploads } = generateBlockListingPayload

      const buildingUploads = uploads.filter((upload) => upload.type === UploadTypeEnum.home_building_picture)
      const apartmentUploads = uploads.filter((upload) => upload.type === UploadTypeEnum.home_layout_picture) //BlockQuery does not send apartment images, only layout

      setImageUploads([...apartmentUploads, ...buildingUploads])

      setListingInput({
        homeTemplateIds: state?.apartmentIds,
        type: HomeTypeEnum.apartment,
        shared: false,
        firsthand: true,
        tenantCount: 1,
        rent: Math.round((generateBlockListingPayload.minRent + generateBlockListingPayload.maxRent) / 2),
        roomCount: Math.round(
          (generateBlockListingPayload.minRoomCount + generateBlockListingPayload.maxRoomCount) / 2,
        ),
        squareMeters: Math.round(
          (generateBlockListingPayload.minSquareMeters + generateBlockListingPayload.maxSquareMeters) / 2,
        ),
        minRent: generateBlockListingPayload.minRent,
        maxRent: generateBlockListingPayload.maxRent,
        minRoomCount: generateBlockListingPayload.minRoomCount,
        maxRoomCount: generateBlockListingPayload.maxRoomCount,
        minSquareMeters: generateBlockListingPayload.minSquareMeters,
        maxSquareMeters: generateBlockListingPayload.maxSquareMeters,
        minTenantCount: generateBlockListingPayload.minTenantCount,
        maxTenantCount: generateBlockListingPayload.maxTenantCount,
        numberOfHomes: generateBlockListingPayload.numberOfHomes,
        traits: [],
        location: {
          country: location.country,
          countryCode: location.countryCode,
          latitude: location.latitude,
          locality: location.locality,
          longitude: location.longitude,
          postalCode: location.postalCode,
          route: location.route,
          streetNumber: location.streetNumber,
        },
        duration: {
          startAsap: true,
          startOptimal: null,
          endUfn: true,
          endOptimal: null,
        },
        uploadIds: uploads.map((upload) => upload.id),
      })
    }
  }

  if (shouldApartmentIdsNotBeProvided) {
    return <Navigate to="/homes/apartments" replace />
  }

  if (apartmentError) {
    // eslint-disable-next-line no-console
    console.error(apartmentError)
  }
  if (blockError) {
    // eslint-disable-next-line no-console
    console.error(blockError)
  }

  if (!listingInput || isLoadingApartment || isLoadingBlock) {
    return <LoadingDots />
  }

  const title = isBlockListing ? t('actions.create_group_listing') : t('actions.create_listing')

  return (
    <AddListingForm
      title={title}
      isBlockListing={isBlockListing}
      listingInput={listingInput}
      imageUploads={imageUploads}
    />
  )
}
