import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Input } from '../../../ui-atoms/input'

import { ContractFormInputHeader } from './contract-form-input-header'

export function ContractFormRentField() {
  const { control } = useFormContext()
  const { t } = useTranslation('applicant', {
    keyPrefix: 'contract_form',
  })

  return (
    <div>
      <ContractFormInputHeader label={t('rent')} />
      <Controller
        name="rent"
        control={control}
        rules={{ required: true, min: 1 }}
        render={({ field: { onChange, value, name } }) => {
          return (
            <Input
              name={name}
              onChange={(val) => {
                onChange(Number(val.target.value))
              }}
              value={value}
              type={'number'}
            />
          )
        }}
      />
    </div>
  )
}
