import styled from 'styled-components/macro'
import { useReducer } from 'react'
import { useTranslation } from 'react-i18next'

import { HousingSituationAgreementTypeEnum } from '../../../data/graphql/types/__generated__/globalTypes'
import type { HomeApplicationOverview_homeApplication } from '../../../data/graphql/types/__generated__/HomeApplicationOverview'
import { fontSize, palette, placeholder } from '../../../styling'
import { Spacer } from '../../../ui-atoms/help-components'
import { ApplicantBadge } from '../applicant-badge'
import { ExpandButton } from '../../../ui-atoms/expand-button'
import { MediumHeader } from '../../../ui-atoms/header-text'
import { InformationBox } from '../../../ui-atoms/information-box'

import { OverviewSavedSearch } from './overview-saved-search'
import { OverviewPreferredAmenities } from './overview-preferred-amenities'
import { parsePreferredAmenities } from './overview-applicant-profile.util'

const MAX_PROFILE_HEIGHT = 100

const TenantTitle = styled.div({
  fontWeight: 600,
  fontSize: fontSize.textBase,
})

const TenantBio = styled.div({
  fontSize: fontSize.textSm,
})

const ProfileInfoWrapper = styled.div<{ isOpen: boolean }>(({ isOpen }) => ({
  position: 'relative',
  maxHeight: isOpen ? 'none' : MAX_PROFILE_HEIGHT,
  overflow: 'hidden',
  display: ' flex',
  flexDirection: 'column',
}))

const BottomFade = styled.div({
  position: 'absolute',
  width: '100%',
  height: 64,
  bottom: 0,
  left: 0,
  right: 0,
  background: 'linear-gradient(rgba(255, 255, 255, 0), white)',
})

type OverviewApplicantProfileProps = {
  data: HomeApplicationOverview_homeApplication
}

export function OverviewApplicantProfile({ data }: OverviewApplicantProfileProps) {
  const { t } = useTranslation('applications', { keyPrefix: 'overview.applicant_profile' })
  const [isOpen, onToggleOpen] = useReducer((old) => !old, false)
  const tenantWork = data.tenant.bio.work
  const tenantStudy = data.tenant.bio.study
  const tenantBio = data.tenant.bio.intro
  const tenantTitle = data.tenant.bio.title || 'Profil'
  const tenantPreviousPlaces = data.tenant.bio.lived
  const tenantPet = data.tenant.bio.pets
  const tenantAgreementType = data.tenant.housingSituation?.agreementType
  const tenantMovingReason = data.tenant.movingReason

  const hasLivingInfo = tenantAgreementType || tenantMovingReason || tenantPreviousPlaces
  const hasOccupationInfo = tenantStudy || tenantWork
  const hasTenantAd = data.tenant.tenantAd?.searchPreference || data.tenant.tenantAd

  const preferredAmenities = parsePreferredAmenities({
    searchPreference: data?.tenant?.tenantAd?.searchPreference,
  })
  const hasPreferenses = preferredAmenities.length !== 0

  const getCurrrentAgreementText = () => {
    if (
      tenantAgreementType &&
      tenantAgreementType !== HousingSituationAgreementTypeEnum.other_agreement_type
    ) {
      return t(`current_agreement.${tenantAgreementType}`)
    }
    return placeholder.emptyString
  }
  return (
    <div>
      <ProfileInfoWrapper isOpen={isOpen}>
        <MediumHeader>{tenantTitle}</MediumHeader>
        <Spacer factor={2} />
        {tenantBio && (
          <>
            <TenantBio>{tenantBio}</TenantBio>
            <Spacer factor={2} />
          </>
        )}
        {tenantPet && (
          <>
            <ApplicantBadge faIcon={'paw-claws'} title={tenantPet} iconColor={palette.blocketBlack} />
            <Spacer factor={2} />
          </>
        )}

        {hasLivingInfo && (
          <>
            <Spacer factor={4} />
            <TenantTitle>{t('living_situation')}</TenantTitle>
            <Spacer factor={2} />
            {tenantAgreementType && (
              <>
                <ApplicantBadge
                  faIcon={'house-user'}
                  title={getCurrrentAgreementText()}
                  iconColor={palette.blocketBlack}
                />
                <Spacer factor={2} />
              </>
            )}
            {tenantMovingReason && (
              <>
                <ApplicantBadge
                  faIcon={'house-leave'}
                  title={tenantMovingReason}
                  iconColor={palette.blocketBlack}
                />
                <Spacer factor={2} />
              </>
            )}
            {tenantPreviousPlaces && (
              <>
                <ApplicantBadge
                  faIcon={'house-night'}
                  title={tenantPreviousPlaces}
                  iconColor={palette.blocketBlack}
                />
                <Spacer factor={2} />
              </>
            )}
          </>
        )}
        {hasOccupationInfo && (
          <>
            <Spacer factor={4} />
            <TenantTitle>{t('occupation')}</TenantTitle>
            <Spacer factor={2} />
            {tenantWork && (
              <>
                <ApplicantBadge faIcon={'briefcase'} title={tenantWork} iconColor={palette.blocketBlack} />
                <Spacer factor={2} />
              </>
            )}
            {tenantStudy && (
              <>
                <ApplicantBadge
                  faIcon={'graduation-cap'}
                  title={tenantStudy}
                  iconColor={palette.blocketBlack}
                />
                <Spacer factor={2} />
              </>
            )}
          </>
        )}

        {hasTenantAd && (
          <>
            <Spacer factor={4} />
            <TenantTitle>{t('looking_for', { name: data.tenant.firstName })}</TenantTitle>
            <Spacer factor={2} />
            <OverviewSavedSearch data={data.tenant.tenantAd!.searchPreference} />
            {hasPreferenses && (
              <>
                <Spacer factor={2} />
                <TenantTitle>{t('preferences')}</TenantTitle>
                <Spacer factor={2} />
                <OverviewPreferredAmenities preferredAmenities={preferredAmenities} />
                <Spacer factor={2} />
                <InformationBox>{t('preferences_description')}</InformationBox>
              </>
            )}
            <Spacer factor={4} />
          </>
        )}

        {!isOpen && <BottomFade />}
      </ProfileInfoWrapper>

      <ExpandButton {...{ isOpen, onToggleOpen }} title={t('show_more_button')} />
    </div>
  )
}
