import styled from '@emotion/styled'
import { useParams, NavLink, Route, Routes } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { queries } from '../data/listing'
import type { ListingQuery, ListingQueryVariables } from '../data/graphql/types/__generated__/ListingQuery'
import { ListingContextProvider } from '../context/listing-context'
import { ListingContent } from '../ui-feature-modules/listing/listing-content'
import { BlockListingApartmentsTable } from '../ui-feature-modules/listing/block-listing-appartments-table'
import { TopSection } from '../ui-feature-modules/listing/top-section'
import { getIsBlockListing } from '../util-functions/listing.util'
import { ListingStats } from '../ui-feature-modules/listing/listing-stats'
import { WhiteBoxWrapper } from '../ui-shared/wrapper'
import { LoadingDots } from '../ui-shared/loading-dots'
import { ListingDates } from '../ui-feature-modules/listing/listing-dates'

const Wrapper = styled.div({
  height: '100%',
  display: 'flex',
})

const TabsSection = Stack

const Content = styled(Stack)({
  maxWidth: '1280px',
  width: '100%',
})

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  height: 48,
  textDecoration: 'none',
  color: theme.colors.text.default,
  fontWeight: 500,
  ':before': {
    content: "''",
    display: 'none',
    position: 'absolute',
    height: '3px',
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.colors.bg.negative,
  },
  '&.active': {
    color: theme.colors.text.negative,
    '&:before': {
      display: 'flex',
    },
  },
}))

type TParams = { listingId: string }

export function Listing() {
  const { t } = useTranslation('listing', { keyPrefix: 'tabs' })
  const params = useParams<TParams>()
  const { listingId } = params

  const {
    data: listingData,
    loading: isLoadingListing,
    error: listingError,
  } = useQuery<ListingQuery, ListingQueryVariables>(queries.GET_LISTING, {
    variables: { listingId: listingId! },
  })

  if (listingError) {
    // eslint-disable-next-line no-console
    console.error(listingError)
  }

  if (isLoadingListing) return <LoadingDots />

  if (!listingData || !listingData.home) return null

  return (
    <ListingContextProvider home={listingData.home}>
      <Wrapper>
        <Content gap="6x">
          <TopSection />
          <WhiteBoxWrapper gap="12x">
            <Stack direction="row" justifyContent="space-between">
              <TabsSection direction="row" gap="4x">
                <StyledNavLink end to="">
                  {t('listing')}
                </StyledNavLink>
                <StyledNavLink to="statistics">{t('statistics')}</StyledNavLink>
                {getIsBlockListing({
                  property: listingData.home.property,
                  numberOfHomes: listingData.home.numberOfHomes,
                }) && <StyledNavLink to={`apartments`}>{t('apartments')}</StyledNavLink>}
              </TabsSection>
              <ListingDates />
            </Stack>
            <Routes>
              <Route path="" element={<ListingContent />} />
              <Route path="statistics" element={<ListingStats />} />
              <Route
                path="apartments"
                element={<BlockListingApartmentsTable listingId={listingData!.home!.id} />}
              />
            </Routes>
          </WhiteBoxWrapper>
        </Content>
      </Wrapper>
    </ListingContextProvider>
  )
}
