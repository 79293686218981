import styled from 'styled-components/macro'
import { useEffect, useRef } from 'react'
import mapboxgl from 'mapbox-gl'
import isEqual from 'lodash/isEqual'

import { defaultStyles } from '../styling/defaults'
import { palette } from '../styling'

type MapPropTypes = {
  width?: string | number
  height?: string | number
  markers?: Array<{
    latitude: number
    longitude: number
    dragable?: boolean
  }>
  markersDragable?: boolean
  isZoomDisabled?: boolean
  isInteractionDisabled?: boolean
  onLatLngChange?: (latLng: { latitude: number; longitude: number }) => void
}

const Wrapper = styled.div<Partial<MapPropTypes>>(({ width, height, isInteractionDisabled }) => ({
  minHeight: '200px',
  width: width ? width : 'auto',
  height: height ? height : 'auto',
  borderRadius: defaultStyles.borderRadius,
  border: `1px solid ${palette.grey40}`,
  pointerEvents: isInteractionDisabled ? 'none' : 'visible',
}))

export function Map(props: MapPropTypes) {
  const mapContainer = useRef<HTMLDivElement>(null)
  const map = useRef<mapboxgl.Map>()
  const markers = useRef<Array<mapboxgl.Marker>>([])

  useEffect(() => {
    const currentMarkers = markers.current.map((marker) => {
      const { lng: longitude, lat: latitude } = marker.getLngLat()
      return { longitude, latitude }
    })

    if (!isEqual(currentMarkers, props.markers)) {
      if (!map || !map.current) {
        map.current = new mapboxgl.Map({
          container: mapContainer.current!,
          style:
            'https://tiles.locationiq.com/v3/streets/vector.json?key=' +
            process.env.REACT_APP_LOCAITON_IQ_ACCESS_TOKEN,
          center: [14.6435, 59.1282], //aprox. center of sweden
          zoom: 4,
        })

        if (props.isZoomDisabled) {
          map.current.scrollZoom.disable()
          map.current.dragPan.disable()
          map.current.doubleClickZoom.disable()
        }
      }

      if (map && map.current) {
        markers.current.forEach((marker) => {
          marker.remove()
        })

        markers.current = []

        props.markers?.forEach(({ longitude, latitude, dragable = false }) => {
          const marker = new mapboxgl.Marker()
            .setLngLat([longitude, latitude])
            .setDraggable(dragable)
            .addTo(map.current!)

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          marker.on('dragend', (event: any) => {
            const { lng, lat } = event.target._lngLat

            if (props.onLatLngChange) {
              props.onLatLngChange({
                longitude: Number(lng.toFixed(7)),
                latitude: Number(lat.toFixed(7)),
              })
            }
          })

          markers.current.push(marker)
        })

        if (markers.current.length === 1) {
          map.current.setCenter(markers.current[0].getLngLat())
          map.current.setZoom(13)
        } else if (markers.current.length > 1) {
          //TODO -> if there are multiple markers, fit the map to them
          //Hint -> map.current.fitBounds()
        }
      }
    }

    return () => {
      // map.current?.remove() // For some reason this is executed randomly
    }
  }, [props.markers, props])

  return (
    <Wrapper
      height={props.height}
      width={props.width}
      isInteractionDisabled={props.isInteractionDisabled}
      ref={mapContainer}
    />
  )
}
