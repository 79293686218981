import type { ListingQuery_home } from '../../data/graphql/types/__generated__/ListingQuery'
import type { ListingsQuery_homes_edges_node } from '../../data/graphql/types/__generated__/ListingsQuery'
import type { ListingSpan } from '../../util-functions/general.util'

type Props = {
  home: ListingQuery_home | ListingsQuery_homes_edges_node
}

export function getListingSpans({ home }: Props) {
  let rentSpan: ListingSpan
  let roomCountSpan: ListingSpan
  let squareMeterSpan: ListingSpan
  const tenantCountSpan = { min: home.minTenantCount, max: home.maxTenantCount }

  rentSpan = { min: home.minRent, max: home.maxRent }
  roomCountSpan = { min: home.minRoomCount, max: home.maxRoomCount }
  squareMeterSpan = { min: home.minSquareMeters, max: home.maxSquareMeters }
  if (home.property) {
    rentSpan = { min: home?.property?.minRent, max: home?.property?.maxRent }
    roomCountSpan = {
      min: home?.property?.minRoomCount,
      max: home?.property?.maxRoomCount,
    }
    squareMeterSpan = {
      min: home?.property?.minSquareMeters,
      max: home?.property?.maxSquareMeters,
    }
  }

  return { rentSpan, roomCountSpan, squareMeterSpan, tenantCountSpan }
}
