import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

import { Input } from '../../ui-atoms/input'

export function ViewingInformation() {
  const { t } = useTranslation('edit_listing', { keyPrefix: 'viewing_information' })
  const { control } = useFormContext()

  return (
    <Controller
      name="matterportEmbedUrl"
      control={control}
      render={({ field: { onChange, value, name } }) => {
        return (
          <Input
            name={name}
            type="text"
            placeholder={t('matterport_placeholder')}
            onChange={onChange}
            value={value}
            optional
          />
        )
      }}
    />
  )
}
