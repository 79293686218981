import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { Button } from '@qasa/qds-ui'
import { Controller, useFormContext } from 'react-hook-form'

import { palette } from '../../styling'
import { defaultStyles } from '../../styling/defaults'
import { getPixelAmount } from '../../styling/pixel-amount'
import { ImagesContainer, ObjectImage } from '../../ui-atoms/object-image'
import { SmallData } from '../../ui-atoms/small-data'
import { SmallHeader } from '../../ui-atoms/small-header'
import type { UploadData } from '../../types/uploads'
import { Stack } from '../../ui-shared/_core/stack'
import { UploadTypeEnum } from '../../data/graphql/types/__generated__/globalTypes'

import { FormSection } from './form-section'
import type { ListingFormValues } from './add-listing-form'

type HomeTemplateImagesProps = {
  data: UploadData[]
}

type HandleAddRemoveImageParams = {
  uploadId: string
  uploadIds: string[]
  onChange: (newValue: string[]) => void
}

const CurrentPicture = styled.div<{ chosen?: boolean }>(({ chosen }) => ({
  position: 'relative',
  marginRight: getPixelAmount(1),
  cursor: 'pointer',
  '& > div': {
    opacity: 0.5,
    ...(chosen && {
      opacity: 1,
    }),
  },
}))

const StatusIconWrapper = styled.div<{ chosen?: boolean }>(({ chosen }) => ({
  position: 'absolute',
  display: 'none',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'limegreen',
  bottom: getPixelAmount(2),
  right: getPixelAmount(1),
  height: getPixelAmount(3.5),
  width: getPixelAmount(3.5),
  borderRadius: defaultStyles.borderRadiusRound,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
  color: palette.white,
  ...(chosen && {
    display: 'flex',
  }),
}))

export function HomeTemplateImages({ data }: HomeTemplateImagesProps) {
  const { t } = useTranslation(['add_listing', 'building', 'apartment', 'commons'])
  const { control } = useFormContext<ListingFormValues>()

  const handleAddRemoveImage = ({ uploadId, uploadIds, onChange }: HandleAddRemoveImageParams) => {
    const newList = uploadIds?.includes(uploadId)
      ? uploadIds.filter((elementId) => elementId !== uploadId)
      : [...uploadIds, uploadId]
    onChange(newList)
  }

  const apartmentPictures = data?.filter((upload) => upload.type === UploadTypeEnum.home_picture) ?? []
  const layoutPictures = data?.filter((upload) => upload.type === UploadTypeEnum.home_layout_picture) ?? []
  const buildingPictures =
    data?.filter((upload) => upload.type === UploadTypeEnum.home_building_picture) ?? []

  return (
    <Controller
      name="uploadIds"
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <FormSection
            title={t('home_template_images.title')}
            description={t('home_template_images.description')}
          >
            <Stack gap={1}>
              <SmallHeader>{t('apartment:apartment_form.apartment_images_label')}</SmallHeader>
              {apartmentPictures.length ? (
                <ImagesContainer>
                  {apartmentPictures.map(({ url, id }) => (
                    <CurrentPicture
                      key={url}
                      chosen={value?.includes(id)}
                      onClick={() => handleAddRemoveImage({ uploadId: id, uploadIds: value ?? [], onChange })}
                    >
                      <ObjectImage src={url} />

                      <StatusIconWrapper chosen={value?.includes(id)}>
                        <FontAwesomeIcon icon={['fas', 'check']} />
                      </StatusIconWrapper>
                    </CurrentPicture>
                  ))}
                </ImagesContainer>
              ) : (
                <SmallData transparent>{t('apartment:missing_info.no_images_added')}</SmallData>
              )}
            </Stack>
            <Stack gap={1}>
              <SmallHeader>{t('apartment:apartment_form.layout_images_label')}</SmallHeader>
              {layoutPictures.length ? (
                <ImagesContainer>
                  {layoutPictures.map(({ url, id }) => (
                    <CurrentPicture
                      key={url}
                      chosen={value?.includes(id)}
                      onClick={() => handleAddRemoveImage({ uploadId: id, uploadIds: value ?? [], onChange })}
                    >
                      <ObjectImage src={url} />

                      <StatusIconWrapper chosen={value?.includes(id)}>
                        <FontAwesomeIcon icon={['fas', 'check']} />
                      </StatusIconWrapper>
                    </CurrentPicture>
                  ))}
                </ImagesContainer>
              ) : (
                <SmallData transparent>{t('apartment:missing_info.no_images_added')}</SmallData>
              )}
            </Stack>
            <Stack gap={1}>
              <SmallHeader>{t('building:fields.building_images')}</SmallHeader>
              {buildingPictures.length ? (
                <ImagesContainer>
                  {buildingPictures.map(({ url, id }) => (
                    <CurrentPicture
                      key={url}
                      chosen={value?.includes(id)}
                      onClick={() => handleAddRemoveImage({ uploadId: id, uploadIds: value ?? [], onChange })}
                    >
                      <ObjectImage src={url} />

                      <StatusIconWrapper chosen={value?.includes(id)}>
                        <FontAwesomeIcon icon={['fas', 'check']} />
                      </StatusIconWrapper>
                    </CurrentPicture>
                  ))}
                </ImagesContainer>
              ) : (
                <SmallData transparent>{t('building:missing_info.no_building_images')}</SmallData>
              )}
            </Stack>
            <div>
              <Button type="submit" variant={'primary'}>
                {t('commons:ctas.continue')}
              </Button>
            </div>
          </FormSection>
        )
      }}
    />
  )
}
