import { gql } from '@apollo/client'

export const UPDATE_UPLOAD = gql`
  mutation UpdateUploadMutation($id: ID!, $input: UpdateUploadInput!) {
    __typename
    updateUpload(id: $id, input: $input) {
      upload {
        id
        metadata {
          order
          primary
          rotation
        }
      }
      errors {
        codes
        field
      }
    }
  }
`
