/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ApplicationBoardListDefaultTypeEnum {
  completed = "completed",
  ongoing = "ongoing",
  pending = "pending",
}

export enum CancellationPolicyNameEnum {
  flexible = "flexible",
  medium = "medium",
  strict = "strict",
}

export enum CertificateStatusEnum {
  certified = "certified",
  denied_bad_credit = "denied_bad_credit",
  denied_blocked = "denied_blocked",
  denied_id_not_found = "denied_id_not_found",
  denied_unavailable = "denied_unavailable",
  expired = "expired",
  in_review = "in_review",
  payment_accepted = "payment_accepted",
  payment_error = "payment_error",
  payment_required = "payment_required",
}

export enum ContractLanguageEnum {
  en = "en",
  fi = "fi",
  fr = "fr",
  no = "no",
  sv = "sv",
}

export enum ContractPostCleaningEnum {
  post_cleaning_landlord = "post_cleaning_landlord",
  post_cleaning_tenant = "post_cleaning_tenant",
}

export enum ContractPreCleaningEnum {
  pre_cleaning_landlord = "pre_cleaning_landlord",
  pre_cleaning_tenant = "pre_cleaning_tenant",
}

export enum ContractRentalTypeEnum {
  long_term = "long_term",
  vacation = "vacation",
}

export enum ContractSmallRepairsEnum {
  small_repairs_landlord = "small_repairs_landlord",
  small_repairs_tenant = "small_repairs_tenant",
}

export enum ContractStatusEnum {
  awaiting_insurance_approval = "awaiting_insurance_approval",
  canceled = "canceled",
  canceled_bad_certificate = "canceled_bad_certificate",
  draft = "draft",
  ended = "ended",
  extended = "extended",
  final_draft = "final_draft",
  pending = "pending",
  pending_aprila_application = "pending_aprila_application",
  pending_aprila_rental_agreement = "pending_aprila_rental_agreement",
  pending_tenant_credit_check = "pending_tenant_credit_check",
  rejected = "rejected",
  signed = "signed",
  timedout = "timedout",
}

export enum ContractTypeEnum {
  extension = "extension",
  normal = "normal",
}

export enum ConversationMessageTypeEnum {
  booking_accepted = "booking_accepted",
  booking_canceled = "booking_canceled",
  booking_canceled_by_admin = "booking_canceled_by_admin",
  booking_confirmed = "booking_confirmed",
  booking_declined = "booking_declined",
  booking_declined_check_in_passed = "booking_declined_check_in_passed",
  booking_declined_due_to_inactivity = "booking_declined_due_to_inactivity",
  booking_declined_due_to_late_payment = "booking_declined_due_to_late_payment",
  booking_offer_accepted = "booking_offer_accepted",
  booking_offer_canceled = "booking_offer_canceled",
  booking_offer_canceled_by_other_booking = "booking_offer_canceled_by_other_booking",
  booking_offer_proposed = "booking_offer_proposed",
  booking_offer_rejected = "booking_offer_rejected",
  booking_payment_initiated = "booking_payment_initiated",
  booking_requested = "booking_requested",
  booking_revoked = "booking_revoked",
  contract_canceled_bad_certificate = "contract_canceled_bad_certificate",
  contract_draft_created = "contract_draft_created",
  contract_landlord_signed = "contract_landlord_signed",
  contract_tenant_signed = "contract_tenant_signed",
  conversation_closed = "conversation_closed",
  conversation_reopened = "conversation_reopened",
  cosigning_tenant_accepted = "cosigning_tenant_accepted",
  cosigning_tenant_invited = "cosigning_tenant_invited",
  cosigning_tenant_removed = "cosigning_tenant_removed",
  home_application_declined = "home_application_declined",
  home_application_in_contact = "home_application_in_contact",
  home_application_revoked = "home_application_revoked",
  home_archived = "home_archived",
  home_rented_out = "home_rented_out",
  image = "image",
  offering_received = "offering_received",
  shortcut_canceled_new_conversation = "shortcut_canceled_new_conversation",
  shortcut_canceled_old_conversation = "shortcut_canceled_old_conversation",
  shortcut_completed_new_conversation = "shortcut_completed_new_conversation",
  shortcut_completed_old_conversation = "shortcut_completed_old_conversation",
  shortcut_new_home_application = "shortcut_new_home_application",
  tenant_wants_contract = "tenant_wants_contract",
  text = "text",
  undo_home_application_declined = "undo_home_application_declined",
  undo_home_application_revoked = "undo_home_application_revoked",
  user_suspended = "user_suspended",
  viewing_cancelled = "viewing_cancelled",
  viewing_confirmed = "viewing_confirmed",
  viewing_detected = "viewing_detected",
  viewing_reminder = "viewing_reminder",
}

export enum ConversationMessageTypeInputEnum {
  image = "image",
  tenant_wants_contract = "tenant_wants_contract",
  text = "text",
}

export enum ConversationRoleTypeEnum {
  landlord = "landlord",
  tenant = "tenant",
}

export enum ConversationStatusEnum {
  closed = "closed",
  open = "open",
}

export enum CreditReportFetchStatusEnum {
  downloaded = "downloaded",
  errored = "errored",
  pending = "pending",
  unobtainable = "unobtainable",
}

export enum CurrencyEnum {
  EUR = "EUR",
  NOK = "NOK",
  SEK = "SEK",
}

export enum ElectricityFeePaymentOptionEnum {
  fixed_fee = "fixed_fee",
  included_in_rent = "included_in_rent",
  not_included_in_rent = "not_included_in_rent",
  tenant_managed = "tenant_managed",
}

export enum ExtraCostForEnum {
  electricity = "electricity",
  gas = "gas",
  heating = "heating",
  internet = "internet",
  other = "other",
  tv = "tv",
  water = "water",
}

export enum ExtraCostPlanEnum {
  included = "included",
  monthly = "monthly",
  monthly_per_person = "monthly_per_person",
  not_available = "not_available",
  not_included = "not_included",
  tenant_responsibility = "tenant_responsibility",
  usage = "usage",
}

export enum FeeAvailabilityEnum {
  mandatory = "mandatory",
  optional = "optional",
  unavailable = "unavailable",
}

export enum HomeApplicationOrderEnum {
  BEST_MATCH = "BEST_MATCH",
  LAST_CHAT_ACTIVITY = "LAST_CHAT_ACTIVITY",
  NEWEST_FIRST = "NEWEST_FIRST",
  NOT_STARRED = "NOT_STARRED",
  OLDEST_FIRST = "OLDEST_FIRST",
  POSITION = "POSITION",
  STARRED = "STARRED",
  WAITING_TIME = "WAITING_TIME",
}

export enum HomeApplicationOriginEnum {
  landlord_application = "landlord_application",
  tenant_application = "tenant_application",
}

export enum HomeApplicationStatusEnum {
  closed = "closed",
  declined = "declined",
  in_contact = "in_contact",
  pending = "pending",
  revoked = "revoked",
}

export enum HomeArchiveReasonEnum {
  accidental_publish = "accidental_publish",
  application_deadline_passed = "application_deadline_passed",
  changed_plans = "changed_plans",
  dissatisfying_applications = "dissatisfying_applications",
  dont_want_additional_costs = "dont_want_additional_costs",
  end_date_passed = "end_date_passed",
  expired = "expired",
  inactivity = "inactivity",
  lease_agreement_too_difficult = "lease_agreement_too_difficult",
  needs_verification = "needs_verification",
  other_reason = "other_reason",
  review_denied = "review_denied",
  shortcut_cancelled = "shortcut_cancelled",
  shortcut_completed = "shortcut_completed",
  suspected_scam = "suspected_scam",
  tenant_found_in_bbq = "tenant_found_in_bbq",
  tenant_from_platform = "tenant_from_platform",
  tenant_outside_platform = "tenant_outside_platform",
  token_archive = "token_archive",
  user_removed = "user_removed",
  using_broker_instead = "using_broker_instead",
}

export enum HomeConditionEnum {
  good = "good",
  newly_built = "newly_built",
  poor = "poor",
  satisfactory = "satisfactory",
}

export enum HomeInsuranceEnum {
  insurance_landlord = "insurance_landlord",
  insurance_tenant = "insurance_tenant",
}

export enum HomeRentalTypeEnum {
  long_term = "long_term",
  vacation = "vacation",
}

export enum HomeSearchOrderByEnum {
  CREATED_AT = "CREATED_AT",
  FAVORITE_MARKED_AT = "FAVORITE_MARKED_AT",
  MONTHLY_COST = "MONTHLY_COST",
  MOVE_IN = "MOVE_IN",
  MOVE_OUT = "MOVE_OUT",
  PUBLISHED_AT = "PUBLISHED_AT",
  RENT = "RENT",
  ROOM_COUNT = "ROOM_COUNT",
  ROUTE = "ROUTE",
  SORTING_SCORE = "SORTING_SCORE",
  SQUARE_METERS = "SQUARE_METERS",
  UPDATED_AT = "UPDATED_AT",
}

export enum HomeSearchOrderEnum {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}

export enum HomeStatusEnum {
  archived = "archived",
  contract_ended = "contract_ended",
  hidden = "hidden",
  in_review = "in_review",
  in_shortcut = "in_shortcut",
  normal = "normal",
  pending = "pending",
  pending_shortcut = "pending_shortcut",
  proposed_shortcut = "proposed_shortcut",
  rented_out = "rented_out",
}

export enum HomeTemplateOrderEnum {
  BUILDING_NAME = "BUILDING_NAME",
  IS_LISTED = "IS_LISTED",
  UPDATED_LATEST = "UPDATED_LATEST",
}

export enum HomeTypeEnum {
  apartment = "apartment",
  corridor = "corridor",
  cottage = "cottage",
  duplex = "duplex",
  house = "house",
  loft = "loft",
  other = "other",
  own = "own",
  room = "room",
  terrace_house = "terrace_house",
}

export enum HomeUserRoleEnum {
  landlord = "landlord",
  tenant = "tenant",
}

export enum HousingSituationAgreementTypeEnum {
  co_living = "co_living",
  first_hand_renting = "first_hand_renting",
  living_with_family = "living_with_family",
  other_agreement_type = "other_agreement_type",
  own_condominium = "own_condominium",
  own_house = "own_house",
  owning = "owning",
  second_hand_renting = "second_hand_renting",
  student_housing = "student_housing",
}

export enum IdentificationTypeEnum {
  fi_tupas = "fi_tupas",
  manual = "manual",
  no_bank_id = "no_bank_id",
  onfido = "onfido",
  se_bank_id = "se_bank_id",
  tink = "tink",
}

export enum InsuranceResponsibilityEnum {
  insurance_responsibility_landlord = "insurance_responsibility_landlord",
  insurance_responsibility_tenant = "insurance_responsibility_tenant",
}

export enum InvoiceEntryCostTypeEnum {
  deduction = "deduction",
  surcharge = "surcharge",
}

export enum InvoiceEntryTypeEnum {
  cleaning_fee_payment = "cleaning_fee_payment",
  cleaning_fee_payout = "cleaning_fee_payout",
  deposit_compensation_deposit_free_payout = "deposit_compensation_deposit_free_payout",
  deposit_compensation_payment = "deposit_compensation_payment",
  deposit_compensation_payout = "deposit_compensation_payout",
  deposit_free = "deposit_free",
  deposit_payment = "deposit_payment",
  deposit_payout = "deposit_payout",
  electricity_fee_payment = "electricity_fee_payment",
  electricity_fee_payout = "electricity_fee_payout",
  insurance_landlord = "insurance_landlord",
  insurance_tenant = "insurance_tenant",
  landlord_base_fee = "landlord_base_fee",
  landlord_base_fee_adjustment = "landlord_base_fee_adjustment",
  qasa_guarantee = "qasa_guarantee",
  rental_payment = "rental_payment",
  rental_payment_adjustment = "rental_payment_adjustment",
  rental_payout = "rental_payout",
  rental_payout_adjustment = "rental_payout_adjustment",
  tenant_base_fee = "tenant_base_fee",
  tenant_base_fee_adjustment = "tenant_base_fee_adjustment",
  water_cost_payment = "water_cost_payment",
  water_cost_payout = "water_cost_payout",
}

export enum InvoiceTypeEnum {
  invoice_deposit = "invoice_deposit",
  invoice_deposit_compensation = "invoice_deposit_compensation",
  invoice_rent = "invoice_rent",
  selfinvoice_deposit = "selfinvoice_deposit",
  selfinvoice_deposit_compensation = "selfinvoice_deposit_compensation",
  selfinvoice_rent = "selfinvoice_rent",
  verification_fee = "verification_fee",
}

export enum LegalTypeEnum {
  egen_bolig = "egen_bolig",
  enkelt_beboelserom = "enkelt_beboelserom",
  hus_leilighet = "hus_leilighet",
  loft_sokkenbolig = "loft_sokkenbolig",
}

export enum LocationGranularityEnum {
  city = "city",
  country = "country",
  exact = "exact",
  formatted_address = "formatted_address",
  manual_address = "manual_address",
  street = "street",
  sublocality = "sublocality",
}

export enum NotificationSpanEnum {
  EVERY_HOUR = "EVERY_HOUR",
  EVERY_THIRD_HOUR = "EVERY_THIRD_HOUR",
  INSTANT = "INSTANT",
  ONCE_A_DAY = "ONCE_A_DAY",
}

export enum OfferingStatusEnum {
  accepted = "accepted",
  completed = "completed",
  declined = "declined",
  pending = "pending",
  revoked = "revoked",
}

export enum OwnerTypeEnum {
  AREA_UPLOAD = "AREA_UPLOAD",
  BUILDING = "BUILDING",
  CERTIFICATE = "CERTIFICATE",
  CONTRACT = "CONTRACT",
  CONVERSATION = "CONVERSATION",
  CO_APPLICANT = "CO_APPLICANT",
  EMPLOYMENT_VERIFICATION = "EMPLOYMENT_VERIFICATION",
  HOME = "HOME",
  HOME_TEMPLATE = "HOME_TEMPLATE",
  INCOME_VERIFICATION = "INCOME_VERIFICATION",
  REFERENCE_CHECK = "REFERENCE_CHECK",
  SAVINGS_VERIFICATION = "SAVINGS_VERIFICATION",
  STUDENT_VERIFICATION = "STUDENT_VERIFICATION",
  USER = "USER",
}

export enum PlatformEnum {
  blocket = "blocket",
  dotcom = "dotcom",
  finn = "finn",
  homeq = "homeq",
  qasa = "qasa",
  qasa_finland = "qasa_finland",
  qasa_france = "qasa_france",
}

export enum ResponsibleForCleaningEnum {
  landlord = "landlord",
  landlord_for_fee = "landlord_for_fee",
  tenant = "tenant",
}

export enum ScriveInvitationOptionEnum {
  api = "api",
  email_mobile = "email_mobile",
}

export enum ServiceAvailabilityEnum {
  included = "included",
  optional = "optional",
  unavailable = "unavailable",
}

export enum ShortcutRejectionReasonEnum {
  NOT_INTERESTED_WILL_PUBLISH_THEMSELVES = "NOT_INTERESTED_WILL_PUBLISH_THEMSELVES",
  NO_RESPONSE = "NO_RESPONSE",
  OTHER = "OTHER",
  SHARED_HOME = "SHARED_HOME",
  SPECIFIC_TENANT_REQUIREMENTS = "SPECIFIC_TENANT_REQUIREMENTS",
  TOO_HIGH_RENT_EXPECTATION = "TOO_HIGH_RENT_EXPECTATION",
  TOO_SHORT_DURATION = "TOO_SHORT_DURATION",
  USED_ANOTHER_SERVICE = "USED_ANOTHER_SERVICE",
}

export enum ShortcutStatesEnum {
  aborted = "aborted",
  canceled = "canceled",
  completed = "completed",
  draft = "draft",
  ongoing = "ongoing",
  paused = "paused",
  pending = "pending",
}

export enum ShowingStatusEnum {
  current_tenant_has_showing = "current_tenant_has_showing",
  no_showing = "no_showing",
  showing = "showing",
}

export enum SignatoryRoleEnum {
  cosigning_tenant = "cosigning_tenant",
  landlord = "landlord",
  tenant = "tenant",
}

export enum SigningProcessEnum {
  default = "default",
  instant = "instant",
}

export enum TenantVerificationEnum {
  credit_check_verified = "credit_check_verified",
  identity_verified = "identity_verified",
  income_verified = "income_verified",
  student = "student",
}

export enum TenureTypeEnum {
  condominium = "condominium",
  proprietary = "proprietary",
  tenancy = "tenancy",
}

export enum TraitTypeEnum {
  archipelago = "archipelago",
  balcony = "balcony",
  bathtub = "bathtub",
  bed_sheets = "bed_sheets",
  bike_room = "bike_room",
  bikes = "bikes",
  boat_kayak = "boat_kayak",
  coffee_brewer = "coffee_brewer",
  communal_sauna = "communal_sauna",
  cot = "cot",
  dish_washer = "dish_washer",
  drying_room = "drying_room",
  electric_car_charger = "electric_car_charger",
  electricity_included = "electricity_included",
  elevator = "elevator",
  extra_blankets_and_pillows = "extra_blankets_and_pillows",
  fire_extinguisher = "fire_extinguisher",
  first_aid_kit = "first_aid_kit",
  freezer = "freezer",
  french_balcony = "french_balcony",
  fridge = "fridge",
  furniture = "furniture",
  garage = "garage",
  garage_included = "garage_included",
  garden = "garden",
  grill = "grill",
  grill_essentials = "grill_essentials",
  hair_dryer = "hair_dryer",
  heat_included = "heat_included",
  high_chair = "high_chair",
  indoor_fireplace = "indoor_fireplace",
  inhome_sauna = "inhome_sauna",
  internet = "internet",
  internet_included = "internet_included",
  iron = "iron",
  jacuzzi = "jacuzzi",
  kettle = "kettle",
  kitchen_appliances = "kitchen_appliances",
  kitchenette = "kitchenette",
  microwave_oven = "microwave_oven",
  near_beach = "near_beach",
  near_fishing = "near_fishing",
  near_golf = "near_golf",
  near_hiking_routes = "near_hiking_routes",
  near_restaurants = "near_restaurants",
  outdoor_furniture = "outdoor_furniture",
  outdoor_shower = "outdoor_shower",
  outhouse = "outhouse",
  oven = "oven",
  overnight_stay = "overnight_stay",
  parking = "parking",
  parking_included = "parking_included",
  patio = "patio",
  pets = "pets",
  pool = "pool",
  porcelain_glasses_cutlery = "porcelain_glasses_cutlery",
  recycling = "recycling",
  remote = "remote",
  security_door = "security_door",
  shared_washing_area = "shared_washing_area",
  shower = "shower",
  shower_supplies = "shower_supplies",
  ski_in_out = "ski_in_out",
  smoke_alarm = "smoke_alarm",
  smoker = "smoker",
  sound_system = "sound_system",
  storage = "storage",
  stove = "stove",
  stroller_room = "stroller_room",
  television = "television",
  television_included = "television_included",
  toilet = "toilet",
  towels = "towels",
  tumble_dryer = "tumble_dryer",
  washing_machine = "washing_machine",
  water_included = "water_included",
  wheelchair_accessible = "wheelchair_accessible",
}

export enum UploadFileTypeEnum {
  document = "document",
  geometry = "geometry",
  image = "image",
  unknown = "unknown",
  video = "video",
}

export enum UploadTypeEnum {
  activity_compensation_verification = "activity_compensation_verification",
  activity_grant_verification = "activity_grant_verification",
  area_upload_geometry = "area_upload_geometry",
  capital_as_income_verification = "capital_as_income_verification",
  certificate_credit_check = "certificate_credit_check",
  child_benefit_verification = "child_benefit_verification",
  contract_pdf = "contract_pdf",
  csn_verification = "csn_verification",
  data_takeout_json = "data_takeout_json",
  employment_verification = "employment_verification",
  home_building_picture = "home_building_picture",
  home_layout_picture = "home_layout_picture",
  home_picture = "home_picture",
  housing_allowance_verification = "housing_allowance_verification",
  housing_supplement_verification = "housing_supplement_verification",
  income_verification = "income_verification",
  insurance_terms = "insurance_terms",
  interest_income_verification = "interest_income_verification",
  introduction_benefit_verification = "introduction_benefit_verification",
  limited_company = "limited_company",
  limited_company_annual_account = "limited_company_annual_account",
  limited_company_audit_certificate = "limited_company_audit_certificate",
  limited_company_f_tax = "limited_company_f_tax",
  maintenance_support_verification = "maintenance_support_verification",
  message_image = "message_image",
  parental_benefit_employed_verification = "parental_benefit_employed_verification",
  parental_benefit_unemployed_verification = "parental_benefit_unemployed_verification",
  passport_picture = "passport_picture",
  pension_verification = "pension_verification",
  private_company = "private_company",
  private_company_audit_certificate = "private_company_audit_certificate",
  private_company_balance_report = "private_company_balance_report",
  private_company_declaration = "private_company_declaration",
  private_company_f_tax = "private_company_f_tax",
  private_company_result_report = "private_company_result_report",
  profile_picture = "profile_picture",
  reference_check_pdf = "reference_check_pdf",
  savings_verification = "savings_verification",
  sick_pay_verification = "sick_pay_verification",
  sickness_compensation_verification = "sickness_compensation_verification",
  social_welfare_verification = "social_welfare_verification",
  student_verification = "student_verification",
  unemployment_benefit_scheme_verification = "unemployment_benefit_scheme_verification",
  verification_selfie_picture = "verification_selfie_picture",
}

export enum UserNoteRecordTypeEnum {
  home_application = "home_application",
}

export interface ContractTemplateInput {
  landlordHasKeys?: boolean | null;
  canUse?: string[] | null;
  rentExcludes?: string[] | null;
  rentIncludes?: string[] | null;
  smallRepairs?: ContractSmallRepairsEnum | null;
}

export interface CreateConversationMessageInput {
  conversationId: string;
  message?: string | null;
  uploadId?: string | null;
  messageType?: ConversationMessageTypeInputEnum | null;
}

export interface CreateDurationInput {
  startOptimal?: QasaDateTime | null;
  endOptimal?: QasaDateTime | null;
  startAsap?: boolean | null;
  endUfn?: boolean | null;
  possibilityOfExtension?: boolean | null;
  durationPreference?: CreateDurationPreferenceInput | null;
}

export interface CreateDurationPreferenceInput {
  startLatest?: QasaDateTime | null;
  endEarliest?: QasaDateTime | null;
  shortestDurationDays?: number | null;
  longestDurationDays?: number | null;
}

export interface CreateHomeInput {
  type: HomeTypeEnum;
  shared: boolean;
  minSquareMeters?: number | null;
  maxSquareMeters?: number | null;
  squareMeters?: number | null;
  minRoomCount?: number | null;
  maxRoomCount?: number | null;
  roomCount?: number | null;
  toiletCount?: number | null;
  bedroomCount?: number | null;
  bedCount?: number | null;
  hasKitchen?: boolean | null;
  studentHome?: boolean | null;
  seniorHome?: boolean | null;
  corporateHome?: boolean | null;
  location: CreateLocationInput;
  tenantCount: number;
  duration: CreateDurationInput;
  rent: number;
  firsthand?: boolean | null;
  description?: string | null;
  houseRules?: string | null;
  apartmentNumber?: string | null;
  insurance?: HomeInsuranceEnum | null;
  qasaGuarantee?: boolean | null;
  traits?: TraitTypeEnum[] | null;
  numberOfHomes?: number | null;
  housingAssociation?: string | null;
  energyClass?: string | null;
  buildYear?: number | null;
  kitchenRenovationYear?: number | null;
  bathroomRenovationYear?: number | null;
  floor?: number | null;
  buildingFloors?: number | null;
  uploadIds?: string[] | null;
  homeTemplateIds?: string[] | null;
  minRent?: number | null;
  maxRent?: number | null;
  descriptionBuilding?: string | null;
  descriptionTransportation?: string | null;
  descriptionContract?: string | null;
  maxTenantCount?: number | null;
  minTenantCount?: number | null;
  title?: string | null;
  tenureType?: TenureTypeEnum | null;
  rentalType?: HomeRentalTypeEnum | null;
  instantBooking?: boolean | null;
  ownHome?: boolean | null;
  translatedDescriptions?: TranslatedDescriptionInput[] | null;
  cancellationPolicyName?: CancellationPolicyNameEnum | null;
  waterFeePerTenant?: number | null;
  applicationDeadline?: QasaDateTime | null;
  matterportEmbedUrl?: string | null;
  organizationUid?: string | null;
  electricityFee?: ElectricityFeeInput | null;
  origin?: string | null;
}

export interface CreateLocationInput {
  latitude?: number | null;
  longitude?: number | null;
  placeId?: string | null;
  streetNumber?: string | null;
  route?: string | null;
  sublocality?: string | null;
  locality?: string | null;
  postalCode?: string | null;
  country?: string | null;
  countryCode?: string | null;
  formattedAddress?: string | null;
  shortName?: string | null;
  granularity?: LocationGranularityEnum | null;
}

export interface CreateOfferingInput {
  homeApplicationId: string;
  homeTemplateId?: string | null;
  message?: string | null;
  showingStatus: ShowingStatusEnum;
  showingAt?: QasaDateTime | null;
  showingContactInformation?: string | null;
}

export interface CreateUploadInput {
  title: string;
  contentLength: number;
  contentType: string;
  uploadType: UploadTypeEnum;
  ownerType: OwnerTypeEnum;
  ownerId: string;
  metadata?: UploadMetadataInput | null;
}

export interface ElectricityFeeInput {
  paymentPlan: ElectricityFeePaymentOptionEnum;
  monthlyFee?: number | null;
}

export interface ExtraCostInput {
  costFor: ExtraCostForEnum;
  plan: ExtraCostPlanEnum;
  amount?: number | null;
  description?: string | null;
}

export interface InstantSignSettingInput {
  enabled?: boolean | null;
}

export interface MarkConversationInput {
  conversationId: string;
  lastSeenMessageId?: string | null;
  read?: boolean | null;
}

export interface TransitionShortcutInput {
  shortcutId: string;
  toState: ShortcutStatesEnum;
  reason?: ShortcutRejectionReasonEnum | null;
}

export interface TranslatedDescriptionInput {
  language: string;
  description: string;
}

export interface UpdateHomeInput {
  type?: HomeTypeEnum | null;
  shared?: boolean | null;
  minSquareMeters?: number | null;
  maxSquareMeters?: number | null;
  squareMeters?: number | null;
  minRoomCount?: number | null;
  maxRoomCount?: number | null;
  roomCount?: number | null;
  toiletCount?: number | null;
  bedroomCount?: number | null;
  bedCount?: number | null;
  hasKitchen?: boolean | null;
  studentHome?: boolean | null;
  seniorHome?: boolean | null;
  corporateHome?: boolean | null;
  location?: CreateLocationInput | null;
  tenantCount?: number | null;
  duration?: CreateDurationInput | null;
  rent?: number | null;
  firsthand?: boolean | null;
  description?: string | null;
  houseRules?: string | null;
  externalInfoPage?: string | null;
  traits?: TraitTypeEnum[] | null;
  extraCosts?: ExtraCostInput[] | null;
  apartmentNumber?: string | null;
  qasaGuarantee?: boolean | null;
  insurance?: HomeInsuranceEnum | null;
  numberOfHomes?: number | null;
  housingAssociation?: string | null;
  energyClass?: string | null;
  buildYear?: number | null;
  kitchenRenovationYear?: number | null;
  bathroomRenovationYear?: number | null;
  floor?: number | null;
  buildingFloors?: number | null;
  minRent?: number | null;
  maxRent?: number | null;
  descriptionBuilding?: string | null;
  descriptionTransportation?: string | null;
  descriptionContract?: string | null;
  external?: boolean | null;
  externalEmail?: string | null;
  externalApplicationUrl?: string | null;
  maxTenantCount?: number | null;
  minTenantCount?: number | null;
  title?: string | null;
  tenureType?: TenureTypeEnum | null;
  rentalType?: HomeRentalTypeEnum | null;
  ownHome?: boolean | null;
  responsibleForCleaning?: ResponsibleForCleaningEnum | null;
  translatedDescriptions?: TranslatedDescriptionInput[] | null;
  cancellationPolicyName?: CancellationPolicyNameEnum | null;
  waterFeePerTenant?: number | null;
  applicationDeadline?: QasaDateTime | null;
  cleaningFee?: number | null;
  matterportEmbedUrl?: string | null;
  instantSignSettingAttributes?: InstantSignSettingInput | null;
  contractTemplateAttributes?: ContractTemplateInput | null;
  electricityFee?: ElectricityFeeInput | null;
  origin?: string | null;
  condition?: HomeConditionEnum | null;
}

export interface UpdateShortcutInput {
  homePitch: string;
}

export interface UpdateUploadInput {
  metadata?: UploadMetadataInput | null;
}

export interface UploadMetadataInput {
  order?: number | null;
  rotation?: number | null;
  primary?: boolean | null;
}

export interface UpsertApplicationBoardCardInfoInput {
  showIdentification?: boolean | null;
  showUc?: boolean | null;
  showIncome?: boolean | null;
  showStudyCertificate?: boolean | null;
  showEmployerCertificate?: boolean | null;
  showMatchingScore?: boolean | null;
  showQueueDays?: boolean | null;
  showLastMessage?: boolean | null;
}

export interface UpsertBuildingInput {
  buildingName?: string | null;
  location?: CreateLocationInput | null;
  descriptionBuilding?: string | null;
  descriptionTransportation?: string | null;
  buildYear?: number | null;
  energyClass?: string | null;
  organizationUid?: string | null;
}

export interface UpsertContractDetailsInput {
  tenantFirstName?: string | null;
  tenantFamilyName?: string | null;
  tenantPhoneNumber?: string | null;
  tenantEmail?: string | null;
  cadastralReference?: string | null;
  sectionNumber?: string | null;
  municipality?: string | null;
  titleNumber?: string | null;
  canBeTerminated?: boolean | null;
  terminationNoticeMonths?: number | null;
  bindingTime?: number | null;
  shorterTerminationExplanation?: string | null;
  legalType?: LegalTypeEnum | null;
  knownDamages?: string | null;
  keySpecification?: string | null;
  codeOfConduct?: string | null;
  rentedAsIs?: boolean | null;
  bathroomShared?: boolean | null;
  kitchenShared?: boolean | null;
  additionalInformation?: string | null;
}

export interface UpsertContractInput {
  homeId?: string | null;
  tenantUid?: string | null;
  canUse?: string[] | null;
  furniture?: boolean | null;
  insuranceResponsibility?: InsuranceResponsibilityEnum | null;
  inventory?: string[] | null;
  landlordHasKeys?: boolean | null;
  overnightStay?: boolean | null;
  ownHome?: boolean | null;
  pets?: boolean | null;
  postCleaning?: ContractPostCleaningEnum | null;
  preCleaning?: ContractPreCleaningEnum | null;
  renovations?: boolean | null;
  rent?: number | null;
  rentExcludes?: string[] | null;
  rentIncludes?: string[] | null;
  smallRepairs?: ContractSmallRepairsEnum | null;
  smoker?: boolean | null;
  subletception?: boolean | null;
  temporaryInstallations?: boolean | null;
  tenureType?: TenureTypeEnum | null;
  terminationNotice?: boolean | null;
  annualRentIncrease?: number | null;
  duration?: CreateDurationInput | null;
  extraCosts?: ExtraCostInput[] | null;
  contractDetails?: UpsertContractDetailsInput | null;
  paymentDay?: number | null;
  apartmentNumber?: string | null;
  qasaGuarantee?: boolean | null;
  insurance?: HomeInsuranceEnum | null;
  rentalType?: ContractRentalTypeEnum | null;
  numberOfTenants?: number | null;
  waterFeePerTenant?: number | null;
  acceptsCosignatures?: boolean | null;
  electricityFee?: ElectricityFeeInput | null;
}

export interface UpsertHomeTemplateInput {
  type?: HomeTypeEnum | null;
  shared?: boolean | null;
  squareMeters?: number | null;
  roomCount?: number | null;
  studentHome?: boolean | null;
  seniorHome?: boolean | null;
  corporateHome?: boolean | null;
  location?: CreateLocationInput | null;
  tenantCount?: number | null;
  rent?: number | null;
  floor?: number | null;
  firsthand?: boolean | null;
  description?: string | null;
  apartmentNumber?: string | null;
  buildingId?: string | null;
  traits?: TraitTypeEnum[] | null;
  kitchenRenovationYear?: number | null;
  bathroomRenovationYear?: number | null;
  waterFeePerTenant?: number | null;
  tenureType?: TenureTypeEnum | null;
  organizationUid?: string | null;
  electricityFee?: ElectricityFeeInput | null;
}

export interface UpsertRentalRequirementInput {
  id?: string | null;
  verifiedIncome?: boolean | null;
  rentMultiplier?: number | null;
  verifiedIdNumber?: boolean | null;
  approvedCreditCheck?: boolean | null;
  studentVerification?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
