import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from '@emotion/styled'
import { Paragraph, Stack } from '@qasa/qds-ui'

import type { HomeApplicationsRequest_homeApplications_edges_node_tenant } from '../../../data/graphql/types/__generated__/HomeApplicationsRequest'

const Wrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.colors.core.yellow10,
  paddingBlock: theme.spacing['1x'],
  paddingInline: theme.spacing['2x'],
  borderRadius: theme.radii.xs,
}))

type Props = Pick<HomeApplicationsRequest_homeApplications_edges_node_tenant, 'userNotes'>

export function ApplicantCardNote({ userNotes }: Props) {
  const hasNote = Boolean(userNotes.length)
  if (!hasNote) return null

  const note = userNotes[userNotes.length - 1].note

  return (
    <Wrapper direction="row" alignItems={'center'} gap="2x">
      {/* TODO: Replace with QDS Icon */}
      <FontAwesomeIcon icon={['fal', 'sticky-note']} />
      <Paragraph numberOfLines={1} size="xs">
        {note}
      </Paragraph>
    </Wrapper>
  )
}
