import { Stack, Heading, Paragraph } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import { EditDialog } from '@qasa/app/src/modules/edit-dialog'

import { ListingSectionEnum, useListingContext } from '../../context/listing-context'
import { ObjectImage, ImagesContainer } from '../../ui-atoms/object-image'

const Wrapper = Stack
const LeftContainer = Stack
const RightContainer = Stack

export function Images() {
  const { t } = useTranslation('listing')
  const { home, setIsEditDialogOpen, setActiveEditSection } = useListingContext()
  const { uploads } = home || {}

  return (
    <Wrapper gap="6x" direction="row" justifyContent={'space-between'}>
      <LeftContainer gap="2x">
        <Heading size="3xs">{t('listing_images')}</Heading>
        {!uploads || uploads.length === 0 ? (
          <Paragraph>{t('no_images')}</Paragraph>
        ) : (
          <ImagesContainer>
            {uploads.map((upload, index) => (
              <ObjectImage key={index} src={upload.url} />
            ))}
          </ImagesContainer>
        )}
      </LeftContainer>
      <RightContainer>
        <EditDialog.Trigger
          onClick={() => {
            setIsEditDialogOpen(true)
            setActiveEditSection(ListingSectionEnum.HOME_IMAGES)
          }}
        />
      </RightContainer>
    </Wrapper>
  )
}
