import styled from 'styled-components/macro'
import { Button, Heading, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

import { TableTopSection } from '../ui-atoms/top-section'
import { IframeInformation } from '../ui-feature-modules/settings/iframe-information'
import { WhiteBoxWrapper } from '../ui-shared/wrapper'
import { AddMacroDialog } from '../ui-feature-modules/settings/add-macro-dialog'
import { useUserContext } from '../context/user-context'

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
})

export function Settings() {
  const { t } = useTranslation('settings')
  const { authBody } = useUserContext()
  const [isOpen, setIsOpen] = useState(false)

  const isProAgent = authBody?.isProAgent

  return (
    <Wrapper>
      <Stack gap="8x">
        <TableTopSection>
          <Heading as="h1" size="md">
            {t('title')}
          </Heading>
        </TableTopSection>
        <WhiteBoxWrapper>
          <Stack gap="6x">
            <IframeInformation />
          </Stack>
        </WhiteBoxWrapper>
        {isProAgent && <Button onClick={() => setIsOpen(true)}>{'Add message macro'}</Button>}
      </Stack>
      <AddMacroDialog isOpen={isOpen} setIsOpen={setIsOpen} />
    </Wrapper>
  )
}
