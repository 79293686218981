import { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Spacer } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import { useQueryParams, withDefault, StringParam } from 'use-query-params'

import { queries } from '../../../data/application-board-lists'
import { mutations } from '../../../data/applications-by-board-list'
import { queries as applicationsQueries } from '../../../data/applications'
import type { ApplicationBoardLists } from '../../../data/graphql/types/__generated__/ApplicationBoardLists'
import {
  HomeApplicationOrderEnum,
  HomeApplicationStatusEnum,
  OfferingStatusEnum,
} from '../../../data/graphql/types/__generated__/globalTypes'
import type {
  HomeApplicationsRequest,
  HomeApplicationsRequestVariables,
} from '../../../data/graphql/types/__generated__/HomeApplicationsRequest'
import { Input } from '../../../ui-atoms/input'
import { LoadingDots } from '../../../ui-shared/loading-dots'
import { useFetchMoreOnScrollToBottom } from '../../../hooks/use-fetch-more-on-scroll-to-bottom'

import { RemovedApplicantCard } from './removed-applicant-card'
import { RemovedActions } from './removed-actions'

const ListContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  overflowX: 'hidden',
  height: '100%',
})

const BULK_SIZE = 20
const INITIAL_REQUEST_COUNT = 30
const REJECTED_STATUS = [
  HomeApplicationStatusEnum.declined,
  HomeApplicationStatusEnum.closed,
  HomeApplicationStatusEnum.revoked,
]

export type RemovedListFilterOrSortParams = Partial<HomeApplicationsRequestVariables>

export function RemovedList() {
  const { t } = useTranslation('applications')
  const [searchString, setSearchString] = useState<string>('')
  const [{ listing: listingId }] = useQueryParams({ listing: withDefault(StringParam, null) })
  const navigate = useNavigate()

  const {
    loading: isLoadingRemoved,
    error: errorRemoved,
    data: removedData,
    fetchMore,
    refetch,
  } = useQuery<HomeApplicationsRequest, HomeApplicationsRequestVariables>(applicationsQueries.GET_REQUESTS, {
    variables: {
      homeApplicationsStatus: REJECTED_STATUS,
      homeApplicationsFirst: INITIAL_REQUEST_COUNT,
      homeApplicationsExcludeOfferingStatuses: [OfferingStatusEnum.declined],
      homeApplicationsOrderBy: HomeApplicationOrderEnum.BEST_MATCH,
      homeApplicationsHomeId: listingId ? [listingId] : null,
    },
  })
  const { handleScroll, isFetchingMore } = useFetchMoreOnScrollToBottom({
    handleFetchMore: () =>
      fetchMore({
        variables: {
          homeApplicationsFirst: BULK_SIZE,
          homeApplicationsAfter: removedData?.homeApplications?.pageInfo.endCursor,
        },
      }),
    shouldFetchMore: removedData?.homeApplications?.pageInfo.hasNextPage,
  })

  const { data: columnData } = useQuery<ApplicationBoardLists>(queries.GET_APPLICATION_BOARD_LISTS, {
    variables: {
      homeId: listingId,
    },
    fetchPolicy: 'network-only',
  })

  const [addHomeApplicationToBoardList] = useMutation(mutations.ADD_HOME_APPLICATIONS_TO_BOARD_LIST, {
    onCompleted: () => navigate(`/applications/requests?listing=${listingId}`),
    // eslint-disable-next-line no-console
    onError: (e) => console.log(e),
  })
  const ongoingColumn = columnData?.home?.applicationBoardLists[1].id

  if (errorRemoved) {
    return null
  }

  const handleFilterOrSort = (newFilters: RemovedListFilterOrSortParams) => {
    refetch({ ...newFilters, homeApplicationsAfter: null, homeApplicationsFirst: INITIAL_REQUEST_COUNT })
  }
  const handleSearch = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { value: searchString } = event.target
    setSearchString(searchString)
    refetch({
      homeApplicationsSearchString: searchString,
      homeApplicationsAfter: null,
      homeApplicationsFirst: INITIAL_REQUEST_COUNT,
    })
  }
  const handleMoveFromDeclined = (id: string) => {
    addHomeApplicationToBoardList({
      variables: { applicationBoardListId: ongoingColumn, homeApplicationIds: [id] },
      // eslint-disable-next-line no-console
    })
  }

  return (
    <>
      <Input
        type="search"
        name="search"
        width="300px"
        value={searchString}
        inputFocus="true"
        onChange={handleSearch}
        placeholder={t('applications_board.search_placeholder')}
      />
      <Spacer size="4x" />
      <RemovedActions ongoingColumn={ongoingColumn} onFilterOrSort={handleFilterOrSort} />
      <ListContainer onScroll={handleScroll}>
        {removedData?.homeApplications.edges.map((application) => {
          return (
            <RemovedApplicantCard
              key={application.node.id}
              data={application.node}
              handleMoveFromDeclined={handleMoveFromDeclined}
              applicationBoardCardInfo={columnData?.home?.applicationBoardCardInfo}
            />
          )
        })}
        {(isLoadingRemoved || isFetchingMore) && <LoadingDots />}
        <Spacer size="12x" />
      </ListContainer>
    </>
  )
}
