import { Trans, useTranslation } from 'react-i18next'
import { Avatar, Heading, Paragraph, Stack } from '@qasa/qds-ui'
import styled from '@emotion/styled'

import { useApplicantContext } from '../../context/applicant-context'
import { PlatformEnum } from '../../data/graphql/types/__generated__/globalTypes'
import { useUserContext } from '../../context/user-context'

const Wrapper = Stack
const BoldSpan = styled.span({
  fontWeight: 600,
})

const getPlatformDisplayName = (platform: PlatformEnum) => {
  return platform === PlatformEnum.blocket ? 'Blocket' : 'Qasa'
}

export function ApplicantNameCard() {
  const { t } = useTranslation('applications', {
    keyPrefix: 'applicant_card',
  })
  const { applicantData } = useApplicantContext()
  const { authBody } = useUserContext()

  if (!applicantData) {
    return null
  }

  const { firstName, profilePicture, age, mainPlatform } = applicantData.tenant
  const familyName = applicantData.tenantFamilyName
  const name = [firstName, familyName].filter(Boolean).join(' ')
  const isProAgent = authBody?.isProAgent

  return (
    <Wrapper direction="row" gap="5x" alignItems="center">
      <Avatar size="lg" src={profilePicture?.url} />
      <div>
        <Stack direction="row" gap="3x" alignItems="center">
          <Heading size="sm">{name}</Heading>
          {age && <Paragraph>{t('age', { age })}</Paragraph>}
        </Stack>
        {isProAgent && (
          <Paragraph size="sm">
            <Trans
              t={t}
              i18nKey="main_platform_label"
              values={{ platform: getPlatformDisplayName(mainPlatform) }}
              components={[<BoldSpan key="0" />]}
            />
          </Paragraph>
        )}
      </div>
    </Wrapper>
  )
}
