import { Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { useListingContext } from '../../context/listing-context'
import { formatBlockSizes, formatBasicInfo } from '../../util-functions/general.util'
import { getListingSpans } from '../listing/listing.util'
import { BaseInfoItem } from '../listing/base-info-item'
import { getContractType } from '../../util-functions/listing.util'

import { BaseInfoList } from './apartment-details'

export function ApartmentDetailsBlock() {
  const { home } = useListingContext()
  const {
    rent,
    maxTenantCount,
    bathroomRenovationYear,
    kitchenRenovationYear,
    seniorHome: isSeniorHome,
    firsthand: isFirsthand,
    studentHome: isStudentHome,
    corporateHome: isCorporateHome,
  } = home || {}
  const listingSpans = getListingSpans({ home })

  const { t } = useTranslation(['apartment', 'commons'])

  const labelsTranslationPrefix = 'apartment_form'
  const fields = [
    {
      heading: t(`${labelsTranslationPrefix}.rent_label`),
      value: `${formatBasicInfo({
        isBlock: true,
        value: rent,
        span: listingSpans.rentSpan,
        unit: 'SEK',
      })}`,
    },
    {
      heading: t(`${labelsTranslationPrefix}.max_tenants_label`),
      value: `${maxTenantCount}`,
    },
    {
      heading: t(`${labelsTranslationPrefix}.size_label`),
      value: `${formatBlockSizes(listingSpans.roomCountSpan, listingSpans.squareMeterSpan)}`,
    },
    {
      heading: t(`${labelsTranslationPrefix}.contract_type`),
      value: `${t(
        `commons:contract_types.${getContractType({
          seniorHome: isSeniorHome,
          firsthand: isFirsthand,
          studentHome: isStudentHome,
          corporateHome: isCorporateHome,
        })}`,
      )}`,
    },
    {
      heading: t(`${labelsTranslationPrefix}.latest_bath_renovation_label`),
      value: bathroomRenovationYear ? `${bathroomRenovationYear}` : t('commons:placeholders.not_filled_in'),
    },
    {
      heading: t(`${labelsTranslationPrefix}.latest_kitchen_renovation_label`),
      value: kitchenRenovationYear ? `${kitchenRenovationYear}` : t('commons:placeholders.not_filled_in'),
    },
  ]

  return (
    <Stack gap="8x">
      <BaseInfoList>
        {fields.map((field) => {
          const { heading, value } = field
          return <BaseInfoItem key={heading} heading={heading} value={value} />
        })}
      </BaseInfoList>
    </Stack>
  )
}
