import { Spacer, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import { EditDialog } from '@qasa/app/src/modules/edit-dialog'

import { BaseInfoItem } from '../listing/base-info-item'
import { formatLocation } from '../../util-functions/general.util'
import { Map } from '../../ui-atoms/map'
import { ListingSectionEnum, useListingContext } from '../../context/listing-context'
import { LeftContainer, RightContainer } from '../listing/building-content'

const LocationWrapper = Stack

export function BuildingLocation() {
  const { t } = useTranslation(['building', 'commons'])
  const { home, setActiveEditSection, setIsEditDialogOpen } = useListingContext()
  const { location } = home || {}

  return (
    <Stack gap="12x" direction="row" justifyContent="space-between">
      <LeftContainer gap="8x">
        <LocationWrapper>
          <BaseInfoItem heading={t('fields.building_address')} value={formatLocation(location!)} />
          <Spacer size="4x" />
          <Map
            width={400}
            isInteractionDisabled={true}
            markers={[
              {
                longitude: location!.longitude!,
                latitude: location!.latitude!,
                dragable: false,
              },
            ]}
          />
        </LocationWrapper>
      </LeftContainer>
      <RightContainer>
        <EditDialog.Trigger
          onClick={() => {
            setIsEditDialogOpen(true)
            setActiveEditSection(ListingSectionEnum.BUILDING_LOCATION)
          }}
        />
      </RightContainer>
    </Stack>
  )
}
