import styled from 'styled-components/macro'
import { useMutation } from '@apollo/client'
import {
  Heading,
  IconButton,
  Spacer,
  TrashIcon,
  createIcon,
  SettingsIcon,
  ChevronDownIcon,
  Divider,
  Stack,
} from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { palette } from '../../../styling'
import { ToolTip } from '../../../ui-atoms/tooltip'
import { Checkbox } from '../../../ui-atoms/checkbox'
import { mutations } from '../../../data/applications-by-board-list'
import type { HomeApplicationOrderEnum } from '../../../data/graphql/types/__generated__/globalTypes'
import { ApplicationBoardListDefaultTypeEnum } from '../../../data/graphql/types/__generated__/globalTypes'
import type { ApplicationBoardLists } from '../../../data/graphql/types/__generated__/ApplicationBoardLists'
import { getPixelAmount } from '../../../styling/pixel-amount'
import { useApplicationsContext } from '../../../context/applications-context'
import type { HomeApplicationsByBoardListVariables } from '../../../data/graphql/types/__generated__/HomeApplicationsByBoardList'
import { ApplicationListSort } from '../application-list-sort'

import { ColumnMenu } from './column-menu'
import { ColumnFilters } from './column-filters'
import { ColumnSettings } from './column-settings'
import { MassMessage } from './mass-message'
import { MoveApplicantMenu } from './move-applicant-menu'
import { ColumnRequirements } from './column-requirements'

export const CheckBoxWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  height: getPixelAmount(6),
})
const HeaderContainer = styled.div({
  width: '100%',
  borderBottom: `1px solid ${palette.blocketGreyLight}`,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: 12,
})

const ActionsContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
})

export const FilterIcon = createIcon({
  displayName: 'FilterIcon',
  viewBox: '0 0 17 17',
  d: 'M1.00427 3.5C1.00427 2.94687 1.45115 2.5 2.00427 2.5H14.0043C14.5574 2.5 15.0043 2.94687 15.0043 3.5C15.0043 4.05313 14.5574 4.5 14.0043 4.5H2.00427C1.45115 4.5 1.00427 4.05313 1.00427 3.5ZM3.00427 8.5C3.00427 7.94688 3.45115 7.5 4.00427 7.5H12.0043C12.5574 7.5 13.0043 7.94688 13.0043 8.5C13.0043 9.05312 12.5574 9.5 12.0043 9.5H4.00427C3.45115 9.5 3.00427 9.05312 3.00427 8.5ZM10.0043 13.5C10.0043 14.0531 9.5574 14.5 9.00427 14.5H7.00427C6.45115 14.5 6.00427 14.0531 6.00427 13.5C6.00427 12.9469 6.45115 12.5 7.00427 12.5H9.00427C9.5574 12.5 10.0043 12.9469 10.0043 13.5Z',
})

const MenuContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
})

const StyledChecBoxWrapper = styled(CheckBoxWrapper)({
  height: 'auto',
})

type ColumnHeaderProps = {
  columnDataConversationIds: string[]
  columnDataIds: string[]
  columns: ApplicationBoardLists
  currentColumnId: string
  defaultType: ApplicationBoardListDefaultTypeEnum | null
  isDefaultColumn: boolean
  isEmpty: boolean
  noApplications?: number
  refetchColumns: () => void
  refetchColumnData: (newVariables: Partial<HomeApplicationsByBoardListVariables>) => void
  title?: string
  totNoOfApplications?: number
}

const pending = ApplicationBoardListDefaultTypeEnum.pending

export function ColumnHeader({
  columnDataConversationIds,
  columnDataIds,
  columns,
  currentColumnId,
  defaultType,
  isDefaultColumn,
  isEmpty,
  noApplications,
  refetchColumns,
  refetchColumnData,
  title,
  totNoOfApplications,
}: ColumnHeaderProps) {
  const { t } = useTranslation('applications', {
    keyPrefix: 'column_header',
  })

  const {
    selectedApplicantIds,
    selectedColumnId,
    updateColumnSelection,
    resetColumnSelection,
    pendingColumnSettings: { shouldFulfillRequirements },
  } = useApplicationsContext()

  const activeRequirement = shouldFulfillRequirements !== undefined ? t('meets_requirements') : t('all')

  const columnTitle = title || t('incoming')
  const numberOfApplications = noApplications || 0
  const inContactColumn = columns.home?.applicationBoardLists[1].id || ''
  const isPending = defaultType === pending

  const isHeaderActionsActive = selectedApplicantIds.length > 0 && selectedColumnId === currentColumnId

  const [addHomeApplicationToBoardList] = useMutation(mutations.ADD_HOME_APPLICATIONS_TO_BOARD_LIST, {
    refetchQueries: ['HomeApplicationsByBoardList'],
  })
  const [removeHomeApplicationFromBoardList] = useMutation(
    mutations.REMOVE_HOME_APPLICATIONS_FROM_BOARD_LIST,
    {
      refetchQueries: ['HomeApplicationsByBoardList'],
    },
  )

  const handleMultiMove = (columnId: string) => {
    addHomeApplicationToBoardList({
      variables: { applicationBoardListId: columnId, homeApplicationIds: selectedApplicantIds },
    })
    resetColumnSelection()
  }

  const handleMultiDecline = () => {
    removeHomeApplicationFromBoardList({ variables: { homeApplicationIds: selectedApplicantIds } })
    resetColumnSelection()
  }

  const handleSelectColumn = () => {
    if (selectedColumnId === currentColumnId) {
      resetColumnSelection()
    } else {
      if (columnDataIds && columnDataConversationIds) {
        updateColumnSelection({
          applicantIds: columnDataIds,
          conversationIds: columnDataConversationIds,
          columnId: currentColumnId,
        })
      }
    }
  }

  const handleSort = (newSort: HomeApplicationOrderEnum) => {
    refetchColumnData({ orderBy: newSort })
  }
  const numberOfApplicationsString = isPending
    ? `${numberOfApplications}/${totNoOfApplications}`
    : `${numberOfApplications}`
  return (
    <HeaderContainer>
      <ActionsContainer>
        <StyledChecBoxWrapper
          style={{ height: 'auto' }}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            handleSelectColumn()
          }}
        >
          <Checkbox
            name={'multiCheckbox'}
            isChecked={Boolean(selectedApplicantIds.length) && currentColumnId === selectedColumnId}
          />
        </StyledChecBoxWrapper>
        <Spacer axis="x" size="2x" />
        <Heading size="xs">{`${columnTitle} (${numberOfApplicationsString})`}</Heading>
        <Spacer axis="x" size="3x" />
        {!isHeaderActionsActive && isPending && (
          <ColumnMenu
            Icon={<IconButton icon={ChevronDownIcon} label="Column Requirements" size="xs" />}
            label={activeRequirement}
            width={'200px'}
          >
            <ColumnRequirements />
          </ColumnMenu>
        )}
        {isHeaderActionsActive && (
          <>
            <ToolTip content={t('remove')} isDisabled={!isHeaderActionsActive}>
              <IconButton icon={TrashIcon} label={t('remove')} onClick={handleMultiDecline} size="xs" />
            </ToolTip>
            <MassMessage
              isInContact={!isPending}
              inContactColumn={inContactColumn}
              isHeaderActionsActive={isHeaderActionsActive}
            />
            <MoveApplicantMenu
              currentColumnId={currentColumnId}
              columns={columns}
              handleMove={handleMultiMove}
              isActive={isHeaderActionsActive}
            />
          </>
        )}
      </ActionsContainer>
      <MenuContainer>
        {!isDefaultColumn && (
          <ColumnMenu Icon={<IconButton icon={SettingsIcon} label="Settings modal" size="xs" />}>
            <ColumnSettings
              columnId={currentColumnId}
              refetch={refetchColumns}
              isEmpty={isEmpty}
              columnName={columnTitle}
            />
          </ColumnMenu>
        )}
        <ColumnMenu Icon={<IconButton icon={FilterIcon} label="Filters" size="xs" />}>
          <Stack divider={<Divider />} gap="3x">
            <ApplicationListSort columnKey={currentColumnId} onSort={handleSort} />
            {isPending && <ColumnFilters />}
          </Stack>
        </ColumnMenu>
      </MenuContainer>
    </HeaderContainer>
  )
}
