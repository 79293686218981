import { useApplicantContext } from '../../../context/applicant-context'
import { LandlordPreview } from '../../listing/landlord-preview'

export function ContractPreviewLandlord() {
  const { applicantData } = useApplicantContext()
  const { home } = applicantData!

  const { firstName, profilePicture, private: privateFields } = home?.landlord || {}

  const fullName = `${firstName} ${privateFields?.familyName ?? ''}`

  return (
    <LandlordPreview
      fullName={fullName}
      profilePictureUrl={profilePicture?.url}
      email={privateFields?.email}
      phoneNumber={privateFields?.phoneNumber}
    />
  )
}
