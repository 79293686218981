import { Stack } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import type { ListingQuery_home } from '../../data/graphql/types/__generated__/ListingQuery'
import { BaseInfoItem } from '../listing/base-info-item'
import { getContractType } from '../../util-functions/listing.util'

export const BaseInfoList = styled.ul(({ theme }) => ({
  listStyle: 'none',
  margin: 0,
  padding: 0,
  display: 'grid',
  rowGap: theme.spacing['8x'],
  columnGap: theme.spacing['2x'],
  gridTemplateColumns: 'repeat(2,1fr)',
  [theme.mediaQueries.mdUp]: {
    gridTemplateColumns: 'repeat(3,1fr)',
  },
}))

type Props = Pick<
  ListingQuery_home,
  | 'rent'
  | 'tenantCount'
  | 'squareMeters'
  | 'roomCount'
  | 'kitchenRenovationYear'
  | 'bathroomRenovationYear'
  | 'floor'
  | 'buildingFloors'
  | 'seniorHome'
  | 'studentHome'
  | 'firsthand'
  | 'corporateHome'
  | 'apartmentNumber'
>
export function ApartmentDetails({
  rent,
  tenantCount,
  squareMeters,
  roomCount,
  kitchenRenovationYear,
  bathroomRenovationYear,
  floor,
  buildingFloors,
  seniorHome,
  studentHome,
  firsthand,
  corporateHome,
  apartmentNumber,
}: Props) {
  const { t } = useTranslation(['apartment', 'commons'])
  const fieldsTranslationPrefix = 'formatted_fields'
  const labelsTranslationPrefix = 'apartment_form'
  const fields = [
    {
      heading: t(`${labelsTranslationPrefix}.rent_label`),
      value: t(`${fieldsTranslationPrefix}.rent_value`, { rent }),
    },
    {
      heading: t(`${labelsTranslationPrefix}.max_tenants_label`),
      value: `${tenantCount}`,
    },
    {
      heading: t(`${labelsTranslationPrefix}.size_label`),
      value: t(`${fieldsTranslationPrefix}.size_value`, { roomCount, squareMeters }),
    },
    {
      heading: t(`${labelsTranslationPrefix}.apartment_number`),
      value: apartmentNumber || t('commons:placeholders.not_filled_in'),
    },
    {
      heading: t(`${labelsTranslationPrefix}.floor_label`),
      value: floor !== null ? `${floor}` : t('commons:placeholders.not_filled_in'),
    },
    {
      heading: t(`${labelsTranslationPrefix}.building_floors_label`),
      value: buildingFloors !== null ? `${buildingFloors}` : t('commons:placeholders.not_filled_in'),
    },
    {
      heading: t(`${labelsTranslationPrefix}.contract_type`),
      value: `${t(
        `commons:contract_types.${getContractType({ seniorHome, firsthand, studentHome, corporateHome })}`,
      )}`,
    },
    {
      heading: t(`${labelsTranslationPrefix}.latest_bath_renovation_label`),
      value: bathroomRenovationYear ? `${bathroomRenovationYear}` : t('commons:placeholders.not_filled_in'),
    },
    {
      heading: t(`${labelsTranslationPrefix}.latest_kitchen_renovation_label`),
      value: kitchenRenovationYear ? `${kitchenRenovationYear}` : t('commons:placeholders.not_filled_in'),
    },
  ]
  return (
    <Stack gap="8x">
      <BaseInfoList>
        {fields.map((field) => {
          const { heading, value } = field
          return <BaseInfoItem key={heading} heading={heading} value={value} />
        })}
      </BaseInfoList>
    </Stack>
  )
}
