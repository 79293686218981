import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { Paragraph, Stack } from '@qasa/qds-ui'
import { PillButton } from '@qasa/app/src/components/pill-button'

import { SmallHeader } from '../../ui-atoms/small-header'
import { TraitTypeEnum } from '../../data/graphql/types/__generated__/globalTypes'
import { getTraits } from '../../util-functions/traits.util'

import type { FormValues } from './update-home-dialog'

export const CheckboxLine = styled(Stack)({
  flexWrap: 'wrap',
})

export type AmenityCategory =
  | 'kitchen'
  | 'bathroom'
  | 'washing'
  | 'popular'
  | 'technical'
  | 'parking_and_aids'
export const TRAITS = [
  {
    category: 'kitchen',
    traits: [
      TraitTypeEnum.fridge,
      TraitTypeEnum.freezer,
      TraitTypeEnum.oven,
      TraitTypeEnum.stove,
      TraitTypeEnum.dish_washer,
      TraitTypeEnum.microwave_oven,
      TraitTypeEnum.kitchenette,
    ],
  },
  {
    category: 'bathroom',
    traits: [TraitTypeEnum.shower, TraitTypeEnum.toilet, TraitTypeEnum.bathtub],
  },
  {
    category: 'washing',
    traits: [
      TraitTypeEnum.washing_machine,
      TraitTypeEnum.tumble_dryer,
      TraitTypeEnum.shared_washing_area,
      TraitTypeEnum.drying_room,
    ],
  },
  {
    category: 'popular',
    traits: [
      TraitTypeEnum.balcony,
      TraitTypeEnum.french_balcony,
      TraitTypeEnum.patio,
      TraitTypeEnum.inhome_sauna,
      TraitTypeEnum.communal_sauna,
      TraitTypeEnum.jacuzzi,
      TraitTypeEnum.indoor_fireplace,
      TraitTypeEnum.pool,
    ],
  },
  {
    category: 'technical',
    traits: [TraitTypeEnum.internet, TraitTypeEnum.television],
  },
  {
    category: 'parking_and_aids',
    traits: [
      TraitTypeEnum.bike_room,
      TraitTypeEnum.storage,
      TraitTypeEnum.elevator,
      TraitTypeEnum.electric_car_charger,
      TraitTypeEnum.parking_included,
      TraitTypeEnum.parking,
      TraitTypeEnum.recycling,
      TraitTypeEnum.security_door,
      TraitTypeEnum.stroller_room,
    ],
  },
]

export const getTraitsAfterClick = (trait: TraitTypeEnum, previousTraits?: TraitTypeEnum[] | null) => {
  if (previousTraits?.includes(trait)) {
    return previousTraits.filter((traitValue) => traitValue !== trait)
  } else {
    return [...(previousTraits ?? []), trait]
  }
}

export function AmenitiesSection() {
  const { t } = useTranslation('amenities', { keyPrefix: 'categories' })
  const { control, setValue } = useFormContext<FormValues>()

  return (
    <Controller
      name="traits"
      control={control}
      render={({ field: { value } }) => {
        return (
          <Stack gap={'6x'}>
            {TRAITS.map(({ category, traits }) => (
              <Stack gap={'4x'} key={category}>
                <SmallHeader>{t(category as AmenityCategory)}</SmallHeader>
                <CheckboxLine direction="row" gap={'2x'}>
                  {traits.map((trait) => (
                    <PillButton
                      key={trait}
                      size="small"
                      type="button"
                      onPress={() => {
                        const newTraits = getTraitsAfterClick(trait, value)
                        setValue('traits', newTraits)
                      }}
                      isActive={Boolean(value?.includes(trait))}
                    >
                      <Paragraph size="sm">{getTraits(trait)}</Paragraph>
                    </PillButton>
                  ))}
                </CheckboxLine>
              </Stack>
            ))}
          </Stack>
        )
      }}
    />
  )
}
