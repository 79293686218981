import { createContext, useContext } from 'react'

import type { RadioGroupContextValue } from './radio-group.types'

export const RadioGroupContext = createContext<RadioGroupContextValue | undefined>(undefined)

export const useRadioGroupContext = () => {
  const context = useContext(RadioGroupContext)

  if (context === undefined) {
    throw Error('Radio components must be used within a RadioGroup')
  }

  return context
}
