import { Link } from '@qasa/qds-ui'
import { Link as RouterLink } from 'react-router-dom'
import i18next from 'i18next'

import type { ApartmentsQuery_homeTemplates } from '../../../data/graphql/types/__generated__/ApartmentsQuery'
import { HomeStatusEnum } from '../../../data/graphql/types/__generated__/globalTypes'
import { Checkbox } from '../../../ui-atoms/checkbox'
import type { TableRowType } from '../../../ui-atoms/table'
import { TableText } from '../../../ui-atoms/table'
import buildingPlaceholder from '../../../assets/placeholders/building.png'
import { formatApartmentSize, formatLocation } from '../../../util-functions/general.util'
import { formatDate } from '../../../util-functions/date.util'

import {
  Address,
  ApartmentImageWrapper,
  ApartmentImage,
  ApartmentNumber,
  BuildingName,
  LastUpdate,
  ListedStatusIndicator,
  ListingStatus,
  Size,
} from './apartment-table-components'

export const constructApartmentsTableRows = (
  checkCallBack: (apartmentId: string) => void,
  chosenApartments: Array<string>,
  apartments?: ApartmentsQuery_homeTemplates,
  isLoading?: boolean,
): TableRowType[] => {
  if (isLoading) {
    return Array<TableRowType>(20)
      .fill({ id: '', row: [] })
      .map((_, index) => ({
        id: Math.random().toString(),
        row: [
          // TODO: Remove disabling of eslint rule
          /* eslint-disable react/jsx-key */
          <ApartmentImageWrapper isLoading={true} />,
          <ApartmentNumber isLoading={true} />,
          <Size isLoading={true} />,
          <Address isLoading={true} />,
          <BuildingName isLoading={true} />,
          <ListingStatus isLoading={true} />,
          <LastUpdate isLoading={true} />,
          /* eslint-enable react/jsx-key */
        ],
      }))
  } else {
    if (apartments) {
      const rows = apartments.edges.map(({ node: apartment }) => {
        const temp = apartment.publishedHomes.filter((home) => home.status === HomeStatusEnum.normal)
        const isListed = Boolean(temp && temp.length > 0)
        //TODO -> replace with an apartment placeholder instead of building
        const apartmentImageUrl = apartment.uploads[0]?.url ? apartment.uploads[0]?.url : buildingPlaceholder
        const handleCheckboxClick = (event: React.MouseEvent<HTMLDivElement>) => {
          event.stopPropagation()
          if (isListed) {
            alert(i18next.t('my_homes:apartments_table.already_published_listing_warning'))
          } else {
            checkCallBack(apartment.id)
          }
        }
        const apartmentImage = (
          <ApartmentImageWrapper onClick={handleCheckboxClick}>
            <Checkbox
              name={apartment.id}
              isChecked={chosenApartments.includes(apartment.id)}
              inactive={isListed}
            />
            <ApartmentImage src={apartmentImageUrl} alt="apartment-image" />
          </ApartmentImageWrapper>
        )

        const apartmentNumber = (
          <ApartmentNumber>
            <TableText>{apartment.apartmentNumber}</TableText>
          </ApartmentNumber>
        )

        const size =
          apartment.roomCount && apartment.squareMeters
            ? formatApartmentSize(apartment.roomCount, apartment.squareMeters)
            : i18next.t('my_homes:apartments_table.unavailable_info_warning')
        const apartmentSize = <Size>{size}</Size>

        const building = (
          <BuildingName>
            <TableText>
              {apartment.building?.buildingName ?? i18next.t('my_homes:apartments_table.no_building_info')}
            </TableText>
          </BuildingName>
        )

        const address = <Address>{formatLocation(apartment.location!)}</Address>

        const listedStatus = isListed ? (
          <ListingStatus $isListed>
            <ListedStatusIndicator />
            <TableText>{i18next.t('my_homes:apartments_table.has_live_listing')}</TableText>
          </ListingStatus>
        ) : (
          <ListingStatus onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
            <Link as={RouterLink} to={`/listings/add/${apartment?.id}`}>
              {i18next.t('commons:actions.publish_listing')}
            </Link>
          </ListingStatus>
        )

        const lastUpdated = <LastUpdate>{formatDate(new Date(apartment.updatedAt))}</LastUpdate>

        return {
          id: apartment.id,
          row: [apartmentImage, apartmentNumber, apartmentSize, address, building, listedStatus, lastUpdated],
        }
      })

      return rows
    }
  }
  return []
}
