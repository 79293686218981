import { Stack, Divider } from '@qasa/qds-ui'

import { useListingContext } from '../../context/listing-context'
import { useUserContext } from '../../context/user-context'
import { EditListing } from '../edit-listing/edit-listing'

import { RentalDetails } from './rental-details'
import { Images } from './images'
import { ApartmentContent } from './apartment-content'
import { ApartmentContentBlockListing } from './apartment-content-block-listing'
import { BuildingContent } from './building-content'
import { ShortcutDetails } from './shortcut-details'
import { LeaseContent } from './lease-content'

const Wrapper = Stack
const ListingSection = Stack
const ShortcutSection = Stack

export function ListingContent() {
  const { isBlockListing } = useListingContext()
  const { authBody } = useUserContext()
  const isProAgent = authBody?.isProAgent

  return (
    <>
      <Wrapper gap="12x" divider={<Divider />}>
        {isProAgent && (
          <ShortcutSection>
            <ShortcutDetails />
          </ShortcutSection>
        )}
        <ListingSection gap="12x">
          <RentalDetails />
          <Images />
        </ListingSection>
        <ListingSection>
          {isBlockListing ? <ApartmentContentBlockListing /> : <ApartmentContent />}
        </ListingSection>
        <ListingSection>
          <BuildingContent />
        </ListingSection>
        {isProAgent && (
          <ListingSection>
            <LeaseContent />
          </ListingSection>
        )}
      </Wrapper>
      <EditListing />
    </>
  )
}
