import { useQuery } from '@apollo/client'
import { useReducer } from 'react'
import styled from 'styled-components/macro'
import { useTranslation, Trans } from 'react-i18next'

import type { ConnectedUids } from '../../data/graphql/types/__generated__/ConnectedUids'
import { fontSize } from '../../styling'
import { basePalette, palette } from '../../styling/colors'
import { ExpandButton } from '../../ui-atoms/expand-button'
import { MediumHeader, HeaderText } from '../../ui-atoms/header-text'
import { Icon } from '../../ui-shared/_core/icon'
import { LoadingDots } from '../../ui-atoms/loader'
import { Stack } from '../../ui-shared/_core/stack'
import { useUserContext } from '../../context/user-context'

import { CONNECTED_UIDS } from './iframe.gql'

const SUPPORT_EMAIL = 'pro@bostad.blocket.se'

const CopyButton = styled.button({
  alignSelf: 'flex-end',
  width: 'fit-content',
  backgroundColor: basePalette.white,
  //color: basePalette.white,
  cursor: 'pointer',
  padding: 5,
  borderRadius: 4,
  border: 'none',
  fontSize: fontSize.textSm,
})
const CodeBlock = styled(Stack)({
  border: `1px solid ${basePalette.black100}`,
  padding: 10,
  borderRadius: 10,
  width: 'fit-content',
})
const InfoText = styled.p({
  fontSize: fontSize.textBase,
  maxWidth: 800,
})
const MailLink = styled.a({
  textDecoration: 'underline',
  color: palette.secondary,
})

export function IframeInformation() {
  const { authBody } = useUserContext()
  const {
    data,
    loading: isLoading,
    error,
  } = useQuery<ConnectedUids>(CONNECTED_UIDS, {
    variables: {
      uid: authBody?.uid,
    },
  })
  const [isOpen, onToggleOpen] = useReducer((old) => !old, false)
  const { t } = useTranslation('settings', {
    keyPrefix: 'iframe_information',
  })

  const uids = data?.proPilotConnectedUids.join(',')
  const url = `https://bostad.blocket.se/sv/embedded-find-home?uids=${uids}`
  const iframeCode = `<iframe title="bbq" width="100%" height="100%" src="${url}" frameBorder="0" />`

  const copyToClipboard = () => {
    navigator.clipboard.writeText(iframeCode).then(() => {
      alert(t('on_copy_alert'))
    })
  }
  if (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
  if (isLoading) return <LoadingDots />
  return (
    <Stack gap={'medium'} align={'flex-start'}>
      <HeaderText>{t('title')}</HeaderText>
      <InfoText>
        <Trans t={t} i18nKey={'iframe_description'} values={{ email: SUPPORT_EMAIL }}>
          <MailLink href={`mailto:${SUPPORT_EMAIL}`} />
        </Trans>
      </InfoText>

      <ExpandButton {...{ isOpen, onToggleOpen }} title={t('show_iframe_use_introduction')} />
      {isOpen && (
        <Stack gap={'small'} align={'flex-start'}>
          <MediumHeader>{t('iframe_introduction_title')}</MediumHeader>
          <InfoText>{t('instructions', { name: authBody?.firstName })}</InfoText>
          <CodeBlock gap={0} direction={'column'}>
            <CopyButton onClick={copyToClipboard}>
              <Icon icon="copy" color="default" />
            </CopyButton>
            <pre>
              <code id="content">{t('iframe_code', { link: url })}</code>
            </pre>
          </CodeBlock>
        </Stack>
      )}
    </Stack>
  )
}
