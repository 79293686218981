import { Stack, Paragraph } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { addDays } from 'date-fns'
import { Popover } from '@qasa/app/web'

import { ErrorMessage } from '../../ui-shared/_core/error-message'
import { formatDate, formatDateYYYYMMDD } from '../../util-functions/date.util'
import type { UpdateHomeInput } from '../../data/graphql/types/__generated__/globalTypes'
import { Radio } from '../../ui-atoms/radio'
import { DatePicker } from '../../ui-atoms/date-picker'

import { InputRow, InputHeader } from './input-grid-styles'

const START_LATEST_INPUT_NAME = 'duration.durationPreference.startLatest'

const LatestMoveInHeaderRow = styled(Stack)({
  gridColumn: '1 / 3',
})
const StyledPopoverContent = styled(Popover.Content)({
  '&&': {
    width: 'fit-content',
  },
})

type LatestMoveInDatePickerProps = {
  startOptimal: Date | null
}

export function LatestMoveInDatePicker({ startOptimal }: LatestMoveInDatePickerProps) {
  const { t } = useTranslation(['edit_listing'])
  const { control, setValue, getFieldState, formState, register } = useFormContext<UpdateHomeInput>()
  const [isStartLatestOpen, setIsStartLatestOpen] = useState(false)

  if (!startOptimal) {
    return null
  }

  const earliestPossibleDate = addDays(startOptimal, 1)
  const handleOpenDatePicker = (currentValue?: string | null) => {
    if (!currentValue) {
      setValue(START_LATEST_INPUT_NAME, earliestPossibleDate.toISOString())
    }
    setIsStartLatestOpen(true)
  }

  const { error: startLatestError } = getFieldState(START_LATEST_INPUT_NAME, formState)

  register(START_LATEST_INPUT_NAME, {
    validate: (value, formValues) => {
      const endOptimalValue = formValues?.duration?.endOptimal

      if (value && endOptimalValue) {
        return value < endOptimalValue || 'The latest move in date must be before the move out date'
      }

      return true
    },
  })

  return (
    <Stack gap="1x">
      <InputRow isRowsFirst>
        <LatestMoveInHeaderRow direction="row" justifyContent="space-between">
          <InputHeader isFullWidth size="2xs">
            {t('basic_listing_information.latest_move_in_date')}
          </InputHeader>
          <Paragraph>{t('basic_listing_information.optional')}</Paragraph>
        </LatestMoveInHeaderRow>
        <Controller
          name="duration.durationPreference.startLatest"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <Radio
              name={name}
              title={t('listing:duration_information.no_flexible_move_in_date')}
              isChecked={Boolean(!value)}
              onClick={() => onChange(null)}
            />
          )}
        />
        <Controller
          name="duration.durationPreference.startLatest"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <Popover
              isOpen={isStartLatestOpen}
              onOpenChange={(isOpen) => setIsStartLatestOpen(isOpen)}
              modal={true}
            >
              <Popover.Trigger>
                <button>
                  <Radio
                    name={name}
                    title={!value ? t('commons:choose_date') : formatDate(new Date(value))}
                    isChecked={Boolean(value)}
                    onClick={() => handleOpenDatePicker(value)}
                    icon={<FontAwesomeIcon icon={['fal', 'calendar-alt']} />}
                  />
                </button>
              </Popover.Trigger>
              <StyledPopoverContent>
                <Popover.Body>
                  <DatePicker
                    onChange={(date) => {
                      setIsStartLatestOpen(false)
                      onChange(formatDateYYYYMMDD(new Date(date.date)))
                    }}
                    shouldProhibitPastDate
                    activeDate={value ? new Date(value) : undefined}
                    customAvailableStartDay={earliestPossibleDate}
                  />
                </Popover.Body>
              </StyledPopoverContent>
            </Popover>
          )}
        />
      </InputRow>
      {startLatestError && <ErrorMessage>{startLatestError.message}</ErrorMessage>}
    </Stack>
  )
}
