import styled from 'styled-components/macro'
import { Heading, Stack } from '@qasa/qds-ui'

import { pixelAmount, placeholder } from '../../../styling'

type UserProps = {
  name: string
  avatar?: string | undefined
}

const Avatar = styled.img({
  width: pixelAmount.xxl,
  aspectRatio: '1/1',
  borderRadius: '50%',
  marginRight: pixelAmount.md,
})

export function UserName(props: UserProps) {
  const { name, avatar } = props
  return (
    <Stack gap="1x" direction="row" alignItems="center">
      {props.avatar ? <Avatar src={avatar} /> : <Avatar src={placeholder.avatar} />}
      <Heading size="2xs"> {name}</Heading>
    </Stack>
  )
}
