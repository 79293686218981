import { placeholder } from '../styling'

const spacingWords = (word: string) => {
  return word.replace(/_/g, ' ').trim()
}

const capitalizeWord = (word: string) => {
  const wordTemp = word.charAt(0).toUpperCase() + word.substring(1)
  return wordTemp.trim()
}

const cleanupWord = (word: string) => {
  return capitalizeWord(spacingWords(word.trim()).trim())
}

const ceilPercent = (score: number | undefined): string => {
  return `${Math.ceil((score || placeholder.zero) / 100)}%`
}

const formatNumber = (x: number | string) => {
  const replaceString = typeof x === 'number' ? x.toString() : x
  // Ok to disable this lint rule since it's only run on the client
  // eslint-disable-next-line security/detect-unsafe-regex
  return replaceString.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export { cleanupWord, capitalizeWord, spacingWords, ceilPercent, formatNumber }
