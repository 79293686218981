import styled from 'styled-components/macro'
import { Stack } from '@qasa/qds-ui'

import { fontSize } from '../../../styling'
import { Checkbox } from '../../../ui-atoms/checkbox'
import { Status, VerificationBadge } from '../../../ui-atoms/verification-badge'

import type { BoardSettingsEnum } from './board-settings'

const CheckBoxWrapper = styled.div({
  display: 'flex',
  cursor: 'pointer',
})
const Text = styled.p({
  fontSize: fontSize.textBase,
  fontWeight: 600,
})

type BoardSettingProps = {
  value: string
  okLabel?: string
  undefLabel?: string
  badLabel?: string
  isChecked: boolean
  type: BoardSettingsEnum
  handleSelectSetting: (type: BoardSettingsEnum) => void
  isDisabled?: boolean
}

export function BoardSetting({
  value,
  handleSelectSetting,
  okLabel,
  undefLabel,
  badLabel,
  isChecked,
  isDisabled,
  type,
}: BoardSettingProps) {
  return (
    <Stack gap="2x">
      <Stack direction="row" gap="2x">
        <CheckBoxWrapper
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            handleSelectSetting(type)
          }}
        >
          <Checkbox name={value} isChecked={isChecked} inactive={isDisabled} />
        </CheckBoxWrapper>
        <Text>{value}</Text>
      </Stack>
      <Stack gap="2x">
        {okLabel && <VerificationBadge details={okLabel} status={Status.Positive} label={value} />}
        {undefLabel && <VerificationBadge details={undefLabel} status={Status.Default} label={value} />}
        {badLabel && <VerificationBadge details={badLabel} status={Status.Negative} label={value} />}
      </Stack>
    </Stack>
  )
}
