import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

import type { UpdateHomeInput } from '../../data/graphql/types/__generated__/globalTypes'
import { Checkbox } from '../../ui-atoms/checkbox'
import { useUserContext } from '../../context/user-context'

export function InstantSignCheckbox() {
  const { t } = useTranslation(['edit_listing'])
  const { authBody } = useUserContext()
  const isProAgent = authBody?.isProAgent

  const { control, setValue } = useFormContext<UpdateHomeInput>()

  if (!isProAgent) {
    return null
  }

  return (
    <Controller
      name="instantSignSettingAttributes.enabled"
      control={control}
      render={({ field: { value, name, onChange } }) => (
        <Checkbox
          name={name}
          label={t('instant_sign.instant_sign_checkbox_label')}
          type="thumb"
          isChecked={Boolean(value)}
          onClick={() => {
            // removing the check should reset the startLatest value
            if (value) {
              setValue('duration.durationPreference.startLatest', null)
            }
            onChange(!value)
          }}
        />
      )}
    />
  )
}
