import { Fragment } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { formatDate, getDaysFromNow } from '../../util-functions/date.util'
import { Spacer } from '../../ui-atoms/help-components'
import { SmallHeader } from '../../ui-atoms/small-header'
import { SmallData } from '../../ui-atoms/small-data'
import { StatisticsBar } from '../../ui-feature-modules/listing/statistics-bar'
import { fontSize, palette, pixelAmount } from '../../styling'
import { useListingContext } from '../../context/listing-context'

type StatsNumberProps = {
  fontSize?: string
  color?: string
}

const Wrapper = styled.div({
  flexGrow: 1,
  overflow: 'auto',
})

const StatsNumberLabel = styled.p({
  fontSize: fontSize.textXs,
  color: palette.textSecondary,
  marginBottom: pixelAmount.md,
})

const StatsNumber = styled.p<StatsNumberProps>(({ fontSize, color }) => ({
  display: 'inline',
  color,
  fontSize,
  fontWeight: 'bold',
  marginRight: pixelAmount.md,
}))

const StatsBarsWrapper = styled.div({
  display: 'flex',
})

const StatsBarWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

export function ListingStats() {
  const { t } = useTranslation('listing', { keyPrefix: 'listing_statistics' })
  const { home, listingStats } = useListingContext()
  const { publishedAt } = home || {}
  const { cumulativePageViews, cumulativeUniquePageViews } = listingStats || {}

  if (!listingStats || !cumulativePageViews || !cumulativeUniquePageViews) return null
  // Defined the properties we want to show as stat bars
  const pageViewSum = cumulativePageViews[cumulativePageViews.length - 1]
  const uniquePageViewsSum = cumulativeUniquePageViews[cumulativeUniquePageViews.length - 1]

  const barProperties = [
    { label: t('visitors'), value: pageViewSum },
    { label: t('unique_visitors'), value: uniquePageViewsSum },
  ]

  // return the value of barProperty with the highest value
  const relativeMax = barProperties.reduce((max, prop) => (max.value > prop.value ? max : prop)).value

  // Include the properties you want to display in the bar chart section. If all stats are 0 we want to display "Statistics are missing"
  const statisticExists = () => barProperties.some((stat) => stat.value !== 0)

  return (
    <Wrapper>
      <Spacer factor={3} />

      <SmallHeader>{t('title')}</SmallHeader>

      <Spacer factor={3} />

      <StatsBarsWrapper>
        {statisticExists() ? (
          <Fragment>
            {barProperties.map((barProp, index) => (
              <StatsBarWrapper key={index}>
                <StatisticsBar value={barProp.value} max={relativeMax} />

                <Spacer factor={2} />

                <StatsNumberLabel>{barProp.label}</StatsNumberLabel>
                <StatsNumber fontSize={fontSize.text2xl} color={palette.blue500}>
                  {barProp.value}
                </StatsNumber>
              </StatsBarWrapper>
            ))}
          </Fragment>
        ) : (
          <SmallData>{t('no_statistics')}</SmallData>
        )}
      </StatsBarsWrapper>

      <Spacer factor={10} />

      <SmallHeader>{t('published')}</SmallHeader>
      <Spacer factor={2} />

      {/* TODO: Don't have published date in data */}
      {publishedAt && (
        <Fragment>
          <StatsNumber fontSize={fontSize.text2xl} color={palette.blue500}>
            {t('days_ago', { count: getDaysFromNow(new Date(publishedAt)) })}
          </StatsNumber>
          <Spacer factor={2} />
        </Fragment>
      )}
      {publishedAt ? (
        <SmallData transparent={true}>{formatDate(new Date(publishedAt))}</SmallData>
      ) : (
        <SmallData transparent={true}>{t('no_publish_date')}</SmallData>
      )}

      <Spacer factor={10} />
    </Wrapper>
  )
}
