import { ContractPage, useContractContext } from '../../context/contract-context'

import { ContractForm } from './contract-form'
import type { ContractFormValues } from './contract-form'

export function ContractEdit() {
  const { contract, openContract, refetchContract } = useContractContext()

  const DEFAULT_CONTRACT_VALUES: ContractFormValues = {
    homeId: contract?.home?.id,
    rent: contract?.rent,
    duration: {
      startOptimal: contract?.duration?.startOptimal,
      endOptimal: contract?.duration?.endOptimal,
      startAsap: contract?.duration?.startAsap,
      endUfn: contract?.duration?.endUfn,
    },
    qasaGuarantee: contract?.qasaGuarantee,
    insurance: contract?.insurance,
    tenureType: contract?.tenureType,
    ownHome: contract?.ownHome,
    canUse: contract?.canUse,
    rentIncludes: contract?.rentIncludes,
    rentExcludes: contract?.rentExcludes,
    landlordHasKeys: contract?.landlordHasKeys,
    smallRepairs: contract?.smallRepairs,
    insuranceResponsibility: contract?.insuranceResponsibility,
    overnightStay: contract?.overnightStay,
    pets: contract?.pets,
    smoker: contract?.smoker,
    furniture: contract?.furniture,
  }
  const handleUpdatedContract = () => {
    refetchContract()
    openContract(ContractPage.PREVIEW)
  }

  return (
    <ContractForm isEdit defaultValues={DEFAULT_CONTRACT_VALUES} onCompletedUpsert={handleUpdatedContract} />
  )
}
