import type { ReactNode, RefObject } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Label, LoadingDots } from '@qasa/qds-ui'
import styled from '@emotion/styled'

import { defaultStyles } from '../styling/defaults'
import { getPixelAmount } from '../styling/pixel-amount'
import { fontSize, palette } from '../styling'

import { VerticalSpacer } from './help-components/spacer'

type TablePropType = {
  isLoading?: boolean
  isFetchMoreLoading?: boolean
  columns?: Array<TableColumnType>
  rows?: Array<TableRowType>
  tableRef?: string
  shouldInteractionsBeDisabled?: boolean
  handleOnScroll?: (event: React.UIEvent<HTMLDivElement>) => void
  scrollRef?: RefObject<HTMLTableElement>
}

type TableColumnType = ReactNode

export type TableRowType = {
  id: string
  row: ReactNode[]
  onClick?: () => void
  isBold?: boolean
  isSelected?: boolean
}

type TableImageCellProps = {
  imageUrl: string
  imageLabel: ReactNode
}

export enum Order {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}

type TableColumnProps = {
  onClick?: () => void
  isOrderable?: boolean
  children: ReactNode
  active?: boolean
  order?: Order
}

export const ColumnWithLoading = styled.div<{ isLoading?: boolean }>(({ isLoading }) => ({
  position: 'relative',
  borderRadius: defaultStyles.borderRadius,
  ...(isLoading && {
    overflow: 'hidden',
    height: '45px',
    ':before': {
      content: '""',
      position: 'absolute',
      height: '100%',
      width: '200%',
      opacity: 0.2,
      background: palette.blocketGreyLight,
      animation: `loading 1s linear infinite`,
    },
    '@keyframes loading': {
      '50%': {
        opacity: 0.8,
      },
    },
  }),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}))

const TableWrapper = styled.div<{ isLoading?: boolean; disabled?: boolean }>(({ isLoading, disabled }) => ({
  paddingBottom: getPixelAmount(4),
  fontSize: fontSize.textSm,
  userSelect: 'none',
  ...((isLoading || disabled) && {
    pointerEvents: 'none',
  }),
  overflow: 'hidden',
}))

const TTable = styled.table({
  textAlign: 'left',
  borderSpacing: 'unset',
  fontWeight: 300,
  whiteSpace: 'nowrap',
  overflow: 'auto',
  height: '100%',
  display: 'block',
})

const TableBody = styled.tbody({})

const TableHeader = styled.thead({
  zIndex: 1,
  ':hover': {
    tr: {
      cursor: 'default',
      backgroundColor: 'transparent',
    },
  },
})

const TableRow = styled.tr<{ isBold?: boolean; isSelected?: boolean }>(
  ({ isBold = false, isSelected = false }) => ({
    height: '60px',
    padding: `0 ${getPixelAmount(6)}`,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: palette.grayAlpha20,
    },
    fontWeight: isBold ? 600 : 500,
    ...(isSelected && {
      backgroundColor: palette.grey20,
    }),
  }),
)

const TableHead = styled.th({
  position: 'sticky',
  top: 0,
  zIndex: 1,
  backgroundColor: palette.white,
  borderBottom: `1px solid ${palette.grey60}`,
  padding: '0' + getPixelAmount(1),
  fontWeight: 400,
  ':first-child': {
    paddingLeft: getPixelAmount(4),
  },
  ':last-child': {
    paddingRight: getPixelAmount(4),
  },
})

const TableData = styled.td({
  borderBottom: `1px solid ${palette.grey60}`,
  padding: '0 ' + getPixelAmount(2),
  ':first-child': {
    paddingLeft: getPixelAmount(4),
  },
  ':last-child': {
    paddingRight: getPixelAmount(4),
  },
})

const StyledTableColumn = styled.div<Partial<TableColumnProps>>(({ isOrderable }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '20px',
  fontWeight: 600,
  ...(isOrderable && {
    cursor: 'pointer',
    svg: {
      transition: 'transform 0.2s',
    },
    '&:hover': {
      textDecoration: 'underline',
      // svg: {
      //   transform: 'translateY(3px)',
      // },
    },
  }),
}))

const TableDataLeftAligned = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const TableImage = styled.img({
  width: '60px',
  height: '40px',
  objectFit: 'cover',
  borderRadius: defaultStyles.borderRadius,
})

export const TableText = styled.p({})

const SortIconWrapper = styled.div({
  position: 'relative',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
})

const SortIconPart = styled(FontAwesomeIcon)({
  position: 'absolute',
})
const LoadingWrapper = styled.div(({ theme }) => ({
  display: 'grid',
  placeItems: 'center',
  padding: theme.spacing['3x'],
}))

export function TableImageCell(props: TableImageCellProps) {
  return (
    <TableDataLeftAligned>
      <TableImage src={props.imageUrl} alt="building" />
      <VerticalSpacer factor={1} />
      {props.imageLabel}
    </TableDataLeftAligned>
  )
}

export function TableColumn(props: TableColumnProps) {
  const { order = Order.DESCENDING, isOrderable = true } = props
  return (
    <StyledTableColumn
      {...(props.onClick && { onClick: props.onClick })}
      isOrderable={props.onClick && isOrderable ? true : false}
    >
      <Label>{props.children}</Label>
      {props.onClick && isOrderable && (
        <>
          <VerticalSpacer factor={1} />
          <SortIconWrapper>
            <SortIconPart icon={['fal', 'sort']} />
            {props.active && (
              <>
                {order === Order.ASCENDING && <SortIconPart icon={['fas', 'sort-down']} />}
                {order === Order.DESCENDING && <SortIconPart icon={['fas', 'sort-up']} />}
              </>
            )}
          </SortIconWrapper>
        </>
      )}
    </StyledTableColumn>
  )
}

export function Table(props: TablePropType) {
  const navigate = useNavigate()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const hasListing = params.get('listing') ? true : false
  const listing = hasListing ? 'listing=' + params.get('listing')?.toString() + '&' : ''

  if (!props.rows) return null

  return (
    <TableWrapper isLoading={Boolean(props.isLoading)} disabled={Boolean(props.shouldInteractionsBeDisabled)}>
      <TTable width="100%" onScroll={props.handleOnScroll} ref={props.scrollRef}>
        <TableHeader>
          <TableRow>
            {props.columns?.map((column, index) => <TableHead key={index}>{column}</TableHead>)}
          </TableRow>
        </TableHeader>
        <TableBody>
          {props.rows?.map((rowData) => (
            <TableRow
              key={rowData?.id}
              isBold={rowData?.isBold}
              isSelected={rowData.isSelected}
              onClick={() =>
                rowData.onClick
                  ? rowData.onClick()
                  : hasListing
                    ? navigate(location.pathname + '?' + listing + 'applicant=' + rowData?.id)
                    : navigate(props.tableRef + '/' + rowData?.id)
              }
            >
              {rowData?.row?.map((tableData, index) => {
                return <TableData key={index}>{tableData}</TableData>
              })}
            </TableRow>
          ))}
        </TableBody>
      </TTable>
      {props.isFetchMoreLoading && (
        <LoadingWrapper>
          <LoadingDots />
        </LoadingWrapper>
      )}
    </TableWrapper>
  )
}
