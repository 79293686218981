import { styled } from '@qasa/ui/web'

import { Link } from '../../components/link'
import type { RouteName } from '../../navigation'

import type { InlineLinkParagraphProps, InlineLinkProps } from './inline-link.types'

const StyledLink = styled(Link)<InlineLinkParagraphProps>(
  ({ theme, color = 'default', size = 'md', textAlign }) => ({
    color: theme.colors.text[color],
    textAlign,
    ...theme.typography.body[size],
  }),
)
export function InlineLink<T extends RouteName>(props: InlineLinkProps<T>) {
  return <StyledLink {...props} />
}
