import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { Divider, Stack } from '@qasa/qds-ui'

import { Spacer } from '../../../ui-atoms/help-components'
import { defaultStyles } from '../../../styling/defaults'
import {
  ApplicationBoardListDefaultTypeEnum,
  HomeApplicationOrderEnum,
  TenantVerificationEnum,
} from '../../../data/graphql/types/__generated__/globalTypes'
import { fontSize, palette, pixelAmount } from '../../../styling'
import { queries } from '../../../data/applications-by-board-list'
import type {
  ApplicationBoardLists,
  ApplicationBoardLists_home_applicationBoardCardInfo,
  ApplicationBoardLists_home_applicationBoardLists,
} from '../../../data/graphql/types/__generated__/ApplicationBoardLists'
import type {
  HomeApplicationsByBoardList,
  HomeApplicationsByBoardListVariables,
} from '../../../data/graphql/types/__generated__/HomeApplicationsByBoardList'
import { LoadingDots } from '../../../ui-shared/loading-dots'
import { useApplicationsContext } from '../../../context/applications-context'
import { useLocalStorage } from '../../../util-functions/use-local-storage'
import { useFetchMoreOnScrollToBottom } from '../../../hooks/use-fetch-more-on-scroll-to-bottom'

import { ColumnHeader } from './column-header'
import { ApplicantCard } from './applicant-card'

const DEFAULT_BULK_COUNT = 50

const ColumnContainer = styled.div<{ isLast?: boolean }>(({ isLast }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '400px',
  height: '100%',
  borderRight: isLast ? 'none' : `1px solid ${palette.blocketGreyLight}`,
}))
const ColumnContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '8px',
  overflowY: 'auto',
  overflowX: 'hidden',
})
const Placeholder = styled.div({
  display: 'flex',
  padding: `${pixelAmount.lg}`,
  backgroundColor: palette.white,
  height: 'fit-content',
  borderRadius: defaultStyles.borderRadius,
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: fontSize.textSm,
  fontWeight: 400,
})

type ApplicationsColumnProps = {
  data: ApplicationBoardLists_home_applicationBoardLists
  applicationBoardCardInfo: ApplicationBoardLists_home_applicationBoardCardInfo | null | undefined
  columns: ApplicationBoardLists
  refetchColumns: () => void
  searchString: string
}

const ongoing = ApplicationBoardListDefaultTypeEnum.ongoing
const pending = ApplicationBoardListDefaultTypeEnum.pending
const completed = ApplicationBoardListDefaultTypeEnum.completed

export function ApplicationsColumn({
  data,
  columns,
  refetchColumns,
  applicationBoardCardInfo,
  searchString,
}: ApplicationsColumnProps) {
  const { t } = useTranslation(['applications', 'applicant'])
  const [isLoadingData, setLoadingData] = useState<boolean>(false)

  const currentColumnId = data.id
  const [orderBy] = useLocalStorage<HomeApplicationOrderEnum>(
    `applicationsColumnSort::${currentColumnId}`,
    HomeApplicationOrderEnum.BEST_MATCH,
  )

  const {
    pendingColumnSettings: { incomeRange, filters, shouldFulfillRequirements },
  } = useApplicationsContext()
  const hasIncomeFilter = filters.includes(TenantVerificationEnum.income_verified)
  const isPending = data.defaultType === pending
  const name = data.name
    ? data.name
    : data.defaultType === pending
      ? t('applicant:applicant_actions.incoming')
      : data.defaultType === ongoing
        ? t('applicant:applicant_actions.ongoing')
        : t('applicant:applicant_actions.assigned')

  const {
    data: columnData,
    loading: isLoading,
    refetch,
    fetchMore,
  } = useQuery<HomeApplicationsByBoardList, HomeApplicationsByBoardListVariables>(
    queries.GET_APPLICATION_BY_BOARD_LIST,
    {
      variables: {
        homeApplicationsByBoardListId: currentColumnId,
        orderBy,
        first: DEFAULT_BULK_COUNT,
        ...(isPending && { fulfillsRentalRequirements: shouldFulfillRequirements }),
      },
      onError: (error) => {
        // eslint-disable-next-line no-console
        console.error(error)
      },
    },
  )
  const { handleScroll, isFetchingMore } = useFetchMoreOnScrollToBottom({
    handleFetchMore: () =>
      fetchMore({
        variables: {
          after: columnData?.homeApplicationsByBoardList.pageInfo.endCursor,
          first: 10,
        },
      }),
    shouldFetchMore: columnData?.homeApplicationsByBoardList.pageInfo.hasNextPage,
  })

  const getColumnDataIds = () => {
    if (columnData) {
      return columnData?.homeApplicationsByBoardList.edges.map((application) => {
        return application.node.id
      })
    } else return []
  }

  const getColumnDataConversationIds = () => {
    const temp: string[] = []
    if (columnData) {
      columnData?.homeApplicationsByBoardList.edges.map((application) => {
        if (application.node.conversation) temp.push(application.node.conversation.id)
        return application
      })
    }
    return temp
  }

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchColumns])

  useEffect(() => {
    setLoadingData(isLoading)
  }, [isLoading])

  useEffect(() => {
    const variables: Partial<HomeApplicationsByBoardListVariables> =
      isPending && shouldFulfillRequirements !== undefined
        ? {
            searchString,
            fulfillsRentalRequirements: shouldFulfillRequirements,
            tenantVerifications: filters,
            minIncome: hasIncomeFilter ? incomeRange.min : null,
            maxIncome: hasIncomeFilter ? incomeRange.max : null,
          }
        : isPending
          ? {
              searchString,
              tenantVerifications: filters,
              minIncome: hasIncomeFilter ? incomeRange.min : null,
              maxIncome: hasIncomeFilter ? incomeRange.max : null,
            }
          : {
              searchString,
            }

    refetch(variables)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomeRange, searchString, shouldFulfillRequirements, filters])

  const refetchColumnData = (newVariables: Partial<HomeApplicationsByBoardListVariables>) => {
    refetch(newVariables)
  }

  return (
    <ColumnContainer isLast={data.defaultType === completed}>
      <ColumnHeader
        isEmpty={columnData?.homeApplicationsByBoardList.edges.length === 0}
        isDefaultColumn={data.isDefault}
        currentColumnId={currentColumnId}
        columns={columns}
        title={name}
        totNoOfApplications={data.applicationCount}
        noApplications={columnData?.homeApplicationsByBoardList.totalCount}
        defaultType={data.defaultType}
        columnDataIds={getColumnDataIds()}
        columnDataConversationIds={getColumnDataConversationIds()}
        refetchColumns={refetchColumns}
        refetchColumnData={refetchColumnData}
      />
      <ColumnContent onScroll={handleScroll}>
        {!isLoading && columnData?.homeApplicationsByBoardList.edges.length === 0 && (
          <>
            <Spacer factor={2} />
            {data.defaultType === completed && (
              <Placeholder>{t('applications_board.assigned_column_description')}</Placeholder>
            )}
            {data.defaultType === pending && (
              <Placeholder>{t('applications_board.incoming_column_description')}</Placeholder>
            )}
          </>
        )}
        <Stack gap="1x" divider={<Divider />}>
          {columnData?.homeApplicationsByBoardList.edges.map((application) => (
            <ApplicantCard
              key={application.node.id}
              data={application.node}
              applicationBoardCardInfo={applicationBoardCardInfo}
              columns={columns}
              currentColumnId={currentColumnId}
            />
          ))}
        </Stack>
        {(isLoadingData || isFetchingMore) && <LoadingDots />}
        <Spacer factor={16} />
      </ColumnContent>
    </ColumnContainer>
  )
}
