import { gql } from '@apollo/client'

export const user = gql`
  fragment BaseUser on User {
    certificate {
      id
      status
      expiresAt
    }
    createdAt
    firstName
    age
    currentIdentification {
      identityConfirmed
      identityConfirmedAt
    }
    incomeVerifiedAt
    emailConfirmed
    profilePicture {
      id
      url
    }
    landlord
    professional
    companyName
    premium
    safeRental
    seenAt
    tenant
    notificationSpan
    sendMonitoringEvents
    doNotContact
    doNotSms
    doNotVacationSms
    showAge
    housingSituation {
      id
      agreementType
    }
    movingReason
    landlordApplicationResponseRate
    landlordApplicationResponseTimeHours
    # TODO: Remove pep and rca when GQL codegen is working in PRO
    pep
    rca
    uid
  }
`
