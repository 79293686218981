import { gql } from '@apollo/client'
export const CREATE_UPLOAD = gql`
  mutation CreateUploadMutation($input: CreateUploadInput!) {
    __typename
    createUpload(input: $input) {
      upload {
        action
        fields
        id
      }
      errors {
        codes
        field
      }
    }
  }
`
