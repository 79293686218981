import styled from 'styled-components/macro'

import { fontSize, palette } from '../styling'

/**
 * @deprecated
 */
export const SmallHeader = styled.p<{ transparent?: boolean }>(({ transparent }) => ({
  opacity: transparent ? 0.2 : 1,
  userSelect: 'none',
  fontSize: fontSize.textBase,
  fontWeight: 600,
  color: palette.textPrimary,
}))
