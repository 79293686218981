import type { ReactNode } from 'react'
import styled from 'styled-components/macro'

import { Stack } from '../../../ui-shared/_core/stack'
import { Icon } from '../../../ui-shared/_core/icon'
import type { IconNames } from '../../../ui-shared/_core/icon'

const ListItem = styled(Stack).attrs({ forwardedAs: 'li', direction: 'row', align: 'center', gap: 2 })({})

const ContentWrapper = styled(Stack)({ minWidth: 0 })
const PreferenceIcon = styled(Icon)({ fontSize: 20 })

type SearchPreferenceListItemProps = {
  label?: string
  value?: ReactNode
  icon?: IconNames
  matchingIndicator?: ReactNode
  className?: string
}
export function SearchPreferenceListItem({
  label,
  value,
  icon,
  matchingIndicator,
  className,
}: SearchPreferenceListItemProps) {
  if (!value) {
    return null
  }
  return (
    <ListItem {...{ className }}>
      {icon && <PreferenceIcon icon={icon} />}
      <ContentWrapper>
        {label && <p>{label}</p>}
        <p>{value}</p>
        {matchingIndicator}
      </ContentWrapper>
    </ListItem>
  )
}
