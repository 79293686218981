import { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { Button, Heading, Paragraph, Spacer, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import { Dialog } from '@qasa/app/src/components/dialog'

import { TableTopSection } from '../ui-atoms/top-section'
import {
  ListingsStatusFilters,
  ListingsStatusNavigation,
} from '../ui-feature-modules/listings/listings-status-navigation'
import type { NavigationOption } from '../ui-feature-modules/listings/navigation-item'
import { ListingsTable } from '../ui-feature-modules/listings/listings-table'
import { WhiteBoxWrapper } from '../ui-shared/wrapper'

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  overflow: 'auto',
  maxWidth: '100vw',
  height: '100%',
})

const ColumnWrapper = styled(Stack)({
  width: 'fit-content',
  maxWidth: '100%',
})

const Content = styled(WhiteBoxWrapper)({
  overflow: 'hidden',
})

export function Listings() {
  const [listingsTotalCount, setListingsTotalCount] = useState<number | undefined>(undefined)
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [listingStatus, setListingStatus] = useState<ListingsStatusFilters>(ListingsStatusFilters.ALL)
  const { t } = useTranslation(['listings', 'commons'])

  const listingsNavigationOptions: NavigationOption[] = [
    {
      label: t('listings_navigation_options.all'),
      value: ListingsStatusFilters.ALL,
    },
    {
      label: t('listings_navigation_options.draft'),
      value: ListingsStatusFilters.DRAFT,
    },
    {
      label: t('listings_navigation_options.published'),
      value: ListingsStatusFilters.PUBLISHED,
    },
    {
      label: t('listings_navigation_options.expired'),
      value: ListingsStatusFilters.PASSED_DEADLINE,
    },
    {
      label: t('listings_navigation_options.archived'),
      value: ListingsStatusFilters.ARCHIVED,
    },
  ]

  return (
    <Wrapper>
      <ColumnWrapper>
        <Heading as="h1" size="md">
          {t('title')}
        </Heading>
        <Spacer size={'6x'} />
        <Content>
          <TableTopSection>
            <ListingsStatusNavigation
              listingsCount={listingsTotalCount ?? '...'}
              options={listingsNavigationOptions}
              listingStatus={listingStatus}
              onChangeListingStatus={setListingStatus}
            />
            <Dialog
              isOpen={isModalOpen}
              onOpenChange={(isOpen) => setModalOpen(isOpen)}
              size="sm"
              trigger={
                <Button variant="primary" onClick={() => setModalOpen(true)}>
                  {t('commons:actions.create_listing')}
                </Button>
              }
            >
              <Dialog.Body>
                <Stack gap="4x">
                  <Dialog.Header>
                    <Dialog.Title>{t('commons:actions.create_listing')}</Dialog.Title>
                  </Dialog.Header>
                  <Paragraph>{t('create_listing_description')}</Paragraph>
                </Stack>
              </Dialog.Body>
              <Dialog.Footer>
                <Stack gap="2x" direction={'row'} justifyContent={'center'}>
                  <Button as={Link} to="/homes/apartments">
                    {t('commons:apartment_other')}
                  </Button>
                  <Button onClick={() => setModalOpen(false)} variant="tertiary">
                    {t('commons:ctas.cancel')}
                  </Button>
                </Stack>
              </Dialog.Footer>
            </Dialog>
          </TableTopSection>
          <Spacer size={'4x'} />
          <ListingsTable setTotalCount={setListingsTotalCount} listingStatus={listingStatus} />
        </Content>
      </ColumnWrapper>
    </Wrapper>
  )
}
