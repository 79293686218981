import styled from 'styled-components/macro'

export const TopSection = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export const TableTopSection = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
})
