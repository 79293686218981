import type { ReactNode } from 'react'
import type { CSSObject } from 'styled-components/macro'
import { createGlobalStyle, useTheme } from 'styled-components/macro'
import styledNormalize from 'styled-normalize'

const GlobalStyles = createGlobalStyle(({ theme }) => ({
  body: {
    backgroundColor: '#FAF9FB',
    width: '100vw',
    height: '100vh',
    'input:not([type=checkbox]):not([type=radio]):focus, select:focus, textarea:focus, button:focus:not(:focus-visible), a:focus:not(:focus-visible)':
      {
        outline: 'none',
      },
    'button:focus-visible, a:focus-visible, input[type=checkbox]:focus-visible, input[type="radio"]:focus-visible':
      {
        outlineOffset: 4,
      },
  },
  '#root': {
    height: '100%',
  },
}))

export const MobileIndicatorGlobalStyles = createGlobalStyle({
  ...(styledNormalize as CSSObject),
  body: {
    margin: '0',
    width: '100vw',
    height: '100vh',
    fontFamily: `'Blocket Sans', sans-serif`,
    color: '#111',
    fontSize: 16,
  },
})

export function GlobalStylesProvider({ children }: { children: ReactNode }) {
  const theme = useTheme()
  return (
    <>
      {theme.fonts.map(({ url, fontWeight, fontStyle, fontFamily }) => (
        <style key={url}>{`
              @font-face {
                font-family: "${fontFamily || theme.fontFamily}";
                font-weight: ${fontWeight};
                font-style: ${fontStyle};
                src: url("${url}") format("woff2");
              }
            `}</style>
      ))}
      <GlobalStyles />
      {children}
    </>
  )
}
