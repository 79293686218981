import { useNavigate } from 'react-router-dom'
import { IconButton, ChevronLeftIcon } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

type GoBackProps = {
  onClick?: () => void
}
export function GoBack(props: GoBackProps) {
  const navigate = useNavigate()
  const { t } = useTranslation('commons', { keyPrefix: 'ctas' })

  return (
    <IconButton
      icon={ChevronLeftIcon}
      onClick={props.onClick ? props.onClick : () => navigate(-1)}
      label={t('back')}
      variant={'tertiary'}
      size="xs"
    />
  )
}
