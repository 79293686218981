import styled from '@emotion/styled'
import { Heading, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { MessagesList } from '../ui-feature-modules/overview/messages-list/messages-list'
import { NewApplications } from '../ui-feature-modules/overview/new-applications/new-applications'

const MainWrapper = styled(Stack)(({ theme }) => ({
  height: '100%',
}))

const RowWrapper = styled(Stack)({
  width: 'fit-content',
  maxWidth: '100%',
  height: 'min-content',
  minHeight: '90%',
  overflow: 'scroll',
})

export function Overview() {
  const { t } = useTranslation('overview')

  return (
    <MainWrapper gap="6x">
      <Heading as="h1" size="md">
        {t('title')}
      </Heading>
      <RowWrapper direction="row" gap="8x">
        <MessagesList />
        <NewApplications />
      </RowWrapper>
    </MainWrapper>
  )
}
