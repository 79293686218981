import { Spacer } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { MenuPicker } from '../../../ui-atoms/menu-picker'
import { useApplicationsContext } from '../../../context/applications-context'

import { SectionTitle } from './column-menu'

export function ColumnRequirements() {
  const {
    pendingColumnSettings: { shouldFulfillRequirements },
    updatePendingColumnSettings,
  } = useApplicationsContext()

  const { t } = useTranslation(['applications', 'commons'])

  const values = [
    { id: '1', value: t('column_header.meets_requirements'), isActive: Boolean(shouldFulfillRequirements) },
    { id: '2', value: t('column_header.all'), isActive: !shouldFulfillRequirements },
  ]

  const onChoice = (id?: string) => {
    const hasChoice = id === '1' ? true : undefined
    updatePendingColumnSettings({ shouldFulfillRequirements: hasChoice })
  }

  return (
    <>
      <Spacer size="3x" />
      <SectionTitle>{t('commons:show')}</SectionTitle>
      <Spacer size="3x" />
      <MenuPicker values={values} onChoice={onChoice} />
    </>
  )
}
