import { gql } from '@apollo/client'
const GET_APPLICANT_OVERVIEW = gql`
  query HomeApplicationOverview($homeApplicationId: ID!) {
    homeApplication(id: $homeApplicationId) {
      id
      tenantIdNumber
      tenantFamilyName
      tenantPhoneNumber
      tenantEmail
      conversation {
        id
      }
      tenant {
        age
        mainPlatform
        userNotes {
          author {
            firstName
            uid
          }
          id
          note
          createdAt
          homeApplication {
            home {
              location {
                route
                streetNumber
              }
            }
          }
        }
        tenantAd {
          id
          searchPreference {
            id
            matchingAreaMetadata {
              name
              city
              osmId
            }
            balcony
            bathtub
            bikeRoom
            corporateHome
            countryCode
            currency
            dishWasher
            furniture
            householdSize
            inhomeSauna
            storage
            studentHome
            pets
            recycling
            seniorHome
            shared
            smoker
            washingMachine
            tumbleDryer
            wheelchairAccessible
            parking
            minRoomCount
            minSquareMeters
            homeType
            maxMonthlyCost
            startAsap
            startOptimal
            endUfn
            endOptimal
          }
        }
        firstName
        createdAt
        profilePicture {
          url
          title
          metadata {
            primary
          }
        }
        matchingProfile {
          furniture
          moveInEarliest
          sharedHomeOk
          maxMonthlyCost
          minRentalLength
          householdSize
          maxRentalLength
          matchingAreaMetadata {
            name
            city
          }
        }
        homeApplicationsToMyHomes {
          matchInterest
          message
          status
        }
        currentIdentification {
          identityConfirmed
          identityConfirmedAt
        }
        bio {
          title
          intro
          spareTime
          work
          who
          where
          lived
          study
          pets
        }
        housingSituation {
          id
          agreementType
        }
        movingReason
        uid
        incomeVerifiedAt
        creditReport {
          id
          fetchStatus
          numberPaymentRemarks
        }
      }
      status
      offering {
        offeringStatus
        id
      }
      home {
        squareMeters
        roomCount
        numberOfHomes
        location {
          streetNumber
          route
        }
        landlord {
          uid
          firstName
          profilePicture {
            url
          }
          private {
            familyName
            phoneNumber
            email
          }
        }
        duration {
          startAsap
          startOptimal
        }
        homeTemplates {
          id
          rent
          apartmentNumber
          floor
          squareMeters
          roomCount
        }
        uploads {
          url
          title
        }
        apartmentNumber
        id
      }
      starMarkedByLandlord
      startOptimal
      homeTemplates {
        id
        rent
        apartmentNumber
        floor
        squareMeters
        roomCount
      }
      contract {
        id
      }
    }
  }
`

const GET_APPLICANT_CONTACT_INFORMATION = gql`
  query HomeApplicationContactInformation($homeApplicationId: ID!) {
    homeApplication(id: $homeApplicationId) {
      tenantEmail
      tenantPhoneNumber
    }
  }
`

const GET_APPLICANT_STATUS = gql`
  query HomeApplicationStatus($homeApplicationId: ID!) {
    homeApplication(id: $homeApplicationId) {
      tenant {
        homeApplicationsToMyHomes {
          status
        }
      }
    }
  }
`

const GET_APPLICANT_ALSO_APPLIED_TO = gql`
  query HomeApplicationAlsoAppliedTo($homeApplicationId: ID!) {
    homeApplication(id: $homeApplicationId) {
      tenant {
        homeApplicationsToMyHomes {
          id
          home {
            id
            squareMeters
            numberOfHomes
            apartmentNumber
            uploads {
              url
              title
            }
            roomCount
            location {
              route
              streetNumber
              locality
            }
          }
          status
          matchInterest
          message
          offering {
            offeringStatus
          }
        }
      }
    }
  }
`

const SET_APPLICANT_MESSAGE = gql`
  mutation CreateConversationMessageMutation(
    $createConversationMessageInput: CreateConversationMessageInput!
  ) {
    createConversationMessage(input: $createConversationMessageInput) {
      errors {
        codes
      }
    }
  }
`

const SET_APPLICANT_DECLINE = gql`
  mutation DeclineHomeApplication($declineHomeApplicationId: ID!) {
    declineHomeApplication(id: $declineHomeApplicationId) {
      errors {
        field
        codes
      }
    }
  }
`
const SET_CREATE_APPLICANT_OFFERING = gql`
  mutation CreateApplicantOffering($createOfferingInput: CreateOfferingInput!) {
    createOffering(input: $createOfferingInput) {
      errors {
        codes
      }
    }
  }
`
const SET_REVOKE_OFFERING_MUTATION = gql`
  mutation RevokeOfferingMutation($revokeOfferingId: ID!) {
    revokeOffering(id: $revokeOfferingId) {
      offering {
        id
      }
    }
  }
`

const SET_STARMARKED = gql`
  mutation StarMarkApplicationMutation($homeApplicationId: ID!, $starMarkedByLandlord: Boolean!) {
    starMarkApplication(id: $homeApplicationId, starMarkedByLandlord: $starMarkedByLandlord) {
      homeApplication {
        id
        starMarkedByLandlord
      }
    }
  }
`

const ACCEPT_HOME_APPLICATIONS = gql`
  mutation AcceptHomeApplications($homeApplicationsIds: [ID!]!) {
    acceptHomeApplications(ids: $homeApplicationsIds) {
      success
    }
  }
`

const DECLINE_HOME_APPLICATIONS = gql`
  mutation DeclineHomeApplications($homeApplicationsIds: [ID!]!) {
    declineHomeApplications(ids: $homeApplicationsIds) {
      success
    }
  }
`

const CREATE_APPLICATION_COMMENT = gql`
  mutation CreateApplicationComment($recordId: ID!, $recordType: UserNoteRecordTypeEnum!, $note: String!) {
    createUserNote(input: { recordId: $recordId, recordType: $recordType, note: $note }) {
      errors {
        codes
      }
      userNote {
        author {
          firstName
        }
        id
        note
        createdAt
      }
    }
  }
`

const DESTROY_APPLICATION_COMMENT = gql`
  mutation DestroyApplicationComment($noteId: ID!) {
    destroyUserNote(id: $noteId) {
      id
    }
  }
`

export const queries = {
  GET_APPLICANT_OVERVIEW,
  GET_APPLICANT_CONTACT_INFORMATION,
  GET_APPLICANT_STATUS,
  GET_APPLICANT_ALSO_APPLIED_TO,
}

export const mutations = {
  SET_CREATE_APPLICANT_OFFERING,
  SET_REVOKE_OFFERING_MUTATION,
  SET_APPLICANT_MESSAGE,
  SET_APPLICANT_DECLINE,
  SET_STARMARKED,
  CREATE_APPLICATION_COMMENT,
  DESTROY_APPLICATION_COMMENT,
  ACCEPT_HOME_APPLICATIONS,
  DECLINE_HOME_APPLICATIONS,
}
