import styled, { ThemeProvider } from 'styled-components/macro'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { QdsProvider, Stack } from '@qasa/qds-ui'
import { I18nextProvider } from '@qasa/app/src/contexts/i18next'
import { QueryParamProvider } from 'use-query-params'

import { GlobalStylesProvider } from './styling/global-styles'
import { UserProvider } from './context/user-context'
import { AppRoutes } from './routing/app-routes'
import { Navbar } from './routing/navbar/navbar'
import './styling/fontAwsome'
import { useMediaQuery } from './util-functions/media-query.util'
import { MobileIndicator } from './ui-atoms/mobile-indicator'
import { currentTheme } from './ui-styles/current-theme'
import { blocketQdsOverrides } from './ui-styles/brand-themes/blocket'
import { useLocalStorage } from './util-functions/use-local-storage'

const Wrapper = styled(Stack)({
  height: '100%',
  width: '100%',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
})

export function App() {
  const isMobile = useMediaQuery('(max-width: 750px)')
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useLocalStorage('isNavbarCollapsed', false)

  if (isMobile) {
    return <MobileIndicator />
  }

  const handleToggleNavbarCollapsed = () => setIsNavbarCollapsed((prevCollapsed) => !prevCollapsed)

  // TODO: change I18nextProvider currentLanguage to the actual current language when we have proper language handling
  return (
    <ThemeProvider theme={currentTheme}>
      <GlobalStylesProvider>
        <QdsProvider locale="sv" themeOverrides={blocketQdsOverrides}>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <UserProvider>
              <I18nextProvider currentLanguage="sv">
                <Wrapper justifyContent="center" direction="row" id="wrapper">
                  <Navbar
                    isNavbarCollapsed={Boolean(isNavbarCollapsed)}
                    handleToggleNavbarCollapsed={handleToggleNavbarCollapsed}
                  />
                  <AppRoutes />
                </Wrapper>
              </I18nextProvider>
            </UserProvider>
          </QueryParamProvider>
        </QdsProvider>
      </GlobalStylesProvider>
    </ThemeProvider>
  )
}
