import { Spacer } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { MenuPicker } from '../../../ui-atoms/menu-picker'
import { SectionTitle } from '../board/column-menu'
import { Order } from '../../../ui-atoms/table'
import { HomeSearchOrderEnum } from '../../../data/graphql/types/__generated__/globalTypes'

type ListingsSortingProps = {
  handleOrderBy: (newOrderBy: HomeSearchOrderEnum) => void
  active?: boolean | undefined
  order?: HomeSearchOrderEnum | null
}
export function ListingsSorting(props: ListingsSortingProps) {
  const { order = Order.DESCENDING, handleOrderBy } = props

  const { t } = useTranslation('applications', { keyPrefix: 'listings.listings_bar' })

  const values = [
    {
      id: '1',
      value: t('last_published'),
      isActive: order === HomeSearchOrderEnum.DESCENDING,
      order: HomeSearchOrderEnum.DESCENDING,
    },
    {
      id: '2',
      value: t('oldest'),
      isActive: order === HomeSearchOrderEnum.ASCENDING,
      order: HomeSearchOrderEnum.ASCENDING,
    },
  ]

  const onChoice = (order?: HomeSearchOrderEnum) => {
    if (order) handleOrderBy(order)
  }

  return (
    <>
      <Spacer size="3x" />
      <SectionTitle>{t('sort')}</SectionTitle>
      <Spacer size="3x" />
      <MenuPicker values={values} onOrder={onChoice} />
    </>
  )
}
