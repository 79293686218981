import { Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import { EditDialog } from '@qasa/app/src/modules/edit-dialog'

import { BaseInfoList } from '../apartment/apartment-details'
import { BaseInfoItem } from '../listing/base-info-item'
import { ListingSectionEnum, useListingContext } from '../../context/listing-context'
import { LeftContainer, RightContainer } from '../listing/building-content'

export function BuildingDetails() {
  const { t } = useTranslation(['building', 'commons'])
  const { home, setActiveEditSection, setIsEditDialogOpen } = useListingContext()
  const { homeTemplates, buildYear, energyClass } = home || {}
  const buildingName = homeTemplates[0].building?.buildingName

  const fields = [
    {
      heading: t('fields.building_name'),
      value: buildingName || t('commons:placeholders.not_filled_in'),
    },
    {
      heading: t('fields.construction_year'),
      value: buildYear ? `${buildYear}` : t('commons:placeholders.not_filled_in'),
    },
    {
      heading: t('fields.energy_class'),
      value: energyClass || t('commons:placeholders.not_filled_in'),
    },
  ]

  return (
    <Stack gap="12x" direction="row" justifyContent="space-between">
      <LeftContainer>
        <BaseInfoList>
          {fields.map((field) => {
            const { heading, value } = field
            return <BaseInfoItem key={heading} heading={heading} value={value} />
          })}
        </BaseInfoList>
      </LeftContainer>
      <RightContainer>
        <EditDialog.Trigger
          onClick={() => {
            setIsEditDialogOpen(true)
            setActiveEditSection(ListingSectionEnum.BUILDING_DETAILS)
          }}
        />
      </RightContainer>
    </Stack>
  )
}
