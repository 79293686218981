import { useQuery } from '@apollo/client'
import debounce from 'lodash/debounce'
import { useRef, useState } from 'react'
import styled from '@emotion/styled'
import { Link as RouterLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { Stack, Button } from '@qasa/qds-ui'

import { queries } from '../../data/my-homes'
import { Input } from '../../ui-atoms/input'
import { formatDate } from '../../util-functions/date.util'
import { formatLocation } from '../../util-functions/general.util'
import { Table, TableImageCell, TableText, ColumnWithLoading, TableColumn } from '../../ui-atoms/table'
import type { TableRowType } from '../../ui-atoms/table'
import type {
  BuildingsQuery,
  BuildingsQueryVariables,
  BuildingsQuery_buildings,
} from '../../data/graphql/types/__generated__/BuildingsQuery'
import buildingPlaceholder from '../../assets/placeholders/building.png'
import { useFetchMoreOnScrollToBottom } from '../../hooks/use-fetch-more-on-scroll-to-bottom'

const BULK_SIZE = 20
const START_COUNT = 10

const BuildingName = styled(ColumnWithLoading)({
  minWidth: '250px',
})
const Address = styled(ColumnWithLoading)({
  minWidth: '300px',
})
const ApartmentCount = styled(ColumnWithLoading)({
  minWidth: '150px',
})
const LastUpdate = styled(ColumnWithLoading)({
  minWidth: '100px',
})
const TableActions = styled(Stack)({
  width: '100%',
})

type TableDataParams = {
  gqlResult: BuildingsQuery_buildings | undefined
  isLoading?: boolean
}
const constructBuildingsTableData = ({ gqlResult, isLoading }: TableDataParams): TableRowType[] => {
  if (isLoading) {
    return Array<TableRowType>(20)
      .fill({ id: '', row: [] })
      .map((_, index) => ({
        id: Math.random().toString(),
        row: [
          <BuildingName key={`${index}-0`} isLoading={true} />,
          <Address key={`${index}-1`} isLoading={true} />,
          <ApartmentCount key={`${index}-2`} isLoading={true} />,
          <LastUpdate key={`${index}-3`} isLoading={true} />,
        ],
      }))
  } else {
    const rows = gqlResult?.edges?.map(({ node: building }) => {
      const id = building.id

      const buildingImageUrl = building.uploads[0]?.url ? building.uploads[0]?.url : buildingPlaceholder
      const buildingName = (
        <BuildingName>
          <TableImageCell
            imageUrl={buildingImageUrl}
            imageLabel={<TableText>{building.buildingName!}</TableText>}
          />
        </BuildingName>
      )

      const address = <Address>{formatLocation(building.location)}</Address>
      const numberOfApartments = (
        <ApartmentCount>
          {i18next.t('my_homes:buildings_table.amount_of_apartments', { count: building.homeTemplateCount })}
        </ApartmentCount>
      )
      const lastUpdated = <LastUpdate>{formatDate(new Date(building.updatedAt))}</LastUpdate>

      return {
        id,
        row: [buildingName, address, numberOfApartments, lastUpdated],
      }
    })

    return rows ? rows : []
  }
}

export function BuildingsTable() {
  const [searchString, setSearchString] = useState<string>('')
  const { t } = useTranslation(['my_homes', 'commons'])

  const { error, data, fetchMore, refetch, networkStatus } = useQuery<
    BuildingsQuery,
    BuildingsQueryVariables
  >(queries.GET_BUILDINGS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      buildingsAfter: null,
      buildingsFirst: START_COUNT,
      buildingsSearchString: '',
    },
  })
  const { handleScroll } = useFetchMoreOnScrollToBottom({
    handleFetchMore: () =>
      fetchMore({
        variables: {
          buildingsAfter: data?.buildings.pageInfo.endCursor,
          buildingsFirst: BULK_SIZE,
        },
      }),
    shouldFetchMore: data?.buildings.pageInfo.hasNextPage,
  })

  // Put in ref so that it wont be re-rendered
  const delaySearchRef = useRef(
    debounce((buildingsSearchString) => {
      refetch({
        buildingsAfter: null,
        buildingsFirst: START_COUNT,
        buildingsSearchString,
      })
    }, 250),
  )

  if (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    return null
  }

  const handleSearch = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { value: searchString } = event.target
    setSearchString(searchString)
    delaySearchRef.current(searchString)
  }

  const constructBuildingsTableColumns = () => {
    return [
      <TableColumn key="0">{t('table_columns.building')}</TableColumn>,
      <TableColumn key="1">{t('table_columns.address')}</TableColumn>,
      <TableColumn key="2">{t('table_columns.size')}</TableColumn>,
      <TableColumn key="3">{t('table_columns.update')}</TableColumn>,
    ]
  }

  const isInitialFetchLoading = networkStatus === 1
  const isFetchMoreLoading = networkStatus === 3

  return (
    <>
      <TableActions gap={'4x'} direction="row" alignItems="center" justifyContent="space-between">
        <Input
          type="search"
          name="search"
          placeholder={t('buildings_table.search_placeholder')}
          width="466px"
          value={searchString}
          onChange={handleSearch}
        />
        <Button size="sm" as={RouterLink} variant="tertiary" to={`/homes/buildings/add/`}>
          {t('commons:actions.add_building')}
        </Button>
      </TableActions>
      <Table
        isLoading={isInitialFetchLoading}
        isFetchMoreLoading={isFetchMoreLoading}
        tableRef="/homes/buildings"
        columns={constructBuildingsTableColumns()}
        rows={constructBuildingsTableData({
          gqlResult: data?.buildings,
          isLoading: isInitialFetchLoading,
        })}
        handleOnScroll={handleScroll}
      />
    </>
  )
}
