import { useQuery } from '@apollo/client'
import styled from 'styled-components/macro'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

import type { BlockListingApartmentsQuery } from '../../data/graphql/types/__generated__/BlockListingApartmentsQuery'
import { queries } from '../../data/listing'
import type { TableRowType } from '../../ui-atoms/table'
import { ColumnWithLoading, Table, TableImageCell, TableText } from '../../ui-atoms/table'
import buildingPlaceholder from '../../assets/placeholders/building.png'
import { formatApartmentSize, formatLocation } from '../../util-functions/general.util'

type BlockListingApartmentsTableProps = {
  listingId: string
}

const BuildingName = styled(ColumnWithLoading)({
  minWidth: '170px',
})
const ApartmentNumber = styled(ColumnWithLoading)({
  minWidth: '140px',
})
const Address = styled(ColumnWithLoading)({
  minWidth: '250px',
})
const Size = styled(ColumnWithLoading)({
  minWidth: '120px',
})
const OffersPending = styled(ColumnWithLoading)({
  minWidth: '120px',
})
const OffersAccepted = styled(ColumnWithLoading)({
  minWidth: '120px',
})

const constructBuildingsTableData = (
  gqlResult: BlockListingApartmentsQuery | undefined,
  isLoading?: boolean,
): TableRowType[] => {
  if (isLoading) {
    return Array<TableRowType>(20)
      .fill({ id: '', row: [] })
      .map((_, index) => ({
        id: Math.random().toString(),
        row: [
          // TODO: Remove disabling of eslint rule
          /* eslint-disable react/jsx-key */
          <BuildingName isLoading={true} />,
          <ApartmentNumber isLoading={true} />,
          <Address isLoading={true} />,
          <Size isLoading={true} />,
          <OffersPending isLoading={true} />,
          <OffersAccepted isLoading={true} />,
          /* eslint-enable react/jsx-key */
        ],
      }))
  } else {
    const rows: TableRowType[] | undefined = gqlResult?.home?.homeTemplates?.map((apartment) => {
      const id = apartment.id
      const buildingImageUrl = apartment?.building?.uploads[0]?.url
        ? apartment?.building?.uploads[0]?.url
        : buildingPlaceholder
      const buildingName = (
        <BuildingName>
          <TableImageCell
            imageUrl={buildingImageUrl}
            imageLabel={<TableText>{apartment?.building?.buildingName}</TableText>}
          />
        </BuildingName>
      )

      const apartmentImage = apartment.uploads[0]?.url ? apartment.uploads[0]?.url : buildingPlaceholder

      const apartmentNumber = (
        <ApartmentNumber>
          <TableImageCell
            imageUrl={apartmentImage}
            imageLabel={<TableText>{apartment.apartmentNumber}</TableText>}
          />
        </ApartmentNumber>
      )

      const address = <Address>{formatLocation(apartment.location!)}</Address>

      let apartmentSize = <Size>{i18next.t('commons:placeholders.not_filled_in')}</Size>
      if (apartment.roomCount && apartment.squareMeters) {
        apartmentSize = <Size>{formatApartmentSize(apartment.roomCount, apartment.squareMeters)}</Size>
      }

      const ongoingOffers = <OffersPending>{apartment.pendingOfferingsCount}</OffersPending>
      const acceptedOffers = <OffersAccepted>{apartment.acceptedOfferingsCount}</OffersAccepted>
      const onRowClick = () => {
        window.open(`/homes/apartments/${id}`)
      }

      return {
        id,
        row: [buildingName, apartmentNumber, address, apartmentSize, ongoingOffers, acceptedOffers],
        onClick: onRowClick,
      }
    })

    return rows ? rows : []
  }
}

export function BlockListingApartmentsTable(props: BlockListingApartmentsTableProps) {
  const { t } = useTranslation('listing', { keyPrefix: 'block_listing_apartments.table_columns' })
  const {
    data,
    loading: isLoading,
    error,
  } = useQuery<BlockListingApartmentsQuery>(queries.GET_BLOCK_LISTING_APARTMENTS, {
    variables: { homeId: props.listingId },
    fetchPolicy: 'no-cache',
  })

  if (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }

  return (
    <Table
      columns={[
        t('building'),
        t('apartment_number'),
        t('address'),
        t('size'),
        t('offers'),
        t('accepted_offers'),
      ]}
      rows={constructBuildingsTableData(data, isLoading)}
    />
  )
}
