import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Heading, Stack } from '@qasa/qds-ui'
import { useTranslation, Trans } from 'react-i18next'

import { defaultStyles } from '../styling/defaults'
import { queries, mutations } from '../data/add-apartment'
import { queries as buildingQueries } from '../data/building'
import { cloneWithoutTypename, formatLocation } from '../util-functions/general.util'
import { HomeTypeEnum, OwnerTypeEnum, UploadTypeEnum } from '../data/graphql/types/__generated__/globalTypes'
import type { TraitTypeEnum, UpsertHomeTemplateInput } from '../data/graphql/types/__generated__/globalTypes'
import { ContractTypesEnum } from '../types/contract-types'
import { useUploadImage } from '../hooks/use-upload-image'
import type {
  BuildingsForAddApartmentQuery,
  BuildingsForAddApartmentQuery_buildings,
} from '../data/graphql/types/__generated__/BuildingsForAddApartmentQuery'
import type {
  GetApartmentsForTemplateChoiceQuery,
  GetApartmentsForTemplateChoiceQuery_homeTemplates,
} from '../data/graphql/types/__generated__/GetApartmentsForTemplateChoiceQuery'
import type {
  ApartmentTemplateQuery,
  ApartmentTemplateQuery_homeTemplate,
} from '../data/graphql/types/__generated__/ApartmentTemplateQuery'
import type { BuildingLocationQuery } from '../data/graphql/types/__generated__/BuildingLocationQuery'
import type { UpsertHomeTemplateMutation } from '../data/graphql/types/__generated__/UpsertHomeTemplateMutation'
import type { ApartmentForEditQuery_homeTemplate_uploads } from '../data/graphql/types/__generated__/ApartmentForEditQuery'
import { fontSize, palette } from '../styling'
import { getPixelAmount } from '../styling/pixel-amount'
import type { BuildingQuery } from '../data/graphql/types/__generated__/BuildingQuery'
import type { DestroyUploadMutation } from '../data/graphql/types/__generated__/DestroyUploadMutation'
import { DESTROY_UPLOAD } from '../data/destroy-upload'
import { EndSpace } from '../ui-feature-modules/applications/overview/overview'
import { AmenitiesSection } from '../ui-feature-modules/apartment-form/amenities-section'
import { FurnishedSection } from '../ui-feature-modules/apartment-form/furnished-section'
import { blocketPalette } from '../styling/colors'
import { RenovationSection } from '../ui-feature-modules/apartment-form/renovation-section'
import { validateYearBeforeCurrent } from '../util-functions/validate-year'
import { WhiteBoxWrapper } from '../ui-shared/wrapper'
import { LoadingDots } from '../ui-shared/loading-dots'

import { HorizontalDivider, Spacer } from './help-components'
import { VerticalSpacer } from './help-components/spacer'
import { TopSection } from './top-section'
import { DropDown, DropDownElementWrapper } from './dropdown'
import type { DropDownElement } from './dropdown'
import { InputHandler } from './input-handler'
import { SmallHeader } from './small-header'
import { Input } from './input'
import { Map } from './map'
import { RadioButton } from './radio-button'
import { ImageUploadHandler } from './image-upload-handler'
import { ImagesContainer, ObjectImage, RemoveImageButton } from './object-image'
import { SmallData } from './small-data'

type ApartmentFormProps = {
  buildingId?: string | null
  apartmentId?: string
  apartment?: UpsertHomeTemplateInput
  title: string
  uploads?: ApartmentForEditQuery_homeTemplate_uploads[]
  cancel?: () => void
}

const Wrapper = styled.div({
  height: '100%',
  display: 'flex',
})

const Content = styled(Stack)({
  maxWidth: '1280px',
  width: '100%',
})

const ButtonsContainer = Stack

const AddBuildingLink = styled(Link)({
  textDecoration: 'underline',
  fontWeight: 400,
  color: palette.blocketBlack,
})

const BuildingName = styled.p({
  fontWeight: 500,
})

const BuildingLocation = styled.p({
  fontSize: fontSize.textXs,
  opacity: 0.5,
})

const Information = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: getPixelAmount(2),
  width: '400px',
  height: '50px',
  borderRadius: defaultStyles.borderRadius,
  backgroundColor: palette.blocketGreyLight,
})

const InputGroup = styled.div({
  display: 'grid',
  gridTemplateRows: '1fr 1fr',
  gridTemplateColumns: '1fr 1fr',
  gridGap: getPixelAmount(1),
  width: '400px',
})

const LngLat = styled.div({
  display: 'flex',
})

const LngLatDisplay = styled.p({
  fontSize: fontSize.textXs,
  borderRadius: defaultStyles.borderRadius,
  backgroundColor: palette.grayAlpha20,
  width: 'fit-content',
  padding: getPixelAmount(0.5),
  margin: `${getPixelAmount(0.5)} 0`,
  label: {
    display: 'inline-block',
    width: '60px',
  },
})

const SmallHeaderWithOptional = styled(SmallHeader)({
  ':after': {
    content: '" (valfritt)"',
    opacity: 0.5,
  },
})

const ErrorText = styled.p({
  color: blocketPalette.orange,
  fontSize: fontSize.textSm,
})

const loadBuildingDropDownValues = (
  buildings: BuildingsForAddApartmentQuery_buildings,
): Array<DropDownElement> => {
  return buildings?.nodes.map(({ id, buildingName, location }) => {
    const buildingDisplay = (
      <DropDownElementWrapper key={id}>
        <BuildingName>{buildingName}</BuildingName>
        <BuildingLocation>{formatLocation(location)}</BuildingLocation>
      </DropDownElementWrapper>
    )
    return {
      id,
      value: buildingDisplay,
    }
  })
}

const loadApartmentDropDownValues = (
  apartments: GetApartmentsForTemplateChoiceQuery_homeTemplates,
): Array<DropDownElement> => {
  return apartments?.nodes.map(({ id, apartmentNumber }) => {
    return {
      id,
      value: apartmentNumber,
    }
  })
}

export function ApartmentForm(props: ApartmentFormProps): JSX.Element | null {
  const navigate = useNavigate()
  const { t } = useTranslation(['apartment', 'commons'])

  const [isSavedEnabled, enableSave] = useState(false)
  const [buildingSearch, setBuildingSearch] = useState('')
  const [apartmentSearch, setApartmentSearch] = useState('')
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const [imagesToDestroy, setImagesToDestroy] = useState<ApartmentForEditQuery_homeTemplate_uploads[]>([])

  const [destroyUpload] = useMutation<DestroyUploadMutation>(DESTROY_UPLOAD)

  const [apartmentImages, setApartmentImages] = useState<Array<File>>([])
  const [layoutImages, setLayoutImages] = useState<Array<File>>([])

  const [uploadApartmentImage, { isLoading: isLoadingApartmentImageUpload }] = useUploadImage()
  const [uploadLayoutImage, { isLoading: isLoadingLayoutImageUpload }] = useUploadImage()

  const images = {
    apartmentPictures: props.uploads
      ? props.uploads.filter((upload) => upload.type === UploadTypeEnum.home_picture)
      : [],
    layoutPictures: props.uploads
      ? props.uploads.filter((upload) => upload.type === UploadTypeEnum.home_layout_picture)
      : [],
  }

  const [form, setForm] = useState<UpsertHomeTemplateInput>({
    buildingId: null,
    type: HomeTypeEnum.apartment,
    apartmentNumber: '',
    roomCount: null,
    squareMeters: null,
    tenantCount: null,
    rent: null,
    description: null,
    floor: null,
    kitchenRenovationYear: null,
    bathroomRenovationYear: null,
    shared: false,
    firsthand: true,
    corporateHome: false,
    studentHome: false,
    seniorHome: false,
    location: {
      postalCode: '',
      route: '',
      streetNumber: '',
      locality: '',
      longitude: 0,
      latitude: 0,
      country: '',
      countryCode: '',
    },
    traits: [],
  })

  const {
    loading: isLoadingBuildings,
    error: buildingsError,
    data: buildingsData,
    fetchMore: fetchMoreBuildings,
  } = useQuery<BuildingsForAddApartmentQuery>(queries.GET_BUILDINGS)

  const [
    getAppartments,
    {
      // loading: isLoadingApartments,
      // error: apartmentsError,
      data: apartmentsData,
      refetch: refetchApartments,
    },
  ] = useLazyQuery<GetApartmentsForTemplateChoiceQuery>(queries.GET_APARTMENTS)
  const [
    getBuildingLocation,
    // { loading: isLoadingLocation, error: locationError },
  ] = useLazyQuery<BuildingLocationQuery>(queries.GET_BUILDING_LOCATION, {
    onCompleted: (data) => onBuildingLocationQueryCompleted(data),
  })
  const [
    getApartment,
    // { loading: isLoadingApartment, error: apartmentError },
  ] = useLazyQuery<ApartmentTemplateQuery>(queries.GET_APARTMENT, {
    onCompleted: ({ homeTemplate }) => onApartmentQueryCompleted(homeTemplate),
  })

  const [upsertApartment, { loading: isLoadingAddApartment }] = useMutation<UpsertHomeTemplateMutation>(
    mutations.ADD_APARTMENT,
    {
      onCompleted: (data) => onAddApartmentCompleted(data),
      onError: (error) => {
        setErrorMessage(t('errors.failed_to_save'))
        // eslint-disable-next-line no-console
        console.error(error)
      },
    },
  )

  useEffect(() => {
    if (props.apartment) {
      setForm(props.apartment)
    }
  }, [props.apartment])

  useEffect(() => {
    if (props.buildingId) {
      getBuilding({ variables: { buildingId: props.buildingId } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onBuildingRequestCompleted = (data: BuildingQuery) => {
    const { id } = data.building
    getAppartments({ variables: { homeTemplatesBuildingId: id } })
    getBuildingLocation({ variables: { buildingId: id } })
    setForm({ ...form, buildingId: id })
  }

  const [getBuilding] = useLazyQuery(buildingQueries.GET_BUILDING, {
    onCompleted: (data) => onBuildingRequestCompleted(data),
  })

  const onBuildingLocationQueryCompleted = ({ building }: BuildingLocationQuery) => {
    const { postalCode, route, streetNumber, locality, longitude, latitude, country, countryCode } =
      building.location
    setForm({
      ...form,
      location: {
        ...form.location,
        postalCode,
        route,
        streetNumber,
        locality,
        longitude,
        latitude,
        country,
        countryCode,
      },
    })
  }

  const onApartmentQueryCompleted = (homeTemplate: ApartmentTemplateQuery_homeTemplate) => {
    const {
      description,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      firsthand,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      seniorHome,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      studentHome,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      corporateHome,
      floor,
      roomCount,
      rent,
      squareMeters,
      tenantCount,
      kitchenRenovationYear,
      traits: traitTypes,
      bathroomRenovationYear,
    } = cloneWithoutTypename(homeTemplate)

    const traits = traitTypes.map((trait) => trait.type)

    setForm({
      ...form,
      description,
      firsthand,
      seniorHome,
      studentHome,
      corporateHome,
      floor,
      roomCount,
      rent,
      squareMeters,
      tenantCount,
      kitchenRenovationYear,
      bathroomRenovationYear,
      traits,
    })
  }

  const handleBuildingInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setApartmentSearch('')
    setBuildingSearch(event.target.value)
    setForm({ ...form, buildingId: null })

    fetchMoreBuildings({
      variables: {
        buildingsSearchString: event.target.value,
      },
    })
  }

  const handleBuildingChoice = (id: string) => {
    setApartmentSearch('')

    const buildingChoice = buildingsData?.buildings.nodes.find((node) => node.id === id)
    setForm({ ...form, buildingId: id })
    setBuildingSearch(buildingChoice!.buildingName!)

    if (apartmentsData && refetchApartments) {
      refetchApartments({ homeTemplatesBuildingId: id })
    } else {
      getAppartments({ variables: { homeTemplatesBuildingId: id } })
    }

    getBuildingLocation({ variables: { buildingId: id } })
  }

  const handleApartmentInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: searchString } = event.target

    setApartmentSearch(searchString)

    if (refetchApartments) {
      if (refetchApartments) {
        refetchApartments({
          homeTemplatesBuildingId: form.buildingId,
          homeTemplatesSearchString: searchString,
        })
      }
    }
  }

  const handleApartmentChoice = (id: string) => {
    const apartmentChoice = apartmentsData?.homeTemplates.nodes.find((node) => node.id === id)
    const variables = { homeTemplateId: id }
    setApartmentSearch(apartmentChoice!.apartmentNumber!)
    getApartment({ variables })
  }

  const onInputChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    let value
    if (event.target.type === 'number') {
      if (event.target.value === '') {
        value = null
      } else {
        value = Number(event.target.value)
      }
    } else {
      value = event.target.value
    }
    setForm({
      ...form,
      [event.target.name]: value,
    })
  }

  const onLocationInputChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setForm({
      ...form,
      location: {
        ...form.location,
        [event.target.name]: event.target.value,
      },
    })
  }

  const handleTraitCheck = (trait: TraitTypeEnum) => {
    if (form.traits?.includes(trait)) {
      setForm({
        ...form,
        traits: form.traits?.filter((t) => t !== trait),
      })
    } else {
      setForm({
        ...form,
        traits: [...form.traits!, trait],
      })
    }
  }

  const setLngLat = (coordinates: { longitude: number; latitude: number }) => {
    setForm({
      ...form,
      location: {
        ...form.location,
        longitude: coordinates.longitude,
        latitude: coordinates.latitude,
      },
    })
  }

  const handleContractTypeChoice = (contractType: string) => {
    setForm({
      ...form,
      corporateHome: false,
      firsthand: false,
      seniorHome: false,
      studentHome: false,
      ...(contractType !== ContractTypesEnum.secondhand && {
        [contractType]: true,
      }),
    })
  }

  const isSecondHand = () => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { corporateHome, firsthand, seniorHome, studentHome } = form
    return !corporateHome && !firsthand && !seniorHome && !studentHome
  }

  const onSubmit = () => {
    if (
      validateYearBeforeCurrent(form.kitchenRenovationYear) &&
      validateYearBeforeCurrent(form.bathroomRenovationYear)
    ) {
      setErrorMessage(null)
      upsertApartment({
        variables: {
          upsertHomeTemplateId: props.apartmentId,
          upsertHomeTemplateInput: form,
        },
      })
    } else {
      setErrorMessage(t('errors.renovation_date_future'))
    }
  }

  const onAddApartmentCompleted = (data: UpsertHomeTemplateMutation) => {
    const apartment = data!.upsertHomeTemplate
    const apartmentImageUploads = apartmentImages.map((image) => {
      return uploadApartmentImage(image, {
        ownerId: apartment!.homeTemplate!.id!,
        ownerType: OwnerTypeEnum.HOME_TEMPLATE,
        uploadType: UploadTypeEnum.home_picture,
      })
    })

    const layoutUploads = layoutImages.map((layoutImage) => {
      return uploadLayoutImage(layoutImage, {
        ownerId: apartment!.homeTemplate!.id!,
        ownerType: OwnerTypeEnum.HOME_TEMPLATE,
        uploadType: UploadTypeEnum.home_layout_picture,
      })
    })

    const imageDestructions = imagesToDestroy.map((image) => {
      return destroyUpload({
        variables: { uploadId: image.id },
      })
    })

    Promise.all([...apartmentImageUploads, ...layoutUploads, ...imageDestructions]).then(() => {
      navigate(`/homes/apartments/${apartment!.homeTemplate?.id}`, { replace: true })
    })
  }

  if (
    isLoadingApartmentImageUpload ||
    isLoadingLayoutImageUpload ||
    isLoadingAddApartment ||
    isLoadingBuildings
  ) {
    return <LoadingDots />
  }

  if (buildingsError) {
    // eslint-disable-next-line no-console
    console.log(buildingsError)
    return null
  }

  return (
    <Wrapper>
      <Content gap="6x">
        <TopSection>
          <Heading as="h1" size="md">
            {props.title}
          </Heading>
          <Stack gap={'3x'}>
            <ButtonsContainer direction={'row'} gap={'3x'}>
              <Button onClick={onSubmit} variant="secondary" disabled={!isSavedEnabled}>
                {t('commons:ctas.save')}
              </Button>
              <Button
                onClick={() =>
                  props.cancel ? props.cancel() : navigate('/homes/apartments', { replace: true })
                }
                variant="tertiary"
              >
                {t('commons:ctas.cancel')}
              </Button>
            </ButtonsContainer>
            {errorMessage && <ErrorText> {errorMessage}</ErrorText>}
          </Stack>
        </TopSection>
        <WhiteBoxWrapper>
          <InputHandler setValid={(isValid) => enableSave(isValid)}>
            {!props.apartment && (
              <>
                {props.buildingId ? (
                  ''
                ) : (
                  <>
                    <SmallHeader>{t('apartment_form.building_label')}</SmallHeader>

                    <Spacer factor={2} />

                    <DropDown
                      name="buildings"
                      value={buildingSearch}
                      values={buildingsData ? loadBuildingDropDownValues(buildingsData.buildings) : []}
                      width={'400px'}
                      placeholder={t('apartment_form.building_placeholder')}
                      onChange={handleBuildingInputChange}
                      onChoice={(id) => handleBuildingChoice(id)}
                      isValid={Boolean(form.buildingId)}
                    />

                    <Spacer factor={3} />
                  </>
                )}

                {form.buildingId ? (
                  <>
                    <SmallHeaderWithOptional
                      transparent={
                        form.buildingId === null ||
                        (apartmentsData &&
                          apartmentsData.homeTemplates.nodes.length === 0 &&
                          apartmentSearch === '')
                      }
                    >
                      {t('apartment_form.prefill_with_apartment_template_label')}
                    </SmallHeaderWithOptional>

                    <Spacer factor={2} />

                    <DropDown
                      name="apartments"
                      value={apartmentSearch}
                      values={apartmentsData ? loadApartmentDropDownValues(apartmentsData.homeTemplates) : []}
                      width={'400px'}
                      placeholder={t('apartment_form.prefill_with_apartment_template_placeholder')}
                      onChange={handleApartmentInputChange}
                      onChoice={(id) => handleApartmentChoice(id)}
                      inactive={
                        form.buildingId === null ||
                        (apartmentsData &&
                          apartmentsData.homeTemplates.nodes.length === 0 &&
                          apartmentSearch === '')
                      }
                      optional
                    />
                    <Spacer factor={4} />
                    <HorizontalDivider />
                  </>
                ) : (
                  <Information>
                    <SmallData smaller>
                      <Trans
                        t={t}
                        i18nKey={'apartment:apartment_form.building_not_found'}
                        components={[<AddBuildingLink key="0" to="/homes/buildings/add" />]}
                      />
                    </SmallData>
                  </Information>
                )}

                <Spacer factor={2} />
              </>
            )}
            {Boolean(form.buildingId) && (
              <>
                <Spacer factor={4} />

                <SmallHeader>{t('apartment_form.apartment_number')}</SmallHeader>

                <Spacer factor={2} />

                <Input
                  name="apartmentNumber"
                  value={form.apartmentNumber}
                  placeholder={t('apartment_form.apartment_number')}
                  onChange={onInputChange}
                  width={'400px'}
                />

                <Spacer factor={3} />

                <SmallHeader>{t('apartment_form.room_label')}</SmallHeader>

                <Spacer factor={2} />

                <Input
                  name="roomCount"
                  type="number"
                  min={0}
                  value={form.roomCount}
                  placeholder={t('apartment_form.room_placeholder')}
                  onChange={onInputChange}
                  width={'400px'}
                />

                <Spacer factor={3} />

                <SmallHeader>{t('apartment_form.size_label')}</SmallHeader>

                <Spacer factor={2} />

                <Input
                  name="squareMeters"
                  type="number"
                  min={0}
                  value={form.squareMeters}
                  placeholder={t('commons:square_meter')}
                  onChange={onInputChange}
                  width={'400px'}
                />

                <Spacer factor={3} />

                <SmallHeader>{t('apartment_form.address_label')}</SmallHeader>

                <Spacer factor={2} />

                <InputGroup>
                  <Input
                    name="route"
                    type="text"
                    min={0}
                    value={form.location?.route}
                    placeholder={t('apartment_form.street_placeholder')}
                    onChange={onLocationInputChange}
                    style={{ gridColumn: '1 / 3' }}
                  />
                  <Input
                    name="streetNumber"
                    type="text"
                    value={form.location?.streetNumber}
                    placeholder={t('apartment_form.street_number_placeholder')}
                    onChange={onLocationInputChange}
                  />
                  <Input
                    name="postalCode"
                    type="text"
                    value={form.location?.postalCode}
                    placeholder={t('apartment_form.post_code_placeholder')}
                    onChange={onLocationInputChange}
                  />
                </InputGroup>

                <Spacer factor={2} />

                <Map
                  width="400px"
                  {...(form.location?.longitude &&
                    form.location?.latitude && {
                      markers: [
                        {
                          longitude: form.location.longitude,
                          latitude: form.location.latitude,
                          dragable: true,
                        },
                      ],
                    })}
                  isZoomDisabled={true}
                  onLatLngChange={setLngLat}
                />
                <LngLat>
                  <LngLatDisplay>
                    {t('commons:latitude', { latitude: form.location?.latitude })}
                  </LngLatDisplay>
                  <VerticalSpacer factor={1} />
                  <LngLatDisplay>
                    {t('commons:longitude', { longitude: form.location?.longitude })}
                  </LngLatDisplay>
                </LngLat>

                <Spacer factor={3} />

                <HorizontalDivider />

                <Spacer factor={3} />

                <SmallHeader>{t('apartment_form.contract_type')}</SmallHeader>

                <Spacer factor={2} />

                {
                  // TODO -> (contractType === ContractTypesEnum.secondhand && isSecondHand())
                  // Please provide a more explanatory solotion
                  Object.keys(ContractTypesEnum).map((contractType) => (
                    <React.Fragment key={contractType}>
                      <RadioButton
                        key={contractType}
                        name={contractType}
                        label={t(`commons:contract_types.${contractType as ContractTypesEnum}`)}
                        isChecked={
                          contractType === ContractTypesEnum.secondhand && isSecondHand()
                            ? true
                            : Object(form)[contractType]
                        }
                        onClick={() => handleContractTypeChoice(contractType)}
                      />
                      <Spacer factor={2} />
                    </React.Fragment>
                  ))
                }
                <Spacer factor={3} />

                <SmallHeader>{t('apartment_form.rent_label')}</SmallHeader>

                <Spacer factor={2} />

                <Input
                  name="rent"
                  type="number"
                  min={0}
                  value={form.rent}
                  placeholder={t('apartment_form.rent_placeholder')}
                  onChange={onInputChange}
                  width={'400px'}
                />

                <Spacer factor={3} />

                <HorizontalDivider />

                <Spacer factor={4} />
                <AmenitiesSection form={form} onTraitChange={handleTraitCheck} />

                <Spacer factor={3} />

                <FurnishedSection form={form} onTraitChange={handleTraitCheck} />

                <Spacer factor={3} />

                <HorizontalDivider />

                <Spacer factor={3} />

                <SmallHeader>{t('apartment_form.max_tenants_label')}</SmallHeader>

                <Spacer factor={2} />

                <Input
                  name="tenantCount"
                  type="number"
                  min={0}
                  value={form.tenantCount}
                  placeholder={t('apartment_form.max_tenants_placeholder')}
                  onChange={onInputChange}
                  width={'400px'}
                />

                <Spacer factor={3} />

                <SmallHeader>{t('apartment_form.description_label')}</SmallHeader>

                <Spacer factor={2} />

                <Input
                  name="description"
                  value={form.description}
                  placeholder={t('apartment_form.description_placeholder')}
                  onChange={onInputChange}
                  width={'400px'}
                  optional
                  multiline
                />

                <Spacer factor={3} />

                <SmallHeader>{t('apartment_form.floor_label')}</SmallHeader>

                <Spacer factor={2} />

                <Input
                  name="floor"
                  type="number"
                  min={0}
                  value={form.floor}
                  placeholder={t('apartment_form.floor_placeholder')}
                  onChange={onInputChange}
                  width={'400px'}
                  optional
                />

                <Spacer factor={4} />
                <HorizontalDivider />
                <Spacer factor={4} />

                <RenovationSection form={form} onInputChange={onInputChange} />

                <Spacer factor={4} />
                <HorizontalDivider />
                <Spacer factor={4} />

                <SmallHeader>{t('apartment_form.apartment_images_label')}</SmallHeader>
                <Spacer factor={2} />
                <ImagesContainer>
                  {images.apartmentPictures.map(
                    (upload) =>
                      !imagesToDestroy.includes(upload) && (
                        <ObjectImage key={upload.url} src={upload.url}>
                          <RemoveImageButton
                            onRemoveImage={() => setImagesToDestroy([...imagesToDestroy, upload])}
                          />
                        </ObjectImage>
                      ),
                  )}
                  {apartmentImages.map((file, index) => (
                    <ObjectImage key={index} src={URL.createObjectURL(file)}>
                      <RemoveImageButton
                        onRemoveImage={() =>
                          setApartmentImages(apartmentImages.filter((image) => image !== file))
                        }
                      />
                    </ObjectImage>
                  ))}
                  <ImageUploadHandler
                    onAdd={(image) => setApartmentImages([...apartmentImages, ...image])}
                    onDelete={(imageToRemove) =>
                      setApartmentImages(apartmentImages.filter((image) => image !== imageToRemove))
                    }
                  />
                </ImagesContainer>

                <Spacer factor={3} />
                <SmallHeader>{t('apartment_form.layout_images_label')}</SmallHeader>
                <Spacer factor={2} />
                <ImagesContainer>
                  {images.layoutPictures.map(
                    (upload) =>
                      !imagesToDestroy.includes(upload) && (
                        <ObjectImage key={upload.url} src={upload.url}>
                          <RemoveImageButton
                            onRemoveImage={() => setImagesToDestroy([...imagesToDestroy, upload])}
                          />
                        </ObjectImage>
                      ),
                  )}
                  {layoutImages.map((file, index) => (
                    <ObjectImage key={index} src={URL.createObjectURL(file)}>
                      <RemoveImageButton
                        onRemoveImage={() => setLayoutImages(layoutImages.filter((image) => image !== file))}
                      />
                    </ObjectImage>
                  ))}
                  <ImageUploadHandler
                    onAdd={(image) => setLayoutImages([...layoutImages, ...image])}
                    onDelete={(imageToRemove) =>
                      setLayoutImages(layoutImages.filter((image) => image !== imageToRemove))
                    }
                  />
                </ImagesContainer>
              </>
            )}
          </InputHandler>

          <EndSpace />
        </WhiteBoxWrapper>
      </Content>
    </Wrapper>
  )
}
