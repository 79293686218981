import { ArrowRightIcon, ChevronRightIcon, Paragraph, Stack, Heading } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { useListingContext } from '../../context/listing-context'
import type { ListingQuery_home } from '../../data/graphql/types/__generated__/ListingQuery'
import { formatDateYYYYMMDD } from '../../util-functions/date.util'
import { useUserContext } from '../../context/user-context'

import { BaseInfoItem } from './base-info-item'

type DurationProps = {
  duration: ListingQuery_home['duration']
}

function Duration({ duration }: DurationProps) {
  const { t } = useTranslation('listing', { keyPrefix: 'duration_information' })
  const { startAsap: isStartAsap, startOptimal, endUfn: isEndUfn, endOptimal } = duration

  return (
    <Stack direction="row" alignItems="center" wrap="wrap" gap={'1x'}>
      <Paragraph>
        {isStartAsap || !startOptimal ? t('start_asap') : formatDateYYYYMMDD(new Date(startOptimal))}
      </Paragraph>
      <ChevronRightIcon size={16} />
      <Paragraph>
        {isEndUfn || !endOptimal ? t('end_undefined') : formatDateYYYYMMDD(new Date(endOptimal))}
      </Paragraph>
    </Stack>
  )
}

export function DurationInformation() {
  const { t } = useTranslation('listing', { keyPrefix: 'duration_information' })
  const { home } = useListingContext()
  const { duration, applicationDeadline } = home || {}
  const { durationPreference } = duration
  const { possibilityOfExtension: isPossibleToExtend, endUfn: isEndUfn } = duration || {}
  const { authBody } = useUserContext()
  const isProAgent = authBody?.isProAgent

  const startLatest = durationPreference?.startLatest

  return (
    <Stack gap="6x">
      <Stack>
        <Heading size={'3xs'}>{t('title')}</Heading>
        <Stack gap="3x">
          <Duration duration={duration} />
          {startLatest && (
            <Stack direction="row" alignItems="center" gap="2x">
              <ArrowRightIcon size={16} />
              <Paragraph>
                {t('flexible_move_in', {
                  end: formatDateYYYYMMDD(new Date(startLatest)),
                  defaultValue: '',
                })}
              </Paragraph>
            </Stack>
          )}
        </Stack>
      </Stack>
      {isProAgent && !isEndUfn && (
        <BaseInfoItem
          heading={t('possibility_of_extension')}
          value={t(isPossibleToExtend ? 'possible_to_extend' : 'not_possible_to_extend')}
        />
      )}
      {applicationDeadline && (
        <BaseInfoItem
          heading={t('application_deadline')}
          value={formatDateYYYYMMDD(new Date(applicationDeadline))}
        />
      )}
    </Stack>
  )
}
