import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { Button, Paragraph, Stack, Textarea } from '@qasa/qds-ui'
import { useLazyQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'

import { useUserContext } from '../../context/user-context'
import type {
  SuggestedDescription,
  SuggestedDescriptionVariables,
} from '../../data/graphql/types/__generated__/SuggestedDescription'
import { queries } from '../../data/edit-listing'

import type { FormValues } from './update-home-dialog'

const SuggestionSection = styled(Stack)(({ theme }) => ({
  padding: theme.spacing['4x'],
  borderRadius: theme.radii.md,
  backgroundColor: theme.colors.core.gray10,
}))

type DescriptionSectionProps = {
  isBlockListing?: boolean
}

export function DescriptionSection({ isBlockListing }: DescriptionSectionProps) {
  const { t } = useTranslation(['apartment', 'edit_listing'])
  const { control, setValue } = useFormContext<FormValues>()
  const { authBody } = useUserContext()
  const { listingId } = useParams<{ listingId: string }>()

  const isProAgent = authBody?.isProAgent

  const [fetchSuggestedDescription, { loading: isLoadingSuggestedDescription }] = useLazyQuery<
    SuggestedDescription,
    SuggestedDescriptionVariables
  >(queries.SUGGESTED_DESCRIPTION, {
    fetchPolicy: 'cache-and-network' /* To allow re-clicking */,
    variables: { homeId: listingId ?? '' },
    onCompleted: (data) => {
      setValue('description', data.homeEvaluation.suggestedDescription)
    },
  })

  return (
    <Stack gap={'8x'}>
      <Stack gap="4x">
        <Controller
          name="description"
          control={control}
          render={({ field: { onChange, value, name } }) => {
            return (
              <Textarea
                label={t(`apartment_form.description_label${isBlockListing ? '_group_listing' : ''}`)}
                value={value ?? undefined}
                name={name}
                onChange={onChange}
                placeholder={t('apartment_form.description_placeholder')}
                rows={9}
                resize="vertical"
              />
            )
          }}
        />
        {isProAgent && (
          <SuggestionSection gap="2x">
            <Paragraph>{t('apartment_form.description_generation_section.description')}</Paragraph>
            <Button
              variant="tertiary"
              onClick={() => fetchSuggestedDescription()}
              isLoading={isLoadingSuggestedDescription}
            >
              {t('apartment_form.description_generation_section.button')}
            </Button>
          </SuggestionSection>
        )}
      </Stack>
      <Controller
        name="descriptionContract"
        control={control}
        render={({ field: { onChange, value, name } }) => {
          return (
            <Textarea
              label={t('apartment_form.contract_description_label')}
              value={value ?? undefined}
              name={name}
              onChange={onChange}
              placeholder={t('apartment_form.contract_description_placeholder')}
              rows={5}
              resize="none"
            />
          )
        }}
      />
      {isProAgent && (
        <Controller
          name="homePitch"
          control={control}
          render={({ field: { onChange, value, name } }) => {
            return (
              <Textarea
                label={t('apartment_form.home_pitch_label')}
                value={value ?? undefined}
                name={name}
                onChange={onChange}
                placeholder={t('apartment_form.home_pitch_placeholder')}
                rows={3}
                maxLength={150}
                resize="none"
                helperText={t('apartment_form.home_pitch_helper_text')}
              />
            )
          }}
        />
      )}
    </Stack>
  )
}
