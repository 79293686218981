import gql from 'graphql-tag'

const GET_HOME_COUNTS = gql`
  query HomeCountsQuery {
    buildings {
      totalCount
    }
    homeTemplates {
      totalCount
    }
    homes {
      totalCount
    }
  }
`

const GET_BUILDINGS = gql`
  query BuildingsQuery($buildingsAfter: String, $buildingsFirst: Int, $buildingsSearchString: String) {
    buildings(after: $buildingsAfter, first: $buildingsFirst, searchString: $buildingsSearchString) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          buildingName
          updatedAt
          homeTemplateCount
          location {
            postalCode
            streetNumber
            route
            locality
          }
          uploads {
            url
            metadata {
              primary
            }
          }
        }
      }
    }
  }
`

const GET_APARTMENTS = gql`
  query ApartmentsQuery(
    $homeTemplatesFirst: Int
    $homeTemplatesAfter: String
    $homeTemplatesSearchString: String
    $homeTemplatesOrderBy: HomeTemplateOrderEnum
    $homeTemplatesBuildingId: ID
    $minSquareMeters: Int
    $minRoomCount: Int
    $maxRoomCount: Int
    $maxSquareMeters: Int
  ) {
    homeTemplates(
      first: $homeTemplatesFirst
      after: $homeTemplatesAfter
      searchString: $homeTemplatesSearchString
      orderBy: $homeTemplatesOrderBy
      buildingId: $homeTemplatesBuildingId
      minSquareMeters: $minSquareMeters
      minRoomCount: $minRoomCount
      maxRoomCount: $maxRoomCount
      maxSquareMeters: $maxSquareMeters
    ) {
      totalCount
      edges {
        cursor
        node {
          id
          apartmentNumber
          squareMeters
          roomCount
          updatedAt
          publishedHomes {
            id
            status
          }
          location {
            route
            streetNumber
            locality
            postalCode
          }
          uploads {
            url
            metadata {
              primary
            }
          }
          building {
            buildingName
            uploads {
              url
              metadata {
                primary
              }
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`

const GET_LISTINGS = gql`
  query ListingsQuery(
    $homesAfter: String
    $homesFirst: Int
    $maxRoomCount: Int
    $maxSquareMeters: Int
    $minRoomCount: Int
    $minSquareMeters: Int
    $homesOrderBy: HomeSearchOrderByEnum
    $homesOrder: HomeSearchOrderEnum
    $homesSearchString: String
    $homeStatuses: [HomeStatusEnum!]
    $onlyApplicationDeadlinePassed: Boolean
    $excludeApplicationDeadlinePassed: Boolean
  ) {
    homes(
      after: $homesAfter
      first: $homesFirst
      maxRoomCount: $maxRoomCount
      maxSquareMeters: $maxSquareMeters
      minRoomCount: $minRoomCount
      minSquareMeters: $minSquareMeters
      orderBy: $homesOrderBy
      order: $homesOrder
      searchString: $homesSearchString
      homeStatuses: $homeStatuses
      onlyApplicationDeadlinePassed: $onlyApplicationDeadlinePassed
      excludeApplicationDeadlinePassed: $excludeApplicationDeadlinePassed
    ) {
      totalCount
      edges {
        cursor
        node {
          id
          apartmentNumber
          status
          roomCount
          squareMeters
          rent
          pendingOfferingsCount
          acceptedOfferingsCount
          unreadConversationsCount
          property {
            numberOfHomes
            minRent
            maxRent
            minSquareMeters
            maxSquareMeters
            minRoomCount
            maxRoomCount
          }

          # movein
          duration {
            startAsap
            startOptimal
          }
          applicationStats {
            # applicants
            applicationCount
            # accepted
            inContactCount
          }
          pendingOfferingsCount
          acceptedOfferingsCount
          publishedAt
          lastBumpedAt
          uploads {
            url
            metadata {
              primary
            }
          }
          location {
            postalCode
            route
            streetNumber
            locality
          }
          numberOfHomes
          minRent
          maxRent
          minSquareMeters
          maxSquareMeters
          minRoomCount
          maxRoomCount
          minTenantCount
          maxTenantCount
          archivedBecauseOfApplicationDeadlinePassed
          applicationDeadline
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`

const GET_HOME_TEMPLATES_FILTER_COUNT = gql`
  query HomeTemplatesFilterTotalCountQuery(
    $minSquareMeters: Int
    $minRoomCount: Int
    $maxRoomCount: Int
    $maxSquareMeters: Int
  ) {
    homeTemplates(
      minSquareMeters: $minSquareMeters
      minRoomCount: $minRoomCount
      maxRoomCount: $maxRoomCount
      maxSquareMeters: $maxSquareMeters
    ) {
      totalCount
    }
  }
`

export const queries = {
  GET_HOME_COUNTS,
  GET_BUILDINGS,
  GET_APARTMENTS,
  GET_LISTINGS,
  GET_HOME_TEMPLATES_FILTER_COUNT,
}
