import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown'
import type { StyledComponentProps } from 'styled-components/macro'
import styled from 'styled-components/macro'
import { forwardRef } from 'react'

import { Spacer } from '../../ui-atoms/help-components'

import { ErrorMessage } from './error-message'
import { Typography } from './typography'

const Wrapper = styled.div({
  alignItems: 'center',
  display: 'inline-flex',
  background: '#fff',
  position: 'relative',
  width: '100%',
})

const StyledSelect = styled.select<{ hasError?: boolean }>(({ theme, hasError = false }) => ({
  appearance: 'none',
  border: '1px solid',
  borderRadius: theme.borderRadiuses.small,
  borderColor: hasError ? theme.palette.error100 : theme.borderColors.border100,
  backgroundColor: hasError ? theme.palette.error20 : 'initial',
  color: theme.palette.black80,
  height: 40,
  paddingRight: 30,
  paddingLeft: 12,
  background: 'transparent',
  width: '100%',
  '&:focus': {
    borderColor: theme.colors.inputs.borderFocused,
  },
}))
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.grey80,
  position: 'absolute',
  right: 12,
  fontSize: 22,
}))
const Label = styled.label({})
const LabelText = styled(Typography).attrs({ variant: 'subhead2', forwardedAs: 'span' })({})
export type SelectOption = { value: string | number; label: string }
type SelectProps = {
  placeholder?: string
  currentValue?: string | number
  className?: string
  isDisabled?: boolean
  options: SelectOption[]
  onChange(event: React.ChangeEvent<HTMLSelectElement>): void
}
type SelectErrorProps = {
  errorMessage?: string | null
  label?: string
}

// eslint-disable-next-line @typescript-eslint/ban-types
type SelectWithErrorProps = StyledComponentProps<'select', LegitimateAny, {}, never> &
  SelectProps &
  SelectErrorProps

export const SelectWithError = forwardRef<HTMLSelectElement, SelectWithErrorProps>(
  ({ placeholder, options, onChange, currentValue, errorMessage, label, className, isDisabled }, ref) => {
    const WrappingElement = label ? Label : 'div'
    return (
      <WrappingElement>
        {label && (
          <>
            <LabelText>{label}</LabelText>
            <Spacer factor={0.5} />
          </>
        )}
        <Wrapper className={className}>
          <StyledSelect
            ref={ref}
            disabled={isDisabled}
            hasError={Boolean(errorMessage)}
            onChange={onChange}
            value={currentValue}
            className={className}
          >
            {placeholder && <option value="">{placeholder}</option>}
            {options.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </StyledSelect>
          <StyledFontAwesomeIcon icon={faAngleDown} />
        </Wrapper>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </WrappingElement>
    )
  },
)
