import { NavLink } from 'react-router-dom'
import styled from '@emotion/styled'
import { Stack, Spacer } from '@qasa/qds-ui'

type SegmentControlProps = {
  children: React.ReactNodeArray
}

type SegmentProps = {
  id: string
  name: string
  count?: number | string
  onClick?: (e: React.MouseEvent) => void
  isActive?: boolean
}

const SegmentWrapper = styled(NavLink)(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  color: theme.colors.text.default,
  fontWeight: 400,
  '&.active': {
    fontWeight: 600,
  },
}))

const StyledStack = styled(Stack)(({ theme }) => ({
  height: 32,
  a: {
    position: 'relative',
    letterSpacing: '0.01em',
    ':before': {
      content: "''",
      display: 'none',
      position: 'absolute',
      height: '3px',
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: theme.colors.core.black,
    },
    '&.active': {
      letterSpacing: 0,
      '&:before': {
        display: 'flex',
      },
    },
  },
}))

export function Segment(props: SegmentProps) {
  return (
    <SegmentWrapper to={props.id}>
      {props.name}
      {props.count !== undefined && (
        <>
          <Spacer axis="x" size="1x" />
          {`(${props.count})`}
        </>
      )}
    </SegmentWrapper>
  )
}

export function SegmentControl(props: SegmentControlProps) {
  return (
    <StyledStack direction="row" gap="4x">
      {props.children}
    </StyledStack>
  )
}
