import { EditDialog } from '@qasa/app/src/modules/edit-dialog'
import { useTranslation } from 'react-i18next'
import { Suspense, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { Button, LoadingDots, Stack } from '@qasa/qds-ui'
import type { FetchResult } from '@apollo/client'
import { Paragraph } from '@qasa/ui'

import { useListingContext } from '../../context/listing-context'
import { useUploadImage } from '../../hooks/use-upload-image'
import { OwnerTypeEnum, UploadTypeEnum } from '../../data/graphql/types/__generated__/globalTypes'
import { useOptimisticUpdateOfHomeUpload } from '../listing/use-optimistic-image-upload'
import type { UpdateUploadMutation } from '../../data/graphql/types/__generated__/UpdateUploadMutation'
import type { UploadData } from '../../types/uploads'

import { HomeImages } from './home-images'

export function UpdateImagesDialog() {
  const { t } = useTranslation(['edit_listing', 'commons'])
  const { listingId } = useParams<{ listingId: string }>()
  const { isEditDialogOpen, setIsEditDialogOpen, home } = useListingContext()
  const { uploads: homeUploads } = home
  const [imagesToUpload, setImagesToUpload] = useState<File[]>([])

  const [uploadListingImage, { isLoading: isLoadingListingImageUpload }] = useUploadImage()
  const [isSortLoading, setIsSortLoading] = useState(false)
  const updateUpload = useOptimisticUpdateOfHomeUpload()

  // NOTE: not really using the form here since the File type is not supported in RHF v7 but still keeping the
  // structure since it will likely be supported in v8 so we can refactor this when we can upgrade
  const methods = useForm()

  const handleSort = async (newImagesOrder: UploadData[]) => {
    setIsSortLoading(true)
    const updateUploadPromises: Promise<FetchResult<UpdateUploadMutation>>[] = []
    homeUploads.forEach((upload, index) => {
      const newIndex = newImagesOrder.indexOf(upload)
      if (index !== newIndex) {
        updateUploadPromises.push(
          updateUpload({
            variables: {
              id: upload.id,
              input: {
                metadata: {
                  order: newIndex + 1,
                },
              },
            },
          }),
        )
      }
    })
    Promise.all(updateUploadPromises).finally(() => setIsSortLoading(false))
  }
  const handleUpload = () => {
    const uploadPromises: Promise<unknown>[] = []
    imagesToUpload.forEach((image) => {
      uploadPromises.push(
        uploadListingImage(image, {
          ownerId: listingId ?? '',
          ownerType: OwnerTypeEnum.HOME,
          uploadType: UploadTypeEnum.home_picture,
        }),
      )
    })

    Promise.all([...uploadPromises]).then(() => {
      setImagesToUpload([])
    })
  }

  useEffect(() => {
    if (imagesToUpload.length > 0) {
      handleUpload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagesToUpload])

  const handleSubmit = () => {
    setIsEditDialogOpen(false)
  }

  return (
    <EditDialog
      isOpen={isEditDialogOpen}
      onOpenChange={setIsEditDialogOpen}
      title={t('edit_sections.home_images')}
      formMethods={methods}
      formId="edit-listing-form"
      onSubmit={handleSubmit}
      customFooter={
        <Button
          variant={'primary'}
          isLoading={isLoadingListingImageUpload || isSortLoading}
          type="submit"
          form="edit-listing-form"
        >
          {t('commons:ctas.save')}
        </Button>
      }
    >
      <Suspense fallback={<LoadingDots />}>
        <Stack gap="4x">
          <Paragraph>{t('edit_sections.home_images_description')}</Paragraph>
          <HomeImages images={homeUploads} handleSort={handleSort} setImagesToUpload={setImagesToUpload} />
        </Stack>
      </Suspense>
    </EditDialog>
  )
}
