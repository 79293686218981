import { Heading } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

type PageNotFoundProps = {
  children?: React.ReactNode
}
export function PageNotFound(props: PageNotFoundProps) {
  const { t } = useTranslation('page_not_found')
  return (
    <div>
      <Heading as="h1" size="md">
        {t('title')}
      </Heading>
      <Heading>{t('description')}</Heading>
      {props.children}
    </div>
  )
}
