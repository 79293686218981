import { useMutation } from '@apollo/client'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Paragraph, IconButton, Stack, TrashIcon } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import { Dialog } from '@qasa/app/src/components/dialog'

import { mutations as listingMutations } from '../../data/listing'
import { HomeArchiveReasonEnum } from '../../data/graphql/types/__generated__/globalTypes'
import { useListingContext } from '../../context/listing-context'

export function ArchiveListing() {
  const { t } = useTranslation(['listing', 'commons'])
  const { home } = useListingContext()
  const { id } = home || {}
  const navigate = useNavigate()
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false)
  const [archiveListing, { loading: isLoadingArchive }] = useMutation(listingMutations.ARCHIVE_LISTING, {
    variables: { archiveHomeId: id, archiveReason: HomeArchiveReasonEnum.other_reason },
    onCompleted: () => navigate(0),
  })

  return (
    <Dialog
      isOpen={isArchiveModalOpen}
      onOpenChange={(isOpen) => setIsArchiveModalOpen(isOpen)}
      size="sm"
      trigger={
        <IconButton
          variant="tertiary"
          icon={TrashIcon}
          label="archive-button"
          title={t('icon_tooltips.archive')}
        />
      }
    >
      <Dialog.Body>
        <Stack gap="4x">
          <Dialog.Header>
            <Dialog.Title>{t('listing_actions.archive_modal_title')}</Dialog.Title>
          </Dialog.Header>
          <Paragraph>{t('listing_actions.archive_modal_description')}</Paragraph>
        </Stack>
      </Dialog.Body>
      <Dialog.Footer>
        <Stack gap="2x" direction={'row'} justifyContent={'center'}>
          <Button variant={'tertiary'} onClick={() => archiveListing()} isLoading={isLoadingArchive}>
            {t('listing_actions.archive_action')}
          </Button>
          <Button variant={'primary'} onClick={() => setIsArchiveModalOpen(false)}>
            {t('commons:ctas.cancel')}
          </Button>
        </Stack>
      </Dialog.Footer>
    </Dialog>
  )
}
