import { Fragment } from 'react'
import { Stack, Paragraph, Heading } from '@qasa/qds-ui'
import toPairs from 'lodash/toPairs'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

import { useListingContext } from '../../context/listing-context'
import { Icon } from '../../ui-shared/_core/icon'

const Item = Stack
const List = Stack

function getRentalRequirement(requirement: string) {
  const rentalRequirements: Record<string, string> = {
    approvedCreditCheck: i18next.t('applications:verifications_filter.no_payment_remarks'),
    rentMultiplier: i18next.t('listing:rental_requirements.rent_multiplier'),
    verifiedIdNumber: i18next.t('applications:verifications_filter.verified_id'),
    verifiedIncome: i18next.t('applications:verifications_filter.verified_income'),
  }
  return rentalRequirements[requirement] ? rentalRequirements[requirement] : requirement
}

export function RentalRequirements() {
  const { t } = useTranslation(['listing', 'applications'])
  const { home } = useListingContext()
  const { rentalRequirement } = home || {}

  const requirements = rentalRequirement
    ? toPairs(rentalRequirement).filter((value) => typeof value[1] === 'boolean' && value[1])
    : []

  return (
    <Stack gap="1x">
      <Heading size="3xs">{t('rental_requirements.title')}</Heading>
      {rentalRequirement && requirements.length ? (
        <List gap="2x">
          {Object.entries(rentalRequirement)
            .filter((k) => k[0] !== 'id' && k[0] !== '__typename' && k[0] !== 'rentMultiplier' && k[1])
            .map((key, value) => (
              <Fragment key={key.toString()}>
                <Item direction="row" alignItems="center" gap="2x">
                  <Icon icon="shieldCheck" />
                  <Paragraph>
                    {`${getRentalRequirement(key[0].toString())}${
                      key[0] === 'verifiedIncome' ? ` (${rentalRequirement['rentMultiplier']}x)` : ''
                    }`}
                  </Paragraph>
                </Item>
              </Fragment>
            ))}
        </List>
      ) : (
        <Paragraph>{t('rental_requirements.no_requirements')}</Paragraph>
      )}
    </Stack>
  )
}
