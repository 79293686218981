import { useMutation } from '@apollo/client'
import styled from 'styled-components/macro'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { ArrowLeftIcon, Divider, IconButton, Stack } from '@qasa/qds-ui'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'

import { palette } from '../../../styling'
import { mutations } from '../../../data/applications-by-board-list'
import { ToolTip } from '../../../ui-atoms/tooltip'
import { ColumnMenu } from '../board/column-menu'
import type { AddHomeApplicationsToBoardList } from '../../../data/graphql/types/__generated__/AddHomeApplicationsToBoardList'
import type { HomeApplicationOrderEnum } from '../../../data/graphql/types/__generated__/globalTypes'
import { TenantVerificationEnum } from '../../../data/graphql/types/__generated__/globalTypes'
import { useApplicationsContext } from '../../../context/applications-context'
import { FilterIcon } from '../board/column-header'
import { ApplicationListSort } from '../application-list-sort'
import { ApplicationListFilters } from '../application-list-filters'
import type { IncomeRange } from '../application-list-filters'

import type { RemovedListFilterOrSortParams } from './removed-list'

const RemovedActionsWrapper = styled.div({
  width: '100%',
  maxWidth: '1400px',
  display: 'flex',
  flexDirection: 'row',
  padding: 12,
  justifyContent: 'flex-end',
  borderBottom: `1px solid ${palette.blocketGreyLight}`,
  zIndex: 1,
  cursor: 'pointer',
})

type RemovedActionsProps = {
  ongoingColumn: string | undefined
  onFilterOrSort: (newValues: RemovedListFilterOrSortParams) => void
}
export function RemovedActions({ ongoingColumn, onFilterOrSort }: RemovedActionsProps) {
  const { selectedApplicantIds, selectedColumnId } = useApplicationsContext()
  const { t } = useTranslation('applicant', { keyPrefix: 'applicant_actions' })
  const [filters, setFilters] = useState<TenantVerificationEnum[]>([])
  const [incomeRange, setIncomeRange] = useState<IncomeRange | undefined>(undefined)
  const [{ listing: listingId }] = useQueryParams({ listing: withDefault(StringParam, null) })

  const navigate = useNavigate()

  const [addHomeApplicationToBoardList] = useMutation<AddHomeApplicationsToBoardList>(
    mutations.ADD_HOME_APPLICATIONS_TO_BOARD_LIST,
    {
      onCompleted: () => navigate(`/applications/requests?listing=${listingId}`),
    },
  )

  const isActionsActive = selectedApplicantIds.length > 0 && selectedColumnId === 'removed'

  const handleMultiMove = () => {
    addHomeApplicationToBoardList({
      variables: { applicationBoardListId: ongoingColumn, homeApplicationIds: selectedApplicantIds },
    })
  }

  const handleSort = (orderValue: HomeApplicationOrderEnum) => {
    onFilterOrSort({
      homeApplicationsOrderBy: orderValue,
    })
  }

  const handleResetFilters = () => {
    setFilters([])
    setIncomeRange(undefined)
    onFilterOrSort({
      homeApplicationsTenantVerifications: [],
      minIncome: undefined,
      maxIncome: undefined,
    })
  }

  const handleFiltersChange = (newFilters: TenantVerificationEnum[]) => {
    setFilters(newFilters)
    const hasIncomeFilter = newFilters.includes(TenantVerificationEnum.income_verified)
    onFilterOrSort({
      homeApplicationsTenantVerifications: newFilters,
      minIncome: hasIncomeFilter ? incomeRange?.min : undefined,
      maxIncome: hasIncomeFilter ? incomeRange?.max : undefined,
    })
  }

  const handleIncomeRangeChange = (newIncomeRange?: IncomeRange) => {
    setIncomeRange(newIncomeRange)
    const hasIncomeFilter = filters.includes(TenantVerificationEnum.income_verified)
    onFilterOrSort({
      homeApplicationsTenantVerifications: filters,
      minIncome: hasIncomeFilter ? newIncomeRange?.min : undefined,
      maxIncome: hasIncomeFilter ? newIncomeRange?.max : undefined,
    })
  }

  return (
    <RemovedActionsWrapper>
      {isActionsActive && (
        <ToolTip content={t('move_to_ongoing')}>
          <IconButton label={t('move_to_ongoing')} icon={ArrowLeftIcon} size="xs" onClick={handleMultiMove} />
        </ToolTip>
      )}
      <ColumnMenu Icon={<IconButton icon={FilterIcon} label="Filter" size="xs" />}>
        <Stack divider={<Divider />} gap="3x">
          <ApplicationListSort onSort={handleSort} columnKey={`removedApplicants_${listingId}`} />
          <ApplicationListFilters
            reset={handleResetFilters}
            values={filters}
            onChange={handleFiltersChange}
            onRangeChange={handleIncomeRangeChange}
            incomeRange={incomeRange}
          />
        </Stack>
      </ColumnMenu>
    </RemovedActionsWrapper>
  )
}
