import styled from 'styled-components/macro'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton, ArrowRightIcon } from '@qasa/qds-ui'

import { ToolTip } from '../../../ui-atoms/tooltip'
import { fontSize, palette } from '../../../styling'
import { HorizontalDivider } from '../../../ui-atoms/help-components'
import { ApplicationBoardListDefaultTypeEnum } from '../../../data/graphql/types/__generated__/globalTypes'
import type { ApplicationBoardLists } from '../../../data/graphql/types/__generated__/ApplicationBoardLists'

const MoveToMenuWrapper = styled.div({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  userSelect: 'none',
})

const MoveToMenu = styled.div({
  width: '100px',
  backgroundColor: 'white',
  boxShadow: `0 0 5px ${palette.shadow}`,
  position: 'absolute',
  display: 'flex',
  alginItems: 'center',
  right: -10,
  top: 15,
  zIndex: 20,
  padding: '3px 6px',
  flexDirection: 'column',
})

const Title = styled.p({
  fontSize: fontSize.textSm,
  fontWeight: 600,
  padding: '3px',
})

const MenuOption = styled.div({
  fontSize: fontSize.textSm,
  padding: '3px',
  cursor: 'pointer',
})

type MoveApplicantMenuProps = {
  currentColumnId: string
  columns: ApplicationBoardLists
  isActive?: boolean
  handleMove: (columnId: string) => void
}

const ongoing = ApplicationBoardListDefaultTypeEnum.ongoing
const pending = ApplicationBoardListDefaultTypeEnum.pending

export function MoveApplicantMenu({
  currentColumnId,
  columns,
  isActive,
  handleMove,
}: MoveApplicantMenuProps) {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const dataForSorting = columns && columns.home ? columns?.home?.applicationBoardLists : []
  const copyOfData = [...dataForSorting]
  const sortedData = copyOfData.sort((a, b) => {
    return a.order - b.order
  })

  const { t } = useTranslation('applicant', { keyPrefix: 'applicant_actions' })

  const handleClick = (id: string) => {
    handleMove(id)
    setIsOpen(false)
  }

  const handleOutisideClick = (e: MouseEvent) => {
    if (ref.current && e.target && !ref.current.contains(e.target as Node)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutisideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutisideClick)
    }
  }, [])
  return (
    <MoveToMenuWrapper>
      <ToolTip content={t('move')} isDisabled={!isActive}>
        <IconButton
          label={t('move')}
          icon={ArrowRightIcon}
          size="xs"
          onClick={() => {
            if (isActive) setIsOpen(!isOpen)
          }}
        />
      </ToolTip>
      {isOpen && (
        <MoveToMenu ref={ref}>
          <Title>{t('move_to')}</Title>
          <HorizontalDivider />
          {sortedData.map((column) => {
            const name = column.name
              ? column.name
              : column.defaultType === pending
                ? t('incoming')
                : column.defaultType === ongoing
                  ? t('ongoing')
                  : t('assigned')
            if (column.id === currentColumnId || column.defaultType === pending)
              return <div key={column.id} />
            return (
              <div key={column.id}>
                <MenuOption onClick={() => handleClick(column.id)}>{name}</MenuOption>
                <HorizontalDivider />
              </div>
            )
          })}
        </MoveToMenu>
      )}
    </MoveToMenuWrapper>
  )
}
