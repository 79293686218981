import { Fragment } from 'react'
import { Label, Stack } from '@qasa/ui'

import { VisuallyHidden } from '../visually-hidden'

import type { RadioGroupProps } from './radio-group.types'
import { RadioGroupContext } from './radio-group-context'

/**
 * @deprecated - Use `RadioGroup` from `@qasa/qds-ui` instead
 */
export function RadioGroup({
  label,
  children,
  name,
  isLabelVisuallyHidden,
  value,
  onChange,
}: RadioGroupProps) {
  const groupContextValue = { name, onChange, value }

  const ariaLabelId = ['radio-group-label', name].join('-')
  const LabelWrapper = isLabelVisuallyHidden ? VisuallyHidden : Fragment

  return (
    <RadioGroupContext.Provider value={groupContextValue}>
      <Stack gap="2x" aria-labelledby={ariaLabelId}>
        <LabelWrapper>
          <Label id={ariaLabelId}>{label}</Label>
        </LabelWrapper>
        {children}
      </Stack>
    </RadioGroupContext.Provider>
  )
}
