import type { TenantVerificationEnum } from '../../../data/graphql/types/__generated__/globalTypes'
import { useApplicationsContext } from '../../../context/applications-context'
import { ApplicationListFilters } from '../application-list-filters'

export function ColumnFilters() {
  const {
    pendingColumnSettings: { incomeRange, filters },
    updatePendingColumnSettings,
  } = useApplicationsContext()

  const filterByVerifications = (filterValues: TenantVerificationEnum[]) => {
    updatePendingColumnSettings({
      filters: filterValues,
    })
  }

  const resetFilters = () => {
    updatePendingColumnSettings({
      incomeRange: { min: 0, max: 100_000 },
      filters: [],
    })
  }

  const handleIncomeRangeChange = (incomeRange?: { min: number; max: number }) => {
    updatePendingColumnSettings({
      incomeRange,
    })
  }

  return (
    <ApplicationListFilters
      reset={resetFilters}
      values={filters}
      onChange={filterByVerifications}
      onRangeChange={handleIncomeRangeChange}
      incomeRange={incomeRange}
    />
  )
}
