import styled from 'styled-components/macro'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { RadioButton } from '../../../ui-atoms/radio-button'
import {
  ContractSmallRepairsEnum,
  InsuranceResponsibilityEnum,
} from '../../../data/graphql/types/__generated__/globalTypes'
import { Stack } from '../../../ui-shared/_core/stack'

import { ContractFormInputHeader } from './contract-form-input-header'
import { ContractFormSection } from './contract-form-section'

const RadioButtonGroup = styled(Stack).attrs({ gap: 1 })({})

export function ContractFormOtherTerms() {
  const { control } = useFormContext()
  const { t } = useTranslation('applicant', {
    keyPrefix: 'contract_form_other_terms',
  })

  return (
    <ContractFormSection title={t('title')}>
      <RadioButtonGroup>
        <ContractFormInputHeader label={t('landlord_keeps_keys.question')} />
        <Controller
          name={'landlordHasKeys'}
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <>
                <RadioButton
                  label={t('landlord_keeps_keys.yes')}
                  isChecked={value}
                  onClick={() => onChange(true)}
                />
                <RadioButton
                  label={t('landlord_keeps_keys.no')}
                  isChecked={!value}
                  onClick={() => onChange(false)}
                />
              </>
            )
          }}
        />
      </RadioButtonGroup>

      <RadioButtonGroup>
        <ContractFormInputHeader label={t('who_pays_repairs.question')} />
        <Controller
          name={'smallRepairs'}
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <>
                <RadioButton
                  label={t('who_pays_repairs.landlord')}
                  isChecked={value === ContractSmallRepairsEnum.small_repairs_landlord}
                  onClick={() => onChange(ContractSmallRepairsEnum.small_repairs_landlord)}
                />
                <RadioButton
                  label={t('who_pays_repairs.tenant')}
                  isChecked={value === ContractSmallRepairsEnum.small_repairs_tenant}
                  onClick={() => onChange(ContractSmallRepairsEnum.small_repairs_tenant)}
                />
              </>
            )
          }}
        />
      </RadioButtonGroup>
      <RadioButtonGroup>
        <ContractFormInputHeader label={t('insurance_responsibility.question')} />
        <Controller
          name={'insuranceResponsibility'}
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <>
                <RadioButton
                  label={t('insurance_responsibility.tenant')}
                  isChecked={value === InsuranceResponsibilityEnum.insurance_responsibility_tenant}
                  onClick={() => onChange(InsuranceResponsibilityEnum.insurance_responsibility_tenant)}
                />
                <RadioButton
                  label={t('insurance_responsibility.landlord')}
                  isChecked={value === InsuranceResponsibilityEnum.insurance_responsibility_landlord}
                  onClick={() => onChange(InsuranceResponsibilityEnum.insurance_responsibility_landlord)}
                />
              </>
            )
          }}
        />
      </RadioButtonGroup>
      <RadioButtonGroup>
        <ContractFormInputHeader label={t('is_permanent_residence.question')} />
        <Controller
          name={'overnightStay'}
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <>
                <RadioButton
                  label={t('is_permanent_residence.no')}
                  isChecked={value}
                  onClick={() => onChange(true)}
                />
                <RadioButton
                  label={t('is_permanent_residence.yes')}
                  isChecked={!value}
                  onClick={() => onChange(false)}
                />
              </>
            )
          }}
        />
      </RadioButtonGroup>
      <RadioButtonGroup>
        <ContractFormInputHeader label={t('pets_forbidden.question')} />
        <Controller
          name={'pets'}
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <>
                <RadioButton
                  label={t('pets_forbidden.no')}
                  isChecked={value}
                  onClick={() => onChange(true)}
                />
                <RadioButton
                  label={t('pets_forbidden.yes')}
                  isChecked={!value}
                  onClick={() => onChange(false)}
                />
              </>
            )
          }}
        />
      </RadioButtonGroup>
      <RadioButtonGroup>
        <ContractFormInputHeader label={t('smoking_forbidden.question')} />
        <Controller
          name={'smoker'}
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <>
                <RadioButton
                  label={t('smoking_forbidden.no')}
                  isChecked={value}
                  onClick={() => onChange(true)}
                />
                <RadioButton
                  label={t('smoking_forbidden.yes')}
                  isChecked={!value}
                  onClick={() => onChange(false)}
                />
              </>
            )
          }}
        />
      </RadioButtonGroup>
    </ContractFormSection>
  )
}
