import { gql } from '@apollo/client'

export const tenantProfileCompleteness = gql`
  fragment TenantProfileCompleteness on User {
    tenantProfileCompleteness {
      completenessScore
      tenantProfileFields {
        bioLived {
          completed
        }
        bio {
          completed
        }
        firstName {
          completed
        }
        idNumberConfirmed {
          completed
        }
        matchingProfile {
          completed
        }
        movingReason {
          completed
        }
        profilePicture {
          completed
        }
        bioPets {
          completed
        }
        bioTitle {
          completed
        }
        currentAgreementType {
          completed
        }
      }
    }
  }
`
