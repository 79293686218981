import type { ApolloQueryResult } from '@apollo/client'
import { useState } from 'react'

type UseFetchMoreOnScrollToBottomParams<T> = {
  handleFetchMore: () => Promise<ApolloQueryResult<T>>
  shouldFetchMore?: boolean
}

export function useFetchMoreOnScrollToBottom<T>({
  handleFetchMore,
  shouldFetchMore = true,
}: UseFetchMoreOnScrollToBottomParams<T>) {
  const [isFetchingMore, setIsFetchingMore] = useState(false)

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollHeight, clientHeight, scrollTop } = event.currentTarget
    const isBottomOfContainer = scrollTop + clientHeight >= scrollHeight - 5

    if (isBottomOfContainer && shouldFetchMore && !isFetchingMore) {
      setIsFetchingMore(true)
      handleFetchMore().finally(() => {
        setIsFetchingMore(false)
      })
    }
  }

  return { handleScroll, isFetchingMore }
}
