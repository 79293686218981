import styled from '@emotion/styled'
import { Stack, Heading, Paragraph } from '@qasa/qds-ui'

type StyledParagraphProps = {
  longText?: boolean
}

const StyledParagraph = styled(Paragraph)(({ longText }: StyledParagraphProps) => ({
  ...(longText && {
    maxWidth: '80%',
    whiteSpace: 'pre-line',
  }),
}))

type Props = {
  heading: string
  value: string
  longText?: boolean
}

export function BaseInfoItem({ heading, value, longText }: Props) {
  return (
    <Stack>
      <Heading size={'3xs'}>{heading}</Heading>
      <StyledParagraph longText={longText}>{value}</StyledParagraph>
    </Stack>
  )
}
