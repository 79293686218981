import styled from 'styled-components/macro'

import { ApplicantTab, useApplicantContext } from '../../context/applicant-context'
import { ApplicantOverview } from '../applications/overview/overview'
import { Document } from '../applications/documents/documents'
import { Comments } from '../applications/comments/comments'
import { ApplicantNestedNavigator } from '../applications/applicant-nested-navigator'
import { Stack } from '../../ui-shared/_core/stack'

import { Contract } from './contract'
import { Chat } from './conversation/chat'

const Wrapper = styled(Stack)({
  padding: '0 24px',
  height: 'calc(100% - 70px)',
  overflow: 'hidden',
})

const ContentWrapper = styled.div({
  overflow: 'auto',
  paddingRight: 16,
  paddingBottom: 24,
  boxSizing: 'border-box',
  height: '100%',
})

export function ApplicantContent() {
  const { currentTab, applicantData } = useApplicantContext()

  const renderContent = () => {
    switch (currentTab) {
      case ApplicantTab.Overview:
      default:
        return <ApplicantOverview />
      case ApplicantTab.Document:
        return <Document />
      case ApplicantTab.Conversation:
        return <Chat />
      case ApplicantTab.Comments:
        return <Comments />
      case ApplicantTab.Contract:
        return <Contract />
    }
  }

  return (
    <Wrapper>
      <ApplicantNestedNavigator data={applicantData!} />
      <ContentWrapper>{renderContent()}</ContentWrapper>
    </Wrapper>
  )
}
