import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'
import type { ReactNode } from 'react'

import { palette, pixelAmount, fontSize } from '../styling/index'
import { ReactComponent as SearchIcon } from '../assets/icons/SearchIcon.svg'
import { defaultStyles } from '../styling/defaults'
import { getPixelAmount } from '../styling/pixel-amount'
import { useGooglePlaces } from '../util-functions/google-places.util'
import type { GoogleLocation, Prediction } from '../util-functions/google-places.util'

export type InputProps = {
  width?: string | number
  type?: 'search' | 'text' | 'number' | 'password' | 'email' | 'location'
  name: string
  placeholder?: string
  value?: string | number | null
  inputFocus?: 'true' | 'false'
  errorMessage?: string
  style?: Record<string, string>
  optional?: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void
  onFocusChange?: (hasFocus: boolean) => void
  onClick?: () => void
  readOnly?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onLocationChange?: (location: GoogleLocation) => void
  isValid?: boolean
  min?: number
  max?: number
  inactive?: boolean
  options?: Array<ReactNode>
  multiline?: boolean
  multilineMaxHeight?: number
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  maxLength?: number
  color?: string
}

const Wrapper = styled.div<Partial<InputProps>>(({ width, style, errorMessage, color }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: color ? color : palette.white,
  borderRadius: pixelAmount.md,
  border: `${pixelAmount.xxs} solid ${palette.grey4}`,
  padding: pixelAmount.md,
  width: width ? width : 'auto',
  ':hover': {
    boxShadow: `0px 0px 5px ${palette.grayAlpha20}`,
  },
  ...{ style },
  ...(errorMessage && {
    border: `${pixelAmount.xxs} solid ${palette.blocketRed}`,
  }),
}))

const Icon = styled.div({
  width: pixelAmount.xxl,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  transform: 'scaleX(-1)',
  alignItems: 'center',
})

const InputComponent = styled.input<Partial<InputProps>>((props) => ({
  flex: '1 0',
  height: pixelAmount.xl,
  fontSize: fontSize.textSm,
  backgroundColor: 'transparent',
  padding: `0 ${pixelAmount.sm}`,
  border: 0,
  outline: 'none',
  width: '100%',
  '&:not(:placeholder-shown)': {
    '& + div p': {
      backgroundColor: 'white',
      transform: 'translate(-5px, -120%) scale(0.8)',
      color: palette.textAlpha50,
      fontWeight: 400,
    },
  },
  '&::-webkit-search-decoration': {
    display: 'none',
  },
  '&::-webkit-search-cancel-button': {
    display: 'none',
  },
  '&::-webkit-search-results-button': {
    display: 'none',
  },
  '&::-webkit-search-results-decoration': {
    display: 'none',
  },
  '&::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  pointerEvents: props.inactive ? 'none' : 'visible',
}))

const TextArea = styled.textarea<Partial<InputProps>>((props) => ({
  flex: '1 0',
  minHeight: props.multilineMaxHeight ? pixelAmount.xl : pixelAmount.xl7,
  maxHeight: props.multilineMaxHeight ? `${props.multilineMaxHeight}px` : pixelAmount.xl15,
  fontSize: fontSize.textSm,
  backgroundColor: 'transparent',
  resize: 'none',
  padding: `${pixelAmount.sm} ${pixelAmount.sm}`,
  border: 0,
  outline: 'none',
  width: '100%',
  '&:not(:placeholder-shown)': {
    '& + div p': {
      backgroundColor: 'white',
      transform: 'translate(-5px, -120%) scale(0.8)',
      color: palette.textAlpha50,
      fontWeight: 400,
    },
  },
  '&::-webkit-search-decoration': {
    display: 'none',
  },
  '&::-webkit-search-cancel-button': {
    display: 'none',
  },
  '&::-webkit-search-results-button': {
    display: 'none',
  },
  '&::-webkit-search-results-decoration': {
    display: 'none',
  },
  pointerEvents: props.inactive ? 'none' : 'visible',
}))

const PlaceholderWrapper = styled.div({
  padding: `0 ${pixelAmount.md}`,
  pointerEvents: 'none',
  alignItems: 'center',
  position: 'absolute',
  display: 'flex',
  bottom: 0,
  right: 0,
  left: 0,
  top: 0,
})

const Placeholder = styled.p({
  transition: `transform 0.3s, background-color 0.3s`,
  padding: `0 ${pixelAmount.sm}`,
  color: palette.grey4,
  transformOrigin: 'left',
  width: 'fit-content',
})

const InputOptions = styled.div({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const ErrorMessage = styled.div({
  position: 'absolute',
  bottom: 0,
  right: 0,
  transform: 'translateY(calc(100% + 3px))',
  fontSize: fontSize.textSm,
  color: palette.blocketRed,
  marginTop: pixelAmount.sm,
})

/**
 * @deprecated Use `TextField` from `@qasa/qds-ui` instead
 */
export function Input(props: InputProps) {
  const inputRef = useRef<HTMLInputElement>(null)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (props.inputFocus === 'true') {
      inputRef.current?.focus()
    }
    return
  }, [props.inputFocus])

  useEffect(() => {
    if (textAreaRef.current != null && props.multilineMaxHeight) {
      textAreaRef.current.style.height = '0px'
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px'
    }
  }, [props.value, props.multilineMaxHeight, textAreaRef])

  const inputProps = {
    name: props.name,
    type: props.type,
    value: props.value === null ? '' : props.value,
    placeholder: props.type === 'search' || props.multiline ? props.placeholder : ' ',
    ...(props.type === 'number' && props.min !== undefined ? { min: props.min } : {}),
    ...(props.type === 'number' && props.max !== undefined ? { SVGFEColorMatrixElement: props.max } : {}),
    autoComplete: props.type === 'password' ? 'on' : 'off',
    onChange: props.onChange,
    onClick: props.onClick,
    onFocus: () => (props.onFocusChange ? props.onFocusChange(true) : {}),
    onBlur: () => (props.onFocusChange ? props.onFocusChange(false) : {}),
    inactive: props.inactive,
    onKeyDown: props.onKeyDown,
    maxLength: props.maxLength,
    readOnly: props.readOnly,
  }
  if (props.type === 'location') return <LocationInput {...props} />

  return (
    <Wrapper width={props.width} style={props.style} errorMessage={props.errorMessage} color={props.color}>
      {props.type === 'search' && (
        <Icon>
          <SearchIcon width="22px" height="22px" stroke={palette.grey3} />
        </Icon>
      )}

      {props.multiline ? (
        <TextArea {...inputProps} ref={textAreaRef} multilineMaxHeight={props.multilineMaxHeight} />
      ) : (
        <InputComponent
          ref={inputRef}
          {...inputProps}
          onWheel={(event) => {
            if (event.currentTarget.type === 'number') event.currentTarget.blur()
          }}
        />
      )}

      {props.type !== 'search' && !props.multiline && props.placeholder && (
        <PlaceholderWrapper>
          <Placeholder>{`${props.placeholder} ${props.optional ? '' : ' *'}`}</Placeholder>
        </PlaceholderWrapper>
      )}
      {props.options && <InputOptions>{props.options}</InputOptions>}
      <ErrorMessage>{props.errorMessage}</ErrorMessage>
    </Wrapper>
  )
}

function LocationInput(props: InputProps) {
  const [predictions, setPredictions] = useState<Prediction[]>([])

  const { getDetails, getPredictions } = useGooglePlaces()

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    handleLocationSearch(event.target.value)
    props.onChange(event)
  }

  /**
   * Aborts last request if a search was made before response is retreivd.
   * @param locationSearchString
   */
  const handleLocationSearch = async (locationSearchString: string) => {
    if (locationSearchString !== '') {
      const result = await getPredictions(locationSearchString)
      if (result) {
        setPredictions(result)
      }
    } else {
      setPredictions([])
    }
  }

  const chooseLocation = async (placeId: string) => {
    const location = await getDetails(placeId)
    if (props.onLocationChange) {
      props.onLocationChange(location)
      setPredictions([])
    } else {
      throw new Error('onLocationChange prop needs to be provided to choose location')
    }
  }

  return (
    <div style={{ position: 'relative', width: props.width, ...props.style }}>
      <Input {...props} type="text" onChange={handleChange} />
      {predictions.length > 0 && (
        <Locations>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
          {predictions.map((prediction, index) => (
            <Location key={index} onClick={() => chooseLocation(prediction.placeId)}>
              {prediction.description}
            </Location>
          ))}
        </Locations>
      )}
    </div>
  )
}

const Locations = styled.div({
  position: 'absolute',
  width: '100%',
  backgroundColor: palette.white,
  border: `1px solid ${palette.blocketGrey}`,
  zIndex: 20,
  maxHeight: '200px',
  overflow: 'auto',
  fontSize: fontSize.textXs,
  borderRadius: defaultStyles.borderRadius,
})

const Location = styled.div({
  height: getPixelAmount(6),
  lineHeight: getPixelAmount(6),
  padding: '0 ' + getPixelAmount(2),
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: palette.blocketGreyLight,
  },
})
