import { useListingContext } from '../../context/listing-context'
import type {
  UpdateHomeInput,
  UpsertRentalRequirementInput,
} from '../../data/graphql/types/__generated__/globalTypes'

// TODO: add images handling when working on it
export const useEditListingInput = () => {
  const { home } = useListingContext()
  const {
    location,
    uploads,
    duration,
    minRent,
    maxRent,
    minRoomCount,
    maxRoomCount,
    minSquareMeters,
    maxSquareMeters,
    minTenantCount,
    maxTenantCount,
    numberOfHomes,
    rentalRequirement: rentalRequirementData,
    applicationDeadline,
    matterportEmbedUrl,
    instantSignSetting,
    shortcut,
    contractTemplate,
  } = home

  const listingInput: UpdateHomeInput = {
    squareMeters: home.squareMeters,
    roomCount: home.roomCount,
    firsthand: home.firsthand,
    studentHome: home.studentHome,
    seniorHome: home.seniorHome,
    corporateHome: home.corporateHome,
    tenantCount: home.tenantCount,
    rent: home.rent,
    description: home.description,
    descriptionContract: home.descriptionContract,
    apartmentNumber: home.apartmentNumber,
    energyClass: home.energyClass,
    buildYear: home.buildYear,
    kitchenRenovationYear: home.kitchenRenovationYear,
    bathroomRenovationYear: home.bathroomRenovationYear,
    floor: home.floor,
    buildingFloors: home.buildingFloors,
    traits: home.traits.map((trait) => trait.type),
    descriptionBuilding: home.descriptionBuilding,
    descriptionTransportation: home.descriptionTransportation,
    location: {
      latitude: location?.latitude,
      locality: location?.locality,
      longitude: location?.longitude,
      postalCode: location?.postalCode,
      route: location?.route,
      streetNumber: location?.streetNumber,
    },
    instantSignSettingAttributes: {
      enabled: instantSignSetting?.enabled,
    },
    duration: {
      startAsap: duration?.startAsap ? true : false,
      startOptimal: duration?.startOptimal,
      endUfn: duration?.endUfn ? true : false,
      endOptimal: duration?.endOptimal,
      possibilityOfExtension: duration?.possibilityOfExtension,
      durationPreference: {
        startLatest: duration.durationPreference?.startLatest,
      },
    },
    contractTemplateAttributes: {
      canUse: contractTemplate?.canUse,
      rentIncludes: contractTemplate?.rentIncludes,
      rentExcludes: contractTemplate?.rentExcludes,
      smallRepairs: contractTemplate?.smallRepairs,
      landlordHasKeys: contractTemplate?.landlordHasKeys,
    },
    minRent,
    maxRent,
    minRoomCount,
    maxRoomCount,
    minSquareMeters,
    maxSquareMeters,
    minTenantCount,
    maxTenantCount,
    numberOfHomes,
    applicationDeadline,
    matterportEmbedUrl,
  }

  const rentalRequirements: UpsertRentalRequirementInput = {
    approvedCreditCheck: rentalRequirementData?.approvedCreditCheck || false,
    rentMultiplier: rentalRequirementData?.rentMultiplier || 2,
    verifiedIdNumber: rentalRequirementData?.verifiedIdNumber || false,
    verifiedIncome: rentalRequirementData?.verifiedIncome || false,
  }

  return { listingInput, rentalRequirements, imageUploads: uploads, homePitch: shortcut?.homePitch }
}
