import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import type { HomeApplicationOverview_homeApplication_tenant_tenantAd_searchPreference } from '../../../data/graphql/types/__generated__/HomeApplicationOverview'
import { Spacer } from '../../../ui-atoms/help-components'
import { InformationBox } from '../../../ui-atoms/information-box'
import { formatDate } from '../../../util-functions/date.util'
import { InfoDisplay } from '../Info-display'

const ColumnsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
})

const LeftColumn = styled.div({
  flex: 1,
  width: '100%',
  flexDirection: 'column',
})

const RightColumn = styled.div({
  flex: 1,
  width: '100%',
  flexDirection: 'column',
})

type OverviewSavedSearchProps = {
  data: HomeApplicationOverview_homeApplication_tenant_tenantAd_searchPreference
}
export function OverviewSavedSearch({ data }: OverviewSavedSearchProps) {
  const { t } = useTranslation(['applications', 'commons'])

  const monthlyCost = data.maxMonthlyCost || t('commons:placeholders.not_filled_in')
  const moveInEarliest = data.startOptimal
    ? formatDate(new Date(data.startOptimal))
    : t('commons:placeholders.not_filled_in')
  const members = data.householdSize || t('commons:placeholders.not_filled_in')
  const rentalLength = data.endOptimal ? formatDate(new Date(data.endOptimal)) : 'Tillsvidare'

  const funiture =
    data.furniture === false
      ? t('overview.saved_search.prefer_unfurnished_homes')
      : data.furniture === true
        ? t('overview.saved_search.prefer_furnished_homes')
        : t('overview.saved_search.not_relevant')

  const getAreas = () => {
    if (!data.matchingAreaMetadata) return t('commons:placeholders.not_filled_in')
    const areaData = [...data.matchingAreaMetadata]
    let areas = areaData.shift()?.name
    const length = data.matchingAreaMetadata.length
    if (length > 1) {
      areaData.map((a) => {
        areas = areas + ', ' + a.name
        return areas
      })
    }
    return areas
  }

  return (
    <>
      <ColumnsWrapper>
        <LeftColumn>
          <InfoDisplay header={t('overview.saved_search.max_rent')} value={monthlyCost} />
          <Spacer factor={1} />
          <InfoDisplay header={t('overview.saved_search.earliest_move_in')} value={moveInEarliest} />
          <Spacer factor={1} />
          <InfoDisplay header={t('overview.saved_search.furnished')} value={funiture} />
          <Spacer factor={1} />
          <InfoDisplay header={t('overview.saved_search.areas')} value={getAreas()} />
        </LeftColumn>
        <RightColumn>
          <InfoDisplay header={t('overview.saved_search.household_members')} value={members} />
          <Spacer factor={1} />
          <InfoDisplay header={t('overview.saved_search.rental_length')} value={rentalLength} />
        </RightColumn>
      </ColumnsWrapper>
      <Spacer factor={2} />
      <InformationBox>{t('overview.saved_search.description')}</InformationBox>
      <Spacer factor={2} />
    </>
  )
}
