//To completly remove the cookie it needs to be expired.
export const deleteCookie = (cookieName: string) => {
  const pathSlugs = window.location.pathname.split('/')
  let pathCurrent = ' path='

  document.cookie = cookieName + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT;'

  for (let i = 0; i < pathSlugs.length; i++) {
    pathCurrent += (pathCurrent.substr(-1) !== '/' ? '/' : '') + pathSlugs[i]
    document.cookie = cookieName + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT;' + pathCurrent + ';'
  }
}
