//Import
import type { HTMLAttributes, ReactNode } from 'react'
import { forwardRef } from 'react'
import styled from 'styled-components/macro'

import type { FontSizeType } from '../../ui-styles/base.types'
import { brandTheme, colors } from '../../ui-styles/brand-themes'
import type { Colors } from '../../ui-styles/brand-themes/index.types'

//****** ELEMENTS *******/
const Element = styled.div<{
  fontSize: number
  lineHeight: string
  color: string
  fontWeight: Variant['fontWeight']
  fontFamily?: string
  isCentered?: boolean
  isSingleLine?: boolean
}>(({ theme, fontSize, lineHeight, color, fontWeight, fontFamily, isCentered, isSingleLine }) => ({
  fontSize,
  lineHeight,
  color,
  fontWeight: theme.fontWeights[fontWeight],
  fontFamily,
  margin: 0,
  textAlign: isCentered ? 'center' : 'left',
  ...(isSingleLine
    ? {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }
    : {}),
}))

//****** TYPES *******/
type Variant = {
  tag: 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  fontSize: ValueOf<FontSizeType>
  lineHeight: string
  color: string
  fontWeight: 'bold' | 'semibold' | 'normal'
  fontFamily?: string
}

type VariantsType = {
  /** Primary use: Landing Page Hero Headline */
  display: Variant
  /** Primary use: Page Headline */
  heading1: Variant
  /** Primary use: Pop-up Title, Page Headline  */
  heading2: Variant
  /** Primary use: Card/List Item Title, Pop-up Title */
  heading3: Variant
  /** Primary use: ?  */
  heading4: Variant
  /** Primary use: Form labels and subheaders */
  subhead1: Variant
  /** Primary use: Input field labels */
  subhead2: Variant
  /** Primary use: Tiny labels in home list items etc */
  caption1: Variant
  /** Primary use: Bottom navigation, Notification dots */
  caption2: Variant
  /** Primary use: Ingress text */
  bodyLarge: Variant
  /** Primary use: Default paragraph text */
  bodyMedium: Variant
  /** Primary use: Emphasized default paragraph text */
  bodyMediumBold: Variant
  /** Primary use: Short texts inside cards and lists */
  bodySmall: Variant
  /** Primary use: Emphasized short texts inside cards and lists */
  bodySmallBold: Variant
  /** Primary use: Labels in steppers, sliders*/
  bodyExtraSmall: Variant
}

//****** CONSTS *******/
const { headNav, default: defaultText } = colors.text

const VARIANTS: VariantsType = {
  display: {
    tag: 'h1',
    fontSize: 40,
    lineHeight: '48px',
    color: headNav,
    fontWeight: 'bold',
    fontFamily: brandTheme.fontFamilyDisplay,
  },
  heading1: { tag: 'h1', fontSize: 32, lineHeight: '40px', color: headNav, fontWeight: 'bold' },
  heading2: { tag: 'h2', fontSize: 24, lineHeight: '28px', color: headNav, fontWeight: 'bold' },
  heading3: { tag: 'h3', fontSize: 22, lineHeight: '28px', color: headNav, fontWeight: 'semibold' },
  heading4: { tag: 'h4', fontSize: 18, lineHeight: '24px', color: headNav, fontWeight: 'semibold' },
  subhead1: { tag: 'h5', fontSize: 16, lineHeight: '24px', color: defaultText, fontWeight: 'semibold' },
  subhead2: { tag: 'h5', fontSize: 14, lineHeight: '22px', color: defaultText, fontWeight: 'semibold' },
  caption1: { tag: 'h6', fontSize: 12, lineHeight: '20px', color: defaultText, fontWeight: 'semibold' },
  caption2: { tag: 'h6', fontSize: 11, lineHeight: '16px', color: defaultText, fontWeight: 'semibold' },
  bodyLarge: { tag: 'p', fontSize: 18, lineHeight: '28px', color: defaultText, fontWeight: 'normal' },
  bodyMedium: { tag: 'p', fontSize: 16, lineHeight: '24px', color: defaultText, fontWeight: 'normal' },
  bodyMediumBold: { tag: 'p', fontSize: 16, lineHeight: '24px', color: defaultText, fontWeight: 'semibold' },
  bodySmall: { tag: 'p', fontSize: 14, lineHeight: '20px', color: defaultText, fontWeight: 'normal' },
  bodySmallBold: { tag: 'p', fontSize: 14, lineHeight: '20px', color: defaultText, fontWeight: 'semibold' },
  bodyExtraSmall: { tag: 'p', fontSize: 12, lineHeight: '20px', color: defaultText, fontWeight: 'normal' },
}

//****** FUNCTION *******/
type TypographyVariant = keyof VariantsType

type TypograhyProps = {
  children: ReactNode
  variant?: TypographyVariant
  /** Used to override a variant's normal color. */
  colorVariant?: 'normal' | keyof Pick<Colors['text'], 'secondary'>
  isCentered?: boolean
  isSingleLine?: boolean
  className?: string
  as?: React.ElementType
} & Pick<HTMLAttributes<HTMLElement>, 'id' | 'role'>

/**
 * @deprecated Use `Heading` or `Paragraph` from `@qasa/qds-ui` instead
 */
export const Typography = forwardRef<HTMLElement, TypograhyProps>(
  (
    {
      variant = 'bodyMedium',
      colorVariant = 'normal',
      isCentered = false,
      isSingleLine = false,
      children,
      className,
      as,
      id,
      role,
    },
    forwardedRef,
  ) => {
    const { tag, color, ...props } = VARIANTS[variant]
    return (
      <Element
        ref={forwardedRef}
        as={as || tag}
        color={colorVariant !== 'normal' ? colors.text[colorVariant] : color}
        {...props}
        {...{ className, isCentered, isSingleLine, id, role }}
      >
        {children}
      </Element>
    )
  },
)

// This component is copy and pasted from P2
