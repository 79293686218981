import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { SettingsIcon, Stack, Heading, Avatar } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'

import { queries } from '../../data/notifications'
import { ReactComponent as BlocketXQasa } from '../../assets/logos/blocket-x-qasa.svg'
import { ReactComponent as BlocketXQasaSmall } from '../../assets/logos/blocket-x-qasa-small.svg'
import { useUserContext } from '../../context/user-context'
import type {
  UnreadConversationsCountQuery,
  UnreadConversationsCountQueryVariables,
} from '../../data/graphql/types/__generated__/UnreadConversationsCountQuery'
import { ConversationRoleTypeEnum } from '../../data/graphql/types/__generated__/globalTypes'

import { NavbarLabel } from './navbar-label'
import { NavLink } from './nav-link'
import {
  ApplicationsIcon,
  ArrowLeftFromLineIcon,
  ArrowRightFromLineIcon,
  BuildingsIcon,
  HelpcenterIcon,
  ListingsIcon,
  OverviewIcon,
  SignOutIcon,
} from './navbar-icons'

const NAVBAR_WIDTH = 200
const NAVBAR_WIDTH_COLLAPSED = 85

type NavBarItemType =
  | { href: string; label: string; icon: JSX.Element; badgeCount?: number }
  | { to: string; label: string; icon: JSX.Element; badgeCount?: number }

const StyledBlocketXQasaLogo = styled(BlocketXQasa)({
  height: 60,
  width: 'auto',
})

const StyledBlocketXQasaLogoSmall = styled(BlocketXQasaSmall)({
  height: 60,
  width: 'auto',
})

const Wrapper = styled.div<{ isNavbarCollapsed: boolean }>(({ theme, isNavbarCollapsed }) => ({
  paddingBlock: theme.spacing['6x'],
  ...(isNavbarCollapsed && { paddingRight: theme.spacing['4x'] }),
  height: '100vh',
  width: isNavbarCollapsed ? NAVBAR_WIDTH_COLLAPSED : NAVBAR_WIDTH,
  overflowX: 'hidden',
  transition: 'width 0.3s',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flexShrink: 0,
}))

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  borderTopRightRadius: theme.radii.full,
  borderBottomRightRadius: theme.radii.full,
  paddingBlock: theme.spacing['3x'],
  paddingInline: theme.spacing['6x'],
  ...theme.typography.body.md,
  '&:hover': {
    backgroundColor: theme.colors.core.gray30,
  },
}))

const NonShrinkableWrapper = styled.div({
  flexShrink: 0,
  height: 24,
})

const LinkWithIcon = styled(Link)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing['5x'],
}))

const ButtonWithIcon = styled.button(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing['5x'],
  whiteSpace: 'nowrap',
}))

const LogoLink = styled(Link)({
  alignSelf: 'center',
})

const BottomSectionWrapper = styled(Stack)(({ theme }) => ({
  paddingLeft: theme.spacing['6x'],
}))

type NavbarProps = {
  isNavbarCollapsed: boolean
  handleToggleNavbarCollapsed: () => void
}

export function Navbar({ isNavbarCollapsed, handleToggleNavbarCollapsed }: NavbarProps) {
  const { t } = useTranslation('navbar')
  const { isAuthenticated, authBody, logout } = useUserContext()

  const { data, error } = useQuery<UnreadConversationsCountQuery, UnreadConversationsCountQueryVariables>(
    queries.GET_UNREAD_CONVERSATIONS_COUNT,
    {
      variables: {
        onlyActiveApplications: true,
        lastMessageFrom: ConversationRoleTypeEnum.tenant,
      },
      fetchPolicy: 'no-cache',
    },
  )

  if (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }

  if (!isAuthenticated) {
    return null
  }

  const unreadMessagesCount = data?.conversations?.totalCount

  const NAV_BAR_ITEMS: NavBarItemType[] = [
    {
      to: '/',
      label: t('items.overview'),
      icon: <OverviewIcon size={24} />,
      badgeCount: unreadMessagesCount,
    },
    { to: '/applications', label: t('items.applications'), icon: <ApplicationsIcon size={24} /> },
    { to: '/listings', label: t('items.listings'), icon: <ListingsIcon size={24} /> },
    { to: '/homes', label: t('items.homes'), icon: <BuildingsIcon size={24} /> },
    {
      href: 'https://probostadblocket.zendesk.com/hc/sv',
      label: t('items.help_center'),
      icon: <HelpcenterIcon size={24} />,
    },
  ]

  return (
    <Wrapper isNavbarCollapsed={isNavbarCollapsed}>
      <Stack gap="4x">
        <LogoLink to="/">
          {isNavbarCollapsed ? <StyledBlocketXQasaLogoSmall /> : <StyledBlocketXQasaLogo />}
        </LogoLink>
        <Stack gap="1x">
          {NAV_BAR_ITEMS.map(({ label, badgeCount, icon, ...linkProps }) => (
            <StyledNavLink key={label} {...linkProps}>
              <Stack gap={isNavbarCollapsed ? '1x' : '5x'} direction="row" alignItems="center">
                <NonShrinkableWrapper>{icon}</NonShrinkableWrapper>
                <NavbarLabel isNavbarCollapsed={isNavbarCollapsed} label={label} badgeCount={badgeCount} />
              </Stack>
            </StyledNavLink>
          ))}
        </Stack>
      </Stack>
      <BottomSectionWrapper alignItems="flex-start" gap="5x">
        <Stack direction="row" alignItems="center" gap="5x">
          <Avatar src={authBody?.profilePicture?.url} size="sm" />
          {!isNavbarCollapsed && (
            <Heading size="2xs" numberOfLines={2}>
              {authBody?.companyName ?? authBody?.firstName}
            </Heading>
          )}
        </Stack>
        <LinkWithIcon to="/settings/">
          <SettingsIcon size={24} />
          {!isNavbarCollapsed && t('items.settings')}
        </LinkWithIcon>
        <ButtonWithIcon onClick={logout}>
          <SignOutIcon size={24} />
          {!isNavbarCollapsed && t('items.log_out')}
        </ButtonWithIcon>
        <ButtonWithIcon onClick={handleToggleNavbarCollapsed}>
          {isNavbarCollapsed ? <ArrowRightFromLineIcon size={24} /> : <ArrowLeftFromLineIcon size={24} />}
          {!isNavbarCollapsed && t('items.collapse_navbar')}
        </ButtonWithIcon>
      </BottomSectionWrapper>
    </Wrapper>
  )
}
