import type { RefObject } from 'react'

export const scrollToBottom = (divRef: RefObject<HTMLDivElement>, smooth = true) => {
  if (divRef.current) {
    const { scrollHeight, clientHeight, scrollTop } = divRef.current
    const amountOfScrollToBottom = scrollHeight - scrollTop - clientHeight
    smooth
      ? divRef.current.scrollBy({ top: amountOfScrollToBottom, behavior: 'smooth' })
      : divRef.current.scrollBy({ top: amountOfScrollToBottom })
  }
}
