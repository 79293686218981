import styled from '@emotion/styled'
import { Stack, Paragraph, CheckIcon, Heading } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import { EditDialog } from '@qasa/app/src/modules/edit-dialog'

import type { ApartmentQuery_homeTemplate } from '../../data/graphql/types/__generated__/ApartmentQuery'
import { getTraits } from '../../util-functions/traits.util'
import { ListingSectionEnum, useListingContext } from '../../context/listing-context'

const AmenityGrid = styled.ul({
  listStyle: 'none',
  margin: 0,
  padding: 0,
  display: 'grid',
  columnGap: 8,
  rowGap: 12,
  gridTemplateColumns: 'repeat(3,1fr)',
})

const LeftContainer = styled(Stack)({
  flexGrow: 1,
})
const RightContainer = Stack

type Props = Pick<ApartmentQuery_homeTemplate, 'traits'>
export function ApartmentAmenities({ traits }: Props) {
  const { setActiveEditSection, setIsEditDialogOpen } = useListingContext()
  const { t } = useTranslation('apartment', {
    keyPrefix: 'apartment_form',
  })

  return (
    <Stack gap="6x" direction="row" justifyContent={'space-between'}>
      <LeftContainer gap="2x">
        <Heading size="3xs">{t('amenities_label')}</Heading>
        <AmenityGrid>
          {traits.map((trait) => (
            <Stack key={trait.id} direction="row" alignItems="center" gap="1x">
              <CheckIcon />
              <Paragraph style={{ display: 'flex' }}>{getTraits(trait.type)}</Paragraph>
            </Stack>
          ))}
        </AmenityGrid>
      </LeftContainer>
      <RightContainer>
        <EditDialog.Trigger
          onClick={() => {
            setIsEditDialogOpen(true)
            setActiveEditSection(ListingSectionEnum.AMENITIES)
          }}
        />
      </RightContainer>
    </Stack>
  )
}
