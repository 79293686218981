import type { ThemeOverrides } from '@qasa/qds-ui'

// import blocketFavicon from '../../assets/favicons/blocket/favicon.png'
import blocketSansRegular from '../../assets/fonts/blocket/BlocketSans-Regular.woff2'
import blocketSansMedium from '../../assets/fonts/blocket/BlocketSans-Medium.woff2'
import blocketSansBold from '../../assets/fonts/blocket/BlocketSans-Bold.woff2'
import blocketSansLight from '../../assets/fonts/blocket/BlocketSans-Light.woff2'
import { baseTheme } from '../base'

// import type { LegacyPalette, TemporaryAccentPalette, BrandTheme, DisabledButton, Colors } from './index.types'
import type { BrandTheme, Colors, DisabledButton } from './index.types'

const palette = {
  red: '#F71414',
  purple: '#AE006E',
  orange: '#ff5800',
  peach: '#fb8375',
  pink: '#fdadae',
  pinkLight: '#fdd4d6',
  yellow: '#ffb500',
  blue: '#007ff1',
  blueLight: '#a0e5ff',
  green: '#009B42',
  brown: '#80310B',
  black: '#000000',
  greyDark: '#757575',
  grey: '#bebcbb',
  greyLight: '#e3e1df',
}

const colors: Colors = {
  general: {
    brand: palette.red,
    navigationHighlight: palette.red,
    background: baseTheme.palette.white,
    backgroundArea: baseTheme.palette.grey20,
    blue: palette.blue,
  },
  alerts: {
    neutral: palette.blue,
    alert: palette.red,
    danger: palette.red,
    success: palette.green,
  },
  icons: {
    default: palette.black,
    informative: palette.red,
    usp: palette.red,
    verified: palette.red,
    interactive: palette.black,
    disabled: baseTheme.palette.grey60,
  },
  buttons: {
    default: { fill: palette.black, text: baseTheme.palette.white, outline: palette.black },
    important: { fill: palette.red, text: baseTheme.palette.white, outline: palette.red },
    money: { fill: palette.red, text: baseTheme.palette.white, outline: palette.red },
    secondary: { fill: baseTheme.palette.white, text: palette.black, outline: palette.black },
  },
  inputs: {
    input: palette.red,
    borderFocused: baseTheme.palette.black100,
  },
  labels: {
    default: { fill: palette.greyLight, text: palette.black },
  },
  text: {
    headNav: palette.black,
    default: palette.black,
    secondary: palette.greyDark,
    link: palette.blue,
  },
  dividers: {
    default: baseTheme.palette.grey40,
    extra: baseTheme.palette.grey60,
  },
}

const fontFamily = 'BlocketSans'
const fonts = [
  {
    url: blocketSansLight,
    fontWeight: '300',
    fontStyle: 'normal',
  },
  {
    url: blocketSansRegular,
    fontWeight: '400',
    fontStyle: 'normal',
  },
  {
    url: blocketSansMedium,
    fontWeight: '600',
    fontStyle: 'normal',
  },
  {
    url: blocketSansBold,
    fontWeight: '700',
    fontStyle: 'normal',
  },
]

const disabledButton: DisabledButton = {
  backgroundColor: '#dfdede',
  borderColor: '#dfdede',
  color: '#959595',
}

export const blocketTheme: BrandTheme = {
  colors,
  disabledButton,
  fontFamily,
  fonts,
}

const blocketBrandPrimary = {
  brand90: '#9C0E0E',
  brand80: '#C81111',
  brand70: '#E91111',
  brand60: '#F71414',
  brand50: '#FB2A2A',
  brand40: '#FF6464',
  brand30: '#F4AFAF',
  brand20: '#FFD3D3',
  brand10: '#FEF3F3',
}

export const blocketQdsOverrides: ThemeOverrides = {
  colors: {
    core: {
      uiPink: blocketBrandPrimary.brand60,
      uiPinkLight: blocketBrandPrimary.brand50,
      uiPinkDark: blocketBrandPrimary.brand70,
    },
    bg: {
      brandPrimary: blocketBrandPrimary.brand60,
      brandPrimaryHover: blocketBrandPrimary.brand50,
      brandPrimaryActive: blocketBrandPrimary.brand70,
    },
    text: {
      onBrandPrimary: '#ffffff',
    },
  },
  typography: {
    display: {
      fontFamily,
    },
    title: {
      fontFamily,
      fontWeight: '600',
    },
    body: {
      fontFamily,
    },
    caption: {
      fontFamily,
    },
    label: {
      fontFamily,
    },
  },
}
