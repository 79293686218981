import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { Control, Path } from 'react-hook-form'
import { Controller, useFormContext } from 'react-hook-form'
import { Select } from '@qasa/ui'
import { Stack } from '@qasa/qds-ui'

import { ContractTypesEnum } from '../../types/contract-types'
import { Input } from '../../ui-atoms/input'

import type { FormValues } from './update-home-dialog'
import { RenovationSection } from './renovation-section'
import { InputHeader, InputRow } from './input-grid-styles'

type HomeDetailsProps = {
  isBlockListing: boolean
}

type ControlledNumberInputProps = {
  control: Control<FormValues>
  fieldName: Path<FormValues>
  placeholder: string
}

function ControlledNumberInput({ control, fieldName, placeholder }: ControlledNumberInputProps) {
  const { t } = useTranslation(['edit_listing', 'commons'])

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{
        required: {
          value: true,
          message: t('commons:errors.mandatory_field'),
        },
        min: {
          value: 1,
          message: t('edit_listing:errors.value_below_zero'),
        },
      }}
      render={({ field: { value, onChange, name }, fieldState: { error } }) => (
        <Input
          name={name}
          type="number"
          value={value as LegitimateAny}
          placeholder={placeholder}
          errorMessage={error?.message}
          onChange={(event) => onChange(event.target.value !== '' ? Number(event.target.value) : null)}
        />
      )}
    />
  )
}

export function HomeDetails({ isBlockListing }: HomeDetailsProps) {
  const { control, getValues, watch, reset } = useFormContext<FormValues>()
  const { t } = useTranslation(['edit_listing', 'commons', 'apartment'])

  const isCorporateHome = watch('corporateHome')
  const isFirsthand = watch('firsthand')
  const isSeniorHome = watch('seniorHome')
  const isStudentHome = watch('studentHome')
  const floor = watch('floor')

  const selectedContractType = useMemo(() => {
    switch (true) {
      case isCorporateHome:
        return ContractTypesEnum.corporateHome
      case isFirsthand:
        return ContractTypesEnum.firsthand
      case isSeniorHome:
        return ContractTypesEnum.seniorHome
      case isStudentHome:
        return ContractTypesEnum.studentHome
      default:
        return ContractTypesEnum.secondhand
    }
  }, [isCorporateHome, isFirsthand, isSeniorHome, isStudentHome])

  const contractTypeOptions = Object.keys(ContractTypesEnum).map((type) => ({
    value: type,
    label: t(`commons:contract_types.${type as ContractTypesEnum}`),
  }))

  const handleContractTypeChoice = (contractType: ContractTypesEnum) => {
    reset({
      ...getValues(),
      corporateHome: false,
      firsthand: false,
      seniorHome: false,
      studentHome: false,
      ...(contractType !== 'secondhand' && { [contractType]: true }),
    })
  }

  const buildingFloorsErrorMessage = floor ? 'building_floors' : 'value_below_zero'

  return (
    <Stack gap={'8x'}>
      {isBlockListing ? (
        <>
          <InputRow isRowsFirst>
            <InputHeader isFullWidth size="2xs">
              {t('basic_listing_information.rent')}
            </InputHeader>
            <ControlledNumberInput
              control={control}
              fieldName="minRent"
              placeholder={t('basic_listing_information.rent_min')}
            />
            <ControlledNumberInput
              control={control}
              fieldName="maxRent"
              placeholder={t('basic_listing_information.rent_max')}
            />
          </InputRow>
          <InputRow isRowsFirst>
            <InputHeader isFullWidth size="2xs">
              {t('basic_listing_information.tenant_count')}
            </InputHeader>
            <ControlledNumberInput
              control={control}
              fieldName="minTenantCount"
              placeholder={t('basic_listing_information.tenant_count_min')}
            />
            <ControlledNumberInput
              control={control}
              fieldName="maxTenantCount"
              placeholder={t('basic_listing_information.tenant_count_max')}
            />
          </InputRow>
          <InputRow isRowsFirst>
            <InputHeader isFullWidth size="2xs">
              {t('basic_listing_information.room_count')}
            </InputHeader>
            <ControlledNumberInput
              control={control}
              fieldName="minRoomCount"
              placeholder={t('basic_listing_information.room_count_min')}
            />
            <ControlledNumberInput
              control={control}
              fieldName="maxRoomCount"
              placeholder={t('basic_listing_information.room_count_max')}
            />
          </InputRow>
          <InputRow isRowsFirst>
            <InputHeader isFullWidth size="2xs">
              {t('basic_listing_information.size')}
            </InputHeader>
            <ControlledNumberInput
              control={control}
              fieldName="minSquareMeters"
              placeholder={t('basic_listing_information.size_min')}
            />
            <ControlledNumberInput
              control={control}
              fieldName="maxSquareMeters"
              placeholder={t('basic_listing_information.size_max')}
            />
          </InputRow>
        </>
      ) : (
        <>
          <InputRow>
            <InputHeader size="2xs">{t('basic_listing_information.rent')}</InputHeader>
            <ControlledNumberInput
              control={control}
              fieldName="rent"
              placeholder={t('basic_listing_information.rent')}
            />
            <InputHeader size="2xs">{t('basic_listing_information.tenant_count')}</InputHeader>
            <ControlledNumberInput
              control={control}
              fieldName="tenantCount"
              placeholder={t('basic_listing_information.tenant_count')}
            />
          </InputRow>
          <InputRow>
            <InputHeader size="2xs">{t('basic_listing_information.room_count')}</InputHeader>
            <ControlledNumberInput
              control={control}
              fieldName="roomCount"
              placeholder={t('basic_listing_information.room_count')}
            />
            <InputHeader size="2xs">{t('basic_listing_information.size')}</InputHeader>
            <ControlledNumberInput
              control={control}
              fieldName="squareMeters"
              placeholder={t('basic_listing_information.size')}
            />
          </InputRow>
        </>
      )}

      {!isBlockListing && (
        <>
          <InputRow>
            <InputHeader size="2xs">{t('apartment:apartment_form.apartment_number')}</InputHeader>
            <Controller
              name="apartmentNumber"
              control={control}
              render={({ field: { onChange, value, name } }) => {
                return (
                  <Input
                    name={name}
                    placeholder={t('apartment:apartment_form.apartment_number')}
                    onChange={(event) => {
                      onChange(event.target.value)
                    }}
                    value={value}
                    optional
                  />
                )
              }}
            />
            <InputHeader size="2xs">{t('apartment:apartment_form.floor_label')}</InputHeader>
            <Controller
              name="floor"
              control={control}
              render={({ field: { onChange, value, name } }) => {
                return (
                  <Input
                    name={name}
                    type="number"
                    placeholder={t('apartment:apartment_form.floor_placeholder')}
                    onChange={(event) => {
                      onChange(event.target.value !== '' ? Number(event.target.value) : null)
                    }}
                    value={value}
                    optional
                  />
                )
              }}
            />
          </InputRow>
          <InputRow>
            <InputHeader size="2xs">{t('apartment:apartment_form.building_floors_label')}</InputHeader>
            <Controller
              name="buildingFloors"
              control={control}
              rules={{
                min: {
                  value: floor || 0,
                  message: t(`edit_listing:errors.${buildingFloorsErrorMessage}`),
                },
              }}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                return (
                  <Input
                    name={name}
                    type="number"
                    placeholder={t('apartment:apartment_form.building_floors_placeholder')}
                    onChange={(event) => {
                      onChange(event.target.value !== '' ? Number(event.target.value) : null)
                    }}
                    value={value}
                    errorMessage={error?.message}
                    optional
                    // NOTE: small styling adjustment to align the input with the select field
                    style={{ height: '48px', alignSelf: 'end' }}
                  />
                )
              }}
            />
            <InputHeader size="2xs">{t('basic_listing_information.lease_type')}</InputHeader>
            <Select
              label={''}
              value={selectedContractType}
              options={contractTypeOptions}
              onChange={(event) => handleContractTypeChoice(event.currentTarget.value as ContractTypesEnum)}
            />
          </InputRow>
        </>
      )}
      <RenovationSection />
    </Stack>
  )
}
