import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Input } from '../../../ui-atoms/input'

import { ContractFormInputHeader } from './contract-form-input-header'
import { ContractFormSection } from './contract-form-section'

export function ContractFormAboutRent() {
  const { control } = useFormContext()
  const { t } = useTranslation('applicant', {
    keyPrefix: 'contract_form',
  })

  return (
    <ContractFormSection title={t('about_rent_title')}>
      <ContractFormInputHeader label={t('extra_spaces')} />
      <Controller
        name={'canUse'}
        control={control}
        render={({ field: { onChange, value, name } }) => {
          return (
            <Input
              name={name}
              placeholder={t('extra_spaces_example')}
              onChange={onChange}
              value={value}
              multiline
            />
          )
        }}
      />
      <ContractFormInputHeader label={t('accomodation_costs')} />
      <Controller
        name="rentIncludes"
        control={control}
        render={({ field: { onChange, value, name } }) => {
          return (
            <Input
              name={name}
              placeholder={t('accomodation_costs_example')}
              onChange={onChange}
              value={value}
              multiline
            />
          )
        }}
      />
      <ContractFormInputHeader label={t('not_included_rent')} />
      <Controller
        name="rentExcludes"
        control={control}
        render={({ field: { onChange, value, name } }) => {
          return (
            <Input
              name={name}
              placeholder={t('not_included_rent_example')}
              onChange={onChange}
              value={value}
              multiline
            />
          )
        }}
      />
    </ContractFormSection>
  )
}
