import { gql } from '@apollo/client'

import * as fragments from './fragments'

export const UPSERT_CONTRACT = gql`
  mutation UpsertContractMutation($input: UpsertContractInput!, $id: ID) {
    upsertContract(input: $input, id: $id) {
      contract {
        qasaGuaranteeCost
        insuranceCost
        qasaGuarantee
        insurance
        rentIncludes
        rentExcludes
        furniture
        inventory
        canUse
        landlordHasKeys
        subletception
        preCleaning
        postCleaning
        renovations
        temporaryInstallations
        smallRepairs
        insuranceResponsibility
        overnightStay
        pets
        smoker
        numberOfTenants
        duration {
          endOptimal
          endUfn
          startAsap
          startOptimal
          updatedAt
          id
        }
        tenantFees {
          waterFeePerTenant {
            availability
            cost {
              fixed
              isFree
            }
          }
        }
        home {
          ...BaseHome
        }
        id
        rent
        status
        paymentDay
        tenant {
          uid
          firstName
        }
        extraCosts {
          costFor
          plan
        }
      }
      errors {
        codes
        field
      }
    }
  }
  ${fragments.home}
`
