import gql from 'graphql-tag'

const GET_APARTMENT_FOR_ADD_LISTING = gql`
  query ApartmentForAddListingQuery($homeTemplateId: ID!) {
    homeTemplate(id: $homeTemplateId) {
      apartmentNumber
      corporateHome
      firsthand
      seniorHome
      studentHome
      rent
      tenantCount
      roomCount
      squareMeters
      kitchenRenovationYear
      bathroomRenovationYear
      description
      floor
      traits {
        type
        id
      }
      location {
        country
        countryCode
        latitude
        locality
        longitude
        postalCode
        route
        streetNumber
      }
      uploads {
        id
        type
        url
        title
        metadata {
          order
        }
      }
      building {
        descriptionBuilding
        descriptionTransportation
        buildYear
        energyClass
        uploads {
          url
          type
          id
          title
          metadata {
            order
          }
        }
      }
    }
  }
`

const GET_BLOCK_LISTING_PAYLOAD = gql`
  query BlockListingPayloadQuery($apartmentIds: [ID!]!) {
    generateBlockListingPayload(homeTemplateIds: $apartmentIds) {
      maxRent
      maxRoomCount
      maxSquareMeters
      minRent
      minRoomCount
      minSquareMeters
      numberOfHomes
      minTenantCount
      maxTenantCount
      location {
        country
        countryCode
        latitude
        locality
        longitude
        postalCode
        route
        streetNumber
      }
      uploads {
        id
        type
        url
        title
        metadata {
          order
        }
      }
    }
  }
`

const ADD_LISTING = gql`
  mutation CreateHomeMutation($createHomeInput: CreateHomeInput!) {
    createHome(input: $createHomeInput) {
      home {
        id
      }
      errors {
        field
        codes
      }
    }
  }
`

const PUBLISH_LISTING = gql`
  mutation PublishHomeMutation($publishHomeId: ID!) {
    publishHome(id: $publishHomeId) {
      home {
        id
      }
      errors {
        codes
        field
      }
    }
  }
`

export const queries = {
  GET_APARTMENT_FOR_ADD_LISTING,
  GET_BLOCK_LISTING_PAYLOAD,
}

export const mutations = {
  ADD_LISTING,
  PUBLISH_LISTING,
}
