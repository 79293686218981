import { useLazyQuery, useMutation } from '@apollo/client'
import type { ReactNode } from 'react'
import { useCallback, useEffect, useState, useContext, createContext } from 'react'
import { useTranslation } from 'react-i18next'
import { CurrentUserProvider } from '@qasa/app/src/contexts/current-user'
import Cookies from 'js-cookie'

import { useEffectOnMount } from '../hooks/use-effect-on-mount'
import { initializeHotjar } from '../vendor/hotjar-script'
import { deleteCookie } from '../util-functions/cookie.util'
import { useLocalStorage } from '../util-functions/use-local-storage'
import type { MeQuery } from '../data/graphql/types/__generated__/MeQuery'
import type {
  SubUserLoginMutation,
  SubUserLoginMutationVariables,
} from '../data/graphql/types/__generated__/SubUserLoginMutation'

import { LOGIN, ME } from './verify-user'

type AuthBodyType = MeQuery['me']

type MeQueryResponse = {
  me?: AuthBodyType
}

type UserContextType = {
  authBody?: AuthBodyType
  login: (object: Record<string, unknown>) => void
  logout: () => void
  clearAuthError: () => void
  authError: string
  isAuthenticated: boolean
  isLoading: boolean
  isLoadingLogin: boolean
}

// @ts-ignore
const UserContext = createContext<UserContextType>()

export function getAccessToken() {
  return Cookies.get('Access-Token')
}

function setAccessToken(token?: string) {
  if (token) {
    Cookies.set('Access-Token', token, { expires: 30 })
  } else {
    Cookies.remove('Access-Token')
  }
}

function UserProvider({ children }: { children: ReactNode }) {
  const { t } = useTranslation('commons', { keyPrefix: 'errors' })
  const [authError, setAuthError] = useState('')
  const hasAccessToken = Boolean(getAccessToken())
  const [authBody, setAuthBody] = useLocalStorage<AuthBodyType>('authBody')
  const [isInitializing, setInitializing] = useState(hasAccessToken && !authBody)
  const isAuthenticated = Boolean(authBody?.uid)

  const [login, { loading: isLoadingLogin }] = useMutation<
    SubUserLoginMutation,
    SubUserLoginMutationVariables
  >(LOGIN, {
    onCompleted: (data) => {
      const { payload, errors } = data.subUserLogin || {}
      if (errors) {
        const isProPilotDisabledError = errors.some(({ codes }) => codes.includes('pro_pilot_disabled'))

        setAuthError(isProPilotDisabledError ? t('login_error_pro_pilot_disabled') : t('login_error'))
        return
      }
      fetchLoggedUser()
      setAccessToken(payload?.accessToken)
    },
    onError: () => {
      setAuthError(t('login_error'))
    },
  })

  const [fetchLoggedUser] = useLazyQuery<MeQueryResponse>(ME, {
    onCompleted: (data) => {
      setAuthBody(data.me)
      setInitializing(false)
    },
    onError: (error) => {
      if (error.message === 'unauthorized') {
        logout()
      }
      setInitializing(false)
    },
  })

  const logout = useCallback(() => {
    setAuthBody(undefined)
    setInitializing(false)
    deleteCookie('Access-Token')
    window.location.reload()
  }, [setAuthBody, setInitializing])

  useEffectOnMount(() => {
    initializeHotjar()
    if (hasAccessToken) {
      // Runs every time the app is loaded if the user is already logged in.
      // Validates the existing access token and fetches latest user profile data
      fetchLoggedUser()
    } else {
      setAuthBody(undefined)
    }
  })

  const clearAuthError = () => {
    setAuthError('')
  }

  const value = {
    authBody,
    login,
    logout,
    authError,
    clearAuthError,
    isAuthenticated,
    isLoading: isInitializing,
    isLoadingLogin,
  }

  return (
    <UserContext.Provider value={value}>
      <CurrentUserProvider
        currentUser={
          authBody?.uid
            ? {
                ...authBody,
              }
            : undefined
        }
        isAuthenticated={isAuthenticated}
      >
        {children}
      </CurrentUserProvider>
    </UserContext.Provider>
  )
}

const useUserContext = () => useContext<UserContextType>(UserContext)

export { useUserContext, UserProvider }
