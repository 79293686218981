import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Heading, Paragraph, Stack, createStyleVariants } from '@qasa/qds-ui'

import type { HomeApplicationsByBoardList_homeApplicationsByBoardList_edges_node_conversation_messages_nodes } from '../../../data/graphql/types/__generated__/HomeApplicationsByBoardList'
import { ConversationMessageTypeEnum } from '../../../data/graphql/types/__generated__/globalTypes'
import { useUserContext } from '../../../context/user-context'

type DotVariant = 'default' | 'unread' | 'unanswered'

const getDotVariants = createStyleVariants((theme) => ({
  default: {
    backgroundColor: theme.colors.core.gray40,
  },
  unread: {
    backgroundColor: theme.colors.core.red50,
  },
  unanswered: {
    backgroundColor: theme.colors.core.yellow60,
  },
}))
const Dot = styled.div<{ variant: DotVariant }>(({ theme, variant }) => ({
  ...getDotVariants(theme)[variant],
  width: theme.sizes['3x'],
  height: theme.sizes['3x'],
  borderRadius: theme.radii.full,
  flexShrink: 0,
}))

type LastMessageTextProps = {
  isBold?: boolean
}
const LastMessageTextLarge = styled(Heading)<LastMessageTextProps>(({ theme, isBold }) => ({
  ...(!isBold && { fontWeight: theme.typography.body.sm.fontWeight }),
}))
const LastMessageTextDefault = styled(Paragraph)<LastMessageTextProps>(({ theme, isBold }) => ({
  ...(isBold && { fontWeight: theme.typography.title.sm.fontWeight }),
}))

type LastMessageProps = {
  tenantName: string | null
  lastMessage:
    | HomeApplicationsByBoardList_homeApplicationsByBoardList_edges_node_conversation_messages_nodes
    | undefined
  isUnread: boolean
  messageSize?: 'large' | 'default'
}

export function LastMessage({
  tenantName,
  lastMessage,
  isUnread,
  messageSize = 'default',
}: LastMessageProps) {
  const { authBody } = useUserContext()
  const userId = authBody?.uid
  const { t } = useTranslation(['applications', 'commons'])
  const isIncomingMessage = lastMessage?.user?.uid !== userId
  const messagePrefix = isIncomingMessage ? tenantName : t('commons:you')

  const formatMessage = () => {
    if (!lastMessage) {
      return t('applicant_card.no_messages')
    }
    if (lastMessage?.messageType === ConversationMessageTypeEnum.image) {
      return t('applicant_card.new_document')
    }
    if (lastMessage?.messageType === ConversationMessageTypeEnum.text) {
      return `${messagePrefix}: ${lastMessage.message}`
    }
    return t('applicant_card.new_event')
  }

  const isUnanswered = Boolean(lastMessage) && !isUnread && isIncomingMessage
  const dotVariant = isUnread ? 'unread' : isUnanswered ? 'unanswered' : 'default'

  const sharedProps = {
    isBold: isUnread || isUnanswered,
    numberOfLines: 1,
  }
  const LastMessageText =
    messageSize === 'large' ? (
      <LastMessageTextLarge {...sharedProps} size="3xs">
        {formatMessage()}
      </LastMessageTextLarge>
    ) : (
      <LastMessageTextDefault {...sharedProps} size="xs">
        {formatMessage()}
      </LastMessageTextDefault>
    )

  return (
    <Stack direction="row" alignItems="center" gap="1x">
      <Dot variant={dotVariant} />
      {LastMessageText}
    </Stack>
  )
}
