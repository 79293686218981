import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useMutation } from '@apollo/client'
import { Link as RouterLink } from 'react-router-dom'
import { Button, Paragraph, TextField, Link, Stack, ChevronLeftIcon, Spacer } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { mutations } from '../data/forgot-password'
import { FormWindow } from '../ui-atoms/form-window'

import { UnauthenticatedPageWrapper } from './login'

const StyledStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.colors.bg.default,
  padding: theme.spacing['8x'],
  border: `1px solid ${theme.colors.border.default}`,
  borderRadius: theme.radii.xl,
}))

const GoBackButton = styled.button({
  display: 'flex',
  alignItems: 'center',
})

export function ForgotPassword() {
  const [email, setEmail] = useState('')
  const [hasSentEmail, setEmailWasSent] = useState(false)
  const { t } = useTranslation(['password', 'commons'])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState('')

  const [subUserCreateResetPasswordToken, { data }] = useMutation(mutations.SEND_EMAIL_FOR_RESET)

  useEffect(() => {
    if (data !== undefined) {
      if (data.subUserCreateResetPasswordToken.success) {
        setEmailWasSent(true)
      }
    }
  }, [data])

  const handleResetPassword = () => {
    subUserCreateResetPasswordToken({ variables: { subUserCreateResetPasswordTokenEmail: email } })
  }

  if (hasSentEmail)
    return (
      <UnauthenticatedPageWrapper>
        <StyledStack gap="4x" direction="column">
          <Paragraph>{t('forgot_password.confirmation_message', { email })}</Paragraph>
          <Button as={RouterLink} to="/" isFullWidth>
            {t('return_to_login_after_confirmation')}
          </Button>
        </StyledStack>
      </UnauthenticatedPageWrapper>
    )

  return (
    <UnauthenticatedPageWrapper>
      <FormWindow>
        <Link as={RouterLink} to="/">
          <GoBackButton>
            <ChevronLeftIcon /> {t('forgot_password.return_to_login')}
          </GoBackButton>
        </Link>
        <Spacer size="8x" />
        <Stack gap="4x">
          <Paragraph>{t('forgot_password.instructions')}</Paragraph>
          <TextField
            label={t('commons:email')}
            isInvalid={Boolean(error)}
            errorMessage={error}
            onChange={(event) => setEmail(event.target.value)}
          />
          <Button onClick={handleResetPassword} isFullWidth>
            {t('forgot_password.send_reset_link')}
          </Button>
        </Stack>
      </FormWindow>
    </UnauthenticatedPageWrapper>
  )
}
