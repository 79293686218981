import styled from 'styled-components/macro'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMutation, gql } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { ArrowLeftIcon, IconButton, Paragraph, Stack, StarFilledIcon, StarIcon } from '@qasa/qds-ui'

import type { HomeApplicationsRequest_homeApplications_edges_node } from '../../../data/graphql/types/__generated__/HomeApplicationsRequest'
import { fontSize, palette, placeholder } from '../../../styling'
import { ApplicantCardAvatar } from '../board/applicant-card-avatar'
import { ListingApplicationStats } from '../listing-application-stats'
import { formatNumber } from '../../../util-functions/general.util'
import { getDaysFromNow } from '../../../util-functions/date.util'
import { ApplicantCardVerifications } from '../board/applicant-card-verifications'
import type { ApplicationBoardLists_home_applicationBoardCardInfo } from '../../../data/graphql/types/__generated__/ApplicationBoardLists'
import { ToolTip } from '../../../ui-atoms/tooltip'
import { HomeApplicationStatusEnum } from '../../../data/graphql/types/__generated__/globalTypes'
import { mutations as applicantMutations } from '../../../data/applicant'
import { MatchingScore } from '../../../ui-atoms/matching-score'
import { ApplicantCardNote } from '../board/applicant-card-note'

const Card = styled.div({
  borderBottom: `1px solid ${palette.blocketGreyLight}`,
  padding: '16px 0px 16px 8px',
  display: 'flex',
  flexDirection: 'row',
  cursor: 'pointer',
  margin: 0,
  alignItems: 'center',
  justifyContent: 'space-between',
  width: 'fit-content',
})

const RowWrapper = styled(Stack).attrs({ direction: 'row', align: 'center' })({})
const ApplicantCardAvatarWrapper = styled(RowWrapper)({
  width: '250px',
})
const ApplicantStatsWrapper = styled(RowWrapper)({
  width: '200px',
})
const ApplicantNotesWrapper = styled(RowWrapper)({
  width: '200px',
})
const ApplicantVerificationsWrapper = styled(RowWrapper)({
  width: '300px',
})
const IconWrapper = styled(Stack)({
  width: '200px',
})
const Name = Paragraph

const Text = styled.div({
  fontSize: fontSize.textSm,
  color: palette.blocketGrey,
  whiteSpace: 'nowrap',
  width: '200px',
  textAlign: 'right',
  paddingRight: '16px',
})
type RemovedApplicantCardProps = {
  data: HomeApplicationsRequest_homeApplications_edges_node
  handleMoveFromDeclined: (id: string) => void
  applicationBoardCardInfo: ApplicationBoardLists_home_applicationBoardCardInfo | null | undefined
}

export function RemovedApplicantCard(props: RemovedApplicantCardProps) {
  const { data, handleMoveFromDeclined, applicationBoardCardInfo } = props

  const { t } = useTranslation(['applications', 'applicant'])
  const navigate = useNavigate()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const hasListing = params.get('listing') ? true : false
  const listing = hasListing ? 'listing=' + params.get('listing')?.toString() + '&' : ''

  const {
    id,
    status,
    conversation,
    tenant,
    starMarkedByLandlord: isStarmarkedByLandlord,
    tenantFamilyName,
    home,
  } = data || {}

  const { profilePicture, firstName, createdAt, uid: tenantUid, userNotes } = tenant
  const { id: homeId } = home

  const conversationId = conversation?.id || ''
  const avatarSrc = profilePicture?.url || placeholder.avatar
  const avatarAlt = profilePicture?.title || placeholder.avatarAlt
  const currentColumnId = 'removed'
  const queueDays = formatNumber(getDaysFromNow(new Date(createdAt))) || 0

  const isRevoked = status === HomeApplicationStatusEnum.revoked
  const shouldShowMatch = applicationBoardCardInfo ? applicationBoardCardInfo.showMatchingScore : false
  const shouldShowQueue = applicationBoardCardInfo ? applicationBoardCardInfo.showQueueDays : false

  const [setStarMarked] = useMutation(applicantMutations.SET_STARMARKED, {
    update: (
      cache,
      {
        data: {
          starMarkApplication: { homeApplication },
        },
      },
    ) => {
      cache.modify({
        fields: {
          homeApplication(existingHomeApplication = {}) {
            const updatedHomeApplicationRef = cache.writeFragment({
              data: homeApplication,
              fragment: gql`
                fragment UpdatedApplication on homeApplication {
                  id
                  starMarkedByLandlord
                }
              `,
            })
            return { ...existingHomeApplication, updatedHomeApplicationRef }
          },
        },
      })
    },
  })

  const handleOnClickCard = () => {
    navigate(location.pathname + '?' + listing + 'list=' + currentColumnId + '&applicant=' + id)
  }

  const handleMoveBack = () => {
    handleMoveFromDeclined(id)
  }

  const handleStarmark = () => {
    setStarMarked({
      variables: { homeApplicationId: id, starMarkedByLandlord: !isStarmarkedByLandlord },
    })
  }

  const fullName = `${firstName} ${tenantFamilyName}`

  return (
    <Card>
      <div onClick={handleOnClickCard}>
        <Stack direction={'row'} gap="2x">
          <ApplicantCardAvatarWrapper gap="4x">
            <ApplicantCardAvatar
              id={id}
              currentColumnId={currentColumnId}
              conversationId={conversationId}
              avatarSrc={avatarSrc}
              avatarAlt={avatarAlt}
              isCheckboxHidden={isRevoked}
            />
            <Name>{fullName}</Name>
          </ApplicantCardAvatarWrapper>
          {(shouldShowMatch || shouldShowQueue) && (
            <ApplicantStatsWrapper gap="2x">
              {shouldShowMatch && <MatchingScore tenantUid={tenantUid} matchingHomeId={homeId} />}
              {shouldShowQueue && (
                <ListingApplicationStats
                  header={t('applicant_card.queue_days')}
                  value={queueDays}
                  isValueBold={true}
                />
              )}
            </ApplicantStatsWrapper>
          )}
          <ApplicantVerificationsWrapper>
            <ApplicantCardVerifications
              data={data}
              applicationBoardCardInfo={applicationBoardCardInfo}
              shouldNotHaveWidth={true}
            />
          </ApplicantVerificationsWrapper>
          <ApplicantNotesWrapper>
            <ApplicantCardNote userNotes={userNotes} />
          </ApplicantNotesWrapper>
        </Stack>
      </div>
      {!isRevoked && (
        <IconWrapper direction={'row'} justifyContent={'flex-end'}>
          <ToolTip content={t('applicant:applicant_actions.star_mark')}>
            <IconButton
              label={t('applicant:applicant_actions.star_mark')}
              icon={isStarmarkedByLandlord ? StarFilledIcon : StarIcon}
              onClick={handleStarmark}
              size="sm"
            />
          </ToolTip>
          <ToolTip content={t('applicant:applicant_actions.move_to_ongoing')}>
            <IconButton
              label={t('applicant:applicant_actions.move_to_ongoing')}
              icon={ArrowLeftIcon}
              onClick={handleMoveBack}
              size="sm"
            />
          </ToolTip>
        </IconWrapper>
      )}
      {isRevoked && <Text>{t('applications_board.revoked_application')}</Text>}
    </Card>
  )
}
