import styled from 'styled-components/macro'

import { palette } from '../styling'
import { defaultStyles } from '../styling/defaults'

import { Loader } from './loader'

type ButtonProps = {
  width?: string
  height?: string
  children: React.ReactNode
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void
  inactive?: boolean
  secondary?: boolean
  third?: boolean
  style?: Record<string, string | number>
  disabled?: boolean
  isLoading?: boolean
  type?: 'button' | 'submit' | 'reset'
}

const Wrapper = styled.button<Partial<ButtonProps>>(
  ({ width, height, inactive, secondary, third, disabled }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
    backgroundColor: palette.primary,
    border: 'none',
    outline: 'none',
    color: palette.white,
    width: width ? width : 'fit-content',
    height: height ? height : '50px',
    borderRadius: defaultStyles.borderRadius,
    textDecoration: 'line',
    cursor: disabled ? 'default' : 'pointer',
    ...(inactive && {
      pointerEvents: 'none',
      opacity: 0.5,
    }),
    ...(secondary && {
      backgroundColor: palette.white,
      border: `1px solid ${palette.blocketGrey}`,
      color: palette.textPrimary,
    }),
    ...(third && {
      backgroundColor: palette.blocketBlack,
    }),
    ...(disabled && {
      backgroundColor: palette.blocketIconDisabled,
    }),
  }),
)

/**
 * @deprecated Use `Button` from `@qasa/qds-ui` instead
 */
export function Button(props: ButtonProps) {
  return (
    <Wrapper {...props} onClick={props.onClick} style={props.style} disabled={props.disabled}>
      {props.isLoading ? <Loader /> : props.children}
    </Wrapper>
  )
}
