/* eslint-disable no-console */
import { useLazyQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { IconButton, LoadingDots, Paragraph, createIcon } from '@qasa/qds-ui'
import * as SelectPrimitive from '@radix-ui/react-select'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import { queries } from '../../../data/applicant-chat'
import type { ChatMacros, ChatMacrosVariables } from '../../../data/graphql/types/__generated__/ChatMacros'

const StyledContent = styled(SelectPrimitive.Content)(({ theme }) => ({
  zIndex: theme.zIndices.popover,
  overflow: 'hidden',
}))

const SelectContent = forwardRef<HTMLDivElement, SelectPrimitive.SelectContentProps>(
  ({ children, ...restProps }, forwardedRef) => (
    <SelectPrimitive.Portal>
      <StyledContent position="popper" {...restProps} ref={forwardedRef}>
        <SelectPrimitive.Viewport>{children}</SelectPrimitive.Viewport>
      </StyledContent>
    </SelectPrimitive.Portal>
  ),
)

const SelectGroup = styled(SelectPrimitive.Group)<{ shouldCenterContent: boolean }>(
  ({ theme, shouldCenterContent }) => ({
    paddingBlock: theme.spacing['3x'],
    width: theme.sizes['384'],
    maxHeight: '45vh',
    overflowY: 'scroll',
    backgroundColor: theme.colors.bg.default,
    border: '1px solid',
    borderColor: theme.colors.border.default,
    borderRadius: theme.radii.xs,
    ...(shouldCenterContent && {
      display: 'grid',
      placeItems: 'center',
      height: theme.sizes['128'],
    }),
  }),
)

const SelectItem = styled(SelectPrimitive.Item)(({ theme }) => ({
  paddingInline: theme.spacing['4x'],
  paddingBlock: theme.spacing['1x'],
  cursor: 'pointer',
  ':hover': {
    backgroundColor: theme.colors.bg.brandTertiaryHover,
  },
}))

const LightningBoltIcon = createIcon({
  displayName: 'LightningBoltIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      d="M8.66667 1L2 9.4H8L7.33333 15L14 6.6H8L8.66667 1Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="currentColor"
    />
  ),
})
const MacroTitle = styled(Paragraph)({ fontWeight: 600 })

type MacrosProps = {
  setMessageDraft: (message: string) => void
}

export function Macros({ setMessageDraft }: MacrosProps) {
  const { t } = useTranslation('applications', { keyPrefix: 'chat.macros' })
  const [getMacros, { data, loading: isLoading }] = useLazyQuery<ChatMacros, ChatMacrosVariables>(
    queries.GET_CHAT_MACROS,
    {
      variables: {
        first: 20,
      },
    },
  )

  const handleOpenChange = (isOpen: boolean) => {
    if (isOpen) {
      getMacros()
    }
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingDots />
    }

    if (!data || data.messageMacros.edges.length === 0) {
      return (
        <Paragraph textAlign="center" color="subtle">
          {t('no_macros')}
        </Paragraph>
      )
    }

    return (
      <>
        {data.messageMacros.edges.map(({ node: macro }) => (
          <SelectItem key={macro.id} value={macro.text} textValue={macro.title}>
            <MacroTitle numberOfLines={1}>{macro.title}</MacroTitle>
            <Paragraph color="subtle" numberOfLines={1}>
              {macro.text}
            </Paragraph>
          </SelectItem>
        ))}
      </>
    )
  }

  return (
    <SelectPrimitive.Root
      onOpenChange={handleOpenChange}
      onValueChange={(value) => {
        if (value) {
          setMessageDraft(value)
        }
      }}
    >
      <SelectPrimitive.Trigger>
        <IconButton variant="ghost" icon={LightningBoltIcon} label="macros" />
      </SelectPrimitive.Trigger>
      <SelectContent side="top" sideOffset={8}>
        <SelectGroup shouldCenterContent={isLoading || !data}>{renderContent()}</SelectGroup>
      </SelectContent>
    </SelectPrimitive.Root>
  )
}
