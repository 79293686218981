import type { Theme } from '@qasa/qds-ui'
import { Stack, LoadingDots as QDSLoadingDots } from '@qasa/qds-ui'
import styled from '@emotion/styled'

type LoadingDotsProps = {
  alignItems?: 'center' | 'flex-start' | 'flex-end' | 'stretch' | 'baseline'
  justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly'
  paddingTop?: keyof Theme['spacing']
  paddingBottom?: keyof Theme['spacing']
}

const LoadingDotsContainer = styled(Stack)<{
  paddingTop?: keyof Theme['spacing']
  paddingBottom?: keyof Theme['spacing']
}>(({ theme, paddingTop, paddingBottom }) => ({
  paddingTop: theme.spacing[paddingTop ?? '6x'],
  paddingBottom: theme.spacing[paddingBottom ?? '6x'],
}))

export function LoadingDots({ alignItems, justifyContent, paddingTop, paddingBottom }: LoadingDotsProps) {
  return (
    <LoadingDotsContainer
      alignItems={alignItems ?? 'center'}
      justifyContent={justifyContent ?? 'center'}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
    >
      <QDSLoadingDots />
    </LoadingDotsContainer>
  )
}
