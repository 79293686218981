import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { Popover } from '@qasa/app/web'
import { Stack } from '@qasa/ui'
import styled from '@emotion/styled'

import { DatePicker } from '../../ui-atoms/date-picker'
import { Radio } from '../../ui-atoms/radio'
import { formatDate, formatDateYYYYMMDD } from '../../util-functions/date.util'
import type { UpdateHomeInput } from '../../data/graphql/types/__generated__/globalTypes'
import { ErrorMessage } from '../../ui-shared/_core/error-message'
import { Checkbox } from '../../ui-atoms/checkbox'
import { useUserContext } from '../../context/user-context'

import { ApplicationDeadline } from './application-deadline'
import { InputRow, InputHeader } from './input-grid-styles'
import { LatestMoveInDatePicker } from './latest-move-in-date-picker'

enum Input {
  START_ASAP = 'duration.startAsap',
  START_OPTIMAL = 'duration.startOptimal',
  START_LATEST = 'duration.durationPreference.startLatest',
  END_UFN = 'duration.endUfn',
  END_OPTIMAL = 'duration.endOptimal',
  POSSIBILITY_OF_EXTENSTION = 'duration.possibilityOfExtension',
}

const StyledPopoverContent = styled(Popover.Content)({
  '&&': {
    width: 'fit-content',
  },
})

export function BasicListingInformation() {
  const { t } = useTranslation(['edit_listing', 'listing', 'commons', 'applicant'])
  const { authBody } = useUserContext()
  const isProAgent = authBody?.isProAgent

  const { control, setValue, watch, formState } = useFormContext<UpdateHomeInput>()

  const isStartAsap = watch(Input.START_ASAP)
  const startOptimal = watch(Input.START_OPTIMAL)
  const isEndUfn = watch(Input.END_UFN)
  const endOptimal = watch(Input.END_OPTIMAL)
  const isInstantSignEnabled = watch('instantSignSettingAttributes.enabled')

  const [isStartOpen, setStartOpen] = useState(false)
  const [isEndOpen, setEndOpen] = useState(false)

  const handleOpenDatePicker = (name?: string) => {
    if (name === Input.START_OPTIMAL) {
      if (!startOptimal) {
        setValue(Input.START_OPTIMAL, new Date().toISOString())
      }
      setStartOpen(true)
      setValue(Input.START_ASAP, false)
    } else if (name === Input.END_OPTIMAL) {
      if (!endOptimal) {
        setValue(Input.END_OPTIMAL, new Date().toISOString())
      }
      setEndOpen(true)
      setValue(Input.END_UFN, false)
    }
  }

  const { startOptimal: startOptimalError, endOptimal: endOptimalError } = formState.errors?.duration ?? {}

  return (
    <Stack gap={'8x'}>
      <Stack gap="1x">
        <InputRow isRowsFirst>
          <InputHeader isFullWidth size="2xs">
            {t('basic_listing_information.move_in')}
          </InputHeader>
          <Controller
            name={Input.START_ASAP}
            control={control}
            render={({ field: { value, name } }) => (
              <Radio
                title={t('listing:duration_information.start_asap')}
                name={name}
                isChecked={Boolean(value)}
                onClick={() => {
                  setValue(Input.START_OPTIMAL, null)
                  setValue(Input.START_LATEST, null)
                  setValue(Input.START_ASAP, true)
                }}
              />
            )}
          />
          <Controller
            name={Input.START_OPTIMAL}
            rules={{
              validate: (value, formValues) => {
                const startLatestValue = formValues?.duration?.durationPreference?.startLatest
                if (value && startLatestValue) {
                  return (
                    value < startLatestValue || t('listing:validation.earliest_move_in_after_latest_move_in')
                  )
                }

                return true
              },
            }}
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <Popover isOpen={isStartOpen} onOpenChange={() => setStartOpen(!isStartOpen)} modal={true}>
                <Popover.Trigger>
                  <button>
                    <Radio
                      title={isStartAsap || !value ? t('commons:choose_date') : formatDate(new Date(value))}
                      name={name}
                      isChecked={!isStartAsap}
                      onClick={handleOpenDatePicker}
                      icon={<FontAwesomeIcon icon={['fal', 'calendar-alt']} />}
                    />
                  </button>
                </Popover.Trigger>
                <StyledPopoverContent>
                  <Popover.Body>
                    <DatePicker
                      onChange={(date) => {
                        setStartOpen(false)
                        onChange(formatDateYYYYMMDD(new Date(date.date)))
                      }}
                      shouldProhibitPastDate
                      activeDate={value ? new Date(value) : undefined}
                    />
                  </Popover.Body>
                </StyledPopoverContent>
              </Popover>
            )}
          />
        </InputRow>
        {startOptimalError && <ErrorMessage>{startOptimalError.message}</ErrorMessage>}
      </Stack>
      {isInstantSignEnabled && !isStartAsap && (
        <LatestMoveInDatePicker startOptimal={startOptimal ? new Date(startOptimal) : null} />
      )}
      <Stack gap="1x">
        <InputRow isRowsFirst>
          <InputHeader isFullWidth size="2xs">
            {t('basic_listing_information.move_out')}
          </InputHeader>
          <Controller
            name={Input.END_UFN}
            control={control}
            render={({ field: { value, name } }) => (
              <Radio
                title={t('listing:duration_information.end_undefined')}
                name={name}
                isChecked={Boolean(value)}
                onClick={() => {
                  setValue(Input.END_OPTIMAL, null)
                  setValue(Input.END_UFN, true)
                }}
              />
            )}
          />
          <Controller
            name={Input.END_OPTIMAL}
            control={control}
            rules={{
              validate: (value, formValues) => {
                const startOptimalValue = formValues?.duration?.startOptimal ?? 0
                const startLatestValue = formValues?.duration?.durationPreference?.startLatest ?? 0

                if (value) {
                  if (value < startLatestValue && value > startOptimalValue) {
                    return t('listing:validation.move_in_date_outside_move_in_range')
                  } else if (value < startOptimalValue) {
                    return t('listing:validation.move_out_after_move_in')
                  }
                }
                return true
              },
            }}
            render={({ field: { onChange, value, name } }) => (
              <Popover isOpen={isEndOpen} onOpenChange={() => setEndOpen(!isEndOpen)} modal={true}>
                <Popover.Trigger>
                  <button>
                    <Radio
                      title={isEndUfn || !value ? t('commons:choose_date') : formatDate(new Date(value))}
                      name={name}
                      isChecked={!isEndUfn}
                      onClick={handleOpenDatePicker}
                      icon={<FontAwesomeIcon icon={['fal', 'calendar-alt']} />}
                    />
                  </button>
                </Popover.Trigger>
                <StyledPopoverContent>
                  <Popover.Body>
                    <DatePicker
                      onChange={(date) => {
                        setEndOpen(false)
                        onChange(formatDateYYYYMMDD(new Date(date.date)))
                      }}
                      shouldProhibitPastDate
                      activeDate={value ? new Date(value) : undefined}
                    />
                  </Popover.Body>
                </StyledPopoverContent>
              </Popover>
            )}
          />
        </InputRow>
        {endOptimalError && <ErrorMessage>{endOptimalError.message}</ErrorMessage>}
      </Stack>
      {isProAgent && !isEndUfn && (
        <InputRow isRowsFirst>
          <InputHeader isFullWidth size="2xs">
            {t('basic_listing_information.possibility_of_extension')}
          </InputHeader>
          <Controller
            name={Input.POSSIBILITY_OF_EXTENSTION}
            control={control}
            render={({ field: { value, name, onChange } }) => (
              <Checkbox
                name={name}
                label={t('basic_listing_information.possibility_of_extension_label')}
                type="thumb"
                isChecked={Boolean(value)}
                onClick={() => onChange(!value)}
              />
            )}
          />
        </InputRow>
      )}
      <ApplicationDeadline />
    </Stack>
  )
}
