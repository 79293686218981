import { useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { palette } from '../../../styling'
import type {
  HomeApplicationOverview_homeApplication_homeTemplates,
  HomeApplicationOverview_homeApplication_home_homeTemplates,
} from '../../../data/graphql/types/__generated__/HomeApplicationOverview'
import { RotatingCaret } from '../../../ui-atoms/rotating-caret'
import { VerticalSpacer } from '../../../ui-atoms/help-components/spacer'
import { formatMoney } from '../../../util-functions/general.util'

// TODO: fix currency
const currency = 'kr'

const HomeTemplatesWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid #e8e8e8`,
  borderRadius: '0 0 4px 4px',
  borderTop: 'none',
})

const HomeTemplatesHeader = styled.div<{ isOpen: boolean }>(({ isOpen }) => ({
  height: 40,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: 4,
  backgroundColor: 'rgba(0,0,0,0.1)',
  padding: '0 16px',
  cursor: 'pointer',
  ...(isOpen && {
    borderRadius: '4px 4px 0 0 ',
  }),
}))

const HomeTemplateRow = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 16px',
  height: 40,
  borderBottom: `1px solid rgba(0,0,0,0.1)`,
  ':last-of-type': {
    borderBottom: 'none',
  },
})

const HomeTemplateCol = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flex: '1',
})

const MissingText = styled.p({
  color: palette.blocketGreyLight,
})

type HomeTemplatesProps = {
  homeTemplates: (
    | HomeApplicationOverview_homeApplication_homeTemplates
    | HomeApplicationOverview_homeApplication_home_homeTemplates
  )[]
}

export function OverviewHomeTemplates({ homeTemplates }: HomeTemplatesProps) {
  const [isListOpen, setIsListOpen] = useState(true)
  const { t } = useTranslation(['applications', 'commons'])

  return (
    <>
      <HomeTemplatesHeader isOpen={isListOpen} onClick={() => setIsListOpen(!isListOpen)}>
        <RotatingCaret isAngleUp={isListOpen} />
        <VerticalSpacer factor={1} />
        {t('commons:apartment_other')}
      </HomeTemplatesHeader>
      {isListOpen && (
        <HomeTemplatesWrapper>
          {homeTemplates.map((homeTemplate) => (
            <HomeTemplateRow key={homeTemplate.id}>
              <HomeTemplateCol>
                {homeTemplate.apartmentNumber ? (
                  homeTemplate.apartmentNumber
                ) : (
                  <MissingText>{t('overview.home_templates.missing_apartment_number')}</MissingText>
                )}
              </HomeTemplateCol>
              <HomeTemplateCol>{formatMoney(homeTemplate.rent || 0, currency)}</HomeTemplateCol>
              <HomeTemplateCol>{`${homeTemplate.squareMeters} ${t('commons:square_meter')}`}</HomeTemplateCol>
              <HomeTemplateCol>
                {t('overview.home_templates.rooms', { count: homeTemplate.roomCount ?? 0 })}
              </HomeTemplateCol>
              <HomeTemplateCol>
                {homeTemplate.floor !== null ? (
                  t('overview.home_templates.floorplan', { floorplan: homeTemplate.floor })
                ) : (
                  <MissingText>{t('overview.home_templates.no_floorplan')}</MissingText>
                )}
              </HomeTemplateCol>
            </HomeTemplateRow>
          ))}
        </HomeTemplatesWrapper>
      )}
    </>
  )
}
