import { useContext, createContext, useState } from 'react'
import type { ReactNode } from 'react'

import type { HomeApplicationOverview_homeApplication } from '../data/graphql/types/__generated__/HomeApplicationOverview'

export enum ApplicantTab {
  Overview = 'overview',
  Document = 'document',
  Conversation = 'conversation',
  Comments = 'comments',
  Contract = 'contract',
}

type ApplicantActions = {
  openApplicant: (tab: ApplicantTab) => void
  currentTab: ApplicantTab
  applicantData?: HomeApplicationOverview_homeApplication
}

const ApplicantContext = createContext<ApplicantActions>({
  openApplicant: () => undefined,
  currentTab: ApplicantTab.Overview,
  applicantData: undefined,
})

type ApplicantProviderProps = {
  data: HomeApplicationOverview_homeApplication
  applicantId: string
  children?: ReactNode
  initialTab: ApplicantTab | null
}

export function ApplicantProvider(props: ApplicantProviderProps) {
  const { data, children, initialTab } = props
  const [currentTab, setCurrentTab] = useState(initialTab ?? ApplicantTab.Overview)

  return (
    <ApplicantContext.Provider
      value={{
        openApplicant: (tab: ApplicantTab) => {
          setCurrentTab(tab)
        },
        currentTab,
        applicantData: data,
      }}
    >
      {children}
    </ApplicantContext.Provider>
  )
}

export const useApplicantContext = () => useContext(ApplicantContext)
