import gql from 'graphql-tag'

const SEND_EMAIL_FOR_RESET = gql`
  mutation SubUserCreateResetPasswordTokenMutation($subUserCreateResetPasswordTokenEmail: String!) {
    subUserCreateResetPasswordToken(email: $subUserCreateResetPasswordTokenEmail) {
      success
    }
  }
`

export const mutations = {
  SEND_EMAIL_FOR_RESET,
}
