import React, { Fragment } from 'react'
import type { ReactNode } from 'react'
import styled from '@emotion/styled'
import { Stack } from '@qasa/qds-ui'

const Indicator = styled.span<{ isLarge: boolean | undefined }>(({ theme, isLarge }) => ({
  fontSize: isLarge ? 10 : 8,
  paddingRight: 6,
  paddingLeft: 6,
  '&::before': {
    content: '"●"',
  },
}))

type BulletSeperatorProps = {
  isLarge?: boolean
  children: ReactNode
}

export function BulletSeperator({ isLarge, children }: BulletSeperatorProps) {
  const arrayOfChildren = React.Children.toArray(children)
  return (
    <Stack direction="row" alignItems="center">
      {arrayOfChildren.map((child, index) => {
        return (
          <Fragment key={index}>
            {index !== 0 && <Indicator isLarge={isLarge} />}
            {child}
          </Fragment>
        )
      })}
    </Stack>
  )
}
