import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { TenureTypeEnum } from '../../../data/graphql/types/__generated__/globalTypes'
import { SelectWithError } from '../../../ui-shared/_core/select'
import type { SelectOption } from '../../../ui-shared/_core/select'

import { ContractFormInputHeader } from './contract-form-input-header'

export function ContractFormTenureType() {
  const { control } = useFormContext()
  const { t } = useTranslation('applicant', {
    keyPrefix: 'contract_form',
  })

  const tenureOptions: SelectOption[] = [
    {
      value: TenureTypeEnum.condominium,
      label: t('condominium'),
    },
    {
      value: TenureTypeEnum.proprietary,
      label: t('proprietary'),
    },
    {
      value: TenureTypeEnum.tenancy,
      label: t('tenancy'),
    },
  ]

  return (
    <div>
      <ContractFormInputHeader label={t('residence_type')} />
      <Controller
        name="tenureType"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState }) => {
          return (
            <SelectWithError
              placeholder={t('choose_value')}
              onChange={onChange}
              currentValue={value || undefined}
              options={tenureOptions}
              errorMessage={fieldState.error && t('mandatory')}
            />
          )
        }}
      />
    </div>
  )
}
