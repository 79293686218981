import {
  AcceptBookingPayload,
  AcceptHomeApplicationPayload,
  AcceptHomeApplicationsPayload,
  AcceptOfferingPayload,
  AcceptShortcutProposalPayload,
  AccessToken,
  AccountResource,
  AddHomeApplicationsToBoardListPayload,
  AdminFields,
  AdminInfo,
  AdminNote,
  AffiliateCode,
  ApplicationBoardCardInfo,
  ApplicationBoardList,
  ApplicationTimeSeries,
  ApproveDepositPayoutPayload,
  ApproveIdentificationPayload,
  ArchiveConversationPayload,
  ArchiveHomePayload,
  AreaParamsInput,
  AssignTagsPayload,
  AttributeError,
  AuthenticationError,
  AuthorizationError,
  BankAccount,
  BankAccountType,
  Blob,
  BlockListingPayload,
  Booking,
  BookingOffer,
  Building,
  BuildingConnection,
  BuildingEdge,
  BumpListingPayload,
  BumpVacationAdPayload,
  CancelBookingPayload,
  CancelContractSigningPayload,
  CancelViewingPayload,
  CancellationPolicy,
  Certificate,
  ClaimContractInvitationPayload,
  CloseConversationPayload,
  CodesWithMessage,
  CompensationClaim,
  CompensationClaimCoverageType,
  CompensationClaimReservationType,
  CompensationReservationInput,
  CompleteOfferingPayload,
  ConfirmEmailPayload,
  ConnectHubConnectAccountsPreviewType,
  ConnectHubHomeQBonusPointsType,
  ConnectHubHomeQClaimAttributesType,
  ConnectHubHomeQClaimFileType,
  ConnectHubHomeQConnectUserPayload,
  ConnectHubHomeQCreateApplicationPayload,
  ConnectHubHomeQCreateFileReferencePayload,
  ConnectHubHomeQCurrentClaimType,
  ConnectHubHomeQDeleteApplicationInterestPayload,
  ConnectHubHomeQDeleteClaimPayload,
  ConnectHubHomeQDisconnectUserPayload,
  ConnectHubHomeQFileReferenceHeadersType,
  ConnectHubHomeQFileReferenceType,
  ConnectHubHomeQHomeApplicationPreconditions,
  ConnectHubHomeQHomeApplicationType,
  ConnectHubHomeQHomeApplicationsType,
  ConnectHubHomeQInterestType,
  ConnectHubHomeQLandlordCompanyType,
  ConnectHubHomeQPointsType,
  ConnectHubHomeQRenewClaimPayload,
  ConnectHubHomeQUpdateClaimPayload,
  ConnectHubHomeQUserClaimsType,
  ConnectHubHomeQUserSearchResultType,
  ConnectHubHomeQUserType,
  ConnectHubHomeQViewingType,
  ConnectHubInputObjectsCreateFileReferenceInput,
  ConnectHubInputObjectsUpdateClaimFileInput,
  ConnectHubInputObjectsUpdateClaimInput,
  Contract,
  ContractConnection,
  ContractDetails,
  ContractEdge,
  ContractInput,
  ContractLandlordFees,
  ContractLandlordServices,
  ContractPreview,
  ContractTemplate,
  ContractTemplateInput,
  ContractTenantFees,
  ContractTenantInvitation,
  ContractTenantServices,
  ContractsByStatusCounts,
  ContractsCounts,
  Conversation,
  ConversationConnection,
  ConversationEdge,
  ConversationMessage,
  ConversationMessageConnection,
  ConversationMessageEdge,
  ConversationUserRelation,
  CoordinatesStatistics,
  Cost,
  CreateAdminNoteInput,
  CreateAdminNotePayload,
  CreateApplicationBoardListPayload,
  CreateBlobInput,
  CreateBlobPayload,
  CreateBookingOfferFromBookingPayload,
  CreateBookingOfferInput,
  CreateBookingOfferPayload,
  CreateCertificatePayload,
  CreateClaimPayload,
  CreateCompensationClaimPayload,
  CreateCompensationReservationsPayload,
  CreateConversationMessageInput,
  CreateConversationMessagePayload,
  CreateCreditReportPayload,
  CreateDurationInput,
  CreateDurationPreferenceInput,
  CreateExternalContractTakeoverInput,
  CreateHomeFromTemplatePayload,
  CreateHomeInput,
  CreateHomePayload,
  CreateInstantSignContractInput,
  CreateInstantSignContractPayload,
  CreateLandlordHomeApplicationInput,
  CreateLandlordHomeApplicationPayload,
  CreateListingPlatformSelectorEventPayload,
  CreateLocationInput,
  CreateMatchingScoreFeedbackPayload,
  CreateOfferingInput,
  CreateOfferingPayload,
  CreateOnfidoVerificationLinkPayload,
  CreateSavedSearchInput,
  CreateSavedSearchPayload,
  CreateSavingsVerificationPayload,
  CreateSubUserPayload,
  CreateTenantHomeApplicationInput,
  CreateTenantHomeApplicationPayload,
  CreateTenantInvitationInput,
  CreateTenantInvitationPayload,
  CreateUpload,
  CreateUploadInput,
  CreateUploadPayload,
  CreateUserNoteInput,
  CreateUserNotePayload,
  CreateViewingPayload,
  CreditReport,
  CreditStatusSummary,
  CronofyElementData,
  CurrentAddressType,
  DateRange,
  DateRangeInput,
  DateTimeRangeInput,
  DeclineHomeApplicationPayload,
  DeclineHomeApplicationsPayload,
  DeclineOfferingPayload,
  DeclineOrRevokeBookingPayload,
  DeclineShortcutProposalPayload,
  DeleteHomePayload,
  DeliveryTrace,
  DeliveryTraceConnection,
  DeliveryTraceEdge,
  DenyDepositPayoutPayload,
  DestroyAdminNotePayload,
  DestroyApplicationBoardListPayload,
  DestroyCreditReportPayload,
  DestroyHomeAvailabilityPayload,
  DestroyIncomeVerificationPayload,
  DestroyMessageMacroPayload,
  DestroyOccupationPayload,
  DestroyOccupationVerificationPayload,
  DestroySavedSearchPayload,
  DestroyUploadPayload,
  DestroyUserNotePayload,
  DestroyUserPayload,
  DiscardIdentificationPayload,
  DuplicateHomePayload,
  Duration,
  DurationPreference,
  ElectricityFeeInput,
  ElectricityFeeType,
  Employee,
  EmployeeConnection,
  EmployeeEdge,
  EmployeeGroup,
  EmployeeGroupConnection,
  EmployeeGroupEdge,
  EmployeePermission,
  EmployeePermissionConnection,
  EmployeePermissionEdge,
  EmployeeRole,
  EmployeeRoleConnection,
  EmployeeRoleEdge,
  EmploymentVerification,
  EnrollHomeInShortcutInput,
  EnrollHomeInShortcutPayload,
  Error,
  EvaluateHomeForShortcutPayload,
  EvictionProcess,
  EvictionProcessAttributes,
  EvictionProcessComment,
  EvictionProcessConnection,
  EvictionProcessEdge,
  EvictionProcessSocInfo,
  ExchangeLoginTokenPayload,
  ExchangeSignInCodePayload,
  ExtendContractPayload,
  ExternalContractTakeover,
  ExtraCost,
  ExtraCostInput,
  FamilyMember,
  FamilyMemberInput,
  FavoriteMarkHomePayload,
  Fee,
  FinalizeUploadPayload,
  FinanceChargeType,
  FinanceDepositInvoice,
  FinanceGenericInvoice,
  FinanceItemType,
  FinanceMonthlyRentInvoice,
  FinancePayoutType,
  FinanceTestModePayInvoicePayload,
  FinlandMarketDetails,
  FinlandMarketDetailsInput,
  FinlandRentIndexationInput,
  FinlandRentIndexationType,
  FinlandUpsertContractInput,
  FinlandUpsertContractPayload,
  FranceCancelContractDraftPayload,
  FranceCellarInput,
  FranceCellarType,
  FranceCreateExternalContractTakeoverPayload,
  FranceCreateInsuranceRequestInput,
  FranceCreateInsuranceRequestPayload,
  FranceDocumentRequestType,
  FranceDurationInput,
  FranceDurationType,
  FranceEquipmentInput,
  FranceEquipmentType,
  FranceExternalContractTakeoverDurationInput,
  FranceFacilityInput,
  FranceFacilityType,
  FranceGuarantorInput,
  FranceGuarantorType,
  FranceHeatingProductionInput,
  FranceHeatingProductionType,
  FranceHotWaterProductionInput,
  FranceHotWaterProductionType,
  FranceInitiateContractSigningPayload,
  FranceInsuranceRequestType,
  FranceInternetConnectionInput,
  FranceInternetConnectionType,
  FranceLegalEntityDetailsInput,
  FranceLegalEntityDetailsType,
  FranceMarketDetails,
  FranceMarketDetailsInput,
  FranceOtherOwnerInput,
  FranceOtherOwnerType,
  FranceParkingInput,
  FranceParkingType,
  FrancePreviousRentalInput,
  FrancePreviousRentalType,
  FrancePrivateGarageInput,
  FrancePrivateGarageType,
  FrancePrivateGardenInput,
  FrancePrivateGardenType,
  FranceRecoverableChargesInput,
  FranceRecoverableChargesType,
  FranceRentControlInput,
  FranceRentControlType,
  FranceTvConnectionInput,
  FranceTvConnectionType,
  FranceTransitionInsuranceRequestPayload,
  FranceUpdateDocumentRequestInput,
  FranceUpdateDocumentRequestPayload,
  FranceUpsertContractInput,
  FranceUpsertContractPayload,
  GeoPoint,
  Home,
  HomeApplication,
  HomeApplicationConnection,
  HomeApplicationEdge,
  HomeApplicationStats,
  HomeAvailability,
  HomeAvailabilitySettings,
  HomeConnection,
  HomeCoords,
  HomeCoordsConnection,
  HomeCoordsEdge,
  HomeDocument,
  HomeDocumentLocationType,
  HomeDocumentOffsetLimit,
  HomeDocumentUploadType,
  HomeEdge,
  HomeEvaluation,
  HomeIndexSearchOrderInput,
  HomeIndexSearchQuery,
  HomeLandlordFees,
  HomeLandlordServices,
  HomeOffsetLimit,
  HomeSearch,
  HomeSearchCoords,
  HomeSearchParams,
  HomeSearchParamsInput,
  HomeStats,
  HomeTemplate,
  HomeTemplateConnection,
  HomeTemplateEdge,
  HomeTenantFees,
  HousingSituation,
  HousingSituationInput,
  IdNumberFieldsInput,
  Identification,
  IdentifyInput,
  IdentifyPayload,
  IfInsurancePromotion,
  IfInsurancePromotionSignUpPayload,
  ImportTinkBankAccountPayload,
  IncomeVerification,
  IncomeVerificationInput,
  InitiateBookingCardPaymentInput,
  InitiateBookingCardPaymentPayload,
  InitiateContractSigningPayload,
  InitiateListingBumpPaymentInput,
  InitiateListingBumpPaymentPayload,
  InitiateListingPublicationFeePaymentInput,
  InitiateListingPublicationFeePaymentPayload,
  InitiateOnfidoCheckPayload,
  InitiateSwishPaymentInput,
  InitiateSwishPaymentPayload,
  InitiateTinkIncomeCheckPayload,
  Inspection,
  InspectionObservation,
  InspectionObservationInput,
  InstantSignDurationInput,
  InstantSignSetting,
  InstantSignSettingInput,
  InstantSignType,
  Insurance,
  Invoice,
  InvoiceEntry,
  InvoiceInterface,
  InvoiceSettingsType,
  KanyeHome,
  KanyeHomeConnection,
  KanyeHomeEdge,
  KanyeHomeReviewData,
  KanyeInputObjectsCreatePublicDocumentInput,
  KanyeUser,
  KanyeUserConnection,
  KanyeUserEdge,
  LandlordContactInfo,
  LastSeenMessage,
  Link,
  Localization,
  Location,
  LocationInput,
  LoginPayload,
  LoginUrl,
  MagicNumberTooLowError,
  MarkApplicationAsSeenPayload,
  MarkAsPaidError,
  MarkConversationInput,
  MarkConversationPayload,
  Market,
  MarketSettings,
  MatchingAreaMetadata,
  MatchingAreaMetadataInput,
  MatchingScore,
  MessageMacro,
  MessageMacroConnection,
  MessageMacroEdge,
  MessagesSubscriptionPayload,
  MetadataAddressType,
  MetadataFreeFormatAddressType,
  Money,
  MoveInfoType,
  MutationRoot,
  Node,
  NotFoundError,
  Occupation,
  OccupationVerification,
  Offering,
  OfferingConnection,
  OfferingEdge,
  OpenConversationPayload,
  Organization,
  PageInfo,
  PayoutCompensationClaimReservationPayload,
  PayoutOnboardingUrlQueryInput,
  PlatformConfiguration,
  PointOfInterest,
  PointOfInterestConnection,
  PointOfInterestEdge,
  PricePreview,
  PricingModel,
  PrivacyControllerByPlatform,
  ProContactFormInput,
  ProContactFormPayload,
  Property,
  PublicDocument,
  PublicDocumentConnection,
  PublicDocumentEdge,
  PublishHomePayload,
  PublishTenantAdPayload,
  QueryRoot,
  RateLimitExceeded,
  RejectIdentificationPayload,
  RemoveCosigningTenantPayload,
  RemoveHomeApplicationsFromBoardListPayload,
  RentHistogram,
  RentalRequirement,
  ReportUserPayload,
  ResendConfirmBankAccountEmailPayload,
  RevokeHomeApplicationPayload,
  RevokeOfferingPayload,
  SavedSearch,
  SavingsVerification,
  SchibstedLoginInput,
  SchibstedLoginPayload,
  ScriveEidTransaction,
  SearchArea,
  SearchAreaConnection,
  SearchAreaEdge,
  SearchAreaMatcher,
  SearchPreference,
  SearchPreferenceInput,
  SendBookingEmailsPayload,
  SendEmailConfirmationTokenPayload,
  SendMassMessagePayload,
  SendSignInCodePayload,
  Service,
  SetActiveBankAccountPayload,
  SetDepositFreePayload,
  SetScriveFieldsInput,
  SetScriveFieldsPayload,
  Shortcut,
  SignVacationContractPayload,
  Signatory,
  SimilarHomes,
  SmoochParams,
  SocEvictionEmailQasaInfo,
  StarMarkApplicationPayload,
  StarMarkConversationPayload,
  StartBankIdTransactionPayload,
  StripeCheckoutInput,
  StripePayment,
  StripeSepaTransferMoneyAction,
  StripeSetupAccountAction,
  StudentVerification,
  SubUser,
  SubUserCreateResetPasswordTokenPayload,
  SubUserLoginPayload,
  SubUserResetPasswordPayload,
  SubscriptionRoot,
  Success,
  Suspicion,
  SuspicionConnection,
  SuspicionEdge,
  SwishPayment,
  TagInput,
  TaggableInput,
  TaxReportAddressType,
  TaxReportMetadataType,
  TaxReportQuarterType,
  TaxReportType,
  TemporaryContract,
  TenantAd,
  TenantAdInput,
  TenantAdOffsetLimit,
  TenantContactInfo,
  TenantInput,
  TenantProfileCompleteness,
  TenantProfileField,
  TenantProfileFields,
  TerminateContractPayload,
  TerminateContractValidation,
  TestErrorsInput,
  TinkTransactionGroupType,
  TinkTransactionType,
  Trait,
  TransitionBookingOfferInput,
  TransitionBookingOfferPayload,
  TransitionShortcutInput,
  TransitionShortcutPayload,
  TranslatedDescription,
  TranslatedDescriptionInput,
  UnarchiveConversationPayload,
  UnmarkFavoriteHomePayload,
  UnpublishTenantAdPayload,
  UnreadConversationsCount,
  UpcomingPayoutType,
  UpdateApplicationBoardListOrderPayload,
  UpdateApplicationBoardListPayload,
  UpdateContractScriveIdPayload,
  UpdateContractWaterFeePayload,
  UpdateHomeApplicationPositionPayload,
  UpdateHomeInput,
  UpdateHomePayload,
  UpdateInstantSignContractInput,
  UpdateInstantSignContractPayload,
  UpdateLandlordPostSignDocumentsInput,
  UpdateLandlordPostSignDocumentsPayload,
  UpdateMarketSettingsInput,
  UpdateMarketSettingsPayload,
  UpdateSavedSearchInput,
  UpdateSavedSearchPayload,
  UpdateShortcutInput,
  UpdateShortcutPayload,
  UpdateTenantPostSignDocumentsInput,
  UpdateTenantPostSignDocumentsPayload,
  UpdateUploadInput,
  UpdateUploadPayload,
  UpdateUserInput,
  UpdateUserPayload,
  UpdateWaterFeeInput,
  Upload,
  UploadMetadata,
  UploadMetadataInput,
  UploadTagType,
  UploadTaggableInput,
  UpsertApplicationBoardCardInfoInput,
  UpsertApplicationBoardCardInfoPayload,
  UpsertBankAccountInput,
  UpsertBankAccountPayload,
  UpsertBookingInput,
  UpsertBookingPayload,
  UpsertBuildingInput,
  UpsertBuildingPayload,
  UpsertContractDetailsInput,
  UpsertContractInput,
  UpsertContractPayload,
  UpsertEmploymentVerificationInput,
  UpsertEmploymentVerificationPayload,
  UpsertHomeAvailabilityInput,
  UpsertHomeAvailabilityPayload,
  UpsertHomeAvailabilitySettingsInput,
  UpsertHomeAvailabilitySettingsPayload,
  UpsertHomeTemplateInput,
  UpsertHomeTemplatePayload,
  UpsertIncomeVerificationPayload,
  UpsertInspectionPayload,
  UpsertInstantSignInput,
  UpsertMessageMacroInput,
  UpsertMessageMacroPayload,
  UpsertOccupationInput,
  UpsertOccupationPayload,
  UpsertOccupationVerificationInput,
  UpsertOccupationVerificationPayload,
  UpsertRentalRequirementInput,
  UpsertRentalRequirementPayload,
  UpsertStudentVerificationInput,
  UpsertStudentVerificationPayload,
  UpsertTenantAdPayload,
  UpsertViewingCalendarPayload,
  User,
  UserAction,
  UserApplicationStats,
  UserBio,
  UserConnection,
  UserContracts,
  UserEdge,
  UserMatchingProfile,
  UserNote,
  UserPrivate,
  UserReport,
  UserReportConnection,
  UserReportEdge,
  UsersSearchInput,
  Validation,
  ValidationError,
  VerifyBankAccountPayload,
  Viewing,
  ViewingBooking,
  ViewingCalendar,
  ViewingSlot,
  ViewingSlotConnection,
  ViewingSlotEdge,
  ViewingSlotInput,
  WithinDistanceFromInput,
  YearlyIncomeStatementsType,
  AccountResourceStateEnum,
  ActiveStorageServiceEnum,
  AdminNoteRecordTypeEnum,
  AffiliateCodeDiscountForEnum,
  AffiliateCodeDiscountTypeEnum,
  AffiliateCodeTypeEnum,
  ApplicationBoardListDefaultTypeEnum,
  ApplicationSelectionTypeEnum,
  ApplicationSortingTypeEnum,
  BankAccountOwnerRelationTypeEnum,
  BankAccountTypeEnum,
  BankAccountTypeTypeEnum,
  BookingEmailTemplateEnum,
  BookingOfferStatesEnum,
  BookingStatusEnum,
  BrandControllerEnum,
  CancellationPolicyNameEnum,
  CertificateStatusEnum,
  ConnectHubHomeQClaimDurationEnum,
  ConnectHubHomeQClaimFileClassificationEnum,
  ConnectHubHomeQClaimIncomeSourceEnum,
  ConnectHubHomeQClaimOrgTypeEnum,
  ConnectHubHomeQClaimStatusEnum,
  ConnectHubHomeQClaimTypeEnum,
  ConnectHubHomeQEntityTypeEnum,
  ConnectHubHomeqHomeApplicationStatusEnum,
  ConnectHubHomeqInterestStatusEnum,
  ConnectHubHomeqViewingResponseStatusEnum,
  ContractLanguageEnum,
  ContractPostCleaningEnum,
  ContractPreCleaningEnum,
  ContractRentalTypeEnum,
  ContractSmallRepairsEnum,
  ContractStatusEnum,
  ContractTypeEnum,
  ConversationMessageTypeEnum,
  ConversationMessageTypeInputEnum,
  ConversationRoleTypeEnum,
  ConversationStateEnum,
  ConversationStatusEnum,
  CountryCodeEnum,
  CreditReportFetchStatusEnum,
  CreditReportProviderEnum,
  CurrencyEnum,
  DayOfTheWeekTypeEnum,
  ElectricityFeePaymentOptionEnum,
  EmployeePermissionActionEnum,
  EvictionProcessState,
  ExternalContractTakeoverStateEnum,
  ExtraCostForEnum,
  ExtraCostPlanEnum,
  FeeAvailabilityEnum,
  FinlandRentIndexationOptionEnum,
  FranceConstructionPeriodEnum,
  FranceDocumentRequestConcernedBusinessRoleEnum,
  FranceDocumentRequestStatusEnum,
  FranceDocumentRequestTypeEnum,
  FranceEquipmentEnum,
  FranceFacilityEnum,
  FranceGuarantorProfessionalSituationEnum,
  FranceHeatingProductionEnum,
  FranceHotWaterProductionEnum,
  FranceHousingTypeEnum,
  FranceInsuranceRequestStateEnum,
  FranceLandlordTypeEnum,
  FranceLegalEntityTypeEnum,
  FranceOwnershipEnum,
  FranceRecoverableChargesPaymentPlanEnum,
  FranceTenantAdditionalIncomeEnum,
  FranceTenantProfessionalSituationEnum,
  GenderEnum,
  HomeApplicationOrderEnum,
  HomeApplicationOriginEnum,
  HomeApplicationStatusEnum,
  HomeArchiveReasonEnum,
  HomeAvailabilityStatusEnum,
  HomeConditionEnum,
  HomeDashboardStatusEnum,
  HomeIndexSearchOrderByEnum,
  HomeIndexSearchOrderDirectionEnum,
  HomeInsuranceEnum,
  HomeRentalTypeEnum,
  HomeSearchOrderByEnum,
  HomeSearchOrderEnum,
  HomeSortingEnum,
  HomeStatusEnum,
  HomeTemplateOrderEnum,
  HomeTraitEnum,
  HomeTypeEnum,
  HomeUserRoleEnum,
  HousingSituationAgreementTypeEnum,
  IdNumberTypeEnum,
  IdentificationStatusEnum,
  IdentificationTypeEnum,
  InboxQueryOrderByEnum,
  IncomeVerificationStatusEnum,
  InspectionRoomsEnum,
  InsuranceResponsibilityEnum,
  InvoiceEntryCostTypeEnum,
  InvoiceEntryTypeEnum,
  InvoiceStateEnum,
  InvoiceTypeEnum,
  LeaseTypeEnum,
  LegalTypeEnum,
  ListingPlatformSelectorEventTypeEnum,
  LocationGranularityEnum,
  MarketCurrencyEnum,
  MarketNameEnum,
  MarketNameTypeEnum,
  MatchingScoreFeedbackReasonsEnum,
  MilaTestCommand,
  MonthEnum,
  NotificationSpanEnum,
  NrFirsthandHousingUnitsEnum,
  OccupationEnum,
  OccupationScheduleTypeEnum,
  OccupationTypeEnum,
  OfferingOrderEnum,
  OfferingStatusEnum,
  OwnerTypeEnum,
  PayoutStateEnum,
  PlatformEnum,
  PointOfInterestEnum,
  PricingModelNameEnum,
  ProductEnum,
  RefundActionEnum,
  ReportTypeEnum,
  ResponsibleForCleaningEnum,
  SavedSearchVisibilityEnum,
  SchibstedAccountEnvironmentEnum,
  ScriveInvitationOptionEnum,
  SendEmailStatusEnum,
  ServiceAvailabilityEnum,
  ShortcutProposalDeclineReasonEnum,
  ShortcutRejectionReasonEnum,
  ShortcutStatesEnum,
  ShowingStatusEnum,
  SignatoryRoleEnum,
  SigningProcessEnum,
  SimplifiedHomeStatusEnum,
  StudentVerificationEducationTypeEnum,
  StudentVerificationExtentEnum,
  SuspicionTypeEnum,
  SwishPaymentStatusEnum,
  TaggableTypeEnum,
  TenantAdSortingEnum,
  TenantAdStatusEnum,
  TenantTypeEnum,
  TenantVerificationEnum,
  TenureTypeEnum,
  TerminateContractValidationCodeEnum,
  TraitTypeEnum,
  UploadFileTypeEnum,
  UploadTaggableNameEnum,
  UploadTypeEnum,
  UserNoteRecordTypeEnum,
  UserReportTypeEnum,
  VerificationTypeEnum,
  ViewingStatusEnum,
} from './graphql'

export const anAcceptBookingPayload = (
  overrides?: Partial<AcceptBookingPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'AcceptBookingPayload' } & AcceptBookingPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('AcceptBookingPayload')
  return {
    __typename: 'AcceptBookingPayload',
    booking:
      overrides && overrides.hasOwnProperty('booking')
        ? overrides.booking!
        : relationshipsToOmit.has('Booking')
          ? ({} as Booking)
          : aBooking({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const anAcceptHomeApplicationPayload = (
  overrides?: Partial<AcceptHomeApplicationPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'AcceptHomeApplicationPayload' } & AcceptHomeApplicationPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('AcceptHomeApplicationPayload')
  return {
    __typename: 'AcceptHomeApplicationPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    homeApplication:
      overrides && overrides.hasOwnProperty('homeApplication')
        ? overrides.homeApplication!
        : relationshipsToOmit.has('HomeApplication')
          ? ({} as HomeApplication)
          : aHomeApplication({}, relationshipsToOmit),
  }
}

export const anAcceptHomeApplicationsPayload = (
  overrides?: Partial<AcceptHomeApplicationsPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'AcceptHomeApplicationsPayload' } & AcceptHomeApplicationsPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('AcceptHomeApplicationsPayload')
  return {
    __typename: 'AcceptHomeApplicationsPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : true,
  }
}

export const anAcceptOfferingPayload = (
  overrides?: Partial<AcceptOfferingPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'AcceptOfferingPayload' } & AcceptOfferingPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('AcceptOfferingPayload')
  return {
    __typename: 'AcceptOfferingPayload',
    offering:
      overrides && overrides.hasOwnProperty('offering')
        ? overrides.offering!
        : relationshipsToOmit.has('Offering')
          ? ({} as Offering)
          : anOffering({}, relationshipsToOmit),
  }
}

export const anAcceptShortcutProposalPayload = (
  overrides?: Partial<AcceptShortcutProposalPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'AcceptShortcutProposalPayload' } & AcceptShortcutProposalPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('AcceptShortcutProposalPayload')
  return {
    __typename: 'AcceptShortcutProposalPayload',
    homeId:
      overrides && overrides.hasOwnProperty('homeId')
        ? overrides.homeId!
        : '07769dc5-6330-480b-ae3a-2233f9fee740',
  }
}

export const anAccessToken = (
  overrides?: Partial<AccessToken>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'AccessToken' } & AccessToken => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('AccessToken')
  return {
    __typename: 'AccessToken',
    expiresAt:
      overrides && overrides.hasOwnProperty('expiresAt')
        ? overrides.expiresAt!
        : 'reiciendis atque aut esse vero a dolorem',
    impersonatedByAdmin:
      overrides && overrides.hasOwnProperty('impersonatedByAdmin') ? overrides.impersonatedByAdmin! : false,
    token: overrides && overrides.hasOwnProperty('token') ? overrides.token! : 'aut',
    uid: overrides && overrides.hasOwnProperty('uid') ? overrides.uid! : 'autem',
  }
}

export const anAccountResource = (
  overrides?: Partial<AccountResource>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'AccountResource' } & AccountResource => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('AccountResource')
  return {
    __typename: 'AccountResource',
    currentState:
      overrides && overrides.hasOwnProperty('currentState')
        ? overrides.currentState!
        : AccountResourceStateEnum.actions_required,
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '15974738-0ece-4951-8278-2e90c79bf57d',
    url: overrides && overrides.hasOwnProperty('url') ? overrides.url! : 'et',
    user:
      overrides && overrides.hasOwnProperty('user')
        ? overrides.user!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
  }
}

export const anAddHomeApplicationsToBoardListPayload = (
  overrides?: Partial<AddHomeApplicationsToBoardListPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'AddHomeApplicationsToBoardListPayload' } & AddHomeApplicationsToBoardListPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('AddHomeApplicationsToBoardListPayload')
  return {
    __typename: 'AddHomeApplicationsToBoardListPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    homeApplicationsFailed:
      overrides && overrides.hasOwnProperty('homeApplicationsFailed')
        ? overrides.homeApplicationsFailed!
        : [
            relationshipsToOmit.has('HomeApplication')
              ? ({} as HomeApplication)
              : aHomeApplication({}, relationshipsToOmit),
          ],
    homeApplicationsSuccessful:
      overrides && overrides.hasOwnProperty('homeApplicationsSuccessful')
        ? overrides.homeApplicationsSuccessful!
        : [
            relationshipsToOmit.has('HomeApplication')
              ? ({} as HomeApplication)
              : aHomeApplication({}, relationshipsToOmit),
          ],
  }
}

export const anAdminFields = (
  overrides?: Partial<AdminFields>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'AdminFields' } & AdminFields => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('AdminFields')
  return {
    __typename: 'AdminFields',
    adminNotes:
      overrides && overrides.hasOwnProperty('adminNotes')
        ? overrides.adminNotes!
        : [relationshipsToOmit.has('AdminNote') ? ({} as AdminNote) : anAdminNote({}, relationshipsToOmit)],
    onfidoApplicantId:
      overrides && overrides.hasOwnProperty('onfidoApplicantId') ? overrides.onfidoApplicantId! : 'sunt',
  }
}

export const anAdminInfo = (
  overrides?: Partial<AdminInfo>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'AdminInfo' } & AdminInfo => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('AdminInfo')
  return {
    __typename: 'AdminInfo',
    email: overrides && overrides.hasOwnProperty('email') ? overrides.email! : 'quaerat',
    name: overrides && overrides.hasOwnProperty('name') ? overrides.name! : 'ratione',
    picture: overrides && overrides.hasOwnProperty('picture') ? overrides.picture! : 'rerum',
  }
}

export const anAdminNote = (
  overrides?: Partial<AdminNote>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'AdminNote' } & AdminNote => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('AdminNote')
  return {
    __typename: 'AdminNote',
    author:
      overrides && overrides.hasOwnProperty('author')
        ? overrides.author!
        : relationshipsToOmit.has('Employee')
          ? ({} as Employee)
          : anEmployee({}, relationshipsToOmit),
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'doloremque doloribus doloremque aliquid sequi et voluptate',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '58257c26-0ae9-45c5-aa2a-4735ff6dff51',
    note: overrides && overrides.hasOwnProperty('note') ? overrides.note! : 'earum',
    updatedAt:
      overrides && overrides.hasOwnProperty('updatedAt')
        ? overrides.updatedAt!
        : 'et velit fugit sint eligendi debitis corporis',
  }
}

export const anAffiliateCode = (
  overrides?: Partial<AffiliateCode>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'AffiliateCode' } & AffiliateCode => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('AffiliateCode')
  return {
    __typename: 'AffiliateCode',
    affiliateCodeType:
      overrides && overrides.hasOwnProperty('affiliateCodeType')
        ? overrides.affiliateCodeType!
        : AffiliateCodeTypeEnum.auto_certified,
    code: overrides && overrides.hasOwnProperty('code') ? overrides.code! : 'dolor',
    discountFor:
      overrides && overrides.hasOwnProperty('discountFor')
        ? overrides.discountFor!
        : AffiliateCodeDiscountForEnum.insurance_cost,
    discountType:
      overrides && overrides.hasOwnProperty('discountType')
        ? overrides.discountType!
        : AffiliateCodeDiscountTypeEnum.fixed,
    discountValue: overrides && overrides.hasOwnProperty('discountValue') ? overrides.discountValue! : 3373,
    expirationDate:
      overrides && overrides.hasOwnProperty('expirationDate')
        ? overrides.expirationDate!
        : 'officia sed quia vero est dolorem delectus',
    partner: overrides && overrides.hasOwnProperty('partner') ? overrides.partner! : 'ipsa',
  }
}

export const anApplicationBoardCardInfo = (
  overrides?: Partial<ApplicationBoardCardInfo>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ApplicationBoardCardInfo' } & ApplicationBoardCardInfo => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ApplicationBoardCardInfo')
  return {
    __typename: 'ApplicationBoardCardInfo',
    home:
      overrides && overrides.hasOwnProperty('home')
        ? overrides.home!
        : relationshipsToOmit.has('Home')
          ? ({} as Home)
          : aHome({}, relationshipsToOmit),
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '29485206-e7bf-4cca-9b06-0bba0a5f59ae',
    showEmployerCertificate:
      overrides && overrides.hasOwnProperty('showEmployerCertificate')
        ? overrides.showEmployerCertificate!
        : false,
    showIdentification:
      overrides && overrides.hasOwnProperty('showIdentification') ? overrides.showIdentification! : true,
    showIncome: overrides && overrides.hasOwnProperty('showIncome') ? overrides.showIncome! : false,
    showLastMessage:
      overrides && overrides.hasOwnProperty('showLastMessage') ? overrides.showLastMessage! : true,
    showMatchingScore:
      overrides && overrides.hasOwnProperty('showMatchingScore') ? overrides.showMatchingScore! : false,
    showQueueDays: overrides && overrides.hasOwnProperty('showQueueDays') ? overrides.showQueueDays! : true,
    showStudyCertificate:
      overrides && overrides.hasOwnProperty('showStudyCertificate') ? overrides.showStudyCertificate! : true,
    showUc: overrides && overrides.hasOwnProperty('showUc') ? overrides.showUc! : false,
  }
}

export const anApplicationBoardList = (
  overrides?: Partial<ApplicationBoardList>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ApplicationBoardList' } & ApplicationBoardList => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ApplicationBoardList')
  return {
    __typename: 'ApplicationBoardList',
    applicationCount:
      overrides && overrides.hasOwnProperty('applicationCount') ? overrides.applicationCount! : 2562,
    defaultType:
      overrides && overrides.hasOwnProperty('defaultType')
        ? overrides.defaultType!
        : ApplicationBoardListDefaultTypeEnum.completed,
    home:
      overrides && overrides.hasOwnProperty('home')
        ? overrides.home!
        : relationshipsToOmit.has('Home')
          ? ({} as Home)
          : aHome({}, relationshipsToOmit),
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '924e2efe-f1e2-4149-a3a0-69f78a1c16d4',
    isDefault: overrides && overrides.hasOwnProperty('isDefault') ? overrides.isDefault! : false,
    name: overrides && overrides.hasOwnProperty('name') ? overrides.name! : 'rerum',
    order: overrides && overrides.hasOwnProperty('order') ? overrides.order! : 8528,
  }
}

export const anApplicationTimeSeries = (
  overrides?: Partial<ApplicationTimeSeries>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ApplicationTimeSeries' } & ApplicationTimeSeries => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ApplicationTimeSeries')
  return {
    __typename: 'ApplicationTimeSeries',
    cumulativeApplications:
      overrides && overrides.hasOwnProperty('cumulativeApplications')
        ? overrides.cumulativeApplications!
        : [9205],
    dailyApplications:
      overrides && overrides.hasOwnProperty('dailyApplications') ? overrides.dailyApplications! : [6194],
    timeInterval:
      overrides && overrides.hasOwnProperty('timeInterval')
        ? overrides.timeInterval!
        : ['velit sed aliquam commodi quas quasi suscipit'],
  }
}

export const anApproveDepositPayoutPayload = (
  overrides?: Partial<ApproveDepositPayoutPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ApproveDepositPayoutPayload' } & ApproveDepositPayoutPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ApproveDepositPayoutPayload')
  return {
    __typename: 'ApproveDepositPayoutPayload',
    contract:
      overrides && overrides.hasOwnProperty('contract')
        ? overrides.contract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const anApproveIdentificationPayload = (
  overrides?: Partial<ApproveIdentificationPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ApproveIdentificationPayload' } & ApproveIdentificationPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ApproveIdentificationPayload')
  return {
    __typename: 'ApproveIdentificationPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : true,
  }
}

export const anArchiveConversationPayload = (
  overrides?: Partial<ArchiveConversationPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ArchiveConversationPayload' } & ArchiveConversationPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ArchiveConversationPayload')
  return {
    __typename: 'ArchiveConversationPayload',
    conversation:
      overrides && overrides.hasOwnProperty('conversation')
        ? overrides.conversation!
        : relationshipsToOmit.has('Conversation')
          ? ({} as Conversation)
          : aConversation({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const anArchiveHomePayload = (
  overrides?: Partial<ArchiveHomePayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ArchiveHomePayload' } & ArchiveHomePayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ArchiveHomePayload')
  return {
    __typename: 'ArchiveHomePayload',
    home:
      overrides && overrides.hasOwnProperty('home')
        ? overrides.home!
        : relationshipsToOmit.has('Home')
          ? ({} as Home)
          : aHome({}, relationshipsToOmit),
  }
}

export const anAreaParamsInput = (
  overrides?: Partial<AreaParamsInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): AreaParamsInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('AreaParamsInput')
  return {
    city: overrides && overrides.hasOwnProperty('city') ? overrides.city! : 'consequatur',
    countryCode: overrides && overrides.hasOwnProperty('countryCode') ? overrides.countryCode! : 'libero',
    name: overrides && overrides.hasOwnProperty('name') ? overrides.name! : 'omnis',
  }
}

export const anAssignTagsPayload = (
  overrides?: Partial<AssignTagsPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'AssignTagsPayload' } & AssignTagsPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('AssignTagsPayload')
  return {
    __typename: 'AssignTagsPayload',
    taggable:
      overrides && overrides.hasOwnProperty('taggable')
        ? overrides.taggable!
        : relationshipsToOmit.has('Upload')
          ? ({} as Upload)
          : anUpload({}, relationshipsToOmit),
  }
}

export const anAttributeError = (
  overrides?: Partial<AttributeError>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'AttributeError' } & AttributeError => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('AttributeError')
  return {
    __typename: 'AttributeError',
    attribute: overrides && overrides.hasOwnProperty('attribute') ? overrides.attribute! : 'aut',
    message: overrides && overrides.hasOwnProperty('message') ? overrides.message! : 'omnis',
    type: overrides && overrides.hasOwnProperty('type') ? overrides.type! : 'similique',
  }
}

export const anAuthenticationError = (
  overrides?: Partial<AuthenticationError>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'AuthenticationError' } & AuthenticationError => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('AuthenticationError')
  return {
    __typename: 'AuthenticationError',
    message: overrides && overrides.hasOwnProperty('message') ? overrides.message! : 'ea',
  }
}

export const anAuthorizationError = (
  overrides?: Partial<AuthorizationError>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'AuthorizationError' } & AuthorizationError => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('AuthorizationError')
  return {
    __typename: 'AuthorizationError',
    message: overrides && overrides.hasOwnProperty('message') ? overrides.message! : 'quasi',
  }
}

export const aBankAccount = (
  overrides?: Partial<BankAccount>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'BankAccount' } & BankAccount => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('BankAccount')
  return {
    __typename: 'BankAccount',
    active: overrides && overrides.hasOwnProperty('active') ? overrides.active! : true,
    bankLocation:
      overrides && overrides.hasOwnProperty('bankLocation')
        ? overrides.bankLocation!
        : relationshipsToOmit.has('Location')
          ? ({} as Location)
          : aLocation({}, relationshipsToOmit),
    bankName: overrides && overrides.hasOwnProperty('bankName') ? overrides.bankName! : 'voluptate',
    bic: overrides && overrides.hasOwnProperty('bic') ? overrides.bic! : 'sint',
    clearingNumber:
      overrides && overrides.hasOwnProperty('clearingNumber') ? overrides.clearingNumber! : 'totam',
    countryCode: overrides && overrides.hasOwnProperty('countryCode') ? overrides.countryCode! : 'quaerat',
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'soluta voluptatem qui recusandae dolorum impedit mollitia',
    email: overrides && overrides.hasOwnProperty('email') ? overrides.email! : 'debitis',
    iban: overrides && overrides.hasOwnProperty('iban') ? overrides.iban! : 'doloremque',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'a32e6d75-eafe-4417-98c2-37de8730189f',
    location:
      overrides && overrides.hasOwnProperty('location')
        ? overrides.location!
        : relationshipsToOmit.has('Location')
          ? ({} as Location)
          : aLocation({}, relationshipsToOmit),
    name: overrides && overrides.hasOwnProperty('name') ? overrides.name! : 'et',
    number: overrides && overrides.hasOwnProperty('number') ? overrides.number! : 'consequatur',
    ownerRelationType:
      overrides && overrides.hasOwnProperty('ownerRelationType')
        ? overrides.ownerRelationType!
        : BankAccountOwnerRelationTypeEnum.family,
    type: overrides && overrides.hasOwnProperty('type') ? overrides.type! : BankAccountTypeEnum.address,
    updatedAt:
      overrides && overrides.hasOwnProperty('updatedAt')
        ? overrides.updatedAt!
        : 'qui aut aperiam porro doloremque adipisci et',
    user:
      overrides && overrides.hasOwnProperty('user')
        ? overrides.user!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
    verified: overrides && overrides.hasOwnProperty('verified') ? overrides.verified! : false,
    verifiedAt:
      overrides && overrides.hasOwnProperty('verifiedAt')
        ? overrides.verifiedAt!
        : 'labore mollitia adipisci qui velit eius facilis',
  }
}

export const aBankAccountType = (
  overrides?: Partial<BankAccountType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'BankAccountType' } & BankAccountType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('BankAccountType')
  return {
    __typename: 'BankAccountType',
    number: overrides && overrides.hasOwnProperty('number') ? overrides.number! : 'doloribus',
    type:
      overrides && overrides.hasOwnProperty('type') ? overrides.type! : BankAccountTypeTypeEnum.bank_account,
  }
}

export const aBlob = (
  overrides?: Partial<Blob>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Blob' } & Blob => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Blob')
  return {
    __typename: 'Blob',
    byteSize: overrides && overrides.hasOwnProperty('byteSize') ? overrides.byteSize! : 1494,
    contentType: overrides && overrides.hasOwnProperty('contentType') ? overrides.contentType! : 'beatae',
    filename: overrides && overrides.hasOwnProperty('filename') ? overrides.filename! : 'est',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'b263b9ae-cf1f-451c-a0c8-2f2faee88e3b',
    metadata: overrides && overrides.hasOwnProperty('metadata') ? overrides.metadata! : {},
    url: overrides && overrides.hasOwnProperty('url') ? overrides.url! : 'libero',
  }
}

export const aBlockListingPayload = (
  overrides?: Partial<BlockListingPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'BlockListingPayload' } & BlockListingPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('BlockListingPayload')
  return {
    __typename: 'BlockListingPayload',
    location:
      overrides && overrides.hasOwnProperty('location')
        ? overrides.location!
        : relationshipsToOmit.has('Location')
          ? ({} as Location)
          : aLocation({}, relationshipsToOmit),
    maxRent: overrides && overrides.hasOwnProperty('maxRent') ? overrides.maxRent! : 4931,
    maxRoomCount: overrides && overrides.hasOwnProperty('maxRoomCount') ? overrides.maxRoomCount! : 9754,
    maxSquareMeters:
      overrides && overrides.hasOwnProperty('maxSquareMeters') ? overrides.maxSquareMeters! : 3195,
    maxTenantCount:
      overrides && overrides.hasOwnProperty('maxTenantCount') ? overrides.maxTenantCount! : 6162,
    minRent: overrides && overrides.hasOwnProperty('minRent') ? overrides.minRent! : 9443,
    minRoomCount: overrides && overrides.hasOwnProperty('minRoomCount') ? overrides.minRoomCount! : 1256,
    minSquareMeters:
      overrides && overrides.hasOwnProperty('minSquareMeters') ? overrides.minSquareMeters! : 935,
    minTenantCount:
      overrides && overrides.hasOwnProperty('minTenantCount') ? overrides.minTenantCount! : 5427,
    numberOfHomes: overrides && overrides.hasOwnProperty('numberOfHomes') ? overrides.numberOfHomes! : 7462,
    uploads:
      overrides && overrides.hasOwnProperty('uploads')
        ? overrides.uploads!
        : [relationshipsToOmit.has('Upload') ? ({} as Upload) : anUpload({}, relationshipsToOmit)],
  }
}

export const aBooking = (
  overrides?: Partial<Booking>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Booking' } & Booking => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Booking')
  return {
    __typename: 'Booking',
    actionDeadline:
      overrides && overrides.hasOwnProperty('actionDeadline')
        ? overrides.actionDeadline!
        : 'ratione voluptatem dolor dolore aut atque iusto',
    bookingOffers:
      overrides && overrides.hasOwnProperty('bookingOffers')
        ? overrides.bookingOffers!
        : [
            relationshipsToOmit.has('BookingOffer')
              ? ({} as BookingOffer)
              : aBookingOffer({}, relationshipsToOmit),
          ],
    canceledAt:
      overrides && overrides.hasOwnProperty('canceledAt')
        ? overrides.canceledAt!
        : 'ducimus vel totam omnis est accusamus non',
    checkIn:
      overrides && overrides.hasOwnProperty('checkIn')
        ? overrides.checkIn!
        : 'doloribus sed repellendus ut consequatur eum pariatur',
    checkOut:
      overrides && overrides.hasOwnProperty('checkOut')
        ? overrides.checkOut!
        : 'voluptas non omnis ex rerum amet dicta',
    contract:
      overrides && overrides.hasOwnProperty('contract')
        ? overrides.contract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    conversation:
      overrides && overrides.hasOwnProperty('conversation')
        ? overrides.conversation!
        : relationshipsToOmit.has('Conversation')
          ? ({} as Conversation)
          : aConversation({}, relationshipsToOmit),
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'est qui eius quia ab officiis tenetur',
    home:
      overrides && overrides.hasOwnProperty('home')
        ? overrides.home!
        : relationshipsToOmit.has('Home')
          ? ({} as Home)
          : aHome({}, relationshipsToOmit),
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '4ec76382-bc25-4eaf-87d8-565f8586569d',
    landlord:
      overrides && overrides.hasOwnProperty('landlord')
        ? overrides.landlord!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
    pricingInfo:
      overrides && overrides.hasOwnProperty('pricingInfo')
        ? overrides.pricingInfo!
        : relationshipsToOmit.has('PricePreview')
          ? ({} as PricePreview)
          : aPricePreview({}, relationshipsToOmit),
    refundAction:
      overrides && overrides.hasOwnProperty('refundAction')
        ? overrides.refundAction!
        : RefundActionEnum.full_refund,
    status: overrides && overrides.hasOwnProperty('status') ? overrides.status! : BookingStatusEnum.accepted,
    tenant:
      overrides && overrides.hasOwnProperty('tenant')
        ? overrides.tenant!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
    updatedAt:
      overrides && overrides.hasOwnProperty('updatedAt')
        ? overrides.updatedAt!
        : 'ipsum exercitationem molestiae est rerum adipisci voluptatem',
  }
}

export const aBookingOffer = (
  overrides?: Partial<BookingOffer>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'BookingOffer' } & BookingOffer => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('BookingOffer')
  return {
    __typename: 'BookingOffer',
    allowedTransitions:
      overrides && overrides.hasOwnProperty('allowedTransitions')
        ? overrides.allowedTransitions!
        : [BookingOfferStatesEnum.accepted],
    checkIn:
      overrides && overrides.hasOwnProperty('checkIn')
        ? overrides.checkIn!
        : 'ut quia tempora voluptas dignissimos modi iusto',
    checkOut:
      overrides && overrides.hasOwnProperty('checkOut')
        ? overrides.checkOut!
        : 'eos nulla omnis fugiat temporibus quasi autem',
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'doloremque rerum similique eaque et corrupti perferendis',
    createdFromBooking:
      overrides && overrides.hasOwnProperty('createdFromBooking') ? overrides.createdFromBooking! : true,
    currentState:
      overrides && overrides.hasOwnProperty('currentState')
        ? overrides.currentState!
        : BookingOfferStatesEnum.accepted,
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '2f0e9fd7-80d6-4c3f-a521-91037bbef538',
    pricingInfo:
      overrides && overrides.hasOwnProperty('pricingInfo')
        ? overrides.pricingInfo!
        : relationshipsToOmit.has('PricePreview')
          ? ({} as PricePreview)
          : aPricePreview({}, relationshipsToOmit),
    updatedAt:
      overrides && overrides.hasOwnProperty('updatedAt')
        ? overrides.updatedAt!
        : 'accusamus ut et cum sit voluptatibus eveniet',
  }
}

export const aBuilding = (
  overrides?: Partial<Building>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Building' } & Building => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Building')
  return {
    __typename: 'Building',
    buildYear: overrides && overrides.hasOwnProperty('buildYear') ? overrides.buildYear! : 3610,
    buildingName:
      overrides && overrides.hasOwnProperty('buildingName') ? overrides.buildingName! : 'quibusdam',
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'eos illum velit hic nihil nulla quae',
    descriptionBuilding:
      overrides && overrides.hasOwnProperty('descriptionBuilding') ? overrides.descriptionBuilding! : 'nemo',
    descriptionTransportation:
      overrides && overrides.hasOwnProperty('descriptionTransportation')
        ? overrides.descriptionTransportation!
        : 'aut',
    energyClass: overrides && overrides.hasOwnProperty('energyClass') ? overrides.energyClass! : 'ut',
    homeTemplateCount:
      overrides && overrides.hasOwnProperty('homeTemplateCount') ? overrides.homeTemplateCount! : 1512,
    homeTemplates:
      overrides && overrides.hasOwnProperty('homeTemplates')
        ? overrides.homeTemplates!
        : [
            relationshipsToOmit.has('HomeTemplate')
              ? ({} as HomeTemplate)
              : aHomeTemplate({}, relationshipsToOmit),
          ],
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'c42bc0b1-8cd7-4e87-8626-ad71a1fd9cb7',
    location:
      overrides && overrides.hasOwnProperty('location')
        ? overrides.location!
        : relationshipsToOmit.has('Location')
          ? ({} as Location)
          : aLocation({}, relationshipsToOmit),
    organization:
      overrides && overrides.hasOwnProperty('organization')
        ? overrides.organization!
        : relationshipsToOmit.has('Organization')
          ? ({} as Organization)
          : anOrganization({}, relationshipsToOmit),
    updatedAt:
      overrides && overrides.hasOwnProperty('updatedAt')
        ? overrides.updatedAt!
        : 'in odit ratione qui numquam expedita ut',
    uploads:
      overrides && overrides.hasOwnProperty('uploads')
        ? overrides.uploads!
        : [relationshipsToOmit.has('Upload') ? ({} as Upload) : anUpload({}, relationshipsToOmit)],
    user:
      overrides && overrides.hasOwnProperty('user')
        ? overrides.user!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
  }
}

export const aBuildingConnection = (
  overrides?: Partial<BuildingConnection>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'BuildingConnection' } & BuildingConnection => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('BuildingConnection')
  return {
    __typename: 'BuildingConnection',
    edges:
      overrides && overrides.hasOwnProperty('edges')
        ? overrides.edges!
        : [
            relationshipsToOmit.has('BuildingEdge')
              ? ({} as BuildingEdge)
              : aBuildingEdge({}, relationshipsToOmit),
          ],
    nodes:
      overrides && overrides.hasOwnProperty('nodes')
        ? overrides.nodes!
        : [relationshipsToOmit.has('Building') ? ({} as Building) : aBuilding({}, relationshipsToOmit)],
    pageInfo:
      overrides && overrides.hasOwnProperty('pageInfo')
        ? overrides.pageInfo!
        : relationshipsToOmit.has('PageInfo')
          ? ({} as PageInfo)
          : aPageInfo({}, relationshipsToOmit),
    totalCount: overrides && overrides.hasOwnProperty('totalCount') ? overrides.totalCount! : 8853,
  }
}

export const aBuildingEdge = (
  overrides?: Partial<BuildingEdge>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'BuildingEdge' } & BuildingEdge => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('BuildingEdge')
  return {
    __typename: 'BuildingEdge',
    cursor: overrides && overrides.hasOwnProperty('cursor') ? overrides.cursor! : 'eius',
    node:
      overrides && overrides.hasOwnProperty('node')
        ? overrides.node!
        : relationshipsToOmit.has('Building')
          ? ({} as Building)
          : aBuilding({}, relationshipsToOmit),
  }
}

export const aBumpListingPayload = (
  overrides?: Partial<BumpListingPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'BumpListingPayload' } & BumpListingPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('BumpListingPayload')
  return {
    __typename: 'BumpListingPayload',
    home:
      overrides && overrides.hasOwnProperty('home')
        ? overrides.home!
        : relationshipsToOmit.has('Home')
          ? ({} as Home)
          : aHome({}, relationshipsToOmit),
  }
}

export const aBumpVacationAdPayload = (
  overrides?: Partial<BumpVacationAdPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'BumpVacationAdPayload' } & BumpVacationAdPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('BumpVacationAdPayload')
  return {
    __typename: 'BumpVacationAdPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    home:
      overrides && overrides.hasOwnProperty('home')
        ? overrides.home!
        : relationshipsToOmit.has('Home')
          ? ({} as Home)
          : aHome({}, relationshipsToOmit),
  }
}

export const aCancelBookingPayload = (
  overrides?: Partial<CancelBookingPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CancelBookingPayload' } & CancelBookingPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CancelBookingPayload')
  return {
    __typename: 'CancelBookingPayload',
    booking:
      overrides && overrides.hasOwnProperty('booking')
        ? overrides.booking!
        : relationshipsToOmit.has('Booking')
          ? ({} as Booking)
          : aBooking({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const aCancelContractSigningPayload = (
  overrides?: Partial<CancelContractSigningPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CancelContractSigningPayload' } & CancelContractSigningPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CancelContractSigningPayload')
  return {
    __typename: 'CancelContractSigningPayload',
    contract:
      overrides && overrides.hasOwnProperty('contract')
        ? overrides.contract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const aCancelViewingPayload = (
  overrides?: Partial<CancelViewingPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CancelViewingPayload' } & CancelViewingPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CancelViewingPayload')
  return {
    __typename: 'CancelViewingPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    viewing:
      overrides && overrides.hasOwnProperty('viewing')
        ? overrides.viewing!
        : relationshipsToOmit.has('Viewing')
          ? ({} as Viewing)
          : aViewing({}, relationshipsToOmit),
  }
}

export const aCancellationPolicy = (
  overrides?: Partial<CancellationPolicy>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CancellationPolicy' } & CancellationPolicy => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CancellationPolicy')
  return {
    __typename: 'CancellationPolicy',
    fullRefundDays:
      overrides && overrides.hasOwnProperty('fullRefundDays') ? overrides.fullRefundDays! : 6693,
    landlordFullRefundDays:
      overrides && overrides.hasOwnProperty('landlordFullRefundDays')
        ? overrides.landlordFullRefundDays!
        : 2513,
    partialRefundDays:
      overrides && overrides.hasOwnProperty('partialRefundDays') ? overrides.partialRefundDays! : 5743,
    partialRefundFactor:
      overrides && overrides.hasOwnProperty('partialRefundFactor') ? overrides.partialRefundFactor! : 6.51,
    policyName:
      overrides && overrides.hasOwnProperty('policyName')
        ? overrides.policyName!
        : CancellationPolicyNameEnum.flexible,
  }
}

export const aCertificate = (
  overrides?: Partial<Certificate>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Certificate' } & Certificate => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Certificate')
  return {
    __typename: 'Certificate',
    certifiedAt:
      overrides && overrides.hasOwnProperty('certifiedAt')
        ? overrides.certifiedAt!
        : 'voluptatem ut ipsum assumenda quis rem nemo',
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'illo illum tenetur quaerat explicabo ipsam vel',
    evaluatedAt:
      overrides && overrides.hasOwnProperty('evaluatedAt')
        ? overrides.evaluatedAt!
        : 'modi placeat similique est et modi esse',
    expiresAt:
      overrides && overrides.hasOwnProperty('expiresAt')
        ? overrides.expiresAt!
        : 'et id et eaque facere explicabo tenetur',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '04c594a1-9d17-4ede-8d30-b211a5abbad0',
    numberOfComplaints:
      overrides && overrides.hasOwnProperty('numberOfComplaints') ? overrides.numberOfComplaints! : 7408,
    status:
      overrides && overrides.hasOwnProperty('status') ? overrides.status! : CertificateStatusEnum.certified,
    sumPrivatePublicDebt:
      overrides && overrides.hasOwnProperty('sumPrivatePublicDebt') ? overrides.sumPrivatePublicDebt! : 7698,
    updatedAt:
      overrides && overrides.hasOwnProperty('updatedAt')
        ? overrides.updatedAt!
        : 'et ut ut quia magnam facilis illo',
    user:
      overrides && overrides.hasOwnProperty('user')
        ? overrides.user!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
  }
}

export const aClaimContractInvitationPayload = (
  overrides?: Partial<ClaimContractInvitationPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ClaimContractInvitationPayload' } & ClaimContractInvitationPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ClaimContractInvitationPayload')
  return {
    __typename: 'ClaimContractInvitationPayload',
    contract:
      overrides && overrides.hasOwnProperty('contract')
        ? overrides.contract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const aCloseConversationPayload = (
  overrides?: Partial<CloseConversationPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CloseConversationPayload' } & CloseConversationPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CloseConversationPayload')
  return {
    __typename: 'CloseConversationPayload',
    conversation:
      overrides && overrides.hasOwnProperty('conversation')
        ? overrides.conversation!
        : relationshipsToOmit.has('Conversation')
          ? ({} as Conversation)
          : aConversation({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const aCodesWithMessage = (
  overrides?: Partial<CodesWithMessage>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CodesWithMessage' } & CodesWithMessage => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CodesWithMessage')
  return {
    __typename: 'CodesWithMessage',
    code: overrides && overrides.hasOwnProperty('code') ? overrides.code! : 'reprehenderit',
    message: overrides && overrides.hasOwnProperty('message') ? overrides.message! : 'corporis',
  }
}

export const aCompensationClaim = (
  overrides?: Partial<CompensationClaim>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CompensationClaim' } & CompensationClaim => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CompensationClaim')
  return {
    __typename: 'CompensationClaim',
    amount: overrides && overrides.hasOwnProperty('amount') ? overrides.amount! : 6332,
    balance: overrides && overrides.hasOwnProperty('balance') ? overrides.balance! : 2071,
    cap: overrides && overrides.hasOwnProperty('cap') ? overrides.cap! : 7720,
    contract:
      overrides && overrides.hasOwnProperty('contract')
        ? overrides.contract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    coverages:
      overrides && overrides.hasOwnProperty('coverages')
        ? overrides.coverages!
        : [
            relationshipsToOmit.has('CompensationClaimCoverageType')
              ? ({} as CompensationClaimCoverageType)
              : aCompensationClaimCoverageType({}, relationshipsToOmit),
          ],
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '32bdfbb4-df5b-4a52-8796-be88807c7cbe',
    reservations:
      overrides && overrides.hasOwnProperty('reservations')
        ? overrides.reservations!
        : [
            relationshipsToOmit.has('CompensationClaimReservationType')
              ? ({} as CompensationClaimReservationType)
              : aCompensationClaimReservationType({}, relationshipsToOmit),
          ],
  }
}

export const aCompensationClaimCoverageType = (
  overrides?: Partial<CompensationClaimCoverageType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CompensationClaimCoverageType' } & CompensationClaimCoverageType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CompensationClaimCoverageType')
  return {
    __typename: 'CompensationClaimCoverageType',
    amount: overrides && overrides.hasOwnProperty('amount') ? overrides.amount! : 6341,
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '21dd38b7-a765-4fd0-9fca-40dc9b50f991',
    status: overrides && overrides.hasOwnProperty('status') ? overrides.status! : 'pariatur',
  }
}

export const aCompensationClaimReservationType = (
  overrides?: Partial<CompensationClaimReservationType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CompensationClaimReservationType' } & CompensationClaimReservationType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CompensationClaimReservationType')
  return {
    __typename: 'CompensationClaimReservationType',
    amount: overrides && overrides.hasOwnProperty('amount') ? overrides.amount! : 6272,
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'acec4962-3b89-4687-8558-9ad8e150a796',
    invoice:
      overrides && overrides.hasOwnProperty('invoice')
        ? overrides.invoice!
        : relationshipsToOmit.has('Invoice')
          ? ({} as Invoice)
          : anInvoice({}, relationshipsToOmit),
    payoutTo:
      overrides && overrides.hasOwnProperty('payoutTo')
        ? overrides.payoutTo!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
    status: overrides && overrides.hasOwnProperty('status') ? overrides.status! : 'in',
  }
}

export const aCompensationReservationInput = (
  overrides?: Partial<CompensationReservationInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): CompensationReservationInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CompensationReservationInput')
  return {
    amount: overrides && overrides.hasOwnProperty('amount') ? overrides.amount! : 152,
    payoutToUid:
      overrides && overrides.hasOwnProperty('payoutToUid')
        ? overrides.payoutToUid!
        : '5cdd617a-14dc-4702-879d-31ae298e952b',
  }
}

export const aCompleteOfferingPayload = (
  overrides?: Partial<CompleteOfferingPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CompleteOfferingPayload' } & CompleteOfferingPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CompleteOfferingPayload')
  return {
    __typename: 'CompleteOfferingPayload',
    offering:
      overrides && overrides.hasOwnProperty('offering')
        ? overrides.offering!
        : relationshipsToOmit.has('Offering')
          ? ({} as Offering)
          : anOffering({}, relationshipsToOmit),
  }
}

export const aConfirmEmailPayload = (
  overrides?: Partial<ConfirmEmailPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ConfirmEmailPayload' } & ConfirmEmailPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConfirmEmailPayload')
  return {
    __typename: 'ConfirmEmailPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    user:
      overrides && overrides.hasOwnProperty('user')
        ? overrides.user!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
  }
}

export const aConnectHubConnectAccountsPreviewType = (
  overrides?: Partial<ConnectHubConnectAccountsPreviewType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ConnectHubConnectAccountsPreviewType' } & ConnectHubConnectAccountsPreviewType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConnectHubConnectAccountsPreviewType')
  return {
    __typename: 'ConnectHubConnectAccountsPreviewType',
    homeqQueueingPoints:
      overrides && overrides.hasOwnProperty('homeqQueueingPoints') ? overrides.homeqQueueingPoints! : 1463,
    homeqUser:
      overrides && overrides.hasOwnProperty('homeqUser')
        ? overrides.homeqUser!
        : relationshipsToOmit.has('ConnectHubHomeQUserSearchResultType')
          ? ({} as ConnectHubHomeQUserSearchResultType)
          : aConnectHubHomeQUserSearchResultType({}, relationshipsToOmit),
    queueingPointsReceivedFromQasa:
      overrides && overrides.hasOwnProperty('queueingPointsReceivedFromQasa')
        ? overrides.queueingPointsReceivedFromQasa!
        : 5046,
  }
}

export const aConnectHubHomeQBonusPointsType = (
  overrides?: Partial<ConnectHubHomeQBonusPointsType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ConnectHubHomeQBonusPointsType' } & ConnectHubHomeQBonusPointsType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConnectHubHomeQBonusPointsType')
  return {
    __typename: 'ConnectHubHomeQBonusPointsType',
    companyId: overrides && overrides.hasOwnProperty('companyId') ? overrides.companyId! : 7982,
    companyName:
      overrides && overrides.hasOwnProperty('companyName') ? overrides.companyName! : 'consequatur',
    points: overrides && overrides.hasOwnProperty('points') ? overrides.points! : 9716,
  }
}

export const aConnectHubHomeQClaimAttributesType = (
  overrides?: Partial<ConnectHubHomeQClaimAttributesType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ConnectHubHomeQClaimAttributesType' } & ConnectHubHomeQClaimAttributesType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConnectHubHomeQClaimAttributesType')
  return {
    __typename: 'ConnectHubHomeQClaimAttributesType',
    description: overrides && overrides.hasOwnProperty('description') ? overrides.description! : 'rerum',
    duration:
      overrides && overrides.hasOwnProperty('duration')
        ? overrides.duration!
        : ConnectHubHomeQClaimDurationEnum.EMPLOYMENT_FIXED,
    orgType:
      overrides && overrides.hasOwnProperty('orgType')
        ? overrides.orgType!
        : ConnectHubHomeQClaimOrgTypeEnum.LIMITED_COMPANY,
  }
}

export const aConnectHubHomeQClaimFileType = (
  overrides?: Partial<ConnectHubHomeQClaimFileType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ConnectHubHomeQClaimFileType' } & ConnectHubHomeQClaimFileType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConnectHubHomeQClaimFileType')
  return {
    __typename: 'ConnectHubHomeQClaimFileType',
    classification:
      overrides && overrides.hasOwnProperty('classification')
        ? overrides.classification!
        : ConnectHubHomeQClaimFileClassificationEnum.EMPLOYMENT_CONTRACT,
    file: overrides && overrides.hasOwnProperty('file') ? overrides.file! : 'laborum',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'd3c25ed7-fbef-4b9e-a216-74eb13d64dc9',
    reference: overrides && overrides.hasOwnProperty('reference') ? overrides.reference! : 'eos',
  }
}

export const aConnectHubHomeQConnectUserPayload = (
  overrides?: Partial<ConnectHubHomeQConnectUserPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ConnectHubHomeQConnectUserPayload' } & ConnectHubHomeQConnectUserPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConnectHubHomeQConnectUserPayload')
  return {
    __typename: 'ConnectHubHomeQConnectUserPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : true,
  }
}

export const aConnectHubHomeQCreateApplicationPayload = (
  overrides?: Partial<ConnectHubHomeQCreateApplicationPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ConnectHubHomeQCreateApplicationPayload' } & ConnectHubHomeQCreateApplicationPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConnectHubHomeQCreateApplicationPayload')
  return {
    __typename: 'ConnectHubHomeQCreateApplicationPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    homeApplication:
      overrides && overrides.hasOwnProperty('homeApplication')
        ? overrides.homeApplication!
        : relationshipsToOmit.has('ConnectHubHomeQHomeApplicationType')
          ? ({} as ConnectHubHomeQHomeApplicationType)
          : aConnectHubHomeQHomeApplicationType({}, relationshipsToOmit),
  }
}

export const aConnectHubHomeQCreateFileReferencePayload = (
  overrides?: Partial<ConnectHubHomeQCreateFileReferencePayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): {
  __typename: 'ConnectHubHomeQCreateFileReferencePayload'
} & ConnectHubHomeQCreateFileReferencePayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConnectHubHomeQCreateFileReferencePayload')
  return {
    __typename: 'ConnectHubHomeQCreateFileReferencePayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    fileReference:
      overrides && overrides.hasOwnProperty('fileReference')
        ? overrides.fileReference!
        : relationshipsToOmit.has('ConnectHubHomeQFileReferenceType')
          ? ({} as ConnectHubHomeQFileReferenceType)
          : aConnectHubHomeQFileReferenceType({}, relationshipsToOmit),
  }
}

export const aConnectHubHomeQCurrentClaimType = (
  overrides?: Partial<ConnectHubHomeQCurrentClaimType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ConnectHubHomeQCurrentClaimType' } & ConnectHubHomeQCurrentClaimType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConnectHubHomeQCurrentClaimType')
  return {
    __typename: 'ConnectHubHomeQCurrentClaimType',
    attributes:
      overrides && overrides.hasOwnProperty('attributes')
        ? overrides.attributes!
        : relationshipsToOmit.has('ConnectHubHomeQClaimAttributesType')
          ? ({} as ConnectHubHomeQClaimAttributesType)
          : aConnectHubHomeQClaimAttributesType({}, relationshipsToOmit),
    dateCreated:
      overrides && overrides.hasOwnProperty('dateCreated')
        ? overrides.dateCreated!
        : 'qui ducimus voluptatibus rerum voluptatem quia consequuntur',
    dateProcessed:
      overrides && overrides.hasOwnProperty('dateProcessed')
        ? overrides.dateProcessed!
        : 'suscipit et enim tempore hic et eveniet',
    expiryDate:
      overrides && overrides.hasOwnProperty('expiryDate')
        ? overrides.expiryDate!
        : 'repudiandae veniam aut eum voluptatem voluptate hic',
    files:
      overrides && overrides.hasOwnProperty('files')
        ? overrides.files!
        : [
            relationshipsToOmit.has('ConnectHubHomeQClaimFileType')
              ? ({} as ConnectHubHomeQClaimFileType)
              : aConnectHubHomeQClaimFileType({}, relationshipsToOmit),
          ],
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'a42a10fe-01f8-4238-b767-2d67e62892d4',
    income: overrides && overrides.hasOwnProperty('income') ? overrides.income! : 960,
    incomeSource:
      overrides && overrides.hasOwnProperty('incomeSource')
        ? overrides.incomeSource!
        : ConnectHubHomeQClaimIncomeSourceEnum.documents,
    mergedFile: overrides && overrides.hasOwnProperty('mergedFile') ? overrides.mergedFile! : 'quia',
    originalClaimId:
      overrides && overrides.hasOwnProperty('originalClaimId')
        ? overrides.originalClaimId!
        : 'ffd0e256-1ff5-433d-b5ea-45a9055eb590',
    reason: overrides && overrides.hasOwnProperty('reason') ? overrides.reason! : 'aut',
    renewed: overrides && overrides.hasOwnProperty('renewed') ? overrides.renewed! : true,
    status:
      overrides && overrides.hasOwnProperty('status')
        ? overrides.status!
        : ConnectHubHomeQClaimStatusEnum.approved,
    type:
      overrides && overrides.hasOwnProperty('type')
        ? overrides.type!
        : ConnectHubHomeQClaimTypeEnum.employment,
  }
}

export const aConnectHubHomeQDeleteApplicationInterestPayload = (
  overrides?: Partial<ConnectHubHomeQDeleteApplicationInterestPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): {
  __typename: 'ConnectHubHomeQDeleteApplicationInterestPayload'
} & ConnectHubHomeQDeleteApplicationInterestPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConnectHubHomeQDeleteApplicationInterestPayload')
  return {
    __typename: 'ConnectHubHomeQDeleteApplicationInterestPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : true,
  }
}

export const aConnectHubHomeQDeleteClaimPayload = (
  overrides?: Partial<ConnectHubHomeQDeleteClaimPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ConnectHubHomeQDeleteClaimPayload' } & ConnectHubHomeQDeleteClaimPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConnectHubHomeQDeleteClaimPayload')
  return {
    __typename: 'ConnectHubHomeQDeleteClaimPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : true,
  }
}

export const aConnectHubHomeQDisconnectUserPayload = (
  overrides?: Partial<ConnectHubHomeQDisconnectUserPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ConnectHubHomeQDisconnectUserPayload' } & ConnectHubHomeQDisconnectUserPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConnectHubHomeQDisconnectUserPayload')
  return {
    __typename: 'ConnectHubHomeQDisconnectUserPayload',
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : true,
  }
}

export const aConnectHubHomeQFileReferenceHeadersType = (
  overrides?: Partial<ConnectHubHomeQFileReferenceHeadersType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ConnectHubHomeQFileReferenceHeadersType' } & ConnectHubHomeQFileReferenceHeadersType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConnectHubHomeQFileReferenceHeadersType')
  return {
    __typename: 'ConnectHubHomeQFileReferenceHeadersType',
    contentMd5: overrides && overrides.hasOwnProperty('contentMd5') ? overrides.contentMd5! : 'incidunt',
    contentType: overrides && overrides.hasOwnProperty('contentType') ? overrides.contentType! : 'qui',
    xAmzAcl: overrides && overrides.hasOwnProperty('xAmzAcl') ? overrides.xAmzAcl! : 'suscipit',
  }
}

export const aConnectHubHomeQFileReferenceType = (
  overrides?: Partial<ConnectHubHomeQFileReferenceType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ConnectHubHomeQFileReferenceType' } & ConnectHubHomeQFileReferenceType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConnectHubHomeQFileReferenceType')
  return {
    __typename: 'ConnectHubHomeQFileReferenceType',
    fileReference:
      overrides && overrides.hasOwnProperty('fileReference') ? overrides.fileReference! : 'iusto',
    headers:
      overrides && overrides.hasOwnProperty('headers')
        ? overrides.headers!
        : relationshipsToOmit.has('ConnectHubHomeQFileReferenceHeadersType')
          ? ({} as ConnectHubHomeQFileReferenceHeadersType)
          : aConnectHubHomeQFileReferenceHeadersType({}, relationshipsToOmit),
    resourceUri: overrides && overrides.hasOwnProperty('resourceUri') ? overrides.resourceUri! : 'in',
    signedUrl: overrides && overrides.hasOwnProperty('signedUrl') ? overrides.signedUrl! : 'eligendi',
  }
}

export const aConnectHubHomeQHomeApplicationPreconditions = (
  overrides?: Partial<ConnectHubHomeQHomeApplicationPreconditions>,
  _relationshipsToOmit: Set<string> = new Set(),
): {
  __typename: 'ConnectHubHomeQHomeApplicationPreconditions'
} & ConnectHubHomeQHomeApplicationPreconditions => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConnectHubHomeQHomeApplicationPreconditions')
  return {
    __typename: 'ConnectHubHomeQHomeApplicationPreconditions',
    activeAd: overrides && overrides.hasOwnProperty('activeAd') ? overrides.activeAd! : false,
    allowsWarrantor:
      overrides && overrides.hasOwnProperty('allowsWarrantor') ? overrides.allowsWarrantor! : false,
    applicantGroupAlreadyApplied:
      overrides && overrides.hasOwnProperty('applicantGroupAlreadyApplied')
        ? overrides.applicantGroupAlreadyApplied!
        : false,
    applicantGroupContainsLocked:
      overrides && overrides.hasOwnProperty('applicantGroupContainsLocked')
        ? overrides.applicantGroupContainsLocked!
        : true,
    applicantGroupExceedLimit:
      overrides && overrides.hasOwnProperty('applicantGroupExceedLimit')
        ? overrides.applicantGroupExceedLimit!
        : false,
    applicantIncompleteProfile:
      overrides && overrides.hasOwnProperty('applicantIncompleteProfile')
        ? overrides.applicantIncompleteProfile!
        : true,
    applicantMissingOccupation:
      overrides && overrides.hasOwnProperty('applicantMissingOccupation')
        ? overrides.applicantMissingOccupation!
        : false,
    canApply: overrides && overrides.hasOwnProperty('canApply') ? overrides.canApply! : true,
    claimedWarrantor:
      overrides && overrides.hasOwnProperty('claimedWarrantor') ? overrides.claimedWarrantor! : true,
    coApplicantMissingInfo:
      overrides && overrides.hasOwnProperty('coApplicantMissingInfo')
        ? overrides.coApplicantMissingInfo!
        : true,
    isMainApplicant:
      overrides && overrides.hasOwnProperty('isMainApplicant') ? overrides.isMainApplicant! : false,
    restrictedDueToSeniorStatus:
      overrides && overrides.hasOwnProperty('restrictedDueToSeniorStatus')
        ? overrides.restrictedDueToSeniorStatus!
        : true,
    restrictedDueToStudentStatus:
      overrides && overrides.hasOwnProperty('restrictedDueToStudentStatus')
        ? overrides.restrictedDueToStudentStatus!
        : true,
    restrictedDueToYouthStatus:
      overrides && overrides.hasOwnProperty('restrictedDueToYouthStatus')
        ? overrides.restrictedDueToYouthStatus!
        : false,
    warrantorMissingInfo:
      overrides && overrides.hasOwnProperty('warrantorMissingInfo') ? overrides.warrantorMissingInfo! : true,
  }
}

export const aConnectHubHomeQHomeApplicationType = (
  overrides?: Partial<ConnectHubHomeQHomeApplicationType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ConnectHubHomeQHomeApplicationType' } & ConnectHubHomeQHomeApplicationType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConnectHubHomeQHomeApplicationType')
  return {
    __typename: 'ConnectHubHomeQHomeApplicationType',
    accessOnAgreement:
      overrides && overrides.hasOwnProperty('accessOnAgreement') ? overrides.accessOnAgreement! : false,
    area: overrides && overrides.hasOwnProperty('area') ? overrides.area! : 'laborum',
    city: overrides && overrides.hasOwnProperty('city') ? overrides.city! : 'earum',
    countsToLimit: overrides && overrides.hasOwnProperty('countsToLimit') ? overrides.countsToLimit! : true,
    dateAccess:
      overrides && overrides.hasOwnProperty('dateAccess')
        ? overrides.dateAccess!
        : 'earum rem libero quia est consequuntur reprehenderit',
    dateCreated:
      overrides && overrides.hasOwnProperty('dateCreated')
        ? overrides.dateCreated!
        : 'odit consequatur maiores animi tempora omnis nulla',
    displayImage: overrides && overrides.hasOwnProperty('displayImage') ? overrides.displayImage! : 'et',
    floor: overrides && overrides.hasOwnProperty('floor') ? overrides.floor! : 3177,
    home:
      overrides && overrides.hasOwnProperty('home')
        ? overrides.home!
        : relationshipsToOmit.has('Home')
          ? ({} as Home)
          : aHome({}, relationshipsToOmit),
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'ca4c8542-a97d-4721-9d87-a32ccda3ba9a',
    interestId: overrides && overrides.hasOwnProperty('interestId') ? overrides.interestId! : 8143,
    interests:
      overrides && overrides.hasOwnProperty('interests')
        ? overrides.interests!
        : [
            relationshipsToOmit.has('ConnectHubHomeQInterestType')
              ? ({} as ConnectHubHomeQInterestType)
              : aConnectHubHomeQInterestType({}, relationshipsToOmit),
          ],
    rent: overrides && overrides.hasOwnProperty('rent') ? overrides.rent! : 7409,
    responseDeadline:
      overrides && overrides.hasOwnProperty('responseDeadline') ? overrides.responseDeadline! : 'quo',
    rooms: overrides && overrides.hasOwnProperty('rooms') ? overrides.rooms! : 'omnis',
    status:
      overrides && overrides.hasOwnProperty('status')
        ? overrides.status!
        : ConnectHubHomeqHomeApplicationStatusEnum.APPLIED,
    street: overrides && overrides.hasOwnProperty('street') ? overrides.street! : 'debitis',
    viewing:
      overrides && overrides.hasOwnProperty('viewing')
        ? overrides.viewing!
        : relationshipsToOmit.has('ConnectHubHomeQViewingType')
          ? ({} as ConnectHubHomeQViewingType)
          : aConnectHubHomeQViewingType({}, relationshipsToOmit),
  }
}

export const aConnectHubHomeQHomeApplicationsType = (
  overrides?: Partial<ConnectHubHomeQHomeApplicationsType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ConnectHubHomeQHomeApplicationsType' } & ConnectHubHomeQHomeApplicationsType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConnectHubHomeQHomeApplicationsType')
  return {
    __typename: 'ConnectHubHomeQHomeApplicationsType',
    applications:
      overrides && overrides.hasOwnProperty('applications')
        ? overrides.applications!
        : [
            relationshipsToOmit.has('ConnectHubHomeQHomeApplicationType')
              ? ({} as ConnectHubHomeQHomeApplicationType)
              : aConnectHubHomeQHomeApplicationType({}, relationshipsToOmit),
          ],
    limit: overrides && overrides.hasOwnProperty('limit') ? overrides.limit! : 9371,
  }
}

export const aConnectHubHomeQInterestType = (
  overrides?: Partial<ConnectHubHomeQInterestType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ConnectHubHomeQInterestType' } & ConnectHubHomeQInterestType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConnectHubHomeQInterestType')
  return {
    __typename: 'ConnectHubHomeQInterestType',
    accessOnAgreement:
      overrides && overrides.hasOwnProperty('accessOnAgreement') ? overrides.accessOnAgreement! : false,
    area: overrides && overrides.hasOwnProperty('area') ? overrides.area! : 'at',
    city: overrides && overrides.hasOwnProperty('city') ? overrides.city! : 'fugit',
    countsToLimit: overrides && overrides.hasOwnProperty('countsToLimit') ? overrides.countsToLimit! : true,
    dateAccess:
      overrides && overrides.hasOwnProperty('dateAccess')
        ? overrides.dateAccess!
        : 'eos incidunt pariatur placeat cumque cum ut',
    dateCreated:
      overrides && overrides.hasOwnProperty('dateCreated')
        ? overrides.dateCreated!
        : 'quo id ullam maxime dignissimos eaque laboriosam',
    displayImage: overrides && overrides.hasOwnProperty('displayImage') ? overrides.displayImage! : 'quos',
    floor: overrides && overrides.hasOwnProperty('floor') ? overrides.floor! : 1211,
    home:
      overrides && overrides.hasOwnProperty('home')
        ? overrides.home!
        : relationshipsToOmit.has('Home')
          ? ({} as Home)
          : aHome({}, relationshipsToOmit),
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '30ca27c5-5407-492e-bf2c-c228f7382dde',
    landlordObjectId:
      overrides && overrides.hasOwnProperty('landlordObjectId') ? overrides.landlordObjectId! : 'mollitia',
    rent: overrides && overrides.hasOwnProperty('rent') ? overrides.rent! : 6096,
    responseDeadline:
      overrides && overrides.hasOwnProperty('responseDeadline')
        ? overrides.responseDeadline!
        : 'cupiditate quis deleniti ea et similique possimus',
    rooms: overrides && overrides.hasOwnProperty('rooms') ? overrides.rooms! : 'nisi',
    status:
      overrides && overrides.hasOwnProperty('status')
        ? overrides.status!
        : ConnectHubHomeqInterestStatusEnum.APPLIED,
    street: overrides && overrides.hasOwnProperty('street') ? overrides.street! : 'quo',
  }
}

export const aConnectHubHomeQLandlordCompanyType = (
  overrides?: Partial<ConnectHubHomeQLandlordCompanyType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ConnectHubHomeQLandlordCompanyType' } & ConnectHubHomeQLandlordCompanyType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConnectHubHomeQLandlordCompanyType')
  return {
    __typename: 'ConnectHubHomeQLandlordCompanyType',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '7460197b-38f3-42df-b5f6-deaaa0162de8',
    name: overrides && overrides.hasOwnProperty('name') ? overrides.name! : 'autem',
  }
}

export const aConnectHubHomeQPointsType = (
  overrides?: Partial<ConnectHubHomeQPointsType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ConnectHubHomeQPointsType' } & ConnectHubHomeQPointsType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConnectHubHomeQPointsType')
  return {
    __typename: 'ConnectHubHomeQPointsType',
    bonusPoints:
      overrides && overrides.hasOwnProperty('bonusPoints')
        ? overrides.bonusPoints!
        : [
            relationshipsToOmit.has('ConnectHubHomeQBonusPointsType')
              ? ({} as ConnectHubHomeQBonusPointsType)
              : aConnectHubHomeQBonusPointsType({}, relationshipsToOmit),
          ],
    queuePoints: overrides && overrides.hasOwnProperty('queuePoints') ? overrides.queuePoints! : 2240,
  }
}

export const aConnectHubHomeQRenewClaimPayload = (
  overrides?: Partial<ConnectHubHomeQRenewClaimPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ConnectHubHomeQRenewClaimPayload' } & ConnectHubHomeQRenewClaimPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConnectHubHomeQRenewClaimPayload')
  return {
    __typename: 'ConnectHubHomeQRenewClaimPayload',
    claim:
      overrides && overrides.hasOwnProperty('claim')
        ? overrides.claim!
        : relationshipsToOmit.has('ConnectHubHomeQCurrentClaimType')
          ? ({} as ConnectHubHomeQCurrentClaimType)
          : aConnectHubHomeQCurrentClaimType({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const aConnectHubHomeQUpdateClaimPayload = (
  overrides?: Partial<ConnectHubHomeQUpdateClaimPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ConnectHubHomeQUpdateClaimPayload' } & ConnectHubHomeQUpdateClaimPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConnectHubHomeQUpdateClaimPayload')
  return {
    __typename: 'ConnectHubHomeQUpdateClaimPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : true,
  }
}

export const aConnectHubHomeQUserClaimsType = (
  overrides?: Partial<ConnectHubHomeQUserClaimsType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ConnectHubHomeQUserClaimsType' } & ConnectHubHomeQUserClaimsType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConnectHubHomeQUserClaimsType')
  return {
    __typename: 'ConnectHubHomeQUserClaimsType',
    allowedClaimTypes:
      overrides && overrides.hasOwnProperty('allowedClaimTypes')
        ? overrides.allowedClaimTypes!
        : [ConnectHubHomeQClaimTypeEnum.employment],
    currentClaims:
      overrides && overrides.hasOwnProperty('currentClaims')
        ? overrides.currentClaims!
        : [
            relationshipsToOmit.has('ConnectHubHomeQCurrentClaimType')
              ? ({} as ConnectHubHomeQCurrentClaimType)
              : aConnectHubHomeQCurrentClaimType({}, relationshipsToOmit),
          ],
  }
}

export const aConnectHubHomeQUserSearchResultType = (
  overrides?: Partial<ConnectHubHomeQUserSearchResultType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ConnectHubHomeQUserSearchResultType' } & ConnectHubHomeQUserSearchResultType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConnectHubHomeQUserSearchResultType')
  return {
    __typename: 'ConnectHubHomeQUserSearchResultType',
    dateOfBirth: overrides && overrides.hasOwnProperty('dateOfBirth') ? overrides.dateOfBirth! : 'officiis',
    email: overrides && overrides.hasOwnProperty('email') ? overrides.email! : 'omnis',
    firstName: overrides && overrides.hasOwnProperty('firstName') ? overrides.firstName! : 'temporibus',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '2fbd5e05-30df-4cae-a648-a42051ae9c58',
    lastName: overrides && overrides.hasOwnProperty('lastName') ? overrides.lastName! : 'ut',
  }
}

export const aConnectHubHomeQUserType = (
  overrides?: Partial<ConnectHubHomeQUserType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ConnectHubHomeQUserType' } & ConnectHubHomeQUserType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConnectHubHomeQUserType')
  return {
    __typename: 'ConnectHubHomeQUserType',
    dateOfBirth: overrides && overrides.hasOwnProperty('dateOfBirth') ? overrides.dateOfBirth! : 'ut',
    email: overrides && overrides.hasOwnProperty('email') ? overrides.email! : 'eos',
    firstName: overrides && overrides.hasOwnProperty('firstName') ? overrides.firstName! : 'nesciunt',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'e793f21f-277a-4b47-a299-09bda58fe303',
    lastName: overrides && overrides.hasOwnProperty('lastName') ? overrides.lastName! : 'consectetur',
    points:
      overrides && overrides.hasOwnProperty('points')
        ? overrides.points!
        : relationshipsToOmit.has('ConnectHubHomeQPointsType')
          ? ({} as ConnectHubHomeQPointsType)
          : aConnectHubHomeQPointsType({}, relationshipsToOmit),
  }
}

export const aConnectHubHomeQViewingType = (
  overrides?: Partial<ConnectHubHomeQViewingType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ConnectHubHomeQViewingType' } & ConnectHubHomeQViewingType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConnectHubHomeQViewingType')
  return {
    __typename: 'ConnectHubHomeQViewingType',
    rsvpStatus:
      overrides && overrides.hasOwnProperty('rsvpStatus')
        ? overrides.rsvpStatus!
        : ConnectHubHomeqViewingResponseStatusEnum.NO,
    viewingDatetime:
      overrides && overrides.hasOwnProperty('viewingDatetime') ? overrides.viewingDatetime! : 'libero',
  }
}

export const aConnectHubInputObjectsCreateFileReferenceInput = (
  overrides?: Partial<ConnectHubInputObjectsCreateFileReferenceInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): ConnectHubInputObjectsCreateFileReferenceInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConnectHubInputObjectsCreateFileReferenceInput')
  return {
    contentLength: overrides && overrides.hasOwnProperty('contentLength') ? overrides.contentLength! : 7463,
    contentMd5: overrides && overrides.hasOwnProperty('contentMd5') ? overrides.contentMd5! : 'officiis',
    entityType:
      overrides && overrides.hasOwnProperty('entityType')
        ? overrides.entityType!
        : ConnectHubHomeQEntityTypeEnum.user_claim,
    mimeType: overrides && overrides.hasOwnProperty('mimeType') ? overrides.mimeType! : 'quia',
  }
}

export const aConnectHubInputObjectsUpdateClaimFileInput = (
  overrides?: Partial<ConnectHubInputObjectsUpdateClaimFileInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): ConnectHubInputObjectsUpdateClaimFileInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConnectHubInputObjectsUpdateClaimFileInput')
  return {
    classification:
      overrides && overrides.hasOwnProperty('classification')
        ? overrides.classification!
        : ConnectHubHomeQClaimFileClassificationEnum.EMPLOYMENT_CONTRACT,
    reference: overrides && overrides.hasOwnProperty('reference') ? overrides.reference! : 'eum',
  }
}

export const aConnectHubInputObjectsUpdateClaimInput = (
  overrides?: Partial<ConnectHubInputObjectsUpdateClaimInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): ConnectHubInputObjectsUpdateClaimInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConnectHubInputObjectsUpdateClaimInput')
  return {
    description: overrides && overrides.hasOwnProperty('description') ? overrides.description! : 'omnis',
    duration:
      overrides && overrides.hasOwnProperty('duration')
        ? overrides.duration!
        : ConnectHubHomeQClaimDurationEnum.EMPLOYMENT_FIXED,
    files:
      overrides && overrides.hasOwnProperty('files')
        ? overrides.files!
        : [
            relationshipsToOmit.has('ConnectHubInputObjectsUpdateClaimFileInput')
              ? ({} as ConnectHubInputObjectsUpdateClaimFileInput)
              : aConnectHubInputObjectsUpdateClaimFileInput({}, relationshipsToOmit),
          ],
    incomeSource:
      overrides && overrides.hasOwnProperty('incomeSource')
        ? overrides.incomeSource!
        : ConnectHubHomeQClaimIncomeSourceEnum.documents,
    moveToProcessing:
      overrides && overrides.hasOwnProperty('moveToProcessing') ? overrides.moveToProcessing! : true,
  }
}

export const aContract = (
  overrides?: Partial<Contract>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Contract' } & Contract => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Contract')
  return {
    __typename: 'Contract',
    acceptsCosignatures:
      overrides && overrides.hasOwnProperty('acceptsCosignatures') ? overrides.acceptsCosignatures! : false,
    adminNotes:
      overrides && overrides.hasOwnProperty('adminNotes')
        ? overrides.adminNotes!
        : [relationshipsToOmit.has('AdminNote') ? ({} as AdminNote) : anAdminNote({}, relationshipsToOmit)],
    annualRentIncrease:
      overrides && overrides.hasOwnProperty('annualRentIncrease') ? overrides.annualRentIncrease! : 1.48,
    apartmentNumber:
      overrides && overrides.hasOwnProperty('apartmentNumber') ? overrides.apartmentNumber! : 'occaecati',
    availablePreviewLanguages:
      overrides && overrides.hasOwnProperty('availablePreviewLanguages')
        ? overrides.availablePreviewLanguages!
        : [ContractLanguageEnum.en],
    booking:
      overrides && overrides.hasOwnProperty('booking')
        ? overrides.booking!
        : relationshipsToOmit.has('Booking')
          ? ({} as Booking)
          : aBooking({}, relationshipsToOmit),
    canUse: overrides && overrides.hasOwnProperty('canUse') ? overrides.canUse! : ['possimus'],
    cleaningFee: overrides && overrides.hasOwnProperty('cleaningFee') ? overrides.cleaningFee! : 8736,
    contractDetails:
      overrides && overrides.hasOwnProperty('contractDetails')
        ? overrides.contractDetails!
        : relationshipsToOmit.has('ContractDetails')
          ? ({} as ContractDetails)
          : aContractDetails({}, relationshipsToOmit),
    conversation:
      overrides && overrides.hasOwnProperty('conversation')
        ? overrides.conversation!
        : relationshipsToOmit.has('Conversation')
          ? ({} as Conversation)
          : aConversation({}, relationshipsToOmit),
    cosigningTenants:
      overrides && overrides.hasOwnProperty('cosigningTenants')
        ? overrides.cosigningTenants!
        : [relationshipsToOmit.has('User') ? ({} as User) : aUser({}, relationshipsToOmit)],
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'delectus minima autem harum ut dolor eum',
    currency: overrides && overrides.hasOwnProperty('currency') ? overrides.currency! : CurrencyEnum.EUR,
    currentUserSignatory:
      overrides && overrides.hasOwnProperty('currentUserSignatory')
        ? overrides.currentUserSignatory!
        : relationshipsToOmit.has('Signatory')
          ? ({} as Signatory)
          : aSignatory({}, relationshipsToOmit),
    depositFree: overrides && overrides.hasOwnProperty('depositFree') ? overrides.depositFree! : true,
    depositFreeCost:
      overrides && overrides.hasOwnProperty('depositFreeCost') ? overrides.depositFreeCost! : 729,
    depositPayoutApprovedAt:
      overrides && overrides.hasOwnProperty('depositPayoutApprovedAt')
        ? overrides.depositPayoutApprovedAt!
        : 'harum saepe et nam quam voluptas debitis',
    depositPayoutDeniedAt:
      overrides && overrides.hasOwnProperty('depositPayoutDeniedAt')
        ? overrides.depositPayoutDeniedAt!
        : 'nobis aut fuga enim architecto est libero',
    duration:
      overrides && overrides.hasOwnProperty('duration')
        ? overrides.duration!
        : relationshipsToOmit.has('Duration')
          ? ({} as Duration)
          : aDuration({}, relationshipsToOmit),
    electricityFee:
      overrides && overrides.hasOwnProperty('electricityFee')
        ? overrides.electricityFee!
        : relationshipsToOmit.has('ElectricityFeeType')
          ? ({} as ElectricityFeeType)
          : anElectricityFeeType({}, relationshipsToOmit),
    evictionProcess:
      overrides && overrides.hasOwnProperty('evictionProcess')
        ? overrides.evictionProcess!
        : relationshipsToOmit.has('EvictionProcess')
          ? ({} as EvictionProcess)
          : anEvictionProcess({}, relationshipsToOmit),
    extensionContract:
      overrides && overrides.hasOwnProperty('extensionContract')
        ? overrides.extensionContract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    externalContractTakeover:
      overrides && overrides.hasOwnProperty('externalContractTakeover')
        ? overrides.externalContractTakeover!
        : relationshipsToOmit.has('ExternalContractTakeover')
          ? ({} as ExternalContractTakeover)
          : anExternalContractTakeover({}, relationshipsToOmit),
    extraCosts:
      overrides && overrides.hasOwnProperty('extraCosts')
        ? overrides.extraCosts!
        : [relationshipsToOmit.has('ExtraCost') ? ({} as ExtraCost) : anExtraCost({}, relationshipsToOmit)],
    fee: overrides && overrides.hasOwnProperty('fee') ? overrides.fee! : 2487,
    financeDepositInvoices:
      overrides && overrides.hasOwnProperty('financeDepositInvoices')
        ? overrides.financeDepositInvoices!
        : [
            relationshipsToOmit.has('FinanceDepositInvoice')
              ? ({} as FinanceDepositInvoice)
              : aFinanceDepositInvoice({}, relationshipsToOmit),
          ],
    financeInvoices:
      overrides && overrides.hasOwnProperty('financeInvoices')
        ? overrides.financeInvoices!
        : [
            relationshipsToOmit.has('InvoiceInterface')
              ? ({} as InvoiceInterface)
              : anInvoiceInterface({}, relationshipsToOmit),
          ],
    financePayouts:
      overrides && overrides.hasOwnProperty('financePayouts')
        ? overrides.financePayouts!
        : [
            relationshipsToOmit.has('FinancePayoutType')
              ? ({} as FinancePayoutType)
              : aFinancePayoutType({}, relationshipsToOmit),
          ],
    financeRentInvoices:
      overrides && overrides.hasOwnProperty('financeRentInvoices')
        ? overrides.financeRentInvoices!
        : [
            relationshipsToOmit.has('FinanceMonthlyRentInvoice')
              ? ({} as FinanceMonthlyRentInvoice)
              : aFinanceMonthlyRentInvoice({}, relationshipsToOmit),
          ],
    furniture: overrides && overrides.hasOwnProperty('furniture') ? overrides.furniture! : true,
    home:
      overrides && overrides.hasOwnProperty('home')
        ? overrides.home!
        : relationshipsToOmit.has('Home')
          ? ({} as Home)
          : aHome({}, relationshipsToOmit),
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '30613e70-9e11-4f12-892b-4309a254eacd',
    ifInsurancePromotion:
      overrides && overrides.hasOwnProperty('ifInsurancePromotion')
        ? overrides.ifInsurancePromotion!
        : relationshipsToOmit.has('IfInsurancePromotion')
          ? ({} as IfInsurancePromotion)
          : anIfInsurancePromotion({}, relationshipsToOmit),
    inspectionProtocol:
      overrides && overrides.hasOwnProperty('inspectionProtocol')
        ? overrides.inspectionProtocol!
        : relationshipsToOmit.has('Blob')
          ? ({} as Blob)
          : aBlob({}, relationshipsToOmit),
    insurance:
      overrides && overrides.hasOwnProperty('insurance')
        ? overrides.insurance!
        : HomeInsuranceEnum.insurance_landlord,
    insuranceCost: overrides && overrides.hasOwnProperty('insuranceCost') ? overrides.insuranceCost! : 5539,
    insuranceRecord:
      overrides && overrides.hasOwnProperty('insuranceRecord')
        ? overrides.insuranceRecord!
        : relationshipsToOmit.has('Insurance')
          ? ({} as Insurance)
          : anInsurance({}, relationshipsToOmit),
    insuranceResponsibility:
      overrides && overrides.hasOwnProperty('insuranceResponsibility')
        ? overrides.insuranceResponsibility!
        : InsuranceResponsibilityEnum.insurance_responsibility_landlord,
    inventory: overrides && overrides.hasOwnProperty('inventory') ? overrides.inventory! : ['accusamus'],
    inventoryLists:
      overrides && overrides.hasOwnProperty('inventoryLists')
        ? overrides.inventoryLists!
        : [relationshipsToOmit.has('Blob') ? ({} as Blob) : aBlob({}, relationshipsToOmit)],
    invoicedThrough:
      overrides && overrides.hasOwnProperty('invoicedThrough')
        ? overrides.invoicedThrough!
        : 'cupiditate maxime quia aut eaque excepturi vel',
    invoices:
      overrides && overrides.hasOwnProperty('invoices')
        ? overrides.invoices!
        : [relationshipsToOmit.has('Invoice') ? ({} as Invoice) : anInvoice({}, relationshipsToOmit)],
    landlord:
      overrides && overrides.hasOwnProperty('landlord')
        ? overrides.landlord!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
    landlordAffiliateCode:
      overrides && overrides.hasOwnProperty('landlordAffiliateCode')
        ? overrides.landlordAffiliateCode!
        : relationshipsToOmit.has('AffiliateCode')
          ? ({} as AffiliateCode)
          : anAffiliateCode({}, relationshipsToOmit),
    landlordBaseFee:
      overrides && overrides.hasOwnProperty('landlordBaseFee') ? overrides.landlordBaseFee! : 6955,
    landlordContactInfo:
      overrides && overrides.hasOwnProperty('landlordContactInfo')
        ? overrides.landlordContactInfo!
        : relationshipsToOmit.has('LandlordContactInfo')
          ? ({} as LandlordContactInfo)
          : aLandlordContactInfo({}, relationshipsToOmit),
    landlordFees:
      overrides && overrides.hasOwnProperty('landlordFees')
        ? overrides.landlordFees!
        : relationshipsToOmit.has('ContractLandlordFees')
          ? ({} as ContractLandlordFees)
          : aContractLandlordFees({}, relationshipsToOmit),
    landlordHasKeys:
      overrides && overrides.hasOwnProperty('landlordHasKeys') ? overrides.landlordHasKeys! : false,
    landlordPostSignDocuments:
      overrides && overrides.hasOwnProperty('landlordPostSignDocuments')
        ? overrides.landlordPostSignDocuments!
        : [relationshipsToOmit.has('Blob') ? ({} as Blob) : aBlob({}, relationshipsToOmit)],
    landlordServices:
      overrides && overrides.hasOwnProperty('landlordServices')
        ? overrides.landlordServices!
        : relationshipsToOmit.has('ContractLandlordServices')
          ? ({} as ContractLandlordServices)
          : aContractLandlordServices({}, relationshipsToOmit),
    language:
      overrides && overrides.hasOwnProperty('language') ? overrides.language! : ContractLanguageEnum.en,
    market:
      overrides && overrides.hasOwnProperty('market')
        ? overrides.market!
        : relationshipsToOmit.has('Market')
          ? ({} as Market)
          : aMarket({}, relationshipsToOmit),
    marketDetails:
      overrides && overrides.hasOwnProperty('marketDetails')
        ? overrides.marketDetails!
        : relationshipsToOmit.has('FinlandMarketDetails')
          ? ({} as FinlandMarketDetails)
          : aFinlandMarketDetails({}, relationshipsToOmit),
    motherContract:
      overrides && overrides.hasOwnProperty('motherContract')
        ? overrides.motherContract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    numberOfTenants:
      overrides && overrides.hasOwnProperty('numberOfTenants') ? overrides.numberOfTenants! : 5394,
    originalFee: overrides && overrides.hasOwnProperty('originalFee') ? overrides.originalFee! : 3953,
    otherLandlordDocuments:
      overrides && overrides.hasOwnProperty('otherLandlordDocuments')
        ? overrides.otherLandlordDocuments!
        : [relationshipsToOmit.has('Blob') ? ({} as Blob) : aBlob({}, relationshipsToOmit)],
    overnightStay: overrides && overrides.hasOwnProperty('overnightStay') ? overrides.overnightStay! : true,
    ownHome: overrides && overrides.hasOwnProperty('ownHome') ? overrides.ownHome! : false,
    paymentDay: overrides && overrides.hasOwnProperty('paymentDay') ? overrides.paymentDay! : 8716,
    paymentInAdvance:
      overrides && overrides.hasOwnProperty('paymentInAdvance') ? overrides.paymentInAdvance! : true,
    payoutDay: overrides && overrides.hasOwnProperty('payoutDay') ? overrides.payoutDay! : 2639,
    payoutInAdvance:
      overrides && overrides.hasOwnProperty('payoutInAdvance') ? overrides.payoutInAdvance! : true,
    pendingTerminationEndDate:
      overrides && overrides.hasOwnProperty('pendingTerminationEndDate')
        ? overrides.pendingTerminationEndDate!
        : 'sit iusto voluptatem dolorem nobis ex et',
    pets: overrides && overrides.hasOwnProperty('pets') ? overrides.pets! : true,
    postCleaning:
      overrides && overrides.hasOwnProperty('postCleaning')
        ? overrides.postCleaning!
        : ContractPostCleaningEnum.post_cleaning_landlord,
    preCleaning:
      overrides && overrides.hasOwnProperty('preCleaning')
        ? overrides.preCleaning!
        : ContractPreCleaningEnum.pre_cleaning_landlord,
    pricingModel:
      overrides && overrides.hasOwnProperty('pricingModel')
        ? overrides.pricingModel!
        : relationshipsToOmit.has('PricingModel')
          ? ({} as PricingModel)
          : aPricingModel({}, relationshipsToOmit),
    qasaGuarantee: overrides && overrides.hasOwnProperty('qasaGuarantee') ? overrides.qasaGuarantee! : true,
    qasaGuaranteeCost:
      overrides && overrides.hasOwnProperty('qasaGuaranteeCost') ? overrides.qasaGuaranteeCost! : 9334,
    qasaGuaranteeMonths:
      overrides && overrides.hasOwnProperty('qasaGuaranteeMonths') ? overrides.qasaGuaranteeMonths! : 5293,
    renovations: overrides && overrides.hasOwnProperty('renovations') ? overrides.renovations! : false,
    rent: overrides && overrides.hasOwnProperty('rent') ? overrides.rent! : 4760,
    rentExcludes: overrides && overrides.hasOwnProperty('rentExcludes') ? overrides.rentExcludes! : ['est'],
    rentIncludes: overrides && overrides.hasOwnProperty('rentIncludes') ? overrides.rentIncludes! : ['dicta'],
    rentalInsuranceMilaRequest:
      overrides && overrides.hasOwnProperty('rentalInsuranceMilaRequest')
        ? overrides.rentalInsuranceMilaRequest!
        : relationshipsToOmit.has('FranceInsuranceRequestType')
          ? ({} as FranceInsuranceRequestType)
          : aFranceInsuranceRequestType({}, relationshipsToOmit),
    rentalType:
      overrides && overrides.hasOwnProperty('rentalType')
        ? overrides.rentalType!
        : ContractRentalTypeEnum.long_term,
    scriveId:
      overrides && overrides.hasOwnProperty('scriveId')
        ? overrides.scriveId!
        : '045b2139-623c-46ae-a4ef-8a16ab72a446',
    signUrl: overrides && overrides.hasOwnProperty('signUrl') ? overrides.signUrl! : 'vel',
    signatories:
      overrides && overrides.hasOwnProperty('signatories')
        ? overrides.signatories!
        : [relationshipsToOmit.has('Signatory') ? ({} as Signatory) : aSignatory({}, relationshipsToOmit)],
    signedAt:
      overrides && overrides.hasOwnProperty('signedAt')
        ? overrides.signedAt!
        : 'qui sed eum accusamus quod sint molestiae',
    signingProcess:
      overrides && overrides.hasOwnProperty('signingProcess')
        ? overrides.signingProcess!
        : SigningProcessEnum.default,
    smallRepairs:
      overrides && overrides.hasOwnProperty('smallRepairs')
        ? overrides.smallRepairs!
        : ContractSmallRepairsEnum.small_repairs_landlord,
    smoker: overrides && overrides.hasOwnProperty('smoker') ? overrides.smoker! : false,
    status:
      overrides && overrides.hasOwnProperty('status')
        ? overrides.status!
        : ContractStatusEnum.awaiting_insurance_approval,
    subletception: overrides && overrides.hasOwnProperty('subletception') ? overrides.subletception! : false,
    technicalDiagnoses:
      overrides && overrides.hasOwnProperty('technicalDiagnoses')
        ? overrides.technicalDiagnoses!
        : [relationshipsToOmit.has('Blob') ? ({} as Blob) : aBlob({}, relationshipsToOmit)],
    temporaryInstallations:
      overrides && overrides.hasOwnProperty('temporaryInstallations')
        ? overrides.temporaryInstallations!
        : true,
    tenant:
      overrides && overrides.hasOwnProperty('tenant')
        ? overrides.tenant!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
    tenantAffiliateCode:
      overrides && overrides.hasOwnProperty('tenantAffiliateCode')
        ? overrides.tenantAffiliateCode!
        : relationshipsToOmit.has('AffiliateCode')
          ? ({} as AffiliateCode)
          : anAffiliateCode({}, relationshipsToOmit),
    tenantBaseFee: overrides && overrides.hasOwnProperty('tenantBaseFee') ? overrides.tenantBaseFee! : 7843,
    tenantContactInfo:
      overrides && overrides.hasOwnProperty('tenantContactInfo')
        ? overrides.tenantContactInfo!
        : relationshipsToOmit.has('TenantContactInfo')
          ? ({} as TenantContactInfo)
          : aTenantContactInfo({}, relationshipsToOmit),
    tenantFees:
      overrides && overrides.hasOwnProperty('tenantFees')
        ? overrides.tenantFees!
        : relationshipsToOmit.has('ContractTenantFees')
          ? ({} as ContractTenantFees)
          : aContractTenantFees({}, relationshipsToOmit),
    tenantInvitations:
      overrides && overrides.hasOwnProperty('tenantInvitations')
        ? overrides.tenantInvitations!
        : [
            relationshipsToOmit.has('ContractTenantInvitation')
              ? ({} as ContractTenantInvitation)
              : aContractTenantInvitation({}, relationshipsToOmit),
          ],
    tenantPostSignDocuments:
      overrides && overrides.hasOwnProperty('tenantPostSignDocuments')
        ? overrides.tenantPostSignDocuments!
        : [relationshipsToOmit.has('Blob') ? ({} as Blob) : aBlob({}, relationshipsToOmit)],
    tenantServices:
      overrides && overrides.hasOwnProperty('tenantServices')
        ? overrides.tenantServices!
        : relationshipsToOmit.has('ContractTenantServices')
          ? ({} as ContractTenantServices)
          : aContractTenantServices({}, relationshipsToOmit),
    tenureType:
      overrides && overrides.hasOwnProperty('tenureType')
        ? overrides.tenureType!
        : TenureTypeEnum.condominium,
    terminationNotice:
      overrides && overrides.hasOwnProperty('terminationNotice') ? overrides.terminationNotice! : true,
    termsUrl: overrides && overrides.hasOwnProperty('termsUrl') ? overrides.termsUrl! : 'eum',
    type: overrides && overrides.hasOwnProperty('type') ? overrides.type! : ContractTypeEnum.extension,
    updatedAt:
      overrides && overrides.hasOwnProperty('updatedAt')
        ? overrides.updatedAt!
        : 'velit maiores minus suscipit quam totam voluptatum',
    upload:
      overrides && overrides.hasOwnProperty('upload')
        ? overrides.upload!
        : relationshipsToOmit.has('Upload')
          ? ({} as Upload)
          : anUpload({}, relationshipsToOmit),
    user:
      overrides && overrides.hasOwnProperty('user')
        ? overrides.user!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
  }
}

export const aContractConnection = (
  overrides?: Partial<ContractConnection>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ContractConnection' } & ContractConnection => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ContractConnection')
  return {
    __typename: 'ContractConnection',
    edges:
      overrides && overrides.hasOwnProperty('edges')
        ? overrides.edges!
        : [
            relationshipsToOmit.has('ContractEdge')
              ? ({} as ContractEdge)
              : aContractEdge({}, relationshipsToOmit),
          ],
    nodes:
      overrides && overrides.hasOwnProperty('nodes')
        ? overrides.nodes!
        : [relationshipsToOmit.has('Contract') ? ({} as Contract) : aContract({}, relationshipsToOmit)],
    pageInfo:
      overrides && overrides.hasOwnProperty('pageInfo')
        ? overrides.pageInfo!
        : relationshipsToOmit.has('PageInfo')
          ? ({} as PageInfo)
          : aPageInfo({}, relationshipsToOmit),
    totalCount: overrides && overrides.hasOwnProperty('totalCount') ? overrides.totalCount! : 8483,
  }
}

export const aContractDetails = (
  overrides?: Partial<ContractDetails>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ContractDetails' } & ContractDetails => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ContractDetails')
  return {
    __typename: 'ContractDetails',
    additionalInformation:
      overrides && overrides.hasOwnProperty('additionalInformation')
        ? overrides.additionalInformation!
        : 'molestias',
    bathroomShared:
      overrides && overrides.hasOwnProperty('bathroomShared') ? overrides.bathroomShared! : true,
    bindingTime: overrides && overrides.hasOwnProperty('bindingTime') ? overrides.bindingTime! : 2010,
    cadastralReference:
      overrides && overrides.hasOwnProperty('cadastralReference') ? overrides.cadastralReference! : 'nihil',
    canBeTerminated:
      overrides && overrides.hasOwnProperty('canBeTerminated') ? overrides.canBeTerminated! : true,
    codeOfConduct:
      overrides && overrides.hasOwnProperty('codeOfConduct') ? overrides.codeOfConduct! : 'itaque',
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'ut vero temporibus iusto quidem et sint',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '834c53c0-5c85-4ef4-8d01-036a6b8e1e27',
    keySpecification:
      overrides && overrides.hasOwnProperty('keySpecification') ? overrides.keySpecification! : 'vitae',
    kitchenShared: overrides && overrides.hasOwnProperty('kitchenShared') ? overrides.kitchenShared! : true,
    knownDamages: overrides && overrides.hasOwnProperty('knownDamages') ? overrides.knownDamages! : 'commodi',
    legalType:
      overrides && overrides.hasOwnProperty('legalType') ? overrides.legalType! : LegalTypeEnum.egen_bolig,
    municipality:
      overrides && overrides.hasOwnProperty('municipality') ? overrides.municipality! : 'temporibus',
    rentedAsIs: overrides && overrides.hasOwnProperty('rentedAsIs') ? overrides.rentedAsIs! : true,
    sectionNumber: overrides && overrides.hasOwnProperty('sectionNumber') ? overrides.sectionNumber! : 'et',
    shorterTerminationExplanation:
      overrides && overrides.hasOwnProperty('shorterTerminationExplanation')
        ? overrides.shorterTerminationExplanation!
        : 'illum',
    tenantEmail: overrides && overrides.hasOwnProperty('tenantEmail') ? overrides.tenantEmail! : 'dolores',
    tenantFamilyName:
      overrides && overrides.hasOwnProperty('tenantFamilyName') ? overrides.tenantFamilyName! : 'aut',
    tenantFirstName:
      overrides && overrides.hasOwnProperty('tenantFirstName') ? overrides.tenantFirstName! : 'inventore',
    tenantPhoneNumber:
      overrides && overrides.hasOwnProperty('tenantPhoneNumber') ? overrides.tenantPhoneNumber! : 'provident',
    terminationNoticeMonths:
      overrides && overrides.hasOwnProperty('terminationNoticeMonths')
        ? overrides.terminationNoticeMonths!
        : 2988,
    titleNumber: overrides && overrides.hasOwnProperty('titleNumber') ? overrides.titleNumber! : 'occaecati',
    updatedAt:
      overrides && overrides.hasOwnProperty('updatedAt')
        ? overrides.updatedAt!
        : 'numquam neque nesciunt molestiae assumenda sed vel',
  }
}

export const aContractEdge = (
  overrides?: Partial<ContractEdge>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ContractEdge' } & ContractEdge => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ContractEdge')
  return {
    __typename: 'ContractEdge',
    cursor: overrides && overrides.hasOwnProperty('cursor') ? overrides.cursor! : 'sit',
    node:
      overrides && overrides.hasOwnProperty('node')
        ? overrides.node!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
  }
}

export const aContractInput = (
  overrides?: Partial<ContractInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): ContractInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ContractInput')
  return {
    furniture: overrides && overrides.hasOwnProperty('furniture') ? overrides.furniture! : false,
    recoverableCharges:
      overrides && overrides.hasOwnProperty('recoverableCharges')
        ? overrides.recoverableCharges!
        : relationshipsToOmit.has('FranceRecoverableChargesInput')
          ? ({} as FranceRecoverableChargesInput)
          : aFranceRecoverableChargesInput({}, relationshipsToOmit),
    scriveId: overrides && overrides.hasOwnProperty('scriveId') ? overrides.scriveId! : 'molestiae',
  }
}

export const aContractLandlordFees = (
  overrides?: Partial<ContractLandlordFees>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ContractLandlordFees' } & ContractLandlordFees => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ContractLandlordFees')
  return {
    __typename: 'ContractLandlordFees',
    base:
      overrides && overrides.hasOwnProperty('base')
        ? overrides.base!
        : relationshipsToOmit.has('Fee')
          ? ({} as Fee)
          : aFee({}, relationshipsToOmit),
  }
}

export const aContractLandlordServices = (
  overrides?: Partial<ContractLandlordServices>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ContractLandlordServices' } & ContractLandlordServices => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ContractLandlordServices')
  return {
    __typename: 'ContractLandlordServices',
    insurance:
      overrides && overrides.hasOwnProperty('insurance')
        ? overrides.insurance!
        : relationshipsToOmit.has('Service')
          ? ({} as Service)
          : aService({}, relationshipsToOmit),
    qasaGuarantee:
      overrides && overrides.hasOwnProperty('qasaGuarantee')
        ? overrides.qasaGuarantee!
        : relationshipsToOmit.has('Service')
          ? ({} as Service)
          : aService({}, relationshipsToOmit),
  }
}

export const aContractPreview = (
  overrides?: Partial<ContractPreview>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ContractPreview' } & ContractPreview => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ContractPreview')
  return {
    __typename: 'ContractPreview',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    previewSource:
      overrides && overrides.hasOwnProperty('previewSource') ? overrides.previewSource! : 'accusantium',
  }
}

export const aContractTemplate = (
  overrides?: Partial<ContractTemplate>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ContractTemplate' } & ContractTemplate => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ContractTemplate')
  return {
    __typename: 'ContractTemplate',
    canUse: overrides && overrides.hasOwnProperty('canUse') ? overrides.canUse! : ['deleniti'],
    landlordHasKeys:
      overrides && overrides.hasOwnProperty('landlordHasKeys') ? overrides.landlordHasKeys! : true,
    rentExcludes:
      overrides && overrides.hasOwnProperty('rentExcludes') ? overrides.rentExcludes! : ['occaecati'],
    rentIncludes: overrides && overrides.hasOwnProperty('rentIncludes') ? overrides.rentIncludes! : ['illum'],
    smallRepairs:
      overrides && overrides.hasOwnProperty('smallRepairs')
        ? overrides.smallRepairs!
        : ContractSmallRepairsEnum.small_repairs_landlord,
  }
}

export const aContractTemplateInput = (
  overrides?: Partial<ContractTemplateInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): ContractTemplateInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ContractTemplateInput')
  return {
    canUse: overrides && overrides.hasOwnProperty('canUse') ? overrides.canUse! : ['rerum'],
    landlordHasKeys:
      overrides && overrides.hasOwnProperty('landlordHasKeys') ? overrides.landlordHasKeys! : false,
    rentExcludes: overrides && overrides.hasOwnProperty('rentExcludes') ? overrides.rentExcludes! : ['sed'],
    rentIncludes:
      overrides && overrides.hasOwnProperty('rentIncludes') ? overrides.rentIncludes! : ['magnam'],
    smallRepairs:
      overrides && overrides.hasOwnProperty('smallRepairs')
        ? overrides.smallRepairs!
        : ContractSmallRepairsEnum.small_repairs_landlord,
  }
}

export const aContractTenantFees = (
  overrides?: Partial<ContractTenantFees>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ContractTenantFees' } & ContractTenantFees => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ContractTenantFees')
  return {
    __typename: 'ContractTenantFees',
    base:
      overrides && overrides.hasOwnProperty('base')
        ? overrides.base!
        : relationshipsToOmit.has('Fee')
          ? ({} as Fee)
          : aFee({}, relationshipsToOmit),
    monthlyElectricityFee:
      overrides && overrides.hasOwnProperty('monthlyElectricityFee')
        ? overrides.monthlyElectricityFee!
        : relationshipsToOmit.has('Fee')
          ? ({} as Fee)
          : aFee({}, relationshipsToOmit),
    waterFeePerTenant:
      overrides && overrides.hasOwnProperty('waterFeePerTenant')
        ? overrides.waterFeePerTenant!
        : relationshipsToOmit.has('Fee')
          ? ({} as Fee)
          : aFee({}, relationshipsToOmit),
  }
}

export const aContractTenantInvitation = (
  overrides?: Partial<ContractTenantInvitation>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ContractTenantInvitation' } & ContractTenantInvitation => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ContractTenantInvitation')
  return {
    __typename: 'ContractTenantInvitation',
    claimKey: overrides && overrides.hasOwnProperty('claimKey') ? overrides.claimKey! : 'asperiores',
    claimable: overrides && overrides.hasOwnProperty('claimable') ? overrides.claimable! : true,
    home:
      overrides && overrides.hasOwnProperty('home')
        ? overrides.home!
        : relationshipsToOmit.has('Home')
          ? ({} as Home)
          : aHome({}, relationshipsToOmit),
    invitedBy:
      overrides && overrides.hasOwnProperty('invitedBy')
        ? overrides.invitedBy!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
    recipientEmail:
      overrides && overrides.hasOwnProperty('recipientEmail') ? overrides.recipientEmail! : 'pariatur',
    tenantType:
      overrides && overrides.hasOwnProperty('tenantType') ? overrides.tenantType! : TenantTypeEnum.cosignee,
  }
}

export const aContractTenantServices = (
  overrides?: Partial<ContractTenantServices>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ContractTenantServices' } & ContractTenantServices => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ContractTenantServices')
  return {
    __typename: 'ContractTenantServices',
    depositFree:
      overrides && overrides.hasOwnProperty('depositFree')
        ? overrides.depositFree!
        : relationshipsToOmit.has('Service')
          ? ({} as Service)
          : aService({}, relationshipsToOmit),
  }
}

export const aContractsByStatusCounts = (
  overrides?: Partial<ContractsByStatusCounts>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ContractsByStatusCounts' } & ContractsByStatusCounts => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ContractsByStatusCounts')
  return {
    __typename: 'ContractsByStatusCounts',
    awaitingInsuranceApproval:
      overrides && overrides.hasOwnProperty('awaitingInsuranceApproval')
        ? overrides.awaitingInsuranceApproval!
        : 3388,
    canceled: overrides && overrides.hasOwnProperty('canceled') ? overrides.canceled! : 6098,
    canceledBadCertificate:
      overrides && overrides.hasOwnProperty('canceledBadCertificate')
        ? overrides.canceledBadCertificate!
        : 9073,
    draft: overrides && overrides.hasOwnProperty('draft') ? overrides.draft! : 1790,
    ended: overrides && overrides.hasOwnProperty('ended') ? overrides.ended! : 7915,
    extended: overrides && overrides.hasOwnProperty('extended') ? overrides.extended! : 9860,
    finalDraft: overrides && overrides.hasOwnProperty('finalDraft') ? overrides.finalDraft! : 3716,
    pending: overrides && overrides.hasOwnProperty('pending') ? overrides.pending! : 9874,
    pendingAprilaApplication:
      overrides && overrides.hasOwnProperty('pendingAprilaApplication')
        ? overrides.pendingAprilaApplication!
        : 3170,
    pendingAprilaRentalAgreement:
      overrides && overrides.hasOwnProperty('pendingAprilaRentalAgreement')
        ? overrides.pendingAprilaRentalAgreement!
        : 3047,
    pendingTenantCreditCheck:
      overrides && overrides.hasOwnProperty('pendingTenantCreditCheck')
        ? overrides.pendingTenantCreditCheck!
        : 1437,
    rejected: overrides && overrides.hasOwnProperty('rejected') ? overrides.rejected! : 2705,
    signed: overrides && overrides.hasOwnProperty('signed') ? overrides.signed! : 9552,
    timedout: overrides && overrides.hasOwnProperty('timedout') ? overrides.timedout! : 8200,
  }
}

export const aContractsCounts = (
  overrides?: Partial<ContractsCounts>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ContractsCounts' } & ContractsCounts => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ContractsCounts')
  return {
    __typename: 'ContractsCounts',
    awaitingInsuranceApproval:
      overrides && overrides.hasOwnProperty('awaitingInsuranceApproval')
        ? overrides.awaitingInsuranceApproval!
        : 9157,
    canceled: overrides && overrides.hasOwnProperty('canceled') ? overrides.canceled! : 1516,
    canceledBadCertificate:
      overrides && overrides.hasOwnProperty('canceledBadCertificate')
        ? overrides.canceledBadCertificate!
        : 7707,
    draft: overrides && overrides.hasOwnProperty('draft') ? overrides.draft! : 3110,
    ended: overrides && overrides.hasOwnProperty('ended') ? overrides.ended! : 1844,
    extended: overrides && overrides.hasOwnProperty('extended') ? overrides.extended! : 5979,
    finalDraft: overrides && overrides.hasOwnProperty('finalDraft') ? overrides.finalDraft! : 7515,
    longTerm:
      overrides && overrides.hasOwnProperty('longTerm')
        ? overrides.longTerm!
        : relationshipsToOmit.has('ContractsByStatusCounts')
          ? ({} as ContractsByStatusCounts)
          : aContractsByStatusCounts({}, relationshipsToOmit),
    pending: overrides && overrides.hasOwnProperty('pending') ? overrides.pending! : 9611,
    pendingAprilaApplication:
      overrides && overrides.hasOwnProperty('pendingAprilaApplication')
        ? overrides.pendingAprilaApplication!
        : 7600,
    pendingAprilaRentalAgreement:
      overrides && overrides.hasOwnProperty('pendingAprilaRentalAgreement')
        ? overrides.pendingAprilaRentalAgreement!
        : 3570,
    pendingTenantCreditCheck:
      overrides && overrides.hasOwnProperty('pendingTenantCreditCheck')
        ? overrides.pendingTenantCreditCheck!
        : 1834,
    rejected: overrides && overrides.hasOwnProperty('rejected') ? overrides.rejected! : 644,
    signed: overrides && overrides.hasOwnProperty('signed') ? overrides.signed! : 5226,
    timedout: overrides && overrides.hasOwnProperty('timedout') ? overrides.timedout! : 3514,
    total:
      overrides && overrides.hasOwnProperty('total')
        ? overrides.total!
        : relationshipsToOmit.has('ContractsByStatusCounts')
          ? ({} as ContractsByStatusCounts)
          : aContractsByStatusCounts({}, relationshipsToOmit),
    vacation:
      overrides && overrides.hasOwnProperty('vacation')
        ? overrides.vacation!
        : relationshipsToOmit.has('ContractsByStatusCounts')
          ? ({} as ContractsByStatusCounts)
          : aContractsByStatusCounts({}, relationshipsToOmit),
  }
}

export const aConversation = (
  overrides?: Partial<Conversation>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Conversation' } & Conversation => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Conversation')
  return {
    __typename: 'Conversation',
    archivedAt:
      overrides && overrides.hasOwnProperty('archivedAt')
        ? overrides.archivedAt!
        : 'nihil accusamus nihil eveniet rerum voluptatibus incidunt',
    booking:
      overrides && overrides.hasOwnProperty('booking')
        ? overrides.booking!
        : relationshipsToOmit.has('Booking')
          ? ({} as Booking)
          : aBooking({}, relationshipsToOmit),
    conversationUserRelations:
      overrides && overrides.hasOwnProperty('conversationUserRelations')
        ? overrides.conversationUserRelations!
        : [
            relationshipsToOmit.has('ConversationUserRelation')
              ? ({} as ConversationUserRelation)
              : aConversationUserRelation({}, relationshipsToOmit),
          ],
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'ut voluptas magni et dignissimos omnis qui',
    currentConversationState:
      overrides && overrides.hasOwnProperty('currentConversationState')
        ? overrides.currentConversationState!
        : ConversationStateEnum.contract_draft_created,
    home:
      overrides && overrides.hasOwnProperty('home')
        ? overrides.home!
        : relationshipsToOmit.has('Home')
          ? ({} as Home)
          : aHome({}, relationshipsToOmit),
    homeApplication:
      overrides && overrides.hasOwnProperty('homeApplication')
        ? overrides.homeApplication!
        : relationshipsToOmit.has('HomeApplication')
          ? ({} as HomeApplication)
          : aHomeApplication({}, relationshipsToOmit),
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '13fc8765-4c48-40be-858a-742435b7d342',
    initiator: overrides && overrides.hasOwnProperty('initiator') ? overrides.initiator! : false,
    lastMessage:
      overrides && overrides.hasOwnProperty('lastMessage')
        ? overrides.lastMessage!
        : relationshipsToOmit.has('ConversationMessage')
          ? ({} as ConversationMessage)
          : aConversationMessage({}, relationshipsToOmit),
    lastSeenMessageId:
      overrides && overrides.hasOwnProperty('lastSeenMessageId')
        ? overrides.lastSeenMessageId!
        : '8fdd8e7f-23b9-4051-a934-1ee3de02c491',
    lastSeenMessageIds:
      overrides && overrides.hasOwnProperty('lastSeenMessageIds')
        ? overrides.lastSeenMessageIds!
        : [
            relationshipsToOmit.has('LastSeenMessage')
              ? ({} as LastSeenMessage)
              : aLastSeenMessage({}, relationshipsToOmit),
          ],
    messages:
      overrides && overrides.hasOwnProperty('messages')
        ? overrides.messages!
        : relationshipsToOmit.has('ConversationMessageConnection')
          ? ({} as ConversationMessageConnection)
          : aConversationMessageConnection({}, relationshipsToOmit),
    read: overrides && overrides.hasOwnProperty('read') ? overrides.read! : false,
    role: overrides && overrides.hasOwnProperty('role') ? overrides.role! : ConversationRoleTypeEnum.landlord,
    starMarked: overrides && overrides.hasOwnProperty('starMarked') ? overrides.starMarked! : false,
    status:
      overrides && overrides.hasOwnProperty('status') ? overrides.status! : ConversationStatusEnum.closed,
    statusChangedAt:
      overrides && overrides.hasOwnProperty('statusChangedAt')
        ? overrides.statusChangedAt!
        : 'quasi molestiae repellendus cupiditate possimus rerum soluta',
    statusChanger:
      overrides && overrides.hasOwnProperty('statusChanger')
        ? overrides.statusChanger!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
    unreadMessagesCount:
      overrides && overrides.hasOwnProperty('unreadMessagesCount') ? overrides.unreadMessagesCount! : 3546,
    users:
      overrides && overrides.hasOwnProperty('users')
        ? overrides.users!
        : [relationshipsToOmit.has('User') ? ({} as User) : aUser({}, relationshipsToOmit)],
    viewing:
      overrides && overrides.hasOwnProperty('viewing')
        ? overrides.viewing!
        : relationshipsToOmit.has('Viewing')
          ? ({} as Viewing)
          : aViewing({}, relationshipsToOmit),
  }
}

export const aConversationConnection = (
  overrides?: Partial<ConversationConnection>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ConversationConnection' } & ConversationConnection => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConversationConnection')
  return {
    __typename: 'ConversationConnection',
    edges:
      overrides && overrides.hasOwnProperty('edges')
        ? overrides.edges!
        : [
            relationshipsToOmit.has('ConversationEdge')
              ? ({} as ConversationEdge)
              : aConversationEdge({}, relationshipsToOmit),
          ],
    nodes:
      overrides && overrides.hasOwnProperty('nodes')
        ? overrides.nodes!
        : [
            relationshipsToOmit.has('Conversation')
              ? ({} as Conversation)
              : aConversation({}, relationshipsToOmit),
          ],
    pageInfo:
      overrides && overrides.hasOwnProperty('pageInfo')
        ? overrides.pageInfo!
        : relationshipsToOmit.has('PageInfo')
          ? ({} as PageInfo)
          : aPageInfo({}, relationshipsToOmit),
    totalCount: overrides && overrides.hasOwnProperty('totalCount') ? overrides.totalCount! : 4063,
  }
}

export const aConversationEdge = (
  overrides?: Partial<ConversationEdge>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ConversationEdge' } & ConversationEdge => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConversationEdge')
  return {
    __typename: 'ConversationEdge',
    cursor: overrides && overrides.hasOwnProperty('cursor') ? overrides.cursor! : 'nemo',
    node:
      overrides && overrides.hasOwnProperty('node')
        ? overrides.node!
        : relationshipsToOmit.has('Conversation')
          ? ({} as Conversation)
          : aConversation({}, relationshipsToOmit),
  }
}

export const aConversationMessage = (
  overrides?: Partial<ConversationMessage>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ConversationMessage' } & ConversationMessage => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConversationMessage')
  return {
    __typename: 'ConversationMessage',
    conversation:
      overrides && overrides.hasOwnProperty('conversation')
        ? overrides.conversation!
        : relationshipsToOmit.has('Conversation')
          ? ({} as Conversation)
          : aConversation({}, relationshipsToOmit),
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'voluptatem aut optio ut id sit nemo',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '2ba80743-add3-4db2-969e-e5550debc8c9',
    message: overrides && overrides.hasOwnProperty('message') ? overrides.message! : 'veniam',
    messageType:
      overrides && overrides.hasOwnProperty('messageType')
        ? overrides.messageType!
        : ConversationMessageTypeEnum.booking_accepted,
    reference:
      overrides && overrides.hasOwnProperty('reference')
        ? overrides.reference!
        : relationshipsToOmit.has('BookingOffer')
          ? ({} as BookingOffer)
          : aBookingOffer({}, relationshipsToOmit),
    updatedAt:
      overrides && overrides.hasOwnProperty('updatedAt')
        ? overrides.updatedAt!
        : 'ut dolorum sapiente et dolorem quas ullam',
    upload:
      overrides && overrides.hasOwnProperty('upload')
        ? overrides.upload!
        : relationshipsToOmit.has('Upload')
          ? ({} as Upload)
          : anUpload({}, relationshipsToOmit),
    user:
      overrides && overrides.hasOwnProperty('user')
        ? overrides.user!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
  }
}

export const aConversationMessageConnection = (
  overrides?: Partial<ConversationMessageConnection>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ConversationMessageConnection' } & ConversationMessageConnection => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConversationMessageConnection')
  return {
    __typename: 'ConversationMessageConnection',
    edges:
      overrides && overrides.hasOwnProperty('edges')
        ? overrides.edges!
        : [
            relationshipsToOmit.has('ConversationMessageEdge')
              ? ({} as ConversationMessageEdge)
              : aConversationMessageEdge({}, relationshipsToOmit),
          ],
    nodes:
      overrides && overrides.hasOwnProperty('nodes')
        ? overrides.nodes!
        : [
            relationshipsToOmit.has('ConversationMessage')
              ? ({} as ConversationMessage)
              : aConversationMessage({}, relationshipsToOmit),
          ],
    pageInfo:
      overrides && overrides.hasOwnProperty('pageInfo')
        ? overrides.pageInfo!
        : relationshipsToOmit.has('PageInfo')
          ? ({} as PageInfo)
          : aPageInfo({}, relationshipsToOmit),
  }
}

export const aConversationMessageEdge = (
  overrides?: Partial<ConversationMessageEdge>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ConversationMessageEdge' } & ConversationMessageEdge => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConversationMessageEdge')
  return {
    __typename: 'ConversationMessageEdge',
    cursor: overrides && overrides.hasOwnProperty('cursor') ? overrides.cursor! : 'minima',
    node:
      overrides && overrides.hasOwnProperty('node')
        ? overrides.node!
        : relationshipsToOmit.has('ConversationMessage')
          ? ({} as ConversationMessage)
          : aConversationMessage({}, relationshipsToOmit),
  }
}

export const aConversationUserRelation = (
  overrides?: Partial<ConversationUserRelation>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ConversationUserRelation' } & ConversationUserRelation => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ConversationUserRelation')
  return {
    __typename: 'ConversationUserRelation',
    archivedAt:
      overrides && overrides.hasOwnProperty('archivedAt')
        ? overrides.archivedAt!
        : 'aut officiis quisquam ut nobis a minima',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'f129e469-c5be-46fb-b7d9-adb916ec60e2',
    lastSeenMessageId:
      overrides && overrides.hasOwnProperty('lastSeenMessageId') ? overrides.lastSeenMessageId! : 6393,
    read: overrides && overrides.hasOwnProperty('read') ? overrides.read! : true,
    role: overrides && overrides.hasOwnProperty('role') ? overrides.role! : ConversationRoleTypeEnum.landlord,
    starMarked: overrides && overrides.hasOwnProperty('starMarked') ? overrides.starMarked! : false,
    user:
      overrides && overrides.hasOwnProperty('user')
        ? overrides.user!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
  }
}

export const aCoordinatesStatistics = (
  overrides?: Partial<CoordinatesStatistics>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CoordinatesStatistics' } & CoordinatesStatistics => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CoordinatesStatistics')
  return {
    __typename: 'CoordinatesStatistics',
    interestedInLocation:
      overrides && overrides.hasOwnProperty('interestedInLocation') ? overrides.interestedInLocation! : 4448,
    latitude: overrides && overrides.hasOwnProperty('latitude') ? overrides.latitude! : 4.06,
    longitude: overrides && overrides.hasOwnProperty('longitude') ? overrides.longitude! : 2.68,
    recentlyPublishedHomes:
      overrides && overrides.hasOwnProperty('recentlyPublishedHomes')
        ? overrides.recentlyPublishedHomes!
        : 1397,
    savedSearchUsers:
      overrides && overrides.hasOwnProperty('savedSearchUsers') ? overrides.savedSearchUsers! : 9211,
    tenantAdsUsers:
      overrides && overrides.hasOwnProperty('tenantAdsUsers') ? overrides.tenantAdsUsers! : 9596,
  }
}

export const aCost = (
  overrides?: Partial<Cost>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Cost' } & Cost => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Cost')
  return {
    __typename: 'Cost',
    factor: overrides && overrides.hasOwnProperty('factor') ? overrides.factor! : 4.6,
    fixed: overrides && overrides.hasOwnProperty('fixed') ? overrides.fixed! : 5306,
    isFree: overrides && overrides.hasOwnProperty('isFree') ? overrides.isFree! : false,
    total: overrides && overrides.hasOwnProperty('total') ? overrides.total! : 6648,
  }
}

export const aCreateAdminNoteInput = (
  overrides?: Partial<CreateAdminNoteInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): CreateAdminNoteInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateAdminNoteInput')
  return {
    note: overrides && overrides.hasOwnProperty('note') ? overrides.note! : 'rerum',
    recordId:
      overrides && overrides.hasOwnProperty('recordId')
        ? overrides.recordId!
        : '8175efd7-f69b-4c6f-b169-873efe278260',
    recordType:
      overrides && overrides.hasOwnProperty('recordType')
        ? overrides.recordType!
        : AdminNoteRecordTypeEnum.Contract,
    uid:
      overrides && overrides.hasOwnProperty('uid') ? overrides.uid! : '92f421ea-afa2-4cc0-8270-cbda4d2011f3',
  }
}

export const aCreateAdminNotePayload = (
  overrides?: Partial<CreateAdminNotePayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CreateAdminNotePayload' } & CreateAdminNotePayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateAdminNotePayload')
  return {
    __typename: 'CreateAdminNotePayload',
    adminNote:
      overrides && overrides.hasOwnProperty('adminNote')
        ? overrides.adminNote!
        : relationshipsToOmit.has('AdminNote')
          ? ({} as AdminNote)
          : anAdminNote({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const aCreateApplicationBoardListPayload = (
  overrides?: Partial<CreateApplicationBoardListPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CreateApplicationBoardListPayload' } & CreateApplicationBoardListPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateApplicationBoardListPayload')
  return {
    __typename: 'CreateApplicationBoardListPayload',
    applicationBoardList:
      overrides && overrides.hasOwnProperty('applicationBoardList')
        ? overrides.applicationBoardList!
        : relationshipsToOmit.has('ApplicationBoardList')
          ? ({} as ApplicationBoardList)
          : anApplicationBoardList({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const aCreateBlobInput = (
  overrides?: Partial<CreateBlobInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): CreateBlobInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateBlobInput')
  return {
    byteSize: overrides && overrides.hasOwnProperty('byteSize') ? overrides.byteSize! : 6342,
    checksum: overrides && overrides.hasOwnProperty('checksum') ? overrides.checksum! : 'sint',
    contentType: overrides && overrides.hasOwnProperty('contentType') ? overrides.contentType! : 'modi',
    filename: overrides && overrides.hasOwnProperty('filename') ? overrides.filename! : 'inventore',
    key: overrides && overrides.hasOwnProperty('key') ? overrides.key! : 'eaque',
    metadata: overrides && overrides.hasOwnProperty('metadata') ? overrides.metadata! : {},
    serviceName:
      overrides && overrides.hasOwnProperty('serviceName')
        ? overrides.serviceName!
        : ActiveStorageServiceEnum.default,
  }
}

export const aCreateBlobPayload = (
  overrides?: Partial<CreateBlobPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CreateBlobPayload' } & CreateBlobPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateBlobPayload')
  return {
    __typename: 'CreateBlobPayload',
    headers: overrides && overrides.hasOwnProperty('headers') ? overrides.headers! : {},
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '5e861449-efc2-4795-a5c1-17d2b70f4209',
    signedUrl: overrides && overrides.hasOwnProperty('signedUrl') ? overrides.signedUrl! : 'animi',
  }
}

export const aCreateBookingOfferFromBookingPayload = (
  overrides?: Partial<CreateBookingOfferFromBookingPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CreateBookingOfferFromBookingPayload' } & CreateBookingOfferFromBookingPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateBookingOfferFromBookingPayload')
  return {
    __typename: 'CreateBookingOfferFromBookingPayload',
    bookingOffer:
      overrides && overrides.hasOwnProperty('bookingOffer')
        ? overrides.bookingOffer!
        : relationshipsToOmit.has('BookingOffer')
          ? ({} as BookingOffer)
          : aBookingOffer({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const aCreateBookingOfferInput = (
  overrides?: Partial<CreateBookingOfferInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): CreateBookingOfferInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateBookingOfferInput')
  return {
    bookingId:
      overrides && overrides.hasOwnProperty('bookingId')
        ? overrides.bookingId!
        : '02539cdf-2d4b-4325-9c80-325956cc99fe',
    checkIn:
      overrides && overrides.hasOwnProperty('checkIn')
        ? overrides.checkIn!
        : 'nihil repudiandae cupiditate fugiat assumenda odio et',
    checkOut:
      overrides && overrides.hasOwnProperty('checkOut')
        ? overrides.checkOut!
        : 'et fuga maxime id et et doloremque',
    rentPrice: overrides && overrides.hasOwnProperty('rentPrice') ? overrides.rentPrice! : 1170,
  }
}

export const aCreateBookingOfferPayload = (
  overrides?: Partial<CreateBookingOfferPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CreateBookingOfferPayload' } & CreateBookingOfferPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateBookingOfferPayload')
  return {
    __typename: 'CreateBookingOfferPayload',
    bookingOffer:
      overrides && overrides.hasOwnProperty('bookingOffer')
        ? overrides.bookingOffer!
        : relationshipsToOmit.has('BookingOffer')
          ? ({} as BookingOffer)
          : aBookingOffer({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const aCreateCertificatePayload = (
  overrides?: Partial<CreateCertificatePayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CreateCertificatePayload' } & CreateCertificatePayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateCertificatePayload')
  return {
    __typename: 'CreateCertificatePayload',
    certificate:
      overrides && overrides.hasOwnProperty('certificate')
        ? overrides.certificate!
        : relationshipsToOmit.has('Certificate')
          ? ({} as Certificate)
          : aCertificate({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const aCreateClaimPayload = (
  overrides?: Partial<CreateClaimPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CreateClaimPayload' } & CreateClaimPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateClaimPayload')
  return {
    __typename: 'CreateClaimPayload',
    claim:
      overrides && overrides.hasOwnProperty('claim')
        ? overrides.claim!
        : relationshipsToOmit.has('ConnectHubHomeQCurrentClaimType')
          ? ({} as ConnectHubHomeQCurrentClaimType)
          : aConnectHubHomeQCurrentClaimType({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const aCreateCompensationClaimPayload = (
  overrides?: Partial<CreateCompensationClaimPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CreateCompensationClaimPayload' } & CreateCompensationClaimPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateCompensationClaimPayload')
  return {
    __typename: 'CreateCompensationClaimPayload',
    compensationClaim:
      overrides && overrides.hasOwnProperty('compensationClaim')
        ? overrides.compensationClaim!
        : relationshipsToOmit.has('CompensationClaim')
          ? ({} as CompensationClaim)
          : aCompensationClaim({}, relationshipsToOmit),
  }
}

export const aCreateCompensationReservationsPayload = (
  overrides?: Partial<CreateCompensationReservationsPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CreateCompensationReservationsPayload' } & CreateCompensationReservationsPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateCompensationReservationsPayload')
  return {
    __typename: 'CreateCompensationReservationsPayload',
    compensationClaim:
      overrides && overrides.hasOwnProperty('compensationClaim')
        ? overrides.compensationClaim!
        : relationshipsToOmit.has('CompensationClaim')
          ? ({} as CompensationClaim)
          : aCompensationClaim({}, relationshipsToOmit),
  }
}

export const aCreateConversationMessageInput = (
  overrides?: Partial<CreateConversationMessageInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): CreateConversationMessageInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateConversationMessageInput')
  return {
    conversationId:
      overrides && overrides.hasOwnProperty('conversationId')
        ? overrides.conversationId!
        : '6131bea8-74e8-467c-9ce9-6e1081da1cb3',
    message: overrides && overrides.hasOwnProperty('message') ? overrides.message! : 'iusto',
    messageType:
      overrides && overrides.hasOwnProperty('messageType')
        ? overrides.messageType!
        : ConversationMessageTypeInputEnum.image,
    uploadId:
      overrides && overrides.hasOwnProperty('uploadId')
        ? overrides.uploadId!
        : '588c8d90-1611-432c-862c-ed1dfc7552dd',
  }
}

export const aCreateConversationMessagePayload = (
  overrides?: Partial<CreateConversationMessagePayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CreateConversationMessagePayload' } & CreateConversationMessagePayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateConversationMessagePayload')
  return {
    __typename: 'CreateConversationMessagePayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    message:
      overrides && overrides.hasOwnProperty('message')
        ? overrides.message!
        : relationshipsToOmit.has('ConversationMessage')
          ? ({} as ConversationMessage)
          : aConversationMessage({}, relationshipsToOmit),
  }
}

export const aCreateCreditReportPayload = (
  overrides?: Partial<CreateCreditReportPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CreateCreditReportPayload' } & CreateCreditReportPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateCreditReportPayload')
  return {
    __typename: 'CreateCreditReportPayload',
    creditReport:
      overrides && overrides.hasOwnProperty('creditReport')
        ? overrides.creditReport!
        : relationshipsToOmit.has('CreditReport')
          ? ({} as CreditReport)
          : aCreditReport({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const aCreateDurationInput = (
  overrides?: Partial<CreateDurationInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): CreateDurationInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateDurationInput')
  return {
    durationPreference:
      overrides && overrides.hasOwnProperty('durationPreference')
        ? overrides.durationPreference!
        : relationshipsToOmit.has('CreateDurationPreferenceInput')
          ? ({} as CreateDurationPreferenceInput)
          : aCreateDurationPreferenceInput({}, relationshipsToOmit),
    endOptimal:
      overrides && overrides.hasOwnProperty('endOptimal')
        ? overrides.endOptimal!
        : 'molestias cum vel aut architecto odit minima',
    endUfn: overrides && overrides.hasOwnProperty('endUfn') ? overrides.endUfn! : true,
    possibilityOfExtension:
      overrides && overrides.hasOwnProperty('possibilityOfExtension')
        ? overrides.possibilityOfExtension!
        : true,
    startAsap: overrides && overrides.hasOwnProperty('startAsap') ? overrides.startAsap! : true,
    startOptimal:
      overrides && overrides.hasOwnProperty('startOptimal')
        ? overrides.startOptimal!
        : 'commodi itaque rerum vel molestiae animi qui',
  }
}

export const aCreateDurationPreferenceInput = (
  overrides?: Partial<CreateDurationPreferenceInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): CreateDurationPreferenceInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateDurationPreferenceInput')
  return {
    endEarliest:
      overrides && overrides.hasOwnProperty('endEarliest')
        ? overrides.endEarliest!
        : 'perspiciatis et distinctio aut qui eos voluptatibus',
    longestDurationDays:
      overrides && overrides.hasOwnProperty('longestDurationDays') ? overrides.longestDurationDays! : 4764,
    shortestDurationDays:
      overrides && overrides.hasOwnProperty('shortestDurationDays') ? overrides.shortestDurationDays! : 3848,
    startLatest:
      overrides && overrides.hasOwnProperty('startLatest')
        ? overrides.startLatest!
        : 'magnam ut dolor perferendis architecto in voluptas',
  }
}

export const aCreateExternalContractTakeoverInput = (
  overrides?: Partial<CreateExternalContractTakeoverInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): CreateExternalContractTakeoverInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateExternalContractTakeoverInput')
  return {
    contract:
      overrides && overrides.hasOwnProperty('contract')
        ? overrides.contract!
        : relationshipsToOmit.has('ContractInput')
          ? ({} as ContractInput)
          : aContractInput({}, relationshipsToOmit),
    duration:
      overrides && overrides.hasOwnProperty('duration')
        ? overrides.duration!
        : relationshipsToOmit.has('FranceExternalContractTakeoverDurationInput')
          ? ({} as FranceExternalContractTakeoverDurationInput)
          : aFranceExternalContractTakeoverDurationInput({}, relationshipsToOmit),
    landlordUid:
      overrides && overrides.hasOwnProperty('landlordUid')
        ? overrides.landlordUid!
        : '3862b31c-ff5a-4d0b-8c9d-aba8ba273409',
    location:
      overrides && overrides.hasOwnProperty('location')
        ? overrides.location!
        : relationshipsToOmit.has('LocationInput')
          ? ({} as LocationInput)
          : aLocationInput({}, relationshipsToOmit),
    milaId: overrides && overrides.hasOwnProperty('milaId') ? overrides.milaId! : 'excepturi',
    tenant:
      overrides && overrides.hasOwnProperty('tenant')
        ? overrides.tenant!
        : relationshipsToOmit.has('TenantInput')
          ? ({} as TenantInput)
          : aTenantInput({}, relationshipsToOmit),
  }
}

export const aCreateHomeFromTemplatePayload = (
  overrides?: Partial<CreateHomeFromTemplatePayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CreateHomeFromTemplatePayload' } & CreateHomeFromTemplatePayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateHomeFromTemplatePayload')
  return {
    __typename: 'CreateHomeFromTemplatePayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    home:
      overrides && overrides.hasOwnProperty('home')
        ? overrides.home!
        : relationshipsToOmit.has('Home')
          ? ({} as Home)
          : aHome({}, relationshipsToOmit),
  }
}

export const aCreateHomeInput = (
  overrides?: Partial<CreateHomeInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): CreateHomeInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateHomeInput')
  return {
    apartmentNumber:
      overrides && overrides.hasOwnProperty('apartmentNumber') ? overrides.apartmentNumber! : 'provident',
    applicationDeadline:
      overrides && overrides.hasOwnProperty('applicationDeadline')
        ? overrides.applicationDeadline!
        : 'et eum officia ullam aspernatur id sint',
    bathroomRenovationYear:
      overrides && overrides.hasOwnProperty('bathroomRenovationYear')
        ? overrides.bathroomRenovationYear!
        : 8430,
    bedCount: overrides && overrides.hasOwnProperty('bedCount') ? overrides.bedCount! : 3833,
    bedroomCount: overrides && overrides.hasOwnProperty('bedroomCount') ? overrides.bedroomCount! : 4095,
    buildYear: overrides && overrides.hasOwnProperty('buildYear') ? overrides.buildYear! : 3082,
    buildingFloors:
      overrides && overrides.hasOwnProperty('buildingFloors') ? overrides.buildingFloors! : 9392,
    cancellationPolicyName:
      overrides && overrides.hasOwnProperty('cancellationPolicyName')
        ? overrides.cancellationPolicyName!
        : CancellationPolicyNameEnum.flexible,
    corporateHome: overrides && overrides.hasOwnProperty('corporateHome') ? overrides.corporateHome! : true,
    description: overrides && overrides.hasOwnProperty('description') ? overrides.description! : 'sit',
    descriptionBuilding:
      overrides && overrides.hasOwnProperty('descriptionBuilding')
        ? overrides.descriptionBuilding!
        : 'corporis',
    descriptionContract:
      overrides && overrides.hasOwnProperty('descriptionContract')
        ? overrides.descriptionContract!
        : 'itaque',
    descriptionTransportation:
      overrides && overrides.hasOwnProperty('descriptionTransportation')
        ? overrides.descriptionTransportation!
        : 'sint',
    duration:
      overrides && overrides.hasOwnProperty('duration')
        ? overrides.duration!
        : relationshipsToOmit.has('CreateDurationInput')
          ? ({} as CreateDurationInput)
          : aCreateDurationInput({}, relationshipsToOmit),
    electricityFee:
      overrides && overrides.hasOwnProperty('electricityFee')
        ? overrides.electricityFee!
        : relationshipsToOmit.has('ElectricityFeeInput')
          ? ({} as ElectricityFeeInput)
          : anElectricityFeeInput({}, relationshipsToOmit),
    energyClass: overrides && overrides.hasOwnProperty('energyClass') ? overrides.energyClass! : 'ea',
    firsthand: overrides && overrides.hasOwnProperty('firsthand') ? overrides.firsthand! : false,
    floor: overrides && overrides.hasOwnProperty('floor') ? overrides.floor! : 4776,
    hasKitchen: overrides && overrides.hasOwnProperty('hasKitchen') ? overrides.hasKitchen! : true,
    homeTemplateIds:
      overrides && overrides.hasOwnProperty('homeTemplateIds')
        ? overrides.homeTemplateIds!
        : ['0c83b065-0178-4c9f-b354-af67a9950f4b'],
    houseRules: overrides && overrides.hasOwnProperty('houseRules') ? overrides.houseRules! : 'cumque',
    housingAssociation:
      overrides && overrides.hasOwnProperty('housingAssociation') ? overrides.housingAssociation! : 'vel',
    instantBooking:
      overrides && overrides.hasOwnProperty('instantBooking') ? overrides.instantBooking! : true,
    insurance:
      overrides && overrides.hasOwnProperty('insurance')
        ? overrides.insurance!
        : HomeInsuranceEnum.insurance_landlord,
    kitchenRenovationYear:
      overrides && overrides.hasOwnProperty('kitchenRenovationYear')
        ? overrides.kitchenRenovationYear!
        : 9022,
    location:
      overrides && overrides.hasOwnProperty('location')
        ? overrides.location!
        : relationshipsToOmit.has('CreateLocationInput')
          ? ({} as CreateLocationInput)
          : aCreateLocationInput({}, relationshipsToOmit),
    matterportEmbedUrl:
      overrides && overrides.hasOwnProperty('matterportEmbedUrl') ? overrides.matterportEmbedUrl! : 'rerum',
    maxRent: overrides && overrides.hasOwnProperty('maxRent') ? overrides.maxRent! : 6747,
    maxRoomCount: overrides && overrides.hasOwnProperty('maxRoomCount') ? overrides.maxRoomCount! : 4.6,
    maxSquareMeters:
      overrides && overrides.hasOwnProperty('maxSquareMeters') ? overrides.maxSquareMeters! : 9152,
    maxTenantCount:
      overrides && overrides.hasOwnProperty('maxTenantCount') ? overrides.maxTenantCount! : 3981,
    minRent: overrides && overrides.hasOwnProperty('minRent') ? overrides.minRent! : 2161,
    minRoomCount: overrides && overrides.hasOwnProperty('minRoomCount') ? overrides.minRoomCount! : 8.85,
    minSquareMeters:
      overrides && overrides.hasOwnProperty('minSquareMeters') ? overrides.minSquareMeters! : 8323,
    minTenantCount:
      overrides && overrides.hasOwnProperty('minTenantCount') ? overrides.minTenantCount! : 1649,
    numberOfHomes: overrides && overrides.hasOwnProperty('numberOfHomes') ? overrides.numberOfHomes! : 5110,
    organizationUid:
      overrides && overrides.hasOwnProperty('organizationUid')
        ? overrides.organizationUid!
        : '9b53bf30-b182-4194-baa2-11d2e96065c1',
    origin: overrides && overrides.hasOwnProperty('origin') ? overrides.origin! : 'nisi',
    ownHome: overrides && overrides.hasOwnProperty('ownHome') ? overrides.ownHome! : true,
    qasaGuarantee: overrides && overrides.hasOwnProperty('qasaGuarantee') ? overrides.qasaGuarantee! : false,
    rent: overrides && overrides.hasOwnProperty('rent') ? overrides.rent! : 8166,
    rentalType:
      overrides && overrides.hasOwnProperty('rentalType')
        ? overrides.rentalType!
        : HomeRentalTypeEnum.long_term,
    roomCount: overrides && overrides.hasOwnProperty('roomCount') ? overrides.roomCount! : 2.74,
    seniorHome: overrides && overrides.hasOwnProperty('seniorHome') ? overrides.seniorHome! : true,
    shared: overrides && overrides.hasOwnProperty('shared') ? overrides.shared! : true,
    squareMeters: overrides && overrides.hasOwnProperty('squareMeters') ? overrides.squareMeters! : 2282,
    studentHome: overrides && overrides.hasOwnProperty('studentHome') ? overrides.studentHome! : false,
    tenantCount: overrides && overrides.hasOwnProperty('tenantCount') ? overrides.tenantCount! : 3355,
    tenureType:
      overrides && overrides.hasOwnProperty('tenureType')
        ? overrides.tenureType!
        : TenureTypeEnum.condominium,
    title: overrides && overrides.hasOwnProperty('title') ? overrides.title! : 'dolores',
    toiletCount: overrides && overrides.hasOwnProperty('toiletCount') ? overrides.toiletCount! : 1178,
    traits: overrides && overrides.hasOwnProperty('traits') ? overrides.traits! : [TraitTypeEnum.archipelago],
    translatedDescriptions:
      overrides && overrides.hasOwnProperty('translatedDescriptions')
        ? overrides.translatedDescriptions!
        : [
            relationshipsToOmit.has('TranslatedDescriptionInput')
              ? ({} as TranslatedDescriptionInput)
              : aTranslatedDescriptionInput({}, relationshipsToOmit),
          ],
    type: overrides && overrides.hasOwnProperty('type') ? overrides.type! : HomeTypeEnum.apartment,
    uploadIds:
      overrides && overrides.hasOwnProperty('uploadIds')
        ? overrides.uploadIds!
        : ['2b634ff8-8be8-471b-bcf7-590ef732e78c'],
    waterFeePerTenant:
      overrides && overrides.hasOwnProperty('waterFeePerTenant') ? overrides.waterFeePerTenant! : 9993,
  }
}

export const aCreateHomePayload = (
  overrides?: Partial<CreateHomePayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CreateHomePayload' } & CreateHomePayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateHomePayload')
  return {
    __typename: 'CreateHomePayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    home:
      overrides && overrides.hasOwnProperty('home')
        ? overrides.home!
        : relationshipsToOmit.has('Home')
          ? ({} as Home)
          : aHome({}, relationshipsToOmit),
  }
}

export const aCreateInstantSignContractInput = (
  overrides?: Partial<CreateInstantSignContractInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): CreateInstantSignContractInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateInstantSignContractInput')
  return {
    durationAttributes:
      overrides && overrides.hasOwnProperty('durationAttributes')
        ? overrides.durationAttributes!
        : relationshipsToOmit.has('InstantSignDurationInput')
          ? ({} as InstantSignDurationInput)
          : anInstantSignDurationInput({}, relationshipsToOmit),
    homeId:
      overrides && overrides.hasOwnProperty('homeId')
        ? overrides.homeId!
        : '9d35a802-1cbc-4171-a877-f93b1a6696a0',
  }
}

export const aCreateInstantSignContractPayload = (
  overrides?: Partial<CreateInstantSignContractPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CreateInstantSignContractPayload' } & CreateInstantSignContractPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateInstantSignContractPayload')
  return {
    __typename: 'CreateInstantSignContractPayload',
    contract:
      overrides && overrides.hasOwnProperty('contract')
        ? overrides.contract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const aCreateLandlordHomeApplicationInput = (
  overrides?: Partial<CreateLandlordHomeApplicationInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): CreateLandlordHomeApplicationInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateLandlordHomeApplicationInput')
  return {
    homeId:
      overrides && overrides.hasOwnProperty('homeId')
        ? overrides.homeId!
        : '04a82c3e-16de-4cb4-a3c0-be8d08f9c3e0',
    matchInterest:
      overrides && overrides.hasOwnProperty('matchInterest') ? overrides.matchInterest! : 'dolores',
    message: overrides && overrides.hasOwnProperty('message') ? overrides.message! : 'quos',
    tenantUid:
      overrides && overrides.hasOwnProperty('tenantUid')
        ? overrides.tenantUid!
        : '88ff9194-e028-4c4d-ab22-0ad5a55e1b90',
  }
}

export const aCreateLandlordHomeApplicationPayload = (
  overrides?: Partial<CreateLandlordHomeApplicationPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CreateLandlordHomeApplicationPayload' } & CreateLandlordHomeApplicationPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateLandlordHomeApplicationPayload')
  return {
    __typename: 'CreateLandlordHomeApplicationPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    homeApplication:
      overrides && overrides.hasOwnProperty('homeApplication')
        ? overrides.homeApplication!
        : relationshipsToOmit.has('HomeApplication')
          ? ({} as HomeApplication)
          : aHomeApplication({}, relationshipsToOmit),
  }
}

export const aCreateListingPlatformSelectorEventPayload = (
  overrides?: Partial<CreateListingPlatformSelectorEventPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): {
  __typename: 'CreateListingPlatformSelectorEventPayload'
} & CreateListingPlatformSelectorEventPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateListingPlatformSelectorEventPayload')
  return {
    __typename: 'CreateListingPlatformSelectorEventPayload',
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : true,
  }
}

export const aCreateLocationInput = (
  overrides?: Partial<CreateLocationInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): CreateLocationInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateLocationInput')
  return {
    country: overrides && overrides.hasOwnProperty('country') ? overrides.country! : 'nobis',
    countryCode: overrides && overrides.hasOwnProperty('countryCode') ? overrides.countryCode! : 'incidunt',
    formattedAddress:
      overrides && overrides.hasOwnProperty('formattedAddress') ? overrides.formattedAddress! : 'consectetur',
    googleMapsPlaceId:
      overrides && overrides.hasOwnProperty('googleMapsPlaceId') ? overrides.googleMapsPlaceId! : 'omnis',
    granularity:
      overrides && overrides.hasOwnProperty('granularity')
        ? overrides.granularity!
        : LocationGranularityEnum.city,
    latitude: overrides && overrides.hasOwnProperty('latitude') ? overrides.latitude! : 1.44,
    locality: overrides && overrides.hasOwnProperty('locality') ? overrides.locality! : 'perspiciatis',
    longitude: overrides && overrides.hasOwnProperty('longitude') ? overrides.longitude! : 0.55,
    placeId: overrides && overrides.hasOwnProperty('placeId') ? overrides.placeId! : 'quis',
    postalCode: overrides && overrides.hasOwnProperty('postalCode') ? overrides.postalCode! : 'occaecati',
    route: overrides && overrides.hasOwnProperty('route') ? overrides.route! : 'deserunt',
    shortName: overrides && overrides.hasOwnProperty('shortName') ? overrides.shortName! : 'ea',
    streetNumber: overrides && overrides.hasOwnProperty('streetNumber') ? overrides.streetNumber! : 'in',
    sublocality: overrides && overrides.hasOwnProperty('sublocality') ? overrides.sublocality! : 'architecto',
  }
}

export const aCreateMatchingScoreFeedbackPayload = (
  overrides?: Partial<CreateMatchingScoreFeedbackPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CreateMatchingScoreFeedbackPayload' } & CreateMatchingScoreFeedbackPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateMatchingScoreFeedbackPayload')
  return {
    __typename: 'CreateMatchingScoreFeedbackPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : true,
  }
}

export const aCreateOfferingInput = (
  overrides?: Partial<CreateOfferingInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): CreateOfferingInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateOfferingInput')
  return {
    homeApplicationId:
      overrides && overrides.hasOwnProperty('homeApplicationId')
        ? overrides.homeApplicationId!
        : '78360f65-f0bc-4202-8518-c953672a657b',
    homeTemplateId:
      overrides && overrides.hasOwnProperty('homeTemplateId')
        ? overrides.homeTemplateId!
        : '19627a19-df2f-4711-a0a3-a725ba5c42d3',
    message: overrides && overrides.hasOwnProperty('message') ? overrides.message! : 'illo',
    showingAt:
      overrides && overrides.hasOwnProperty('showingAt')
        ? overrides.showingAt!
        : 'impedit est quam voluptatem pariatur est qui',
    showingContactInformation:
      overrides && overrides.hasOwnProperty('showingContactInformation')
        ? overrides.showingContactInformation!
        : 'eos',
    showingStatus:
      overrides && overrides.hasOwnProperty('showingStatus')
        ? overrides.showingStatus!
        : ShowingStatusEnum.current_tenant_has_showing,
  }
}

export const aCreateOfferingPayload = (
  overrides?: Partial<CreateOfferingPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CreateOfferingPayload' } & CreateOfferingPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateOfferingPayload')
  return {
    __typename: 'CreateOfferingPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    offering:
      overrides && overrides.hasOwnProperty('offering')
        ? overrides.offering!
        : relationshipsToOmit.has('Offering')
          ? ({} as Offering)
          : anOffering({}, relationshipsToOmit),
  }
}

export const aCreateOnfidoVerificationLinkPayload = (
  overrides?: Partial<CreateOnfidoVerificationLinkPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CreateOnfidoVerificationLinkPayload' } & CreateOnfidoVerificationLinkPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateOnfidoVerificationLinkPayload')
  return {
    __typename: 'CreateOnfidoVerificationLinkPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    url: overrides && overrides.hasOwnProperty('url') ? overrides.url! : 'explicabo',
  }
}

export const aCreateSavedSearchInput = (
  overrides?: Partial<CreateSavedSearchInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): CreateSavedSearchInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateSavedSearchInput')
  return {
    monitoring: overrides && overrides.hasOwnProperty('monitoring') ? overrides.monitoring! : false,
    rentalType:
      overrides && overrides.hasOwnProperty('rentalType')
        ? overrides.rentalType!
        : HomeRentalTypeEnum.long_term,
    searchParams:
      overrides && overrides.hasOwnProperty('searchParams')
        ? overrides.searchParams!
        : relationshipsToOmit.has('HomeSearchParamsInput')
          ? ({} as HomeSearchParamsInput)
          : aHomeSearchParamsInput({}, relationshipsToOmit),
    title: overrides && overrides.hasOwnProperty('title') ? overrides.title! : 'ab',
    visibility:
      overrides && overrides.hasOwnProperty('visibility')
        ? overrides.visibility!
        : SavedSearchVisibilityEnum.private,
  }
}

export const aCreateSavedSearchPayload = (
  overrides?: Partial<CreateSavedSearchPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CreateSavedSearchPayload' } & CreateSavedSearchPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateSavedSearchPayload')
  return {
    __typename: 'CreateSavedSearchPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    savedSearch:
      overrides && overrides.hasOwnProperty('savedSearch')
        ? overrides.savedSearch!
        : relationshipsToOmit.has('SavedSearch')
          ? ({} as SavedSearch)
          : aSavedSearch({}, relationshipsToOmit),
  }
}

export const aCreateSavingsVerificationPayload = (
  overrides?: Partial<CreateSavingsVerificationPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CreateSavingsVerificationPayload' } & CreateSavingsVerificationPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateSavingsVerificationPayload')
  return {
    __typename: 'CreateSavingsVerificationPayload',
    savingsVerification:
      overrides && overrides.hasOwnProperty('savingsVerification')
        ? overrides.savingsVerification!
        : relationshipsToOmit.has('SavingsVerification')
          ? ({} as SavingsVerification)
          : aSavingsVerification({}, relationshipsToOmit),
  }
}

export const aCreateSubUserPayload = (
  overrides?: Partial<CreateSubUserPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CreateSubUserPayload' } & CreateSubUserPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateSubUserPayload')
  return {
    __typename: 'CreateSubUserPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : true,
  }
}

export const aCreateTenantHomeApplicationInput = (
  overrides?: Partial<CreateTenantHomeApplicationInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): CreateTenantHomeApplicationInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateTenantHomeApplicationInput')
  return {
    counterOffer: overrides && overrides.hasOwnProperty('counterOffer') ? overrides.counterOffer! : 2942,
    endOptimal:
      overrides && overrides.hasOwnProperty('endOptimal')
        ? overrides.endOptimal!
        : 'est voluptatem dicta aliquam doloribus ut placeat',
    homeId:
      overrides && overrides.hasOwnProperty('homeId')
        ? overrides.homeId!
        : 'e70310c3-2770-4934-9fbb-ade19497ee9c',
    homeTemplateIds:
      overrides && overrides.hasOwnProperty('homeTemplateIds')
        ? overrides.homeTemplateIds!
        : ['dbe7d1f4-fb0c-4458-ba86-1e37deed1078'],
    message: overrides && overrides.hasOwnProperty('message') ? overrides.message! : 'dolorum',
    startOptimal:
      overrides && overrides.hasOwnProperty('startOptimal')
        ? overrides.startOptimal!
        : 'fugit voluptate non sint deleniti voluptate ea',
  }
}

export const aCreateTenantHomeApplicationPayload = (
  overrides?: Partial<CreateTenantHomeApplicationPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CreateTenantHomeApplicationPayload' } & CreateTenantHomeApplicationPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateTenantHomeApplicationPayload')
  return {
    __typename: 'CreateTenantHomeApplicationPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    homeApplication:
      overrides && overrides.hasOwnProperty('homeApplication')
        ? overrides.homeApplication!
        : relationshipsToOmit.has('HomeApplication')
          ? ({} as HomeApplication)
          : aHomeApplication({}, relationshipsToOmit),
  }
}

export const aCreateTenantInvitationInput = (
  overrides?: Partial<CreateTenantInvitationInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): CreateTenantInvitationInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateTenantInvitationInput')
  return {
    contractId:
      overrides && overrides.hasOwnProperty('contractId')
        ? overrides.contractId!
        : 'be1af67f-1eb8-4438-9b55-6259c60d12ff',
    email: overrides && overrides.hasOwnProperty('email') ? overrides.email! : 'nihil',
    tenantType:
      overrides && overrides.hasOwnProperty('tenantType') ? overrides.tenantType! : TenantTypeEnum.cosignee,
  }
}

export const aCreateTenantInvitationPayload = (
  overrides?: Partial<CreateTenantInvitationPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CreateTenantInvitationPayload' } & CreateTenantInvitationPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateTenantInvitationPayload')
  return {
    __typename: 'CreateTenantInvitationPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    tenantInvitation:
      overrides && overrides.hasOwnProperty('tenantInvitation')
        ? overrides.tenantInvitation!
        : relationshipsToOmit.has('ContractTenantInvitation')
          ? ({} as ContractTenantInvitation)
          : aContractTenantInvitation({}, relationshipsToOmit),
  }
}

export const aCreateUpload = (
  overrides?: Partial<CreateUpload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CreateUpload' } & CreateUpload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateUpload')
  return {
    __typename: 'CreateUpload',
    action: overrides && overrides.hasOwnProperty('action') ? overrides.action! : 'dignissimos',
    fields: overrides && overrides.hasOwnProperty('fields') ? overrides.fields! : {},
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'd0157d00-a2b8-46fe-8b00-bb1297d246b7',
  }
}

export const aCreateUploadInput = (
  overrides?: Partial<CreateUploadInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): CreateUploadInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateUploadInput')
  return {
    contentLength: overrides && overrides.hasOwnProperty('contentLength') ? overrides.contentLength! : 5816,
    contentType:
      overrides && overrides.hasOwnProperty('contentType') ? overrides.contentType! : 'repudiandae',
    metadata:
      overrides && overrides.hasOwnProperty('metadata')
        ? overrides.metadata!
        : relationshipsToOmit.has('UploadMetadataInput')
          ? ({} as UploadMetadataInput)
          : anUploadMetadataInput({}, relationshipsToOmit),
    ownerId: overrides && overrides.hasOwnProperty('ownerId') ? overrides.ownerId! : 'nesciunt',
    ownerType:
      overrides && overrides.hasOwnProperty('ownerType') ? overrides.ownerType! : OwnerTypeEnum.AREA_UPLOAD,
    title: overrides && overrides.hasOwnProperty('title') ? overrides.title! : 'quam',
    uploadType:
      overrides && overrides.hasOwnProperty('uploadType')
        ? overrides.uploadType!
        : UploadTypeEnum.activity_compensation_verification,
  }
}

export const aCreateUploadPayload = (
  overrides?: Partial<CreateUploadPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CreateUploadPayload' } & CreateUploadPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateUploadPayload')
  return {
    __typename: 'CreateUploadPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    upload:
      overrides && overrides.hasOwnProperty('upload')
        ? overrides.upload!
        : relationshipsToOmit.has('CreateUpload')
          ? ({} as CreateUpload)
          : aCreateUpload({}, relationshipsToOmit),
  }
}

export const aCreateUserNoteInput = (
  overrides?: Partial<CreateUserNoteInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): CreateUserNoteInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateUserNoteInput')
  return {
    note: overrides && overrides.hasOwnProperty('note') ? overrides.note! : 'quasi',
    recordId:
      overrides && overrides.hasOwnProperty('recordId')
        ? overrides.recordId!
        : 'be65d72f-fe35-44a2-90e7-c1a4ee6439ff',
    recordType:
      overrides && overrides.hasOwnProperty('recordType')
        ? overrides.recordType!
        : UserNoteRecordTypeEnum.home_application,
  }
}

export const aCreateUserNotePayload = (
  overrides?: Partial<CreateUserNotePayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CreateUserNotePayload' } & CreateUserNotePayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateUserNotePayload')
  return {
    __typename: 'CreateUserNotePayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    userNote:
      overrides && overrides.hasOwnProperty('userNote')
        ? overrides.userNote!
        : relationshipsToOmit.has('UserNote')
          ? ({} as UserNote)
          : aUserNote({}, relationshipsToOmit),
  }
}

export const aCreateViewingPayload = (
  overrides?: Partial<CreateViewingPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CreateViewingPayload' } & CreateViewingPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreateViewingPayload')
  return {
    __typename: 'CreateViewingPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    homeApplication:
      overrides && overrides.hasOwnProperty('homeApplication')
        ? overrides.homeApplication!
        : relationshipsToOmit.has('HomeApplication')
          ? ({} as HomeApplication)
          : aHomeApplication({}, relationshipsToOmit),
    viewing:
      overrides && overrides.hasOwnProperty('viewing')
        ? overrides.viewing!
        : relationshipsToOmit.has('Viewing')
          ? ({} as Viewing)
          : aViewing({}, relationshipsToOmit),
  }
}

export const aCreditReport = (
  overrides?: Partial<CreditReport>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CreditReport' } & CreditReport => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreditReport')
  return {
    __typename: 'CreditReport',
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'architecto laborum sint et est molestiae qui',
    currentAddress:
      overrides && overrides.hasOwnProperty('currentAddress')
        ? overrides.currentAddress!
        : relationshipsToOmit.has('CurrentAddressType')
          ? ({} as CurrentAddressType)
          : aCurrentAddressType({}, relationshipsToOmit),
    debtSanitization:
      overrides && overrides.hasOwnProperty('debtSanitization') ? overrides.debtSanitization! : false,
    expiresOn:
      overrides && overrides.hasOwnProperty('expiresOn')
        ? overrides.expiresOn!
        : 'iure laboriosam aliquid consequatur sed est sed',
    fetchStatus:
      overrides && overrides.hasOwnProperty('fetchStatus')
        ? overrides.fetchStatus!
        : CreditReportFetchStatusEnum.downloaded,
    guardianship: overrides && overrides.hasOwnProperty('guardianship') ? overrides.guardianship! : false,
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '3548f6c0-3807-4959-9830-31ed512bcb17',
    idNumber: overrides && overrides.hasOwnProperty('idNumber') ? overrides.idNumber! : 'nam',
    latestYearlyIncome:
      overrides && overrides.hasOwnProperty('latestYearlyIncome') ? overrides.latestYearlyIncome! : 3.19,
    moveInfo:
      overrides && overrides.hasOwnProperty('moveInfo')
        ? overrides.moveInfo!
        : relationshipsToOmit.has('MoveInfoType')
          ? ({} as MoveInfoType)
          : aMoveInfoType({}, relationshipsToOmit),
    numberPaymentRemarks:
      overrides && overrides.hasOwnProperty('numberPaymentRemarks') ? overrides.numberPaymentRemarks! : 2916,
    provider:
      overrides && overrides.hasOwnProperty('provider')
        ? overrides.provider!
        : CreditReportProviderEnum.valitive,
    seizureAttempt:
      overrides && overrides.hasOwnProperty('seizureAttempt') ? overrides.seizureAttempt! : false,
    totalDebt: overrides && overrides.hasOwnProperty('totalDebt') ? overrides.totalDebt! : 9191,
    updatedAt:
      overrides && overrides.hasOwnProperty('updatedAt')
        ? overrides.updatedAt!
        : 'non dolorem aperiam in quasi fugit tempora',
    user:
      overrides && overrides.hasOwnProperty('user')
        ? overrides.user!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
    yearlyIncomeStatements:
      overrides && overrides.hasOwnProperty('yearlyIncomeStatements')
        ? overrides.yearlyIncomeStatements!
        : [
            relationshipsToOmit.has('YearlyIncomeStatementsType')
              ? ({} as YearlyIncomeStatementsType)
              : aYearlyIncomeStatementsType({}, relationshipsToOmit),
          ],
  }
}

export const aCreditStatusSummary = (
  overrides?: Partial<CreditStatusSummary>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CreditStatusSummary' } & CreditStatusSummary => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CreditStatusSummary')
  return {
    __typename: 'CreditStatusSummary',
    canSignFinland:
      overrides && overrides.hasOwnProperty('canSignFinland') ? overrides.canSignFinland! : true,
    canSignOther: overrides && overrides.hasOwnProperty('canSignOther') ? overrides.canSignOther! : true,
    canSignSweden: overrides && overrides.hasOwnProperty('canSignSweden') ? overrides.canSignSweden! : false,
    certificate:
      overrides && overrides.hasOwnProperty('certificate')
        ? overrides.certificate!
        : relationshipsToOmit.has('Certificate')
          ? ({} as Certificate)
          : aCertificate({}, relationshipsToOmit),
  }
}

export const aCronofyElementData = (
  overrides?: Partial<CronofyElementData>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CronofyElementData' } & CronofyElementData => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CronofyElementData')
  return {
    __typename: 'CronofyElementData',
    dataCenter: overrides && overrides.hasOwnProperty('dataCenter') ? overrides.dataCenter! : 'deleniti',
    endTime:
      overrides && overrides.hasOwnProperty('endTime')
        ? overrides.endTime!
        : 'aliquid iusto et eligendi sint consequuntur quaerat',
    origin: overrides && overrides.hasOwnProperty('origin') ? overrides.origin! : 'in',
    permissions:
      overrides && overrides.hasOwnProperty('permissions') ? overrides.permissions! : ['doloremque'],
    requiredDuration:
      overrides && overrides.hasOwnProperty('requiredDuration') ? overrides.requiredDuration! : 3226,
    startTime:
      overrides && overrides.hasOwnProperty('startTime')
        ? overrides.startTime!
        : 'dolores voluptate occaecati sed laudantium alias illum',
    subs: overrides && overrides.hasOwnProperty('subs') ? overrides.subs! : ['autem'],
    token: overrides && overrides.hasOwnProperty('token') ? overrides.token! : 'molestiae',
  }
}

export const aCurrentAddressType = (
  overrides?: Partial<CurrentAddressType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'CurrentAddressType' } & CurrentAddressType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('CurrentAddressType')
  return {
    __typename: 'CurrentAddressType',
    city: overrides && overrides.hasOwnProperty('city') ? overrides.city! : 'nobis',
    number: overrides && overrides.hasOwnProperty('number') ? overrides.number! : 'quibusdam',
    postalCode: overrides && overrides.hasOwnProperty('postalCode') ? overrides.postalCode! : 'quae',
    street: overrides && overrides.hasOwnProperty('street') ? overrides.street! : 'eos',
  }
}

export const aDateRange = (
  overrides?: Partial<DateRange>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'DateRange' } & DateRange => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('DateRange')
  return {
    __typename: 'DateRange',
    from:
      overrides && overrides.hasOwnProperty('from')
        ? overrides.from!
        : 'accusantium esse eum officiis tenetur rem corporis',
    to:
      overrides && overrides.hasOwnProperty('to')
        ? overrides.to!
        : 'est sit dolores provident doloribus voluptas exercitationem',
  }
}

export const aDateRangeInput = (
  overrides?: Partial<DateRangeInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): DateRangeInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('DateRangeInput')
  return {
    from:
      overrides && overrides.hasOwnProperty('from')
        ? overrides.from!
        : 'eos reprehenderit est magni cumque molestiae et',
    to:
      overrides && overrides.hasOwnProperty('to')
        ? overrides.to!
        : 'similique vero magnam sint saepe ullam voluptatum',
  }
}

export const aDateTimeRangeInput = (
  overrides?: Partial<DateTimeRangeInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): DateTimeRangeInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('DateTimeRangeInput')
  return {
    from:
      overrides && overrides.hasOwnProperty('from')
        ? overrides.from!
        : 'nostrum velit recusandae ut enim deserunt vel',
    to:
      overrides && overrides.hasOwnProperty('to')
        ? overrides.to!
        : 'saepe corrupti labore nemo nobis quidem quis',
  }
}

export const aDeclineHomeApplicationPayload = (
  overrides?: Partial<DeclineHomeApplicationPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'DeclineHomeApplicationPayload' } & DeclineHomeApplicationPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('DeclineHomeApplicationPayload')
  return {
    __typename: 'DeclineHomeApplicationPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    homeApplication:
      overrides && overrides.hasOwnProperty('homeApplication')
        ? overrides.homeApplication!
        : relationshipsToOmit.has('HomeApplication')
          ? ({} as HomeApplication)
          : aHomeApplication({}, relationshipsToOmit),
  }
}

export const aDeclineHomeApplicationsPayload = (
  overrides?: Partial<DeclineHomeApplicationsPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'DeclineHomeApplicationsPayload' } & DeclineHomeApplicationsPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('DeclineHomeApplicationsPayload')
  return {
    __typename: 'DeclineHomeApplicationsPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : false,
  }
}

export const aDeclineOfferingPayload = (
  overrides?: Partial<DeclineOfferingPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'DeclineOfferingPayload' } & DeclineOfferingPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('DeclineOfferingPayload')
  return {
    __typename: 'DeclineOfferingPayload',
    offering:
      overrides && overrides.hasOwnProperty('offering')
        ? overrides.offering!
        : relationshipsToOmit.has('Offering')
          ? ({} as Offering)
          : anOffering({}, relationshipsToOmit),
  }
}

export const aDeclineOrRevokeBookingPayload = (
  overrides?: Partial<DeclineOrRevokeBookingPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'DeclineOrRevokeBookingPayload' } & DeclineOrRevokeBookingPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('DeclineOrRevokeBookingPayload')
  return {
    __typename: 'DeclineOrRevokeBookingPayload',
    booking:
      overrides && overrides.hasOwnProperty('booking')
        ? overrides.booking!
        : relationshipsToOmit.has('Booking')
          ? ({} as Booking)
          : aBooking({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const aDeclineShortcutProposalPayload = (
  overrides?: Partial<DeclineShortcutProposalPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'DeclineShortcutProposalPayload' } & DeclineShortcutProposalPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('DeclineShortcutProposalPayload')
  return {
    __typename: 'DeclineShortcutProposalPayload',
    homeId:
      overrides && overrides.hasOwnProperty('homeId')
        ? overrides.homeId!
        : 'df9cb505-422f-457b-a106-bd908db7f1a0',
  }
}

export const aDeleteHomePayload = (
  overrides?: Partial<DeleteHomePayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'DeleteHomePayload' } & DeleteHomePayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('DeleteHomePayload')
  return {
    __typename: 'DeleteHomePayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    homeId:
      overrides && overrides.hasOwnProperty('homeId')
        ? overrides.homeId!
        : '7eebc7ad-d9d0-48bc-b3d4-0e4e33045b63',
  }
}

export const aDeliveryTrace = (
  overrides?: Partial<DeliveryTrace>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'DeliveryTrace' } & DeliveryTrace => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('DeliveryTrace')
  return {
    __typename: 'DeliveryTrace',
    channel: overrides && overrides.hasOwnProperty('channel') ? overrides.channel! : 'dolores',
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'sint architecto labore blanditiis est delectus animi',
    metadata: overrides && overrides.hasOwnProperty('metadata') ? overrides.metadata! : {},
    subject: overrides && overrides.hasOwnProperty('subject') ? overrides.subject! : 'placeat',
  }
}

export const aDeliveryTraceConnection = (
  overrides?: Partial<DeliveryTraceConnection>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'DeliveryTraceConnection' } & DeliveryTraceConnection => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('DeliveryTraceConnection')
  return {
    __typename: 'DeliveryTraceConnection',
    edges:
      overrides && overrides.hasOwnProperty('edges')
        ? overrides.edges!
        : [
            relationshipsToOmit.has('DeliveryTraceEdge')
              ? ({} as DeliveryTraceEdge)
              : aDeliveryTraceEdge({}, relationshipsToOmit),
          ],
    nodes:
      overrides && overrides.hasOwnProperty('nodes')
        ? overrides.nodes!
        : [
            relationshipsToOmit.has('DeliveryTrace')
              ? ({} as DeliveryTrace)
              : aDeliveryTrace({}, relationshipsToOmit),
          ],
    pageInfo:
      overrides && overrides.hasOwnProperty('pageInfo')
        ? overrides.pageInfo!
        : relationshipsToOmit.has('PageInfo')
          ? ({} as PageInfo)
          : aPageInfo({}, relationshipsToOmit),
  }
}

export const aDeliveryTraceEdge = (
  overrides?: Partial<DeliveryTraceEdge>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'DeliveryTraceEdge' } & DeliveryTraceEdge => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('DeliveryTraceEdge')
  return {
    __typename: 'DeliveryTraceEdge',
    cursor: overrides && overrides.hasOwnProperty('cursor') ? overrides.cursor! : 'sed',
    node:
      overrides && overrides.hasOwnProperty('node')
        ? overrides.node!
        : relationshipsToOmit.has('DeliveryTrace')
          ? ({} as DeliveryTrace)
          : aDeliveryTrace({}, relationshipsToOmit),
  }
}

export const aDenyDepositPayoutPayload = (
  overrides?: Partial<DenyDepositPayoutPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'DenyDepositPayoutPayload' } & DenyDepositPayoutPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('DenyDepositPayoutPayload')
  return {
    __typename: 'DenyDepositPayoutPayload',
    contract:
      overrides && overrides.hasOwnProperty('contract')
        ? overrides.contract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const aDestroyAdminNotePayload = (
  overrides?: Partial<DestroyAdminNotePayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'DestroyAdminNotePayload' } & DestroyAdminNotePayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('DestroyAdminNotePayload')
  return {
    __typename: 'DestroyAdminNotePayload',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '87b38a3c-1ba1-41ee-aacb-07d51fce1aea',
  }
}

export const aDestroyApplicationBoardListPayload = (
  overrides?: Partial<DestroyApplicationBoardListPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'DestroyApplicationBoardListPayload' } & DestroyApplicationBoardListPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('DestroyApplicationBoardListPayload')
  return {
    __typename: 'DestroyApplicationBoardListPayload',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '98f2b7c9-88c5-4af3-a469-9e3e56cb03ec',
  }
}

export const aDestroyCreditReportPayload = (
  overrides?: Partial<DestroyCreditReportPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'DestroyCreditReportPayload' } & DestroyCreditReportPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('DestroyCreditReportPayload')
  return {
    __typename: 'DestroyCreditReportPayload',
    creditReport:
      overrides && overrides.hasOwnProperty('creditReport')
        ? overrides.creditReport!
        : relationshipsToOmit.has('CreditReport')
          ? ({} as CreditReport)
          : aCreditReport({}, relationshipsToOmit),
  }
}

export const aDestroyHomeAvailabilityPayload = (
  overrides?: Partial<DestroyHomeAvailabilityPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'DestroyHomeAvailabilityPayload' } & DestroyHomeAvailabilityPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('DestroyHomeAvailabilityPayload')
  return {
    __typename: 'DestroyHomeAvailabilityPayload',
    dates:
      overrides && overrides.hasOwnProperty('dates')
        ? overrides.dates!
        : ['modi distinctio non dolor veritatis accusantium magni'],
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const aDestroyIncomeVerificationPayload = (
  overrides?: Partial<DestroyIncomeVerificationPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'DestroyIncomeVerificationPayload' } & DestroyIncomeVerificationPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('DestroyIncomeVerificationPayload')
  return {
    __typename: 'DestroyIncomeVerificationPayload',
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : false,
  }
}

export const aDestroyMessageMacroPayload = (
  overrides?: Partial<DestroyMessageMacroPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'DestroyMessageMacroPayload' } & DestroyMessageMacroPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('DestroyMessageMacroPayload')
  return {
    __typename: 'DestroyMessageMacroPayload',
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : true,
  }
}

export const aDestroyOccupationPayload = (
  overrides?: Partial<DestroyOccupationPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'DestroyOccupationPayload' } & DestroyOccupationPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('DestroyOccupationPayload')
  return {
    __typename: 'DestroyOccupationPayload',
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : false,
  }
}

export const aDestroyOccupationVerificationPayload = (
  overrides?: Partial<DestroyOccupationVerificationPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'DestroyOccupationVerificationPayload' } & DestroyOccupationVerificationPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('DestroyOccupationVerificationPayload')
  return {
    __typename: 'DestroyOccupationVerificationPayload',
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : false,
  }
}

export const aDestroySavedSearchPayload = (
  overrides?: Partial<DestroySavedSearchPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'DestroySavedSearchPayload' } & DestroySavedSearchPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('DestroySavedSearchPayload')
  return {
    __typename: 'DestroySavedSearchPayload',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'f8b55991-ca21-49a7-9a71-3063add9d41a',
  }
}

export const aDestroyUploadPayload = (
  overrides?: Partial<DestroyUploadPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'DestroyUploadPayload' } & DestroyUploadPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('DestroyUploadPayload')
  return {
    __typename: 'DestroyUploadPayload',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '5d990bb5-64ac-4fc3-b8f5-cf069b56e835',
  }
}

export const aDestroyUserNotePayload = (
  overrides?: Partial<DestroyUserNotePayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'DestroyUserNotePayload' } & DestroyUserNotePayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('DestroyUserNotePayload')
  return {
    __typename: 'DestroyUserNotePayload',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '68d45d55-cb83-4668-8ae5-37c1186ce25a',
  }
}

export const aDestroyUserPayload = (
  overrides?: Partial<DestroyUserPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'DestroyUserPayload' } & DestroyUserPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('DestroyUserPayload')
  return {
    __typename: 'DestroyUserPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : true,
    uid:
      overrides && overrides.hasOwnProperty('uid') ? overrides.uid! : 'e996cf0d-e4f8-4bf9-8169-4c4bf4a72021',
  }
}

export const aDiscardIdentificationPayload = (
  overrides?: Partial<DiscardIdentificationPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'DiscardIdentificationPayload' } & DiscardIdentificationPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('DiscardIdentificationPayload')
  return {
    __typename: 'DiscardIdentificationPayload',
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : false,
  }
}

export const aDuplicateHomePayload = (
  overrides?: Partial<DuplicateHomePayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'DuplicateHomePayload' } & DuplicateHomePayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('DuplicateHomePayload')
  return {
    __typename: 'DuplicateHomePayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    home:
      overrides && overrides.hasOwnProperty('home')
        ? overrides.home!
        : relationshipsToOmit.has('Home')
          ? ({} as Home)
          : aHome({}, relationshipsToOmit),
  }
}

export const aDuration = (
  overrides?: Partial<Duration>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Duration' } & Duration => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Duration')
  return {
    __typename: 'Duration',
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'explicabo rerum consequuntur non deserunt sunt ab',
    durationPreference:
      overrides && overrides.hasOwnProperty('durationPreference')
        ? overrides.durationPreference!
        : relationshipsToOmit.has('DurationPreference')
          ? ({} as DurationPreference)
          : aDurationPreference({}, relationshipsToOmit),
    endOptimal:
      overrides && overrides.hasOwnProperty('endOptimal')
        ? overrides.endOptimal!
        : 'est sed eveniet eius assumenda ut recusandae',
    endUfn: overrides && overrides.hasOwnProperty('endUfn') ? overrides.endUfn! : true,
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'ca31c94e-6082-49f6-b3be-956c29abbf9e',
    possibilityOfExtension:
      overrides && overrides.hasOwnProperty('possibilityOfExtension')
        ? overrides.possibilityOfExtension!
        : true,
    startAsap: overrides && overrides.hasOwnProperty('startAsap') ? overrides.startAsap! : true,
    startOptimal:
      overrides && overrides.hasOwnProperty('startOptimal')
        ? overrides.startOptimal!
        : 'vero autem ut rerum qui sequi repudiandae',
    updatedAt:
      overrides && overrides.hasOwnProperty('updatedAt')
        ? overrides.updatedAt!
        : 'exercitationem cumque similique suscipit et iusto ut',
  }
}

export const aDurationPreference = (
  overrides?: Partial<DurationPreference>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'DurationPreference' } & DurationPreference => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('DurationPreference')
  return {
    __typename: 'DurationPreference',
    duration:
      overrides && overrides.hasOwnProperty('duration')
        ? overrides.duration!
        : relationshipsToOmit.has('Duration')
          ? ({} as Duration)
          : aDuration({}, relationshipsToOmit),
    endEarliest:
      overrides && overrides.hasOwnProperty('endEarliest')
        ? overrides.endEarliest!
        : 'vitae nesciunt enim consectetur magnam tenetur pariatur',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '7c5f90c2-bb20-4a56-9e79-401e0f0fea10',
    longestDurationDays:
      overrides && overrides.hasOwnProperty('longestDurationDays') ? overrides.longestDurationDays! : 2611,
    shortestDurationDays:
      overrides && overrides.hasOwnProperty('shortestDurationDays') ? overrides.shortestDurationDays! : 2626,
    startLatest:
      overrides && overrides.hasOwnProperty('startLatest')
        ? overrides.startLatest!
        : 'velit at eligendi corrupti sed eos et',
  }
}

export const anElectricityFeeInput = (
  overrides?: Partial<ElectricityFeeInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): ElectricityFeeInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ElectricityFeeInput')
  return {
    monthlyFee: overrides && overrides.hasOwnProperty('monthlyFee') ? overrides.monthlyFee! : 702,
    paymentPlan:
      overrides && overrides.hasOwnProperty('paymentPlan')
        ? overrides.paymentPlan!
        : ElectricityFeePaymentOptionEnum.fixed_fee,
  }
}

export const anElectricityFeeType = (
  overrides?: Partial<ElectricityFeeType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ElectricityFeeType' } & ElectricityFeeType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ElectricityFeeType')
  return {
    __typename: 'ElectricityFeeType',
    monthlyFee: overrides && overrides.hasOwnProperty('monthlyFee') ? overrides.monthlyFee! : 2103,
    paymentPlan:
      overrides && overrides.hasOwnProperty('paymentPlan')
        ? overrides.paymentPlan!
        : ElectricityFeePaymentOptionEnum.fixed_fee,
  }
}

export const anEmployee = (
  overrides?: Partial<Employee>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Employee' } & Employee => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Employee')
  return {
    __typename: 'Employee',
    compiledPermissions:
      overrides && overrides.hasOwnProperty('compiledPermissions')
        ? overrides.compiledPermissions!
        : [
            relationshipsToOmit.has('EmployeePermission')
              ? ({} as EmployeePermission)
              : anEmployeePermission({}, relationshipsToOmit),
          ],
    email: overrides && overrides.hasOwnProperty('email') ? overrides.email! : 'et',
    groups:
      overrides && overrides.hasOwnProperty('groups')
        ? overrides.groups!
        : relationshipsToOmit.has('EmployeeGroupConnection')
          ? ({} as EmployeeGroupConnection)
          : anEmployeeGroupConnection({}, relationshipsToOmit),
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '270d20ad-d092-4815-802a-5db16eb2fbd6',
    name: overrides && overrides.hasOwnProperty('name') ? overrides.name! : 'cumque',
    profilePictureUrl:
      overrides && overrides.hasOwnProperty('profilePictureUrl') ? overrides.profilePictureUrl! : 'quo',
    roles:
      overrides && overrides.hasOwnProperty('roles')
        ? overrides.roles!
        : relationshipsToOmit.has('EmployeeRoleConnection')
          ? ({} as EmployeeRoleConnection)
          : anEmployeeRoleConnection({}, relationshipsToOmit),
  }
}

export const anEmployeeConnection = (
  overrides?: Partial<EmployeeConnection>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'EmployeeConnection' } & EmployeeConnection => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('EmployeeConnection')
  return {
    __typename: 'EmployeeConnection',
    edges:
      overrides && overrides.hasOwnProperty('edges')
        ? overrides.edges!
        : [
            relationshipsToOmit.has('EmployeeEdge')
              ? ({} as EmployeeEdge)
              : anEmployeeEdge({}, relationshipsToOmit),
          ],
    nodes:
      overrides && overrides.hasOwnProperty('nodes')
        ? overrides.nodes!
        : [relationshipsToOmit.has('Employee') ? ({} as Employee) : anEmployee({}, relationshipsToOmit)],
    pageInfo:
      overrides && overrides.hasOwnProperty('pageInfo')
        ? overrides.pageInfo!
        : relationshipsToOmit.has('PageInfo')
          ? ({} as PageInfo)
          : aPageInfo({}, relationshipsToOmit),
  }
}

export const anEmployeeEdge = (
  overrides?: Partial<EmployeeEdge>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'EmployeeEdge' } & EmployeeEdge => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('EmployeeEdge')
  return {
    __typename: 'EmployeeEdge',
    cursor: overrides && overrides.hasOwnProperty('cursor') ? overrides.cursor! : 'id',
    node:
      overrides && overrides.hasOwnProperty('node')
        ? overrides.node!
        : relationshipsToOmit.has('Employee')
          ? ({} as Employee)
          : anEmployee({}, relationshipsToOmit),
  }
}

export const anEmployeeGroup = (
  overrides?: Partial<EmployeeGroup>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'EmployeeGroup' } & EmployeeGroup => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('EmployeeGroup')
  return {
    __typename: 'EmployeeGroup',
    employees:
      overrides && overrides.hasOwnProperty('employees')
        ? overrides.employees!
        : relationshipsToOmit.has('EmployeeConnection')
          ? ({} as EmployeeConnection)
          : anEmployeeConnection({}, relationshipsToOmit),
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '629842ec-579d-4f4b-b70a-a13e5e225d20',
    name: overrides && overrides.hasOwnProperty('name') ? overrides.name! : 'vel',
    roles:
      overrides && overrides.hasOwnProperty('roles')
        ? overrides.roles!
        : relationshipsToOmit.has('EmployeeRoleConnection')
          ? ({} as EmployeeRoleConnection)
          : anEmployeeRoleConnection({}, relationshipsToOmit),
  }
}

export const anEmployeeGroupConnection = (
  overrides?: Partial<EmployeeGroupConnection>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'EmployeeGroupConnection' } & EmployeeGroupConnection => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('EmployeeGroupConnection')
  return {
    __typename: 'EmployeeGroupConnection',
    edges:
      overrides && overrides.hasOwnProperty('edges')
        ? overrides.edges!
        : [
            relationshipsToOmit.has('EmployeeGroupEdge')
              ? ({} as EmployeeGroupEdge)
              : anEmployeeGroupEdge({}, relationshipsToOmit),
          ],
    nodes:
      overrides && overrides.hasOwnProperty('nodes')
        ? overrides.nodes!
        : [
            relationshipsToOmit.has('EmployeeGroup')
              ? ({} as EmployeeGroup)
              : anEmployeeGroup({}, relationshipsToOmit),
          ],
    pageInfo:
      overrides && overrides.hasOwnProperty('pageInfo')
        ? overrides.pageInfo!
        : relationshipsToOmit.has('PageInfo')
          ? ({} as PageInfo)
          : aPageInfo({}, relationshipsToOmit),
  }
}

export const anEmployeeGroupEdge = (
  overrides?: Partial<EmployeeGroupEdge>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'EmployeeGroupEdge' } & EmployeeGroupEdge => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('EmployeeGroupEdge')
  return {
    __typename: 'EmployeeGroupEdge',
    cursor: overrides && overrides.hasOwnProperty('cursor') ? overrides.cursor! : 'maiores',
    node:
      overrides && overrides.hasOwnProperty('node')
        ? overrides.node!
        : relationshipsToOmit.has('EmployeeGroup')
          ? ({} as EmployeeGroup)
          : anEmployeeGroup({}, relationshipsToOmit),
  }
}

export const anEmployeePermission = (
  overrides?: Partial<EmployeePermission>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'EmployeePermission' } & EmployeePermission => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('EmployeePermission')
  return {
    __typename: 'EmployeePermission',
    actions:
      overrides && overrides.hasOwnProperty('actions')
        ? overrides.actions!
        : [EmployeePermissionActionEnum.destroy],
    objectType: overrides && overrides.hasOwnProperty('objectType') ? overrides.objectType! : 'nostrum',
  }
}

export const anEmployeePermissionConnection = (
  overrides?: Partial<EmployeePermissionConnection>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'EmployeePermissionConnection' } & EmployeePermissionConnection => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('EmployeePermissionConnection')
  return {
    __typename: 'EmployeePermissionConnection',
    edges:
      overrides && overrides.hasOwnProperty('edges')
        ? overrides.edges!
        : [
            relationshipsToOmit.has('EmployeePermissionEdge')
              ? ({} as EmployeePermissionEdge)
              : anEmployeePermissionEdge({}, relationshipsToOmit),
          ],
    nodes:
      overrides && overrides.hasOwnProperty('nodes')
        ? overrides.nodes!
        : [
            relationshipsToOmit.has('EmployeePermission')
              ? ({} as EmployeePermission)
              : anEmployeePermission({}, relationshipsToOmit),
          ],
    pageInfo:
      overrides && overrides.hasOwnProperty('pageInfo')
        ? overrides.pageInfo!
        : relationshipsToOmit.has('PageInfo')
          ? ({} as PageInfo)
          : aPageInfo({}, relationshipsToOmit),
  }
}

export const anEmployeePermissionEdge = (
  overrides?: Partial<EmployeePermissionEdge>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'EmployeePermissionEdge' } & EmployeePermissionEdge => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('EmployeePermissionEdge')
  return {
    __typename: 'EmployeePermissionEdge',
    cursor: overrides && overrides.hasOwnProperty('cursor') ? overrides.cursor! : 'ut',
    node:
      overrides && overrides.hasOwnProperty('node')
        ? overrides.node!
        : relationshipsToOmit.has('EmployeePermission')
          ? ({} as EmployeePermission)
          : anEmployeePermission({}, relationshipsToOmit),
  }
}

export const anEmployeeRole = (
  overrides?: Partial<EmployeeRole>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'EmployeeRole' } & EmployeeRole => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('EmployeeRole')
  return {
    __typename: 'EmployeeRole',
    description: overrides && overrides.hasOwnProperty('description') ? overrides.description! : 'itaque',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '6be567cd-5cbf-4952-b95b-678418b6dd2b',
    name: overrides && overrides.hasOwnProperty('name') ? overrides.name! : 'ea',
    permissions:
      overrides && overrides.hasOwnProperty('permissions')
        ? overrides.permissions!
        : relationshipsToOmit.has('EmployeePermissionConnection')
          ? ({} as EmployeePermissionConnection)
          : anEmployeePermissionConnection({}, relationshipsToOmit),
  }
}

export const anEmployeeRoleConnection = (
  overrides?: Partial<EmployeeRoleConnection>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'EmployeeRoleConnection' } & EmployeeRoleConnection => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('EmployeeRoleConnection')
  return {
    __typename: 'EmployeeRoleConnection',
    edges:
      overrides && overrides.hasOwnProperty('edges')
        ? overrides.edges!
        : [
            relationshipsToOmit.has('EmployeeRoleEdge')
              ? ({} as EmployeeRoleEdge)
              : anEmployeeRoleEdge({}, relationshipsToOmit),
          ],
    nodes:
      overrides && overrides.hasOwnProperty('nodes')
        ? overrides.nodes!
        : [
            relationshipsToOmit.has('EmployeeRole')
              ? ({} as EmployeeRole)
              : anEmployeeRole({}, relationshipsToOmit),
          ],
    pageInfo:
      overrides && overrides.hasOwnProperty('pageInfo')
        ? overrides.pageInfo!
        : relationshipsToOmit.has('PageInfo')
          ? ({} as PageInfo)
          : aPageInfo({}, relationshipsToOmit),
  }
}

export const anEmployeeRoleEdge = (
  overrides?: Partial<EmployeeRoleEdge>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'EmployeeRoleEdge' } & EmployeeRoleEdge => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('EmployeeRoleEdge')
  return {
    __typename: 'EmployeeRoleEdge',
    cursor: overrides && overrides.hasOwnProperty('cursor') ? overrides.cursor! : 'voluptas',
    node:
      overrides && overrides.hasOwnProperty('node')
        ? overrides.node!
        : relationshipsToOmit.has('EmployeeRole')
          ? ({} as EmployeeRole)
          : anEmployeeRole({}, relationshipsToOmit),
  }
}

export const anEmploymentVerification = (
  overrides?: Partial<EmploymentVerification>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'EmploymentVerification' } & EmploymentVerification => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('EmploymentVerification')
  return {
    __typename: 'EmploymentVerification',
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'harum ipsa in et molestiae animi quia',
    employerName:
      overrides && overrides.hasOwnProperty('employerName') ? overrides.employerName! : 'explicabo',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '7ae6dcc7-a54d-41e1-9707-a5bb6c8f00e8',
    role: overrides && overrides.hasOwnProperty('role') ? overrides.role! : 'quia',
    updatedAt:
      overrides && overrides.hasOwnProperty('updatedAt')
        ? overrides.updatedAt!
        : 'non necessitatibus temporibus ipsum molestiae sed aliquam',
    upload:
      overrides && overrides.hasOwnProperty('upload')
        ? overrides.upload!
        : relationshipsToOmit.has('Upload')
          ? ({} as Upload)
          : anUpload({}, relationshipsToOmit),
  }
}

export const anEnrollHomeInShortcutInput = (
  overrides?: Partial<EnrollHomeInShortcutInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): EnrollHomeInShortcutInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('EnrollHomeInShortcutInput')
  return {
    homeId:
      overrides && overrides.hasOwnProperty('homeId')
        ? overrides.homeId!
        : 'ddda1c16-a9ca-4445-8ebe-f537003112c0',
  }
}

export const anEnrollHomeInShortcutPayload = (
  overrides?: Partial<EnrollHomeInShortcutPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'EnrollHomeInShortcutPayload' } & EnrollHomeInShortcutPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('EnrollHomeInShortcutPayload')
  return {
    __typename: 'EnrollHomeInShortcutPayload',
    shortcut:
      overrides && overrides.hasOwnProperty('shortcut')
        ? overrides.shortcut!
        : relationshipsToOmit.has('Shortcut')
          ? ({} as Shortcut)
          : aShortcut({}, relationshipsToOmit),
  }
}

export const anError = (
  overrides?: Partial<Error>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Error' } & Error => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Error')
  return {
    __typename: 'Error',
    message: overrides && overrides.hasOwnProperty('message') ? overrides.message! : 'et',
  }
}

export const anEvaluateHomeForShortcutPayload = (
  overrides?: Partial<EvaluateHomeForShortcutPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'EvaluateHomeForShortcutPayload' } & EvaluateHomeForShortcutPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('EvaluateHomeForShortcutPayload')
  return {
    __typename: 'EvaluateHomeForShortcutPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    home:
      overrides && overrides.hasOwnProperty('home')
        ? overrides.home!
        : relationshipsToOmit.has('Home')
          ? ({} as Home)
          : aHome({}, relationshipsToOmit),
  }
}

export const anEvictionProcess = (
  overrides?: Partial<EvictionProcess>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'EvictionProcess' } & EvictionProcess => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('EvictionProcess')
  return {
    __typename: 'EvictionProcess',
    billectaInvoiceNumber:
      overrides && overrides.hasOwnProperty('billectaInvoiceNumber')
        ? overrides.billectaInvoiceNumber!
        : 'inventore',
    closed: overrides && overrides.hasOwnProperty('closed') ? overrides.closed! : true,
    comments:
      overrides && overrides.hasOwnProperty('comments')
        ? overrides.comments!
        : [
            relationshipsToOmit.has('EvictionProcessComment')
              ? ({} as EvictionProcessComment)
              : anEvictionProcessComment({}, relationshipsToOmit),
          ],
    contract:
      overrides && overrides.hasOwnProperty('contract')
        ? overrides.contract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'blanditiis natus est et repellendus dolor ab',
    currentState:
      overrides && overrides.hasOwnProperty('currentState')
        ? overrides.currentState!
        : EvictionProcessState.awaiting_reply_from_kronofogden,
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '55a473fd-7eb7-416b-973d-babb14f9e26e',
    invoice:
      overrides && overrides.hasOwnProperty('invoice')
        ? overrides.invoice!
        : relationshipsToOmit.has('Invoice')
          ? ({} as Invoice)
          : anInvoice({}, relationshipsToOmit),
    landlord:
      overrides && overrides.hasOwnProperty('landlord')
        ? overrides.landlord!
        : relationshipsToOmit.has('KanyeUser')
          ? ({} as KanyeUser)
          : aKanyeUser({}, relationshipsToOmit),
    messageToSocPdf:
      overrides && overrides.hasOwnProperty('messageToSocPdf')
        ? overrides.messageToSocPdf!
        : relationshipsToOmit.has('Blob')
          ? ({} as Blob)
          : aBlob({}, relationshipsToOmit),
    socInfo:
      overrides && overrides.hasOwnProperty('socInfo')
        ? overrides.socInfo!
        : relationshipsToOmit.has('EvictionProcessSocInfo')
          ? ({} as EvictionProcessSocInfo)
          : anEvictionProcessSocInfo({}, relationshipsToOmit),
    updatedAt:
      overrides && overrides.hasOwnProperty('updatedAt')
        ? overrides.updatedAt!
        : 'quia repellat ex nostrum ad a nihil',
    user:
      overrides && overrides.hasOwnProperty('user')
        ? overrides.user!
        : relationshipsToOmit.has('KanyeUser')
          ? ({} as KanyeUser)
          : aKanyeUser({}, relationshipsToOmit),
  }
}

export const anEvictionProcessAttributes = (
  overrides?: Partial<EvictionProcessAttributes>,
  _relationshipsToOmit: Set<string> = new Set(),
): EvictionProcessAttributes => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('EvictionProcessAttributes')
  return {
    messageToSocPdf:
      overrides && overrides.hasOwnProperty('messageToSocPdf')
        ? overrides.messageToSocPdf!
        : '27f75ac3-e2d9-46d7-84c5-caef76563e24',
  }
}

export const anEvictionProcessComment = (
  overrides?: Partial<EvictionProcessComment>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'EvictionProcessComment' } & EvictionProcessComment => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('EvictionProcessComment')
  return {
    __typename: 'EvictionProcessComment',
    body: overrides && overrides.hasOwnProperty('body') ? overrides.body! : 'ut',
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'est quidem harum quia expedita porro pariatur',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'e9ba3ca9-386f-47ad-bd6b-1f32ca2f2d0b',
    processState:
      overrides && overrides.hasOwnProperty('processState') ? overrides.processState! : 'exercitationem',
    user:
      overrides && overrides.hasOwnProperty('user')
        ? overrides.user!
        : relationshipsToOmit.has('KanyeUser')
          ? ({} as KanyeUser)
          : aKanyeUser({}, relationshipsToOmit),
  }
}

export const anEvictionProcessConnection = (
  overrides?: Partial<EvictionProcessConnection>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'EvictionProcessConnection' } & EvictionProcessConnection => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('EvictionProcessConnection')
  return {
    __typename: 'EvictionProcessConnection',
    edges:
      overrides && overrides.hasOwnProperty('edges')
        ? overrides.edges!
        : [
            relationshipsToOmit.has('EvictionProcessEdge')
              ? ({} as EvictionProcessEdge)
              : anEvictionProcessEdge({}, relationshipsToOmit),
          ],
    nodes:
      overrides && overrides.hasOwnProperty('nodes')
        ? overrides.nodes!
        : [
            relationshipsToOmit.has('EvictionProcess')
              ? ({} as EvictionProcess)
              : anEvictionProcess({}, relationshipsToOmit),
          ],
    pageInfo:
      overrides && overrides.hasOwnProperty('pageInfo')
        ? overrides.pageInfo!
        : relationshipsToOmit.has('PageInfo')
          ? ({} as PageInfo)
          : aPageInfo({}, relationshipsToOmit),
  }
}

export const anEvictionProcessEdge = (
  overrides?: Partial<EvictionProcessEdge>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'EvictionProcessEdge' } & EvictionProcessEdge => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('EvictionProcessEdge')
  return {
    __typename: 'EvictionProcessEdge',
    cursor: overrides && overrides.hasOwnProperty('cursor') ? overrides.cursor! : 'fugiat',
    node:
      overrides && overrides.hasOwnProperty('node')
        ? overrides.node!
        : relationshipsToOmit.has('EvictionProcess')
          ? ({} as EvictionProcess)
          : anEvictionProcess({}, relationshipsToOmit),
  }
}

export const anEvictionProcessSocInfo = (
  overrides?: Partial<EvictionProcessSocInfo>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'EvictionProcessSocInfo' } & EvictionProcessSocInfo => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('EvictionProcessSocInfo')
  return {
    __typename: 'EvictionProcessSocInfo',
    nextMonth: overrides && overrides.hasOwnProperty('nextMonth') ? overrides.nextMonth! : 'optio',
    qasaInfo:
      overrides && overrides.hasOwnProperty('qasaInfo')
        ? overrides.qasaInfo!
        : relationshipsToOmit.has('SocEvictionEmailQasaInfo')
          ? ({} as SocEvictionEmailQasaInfo)
          : aSocEvictionEmailQasaInfo({}, relationshipsToOmit),
  }
}

export const anExchangeLoginTokenPayload = (
  overrides?: Partial<ExchangeLoginTokenPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ExchangeLoginTokenPayload' } & ExchangeLoginTokenPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ExchangeLoginTokenPayload')
  return {
    __typename: 'ExchangeLoginTokenPayload',
    accessToken: overrides && overrides.hasOwnProperty('accessToken') ? overrides.accessToken! : 'cum',
  }
}

export const anExchangeSignInCodePayload = (
  overrides?: Partial<ExchangeSignInCodePayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ExchangeSignInCodePayload' } & ExchangeSignInCodePayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ExchangeSignInCodePayload')
  return {
    __typename: 'ExchangeSignInCodePayload',
    accessToken: overrides && overrides.hasOwnProperty('accessToken') ? overrides.accessToken! : 'enim',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const anExtendContractPayload = (
  overrides?: Partial<ExtendContractPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ExtendContractPayload' } & ExtendContractPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ExtendContractPayload')
  return {
    __typename: 'ExtendContractPayload',
    contract:
      overrides && overrides.hasOwnProperty('contract')
        ? overrides.contract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const anExternalContractTakeover = (
  overrides?: Partial<ExternalContractTakeover>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ExternalContractTakeover' } & ExternalContractTakeover => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ExternalContractTakeover')
  return {
    __typename: 'ExternalContractTakeover',
    currentState:
      overrides && overrides.hasOwnProperty('currentState')
        ? overrides.currentState!
        : ExternalContractTakeoverStateEnum.completed,
  }
}

export const anExtraCost = (
  overrides?: Partial<ExtraCost>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ExtraCost' } & ExtraCost => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ExtraCost')
  return {
    __typename: 'ExtraCost',
    amount: overrides && overrides.hasOwnProperty('amount') ? overrides.amount! : 6800,
    costFor:
      overrides && overrides.hasOwnProperty('costFor') ? overrides.costFor! : ExtraCostForEnum.electricity,
    description: overrides && overrides.hasOwnProperty('description') ? overrides.description! : 'numquam',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '54bb7387-f5e0-4d1a-8aba-6711bc56ee97',
    plan: overrides && overrides.hasOwnProperty('plan') ? overrides.plan! : ExtraCostPlanEnum.included,
  }
}

export const anExtraCostInput = (
  overrides?: Partial<ExtraCostInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): ExtraCostInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ExtraCostInput')
  return {
    amount: overrides && overrides.hasOwnProperty('amount') ? overrides.amount! : 6071,
    costFor:
      overrides && overrides.hasOwnProperty('costFor') ? overrides.costFor! : ExtraCostForEnum.electricity,
    description: overrides && overrides.hasOwnProperty('description') ? overrides.description! : 'minima',
    plan: overrides && overrides.hasOwnProperty('plan') ? overrides.plan! : ExtraCostPlanEnum.included,
  }
}

export const aFamilyMember = (
  overrides?: Partial<FamilyMember>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FamilyMember' } & FamilyMember => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FamilyMember')
  return {
    __typename: 'FamilyMember',
    birthDate:
      overrides && overrides.hasOwnProperty('birthDate')
        ? overrides.birthDate!
        : 'doloribus asperiores ut in ut commodi non',
    firstName: overrides && overrides.hasOwnProperty('firstName') ? overrides.firstName! : 'libero',
    gender: overrides && overrides.hasOwnProperty('gender') ? overrides.gender! : GenderEnum.female,
  }
}

export const aFamilyMemberInput = (
  overrides?: Partial<FamilyMemberInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): FamilyMemberInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FamilyMemberInput')
  return {
    birthDate:
      overrides && overrides.hasOwnProperty('birthDate')
        ? overrides.birthDate!
        : 'iusto tempora magnam qui laborum dolores eos',
    firstName: overrides && overrides.hasOwnProperty('firstName') ? overrides.firstName! : 'quis',
    gender: overrides && overrides.hasOwnProperty('gender') ? overrides.gender! : GenderEnum.female,
  }
}

export const aFavoriteMarkHomePayload = (
  overrides?: Partial<FavoriteMarkHomePayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FavoriteMarkHomePayload' } & FavoriteMarkHomePayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FavoriteMarkHomePayload')
  return {
    __typename: 'FavoriteMarkHomePayload',
    home:
      overrides && overrides.hasOwnProperty('home')
        ? overrides.home!
        : relationshipsToOmit.has('Home')
          ? ({} as Home)
          : aHome({}, relationshipsToOmit),
  }
}

export const aFee = (
  overrides?: Partial<Fee>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Fee' } & Fee => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Fee')
  return {
    __typename: 'Fee',
    availability:
      overrides && overrides.hasOwnProperty('availability')
        ? overrides.availability!
        : FeeAvailabilityEnum.mandatory,
    cost:
      overrides && overrides.hasOwnProperty('cost')
        ? overrides.cost!
        : relationshipsToOmit.has('Cost')
          ? ({} as Cost)
          : aCost({}, relationshipsToOmit),
  }
}

export const aFinalizeUploadPayload = (
  overrides?: Partial<FinalizeUploadPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FinalizeUploadPayload' } & FinalizeUploadPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FinalizeUploadPayload')
  return {
    __typename: 'FinalizeUploadPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    upload:
      overrides && overrides.hasOwnProperty('upload')
        ? overrides.upload!
        : relationshipsToOmit.has('Upload')
          ? ({} as Upload)
          : anUpload({}, relationshipsToOmit),
  }
}

export const aFinanceChargeType = (
  overrides?: Partial<FinanceChargeType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FinanceChargeType' } & FinanceChargeType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FinanceChargeType')
  return {
    __typename: 'FinanceChargeType',
    amount:
      overrides && overrides.hasOwnProperty('amount')
        ? overrides.amount!
        : relationshipsToOmit.has('Money')
          ? ({} as Money)
          : aMoney({}, relationshipsToOmit),
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '598333bc-adb0-4236-89c9-8d715db58ad0',
    productName:
      overrides && overrides.hasOwnProperty('productName') ? overrides.productName! : ProductEnum.DEPOSIT,
    vat:
      overrides && overrides.hasOwnProperty('vat')
        ? overrides.vat!
        : relationshipsToOmit.has('Money')
          ? ({} as Money)
          : aMoney({}, relationshipsToOmit),
    vatPercent: overrides && overrides.hasOwnProperty('vatPercent') ? overrides.vatPercent! : 'placeat',
  }
}

export const aFinanceDepositInvoice = (
  overrides?: Partial<FinanceDepositInvoice>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FinanceDepositInvoice' } & FinanceDepositInvoice => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FinanceDepositInvoice')
  return {
    __typename: 'FinanceDepositInvoice',
    charges:
      overrides && overrides.hasOwnProperty('charges')
        ? overrides.charges!
        : [
            relationshipsToOmit.has('FinanceChargeType')
              ? ({} as FinanceChargeType)
              : aFinanceChargeType({}, relationshipsToOmit),
          ],
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'eius atque adipisci dolorem rem eveniet id',
    currentState:
      overrides && overrides.hasOwnProperty('currentState')
        ? overrides.currentState!
        : InvoiceStateEnum.cancelled,
    dueDate:
      overrides && overrides.hasOwnProperty('dueDate')
        ? overrides.dueDate!
        : 'quidem expedita perspiciatis quisquam perspiciatis laborum dolorem',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '3a72c42b-dc2e-495a-aac2-3097bf6af7b6',
    items:
      overrides && overrides.hasOwnProperty('items')
        ? overrides.items!
        : [
            relationshipsToOmit.has('FinanceItemType')
              ? ({} as FinanceItemType)
              : aFinanceItemType({}, relationshipsToOmit),
          ],
    overdue: overrides && overrides.hasOwnProperty('overdue') ? overrides.overdue! : true,
    paidAt:
      overrides && overrides.hasOwnProperty('paidAt')
        ? overrides.paidAt!
        : 'laudantium consectetur aut quia cupiditate sit nihil',
    paymentAction:
      overrides && overrides.hasOwnProperty('paymentAction')
        ? overrides.paymentAction!
        : relationshipsToOmit.has('StripeSepaTransferMoneyAction')
          ? ({} as StripeSepaTransferMoneyAction)
          : aStripeSepaTransferMoneyAction({}, relationshipsToOmit),
    payout:
      overrides && overrides.hasOwnProperty('payout')
        ? overrides.payout!
        : relationshipsToOmit.has('FinancePayoutType')
          ? ({} as FinancePayoutType)
          : aFinancePayoutType({}, relationshipsToOmit),
    receipt:
      overrides && overrides.hasOwnProperty('receipt')
        ? overrides.receipt!
        : relationshipsToOmit.has('Blob')
          ? ({} as Blob)
          : aBlob({}, relationshipsToOmit),
    reference:
      overrides && overrides.hasOwnProperty('reference')
        ? overrides.reference!
        : '69ace9c2-1d1e-47bd-a8ef-35147bcac0bd',
    requiredUserActions:
      overrides && overrides.hasOwnProperty('requiredUserActions')
        ? overrides.requiredUserActions!
        : [relationshipsToOmit.has('UserAction') ? ({} as UserAction) : aUserAction({}, relationshipsToOmit)],
    sumAmount:
      overrides && overrides.hasOwnProperty('sumAmount')
        ? overrides.sumAmount!
        : relationshipsToOmit.has('Money')
          ? ({} as Money)
          : aMoney({}, relationshipsToOmit),
    sumVat:
      overrides && overrides.hasOwnProperty('sumVat')
        ? overrides.sumVat!
        : relationshipsToOmit.has('Money')
          ? ({} as Money)
          : aMoney({}, relationshipsToOmit),
  }
}

export const aFinanceGenericInvoice = (
  overrides?: Partial<FinanceGenericInvoice>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FinanceGenericInvoice' } & FinanceGenericInvoice => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FinanceGenericInvoice')
  return {
    __typename: 'FinanceGenericInvoice',
    charges:
      overrides && overrides.hasOwnProperty('charges')
        ? overrides.charges!
        : [
            relationshipsToOmit.has('FinanceChargeType')
              ? ({} as FinanceChargeType)
              : aFinanceChargeType({}, relationshipsToOmit),
          ],
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'ut consequatur assumenda natus aut fugit velit',
    currentState:
      overrides && overrides.hasOwnProperty('currentState')
        ? overrides.currentState!
        : InvoiceStateEnum.cancelled,
    dueDate:
      overrides && overrides.hasOwnProperty('dueDate')
        ? overrides.dueDate!
        : 'quaerat non autem provident adipisci maxime rerum',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '1653cd37-d24b-4b04-b265-ba45d1d8dd2b',
    items:
      overrides && overrides.hasOwnProperty('items')
        ? overrides.items!
        : [
            relationshipsToOmit.has('FinanceItemType')
              ? ({} as FinanceItemType)
              : aFinanceItemType({}, relationshipsToOmit),
          ],
    overdue: overrides && overrides.hasOwnProperty('overdue') ? overrides.overdue! : true,
    paidAt:
      overrides && overrides.hasOwnProperty('paidAt')
        ? overrides.paidAt!
        : 'provident dolorum delectus quia et perspiciatis aperiam',
    paymentAction:
      overrides && overrides.hasOwnProperty('paymentAction')
        ? overrides.paymentAction!
        : relationshipsToOmit.has('StripeSepaTransferMoneyAction')
          ? ({} as StripeSepaTransferMoneyAction)
          : aStripeSepaTransferMoneyAction({}, relationshipsToOmit),
    payout:
      overrides && overrides.hasOwnProperty('payout')
        ? overrides.payout!
        : relationshipsToOmit.has('FinancePayoutType')
          ? ({} as FinancePayoutType)
          : aFinancePayoutType({}, relationshipsToOmit),
    periodEnd:
      overrides && overrides.hasOwnProperty('periodEnd')
        ? overrides.periodEnd!
        : 'et rem in rerum maiores molestias quae',
    periodStart:
      overrides && overrides.hasOwnProperty('periodStart')
        ? overrides.periodStart!
        : 'architecto sed voluptas vel aliquam est ab',
    receipt:
      overrides && overrides.hasOwnProperty('receipt')
        ? overrides.receipt!
        : relationshipsToOmit.has('Blob')
          ? ({} as Blob)
          : aBlob({}, relationshipsToOmit),
    reference:
      overrides && overrides.hasOwnProperty('reference')
        ? overrides.reference!
        : 'c460e8b3-a447-4860-a5f2-49db2970d8dd',
    requiredUserActions:
      overrides && overrides.hasOwnProperty('requiredUserActions')
        ? overrides.requiredUserActions!
        : [relationshipsToOmit.has('UserAction') ? ({} as UserAction) : aUserAction({}, relationshipsToOmit)],
    sumAmount:
      overrides && overrides.hasOwnProperty('sumAmount')
        ? overrides.sumAmount!
        : relationshipsToOmit.has('Money')
          ? ({} as Money)
          : aMoney({}, relationshipsToOmit),
    sumVat:
      overrides && overrides.hasOwnProperty('sumVat')
        ? overrides.sumVat!
        : relationshipsToOmit.has('Money')
          ? ({} as Money)
          : aMoney({}, relationshipsToOmit),
  }
}

export const aFinanceItemType = (
  overrides?: Partial<FinanceItemType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FinanceItemType' } & FinanceItemType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FinanceItemType')
  return {
    __typename: 'FinanceItemType',
    amount:
      overrides && overrides.hasOwnProperty('amount')
        ? overrides.amount!
        : relationshipsToOmit.has('Money')
          ? ({} as Money)
          : aMoney({}, relationshipsToOmit),
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'f01baad7-642f-4c77-9fd0-4ed84e5e5adb',
    periodStart:
      overrides && overrides.hasOwnProperty('periodStart')
        ? overrides.periodStart!
        : 'corporis voluptate ratione voluptatem ab incidunt dolor',
    productName:
      overrides && overrides.hasOwnProperty('productName') ? overrides.productName! : ProductEnum.DEPOSIT,
    vat:
      overrides && overrides.hasOwnProperty('vat')
        ? overrides.vat!
        : relationshipsToOmit.has('Money')
          ? ({} as Money)
          : aMoney({}, relationshipsToOmit),
    vatPercent: overrides && overrides.hasOwnProperty('vatPercent') ? overrides.vatPercent! : 'explicabo',
  }
}

export const aFinanceMonthlyRentInvoice = (
  overrides?: Partial<FinanceMonthlyRentInvoice>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FinanceMonthlyRentInvoice' } & FinanceMonthlyRentInvoice => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FinanceMonthlyRentInvoice')
  return {
    __typename: 'FinanceMonthlyRentInvoice',
    charges:
      overrides && overrides.hasOwnProperty('charges')
        ? overrides.charges!
        : [
            relationshipsToOmit.has('FinanceChargeType')
              ? ({} as FinanceChargeType)
              : aFinanceChargeType({}, relationshipsToOmit),
          ],
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'qui explicabo repellendus quis consequatur sed voluptate',
    currentState:
      overrides && overrides.hasOwnProperty('currentState')
        ? overrides.currentState!
        : InvoiceStateEnum.cancelled,
    dueDate:
      overrides && overrides.hasOwnProperty('dueDate')
        ? overrides.dueDate!
        : 'odit iusto impedit aliquid explicabo sunt accusantium',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '098b17a3-6d18-4ca8-8b10-19ff1892b403',
    items:
      overrides && overrides.hasOwnProperty('items')
        ? overrides.items!
        : [
            relationshipsToOmit.has('FinanceItemType')
              ? ({} as FinanceItemType)
              : aFinanceItemType({}, relationshipsToOmit),
          ],
    overdue: overrides && overrides.hasOwnProperty('overdue') ? overrides.overdue! : true,
    paidAt:
      overrides && overrides.hasOwnProperty('paidAt')
        ? overrides.paidAt!
        : 'ipsum et commodi corrupti sunt numquam qui',
    paymentAction:
      overrides && overrides.hasOwnProperty('paymentAction')
        ? overrides.paymentAction!
        : relationshipsToOmit.has('StripeSepaTransferMoneyAction')
          ? ({} as StripeSepaTransferMoneyAction)
          : aStripeSepaTransferMoneyAction({}, relationshipsToOmit),
    payout:
      overrides && overrides.hasOwnProperty('payout')
        ? overrides.payout!
        : relationshipsToOmit.has('FinancePayoutType')
          ? ({} as FinancePayoutType)
          : aFinancePayoutType({}, relationshipsToOmit),
    periodEnd:
      overrides && overrides.hasOwnProperty('periodEnd')
        ? overrides.periodEnd!
        : 'a possimus et ad optio nihil odio',
    periodStart:
      overrides && overrides.hasOwnProperty('periodStart')
        ? overrides.periodStart!
        : 'nostrum qui eos quia est omnis quisquam',
    receipt:
      overrides && overrides.hasOwnProperty('receipt')
        ? overrides.receipt!
        : relationshipsToOmit.has('Blob')
          ? ({} as Blob)
          : aBlob({}, relationshipsToOmit),
    reference:
      overrides && overrides.hasOwnProperty('reference')
        ? overrides.reference!
        : 'be22f6bb-6a47-40be-8fd6-b95d90339031',
    requiredUserActions:
      overrides && overrides.hasOwnProperty('requiredUserActions')
        ? overrides.requiredUserActions!
        : [relationshipsToOmit.has('UserAction') ? ({} as UserAction) : aUserAction({}, relationshipsToOmit)],
    sumAmount:
      overrides && overrides.hasOwnProperty('sumAmount')
        ? overrides.sumAmount!
        : relationshipsToOmit.has('Money')
          ? ({} as Money)
          : aMoney({}, relationshipsToOmit),
    sumVat:
      overrides && overrides.hasOwnProperty('sumVat')
        ? overrides.sumVat!
        : relationshipsToOmit.has('Money')
          ? ({} as Money)
          : aMoney({}, relationshipsToOmit),
    upcomingPayout:
      overrides && overrides.hasOwnProperty('upcomingPayout')
        ? overrides.upcomingPayout!
        : relationshipsToOmit.has('UpcomingPayoutType')
          ? ({} as UpcomingPayoutType)
          : anUpcomingPayoutType({}, relationshipsToOmit),
  }
}

export const aFinancePayoutType = (
  overrides?: Partial<FinancePayoutType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FinancePayoutType' } & FinancePayoutType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FinancePayoutType')
  return {
    __typename: 'FinancePayoutType',
    amount:
      overrides && overrides.hasOwnProperty('amount')
        ? overrides.amount!
        : relationshipsToOmit.has('Money')
          ? ({} as Money)
          : aMoney({}, relationshipsToOmit),
    charges:
      overrides && overrides.hasOwnProperty('charges')
        ? overrides.charges!
        : [
            relationshipsToOmit.has('FinanceChargeType')
              ? ({} as FinanceChargeType)
              : aFinanceChargeType({}, relationshipsToOmit),
          ],
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'sunt delectus veritatis consequatur possimus tempore omnis',
    currentState:
      overrides && overrides.hasOwnProperty('currentState')
        ? overrides.currentState!
        : PayoutStateEnum.blocked,
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '203302c4-3efc-4cf8-a19d-4400be403274',
    paidAt:
      overrides && overrides.hasOwnProperty('paidAt')
        ? overrides.paidAt!
        : 'veritatis nulla sed unde qui voluptates et',
    periodEnd:
      overrides && overrides.hasOwnProperty('periodEnd')
        ? overrides.periodEnd!
        : 'sit perferendis officia aut rerum ducimus nam',
    periodStart:
      overrides && overrides.hasOwnProperty('periodStart')
        ? overrides.periodStart!
        : 'natus fugit a fuga asperiores dolor porro',
    receipt:
      overrides && overrides.hasOwnProperty('receipt')
        ? overrides.receipt!
        : relationshipsToOmit.has('Blob')
          ? ({} as Blob)
          : aBlob({}, relationshipsToOmit),
    reference: overrides && overrides.hasOwnProperty('reference') ? overrides.reference! : 'veritatis',
  }
}

export const aFinanceTestModePayInvoicePayload = (
  overrides?: Partial<FinanceTestModePayInvoicePayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FinanceTestModePayInvoicePayload' } & FinanceTestModePayInvoicePayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FinanceTestModePayInvoicePayload')
  return {
    __typename: 'FinanceTestModePayInvoicePayload',
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : true,
  }
}

export const aFinlandMarketDetails = (
  overrides?: Partial<FinlandMarketDetails>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FinlandMarketDetails' } & FinlandMarketDetails => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FinlandMarketDetails')
  return {
    __typename: 'FinlandMarketDetails',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '96bf6c62-7195-4ef5-a800-edf707126e92',
    minimumStayMonths:
      overrides && overrides.hasOwnProperty('minimumStayMonths') ? overrides.minimumStayMonths! : 8328,
    rentIndexation:
      overrides && overrides.hasOwnProperty('rentIndexation')
        ? overrides.rentIndexation!
        : relationshipsToOmit.has('FinlandRentIndexationType')
          ? ({} as FinlandRentIndexationType)
          : aFinlandRentIndexationType({}, relationshipsToOmit),
  }
}

export const aFinlandMarketDetailsInput = (
  overrides?: Partial<FinlandMarketDetailsInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): FinlandMarketDetailsInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FinlandMarketDetailsInput')
  return {
    minimumStayMonths:
      overrides && overrides.hasOwnProperty('minimumStayMonths') ? overrides.minimumStayMonths! : 6457,
    rentIndexation:
      overrides && overrides.hasOwnProperty('rentIndexation')
        ? overrides.rentIndexation!
        : relationshipsToOmit.has('FinlandRentIndexationInput')
          ? ({} as FinlandRentIndexationInput)
          : aFinlandRentIndexationInput({}, relationshipsToOmit),
  }
}

export const aFinlandRentIndexationInput = (
  overrides?: Partial<FinlandRentIndexationInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): FinlandRentIndexationInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FinlandRentIndexationInput')
  return {
    fixedPercentage:
      overrides && overrides.hasOwnProperty('fixedPercentage') ? overrides.fixedPercentage! : 1.39,
    indexationOption:
      overrides && overrides.hasOwnProperty('indexationOption')
        ? overrides.indexationOption!
        : FinlandRentIndexationOptionEnum.consumer_price_index,
  }
}

export const aFinlandRentIndexationType = (
  overrides?: Partial<FinlandRentIndexationType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FinlandRentIndexationType' } & FinlandRentIndexationType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FinlandRentIndexationType')
  return {
    __typename: 'FinlandRentIndexationType',
    fixedPercentage:
      overrides && overrides.hasOwnProperty('fixedPercentage') ? overrides.fixedPercentage! : 1.14,
    indexationOption:
      overrides && overrides.hasOwnProperty('indexationOption')
        ? overrides.indexationOption!
        : FinlandRentIndexationOptionEnum.consumer_price_index,
  }
}

export const aFinlandUpsertContractInput = (
  overrides?: Partial<FinlandUpsertContractInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): FinlandUpsertContractInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FinlandUpsertContractInput')
  return {
    acceptsCosignatures:
      overrides && overrides.hasOwnProperty('acceptsCosignatures') ? overrides.acceptsCosignatures! : true,
    apartmentNumber:
      overrides && overrides.hasOwnProperty('apartmentNumber') ? overrides.apartmentNumber! : 'reiciendis',
    canUse: overrides && overrides.hasOwnProperty('canUse') ? overrides.canUse! : ['harum'],
    duration:
      overrides && overrides.hasOwnProperty('duration')
        ? overrides.duration!
        : relationshipsToOmit.has('CreateDurationInput')
          ? ({} as CreateDurationInput)
          : aCreateDurationInput({}, relationshipsToOmit),
    electricityFee:
      overrides && overrides.hasOwnProperty('electricityFee')
        ? overrides.electricityFee!
        : relationshipsToOmit.has('ElectricityFeeInput')
          ? ({} as ElectricityFeeInput)
          : anElectricityFeeInput({}, relationshipsToOmit),
    furniture: overrides && overrides.hasOwnProperty('furniture') ? overrides.furniture! : true,
    homeId:
      overrides && overrides.hasOwnProperty('homeId')
        ? overrides.homeId!
        : '12c2cc53-90c9-4c63-aace-62acedf8a4ac',
    insuranceResponsibility:
      overrides && overrides.hasOwnProperty('insuranceResponsibility')
        ? overrides.insuranceResponsibility!
        : InsuranceResponsibilityEnum.insurance_responsibility_landlord,
    inventory: overrides && overrides.hasOwnProperty('inventory') ? overrides.inventory! : ['necessitatibus'],
    landlordHasKeys:
      overrides && overrides.hasOwnProperty('landlordHasKeys') ? overrides.landlordHasKeys! : true,
    marketDetails:
      overrides && overrides.hasOwnProperty('marketDetails')
        ? overrides.marketDetails!
        : relationshipsToOmit.has('FinlandMarketDetailsInput')
          ? ({} as FinlandMarketDetailsInput)
          : aFinlandMarketDetailsInput({}, relationshipsToOmit),
    numberOfTenants:
      overrides && overrides.hasOwnProperty('numberOfTenants') ? overrides.numberOfTenants! : 6905,
    paymentDay: overrides && overrides.hasOwnProperty('paymentDay') ? overrides.paymentDay! : 1201,
    pets: overrides && overrides.hasOwnProperty('pets') ? overrides.pets! : false,
    qasaGuarantee: overrides && overrides.hasOwnProperty('qasaGuarantee') ? overrides.qasaGuarantee! : true,
    rent: overrides && overrides.hasOwnProperty('rent') ? overrides.rent! : 5829,
    rentExcludes:
      overrides && overrides.hasOwnProperty('rentExcludes') ? overrides.rentExcludes! : ['facere'],
    rentIncludes:
      overrides && overrides.hasOwnProperty('rentIncludes') ? overrides.rentIncludes! : ['excepturi'],
    smoker: overrides && overrides.hasOwnProperty('smoker') ? overrides.smoker! : false,
    tenantUid:
      overrides && overrides.hasOwnProperty('tenantUid')
        ? overrides.tenantUid!
        : '939f7f04-894b-4807-8991-e7411f618056',
    waterFeePerTenant:
      overrides && overrides.hasOwnProperty('waterFeePerTenant') ? overrides.waterFeePerTenant! : 2673,
  }
}

export const aFinlandUpsertContractPayload = (
  overrides?: Partial<FinlandUpsertContractPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FinlandUpsertContractPayload' } & FinlandUpsertContractPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FinlandUpsertContractPayload')
  return {
    __typename: 'FinlandUpsertContractPayload',
    contract:
      overrides && overrides.hasOwnProperty('contract')
        ? overrides.contract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const aFranceCancelContractDraftPayload = (
  overrides?: Partial<FranceCancelContractDraftPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FranceCancelContractDraftPayload' } & FranceCancelContractDraftPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceCancelContractDraftPayload')
  return {
    __typename: 'FranceCancelContractDraftPayload',
    contract:
      overrides && overrides.hasOwnProperty('contract')
        ? overrides.contract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const aFranceCellarInput = (
  overrides?: Partial<FranceCellarInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): FranceCellarInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceCellarInput')
  return {
    available: overrides && overrides.hasOwnProperty('available') ? overrides.available! : false,
    number: overrides && overrides.hasOwnProperty('number') ? overrides.number! : 'iste',
  }
}

export const aFranceCellarType = (
  overrides?: Partial<FranceCellarType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FranceCellarType' } & FranceCellarType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceCellarType')
  return {
    __typename: 'FranceCellarType',
    available: overrides && overrides.hasOwnProperty('available') ? overrides.available! : true,
    number: overrides && overrides.hasOwnProperty('number') ? overrides.number! : 'ab',
  }
}

export const aFranceCreateExternalContractTakeoverPayload = (
  overrides?: Partial<FranceCreateExternalContractTakeoverPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): {
  __typename: 'FranceCreateExternalContractTakeoverPayload'
} & FranceCreateExternalContractTakeoverPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceCreateExternalContractTakeoverPayload')
  return {
    __typename: 'FranceCreateExternalContractTakeoverPayload',
    contract:
      overrides && overrides.hasOwnProperty('contract')
        ? overrides.contract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const aFranceCreateInsuranceRequestInput = (
  overrides?: Partial<FranceCreateInsuranceRequestInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): FranceCreateInsuranceRequestInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceCreateInsuranceRequestInput')
  return {
    guarantor:
      overrides && overrides.hasOwnProperty('guarantor')
        ? overrides.guarantor!
        : relationshipsToOmit.has('FranceGuarantorInput')
          ? ({} as FranceGuarantorInput)
          : aFranceGuarantorInput({}, relationshipsToOmit),
    tenantAdditionalIncomes:
      overrides && overrides.hasOwnProperty('tenantAdditionalIncomes')
        ? overrides.tenantAdditionalIncomes!
        : [FranceTenantAdditionalIncomeEnum.aah],
    tenantProfessionalSituation:
      overrides && overrides.hasOwnProperty('tenantProfessionalSituation')
        ? overrides.tenantProfessionalSituation!
        : FranceTenantProfessionalSituationEnum.fixed_term_employee,
  }
}

export const aFranceCreateInsuranceRequestPayload = (
  overrides?: Partial<FranceCreateInsuranceRequestPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FranceCreateInsuranceRequestPayload' } & FranceCreateInsuranceRequestPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceCreateInsuranceRequestPayload')
  return {
    __typename: 'FranceCreateInsuranceRequestPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    insuranceRequest:
      overrides && overrides.hasOwnProperty('insuranceRequest')
        ? overrides.insuranceRequest!
        : relationshipsToOmit.has('FranceInsuranceRequestType')
          ? ({} as FranceInsuranceRequestType)
          : aFranceInsuranceRequestType({}, relationshipsToOmit),
  }
}

export const aFranceDocumentRequestType = (
  overrides?: Partial<FranceDocumentRequestType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FranceDocumentRequestType' } & FranceDocumentRequestType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceDocumentRequestType')
  return {
    __typename: 'FranceDocumentRequestType',
    concernedBusinessRole:
      overrides && overrides.hasOwnProperty('concernedBusinessRole')
        ? overrides.concernedBusinessRole!
        : FranceDocumentRequestConcernedBusinessRoleEnum.owner,
    documentDescription:
      overrides && overrides.hasOwnProperty('documentDescription') ? overrides.documentDescription! : 'eum',
    documentType:
      overrides && overrides.hasOwnProperty('documentType')
        ? overrides.documentType!
        : FranceDocumentRequestTypeEnum.fr_2_derniers_bilans,
    documents:
      overrides && overrides.hasOwnProperty('documents')
        ? overrides.documents!
        : [relationshipsToOmit.has('Blob') ? ({} as Blob) : aBlob({}, relationshipsToOmit)],
    evaluatorComment:
      overrides && overrides.hasOwnProperty('evaluatorComment') ? overrides.evaluatorComment! : 'pariatur',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '99a3009b-c529-49f4-9c69-75535dccde27',
    status:
      overrides && overrides.hasOwnProperty('status')
        ? overrides.status!
        : FranceDocumentRequestStatusEnum.accepted,
  }
}

export const aFranceDurationInput = (
  overrides?: Partial<FranceDurationInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): FranceDurationInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceDurationInput')
  return {
    shortened: overrides && overrides.hasOwnProperty('shortened') ? overrides.shortened! : true,
    shortenedEndDate:
      overrides && overrides.hasOwnProperty('shortenedEndDate')
        ? overrides.shortenedEndDate!
        : 'amet consequatur placeat et sit voluptas natus',
    shortenedJustification:
      overrides && overrides.hasOwnProperty('shortenedJustification')
        ? overrides.shortenedJustification!
        : 'exercitationem',
    startDate:
      overrides && overrides.hasOwnProperty('startDate')
        ? overrides.startDate!
        : 'sint ipsum qui aut sed dolor provident',
  }
}

export const aFranceDurationType = (
  overrides?: Partial<FranceDurationType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FranceDurationType' } & FranceDurationType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceDurationType')
  return {
    __typename: 'FranceDurationType',
    shortened: overrides && overrides.hasOwnProperty('shortened') ? overrides.shortened! : false,
    shortenedEndDate:
      overrides && overrides.hasOwnProperty('shortenedEndDate')
        ? overrides.shortenedEndDate!
        : 'atque id delectus perspiciatis optio possimus ut',
    shortenedJustification:
      overrides && overrides.hasOwnProperty('shortenedJustification')
        ? overrides.shortenedJustification!
        : 'quae',
    startDate:
      overrides && overrides.hasOwnProperty('startDate')
        ? overrides.startDate!
        : 'molestiae quod neque atque et sunt sed',
  }
}

export const aFranceEquipmentInput = (
  overrides?: Partial<FranceEquipmentInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): FranceEquipmentInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceEquipmentInput')
  return {
    available: overrides && overrides.hasOwnProperty('available') ? overrides.available! : false,
    name:
      overrides && overrides.hasOwnProperty('name') ? overrides.name! : FranceEquipmentEnum.air_conditioning,
  }
}

export const aFranceEquipmentType = (
  overrides?: Partial<FranceEquipmentType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FranceEquipmentType' } & FranceEquipmentType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceEquipmentType')
  return {
    __typename: 'FranceEquipmentType',
    available: overrides && overrides.hasOwnProperty('available') ? overrides.available! : false,
    name:
      overrides && overrides.hasOwnProperty('name') ? overrides.name! : FranceEquipmentEnum.air_conditioning,
  }
}

export const aFranceExternalContractTakeoverDurationInput = (
  overrides?: Partial<FranceExternalContractTakeoverDurationInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): FranceExternalContractTakeoverDurationInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceExternalContractTakeoverDurationInput')
  return {
    shortenedEndDate:
      overrides && overrides.hasOwnProperty('shortenedEndDate')
        ? overrides.shortenedEndDate!
        : 'exercitationem quis asperiores provident dolorum quo repudiandae',
    startDate:
      overrides && overrides.hasOwnProperty('startDate')
        ? overrides.startDate!
        : 'sint dolor blanditiis voluptatem placeat veritatis rerum',
  }
}

export const aFranceFacilityInput = (
  overrides?: Partial<FranceFacilityInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): FranceFacilityInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceFacilityInput')
  return {
    available: overrides && overrides.hasOwnProperty('available') ? overrides.available! : false,
    name: overrides && overrides.hasOwnProperty('name') ? overrides.name! : FranceFacilityEnum.attic,
  }
}

export const aFranceFacilityType = (
  overrides?: Partial<FranceFacilityType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FranceFacilityType' } & FranceFacilityType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceFacilityType')
  return {
    __typename: 'FranceFacilityType',
    available: overrides && overrides.hasOwnProperty('available') ? overrides.available! : false,
    name: overrides && overrides.hasOwnProperty('name') ? overrides.name! : FranceFacilityEnum.attic,
  }
}

export const aFranceGuarantorInput = (
  overrides?: Partial<FranceGuarantorInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): FranceGuarantorInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceGuarantorInput')
  return {
    familyName: overrides && overrides.hasOwnProperty('familyName') ? overrides.familyName! : 'fugit',
    firstName: overrides && overrides.hasOwnProperty('firstName') ? overrides.firstName! : 'recusandae',
    professionalSituation:
      overrides && overrides.hasOwnProperty('professionalSituation')
        ? overrides.professionalSituation!
        : FranceGuarantorProfessionalSituationEnum.fixed_term_employee,
  }
}

export const aFranceGuarantorType = (
  overrides?: Partial<FranceGuarantorType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FranceGuarantorType' } & FranceGuarantorType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceGuarantorType')
  return {
    __typename: 'FranceGuarantorType',
    familyName: overrides && overrides.hasOwnProperty('familyName') ? overrides.familyName! : 'ut',
    firstName: overrides && overrides.hasOwnProperty('firstName') ? overrides.firstName! : 'quo',
    professionalSituation:
      overrides && overrides.hasOwnProperty('professionalSituation')
        ? overrides.professionalSituation!
        : FranceGuarantorProfessionalSituationEnum.fixed_term_employee,
  }
}

export const aFranceHeatingProductionInput = (
  overrides?: Partial<FranceHeatingProductionInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): FranceHeatingProductionInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceHeatingProductionInput')
  return {
    description: overrides && overrides.hasOwnProperty('description') ? overrides.description! : 'et',
    mode:
      overrides && overrides.hasOwnProperty('mode')
        ? overrides.mode!
        : FranceHeatingProductionEnum.collective,
  }
}

export const aFranceHeatingProductionType = (
  overrides?: Partial<FranceHeatingProductionType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FranceHeatingProductionType' } & FranceHeatingProductionType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceHeatingProductionType')
  return {
    __typename: 'FranceHeatingProductionType',
    description: overrides && overrides.hasOwnProperty('description') ? overrides.description! : 'aut',
    mode:
      overrides && overrides.hasOwnProperty('mode')
        ? overrides.mode!
        : FranceHeatingProductionEnum.collective,
  }
}

export const aFranceHotWaterProductionInput = (
  overrides?: Partial<FranceHotWaterProductionInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): FranceHotWaterProductionInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceHotWaterProductionInput')
  return {
    description: overrides && overrides.hasOwnProperty('description') ? overrides.description! : 'quia',
    mode:
      overrides && overrides.hasOwnProperty('mode')
        ? overrides.mode!
        : FranceHotWaterProductionEnum.collective,
  }
}

export const aFranceHotWaterProductionType = (
  overrides?: Partial<FranceHotWaterProductionType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FranceHotWaterProductionType' } & FranceHotWaterProductionType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceHotWaterProductionType')
  return {
    __typename: 'FranceHotWaterProductionType',
    description: overrides && overrides.hasOwnProperty('description') ? overrides.description! : 'non',
    mode:
      overrides && overrides.hasOwnProperty('mode')
        ? overrides.mode!
        : FranceHotWaterProductionEnum.collective,
  }
}

export const aFranceInitiateContractSigningPayload = (
  overrides?: Partial<FranceInitiateContractSigningPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FranceInitiateContractSigningPayload' } & FranceInitiateContractSigningPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceInitiateContractSigningPayload')
  return {
    __typename: 'FranceInitiateContractSigningPayload',
    contract:
      overrides && overrides.hasOwnProperty('contract')
        ? overrides.contract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const aFranceInsuranceRequestType = (
  overrides?: Partial<FranceInsuranceRequestType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FranceInsuranceRequestType' } & FranceInsuranceRequestType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceInsuranceRequestType')
  return {
    __typename: 'FranceInsuranceRequestType',
    currentState:
      overrides && overrides.hasOwnProperty('currentState')
        ? overrides.currentState!
        : FranceInsuranceRequestStateEnum.added_to_policy,
    documentRequests:
      overrides && overrides.hasOwnProperty('documentRequests')
        ? overrides.documentRequests!
        : [
            relationshipsToOmit.has('FranceDocumentRequestType')
              ? ({} as FranceDocumentRequestType)
              : aFranceDocumentRequestType({}, relationshipsToOmit),
          ],
    guarantor:
      overrides && overrides.hasOwnProperty('guarantor')
        ? overrides.guarantor!
        : relationshipsToOmit.has('FranceGuarantorType')
          ? ({} as FranceGuarantorType)
          : aFranceGuarantorType({}, relationshipsToOmit),
    guarantorDocumentRequests:
      overrides && overrides.hasOwnProperty('guarantorDocumentRequests')
        ? overrides.guarantorDocumentRequests!
        : [
            relationshipsToOmit.has('FranceDocumentRequestType')
              ? ({} as FranceDocumentRequestType)
              : aFranceDocumentRequestType({}, relationshipsToOmit),
          ],
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'ff2fa7e2-3410-4116-aebd-b97b0647d2b5',
    milaId: overrides && overrides.hasOwnProperty('milaId') ? overrides.milaId! : 'quisquam',
    tenantAdditionalIncomes:
      overrides && overrides.hasOwnProperty('tenantAdditionalIncomes')
        ? overrides.tenantAdditionalIncomes!
        : [FranceTenantAdditionalIncomeEnum.aah],
    tenantDocumentRequests:
      overrides && overrides.hasOwnProperty('tenantDocumentRequests')
        ? overrides.tenantDocumentRequests!
        : [
            relationshipsToOmit.has('FranceDocumentRequestType')
              ? ({} as FranceDocumentRequestType)
              : aFranceDocumentRequestType({}, relationshipsToOmit),
          ],
    tenantProfessionalSituation:
      overrides && overrides.hasOwnProperty('tenantProfessionalSituation')
        ? overrides.tenantProfessionalSituation!
        : FranceTenantProfessionalSituationEnum.fixed_term_employee,
  }
}

export const aFranceInternetConnectionInput = (
  overrides?: Partial<FranceInternetConnectionInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): FranceInternetConnectionInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceInternetConnectionInput')
  return {
    available: overrides && overrides.hasOwnProperty('available') ? overrides.available! : true,
  }
}

export const aFranceInternetConnectionType = (
  overrides?: Partial<FranceInternetConnectionType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FranceInternetConnectionType' } & FranceInternetConnectionType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceInternetConnectionType')
  return {
    __typename: 'FranceInternetConnectionType',
    available: overrides && overrides.hasOwnProperty('available') ? overrides.available! : false,
  }
}

export const aFranceLegalEntityDetailsInput = (
  overrides?: Partial<FranceLegalEntityDetailsInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): FranceLegalEntityDetailsInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceLegalEntityDetailsInput')
  return {
    name: overrides && overrides.hasOwnProperty('name') ? overrides.name! : 'sed',
    siretNumber: overrides && overrides.hasOwnProperty('siretNumber') ? overrides.siretNumber! : 'a',
    type:
      overrides && overrides.hasOwnProperty('type') ? overrides.type! : FranceLegalEntityTypeEnum.family_sci,
  }
}

export const aFranceLegalEntityDetailsType = (
  overrides?: Partial<FranceLegalEntityDetailsType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FranceLegalEntityDetailsType' } & FranceLegalEntityDetailsType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceLegalEntityDetailsType')
  return {
    __typename: 'FranceLegalEntityDetailsType',
    name: overrides && overrides.hasOwnProperty('name') ? overrides.name! : 'eos',
    siretNumber: overrides && overrides.hasOwnProperty('siretNumber') ? overrides.siretNumber! : 'eos',
    type:
      overrides && overrides.hasOwnProperty('type') ? overrides.type! : FranceLegalEntityTypeEnum.family_sci,
  }
}

export const aFranceMarketDetails = (
  overrides?: Partial<FranceMarketDetails>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FranceMarketDetails' } & FranceMarketDetails => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceMarketDetails')
  return {
    __typename: 'FranceMarketDetails',
    buildYear: overrides && overrides.hasOwnProperty('buildYear') ? overrides.buildYear! : 6092,
    cellar:
      overrides && overrides.hasOwnProperty('cellar')
        ? overrides.cellar!
        : relationshipsToOmit.has('FranceCellarType')
          ? ({} as FranceCellarType)
          : aFranceCellarType({}, relationshipsToOmit),
    constructionPeriod:
      overrides && overrides.hasOwnProperty('constructionPeriod')
        ? overrides.constructionPeriod!
        : FranceConstructionPeriodEnum.after_1990,
    depositMonths: overrides && overrides.hasOwnProperty('depositMonths') ? overrides.depositMonths! : 7437,
    duration:
      overrides && overrides.hasOwnProperty('duration')
        ? overrides.duration!
        : relationshipsToOmit.has('FranceDurationType')
          ? ({} as FranceDurationType)
          : aFranceDurationType({}, relationshipsToOmit),
    equipment:
      overrides && overrides.hasOwnProperty('equipment')
        ? overrides.equipment!
        : [
            relationshipsToOmit.has('FranceEquipmentType')
              ? ({} as FranceEquipmentType)
              : aFranceEquipmentType({}, relationshipsToOmit),
          ],
    facilities:
      overrides && overrides.hasOwnProperty('facilities')
        ? overrides.facilities!
        : [
            relationshipsToOmit.has('FranceFacilityType')
              ? ({} as FranceFacilityType)
              : aFranceFacilityType({}, relationshipsToOmit),
          ],
    fiscalIdNumber:
      overrides && overrides.hasOwnProperty('fiscalIdNumber') ? overrides.fiscalIdNumber! : 'consequatur',
    heatingProduction:
      overrides && overrides.hasOwnProperty('heatingProduction')
        ? overrides.heatingProduction!
        : relationshipsToOmit.has('FranceHeatingProductionType')
          ? ({} as FranceHeatingProductionType)
          : aFranceHeatingProductionType({}, relationshipsToOmit),
    hotWaterProduction:
      overrides && overrides.hasOwnProperty('hotWaterProduction')
        ? overrides.hotWaterProduction!
        : relationshipsToOmit.has('FranceHotWaterProductionType')
          ? ({} as FranceHotWaterProductionType)
          : aFranceHotWaterProductionType({}, relationshipsToOmit),
    housingType:
      overrides && overrides.hasOwnProperty('housingType')
        ? overrides.housingType!
        : FranceHousingTypeEnum.collective,
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'a023145b-78dd-4bbf-8643-1cd2e552fcfc',
    internetConnection:
      overrides && overrides.hasOwnProperty('internetConnection')
        ? overrides.internetConnection!
        : relationshipsToOmit.has('FranceInternetConnectionType')
          ? ({} as FranceInternetConnectionType)
          : aFranceInternetConnectionType({}, relationshipsToOmit),
    landlordAddress:
      overrides && overrides.hasOwnProperty('landlordAddress') ? overrides.landlordAddress! : 'repellendus',
    landlordType:
      overrides && overrides.hasOwnProperty('landlordType')
        ? overrides.landlordType!
        : FranceLandlordTypeEnum.legal_entity,
    legalEntityDetails:
      overrides && overrides.hasOwnProperty('legalEntityDetails')
        ? overrides.legalEntityDetails!
        : relationshipsToOmit.has('FranceLegalEntityDetailsType')
          ? ({} as FranceLegalEntityDetailsType)
          : aFranceLegalEntityDetailsType({}, relationshipsToOmit),
    otherOwners:
      overrides && overrides.hasOwnProperty('otherOwners')
        ? overrides.otherOwners!
        : [
            relationshipsToOmit.has('FranceOtherOwnerType')
              ? ({} as FranceOtherOwnerType)
              : aFranceOtherOwnerType({}, relationshipsToOmit),
          ],
    ownershipType:
      overrides && overrides.hasOwnProperty('ownershipType')
        ? overrides.ownershipType!
        : FranceOwnershipEnum.joint,
    parking:
      overrides && overrides.hasOwnProperty('parking')
        ? overrides.parking!
        : relationshipsToOmit.has('FranceParkingType')
          ? ({} as FranceParkingType)
          : aFranceParkingType({}, relationshipsToOmit),
    previousRental:
      overrides && overrides.hasOwnProperty('previousRental')
        ? overrides.previousRental!
        : relationshipsToOmit.has('FrancePreviousRentalType')
          ? ({} as FrancePreviousRentalType)
          : aFrancePreviousRentalType({}, relationshipsToOmit),
    privateGarage:
      overrides && overrides.hasOwnProperty('privateGarage')
        ? overrides.privateGarage!
        : relationshipsToOmit.has('FrancePrivateGarageType')
          ? ({} as FrancePrivateGarageType)
          : aFrancePrivateGarageType({}, relationshipsToOmit),
    privateGarden:
      overrides && overrides.hasOwnProperty('privateGarden')
        ? overrides.privateGarden!
        : relationshipsToOmit.has('FrancePrivateGardenType')
          ? ({} as FrancePrivateGardenType)
          : aFrancePrivateGardenType({}, relationshipsToOmit),
    recoverableCharges:
      overrides && overrides.hasOwnProperty('recoverableCharges')
        ? overrides.recoverableCharges!
        : relationshipsToOmit.has('FranceRecoverableChargesType')
          ? ({} as FranceRecoverableChargesType)
          : aFranceRecoverableChargesType({}, relationshipsToOmit),
    rentControl:
      overrides && overrides.hasOwnProperty('rentControl')
        ? overrides.rentControl!
        : relationshipsToOmit.has('FranceRentControlType')
          ? ({} as FranceRentControlType)
          : aFranceRentControlType({}, relationshipsToOmit),
    tenantInvitationEmail:
      overrides && overrides.hasOwnProperty('tenantInvitationEmail')
        ? overrides.tenantInvitationEmail!
        : 'voluptatem',
    tenantInvitationName:
      overrides && overrides.hasOwnProperty('tenantInvitationName')
        ? overrides.tenantInvitationName!
        : 'quaerat',
    tvConnection:
      overrides && overrides.hasOwnProperty('tvConnection')
        ? overrides.tvConnection!
        : relationshipsToOmit.has('FranceTvConnectionType')
          ? ({} as FranceTvConnectionType)
          : aFranceTvConnectionType({}, relationshipsToOmit),
  }
}

export const aFranceMarketDetailsInput = (
  overrides?: Partial<FranceMarketDetailsInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): FranceMarketDetailsInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceMarketDetailsInput')
  return {
    buildYear: overrides && overrides.hasOwnProperty('buildYear') ? overrides.buildYear! : 2364,
    cellar:
      overrides && overrides.hasOwnProperty('cellar')
        ? overrides.cellar!
        : relationshipsToOmit.has('FranceCellarInput')
          ? ({} as FranceCellarInput)
          : aFranceCellarInput({}, relationshipsToOmit),
    constructionPeriod:
      overrides && overrides.hasOwnProperty('constructionPeriod')
        ? overrides.constructionPeriod!
        : FranceConstructionPeriodEnum.after_1990,
    depositMonths: overrides && overrides.hasOwnProperty('depositMonths') ? overrides.depositMonths! : 3706,
    duration:
      overrides && overrides.hasOwnProperty('duration')
        ? overrides.duration!
        : relationshipsToOmit.has('FranceDurationInput')
          ? ({} as FranceDurationInput)
          : aFranceDurationInput({}, relationshipsToOmit),
    equipment:
      overrides && overrides.hasOwnProperty('equipment')
        ? overrides.equipment!
        : [
            relationshipsToOmit.has('FranceEquipmentInput')
              ? ({} as FranceEquipmentInput)
              : aFranceEquipmentInput({}, relationshipsToOmit),
          ],
    facilities:
      overrides && overrides.hasOwnProperty('facilities')
        ? overrides.facilities!
        : [
            relationshipsToOmit.has('FranceFacilityInput')
              ? ({} as FranceFacilityInput)
              : aFranceFacilityInput({}, relationshipsToOmit),
          ],
    fiscalIdNumber:
      overrides && overrides.hasOwnProperty('fiscalIdNumber') ? overrides.fiscalIdNumber! : 'ipsam',
    heatingProduction:
      overrides && overrides.hasOwnProperty('heatingProduction')
        ? overrides.heatingProduction!
        : relationshipsToOmit.has('FranceHeatingProductionInput')
          ? ({} as FranceHeatingProductionInput)
          : aFranceHeatingProductionInput({}, relationshipsToOmit),
    hotWaterProduction:
      overrides && overrides.hasOwnProperty('hotWaterProduction')
        ? overrides.hotWaterProduction!
        : relationshipsToOmit.has('FranceHotWaterProductionInput')
          ? ({} as FranceHotWaterProductionInput)
          : aFranceHotWaterProductionInput({}, relationshipsToOmit),
    housingType:
      overrides && overrides.hasOwnProperty('housingType')
        ? overrides.housingType!
        : FranceHousingTypeEnum.collective,
    internetConnection:
      overrides && overrides.hasOwnProperty('internetConnection')
        ? overrides.internetConnection!
        : relationshipsToOmit.has('FranceInternetConnectionInput')
          ? ({} as FranceInternetConnectionInput)
          : aFranceInternetConnectionInput({}, relationshipsToOmit),
    landlordAddress:
      overrides && overrides.hasOwnProperty('landlordAddress') ? overrides.landlordAddress! : 'aut',
    landlordType:
      overrides && overrides.hasOwnProperty('landlordType')
        ? overrides.landlordType!
        : FranceLandlordTypeEnum.legal_entity,
    legalEntityDetails:
      overrides && overrides.hasOwnProperty('legalEntityDetails')
        ? overrides.legalEntityDetails!
        : relationshipsToOmit.has('FranceLegalEntityDetailsInput')
          ? ({} as FranceLegalEntityDetailsInput)
          : aFranceLegalEntityDetailsInput({}, relationshipsToOmit),
    otherOwners:
      overrides && overrides.hasOwnProperty('otherOwners')
        ? overrides.otherOwners!
        : [
            relationshipsToOmit.has('FranceOtherOwnerInput')
              ? ({} as FranceOtherOwnerInput)
              : aFranceOtherOwnerInput({}, relationshipsToOmit),
          ],
    ownershipType:
      overrides && overrides.hasOwnProperty('ownershipType')
        ? overrides.ownershipType!
        : FranceOwnershipEnum.joint,
    parking:
      overrides && overrides.hasOwnProperty('parking')
        ? overrides.parking!
        : relationshipsToOmit.has('FranceParkingInput')
          ? ({} as FranceParkingInput)
          : aFranceParkingInput({}, relationshipsToOmit),
    previousRental:
      overrides && overrides.hasOwnProperty('previousRental')
        ? overrides.previousRental!
        : relationshipsToOmit.has('FrancePreviousRentalInput')
          ? ({} as FrancePreviousRentalInput)
          : aFrancePreviousRentalInput({}, relationshipsToOmit),
    privateGarage:
      overrides && overrides.hasOwnProperty('privateGarage')
        ? overrides.privateGarage!
        : relationshipsToOmit.has('FrancePrivateGarageInput')
          ? ({} as FrancePrivateGarageInput)
          : aFrancePrivateGarageInput({}, relationshipsToOmit),
    privateGarden:
      overrides && overrides.hasOwnProperty('privateGarden')
        ? overrides.privateGarden!
        : relationshipsToOmit.has('FrancePrivateGardenInput')
          ? ({} as FrancePrivateGardenInput)
          : aFrancePrivateGardenInput({}, relationshipsToOmit),
    recoverableCharges:
      overrides && overrides.hasOwnProperty('recoverableCharges')
        ? overrides.recoverableCharges!
        : relationshipsToOmit.has('FranceRecoverableChargesInput')
          ? ({} as FranceRecoverableChargesInput)
          : aFranceRecoverableChargesInput({}, relationshipsToOmit),
    rentControl:
      overrides && overrides.hasOwnProperty('rentControl')
        ? overrides.rentControl!
        : relationshipsToOmit.has('FranceRentControlInput')
          ? ({} as FranceRentControlInput)
          : aFranceRentControlInput({}, relationshipsToOmit),
    tenantInvitationEmail:
      overrides && overrides.hasOwnProperty('tenantInvitationEmail')
        ? overrides.tenantInvitationEmail!
        : 'occaecati',
    tenantInvitationName:
      overrides && overrides.hasOwnProperty('tenantInvitationName')
        ? overrides.tenantInvitationName!
        : 'cumque',
    tvConnection:
      overrides && overrides.hasOwnProperty('tvConnection')
        ? overrides.tvConnection!
        : relationshipsToOmit.has('FranceTvConnectionInput')
          ? ({} as FranceTvConnectionInput)
          : aFranceTvConnectionInput({}, relationshipsToOmit),
  }
}

export const aFranceOtherOwnerInput = (
  overrides?: Partial<FranceOtherOwnerInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): FranceOtherOwnerInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceOtherOwnerInput')
  return {
    familyName: overrides && overrides.hasOwnProperty('familyName') ? overrides.familyName! : 'earum',
    firstName: overrides && overrides.hasOwnProperty('firstName') ? overrides.firstName! : 'eos',
  }
}

export const aFranceOtherOwnerType = (
  overrides?: Partial<FranceOtherOwnerType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FranceOtherOwnerType' } & FranceOtherOwnerType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceOtherOwnerType')
  return {
    __typename: 'FranceOtherOwnerType',
    familyName: overrides && overrides.hasOwnProperty('familyName') ? overrides.familyName! : 'aspernatur',
    firstName: overrides && overrides.hasOwnProperty('firstName') ? overrides.firstName! : 'voluptas',
  }
}

export const aFranceParkingInput = (
  overrides?: Partial<FranceParkingInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): FranceParkingInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceParkingInput')
  return {
    available: overrides && overrides.hasOwnProperty('available') ? overrides.available! : false,
    number: overrides && overrides.hasOwnProperty('number') ? overrides.number! : 'assumenda',
  }
}

export const aFranceParkingType = (
  overrides?: Partial<FranceParkingType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FranceParkingType' } & FranceParkingType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceParkingType')
  return {
    __typename: 'FranceParkingType',
    available: overrides && overrides.hasOwnProperty('available') ? overrides.available! : true,
    number: overrides && overrides.hasOwnProperty('number') ? overrides.number! : 'ipsa',
  }
}

export const aFrancePreviousRentalInput = (
  overrides?: Partial<FrancePreviousRentalInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): FrancePreviousRentalInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FrancePreviousRentalInput')
  return {
    increasedRentJustification:
      overrides && overrides.hasOwnProperty('increasedRentJustification')
        ? overrides.increasedRentJustification!
        : 'temporibus',
    lastRent: overrides && overrides.hasOwnProperty('lastRent') ? overrides.lastRent! : 5412,
    lastRentPaidAt:
      overrides && overrides.hasOwnProperty('lastRentPaidAt')
        ? overrides.lastRentPaidAt!
        : 'dolor est reprehenderit neque voluptates ratione voluptatum',
    lastRentReviewedAt:
      overrides && overrides.hasOwnProperty('lastRentReviewedAt')
        ? overrides.lastRentReviewedAt!
        : 'totam debitis illum quod autem delectus minus',
    rentedOutRecently:
      overrides && overrides.hasOwnProperty('rentedOutRecently') ? overrides.rentedOutRecently! : false,
  }
}

export const aFrancePreviousRentalType = (
  overrides?: Partial<FrancePreviousRentalType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FrancePreviousRentalType' } & FrancePreviousRentalType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FrancePreviousRentalType')
  return {
    __typename: 'FrancePreviousRentalType',
    increasedRentJustification:
      overrides && overrides.hasOwnProperty('increasedRentJustification')
        ? overrides.increasedRentJustification!
        : 'porro',
    lastRent: overrides && overrides.hasOwnProperty('lastRent') ? overrides.lastRent! : 1571,
    lastRentPaidAt:
      overrides && overrides.hasOwnProperty('lastRentPaidAt')
        ? overrides.lastRentPaidAt!
        : 'qui enim qui illum velit odit dolor',
    lastRentReviewedAt:
      overrides && overrides.hasOwnProperty('lastRentReviewedAt')
        ? overrides.lastRentReviewedAt!
        : 'vitae qui quo ipsum sed molestias aspernatur',
    rentedOutRecently:
      overrides && overrides.hasOwnProperty('rentedOutRecently') ? overrides.rentedOutRecently! : false,
  }
}

export const aFrancePrivateGarageInput = (
  overrides?: Partial<FrancePrivateGarageInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): FrancePrivateGarageInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FrancePrivateGarageInput')
  return {
    available: overrides && overrides.hasOwnProperty('available') ? overrides.available! : false,
    number: overrides && overrides.hasOwnProperty('number') ? overrides.number! : 'porro',
  }
}

export const aFrancePrivateGarageType = (
  overrides?: Partial<FrancePrivateGarageType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FrancePrivateGarageType' } & FrancePrivateGarageType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FrancePrivateGarageType')
  return {
    __typename: 'FrancePrivateGarageType',
    available: overrides && overrides.hasOwnProperty('available') ? overrides.available! : true,
    number: overrides && overrides.hasOwnProperty('number') ? overrides.number! : 'quidem',
  }
}

export const aFrancePrivateGardenInput = (
  overrides?: Partial<FrancePrivateGardenInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): FrancePrivateGardenInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FrancePrivateGardenInput')
  return {
    available: overrides && overrides.hasOwnProperty('available') ? overrides.available! : false,
    squareMeters: overrides && overrides.hasOwnProperty('squareMeters') ? overrides.squareMeters! : 9485,
  }
}

export const aFrancePrivateGardenType = (
  overrides?: Partial<FrancePrivateGardenType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FrancePrivateGardenType' } & FrancePrivateGardenType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FrancePrivateGardenType')
  return {
    __typename: 'FrancePrivateGardenType',
    available: overrides && overrides.hasOwnProperty('available') ? overrides.available! : true,
    squareMeters: overrides && overrides.hasOwnProperty('squareMeters') ? overrides.squareMeters! : 2021,
  }
}

export const aFranceRecoverableChargesInput = (
  overrides?: Partial<FranceRecoverableChargesInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): FranceRecoverableChargesInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceRecoverableChargesInput')
  return {
    monthlyCost: overrides && overrides.hasOwnProperty('monthlyCost') ? overrides.monthlyCost! : 5914,
    paymentPlan:
      overrides && overrides.hasOwnProperty('paymentPlan')
        ? overrides.paymentPlan!
        : FranceRecoverableChargesPaymentPlanEnum.fixed,
  }
}

export const aFranceRecoverableChargesType = (
  overrides?: Partial<FranceRecoverableChargesType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FranceRecoverableChargesType' } & FranceRecoverableChargesType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceRecoverableChargesType')
  return {
    __typename: 'FranceRecoverableChargesType',
    monthlyCost: overrides && overrides.hasOwnProperty('monthlyCost') ? overrides.monthlyCost! : 7078,
    paymentPlan:
      overrides && overrides.hasOwnProperty('paymentPlan')
        ? overrides.paymentPlan!
        : FranceRecoverableChargesPaymentPlanEnum.fixed,
  }
}

export const aFranceRentControlInput = (
  overrides?: Partial<FranceRentControlInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): FranceRentControlInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceRentControlInput')
  return {
    increasedReferenceRent:
      overrides && overrides.hasOwnProperty('increasedReferenceRent')
        ? overrides.increasedReferenceRent!
        : 6.92,
    increasedRentJustification:
      overrides && overrides.hasOwnProperty('increasedRentJustification')
        ? overrides.increasedRentJustification!
        : 'aliquid',
    locatedInTenseArea:
      overrides && overrides.hasOwnProperty('locatedInTenseArea') ? overrides.locatedInTenseArea! : true,
    referenceRent: overrides && overrides.hasOwnProperty('referenceRent') ? overrides.referenceRent! : 6.2,
  }
}

export const aFranceRentControlType = (
  overrides?: Partial<FranceRentControlType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FranceRentControlType' } & FranceRentControlType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceRentControlType')
  return {
    __typename: 'FranceRentControlType',
    increasedReferenceRent:
      overrides && overrides.hasOwnProperty('increasedReferenceRent')
        ? overrides.increasedReferenceRent!
        : 8.44,
    increasedRentJustification:
      overrides && overrides.hasOwnProperty('increasedRentJustification')
        ? overrides.increasedRentJustification!
        : 'sint',
    locatedInTenseArea:
      overrides && overrides.hasOwnProperty('locatedInTenseArea') ? overrides.locatedInTenseArea! : true,
    referenceRent: overrides && overrides.hasOwnProperty('referenceRent') ? overrides.referenceRent! : 3.67,
  }
}

export const aFranceTvConnectionInput = (
  overrides?: Partial<FranceTvConnectionInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): FranceTvConnectionInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceTvConnectionInput')
  return {
    available: overrides && overrides.hasOwnProperty('available') ? overrides.available! : true,
  }
}

export const aFranceTvConnectionType = (
  overrides?: Partial<FranceTvConnectionType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FranceTVConnectionType' } & FranceTvConnectionType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceTvConnectionType')
  return {
    __typename: 'FranceTVConnectionType',
    available: overrides && overrides.hasOwnProperty('available') ? overrides.available! : false,
  }
}

export const aFranceTransitionInsuranceRequestPayload = (
  overrides?: Partial<FranceTransitionInsuranceRequestPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FranceTransitionInsuranceRequestPayload' } & FranceTransitionInsuranceRequestPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceTransitionInsuranceRequestPayload')
  return {
    __typename: 'FranceTransitionInsuranceRequestPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    insuranceRequest:
      overrides && overrides.hasOwnProperty('insuranceRequest')
        ? overrides.insuranceRequest!
        : relationshipsToOmit.has('FranceInsuranceRequestType')
          ? ({} as FranceInsuranceRequestType)
          : aFranceInsuranceRequestType({}, relationshipsToOmit),
  }
}

export const aFranceUpdateDocumentRequestInput = (
  overrides?: Partial<FranceUpdateDocumentRequestInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): FranceUpdateDocumentRequestInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceUpdateDocumentRequestInput')
  return {
    documents:
      overrides && overrides.hasOwnProperty('documents')
        ? overrides.documents!
        : ['353c0b75-325f-4439-bc3a-e51397c33209'],
  }
}

export const aFranceUpdateDocumentRequestPayload = (
  overrides?: Partial<FranceUpdateDocumentRequestPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FranceUpdateDocumentRequestPayload' } & FranceUpdateDocumentRequestPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceUpdateDocumentRequestPayload')
  return {
    __typename: 'FranceUpdateDocumentRequestPayload',
    documentRequest:
      overrides && overrides.hasOwnProperty('documentRequest')
        ? overrides.documentRequest!
        : relationshipsToOmit.has('FranceDocumentRequestType')
          ? ({} as FranceDocumentRequestType)
          : aFranceDocumentRequestType({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const aFranceUpsertContractInput = (
  overrides?: Partial<FranceUpsertContractInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): FranceUpsertContractInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceUpsertContractInput')
  return {
    furniture: overrides && overrides.hasOwnProperty('furniture') ? overrides.furniture! : true,
    homeId:
      overrides && overrides.hasOwnProperty('homeId')
        ? overrides.homeId!
        : 'b2b34a16-ae49-4428-9697-8faed12e7455',
    inventoryLists:
      overrides && overrides.hasOwnProperty('inventoryLists')
        ? overrides.inventoryLists!
        : ['fbc65ff1-6eb8-4ffb-b233-4b410d349e68'],
    marketDetails:
      overrides && overrides.hasOwnProperty('marketDetails')
        ? overrides.marketDetails!
        : relationshipsToOmit.has('FranceMarketDetailsInput')
          ? ({} as FranceMarketDetailsInput)
          : aFranceMarketDetailsInput({}, relationshipsToOmit),
    otherLandlordDocuments:
      overrides && overrides.hasOwnProperty('otherLandlordDocuments')
        ? overrides.otherLandlordDocuments!
        : ['6236f9cb-02f4-4aae-a116-741150cd7c61'],
    rent: overrides && overrides.hasOwnProperty('rent') ? overrides.rent! : 4767,
    technicalDiagnoses:
      overrides && overrides.hasOwnProperty('technicalDiagnoses')
        ? overrides.technicalDiagnoses!
        : ['36aed6f1-24ed-449d-81d0-57dc40495f45'],
    tenantUid:
      overrides && overrides.hasOwnProperty('tenantUid')
        ? overrides.tenantUid!
        : '9f55ca2d-5d14-4e54-889c-56d63c9fdf06',
  }
}

export const aFranceUpsertContractPayload = (
  overrides?: Partial<FranceUpsertContractPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'FranceUpsertContractPayload' } & FranceUpsertContractPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('FranceUpsertContractPayload')
  return {
    __typename: 'FranceUpsertContractPayload',
    contract:
      overrides && overrides.hasOwnProperty('contract')
        ? overrides.contract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const aGeoPoint = (
  overrides?: Partial<GeoPoint>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'GeoPoint' } & GeoPoint => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('GeoPoint')
  return {
    __typename: 'GeoPoint',
    lat: overrides && overrides.hasOwnProperty('lat') ? overrides.lat! : 2.31,
    lon: overrides && overrides.hasOwnProperty('lon') ? overrides.lon! : 3.85,
  }
}

export const aHome = (
  overrides?: Partial<Home>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Home' } & Home => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Home')
  return {
    __typename: 'Home',
    acceptedOfferingsCount:
      overrides && overrides.hasOwnProperty('acceptedOfferingsCount')
        ? overrides.acceptedOfferingsCount!
        : 4680,
    activeContract:
      overrides && overrides.hasOwnProperty('activeContract')
        ? overrides.activeContract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    adPaidAt:
      overrides && overrides.hasOwnProperty('adPaidAt')
        ? overrides.adPaidAt!
        : 'non sunt nam est est id fugiat',
    adPayment:
      overrides && overrides.hasOwnProperty('adPayment')
        ? overrides.adPayment!
        : relationshipsToOmit.has('StripePayment')
          ? ({} as StripePayment)
          : aStripePayment({}, relationshipsToOmit),
    affiliateCode: overrides && overrides.hasOwnProperty('affiliateCode') ? overrides.affiliateCode! : 'eum',
    apartmentNumber:
      overrides && overrides.hasOwnProperty('apartmentNumber') ? overrides.apartmentNumber! : 'est',
    applicationBoardCardInfo:
      overrides && overrides.hasOwnProperty('applicationBoardCardInfo')
        ? overrides.applicationBoardCardInfo!
        : relationshipsToOmit.has('ApplicationBoardCardInfo')
          ? ({} as ApplicationBoardCardInfo)
          : anApplicationBoardCardInfo({}, relationshipsToOmit),
    applicationBoardLists:
      overrides && overrides.hasOwnProperty('applicationBoardLists')
        ? overrides.applicationBoardLists!
        : [
            relationshipsToOmit.has('ApplicationBoardList')
              ? ({} as ApplicationBoardList)
              : anApplicationBoardList({}, relationshipsToOmit),
          ],
    applicationDeadline:
      overrides && overrides.hasOwnProperty('applicationDeadline')
        ? overrides.applicationDeadline!
        : 'ut laudantium dolores magnam cum non quas',
    applicationForecast:
      overrides && overrides.hasOwnProperty('applicationForecast') ? overrides.applicationForecast! : 3992,
    applicationSelectionType:
      overrides && overrides.hasOwnProperty('applicationSelectionType')
        ? overrides.applicationSelectionType!
        : ApplicationSelectionTypeEnum.automatic,
    applicationSortingType:
      overrides && overrides.hasOwnProperty('applicationSortingType')
        ? overrides.applicationSortingType!
        : ApplicationSortingTypeEnum.queue_points,
    applicationStats:
      overrides && overrides.hasOwnProperty('applicationStats')
        ? overrides.applicationStats!
        : relationshipsToOmit.has('HomeApplicationStats')
          ? ({} as HomeApplicationStats)
          : aHomeApplicationStats({}, relationshipsToOmit),
    applicationTimeSeries:
      overrides && overrides.hasOwnProperty('applicationTimeSeries')
        ? overrides.applicationTimeSeries!
        : relationshipsToOmit.has('ApplicationTimeSeries')
          ? ({} as ApplicationTimeSeries)
          : anApplicationTimeSeries({}, relationshipsToOmit),
    archiveReason:
      overrides && overrides.hasOwnProperty('archiveReason')
        ? overrides.archiveReason!
        : HomeArchiveReasonEnum.accidental_publish,
    archivedBecauseOfApplicationDeadlinePassed:
      overrides && overrides.hasOwnProperty('archivedBecauseOfApplicationDeadlinePassed')
        ? overrides.archivedBecauseOfApplicationDeadlinePassed!
        : false,
    averagePricePerNight:
      overrides && overrides.hasOwnProperty('averagePricePerNight') ? overrides.averagePricePerNight! : 7790,
    bathroomRenovationYear:
      overrides && overrides.hasOwnProperty('bathroomRenovationYear')
        ? overrides.bathroomRenovationYear!
        : 458,
    bedCount: overrides && overrides.hasOwnProperty('bedCount') ? overrides.bedCount! : 7275,
    bedroomCount: overrides && overrides.hasOwnProperty('bedroomCount') ? overrides.bedroomCount! : 551,
    buildYear: overrides && overrides.hasOwnProperty('buildYear') ? overrides.buildYear! : 8323,
    buildingFloors:
      overrides && overrides.hasOwnProperty('buildingFloors') ? overrides.buildingFloors! : 5596,
    canBeDeleted: overrides && overrides.hasOwnProperty('canBeDeleted') ? overrides.canBeDeleted! : false,
    canBePublished:
      overrides && overrides.hasOwnProperty('canBePublished') ? overrides.canBePublished! : false,
    cancellationOptions:
      overrides && overrides.hasOwnProperty('cancellationOptions')
        ? overrides.cancellationOptions!
        : [
            relationshipsToOmit.has('CancellationPolicy')
              ? ({} as CancellationPolicy)
              : aCancellationPolicy({}, relationshipsToOmit),
          ],
    cleaningFee: overrides && overrides.hasOwnProperty('cleaningFee') ? overrides.cleaningFee! : 6367,
    condition:
      overrides && overrides.hasOwnProperty('condition') ? overrides.condition! : HomeConditionEnum.good,
    contractTemplate:
      overrides && overrides.hasOwnProperty('contractTemplate')
        ? overrides.contractTemplate!
        : relationshipsToOmit.has('ContractTemplate')
          ? ({} as ContractTemplate)
          : aContractTemplate({}, relationshipsToOmit),
    conversations:
      overrides && overrides.hasOwnProperty('conversations')
        ? overrides.conversations!
        : relationshipsToOmit.has('ConversationConnection')
          ? ({} as ConversationConnection)
          : aConversationConnection({}, relationshipsToOmit),
    corporateHome: overrides && overrides.hasOwnProperty('corporateHome') ? overrides.corporateHome! : true,
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'voluptates facere unde explicabo cum odio consequatur',
    currency: overrides && overrides.hasOwnProperty('currency') ? overrides.currency! : CurrencyEnum.EUR,
    currentCancellationPolicy:
      overrides && overrides.hasOwnProperty('currentCancellationPolicy')
        ? overrides.currentCancellationPolicy!
        : relationshipsToOmit.has('CancellationPolicy')
          ? ({} as CancellationPolicy)
          : aCancellationPolicy({}, relationshipsToOmit),
    dashboardStatus:
      overrides && overrides.hasOwnProperty('dashboardStatus')
        ? overrides.dashboardStatus!
        : HomeDashboardStatusEnum.archived,
    description: overrides && overrides.hasOwnProperty('description') ? overrides.description! : 'culpa',
    descriptionBuilding:
      overrides && overrides.hasOwnProperty('descriptionBuilding')
        ? overrides.descriptionBuilding!
        : 'nostrum',
    descriptionContract:
      overrides && overrides.hasOwnProperty('descriptionContract') ? overrides.descriptionContract! : 'natus',
    descriptionFeatures:
      overrides && overrides.hasOwnProperty('descriptionFeatures')
        ? overrides.descriptionFeatures!
        : 'magnam',
    descriptionLayout:
      overrides && overrides.hasOwnProperty('descriptionLayout') ? overrides.descriptionLayout! : 'facere',
    descriptionTransportation:
      overrides && overrides.hasOwnProperty('descriptionTransportation')
        ? overrides.descriptionTransportation!
        : 'illo',
    displayStreetNumber:
      overrides && overrides.hasOwnProperty('displayStreetNumber') ? overrides.displayStreetNumber! : false,
    duration:
      overrides && overrides.hasOwnProperty('duration')
        ? overrides.duration!
        : relationshipsToOmit.has('Duration')
          ? ({} as Duration)
          : aDuration({}, relationshipsToOmit),
    electricityFee:
      overrides && overrides.hasOwnProperty('electricityFee')
        ? overrides.electricityFee!
        : relationshipsToOmit.has('ElectricityFeeType')
          ? ({} as ElectricityFeeType)
          : anElectricityFeeType({}, relationshipsToOmit),
    energyClass: overrides && overrides.hasOwnProperty('energyClass') ? overrides.energyClass! : 'id',
    expiresAt:
      overrides && overrides.hasOwnProperty('expiresAt')
        ? overrides.expiresAt!
        : 'asperiores aut aspernatur mollitia provident sed non',
    external: overrides && overrides.hasOwnProperty('external') ? overrides.external! : false,
    externalAdId: overrides && overrides.hasOwnProperty('externalAdId') ? overrides.externalAdId! : 8516,
    externalApplicationUrl:
      overrides && overrides.hasOwnProperty('externalApplicationUrl')
        ? overrides.externalApplicationUrl!
        : 'et',
    externalEmail: overrides && overrides.hasOwnProperty('externalEmail') ? overrides.externalEmail! : 'sit',
    externalInfoPage:
      overrides && overrides.hasOwnProperty('externalInfoPage') ? overrides.externalInfoPage! : 'omnis',
    extraCosts:
      overrides && overrides.hasOwnProperty('extraCosts')
        ? overrides.extraCosts!
        : [relationshipsToOmit.has('ExtraCost') ? ({} as ExtraCost) : anExtraCost({}, relationshipsToOmit)],
    favoriteMarkedByUser:
      overrides && overrides.hasOwnProperty('favoriteMarkedByUser') ? overrides.favoriteMarkedByUser! : false,
    favoriteMarkedCount:
      overrides && overrides.hasOwnProperty('favoriteMarkedCount') ? overrides.favoriteMarkedCount! : 2729,
    fee: overrides && overrides.hasOwnProperty('fee') ? overrides.fee! : 2181,
    firsthand: overrides && overrides.hasOwnProperty('firsthand') ? overrides.firsthand! : false,
    floor: overrides && overrides.hasOwnProperty('floor') ? overrides.floor! : 1519,
    handleByAgent: overrides && overrides.hasOwnProperty('handleByAgent') ? overrides.handleByAgent! : true,
    hasKitchen: overrides && overrides.hasOwnProperty('hasKitchen') ? overrides.hasKitchen! : true,
    homeAvailabilitySettings:
      overrides && overrides.hasOwnProperty('homeAvailabilitySettings')
        ? overrides.homeAvailabilitySettings!
        : relationshipsToOmit.has('HomeAvailabilitySettings')
          ? ({} as HomeAvailabilitySettings)
          : aHomeAvailabilitySettings({}, relationshipsToOmit),
    homeTemplates:
      overrides && overrides.hasOwnProperty('homeTemplates')
        ? overrides.homeTemplates!
        : [
            relationshipsToOmit.has('HomeTemplate')
              ? ({} as HomeTemplate)
              : aHomeTemplate({}, relationshipsToOmit),
          ],
    houseRules: overrides && overrides.hasOwnProperty('houseRules') ? overrides.houseRules! : 'quisquam',
    housingAssociation:
      overrides && overrides.hasOwnProperty('housingAssociation') ? overrides.housingAssociation! : 'vitae',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '559865c9-c14c-48ac-ba64-68ba1215b733',
    inspection:
      overrides && overrides.hasOwnProperty('inspection')
        ? overrides.inspection!
        : relationshipsToOmit.has('Inspection')
          ? ({} as Inspection)
          : anInspection({}, relationshipsToOmit),
    instantSignSetting:
      overrides && overrides.hasOwnProperty('instantSignSetting')
        ? overrides.instantSignSetting!
        : relationshipsToOmit.has('InstantSignSetting')
          ? ({} as InstantSignSetting)
          : anInstantSignSetting({}, relationshipsToOmit),
    insurance:
      overrides && overrides.hasOwnProperty('insurance')
        ? overrides.insurance!
        : HomeInsuranceEnum.insurance_landlord,
    insuranceCost: overrides && overrides.hasOwnProperty('insuranceCost') ? overrides.insuranceCost! : 8974,
    kitchenRenovationYear:
      overrides && overrides.hasOwnProperty('kitchenRenovationYear')
        ? overrides.kitchenRenovationYear!
        : 3551,
    landlord:
      overrides && overrides.hasOwnProperty('landlord')
        ? overrides.landlord!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
    landlordBaseFee:
      overrides && overrides.hasOwnProperty('landlordBaseFee') ? overrides.landlordBaseFee! : 6533,
    landlordFees:
      overrides && overrides.hasOwnProperty('landlordFees')
        ? overrides.landlordFees!
        : relationshipsToOmit.has('HomeLandlordFees')
          ? ({} as HomeLandlordFees)
          : aHomeLandlordFees({}, relationshipsToOmit),
    landlordServices:
      overrides && overrides.hasOwnProperty('landlordServices')
        ? overrides.landlordServices!
        : relationshipsToOmit.has('HomeLandlordServices')
          ? ({} as HomeLandlordServices)
          : aHomeLandlordServices({}, relationshipsToOmit),
    landlordUnitId:
      overrides && overrides.hasOwnProperty('landlordUnitId') ? overrides.landlordUnitId! : 'autem',
    lastBumpedAt:
      overrides && overrides.hasOwnProperty('lastBumpedAt')
        ? overrides.lastBumpedAt!
        : 'consequatur sint quidem nam amet sint vel',
    latestViewing:
      overrides && overrides.hasOwnProperty('latestViewing')
        ? overrides.latestViewing!
        : relationshipsToOmit.has('Viewing')
          ? ({} as Viewing)
          : aViewing({}, relationshipsToOmit),
    links:
      overrides && overrides.hasOwnProperty('links')
        ? overrides.links!
        : [relationshipsToOmit.has('Link') ? ({} as Link) : aLink({}, relationshipsToOmit)],
    listingBumpPayment:
      overrides && overrides.hasOwnProperty('listingBumpPayment')
        ? overrides.listingBumpPayment!
        : relationshipsToOmit.has('StripePayment')
          ? ({} as StripePayment)
          : aStripePayment({}, relationshipsToOmit),
    location:
      overrides && overrides.hasOwnProperty('location')
        ? overrides.location!
        : relationshipsToOmit.has('Location')
          ? ({} as Location)
          : aLocation({}, relationshipsToOmit),
    market:
      overrides && overrides.hasOwnProperty('market')
        ? overrides.market!
        : relationshipsToOmit.has('Market')
          ? ({} as Market)
          : aMarket({}, relationshipsToOmit),
    matchingTenantsCount:
      overrides && overrides.hasOwnProperty('matchingTenantsCount') ? overrides.matchingTenantsCount! : 9771,
    matterportEmbedUrl:
      overrides && overrides.hasOwnProperty('matterportEmbedUrl') ? overrides.matterportEmbedUrl! : 'totam',
    maxRent: overrides && overrides.hasOwnProperty('maxRent') ? overrides.maxRent! : 2424,
    maxRoomCount: overrides && overrides.hasOwnProperty('maxRoomCount') ? overrides.maxRoomCount! : 0.78,
    maxSquareMeters:
      overrides && overrides.hasOwnProperty('maxSquareMeters') ? overrides.maxSquareMeters! : 4233,
    maxTenantCount:
      overrides && overrides.hasOwnProperty('maxTenantCount') ? overrides.maxTenantCount! : 1941,
    maximumPricePerNight:
      overrides && overrides.hasOwnProperty('maximumPricePerNight') ? overrides.maximumPricePerNight! : 1836,
    messagesCount: overrides && overrides.hasOwnProperty('messagesCount') ? overrides.messagesCount! : 9432,
    minRent: overrides && overrides.hasOwnProperty('minRent') ? overrides.minRent! : 6136,
    minRoomCount: overrides && overrides.hasOwnProperty('minRoomCount') ? overrides.minRoomCount! : 7.34,
    minSquareMeters:
      overrides && overrides.hasOwnProperty('minSquareMeters') ? overrides.minSquareMeters! : 9067,
    minTenantCount:
      overrides && overrides.hasOwnProperty('minTenantCount') ? overrides.minTenantCount! : 5819,
    minimumPricePerNight:
      overrides && overrides.hasOwnProperty('minimumPricePerNight') ? overrides.minimumPricePerNight! : 2987,
    monthlyCostCents:
      overrides && overrides.hasOwnProperty('monthlyCostCents') ? overrides.monthlyCostCents! : 6260,
    numberOfHomes: overrides && overrides.hasOwnProperty('numberOfHomes') ? overrides.numberOfHomes! : 483,
    openForSigning:
      overrides && overrides.hasOwnProperty('openForSigning') ? overrides.openForSigning! : false,
    organization:
      overrides && overrides.hasOwnProperty('organization')
        ? overrides.organization!
        : relationshipsToOmit.has('Organization')
          ? ({} as Organization)
          : anOrganization({}, relationshipsToOmit),
    origin: overrides && overrides.hasOwnProperty('origin') ? overrides.origin! : 'ipsa',
    ownHome: overrides && overrides.hasOwnProperty('ownHome') ? overrides.ownHome! : true,
    pageViews: overrides && overrides.hasOwnProperty('pageViews') ? overrides.pageViews! : 9989,
    partnerReferenceId:
      overrides && overrides.hasOwnProperty('partnerReferenceId') ? overrides.partnerReferenceId! : 'totam',
    pendingOfferingsCount:
      overrides && overrides.hasOwnProperty('pendingOfferingsCount')
        ? overrides.pendingOfferingsCount!
        : 5081,
    pricingModel:
      overrides && overrides.hasOwnProperty('pricingModel')
        ? overrides.pricingModel!
        : relationshipsToOmit.has('PricingModel')
          ? ({} as PricingModel)
          : aPricingModel({}, relationshipsToOmit),
    pricingModelName:
      overrides && overrides.hasOwnProperty('pricingModelName')
        ? overrides.pricingModelName!
        : PricingModelNameEnum.landlord_fees_effective,
    proLandlord: overrides && overrides.hasOwnProperty('proLandlord') ? overrides.proLandlord! : false,
    property:
      overrides && overrides.hasOwnProperty('property')
        ? overrides.property!
        : relationshipsToOmit.has('Property')
          ? ({} as Property)
          : aProperty({}, relationshipsToOmit),
    publishedAt:
      overrides && overrides.hasOwnProperty('publishedAt')
        ? overrides.publishedAt!
        : 'est quia aut omnis perspiciatis qui possimus',
    qasaGuarantee: overrides && overrides.hasOwnProperty('qasaGuarantee') ? overrides.qasaGuarantee! : false,
    qasaGuaranteeCost:
      overrides && overrides.hasOwnProperty('qasaGuaranteeCost') ? overrides.qasaGuaranteeCost! : 8084,
    recommendedRent:
      overrides && overrides.hasOwnProperty('recommendedRent') ? overrides.recommendedRent! : 5573,
    recommendedRentNew:
      overrides && overrides.hasOwnProperty('recommendedRentNew') ? overrides.recommendedRentNew! : 9861,
    rent: overrides && overrides.hasOwnProperty('rent') ? overrides.rent! : 5031,
    rentalRequirement:
      overrides && overrides.hasOwnProperty('rentalRequirement')
        ? overrides.rentalRequirement!
        : relationshipsToOmit.has('RentalRequirement')
          ? ({} as RentalRequirement)
          : aRentalRequirement({}, relationshipsToOmit),
    rentalType:
      overrides && overrides.hasOwnProperty('rentalType')
        ? overrides.rentalType!
        : HomeRentalTypeEnum.long_term,
    responsibleForCleaning:
      overrides && overrides.hasOwnProperty('responsibleForCleaning')
        ? overrides.responsibleForCleaning!
        : ResponsibleForCleaningEnum.landlord,
    roomCount: overrides && overrides.hasOwnProperty('roomCount') ? overrides.roomCount! : 5.06,
    safeRental: overrides && overrides.hasOwnProperty('safeRental') ? overrides.safeRental! : false,
    seniorHome: overrides && overrides.hasOwnProperty('seniorHome') ? overrides.seniorHome! : true,
    shared: overrides && overrides.hasOwnProperty('shared') ? overrides.shared! : true,
    shortcut:
      overrides && overrides.hasOwnProperty('shortcut')
        ? overrides.shortcut!
        : relationshipsToOmit.has('Shortcut')
          ? ({} as Shortcut)
          : aShortcut({}, relationshipsToOmit),
    showtime: overrides && overrides.hasOwnProperty('showtime') ? overrides.showtime! : false,
    simplifiedStatus:
      overrides && overrides.hasOwnProperty('simplifiedStatus')
        ? overrides.simplifiedStatus!
        : SimplifiedHomeStatusEnum.archived,
    squareMeters: overrides && overrides.hasOwnProperty('squareMeters') ? overrides.squareMeters! : 5647,
    status: overrides && overrides.hasOwnProperty('status') ? overrides.status! : HomeStatusEnum.archived,
    studentHome: overrides && overrides.hasOwnProperty('studentHome') ? overrides.studentHome! : true,
    tenantBaseFee: overrides && overrides.hasOwnProperty('tenantBaseFee') ? overrides.tenantBaseFee! : 8116,
    tenantCount: overrides && overrides.hasOwnProperty('tenantCount') ? overrides.tenantCount! : 8883,
    tenantFees:
      overrides && overrides.hasOwnProperty('tenantFees')
        ? overrides.tenantFees!
        : relationshipsToOmit.has('HomeTenantFees')
          ? ({} as HomeTenantFees)
          : aHomeTenantFees({}, relationshipsToOmit),
    tenureType:
      overrides && overrides.hasOwnProperty('tenureType')
        ? overrides.tenureType!
        : TenureTypeEnum.condominium,
    title: overrides && overrides.hasOwnProperty('title') ? overrides.title! : 'architecto',
    toiletCount: overrides && overrides.hasOwnProperty('toiletCount') ? overrides.toiletCount! : 4288,
    traits:
      overrides && overrides.hasOwnProperty('traits')
        ? overrides.traits!
        : [relationshipsToOmit.has('Trait') ? ({} as Trait) : aTrait({}, relationshipsToOmit)],
    translatedDescriptions:
      overrides && overrides.hasOwnProperty('translatedDescriptions')
        ? overrides.translatedDescriptions!
        : [
            relationshipsToOmit.has('TranslatedDescription')
              ? ({} as TranslatedDescription)
              : aTranslatedDescription({}, relationshipsToOmit),
          ],
    type: overrides && overrides.hasOwnProperty('type') ? overrides.type! : HomeTypeEnum.apartment,
    unreadConversationsCount:
      overrides && overrides.hasOwnProperty('unreadConversationsCount')
        ? overrides.unreadConversationsCount!
        : 9805,
    unreadMessagesCount:
      overrides && overrides.hasOwnProperty('unreadMessagesCount') ? overrides.unreadMessagesCount! : 8735,
    updatedAt:
      overrides && overrides.hasOwnProperty('updatedAt')
        ? overrides.updatedAt!
        : 'omnis esse excepturi consectetur omnis odit necessitatibus',
    uploads:
      overrides && overrides.hasOwnProperty('uploads')
        ? overrides.uploads!
        : [relationshipsToOmit.has('Upload') ? ({} as Upload) : anUpload({}, relationshipsToOmit)],
    user:
      overrides && overrides.hasOwnProperty('user')
        ? overrides.user!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
    userApplication:
      overrides && overrides.hasOwnProperty('userApplication')
        ? overrides.userApplication!
        : relationshipsToOmit.has('HomeApplication')
          ? ({} as HomeApplication)
          : aHomeApplication({}, relationshipsToOmit),
    videos:
      overrides && overrides.hasOwnProperty('videos')
        ? overrides.videos!
        : [relationshipsToOmit.has('Blob') ? ({} as Blob) : aBlob({}, relationshipsToOmit)],
    viewingCalendar:
      overrides && overrides.hasOwnProperty('viewingCalendar')
        ? overrides.viewingCalendar!
        : relationshipsToOmit.has('ViewingCalendar')
          ? ({} as ViewingCalendar)
          : aViewingCalendar({}, relationshipsToOmit),
  }
}

export const aHomeApplication = (
  overrides?: Partial<HomeApplication>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'HomeApplication' } & HomeApplication => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('HomeApplication')
  return {
    __typename: 'HomeApplication',
    activeViewing:
      overrides && overrides.hasOwnProperty('activeViewing')
        ? overrides.activeViewing!
        : relationshipsToOmit.has('Viewing')
          ? ({} as Viewing)
          : aViewing({}, relationshipsToOmit),
    applicationBoardListPosition:
      overrides && overrides.hasOwnProperty('applicationBoardListPosition')
        ? overrides.applicationBoardListPosition!
        : 1126,
    contract:
      overrides && overrides.hasOwnProperty('contract')
        ? overrides.contract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    conversation:
      overrides && overrides.hasOwnProperty('conversation')
        ? overrides.conversation!
        : relationshipsToOmit.has('Conversation')
          ? ({} as Conversation)
          : aConversation({}, relationshipsToOmit),
    counterOffer: overrides && overrides.hasOwnProperty('counterOffer') ? overrides.counterOffer! : 6340,
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'et quis deleniti dolorem placeat minus quia',
    currency: overrides && overrides.hasOwnProperty('currency') ? overrides.currency! : 'deserunt',
    endOptimal:
      overrides && overrides.hasOwnProperty('endOptimal')
        ? overrides.endOptimal!
        : 'et molestias odit velit voluptatem accusantium in',
    endUfn: overrides && overrides.hasOwnProperty('endUfn') ? overrides.endUfn! : true,
    home:
      overrides && overrides.hasOwnProperty('home')
        ? overrides.home!
        : relationshipsToOmit.has('Home')
          ? ({} as Home)
          : aHome({}, relationshipsToOmit),
    homeTemplates:
      overrides && overrides.hasOwnProperty('homeTemplates')
        ? overrides.homeTemplates!
        : [
            relationshipsToOmit.has('HomeTemplate')
              ? ({} as HomeTemplate)
              : aHomeTemplate({}, relationshipsToOmit),
          ],
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'aa2ebc24-f5f0-4969-87c3-9651e934394a',
    inContactAt:
      overrides && overrides.hasOwnProperty('inContactAt')
        ? overrides.inContactAt!
        : 'porro fugiat odit repellendus iste a eum',
    matchInterest:
      overrides && overrides.hasOwnProperty('matchInterest') ? overrides.matchInterest! : 'doloremque',
    message: overrides && overrides.hasOwnProperty('message') ? overrides.message! : 'aut',
    offering:
      overrides && overrides.hasOwnProperty('offering')
        ? overrides.offering!
        : relationshipsToOmit.has('Offering')
          ? ({} as Offering)
          : anOffering({}, relationshipsToOmit),
    origin:
      overrides && overrides.hasOwnProperty('origin')
        ? overrides.origin!
        : HomeApplicationOriginEnum.landlord_application,
    seen: overrides && overrides.hasOwnProperty('seen') ? overrides.seen! : true,
    starMarkedByLandlord:
      overrides && overrides.hasOwnProperty('starMarkedByLandlord') ? overrides.starMarkedByLandlord! : false,
    startOptimal:
      overrides && overrides.hasOwnProperty('startOptimal')
        ? overrides.startOptimal!
        : 'et velit inventore minima occaecati nihil nisi',
    status:
      overrides && overrides.hasOwnProperty('status') ? overrides.status! : HomeApplicationStatusEnum.closed,
    tenant:
      overrides && overrides.hasOwnProperty('tenant')
        ? overrides.tenant!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
    tenantEmail: overrides && overrides.hasOwnProperty('tenantEmail') ? overrides.tenantEmail! : 'aut',
    tenantFamilyName:
      overrides && overrides.hasOwnProperty('tenantFamilyName') ? overrides.tenantFamilyName! : 'est',
    tenantIdNumber:
      overrides && overrides.hasOwnProperty('tenantIdNumber') ? overrides.tenantIdNumber! : 'aut',
    tenantPhoneNumber:
      overrides && overrides.hasOwnProperty('tenantPhoneNumber') ? overrides.tenantPhoneNumber! : 'eius',
    updatedAt:
      overrides && overrides.hasOwnProperty('updatedAt')
        ? overrides.updatedAt!
        : 'ipsum et vel voluptatem voluptas mollitia magni',
    userNotes:
      overrides && overrides.hasOwnProperty('userNotes')
        ? overrides.userNotes!
        : [relationshipsToOmit.has('UserNote') ? ({} as UserNote) : aUserNote({}, relationshipsToOmit)],
  }
}

export const aHomeApplicationConnection = (
  overrides?: Partial<HomeApplicationConnection>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'HomeApplicationConnection' } & HomeApplicationConnection => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('HomeApplicationConnection')
  return {
    __typename: 'HomeApplicationConnection',
    edges:
      overrides && overrides.hasOwnProperty('edges')
        ? overrides.edges!
        : [
            relationshipsToOmit.has('HomeApplicationEdge')
              ? ({} as HomeApplicationEdge)
              : aHomeApplicationEdge({}, relationshipsToOmit),
          ],
    nodes:
      overrides && overrides.hasOwnProperty('nodes')
        ? overrides.nodes!
        : [
            relationshipsToOmit.has('HomeApplication')
              ? ({} as HomeApplication)
              : aHomeApplication({}, relationshipsToOmit),
          ],
    pageInfo:
      overrides && overrides.hasOwnProperty('pageInfo')
        ? overrides.pageInfo!
        : relationshipsToOmit.has('PageInfo')
          ? ({} as PageInfo)
          : aPageInfo({}, relationshipsToOmit),
    totalCount: overrides && overrides.hasOwnProperty('totalCount') ? overrides.totalCount! : 3650,
  }
}

export const aHomeApplicationEdge = (
  overrides?: Partial<HomeApplicationEdge>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'HomeApplicationEdge' } & HomeApplicationEdge => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('HomeApplicationEdge')
  return {
    __typename: 'HomeApplicationEdge',
    cursor: overrides && overrides.hasOwnProperty('cursor') ? overrides.cursor! : 'molestias',
    node:
      overrides && overrides.hasOwnProperty('node')
        ? overrides.node!
        : relationshipsToOmit.has('HomeApplication')
          ? ({} as HomeApplication)
          : aHomeApplication({}, relationshipsToOmit),
  }
}

export const aHomeApplicationStats = (
  overrides?: Partial<HomeApplicationStats>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'HomeApplicationStats' } & HomeApplicationStats => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('HomeApplicationStats')
  return {
    __typename: 'HomeApplicationStats',
    applicationCount:
      overrides && overrides.hasOwnProperty('applicationCount') ? overrides.applicationCount! : 6314,
    declinedCount: overrides && overrides.hasOwnProperty('declinedCount') ? overrides.declinedCount! : 3771,
    inContactCount:
      overrides && overrides.hasOwnProperty('inContactCount') ? overrides.inContactCount! : 7624,
    tenantApplicationCount:
      overrides && overrides.hasOwnProperty('tenantApplicationCount')
        ? overrides.tenantApplicationCount!
        : 2505,
  }
}

export const aHomeAvailability = (
  overrides?: Partial<HomeAvailability>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'HomeAvailability' } & HomeAvailability => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('HomeAvailability')
  return {
    __typename: 'HomeAvailability',
    booking:
      overrides && overrides.hasOwnProperty('booking')
        ? overrides.booking!
        : relationshipsToOmit.has('Booking')
          ? ({} as Booking)
          : aBooking({}, relationshipsToOmit),
    date:
      overrides && overrides.hasOwnProperty('date')
        ? overrides.date!
        : 'officia harum repellendus tempora impedit incidunt quae',
    home:
      overrides && overrides.hasOwnProperty('home')
        ? overrides.home!
        : relationshipsToOmit.has('Home')
          ? ({} as Home)
          : aHome({}, relationshipsToOmit),
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '1551ddd8-08c7-46dd-91ab-c9343234fc53',
    price: overrides && overrides.hasOwnProperty('price') ? overrides.price! : 7565,
    status:
      overrides && overrides.hasOwnProperty('status')
        ? overrides.status!
        : HomeAvailabilityStatusEnum.available,
  }
}

export const aHomeAvailabilitySettings = (
  overrides?: Partial<HomeAvailabilitySettings>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'HomeAvailabilitySettings' } & HomeAvailabilitySettings => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('HomeAvailabilitySettings')
  return {
    __typename: 'HomeAvailabilitySettings',
    defaultPricePerNight:
      overrides && overrides.hasOwnProperty('defaultPricePerNight') ? overrides.defaultPricePerNight! : 1774,
    home:
      overrides && overrides.hasOwnProperty('home')
        ? overrides.home!
        : relationshipsToOmit.has('Home')
          ? ({} as Home)
          : aHome({}, relationshipsToOmit),
    minNightsStay: overrides && overrides.hasOwnProperty('minNightsStay') ? overrides.minNightsStay! : 1082,
    minPrice: overrides && overrides.hasOwnProperty('minPrice') ? overrides.minPrice! : 2035,
    noCheckinDays:
      overrides && overrides.hasOwnProperty('noCheckinDays')
        ? overrides.noCheckinDays!
        : [DayOfTheWeekTypeEnum.friday],
    rentOnlyWeekly:
      overrides && overrides.hasOwnProperty('rentOnlyWeekly') ? overrides.rentOnlyWeekly! : false,
  }
}

export const aHomeConnection = (
  overrides?: Partial<HomeConnection>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'HomeConnection' } & HomeConnection => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('HomeConnection')
  return {
    __typename: 'HomeConnection',
    edges:
      overrides && overrides.hasOwnProperty('edges')
        ? overrides.edges!
        : [relationshipsToOmit.has('HomeEdge') ? ({} as HomeEdge) : aHomeEdge({}, relationshipsToOmit)],
    nodes:
      overrides && overrides.hasOwnProperty('nodes')
        ? overrides.nodes!
        : [relationshipsToOmit.has('Home') ? ({} as Home) : aHome({}, relationshipsToOmit)],
    pageInfo:
      overrides && overrides.hasOwnProperty('pageInfo')
        ? overrides.pageInfo!
        : relationshipsToOmit.has('PageInfo')
          ? ({} as PageInfo)
          : aPageInfo({}, relationshipsToOmit),
    totalCount: overrides && overrides.hasOwnProperty('totalCount') ? overrides.totalCount! : 880,
  }
}

export const aHomeCoords = (
  overrides?: Partial<HomeCoords>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'HomeCoords' } & HomeCoords => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('HomeCoords')
  return {
    __typename: 'HomeCoords',
    cost: overrides && overrides.hasOwnProperty('cost') ? overrides.cost! : 3444,
    currency: overrides && overrides.hasOwnProperty('currency') ? overrides.currency! : CurrencyEnum.EUR,
    firsthand: overrides && overrides.hasOwnProperty('firsthand') ? overrides.firsthand! : true,
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '0c1ebc48-2205-44aa-aa15-93fc7b161ea3',
    latitude: overrides && overrides.hasOwnProperty('latitude') ? overrides.latitude! : 'quidem',
    longitude: overrides && overrides.hasOwnProperty('longitude') ? overrides.longitude! : 'minus',
    professional: overrides && overrides.hasOwnProperty('professional') ? overrides.professional! : true,
    rent: overrides && overrides.hasOwnProperty('rent') ? overrides.rent! : 8867,
    tenantBaseFee: overrides && overrides.hasOwnProperty('tenantBaseFee') ? overrides.tenantBaseFee! : 9444,
  }
}

export const aHomeCoordsConnection = (
  overrides?: Partial<HomeCoordsConnection>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'HomeCoordsConnection' } & HomeCoordsConnection => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('HomeCoordsConnection')
  return {
    __typename: 'HomeCoordsConnection',
    edges:
      overrides && overrides.hasOwnProperty('edges')
        ? overrides.edges!
        : [
            relationshipsToOmit.has('HomeCoordsEdge')
              ? ({} as HomeCoordsEdge)
              : aHomeCoordsEdge({}, relationshipsToOmit),
          ],
    nodes:
      overrides && overrides.hasOwnProperty('nodes')
        ? overrides.nodes!
        : [relationshipsToOmit.has('HomeCoords') ? ({} as HomeCoords) : aHomeCoords({}, relationshipsToOmit)],
    pageInfo:
      overrides && overrides.hasOwnProperty('pageInfo')
        ? overrides.pageInfo!
        : relationshipsToOmit.has('PageInfo')
          ? ({} as PageInfo)
          : aPageInfo({}, relationshipsToOmit),
    totalCount: overrides && overrides.hasOwnProperty('totalCount') ? overrides.totalCount! : 3733,
  }
}

export const aHomeCoordsEdge = (
  overrides?: Partial<HomeCoordsEdge>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'HomeCoordsEdge' } & HomeCoordsEdge => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('HomeCoordsEdge')
  return {
    __typename: 'HomeCoordsEdge',
    cursor: overrides && overrides.hasOwnProperty('cursor') ? overrides.cursor! : 'debitis',
    node:
      overrides && overrides.hasOwnProperty('node')
        ? overrides.node!
        : relationshipsToOmit.has('HomeCoords')
          ? ({} as HomeCoords)
          : aHomeCoords({}, relationshipsToOmit),
  }
}

export const aHomeDocument = (
  overrides?: Partial<HomeDocument>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'HomeDocument' } & HomeDocument => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('HomeDocument')
  return {
    __typename: 'HomeDocument',
    bedroomCount: overrides && overrides.hasOwnProperty('bedroomCount') ? overrides.bedroomCount! : 6642,
    blockListing: overrides && overrides.hasOwnProperty('blockListing') ? overrides.blockListing! : false,
    corporateHome: overrides && overrides.hasOwnProperty('corporateHome') ? overrides.corporateHome! : false,
    currency: overrides && overrides.hasOwnProperty('currency') ? overrides.currency! : 'sed',
    description: overrides && overrides.hasOwnProperty('description') ? overrides.description! : 'nostrum',
    displayStreetNumber:
      overrides && overrides.hasOwnProperty('displayStreetNumber') ? overrides.displayStreetNumber! : false,
    endDate:
      overrides && overrides.hasOwnProperty('endDate')
        ? overrides.endDate!
        : 'asperiores praesentium suscipit dolorem et sed rerum',
    firstHand: overrides && overrides.hasOwnProperty('firstHand') ? overrides.firstHand! : true,
    furnished: overrides && overrides.hasOwnProperty('furnished') ? overrides.furnished! : false,
    homeType: overrides && overrides.hasOwnProperty('homeType') ? overrides.homeType! : 'magni',
    householdSize: overrides && overrides.hasOwnProperty('householdSize') ? overrides.householdSize! : 1783,
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '097eb680-13a0-4ec3-929f-d650e0e8340b',
    instantSign: overrides && overrides.hasOwnProperty('instantSign') ? overrides.instantSign! : true,
    landlordUid: overrides && overrides.hasOwnProperty('landlordUid') ? overrides.landlordUid! : 'incidunt',
    lastBumpedAt:
      overrides && overrides.hasOwnProperty('lastBumpedAt')
        ? overrides.lastBumpedAt!
        : 'voluptas magnam eum sit culpa enim aut',
    location:
      overrides && overrides.hasOwnProperty('location')
        ? overrides.location!
        : relationshipsToOmit.has('HomeDocumentLocationType')
          ? ({} as HomeDocumentLocationType)
          : aHomeDocumentLocationType({}, relationshipsToOmit),
    market: overrides && overrides.hasOwnProperty('market') ? overrides.market! : 'omnis',
    monthlyCost: overrides && overrides.hasOwnProperty('monthlyCost') ? overrides.monthlyCost! : 8620,
    monthlyCostCents:
      overrides && overrides.hasOwnProperty('monthlyCostCents') ? overrides.monthlyCostCents! : 653,
    origin: overrides && overrides.hasOwnProperty('origin') ? overrides.origin! : 'est',
    petsAllowed: overrides && overrides.hasOwnProperty('petsAllowed') ? overrides.petsAllowed! : false,
    platform: overrides && overrides.hasOwnProperty('platform') ? overrides.platform! : 'tenetur',
    publishedAt:
      overrides && overrides.hasOwnProperty('publishedAt')
        ? overrides.publishedAt!
        : 'amet id omnis corrupti doloremque sed maiores',
    publishedOrBumpedAt:
      overrides && overrides.hasOwnProperty('publishedOrBumpedAt')
        ? overrides.publishedOrBumpedAt!
        : 'accusamus enim accusamus deserunt labore in dolore',
    rent: overrides && overrides.hasOwnProperty('rent') ? overrides.rent! : 7901,
    rentalLengthSeconds:
      overrides && overrides.hasOwnProperty('rentalLengthSeconds') ? overrides.rentalLengthSeconds! : 5.89,
    roomCount: overrides && overrides.hasOwnProperty('roomCount') ? overrides.roomCount! : 5620,
    seniorHome: overrides && overrides.hasOwnProperty('seniorHome') ? overrides.seniorHome! : true,
    shared: overrides && overrides.hasOwnProperty('shared') ? overrides.shared! : false,
    shortcutHome: overrides && overrides.hasOwnProperty('shortcutHome') ? overrides.shortcutHome! : true,
    smokingAllowed:
      overrides && overrides.hasOwnProperty('smokingAllowed') ? overrides.smokingAllowed! : true,
    sortingScore: overrides && overrides.hasOwnProperty('sortingScore') ? overrides.sortingScore! : 4.64,
    squareMeters: overrides && overrides.hasOwnProperty('squareMeters') ? overrides.squareMeters! : 6597,
    startDate:
      overrides && overrides.hasOwnProperty('startDate')
        ? overrides.startDate!
        : 'qui eaque nobis vitae iusto itaque animi',
    studentHome: overrides && overrides.hasOwnProperty('studentHome') ? overrides.studentHome! : true,
    tenantBaseFee: overrides && overrides.hasOwnProperty('tenantBaseFee') ? overrides.tenantBaseFee! : 1681,
    title: overrides && overrides.hasOwnProperty('title') ? overrides.title! : 'voluptatibus',
    uploads:
      overrides && overrides.hasOwnProperty('uploads')
        ? overrides.uploads!
        : [
            relationshipsToOmit.has('HomeDocumentUploadType')
              ? ({} as HomeDocumentUploadType)
              : aHomeDocumentUploadType({}, relationshipsToOmit),
          ],
    wheelchairAccessible:
      overrides && overrides.hasOwnProperty('wheelchairAccessible') ? overrides.wheelchairAccessible! : true,
  }
}

export const aHomeDocumentLocationType = (
  overrides?: Partial<HomeDocumentLocationType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'HomeDocumentLocationType' } & HomeDocumentLocationType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('HomeDocumentLocationType')
  return {
    __typename: 'HomeDocumentLocationType',
    countryCode: overrides && overrides.hasOwnProperty('countryCode') ? overrides.countryCode! : 'ut',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 2843,
    locality: overrides && overrides.hasOwnProperty('locality') ? overrides.locality! : 'ad',
    point:
      overrides && overrides.hasOwnProperty('point')
        ? overrides.point!
        : relationshipsToOmit.has('GeoPoint')
          ? ({} as GeoPoint)
          : aGeoPoint({}, relationshipsToOmit),
    route: overrides && overrides.hasOwnProperty('route') ? overrides.route! : 'porro',
    streetNumber:
      overrides && overrides.hasOwnProperty('streetNumber') ? overrides.streetNumber! : 'corrupti',
  }
}

export const aHomeDocumentOffsetLimit = (
  overrides?: Partial<HomeDocumentOffsetLimit>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'HomeDocumentOffsetLimit' } & HomeDocumentOffsetLimit => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('HomeDocumentOffsetLimit')
  return {
    __typename: 'HomeDocumentOffsetLimit',
    hasNextPage: overrides && overrides.hasOwnProperty('hasNextPage') ? overrides.hasNextPage! : false,
    hasPreviousPage:
      overrides && overrides.hasOwnProperty('hasPreviousPage') ? overrides.hasPreviousPage! : true,
    nodes:
      overrides && overrides.hasOwnProperty('nodes')
        ? overrides.nodes!
        : [
            relationshipsToOmit.has('HomeDocument')
              ? ({} as HomeDocument)
              : aHomeDocument({}, relationshipsToOmit),
          ],
    pagesCount: overrides && overrides.hasOwnProperty('pagesCount') ? overrides.pagesCount! : 1749,
    totalCount: overrides && overrides.hasOwnProperty('totalCount') ? overrides.totalCount! : 3847,
  }
}

export const aHomeDocumentUploadType = (
  overrides?: Partial<HomeDocumentUploadType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'HomeDocumentUploadType' } & HomeDocumentUploadType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('HomeDocumentUploadType')
  return {
    __typename: 'HomeDocumentUploadType',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 3138,
    order: overrides && overrides.hasOwnProperty('order') ? overrides.order! : 6066,
    type: overrides && overrides.hasOwnProperty('type') ? overrides.type! : 'atque',
    url: overrides && overrides.hasOwnProperty('url') ? overrides.url! : 'autem',
  }
}

export const aHomeEdge = (
  overrides?: Partial<HomeEdge>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'HomeEdge' } & HomeEdge => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('HomeEdge')
  return {
    __typename: 'HomeEdge',
    cursor: overrides && overrides.hasOwnProperty('cursor') ? overrides.cursor! : 'nesciunt',
    node:
      overrides && overrides.hasOwnProperty('node')
        ? overrides.node!
        : relationshipsToOmit.has('Home')
          ? ({} as Home)
          : aHome({}, relationshipsToOmit),
  }
}

export const aHomeEvaluation = (
  overrides?: Partial<HomeEvaluation>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'HomeEvaluation' } & HomeEvaluation => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('HomeEvaluation')
  return {
    __typename: 'HomeEvaluation',
    suggestedDescription:
      overrides && overrides.hasOwnProperty('suggestedDescription')
        ? overrides.suggestedDescription!
        : 'ipsam',
    suggestedImageUrl:
      overrides && overrides.hasOwnProperty('suggestedImageUrl') ? overrides.suggestedImageUrl! : 'facere',
  }
}

export const aHomeIndexSearchOrderInput = (
  overrides?: Partial<HomeIndexSearchOrderInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): HomeIndexSearchOrderInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('HomeIndexSearchOrderInput')
  return {
    direction:
      overrides && overrides.hasOwnProperty('direction')
        ? overrides.direction!
        : HomeIndexSearchOrderDirectionEnum.ascending,
    orderBy:
      overrides && overrides.hasOwnProperty('orderBy')
        ? overrides.orderBy!
        : HomeIndexSearchOrderByEnum.end_date,
  }
}

export const aHomeIndexSearchQuery = (
  overrides?: Partial<HomeIndexSearchQuery>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'HomeIndexSearchQuery' } & HomeIndexSearchQuery => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('HomeIndexSearchQuery')
  return {
    __typename: 'HomeIndexSearchQuery',
    documents:
      overrides && overrides.hasOwnProperty('documents')
        ? overrides.documents!
        : relationshipsToOmit.has('HomeDocumentOffsetLimit')
          ? ({} as HomeDocumentOffsetLimit)
          : aHomeDocumentOffsetLimit({}, relationshipsToOmit),
  }
}

export const aHomeLandlordFees = (
  overrides?: Partial<HomeLandlordFees>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'HomeLandlordFees' } & HomeLandlordFees => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('HomeLandlordFees')
  return {
    __typename: 'HomeLandlordFees',
    base:
      overrides && overrides.hasOwnProperty('base')
        ? overrides.base!
        : relationshipsToOmit.has('Fee')
          ? ({} as Fee)
          : aFee({}, relationshipsToOmit),
    listingBumpFee:
      overrides && overrides.hasOwnProperty('listingBumpFee')
        ? overrides.listingBumpFee!
        : relationshipsToOmit.has('Fee')
          ? ({} as Fee)
          : aFee({}, relationshipsToOmit),
    listingPublicationFee:
      overrides && overrides.hasOwnProperty('listingPublicationFee')
        ? overrides.listingPublicationFee!
        : relationshipsToOmit.has('Fee')
          ? ({} as Fee)
          : aFee({}, relationshipsToOmit),
  }
}

export const aHomeLandlordServices = (
  overrides?: Partial<HomeLandlordServices>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'HomeLandlordServices' } & HomeLandlordServices => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('HomeLandlordServices')
  return {
    __typename: 'HomeLandlordServices',
    insurance:
      overrides && overrides.hasOwnProperty('insurance')
        ? overrides.insurance!
        : relationshipsToOmit.has('Service')
          ? ({} as Service)
          : aService({}, relationshipsToOmit),
    qasaGuarantee:
      overrides && overrides.hasOwnProperty('qasaGuarantee')
        ? overrides.qasaGuarantee!
        : relationshipsToOmit.has('Service')
          ? ({} as Service)
          : aService({}, relationshipsToOmit),
  }
}

export const aHomeOffsetLimit = (
  overrides?: Partial<HomeOffsetLimit>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'HomeOffsetLimit' } & HomeOffsetLimit => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('HomeOffsetLimit')
  return {
    __typename: 'HomeOffsetLimit',
    hasNextPage: overrides && overrides.hasOwnProperty('hasNextPage') ? overrides.hasNextPage! : true,
    hasPreviousPage:
      overrides && overrides.hasOwnProperty('hasPreviousPage') ? overrides.hasPreviousPage! : false,
    nodes:
      overrides && overrides.hasOwnProperty('nodes')
        ? overrides.nodes!
        : [relationshipsToOmit.has('Home') ? ({} as Home) : aHome({}, relationshipsToOmit)],
    pagesCount: overrides && overrides.hasOwnProperty('pagesCount') ? overrides.pagesCount! : 6989,
    totalCount: overrides && overrides.hasOwnProperty('totalCount') ? overrides.totalCount! : 7386,
  }
}

export const aHomeSearch = (
  overrides?: Partial<HomeSearch>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'HomeSearch' } & HomeSearch => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('HomeSearch')
  return {
    __typename: 'HomeSearch',
    filterHomes:
      overrides && overrides.hasOwnProperty('filterHomes')
        ? overrides.filterHomes!
        : relationshipsToOmit.has('HomeConnection')
          ? ({} as HomeConnection)
          : aHomeConnection({}, relationshipsToOmit),
    filterHomesOffset:
      overrides && overrides.hasOwnProperty('filterHomesOffset')
        ? overrides.filterHomesOffset!
        : relationshipsToOmit.has('HomeOffsetLimit')
          ? ({} as HomeOffsetLimit)
          : aHomeOffsetLimit({}, relationshipsToOmit),
    queryId:
      overrides && overrides.hasOwnProperty('queryId')
        ? overrides.queryId!
        : '50f072b6-7cf1-4d23-8539-5efee89e8fe9',
    searchId:
      overrides && overrides.hasOwnProperty('searchId')
        ? overrides.searchId!
        : '2f0d4b5c-ea94-4857-8cb4-807789055b90',
    similarHomes:
      overrides && overrides.hasOwnProperty('similarHomes')
        ? overrides.similarHomes!
        : relationshipsToOmit.has('HomeConnection')
          ? ({} as HomeConnection)
          : aHomeConnection({}, relationshipsToOmit),
  }
}

export const aHomeSearchCoords = (
  overrides?: Partial<HomeSearchCoords>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'HomeSearchCoords' } & HomeSearchCoords => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('HomeSearchCoords')
  return {
    __typename: 'HomeSearchCoords',
    filterHomes:
      overrides && overrides.hasOwnProperty('filterHomes')
        ? overrides.filterHomes!
        : relationshipsToOmit.has('HomeCoordsConnection')
          ? ({} as HomeCoordsConnection)
          : aHomeCoordsConnection({}, relationshipsToOmit),
    filterHomesCount:
      overrides && overrides.hasOwnProperty('filterHomesCount') ? overrides.filterHomesCount! : 2558,
    filterHomesRaw: overrides && overrides.hasOwnProperty('filterHomesRaw') ? overrides.filterHomesRaw! : {},
    similarHomes:
      overrides && overrides.hasOwnProperty('similarHomes')
        ? overrides.similarHomes!
        : relationshipsToOmit.has('HomeCoordsConnection')
          ? ({} as HomeCoordsConnection)
          : aHomeCoordsConnection({}, relationshipsToOmit),
    similarHomesRaw:
      overrides && overrides.hasOwnProperty('similarHomesRaw') ? overrides.similarHomesRaw! : {},
  }
}

export const aHomeSearchParams = (
  overrides?: Partial<HomeSearchParams>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'HomeSearchParams' } & HomeSearchParams => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('HomeSearchParams')
  return {
    __typename: 'HomeSearchParams',
    areaIdentifier:
      overrides && overrides.hasOwnProperty('areaIdentifier')
        ? overrides.areaIdentifier!
        : ['c2df7339-1e65-4d87-9809-13f4ea4ed83f'],
    checkIn:
      overrides && overrides.hasOwnProperty('checkIn')
        ? overrides.checkIn!
        : 'maiores molestiae culpa eum laudantium sequi debitis',
    checkOut:
      overrides && overrides.hasOwnProperty('checkOut')
        ? overrides.checkOut!
        : 'doloribus quae ratione consectetur non laboriosam officia',
    corporateHome: overrides && overrides.hasOwnProperty('corporateHome') ? overrides.corporateHome! : true,
    countryCode: overrides && overrides.hasOwnProperty('countryCode') ? overrides.countryCode! : 'aut',
    currency: overrides && overrides.hasOwnProperty('currency') ? overrides.currency! : 'ad',
    firsthand: overrides && overrides.hasOwnProperty('firsthand') ? overrides.firsthand! : true,
    furniture: overrides && overrides.hasOwnProperty('furniture') ? overrides.furniture! : false,
    hasKitchen: overrides && overrides.hasOwnProperty('hasKitchen') ? overrides.hasKitchen! : true,
    homeType:
      overrides && overrides.hasOwnProperty('homeType') ? overrides.homeType! : [HomeTypeEnum.apartment],
    householdSize: overrides && overrides.hasOwnProperty('householdSize') ? overrides.householdSize! : 5351,
    maxMonthlyCost:
      overrides && overrides.hasOwnProperty('maxMonthlyCost') ? overrides.maxMonthlyCost! : 5316,
    maxPricePerNight:
      overrides && overrides.hasOwnProperty('maxPricePerNight') ? overrides.maxPricePerNight! : 6109,
    maxRentalLength:
      overrides && overrides.hasOwnProperty('maxRentalLength') ? overrides.maxRentalLength! : 9002,
    maxRoomCount: overrides && overrides.hasOwnProperty('maxRoomCount') ? overrides.maxRoomCount! : 5087,
    maxSquareMeters:
      overrides && overrides.hasOwnProperty('maxSquareMeters') ? overrides.maxSquareMeters! : 4337,
    minBedCount: overrides && overrides.hasOwnProperty('minBedCount') ? overrides.minBedCount! : 9019,
    minBedroomCount:
      overrides && overrides.hasOwnProperty('minBedroomCount') ? overrides.minBedroomCount! : 3835,
    minMonthlyCost: overrides && overrides.hasOwnProperty('minMonthlyCost') ? overrides.minMonthlyCost! : 603,
    minPricePerNight:
      overrides && overrides.hasOwnProperty('minPricePerNight') ? overrides.minPricePerNight! : 2036,
    minRentalLength:
      overrides && overrides.hasOwnProperty('minRentalLength') ? overrides.minRentalLength! : 6607,
    minRoomCount: overrides && overrides.hasOwnProperty('minRoomCount') ? overrides.minRoomCount! : 6930,
    minSquareMeters:
      overrides && overrides.hasOwnProperty('minSquareMeters') ? overrides.minSquareMeters! : 5990,
    minToiletCount:
      overrides && overrides.hasOwnProperty('minToiletCount') ? overrides.minToiletCount! : 4935,
    moveInEarliest:
      overrides && overrides.hasOwnProperty('moveInEarliest')
        ? overrides.moveInEarliest!
        : 'voluptas necessitatibus magni enim et vero eos',
    moveInLatest:
      overrides && overrides.hasOwnProperty('moveInLatest')
        ? overrides.moveInLatest!
        : 'voluptatibus explicabo omnis et error qui ad',
    moveOutEarliest:
      overrides && overrides.hasOwnProperty('moveOutEarliest')
        ? overrides.moveOutEarliest!
        : 'et voluptatem quia nam blanditiis et eum',
    moveOutLatest:
      overrides && overrides.hasOwnProperty('moveOutLatest')
        ? overrides.moveOutLatest!
        : 'consequuntur eos qui odit nihil inventore unde',
    pets: overrides && overrides.hasOwnProperty('pets') ? overrides.pets! : false,
    safeRental: overrides && overrides.hasOwnProperty('safeRental') ? overrides.safeRental! : false,
    searchAreas:
      overrides && overrides.hasOwnProperty('searchAreas')
        ? overrides.searchAreas!
        : [relationshipsToOmit.has('SearchArea') ? ({} as SearchArea) : aSearchArea({}, relationshipsToOmit)],
    seniorHome: overrides && overrides.hasOwnProperty('seniorHome') ? overrides.seniorHome! : false,
    shared: overrides && overrides.hasOwnProperty('shared') ? overrides.shared! : false,
    smoker: overrides && overrides.hasOwnProperty('smoker') ? overrides.smoker! : true,
    studentHome: overrides && overrides.hasOwnProperty('studentHome') ? overrides.studentHome! : false,
    traits: overrides && overrides.hasOwnProperty('traits') ? overrides.traits! : [HomeTraitEnum.archipelago],
    user:
      overrides && overrides.hasOwnProperty('user')
        ? overrides.user!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
    wheelchairAccessible:
      overrides && overrides.hasOwnProperty('wheelchairAccessible') ? overrides.wheelchairAccessible! : true,
  }
}

export const aHomeSearchParamsInput = (
  overrides?: Partial<HomeSearchParamsInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): HomeSearchParamsInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('HomeSearchParamsInput')
  return {
    areaGeom:
      overrides && overrides.hasOwnProperty('areaGeom')
        ? overrides.areaGeom!
        : { type: 'MultiPolygon', coordinates: [[[[0, 0]]]] },
    areaId:
      overrides && overrides.hasOwnProperty('areaId')
        ? overrides.areaId!
        : '47478356-7074-4d66-bd9c-ed37ed7290e5',
    areaIdentifier:
      overrides && overrides.hasOwnProperty('areaIdentifier')
        ? overrides.areaIdentifier!
        : ['bbbade65-8ef2-46c1-981f-6cc3813424e0'],
    checkIn:
      overrides && overrides.hasOwnProperty('checkIn')
        ? overrides.checkIn!
        : 'perferendis occaecati aut ut distinctio quibusdam explicabo',
    checkOut:
      overrides && overrides.hasOwnProperty('checkOut')
        ? overrides.checkOut!
        : 'reprehenderit ut voluptates quae repellendus eos voluptatem',
    corporateHome: overrides && overrides.hasOwnProperty('corporateHome') ? overrides.corporateHome! : true,
    countryCode: overrides && overrides.hasOwnProperty('countryCode') ? overrides.countryCode! : 'rerum',
    currency: overrides && overrides.hasOwnProperty('currency') ? overrides.currency! : 'labore',
    firsthand: overrides && overrides.hasOwnProperty('firsthand') ? overrides.firsthand! : true,
    furniture: overrides && overrides.hasOwnProperty('furniture') ? overrides.furniture! : true,
    hasKitchen: overrides && overrides.hasOwnProperty('hasKitchen') ? overrides.hasKitchen! : false,
    homeType:
      overrides && overrides.hasOwnProperty('homeType') ? overrides.homeType! : [HomeTypeEnum.apartment],
    householdSize: overrides && overrides.hasOwnProperty('householdSize') ? overrides.householdSize! : 9530,
    leaseType:
      overrides && overrides.hasOwnProperty('leaseType')
        ? overrides.leaseType!
        : [LeaseTypeEnum.corporate_home],
    market: overrides && overrides.hasOwnProperty('market') ? overrides.market! : MarketNameTypeEnum.finland,
    matchingArea: overrides && overrides.hasOwnProperty('matchingArea') ? overrides.matchingArea! : {},
    matchingAreaMetadata:
      overrides && overrides.hasOwnProperty('matchingAreaMetadata')
        ? overrides.matchingAreaMetadata!
        : [
            relationshipsToOmit.has('MatchingAreaMetadataInput')
              ? ({} as MatchingAreaMetadataInput)
              : aMatchingAreaMetadataInput({}, relationshipsToOmit),
          ],
    maxMonthlyCost:
      overrides && overrides.hasOwnProperty('maxMonthlyCost') ? overrides.maxMonthlyCost! : 3128,
    maxPricePerNight:
      overrides && overrides.hasOwnProperty('maxPricePerNight') ? overrides.maxPricePerNight! : 2638,
    maxRent: overrides && overrides.hasOwnProperty('maxRent') ? overrides.maxRent! : 3822,
    maxRentalLength:
      overrides && overrides.hasOwnProperty('maxRentalLength') ? overrides.maxRentalLength! : 5838,
    maxRoomCount: overrides && overrides.hasOwnProperty('maxRoomCount') ? overrides.maxRoomCount! : 2686,
    maxSquareMeters:
      overrides && overrides.hasOwnProperty('maxSquareMeters') ? overrides.maxSquareMeters! : 8219,
    minBedCount: overrides && overrides.hasOwnProperty('minBedCount') ? overrides.minBedCount! : 8903,
    minBedroomCount:
      overrides && overrides.hasOwnProperty('minBedroomCount') ? overrides.minBedroomCount! : 7145,
    minMonthlyCost:
      overrides && overrides.hasOwnProperty('minMonthlyCost') ? overrides.minMonthlyCost! : 2777,
    minPricePerNight:
      overrides && overrides.hasOwnProperty('minPricePerNight') ? overrides.minPricePerNight! : 2319,
    minRent: overrides && overrides.hasOwnProperty('minRent') ? overrides.minRent! : 1647,
    minRentalLength:
      overrides && overrides.hasOwnProperty('minRentalLength') ? overrides.minRentalLength! : 6442,
    minRoomCount: overrides && overrides.hasOwnProperty('minRoomCount') ? overrides.minRoomCount! : 2893,
    minSquareMeters:
      overrides && overrides.hasOwnProperty('minSquareMeters') ? overrides.minSquareMeters! : 4667,
    minToiletCount:
      overrides && overrides.hasOwnProperty('minToiletCount') ? overrides.minToiletCount! : 9704,
    moveInEarliest:
      overrides && overrides.hasOwnProperty('moveInEarliest')
        ? overrides.moveInEarliest!
        : 'suscipit doloribus repellat eos sit ut nulla',
    moveInLatest:
      overrides && overrides.hasOwnProperty('moveInLatest')
        ? overrides.moveInLatest!
        : 'sint deleniti et facilis atque nisi et',
    moveOutEarliest:
      overrides && overrides.hasOwnProperty('moveOutEarliest')
        ? overrides.moveOutEarliest!
        : 'error inventore sit harum officiis aliquam reprehenderit',
    moveOutLatest:
      overrides && overrides.hasOwnProperty('moveOutLatest')
        ? overrides.moveOutLatest!
        : 'animi est voluptatum officiis est cupiditate dolore',
    pets: overrides && overrides.hasOwnProperty('pets') ? overrides.pets! : true,
    platform: overrides && overrides.hasOwnProperty('platform') ? overrides.platform! : PlatformEnum.blocket,
    rentalType:
      overrides && overrides.hasOwnProperty('rentalType')
        ? overrides.rentalType!
        : [HomeRentalTypeEnum.long_term],
    safeRental: overrides && overrides.hasOwnProperty('safeRental') ? overrides.safeRental! : false,
    seniorHome: overrides && overrides.hasOwnProperty('seniorHome') ? overrides.seniorHome! : false,
    shared: overrides && overrides.hasOwnProperty('shared') ? overrides.shared! : false,
    shortcutHome: overrides && overrides.hasOwnProperty('shortcutHome') ? overrides.shortcutHome! : false,
    smoker: overrides && overrides.hasOwnProperty('smoker') ? overrides.smoker! : false,
    studentHome: overrides && overrides.hasOwnProperty('studentHome') ? overrides.studentHome! : true,
    traits: overrides && overrides.hasOwnProperty('traits') ? overrides.traits! : [HomeTraitEnum.archipelago],
    uids:
      overrides && overrides.hasOwnProperty('uids')
        ? overrides.uids!
        : ['ab4b0d87-7b95-4cc5-9790-77683c640969'],
    wheelchairAccessible:
      overrides && overrides.hasOwnProperty('wheelchairAccessible') ? overrides.wheelchairAccessible! : false,
    withinDistanceFrom:
      overrides && overrides.hasOwnProperty('withinDistanceFrom')
        ? overrides.withinDistanceFrom!
        : relationshipsToOmit.has('WithinDistanceFromInput')
          ? ({} as WithinDistanceFromInput)
          : aWithinDistanceFromInput({}, relationshipsToOmit),
  }
}

export const aHomeStats = (
  overrides?: Partial<HomeStats>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'HomeStats' } & HomeStats => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('HomeStats')
  return {
    __typename: 'HomeStats',
    acceptedOfferingsCount:
      overrides && overrides.hasOwnProperty('acceptedOfferingsCount')
        ? overrides.acceptedOfferingsCount!
        : 9601,
    applicationsCount:
      overrides && overrides.hasOwnProperty('applicationsCount') ? overrides.applicationsCount! : 1866,
    applicationsInContactCount:
      overrides && overrides.hasOwnProperty('applicationsInContactCount')
        ? overrides.applicationsInContactCount!
        : 3928,
    cumulativePageViews:
      overrides && overrides.hasOwnProperty('cumulativePageViews') ? overrides.cumulativePageViews! : [6455],
    cumulativeUniquePageViews:
      overrides && overrides.hasOwnProperty('cumulativeUniquePageViews')
        ? overrides.cumulativeUniquePageViews!
        : [7431],
    declinedOfferingsCount:
      overrides && overrides.hasOwnProperty('declinedOfferingsCount')
        ? overrides.declinedOfferingsCount!
        : 9329,
    offeringsCount: overrides && overrides.hasOwnProperty('offeringsCount') ? overrides.offeringsCount! : 378,
    pageViews: overrides && overrides.hasOwnProperty('pageViews') ? overrides.pageViews! : 8874,
    pendingOfferingsCount:
      overrides && overrides.hasOwnProperty('pendingOfferingsCount')
        ? overrides.pendingOfferingsCount!
        : 5801,
    periods:
      overrides && overrides.hasOwnProperty('periods')
        ? overrides.periods!
        : ['aliquid assumenda non magni dolorem voluptatem incidunt'],
    revokedOfferingsCount:
      overrides && overrides.hasOwnProperty('revokedOfferingsCount')
        ? overrides.revokedOfferingsCount!
        : 7193,
    uniquePageViews:
      overrides && overrides.hasOwnProperty('uniquePageViews') ? overrides.uniquePageViews! : 4242,
  }
}

export const aHomeTemplate = (
  overrides?: Partial<HomeTemplate>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'HomeTemplate' } & HomeTemplate => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('HomeTemplate')
  return {
    __typename: 'HomeTemplate',
    acceptedOfferingsCount:
      overrides && overrides.hasOwnProperty('acceptedOfferingsCount')
        ? overrides.acceptedOfferingsCount!
        : 3135,
    apartmentNumber:
      overrides && overrides.hasOwnProperty('apartmentNumber') ? overrides.apartmentNumber! : 'aliquid',
    bathroomRenovationYear:
      overrides && overrides.hasOwnProperty('bathroomRenovationYear')
        ? overrides.bathroomRenovationYear!
        : 6429,
    building:
      overrides && overrides.hasOwnProperty('building')
        ? overrides.building!
        : relationshipsToOmit.has('Building')
          ? ({} as Building)
          : aBuilding({}, relationshipsToOmit),
    corporateHome: overrides && overrides.hasOwnProperty('corporateHome') ? overrides.corporateHome! : true,
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'est quod dolor qui quaerat consequuntur exercitationem',
    description: overrides && overrides.hasOwnProperty('description') ? overrides.description! : 'veniam',
    firsthand: overrides && overrides.hasOwnProperty('firsthand') ? overrides.firsthand! : true,
    floor: overrides && overrides.hasOwnProperty('floor') ? overrides.floor! : 3160,
    homeLayoutPictures:
      overrides && overrides.hasOwnProperty('homeLayoutPictures')
        ? overrides.homeLayoutPictures!
        : [relationshipsToOmit.has('Upload') ? ({} as Upload) : anUpload({}, relationshipsToOmit)],
    homes:
      overrides && overrides.hasOwnProperty('homes')
        ? overrides.homes!
        : [relationshipsToOmit.has('Home') ? ({} as Home) : aHome({}, relationshipsToOmit)],
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '671da837-77d2-4700-aa5b-fdc90cfc6672',
    kitchenRenovationYear:
      overrides && overrides.hasOwnProperty('kitchenRenovationYear')
        ? overrides.kitchenRenovationYear!
        : 9048,
    landlord:
      overrides && overrides.hasOwnProperty('landlord')
        ? overrides.landlord!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
    location:
      overrides && overrides.hasOwnProperty('location')
        ? overrides.location!
        : relationshipsToOmit.has('Location')
          ? ({} as Location)
          : aLocation({}, relationshipsToOmit),
    organization:
      overrides && overrides.hasOwnProperty('organization')
        ? overrides.organization!
        : relationshipsToOmit.has('Organization')
          ? ({} as Organization)
          : anOrganization({}, relationshipsToOmit),
    pendingOfferingsCount:
      overrides && overrides.hasOwnProperty('pendingOfferingsCount')
        ? overrides.pendingOfferingsCount!
        : 1791,
    publishedHomes:
      overrides && overrides.hasOwnProperty('publishedHomes')
        ? overrides.publishedHomes!
        : [relationshipsToOmit.has('Home') ? ({} as Home) : aHome({}, relationshipsToOmit)],
    rent: overrides && overrides.hasOwnProperty('rent') ? overrides.rent! : 1369,
    roomCount: overrides && overrides.hasOwnProperty('roomCount') ? overrides.roomCount! : 6.42,
    seniorHome: overrides && overrides.hasOwnProperty('seniorHome') ? overrides.seniorHome! : false,
    shared: overrides && overrides.hasOwnProperty('shared') ? overrides.shared! : false,
    squareMeters: overrides && overrides.hasOwnProperty('squareMeters') ? overrides.squareMeters! : 5683,
    studentHome: overrides && overrides.hasOwnProperty('studentHome') ? overrides.studentHome! : false,
    tenantCount: overrides && overrides.hasOwnProperty('tenantCount') ? overrides.tenantCount! : 9272,
    tenureType: overrides && overrides.hasOwnProperty('tenureType') ? overrides.tenureType! : 'ex',
    traits:
      overrides && overrides.hasOwnProperty('traits')
        ? overrides.traits!
        : [relationshipsToOmit.has('Trait') ? ({} as Trait) : aTrait({}, relationshipsToOmit)],
    type: overrides && overrides.hasOwnProperty('type') ? overrides.type! : HomeTypeEnum.apartment,
    updatedAt:
      overrides && overrides.hasOwnProperty('updatedAt')
        ? overrides.updatedAt!
        : 'qui sit nesciunt quia voluptas nihil nemo',
    uploads:
      overrides && overrides.hasOwnProperty('uploads')
        ? overrides.uploads!
        : [relationshipsToOmit.has('Upload') ? ({} as Upload) : anUpload({}, relationshipsToOmit)],
  }
}

export const aHomeTemplateConnection = (
  overrides?: Partial<HomeTemplateConnection>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'HomeTemplateConnection' } & HomeTemplateConnection => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('HomeTemplateConnection')
  return {
    __typename: 'HomeTemplateConnection',
    edges:
      overrides && overrides.hasOwnProperty('edges')
        ? overrides.edges!
        : [
            relationshipsToOmit.has('HomeTemplateEdge')
              ? ({} as HomeTemplateEdge)
              : aHomeTemplateEdge({}, relationshipsToOmit),
          ],
    nodes:
      overrides && overrides.hasOwnProperty('nodes')
        ? overrides.nodes!
        : [
            relationshipsToOmit.has('HomeTemplate')
              ? ({} as HomeTemplate)
              : aHomeTemplate({}, relationshipsToOmit),
          ],
    pageInfo:
      overrides && overrides.hasOwnProperty('pageInfo')
        ? overrides.pageInfo!
        : relationshipsToOmit.has('PageInfo')
          ? ({} as PageInfo)
          : aPageInfo({}, relationshipsToOmit),
    totalCount: overrides && overrides.hasOwnProperty('totalCount') ? overrides.totalCount! : 5850,
  }
}

export const aHomeTemplateEdge = (
  overrides?: Partial<HomeTemplateEdge>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'HomeTemplateEdge' } & HomeTemplateEdge => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('HomeTemplateEdge')
  return {
    __typename: 'HomeTemplateEdge',
    cursor: overrides && overrides.hasOwnProperty('cursor') ? overrides.cursor! : 'mollitia',
    node:
      overrides && overrides.hasOwnProperty('node')
        ? overrides.node!
        : relationshipsToOmit.has('HomeTemplate')
          ? ({} as HomeTemplate)
          : aHomeTemplate({}, relationshipsToOmit),
  }
}

export const aHomeTenantFees = (
  overrides?: Partial<HomeTenantFees>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'HomeTenantFees' } & HomeTenantFees => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('HomeTenantFees')
  return {
    __typename: 'HomeTenantFees',
    base:
      overrides && overrides.hasOwnProperty('base')
        ? overrides.base!
        : relationshipsToOmit.has('Fee')
          ? ({} as Fee)
          : aFee({}, relationshipsToOmit),
    monthlyElectricityFee:
      overrides && overrides.hasOwnProperty('monthlyElectricityFee')
        ? overrides.monthlyElectricityFee!
        : relationshipsToOmit.has('Fee')
          ? ({} as Fee)
          : aFee({}, relationshipsToOmit),
    waterFeePerTenant:
      overrides && overrides.hasOwnProperty('waterFeePerTenant')
        ? overrides.waterFeePerTenant!
        : relationshipsToOmit.has('Fee')
          ? ({} as Fee)
          : aFee({}, relationshipsToOmit),
  }
}

export const aHousingSituation = (
  overrides?: Partial<HousingSituation>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'HousingSituation' } & HousingSituation => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('HousingSituation')
  return {
    __typename: 'HousingSituation',
    agreementType:
      overrides && overrides.hasOwnProperty('agreementType')
        ? overrides.agreementType!
        : HousingSituationAgreementTypeEnum.co_living,
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'maxime quo natus enim natus illum ab',
    externalLandlordReference:
      overrides && overrides.hasOwnProperty('externalLandlordReference')
        ? overrides.externalLandlordReference!
        : 'aut',
    hasReference: overrides && overrides.hasOwnProperty('hasReference') ? overrides.hasReference! : false,
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'dc4a9d27-6b83-46ab-9cb5-9382fdfe3e64',
    landlordCity: overrides && overrides.hasOwnProperty('landlordCity') ? overrides.landlordCity! : 'vitae',
    landlordName: overrides && overrides.hasOwnProperty('landlordName') ? overrides.landlordName! : 'a',
    landlordPhoneNumber:
      overrides && overrides.hasOwnProperty('landlordPhoneNumber') ? overrides.landlordPhoneNumber! : 'et',
    updatedAt:
      overrides && overrides.hasOwnProperty('updatedAt')
        ? overrides.updatedAt!
        : 'voluptates vel recusandae nobis praesentium occaecati nihil',
  }
}

export const aHousingSituationInput = (
  overrides?: Partial<HousingSituationInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): HousingSituationInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('HousingSituationInput')
  return {
    agreementType:
      overrides && overrides.hasOwnProperty('agreementType')
        ? overrides.agreementType!
        : HousingSituationAgreementTypeEnum.co_living,
    externalLandlordReference:
      overrides && overrides.hasOwnProperty('externalLandlordReference')
        ? overrides.externalLandlordReference!
        : 'totam',
    landlordCity: overrides && overrides.hasOwnProperty('landlordCity') ? overrides.landlordCity! : 'enim',
    landlordName: overrides && overrides.hasOwnProperty('landlordName') ? overrides.landlordName! : 'numquam',
    landlordPhoneNumber:
      overrides && overrides.hasOwnProperty('landlordPhoneNumber') ? overrides.landlordPhoneNumber! : 'porro',
  }
}

export const anIdNumberFieldsInput = (
  overrides?: Partial<IdNumberFieldsInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): IdNumberFieldsInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('IdNumberFieldsInput')
  return {
    idNumber: overrides && overrides.hasOwnProperty('idNumber') ? overrides.idNumber! : 'sed',
    idNumberType:
      overrides && overrides.hasOwnProperty('idNumberType')
        ? overrides.idNumberType!
        : IdNumberTypeEnum.finnish_personal_number,
  }
}

export const anIdentification = (
  overrides?: Partial<Identification>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Identification' } & Identification => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Identification')
  return {
    __typename: 'Identification',
    familyName: overrides && overrides.hasOwnProperty('familyName') ? overrides.familyName! : 'sunt',
    firstName: overrides && overrides.hasOwnProperty('firstName') ? overrides.firstName! : 'pariatur',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'b05b68a4-02c9-4bcc-b614-640c43e1de76',
    idNumber: overrides && overrides.hasOwnProperty('idNumber') ? overrides.idNumber! : 'qui',
    identificationStatus:
      overrides && overrides.hasOwnProperty('identificationStatus')
        ? overrides.identificationStatus!
        : IdentificationStatusEnum.accepted,
    identificationType:
      overrides && overrides.hasOwnProperty('identificationType')
        ? overrides.identificationType!
        : IdentificationTypeEnum.fi_tupas,
    identityConfirmed:
      overrides && overrides.hasOwnProperty('identityConfirmed') ? overrides.identityConfirmed! : false,
    identityConfirmedAt:
      overrides && overrides.hasOwnProperty('identityConfirmedAt')
        ? overrides.identityConfirmedAt!
        : 'ullam dolores placeat totam aut aut vel',
  }
}

export const anIdentifyInput = (
  overrides?: Partial<IdentifyInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): IdentifyInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('IdentifyInput')
  return {
    path: overrides && overrides.hasOwnProperty('path') ? overrides.path! : 'vitae',
    redirectHost:
      overrides && overrides.hasOwnProperty('redirectHost') ? overrides.redirectHost! : 'molestiae',
    type: overrides && overrides.hasOwnProperty('type') ? overrides.type! : IdentificationTypeEnum.fi_tupas,
  }
}

export const anIdentifyPayload = (
  overrides?: Partial<IdentifyPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'IdentifyPayload' } & IdentifyPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('IdentifyPayload')
  return {
    __typename: 'IdentifyPayload',
    accessUrl: overrides && overrides.hasOwnProperty('accessUrl') ? overrides.accessUrl! : 'consequatur',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : false,
  }
}

export const anIfInsurancePromotion = (
  overrides?: Partial<IfInsurancePromotion>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'IfInsurancePromotion' } & IfInsurancePromotion => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('IfInsurancePromotion')
  return {
    __typename: 'IfInsurancePromotion',
    signedUp: overrides && overrides.hasOwnProperty('signedUp') ? overrides.signedUp! : false,
  }
}

export const anIfInsurancePromotionSignUpPayload = (
  overrides?: Partial<IfInsurancePromotionSignUpPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'IfInsurancePromotionSignUpPayload' } & IfInsurancePromotionSignUpPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('IfInsurancePromotionSignUpPayload')
  return {
    __typename: 'IfInsurancePromotionSignUpPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    ifInsurancePromotion:
      overrides && overrides.hasOwnProperty('ifInsurancePromotion')
        ? overrides.ifInsurancePromotion!
        : relationshipsToOmit.has('IfInsurancePromotion')
          ? ({} as IfInsurancePromotion)
          : anIfInsurancePromotion({}, relationshipsToOmit),
  }
}

export const anImportTinkBankAccountPayload = (
  overrides?: Partial<ImportTinkBankAccountPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ImportTinkBankAccountPayload' } & ImportTinkBankAccountPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ImportTinkBankAccountPayload')
  return {
    __typename: 'ImportTinkBankAccountPayload',
    bankAccount:
      overrides && overrides.hasOwnProperty('bankAccount')
        ? overrides.bankAccount!
        : relationshipsToOmit.has('BankAccount')
          ? ({} as BankAccount)
          : aBankAccount({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    isIdNumberMismatch:
      overrides && overrides.hasOwnProperty('isIdNumberMismatch') ? overrides.isIdNumberMismatch! : false,
  }
}

export const anIncomeVerification = (
  overrides?: Partial<IncomeVerification>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'IncomeVerification' } & IncomeVerification => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('IncomeVerification')
  return {
    __typename: 'IncomeVerification',
    benefitsMean: overrides && overrides.hasOwnProperty('benefitsMean') ? overrides.benefitsMean! : 387,
    cashDepositsMean:
      overrides && overrides.hasOwnProperty('cashDepositsMean') ? overrides.cashDepositsMean! : 8759,
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'alias qui consequatur cum pariatur eum rerum',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '54d3ed7d-f2fe-4e73-9a29-9f8e2029adbf',
    meanBenefits:
      overrides && overrides.hasOwnProperty('meanBenefits')
        ? overrides.meanBenefits!
        : relationshipsToOmit.has('Money')
          ? ({} as Money)
          : aMoney({}, relationshipsToOmit),
    meanCashDeposits:
      overrides && overrides.hasOwnProperty('meanCashDeposits')
        ? overrides.meanCashDeposits!
        : relationshipsToOmit.has('Money')
          ? ({} as Money)
          : aMoney({}, relationshipsToOmit),
    meanOtherIncomes:
      overrides && overrides.hasOwnProperty('meanOtherIncomes')
        ? overrides.meanOtherIncomes!
        : relationshipsToOmit.has('Money')
          ? ({} as Money)
          : aMoney({}, relationshipsToOmit),
    meanPension:
      overrides && overrides.hasOwnProperty('meanPension')
        ? overrides.meanPension!
        : relationshipsToOmit.has('Money')
          ? ({} as Money)
          : aMoney({}, relationshipsToOmit),
    meanSalary:
      overrides && overrides.hasOwnProperty('meanSalary')
        ? overrides.meanSalary!
        : relationshipsToOmit.has('Money')
          ? ({} as Money)
          : aMoney({}, relationshipsToOmit),
    meanTotalIncome:
      overrides && overrides.hasOwnProperty('meanTotalIncome')
        ? overrides.meanTotalIncome!
        : relationshipsToOmit.has('Money')
          ? ({} as Money)
          : aMoney({}, relationshipsToOmit),
    otherIncomesMean:
      overrides && overrides.hasOwnProperty('otherIncomesMean') ? overrides.otherIncomesMean! : 9005,
    pensionMean: overrides && overrides.hasOwnProperty('pensionMean') ? overrides.pensionMean! : 7200,
    salaryMean: overrides && overrides.hasOwnProperty('salaryMean') ? overrides.salaryMean! : 2150,
    status:
      overrides && overrides.hasOwnProperty('status')
        ? overrides.status!
        : IncomeVerificationStatusEnum.approved,
    totalIncomeMean:
      overrides && overrides.hasOwnProperty('totalIncomeMean') ? overrides.totalIncomeMean! : 7414,
    transactionGroups:
      overrides && overrides.hasOwnProperty('transactionGroups')
        ? overrides.transactionGroups!
        : [
            relationshipsToOmit.has('TinkTransactionGroupType')
              ? ({} as TinkTransactionGroupType)
              : aTinkTransactionGroupType({}, relationshipsToOmit),
          ],
    transactions:
      overrides && overrides.hasOwnProperty('transactions')
        ? overrides.transactions!
        : [
            relationshipsToOmit.has('TinkTransactionType')
              ? ({} as TinkTransactionType)
              : aTinkTransactionType({}, relationshipsToOmit),
          ],
    updatedAt:
      overrides && overrides.hasOwnProperty('updatedAt')
        ? overrides.updatedAt!
        : 'fugiat illum tempore nam odit blanditiis ipsam',
    uploads:
      overrides && overrides.hasOwnProperty('uploads')
        ? overrides.uploads!
        : [relationshipsToOmit.has('Upload') ? ({} as Upload) : anUpload({}, relationshipsToOmit)],
    user:
      overrides && overrides.hasOwnProperty('user')
        ? overrides.user!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
    verifiedAt:
      overrides && overrides.hasOwnProperty('verifiedAt')
        ? overrides.verifiedAt!
        : 'nam ea consequatur voluptatem eum aut qui',
  }
}

export const anIncomeVerificationInput = (
  overrides?: Partial<IncomeVerificationInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): IncomeVerificationInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('IncomeVerificationInput')
  return {
    benefitsMean: overrides && overrides.hasOwnProperty('benefitsMean') ? overrides.benefitsMean! : 4287,
    cashDepositsMean:
      overrides && overrides.hasOwnProperty('cashDepositsMean') ? overrides.cashDepositsMean! : 2025,
    currency: overrides && overrides.hasOwnProperty('currency') ? overrides.currency! : CurrencyEnum.EUR,
    otherIncomesMean:
      overrides && overrides.hasOwnProperty('otherIncomesMean') ? overrides.otherIncomesMean! : 2571,
    pensionMean: overrides && overrides.hasOwnProperty('pensionMean') ? overrides.pensionMean! : 4748,
    salaryMean: overrides && overrides.hasOwnProperty('salaryMean') ? overrides.salaryMean! : 2255,
  }
}

export const anInitiateBookingCardPaymentInput = (
  overrides?: Partial<InitiateBookingCardPaymentInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): InitiateBookingCardPaymentInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('InitiateBookingCardPaymentInput')
  return {
    invoiceId:
      overrides && overrides.hasOwnProperty('invoiceId')
        ? overrides.invoiceId!
        : '5453fa66-3370-44eb-b608-9ccce3d79840',
    stripeParams:
      overrides && overrides.hasOwnProperty('stripeParams')
        ? overrides.stripeParams!
        : relationshipsToOmit.has('StripeCheckoutInput')
          ? ({} as StripeCheckoutInput)
          : aStripeCheckoutInput({}, relationshipsToOmit),
  }
}

export const anInitiateBookingCardPaymentPayload = (
  overrides?: Partial<InitiateBookingCardPaymentPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'InitiateBookingCardPaymentPayload' } & InitiateBookingCardPaymentPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('InitiateBookingCardPaymentPayload')
  return {
    __typename: 'InitiateBookingCardPaymentPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    sessionId:
      overrides && overrides.hasOwnProperty('sessionId')
        ? overrides.sessionId!
        : 'db61ffc6-eccc-44e6-b1df-c08671f653e2',
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : true,
  }
}

export const anInitiateContractSigningPayload = (
  overrides?: Partial<InitiateContractSigningPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'InitiateContractSigningPayload' } & InitiateContractSigningPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('InitiateContractSigningPayload')
  return {
    __typename: 'InitiateContractSigningPayload',
    contract:
      overrides && overrides.hasOwnProperty('contract')
        ? overrides.contract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const anInitiateListingBumpPaymentInput = (
  overrides?: Partial<InitiateListingBumpPaymentInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): InitiateListingBumpPaymentInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('InitiateListingBumpPaymentInput')
  return {
    homeId:
      overrides && overrides.hasOwnProperty('homeId')
        ? overrides.homeId!
        : '97206f84-ee86-47c9-8f80-100c21fda8e1',
    stripeParams:
      overrides && overrides.hasOwnProperty('stripeParams')
        ? overrides.stripeParams!
        : relationshipsToOmit.has('StripeCheckoutInput')
          ? ({} as StripeCheckoutInput)
          : aStripeCheckoutInput({}, relationshipsToOmit),
  }
}

export const anInitiateListingBumpPaymentPayload = (
  overrides?: Partial<InitiateListingBumpPaymentPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'InitiateListingBumpPaymentPayload' } & InitiateListingBumpPaymentPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('InitiateListingBumpPaymentPayload')
  return {
    __typename: 'InitiateListingBumpPaymentPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    sessionId:
      overrides && overrides.hasOwnProperty('sessionId')
        ? overrides.sessionId!
        : '0bcb385b-9f1a-45c7-8747-08359188c365',
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : false,
  }
}

export const anInitiateListingPublicationFeePaymentInput = (
  overrides?: Partial<InitiateListingPublicationFeePaymentInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): InitiateListingPublicationFeePaymentInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('InitiateListingPublicationFeePaymentInput')
  return {
    homeId:
      overrides && overrides.hasOwnProperty('homeId')
        ? overrides.homeId!
        : '90bc29af-71d2-4c47-9fd3-717979cc7337',
    stripeParams:
      overrides && overrides.hasOwnProperty('stripeParams')
        ? overrides.stripeParams!
        : relationshipsToOmit.has('StripeCheckoutInput')
          ? ({} as StripeCheckoutInput)
          : aStripeCheckoutInput({}, relationshipsToOmit),
  }
}

export const anInitiateListingPublicationFeePaymentPayload = (
  overrides?: Partial<InitiateListingPublicationFeePaymentPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): {
  __typename: 'InitiateListingPublicationFeePaymentPayload'
} & InitiateListingPublicationFeePaymentPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('InitiateListingPublicationFeePaymentPayload')
  return {
    __typename: 'InitiateListingPublicationFeePaymentPayload',
    sessionId:
      overrides && overrides.hasOwnProperty('sessionId')
        ? overrides.sessionId!
        : '5a759360-9a87-4503-a60f-28396989fec9',
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : false,
  }
}

export const anInitiateOnfidoCheckPayload = (
  overrides?: Partial<InitiateOnfidoCheckPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'InitiateOnfidoCheckPayload' } & InitiateOnfidoCheckPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('InitiateOnfidoCheckPayload')
  return {
    __typename: 'InitiateOnfidoCheckPayload',
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : true,
  }
}

export const anInitiateSwishPaymentInput = (
  overrides?: Partial<InitiateSwishPaymentInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): InitiateSwishPaymentInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('InitiateSwishPaymentInput')
  return {
    invoiceId:
      overrides && overrides.hasOwnProperty('invoiceId')
        ? overrides.invoiceId!
        : '39c2cfcc-8c3a-4a3c-a498-ac7b02d8bbd2',
    phoneNumber: overrides && overrides.hasOwnProperty('phoneNumber') ? overrides.phoneNumber! : 'deserunt',
  }
}

export const anInitiateSwishPaymentPayload = (
  overrides?: Partial<InitiateSwishPaymentPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'InitiateSwishPaymentPayload' } & InitiateSwishPaymentPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('InitiateSwishPaymentPayload')
  return {
    __typename: 'InitiateSwishPaymentPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    swishPayment:
      overrides && overrides.hasOwnProperty('swishPayment')
        ? overrides.swishPayment!
        : relationshipsToOmit.has('SwishPayment')
          ? ({} as SwishPayment)
          : aSwishPayment({}, relationshipsToOmit),
  }
}

export const anInitiateTinkIncomeCheckPayload = (
  overrides?: Partial<InitiateTinkIncomeCheckPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'InitiateTinkIncomeCheckPayload' } & InitiateTinkIncomeCheckPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('InitiateTinkIncomeCheckPayload')
  return {
    __typename: 'InitiateTinkIncomeCheckPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    incomeVerification:
      overrides && overrides.hasOwnProperty('incomeVerification')
        ? overrides.incomeVerification!
        : relationshipsToOmit.has('IncomeVerification')
          ? ({} as IncomeVerification)
          : anIncomeVerification({}, relationshipsToOmit),
  }
}

export const anInspection = (
  overrides?: Partial<Inspection>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Inspection' } & Inspection => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Inspection')
  return {
    __typename: 'Inspection',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'ed548f69-2d2d-4384-8de8-86c426879597',
    observations:
      overrides && overrides.hasOwnProperty('observations')
        ? overrides.observations!
        : [
            relationshipsToOmit.has('InspectionObservation')
              ? ({} as InspectionObservation)
              : anInspectionObservation({}, relationshipsToOmit),
          ],
  }
}

export const anInspectionObservation = (
  overrides?: Partial<InspectionObservation>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'InspectionObservation' } & InspectionObservation => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('InspectionObservation')
  return {
    __typename: 'InspectionObservation',
    description: overrides && overrides.hasOwnProperty('description') ? overrides.description! : 'est',
    section:
      overrides && overrides.hasOwnProperty('section') ? overrides.section! : InspectionRoomsEnum.bathroom,
  }
}

export const anInspectionObservationInput = (
  overrides?: Partial<InspectionObservationInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): InspectionObservationInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('InspectionObservationInput')
  return {
    description: overrides && overrides.hasOwnProperty('description') ? overrides.description! : 'dolorem',
    section:
      overrides && overrides.hasOwnProperty('section') ? overrides.section! : InspectionRoomsEnum.bathroom,
  }
}

export const anInstantSignDurationInput = (
  overrides?: Partial<InstantSignDurationInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): InstantSignDurationInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('InstantSignDurationInput')
  return {
    startOptimal:
      overrides && overrides.hasOwnProperty('startOptimal')
        ? overrides.startOptimal!
        : 'dignissimos et dignissimos voluptatem error quasi eos',
  }
}

export const anInstantSignSetting = (
  overrides?: Partial<InstantSignSetting>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'InstantSignSetting' } & InstantSignSetting => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('InstantSignSetting')
  return {
    __typename: 'InstantSignSetting',
    enabled: overrides && overrides.hasOwnProperty('enabled') ? overrides.enabled! : true,
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'efc36223-0634-4809-ac0b-437bb4ebce8c',
    landlordHoursToSign:
      overrides && overrides.hasOwnProperty('landlordHoursToSign') ? overrides.landlordHoursToSign! : 7998,
    tenantMinutesToSign:
      overrides && overrides.hasOwnProperty('tenantMinutesToSign') ? overrides.tenantMinutesToSign! : 9316,
  }
}

export const anInstantSignSettingInput = (
  overrides?: Partial<InstantSignSettingInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): InstantSignSettingInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('InstantSignSettingInput')
  return {
    enabled: overrides && overrides.hasOwnProperty('enabled') ? overrides.enabled! : true,
  }
}

export const anInstantSignType = (
  overrides?: Partial<InstantSignType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'InstantSignType' } & InstantSignType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('InstantSignType')
  return {
    __typename: 'InstantSignType',
    enabled: overrides && overrides.hasOwnProperty('enabled') ? overrides.enabled! : false,
    landlordTimeToSignMinutes:
      overrides && overrides.hasOwnProperty('landlordTimeToSignMinutes')
        ? overrides.landlordTimeToSignMinutes!
        : 703,
    tenantTimeToSignMinutes:
      overrides && overrides.hasOwnProperty('tenantTimeToSignMinutes')
        ? overrides.tenantTimeToSignMinutes!
        : 7503,
  }
}

export const anInsurance = (
  overrides?: Partial<Insurance>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Insurance' } & Insurance => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Insurance')
  return {
    __typename: 'Insurance',
    claims: overrides && overrides.hasOwnProperty('claims') ? overrides.claims! : true,
    hedvigId: overrides && overrides.hasOwnProperty('hedvigId') ? overrides.hedvigId! : 'quidem',
    omocomId: overrides && overrides.hasOwnProperty('omocomId') ? overrides.omocomId! : 'quo',
    status: overrides && overrides.hasOwnProperty('status') ? overrides.status! : 'qui',
  }
}

export const anInvoice = (
  overrides?: Partial<Invoice>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Invoice' } & Invoice => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Invoice')
  return {
    __typename: 'Invoice',
    adminNotes:
      overrides && overrides.hasOwnProperty('adminNotes')
        ? overrides.adminNotes!
        : [relationshipsToOmit.has('AdminNote') ? ({} as AdminNote) : anAdminNote({}, relationshipsToOmit)],
    amount: overrides && overrides.hasOwnProperty('amount') ? overrides.amount! : 5087,
    batch: overrides && overrides.hasOwnProperty('batch') ? overrides.batch! : 2014,
    billectaId:
      overrides && overrides.hasOwnProperty('billectaId')
        ? overrides.billectaId!
        : '791ba6a9-7381-4de4-841b-2f70b88df627',
    contract:
      overrides && overrides.hasOwnProperty('contract')
        ? overrides.contract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'sit ipsa nihil ut eaque excepturi iste',
    creditedAmount:
      overrides && overrides.hasOwnProperty('creditedAmount') ? overrides.creditedAmount! : 6250,
    currency: overrides && overrides.hasOwnProperty('currency') ? overrides.currency! : CurrencyEnum.EUR,
    dueAt:
      overrides && overrides.hasOwnProperty('dueAt')
        ? overrides.dueAt!
        : 'sit dolorem magnam et consectetur eius dolor',
    guaranteed: overrides && overrides.hasOwnProperty('guaranteed') ? overrides.guaranteed! : true,
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '0c247a32-f357-43b6-b459-98ccfe338c5e',
    invoiceEntries:
      overrides && overrides.hasOwnProperty('invoiceEntries')
        ? overrides.invoiceEntries!
        : [
            relationshipsToOmit.has('InvoiceEntry')
              ? ({} as InvoiceEntry)
              : anInvoiceEntry({}, relationshipsToOmit),
          ],
    paidAt:
      overrides && overrides.hasOwnProperty('paidAt')
        ? overrides.paidAt!
        : 'dolorem qui a sequi dolore ducimus quis',
    periodEndsAt:
      overrides && overrides.hasOwnProperty('periodEndsAt')
        ? overrides.periodEndsAt!
        : 'dolores nesciunt veritatis sit quia ratione sunt',
    periodStartsAt:
      overrides && overrides.hasOwnProperty('periodStartsAt')
        ? overrides.periodStartsAt!
        : 'nostrum alias iure occaecati nam fugiat consequatur',
    remindersDisabledAt:
      overrides && overrides.hasOwnProperty('remindersDisabledAt')
        ? overrides.remindersDisabledAt!
        : 'cum provident voluptatem quis assumenda tempore voluptatem',
    sendAt:
      overrides && overrides.hasOwnProperty('sendAt')
        ? overrides.sendAt!
        : 'omnis et dignissimos magni sequi illo facere',
    sentAt:
      overrides && overrides.hasOwnProperty('sentAt')
        ? overrides.sentAt!
        : 'aliquam aspernatur porro pariatur quisquam fuga dicta',
    swishPayments:
      overrides && overrides.hasOwnProperty('swishPayments')
        ? overrides.swishPayments!
        : [
            relationshipsToOmit.has('SwishPayment')
              ? ({} as SwishPayment)
              : aSwishPayment({}, relationshipsToOmit),
          ],
    type: overrides && overrides.hasOwnProperty('type') ? overrides.type! : InvoiceTypeEnum.invoice_deposit,
    updatedAt:
      overrides && overrides.hasOwnProperty('updatedAt')
        ? overrides.updatedAt!
        : 'in fugit mollitia repellat aperiam ex aut',
    url: overrides && overrides.hasOwnProperty('url') ? overrides.url! : 'fugit',
  }
}

export const anInvoiceEntry = (
  overrides?: Partial<InvoiceEntry>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'InvoiceEntry' } & InvoiceEntry => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('InvoiceEntry')
  return {
    __typename: 'InvoiceEntry',
    amount: overrides && overrides.hasOwnProperty('amount') ? overrides.amount! : 3345,
    costType:
      overrides && overrides.hasOwnProperty('costType')
        ? overrides.costType!
        : InvoiceEntryCostTypeEnum.deduction,
    creditedAmount: overrides && overrides.hasOwnProperty('creditedAmount') ? overrides.creditedAmount! : 612,
    currency: overrides && overrides.hasOwnProperty('currency') ? overrides.currency! : CurrencyEnum.EUR,
    entryType:
      overrides && overrides.hasOwnProperty('entryType')
        ? overrides.entryType!
        : InvoiceEntryTypeEnum.cleaning_fee_payment,
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '5fd0021c-8deb-4bb2-a127-f51e177067a6',
    invoiceId:
      overrides && overrides.hasOwnProperty('invoiceId')
        ? overrides.invoiceId!
        : '484f0bd0-819b-4e0a-8836-132dde4d5ce0',
    paidAt:
      overrides && overrides.hasOwnProperty('paidAt')
        ? overrides.paidAt!
        : 'nisi minus ea veritatis consectetur facere consequuntur',
  }
}

export const anInvoiceInterface = (
  overrides?: Partial<InvoiceInterface>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'InvoiceInterface' } & InvoiceInterface => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('InvoiceInterface')
  return {
    __typename: 'InvoiceInterface',
    charges:
      overrides && overrides.hasOwnProperty('charges')
        ? overrides.charges!
        : [
            relationshipsToOmit.has('FinanceChargeType')
              ? ({} as FinanceChargeType)
              : aFinanceChargeType({}, relationshipsToOmit),
          ],
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'ab praesentium et et omnis est sed',
    currentState:
      overrides && overrides.hasOwnProperty('currentState')
        ? overrides.currentState!
        : InvoiceStateEnum.cancelled,
    dueDate:
      overrides && overrides.hasOwnProperty('dueDate')
        ? overrides.dueDate!
        : 'quam laboriosam eum est impedit qui sunt',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'b6b846b2-71db-4bae-8ea3-8e4da2a654c0',
    items:
      overrides && overrides.hasOwnProperty('items')
        ? overrides.items!
        : [
            relationshipsToOmit.has('FinanceItemType')
              ? ({} as FinanceItemType)
              : aFinanceItemType({}, relationshipsToOmit),
          ],
    overdue: overrides && overrides.hasOwnProperty('overdue') ? overrides.overdue! : false,
    paidAt:
      overrides && overrides.hasOwnProperty('paidAt')
        ? overrides.paidAt!
        : 'quia cum numquam consequuntur dolorem repellat ad',
    paymentAction:
      overrides && overrides.hasOwnProperty('paymentAction')
        ? overrides.paymentAction!
        : relationshipsToOmit.has('StripeSepaTransferMoneyAction')
          ? ({} as StripeSepaTransferMoneyAction)
          : aStripeSepaTransferMoneyAction({}, relationshipsToOmit),
    payout:
      overrides && overrides.hasOwnProperty('payout')
        ? overrides.payout!
        : relationshipsToOmit.has('FinancePayoutType')
          ? ({} as FinancePayoutType)
          : aFinancePayoutType({}, relationshipsToOmit),
    receipt:
      overrides && overrides.hasOwnProperty('receipt')
        ? overrides.receipt!
        : relationshipsToOmit.has('Blob')
          ? ({} as Blob)
          : aBlob({}, relationshipsToOmit),
    reference:
      overrides && overrides.hasOwnProperty('reference')
        ? overrides.reference!
        : '0d506772-ba69-4031-bb49-d21177466c8d',
    requiredUserActions:
      overrides && overrides.hasOwnProperty('requiredUserActions')
        ? overrides.requiredUserActions!
        : [relationshipsToOmit.has('UserAction') ? ({} as UserAction) : aUserAction({}, relationshipsToOmit)],
    sumAmount:
      overrides && overrides.hasOwnProperty('sumAmount')
        ? overrides.sumAmount!
        : relationshipsToOmit.has('Money')
          ? ({} as Money)
          : aMoney({}, relationshipsToOmit),
    sumVat:
      overrides && overrides.hasOwnProperty('sumVat')
        ? overrides.sumVat!
        : relationshipsToOmit.has('Money')
          ? ({} as Money)
          : aMoney({}, relationshipsToOmit),
  }
}

export const anInvoiceSettingsType = (
  overrides?: Partial<InvoiceSettingsType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'InvoiceSettingsType' } & InvoiceSettingsType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('InvoiceSettingsType')
  return {
    __typename: 'InvoiceSettingsType',
    daysBeforeReminderDelay:
      overrides && overrides.hasOwnProperty('daysBeforeReminderDelay')
        ? overrides.daysBeforeReminderDelay!
        : 1184,
    serviceFeeVatRate:
      overrides && overrides.hasOwnProperty('serviceFeeVatRate') ? overrides.serviceFeeVatRate! : 6.73,
  }
}

export const aKanyeHome = (
  overrides?: Partial<KanyeHome>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'KanyeHome' } & KanyeHome => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('KanyeHome')
  return {
    __typename: 'KanyeHome',
    acceptedOfferingsCount:
      overrides && overrides.hasOwnProperty('acceptedOfferingsCount')
        ? overrides.acceptedOfferingsCount!
        : 6250,
    activeContract:
      overrides && overrides.hasOwnProperty('activeContract')
        ? overrides.activeContract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    adPaidAt:
      overrides && overrides.hasOwnProperty('adPaidAt')
        ? overrides.adPaidAt!
        : 'deleniti velit nesciunt error illum fugiat dolor',
    adPayment:
      overrides && overrides.hasOwnProperty('adPayment')
        ? overrides.adPayment!
        : relationshipsToOmit.has('StripePayment')
          ? ({} as StripePayment)
          : aStripePayment({}, relationshipsToOmit),
    affiliateCode: overrides && overrides.hasOwnProperty('affiliateCode') ? overrides.affiliateCode! : 'quia',
    apartmentNumber:
      overrides && overrides.hasOwnProperty('apartmentNumber') ? overrides.apartmentNumber! : 'quisquam',
    applicationBoardCardInfo:
      overrides && overrides.hasOwnProperty('applicationBoardCardInfo')
        ? overrides.applicationBoardCardInfo!
        : relationshipsToOmit.has('ApplicationBoardCardInfo')
          ? ({} as ApplicationBoardCardInfo)
          : anApplicationBoardCardInfo({}, relationshipsToOmit),
    applicationBoardLists:
      overrides && overrides.hasOwnProperty('applicationBoardLists')
        ? overrides.applicationBoardLists!
        : [
            relationshipsToOmit.has('ApplicationBoardList')
              ? ({} as ApplicationBoardList)
              : anApplicationBoardList({}, relationshipsToOmit),
          ],
    applicationDeadline:
      overrides && overrides.hasOwnProperty('applicationDeadline')
        ? overrides.applicationDeadline!
        : 'asperiores officiis error recusandae facere maiores earum',
    applicationForecast:
      overrides && overrides.hasOwnProperty('applicationForecast') ? overrides.applicationForecast! : 9079,
    applicationSelectionType:
      overrides && overrides.hasOwnProperty('applicationSelectionType')
        ? overrides.applicationSelectionType!
        : ApplicationSelectionTypeEnum.automatic,
    applicationSortingType:
      overrides && overrides.hasOwnProperty('applicationSortingType')
        ? overrides.applicationSortingType!
        : ApplicationSortingTypeEnum.queue_points,
    applicationStats:
      overrides && overrides.hasOwnProperty('applicationStats')
        ? overrides.applicationStats!
        : relationshipsToOmit.has('HomeApplicationStats')
          ? ({} as HomeApplicationStats)
          : aHomeApplicationStats({}, relationshipsToOmit),
    applicationTimeSeries:
      overrides && overrides.hasOwnProperty('applicationTimeSeries')
        ? overrides.applicationTimeSeries!
        : relationshipsToOmit.has('ApplicationTimeSeries')
          ? ({} as ApplicationTimeSeries)
          : anApplicationTimeSeries({}, relationshipsToOmit),
    archiveReason:
      overrides && overrides.hasOwnProperty('archiveReason')
        ? overrides.archiveReason!
        : HomeArchiveReasonEnum.accidental_publish,
    archivedBecauseOfApplicationDeadlinePassed:
      overrides && overrides.hasOwnProperty('archivedBecauseOfApplicationDeadlinePassed')
        ? overrides.archivedBecauseOfApplicationDeadlinePassed!
        : false,
    averagePricePerNight:
      overrides && overrides.hasOwnProperty('averagePricePerNight') ? overrides.averagePricePerNight! : 815,
    bathroomRenovationYear:
      overrides && overrides.hasOwnProperty('bathroomRenovationYear')
        ? overrides.bathroomRenovationYear!
        : 9681,
    bedCount: overrides && overrides.hasOwnProperty('bedCount') ? overrides.bedCount! : 367,
    bedroomCount: overrides && overrides.hasOwnProperty('bedroomCount') ? overrides.bedroomCount! : 8537,
    buildYear: overrides && overrides.hasOwnProperty('buildYear') ? overrides.buildYear! : 6999,
    buildingFloors:
      overrides && overrides.hasOwnProperty('buildingFloors') ? overrides.buildingFloors! : 2722,
    canBeDeleted: overrides && overrides.hasOwnProperty('canBeDeleted') ? overrides.canBeDeleted! : true,
    canBePublished:
      overrides && overrides.hasOwnProperty('canBePublished') ? overrides.canBePublished! : true,
    cancellationOptions:
      overrides && overrides.hasOwnProperty('cancellationOptions')
        ? overrides.cancellationOptions!
        : [
            relationshipsToOmit.has('CancellationPolicy')
              ? ({} as CancellationPolicy)
              : aCancellationPolicy({}, relationshipsToOmit),
          ],
    cleaningFee: overrides && overrides.hasOwnProperty('cleaningFee') ? overrides.cleaningFee! : 2648,
    condition:
      overrides && overrides.hasOwnProperty('condition') ? overrides.condition! : HomeConditionEnum.good,
    contractTemplate:
      overrides && overrides.hasOwnProperty('contractTemplate')
        ? overrides.contractTemplate!
        : relationshipsToOmit.has('ContractTemplate')
          ? ({} as ContractTemplate)
          : aContractTemplate({}, relationshipsToOmit),
    conversations:
      overrides && overrides.hasOwnProperty('conversations')
        ? overrides.conversations!
        : relationshipsToOmit.has('ConversationConnection')
          ? ({} as ConversationConnection)
          : aConversationConnection({}, relationshipsToOmit),
    corporateHome: overrides && overrides.hasOwnProperty('corporateHome') ? overrides.corporateHome! : false,
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'dignissimos qui hic est et aliquid voluptas',
    currency: overrides && overrides.hasOwnProperty('currency') ? overrides.currency! : CurrencyEnum.EUR,
    currentCancellationPolicy:
      overrides && overrides.hasOwnProperty('currentCancellationPolicy')
        ? overrides.currentCancellationPolicy!
        : relationshipsToOmit.has('CancellationPolicy')
          ? ({} as CancellationPolicy)
          : aCancellationPolicy({}, relationshipsToOmit),
    dashboardStatus:
      overrides && overrides.hasOwnProperty('dashboardStatus')
        ? overrides.dashboardStatus!
        : HomeDashboardStatusEnum.archived,
    description:
      overrides && overrides.hasOwnProperty('description') ? overrides.description! : 'accusantium',
    descriptionBuilding:
      overrides && overrides.hasOwnProperty('descriptionBuilding') ? overrides.descriptionBuilding! : 'nihil',
    descriptionContract:
      overrides && overrides.hasOwnProperty('descriptionContract') ? overrides.descriptionContract! : 'et',
    descriptionFeatures:
      overrides && overrides.hasOwnProperty('descriptionFeatures')
        ? overrides.descriptionFeatures!
        : 'aspernatur',
    descriptionLayout:
      overrides && overrides.hasOwnProperty('descriptionLayout') ? overrides.descriptionLayout! : 'dolorem',
    descriptionTransportation:
      overrides && overrides.hasOwnProperty('descriptionTransportation')
        ? overrides.descriptionTransportation!
        : 'molestiae',
    displayStreetNumber:
      overrides && overrides.hasOwnProperty('displayStreetNumber') ? overrides.displayStreetNumber! : false,
    duration:
      overrides && overrides.hasOwnProperty('duration')
        ? overrides.duration!
        : relationshipsToOmit.has('Duration')
          ? ({} as Duration)
          : aDuration({}, relationshipsToOmit),
    electricityFee:
      overrides && overrides.hasOwnProperty('electricityFee')
        ? overrides.electricityFee!
        : relationshipsToOmit.has('ElectricityFeeType')
          ? ({} as ElectricityFeeType)
          : anElectricityFeeType({}, relationshipsToOmit),
    energyClass: overrides && overrides.hasOwnProperty('energyClass') ? overrides.energyClass! : 'et',
    expiresAt:
      overrides && overrides.hasOwnProperty('expiresAt')
        ? overrides.expiresAt!
        : 'magnam voluptas qui ipsum et nam sint',
    external: overrides && overrides.hasOwnProperty('external') ? overrides.external! : true,
    externalAdId: overrides && overrides.hasOwnProperty('externalAdId') ? overrides.externalAdId! : 567,
    externalApplicationUrl:
      overrides && overrides.hasOwnProperty('externalApplicationUrl')
        ? overrides.externalApplicationUrl!
        : 'id',
    externalEmail:
      overrides && overrides.hasOwnProperty('externalEmail') ? overrides.externalEmail! : 'beatae',
    externalInfoPage:
      overrides && overrides.hasOwnProperty('externalInfoPage') ? overrides.externalInfoPage! : 'et',
    extraCosts:
      overrides && overrides.hasOwnProperty('extraCosts')
        ? overrides.extraCosts!
        : [relationshipsToOmit.has('ExtraCost') ? ({} as ExtraCost) : anExtraCost({}, relationshipsToOmit)],
    favoriteMarkedByUser:
      overrides && overrides.hasOwnProperty('favoriteMarkedByUser') ? overrides.favoriteMarkedByUser! : true,
    favoriteMarkedCount:
      overrides && overrides.hasOwnProperty('favoriteMarkedCount') ? overrides.favoriteMarkedCount! : 7657,
    fee: overrides && overrides.hasOwnProperty('fee') ? overrides.fee! : 2561,
    firsthand: overrides && overrides.hasOwnProperty('firsthand') ? overrides.firsthand! : false,
    floor: overrides && overrides.hasOwnProperty('floor') ? overrides.floor! : 6454,
    handleByAgent: overrides && overrides.hasOwnProperty('handleByAgent') ? overrides.handleByAgent! : true,
    hasKitchen: overrides && overrides.hasOwnProperty('hasKitchen') ? overrides.hasKitchen! : false,
    homeAvailabilitySettings:
      overrides && overrides.hasOwnProperty('homeAvailabilitySettings')
        ? overrides.homeAvailabilitySettings!
        : relationshipsToOmit.has('HomeAvailabilitySettings')
          ? ({} as HomeAvailabilitySettings)
          : aHomeAvailabilitySettings({}, relationshipsToOmit),
    homeReviewData:
      overrides && overrides.hasOwnProperty('homeReviewData')
        ? overrides.homeReviewData!
        : relationshipsToOmit.has('KanyeHomeReviewData')
          ? ({} as KanyeHomeReviewData)
          : aKanyeHomeReviewData({}, relationshipsToOmit),
    homeTemplates:
      overrides && overrides.hasOwnProperty('homeTemplates')
        ? overrides.homeTemplates!
        : [
            relationshipsToOmit.has('HomeTemplate')
              ? ({} as HomeTemplate)
              : aHomeTemplate({}, relationshipsToOmit),
          ],
    houseRules: overrides && overrides.hasOwnProperty('houseRules') ? overrides.houseRules! : 'ipsum',
    housingAssociation:
      overrides && overrides.hasOwnProperty('housingAssociation') ? overrides.housingAssociation! : 'aut',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '2114e7b6-0e9b-4761-a500-80cce04be393',
    inspection:
      overrides && overrides.hasOwnProperty('inspection')
        ? overrides.inspection!
        : relationshipsToOmit.has('Inspection')
          ? ({} as Inspection)
          : anInspection({}, relationshipsToOmit),
    instantSignSetting:
      overrides && overrides.hasOwnProperty('instantSignSetting')
        ? overrides.instantSignSetting!
        : relationshipsToOmit.has('InstantSignSetting')
          ? ({} as InstantSignSetting)
          : anInstantSignSetting({}, relationshipsToOmit),
    insurance:
      overrides && overrides.hasOwnProperty('insurance')
        ? overrides.insurance!
        : HomeInsuranceEnum.insurance_landlord,
    insuranceCost: overrides && overrides.hasOwnProperty('insuranceCost') ? overrides.insuranceCost! : 8522,
    kitchenRenovationYear:
      overrides && overrides.hasOwnProperty('kitchenRenovationYear')
        ? overrides.kitchenRenovationYear!
        : 7375,
    landlord:
      overrides && overrides.hasOwnProperty('landlord')
        ? overrides.landlord!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
    landlordBaseFee:
      overrides && overrides.hasOwnProperty('landlordBaseFee') ? overrides.landlordBaseFee! : 2245,
    landlordFees:
      overrides && overrides.hasOwnProperty('landlordFees')
        ? overrides.landlordFees!
        : relationshipsToOmit.has('HomeLandlordFees')
          ? ({} as HomeLandlordFees)
          : aHomeLandlordFees({}, relationshipsToOmit),
    landlordServices:
      overrides && overrides.hasOwnProperty('landlordServices')
        ? overrides.landlordServices!
        : relationshipsToOmit.has('HomeLandlordServices')
          ? ({} as HomeLandlordServices)
          : aHomeLandlordServices({}, relationshipsToOmit),
    landlordUnitId:
      overrides && overrides.hasOwnProperty('landlordUnitId') ? overrides.landlordUnitId! : 'enim',
    lastBumpedAt:
      overrides && overrides.hasOwnProperty('lastBumpedAt')
        ? overrides.lastBumpedAt!
        : 'quaerat occaecati nisi exercitationem nam ipsam earum',
    latestViewing:
      overrides && overrides.hasOwnProperty('latestViewing')
        ? overrides.latestViewing!
        : relationshipsToOmit.has('Viewing')
          ? ({} as Viewing)
          : aViewing({}, relationshipsToOmit),
    links:
      overrides && overrides.hasOwnProperty('links')
        ? overrides.links!
        : [relationshipsToOmit.has('Link') ? ({} as Link) : aLink({}, relationshipsToOmit)],
    listingBumpPayment:
      overrides && overrides.hasOwnProperty('listingBumpPayment')
        ? overrides.listingBumpPayment!
        : relationshipsToOmit.has('StripePayment')
          ? ({} as StripePayment)
          : aStripePayment({}, relationshipsToOmit),
    location:
      overrides && overrides.hasOwnProperty('location')
        ? overrides.location!
        : relationshipsToOmit.has('Location')
          ? ({} as Location)
          : aLocation({}, relationshipsToOmit),
    market:
      overrides && overrides.hasOwnProperty('market')
        ? overrides.market!
        : relationshipsToOmit.has('Market')
          ? ({} as Market)
          : aMarket({}, relationshipsToOmit),
    matchingTenantsCount:
      overrides && overrides.hasOwnProperty('matchingTenantsCount') ? overrides.matchingTenantsCount! : 7984,
    matterportEmbedUrl:
      overrides && overrides.hasOwnProperty('matterportEmbedUrl')
        ? overrides.matterportEmbedUrl!
        : 'voluptates',
    maxRent: overrides && overrides.hasOwnProperty('maxRent') ? overrides.maxRent! : 449,
    maxRoomCount: overrides && overrides.hasOwnProperty('maxRoomCount') ? overrides.maxRoomCount! : 3.67,
    maxSquareMeters:
      overrides && overrides.hasOwnProperty('maxSquareMeters') ? overrides.maxSquareMeters! : 4879,
    maxTenantCount: overrides && overrides.hasOwnProperty('maxTenantCount') ? overrides.maxTenantCount! : 46,
    maximumPricePerNight:
      overrides && overrides.hasOwnProperty('maximumPricePerNight') ? overrides.maximumPricePerNight! : 6613,
    messagesCount: overrides && overrides.hasOwnProperty('messagesCount') ? overrides.messagesCount! : 2349,
    minRent: overrides && overrides.hasOwnProperty('minRent') ? overrides.minRent! : 3836,
    minRoomCount: overrides && overrides.hasOwnProperty('minRoomCount') ? overrides.minRoomCount! : 3.02,
    minSquareMeters:
      overrides && overrides.hasOwnProperty('minSquareMeters') ? overrides.minSquareMeters! : 6842,
    minTenantCount:
      overrides && overrides.hasOwnProperty('minTenantCount') ? overrides.minTenantCount! : 7652,
    minimumPricePerNight:
      overrides && overrides.hasOwnProperty('minimumPricePerNight') ? overrides.minimumPricePerNight! : 2282,
    monthlyCostCents:
      overrides && overrides.hasOwnProperty('monthlyCostCents') ? overrides.monthlyCostCents! : 300,
    numberOfHomes: overrides && overrides.hasOwnProperty('numberOfHomes') ? overrides.numberOfHomes! : 3726,
    openForSigning:
      overrides && overrides.hasOwnProperty('openForSigning') ? overrides.openForSigning! : true,
    organization:
      overrides && overrides.hasOwnProperty('organization')
        ? overrides.organization!
        : relationshipsToOmit.has('Organization')
          ? ({} as Organization)
          : anOrganization({}, relationshipsToOmit),
    origin: overrides && overrides.hasOwnProperty('origin') ? overrides.origin! : 'dolor',
    ownHome: overrides && overrides.hasOwnProperty('ownHome') ? overrides.ownHome! : true,
    pageViews: overrides && overrides.hasOwnProperty('pageViews') ? overrides.pageViews! : 7878,
    partnerReferenceId:
      overrides && overrides.hasOwnProperty('partnerReferenceId') ? overrides.partnerReferenceId! : 'omnis',
    pendingOfferingsCount:
      overrides && overrides.hasOwnProperty('pendingOfferingsCount')
        ? overrides.pendingOfferingsCount!
        : 5290,
    pricingModel:
      overrides && overrides.hasOwnProperty('pricingModel')
        ? overrides.pricingModel!
        : relationshipsToOmit.has('PricingModel')
          ? ({} as PricingModel)
          : aPricingModel({}, relationshipsToOmit),
    pricingModelName:
      overrides && overrides.hasOwnProperty('pricingModelName')
        ? overrides.pricingModelName!
        : PricingModelNameEnum.landlord_fees_effective,
    proLandlord: overrides && overrides.hasOwnProperty('proLandlord') ? overrides.proLandlord! : true,
    property:
      overrides && overrides.hasOwnProperty('property')
        ? overrides.property!
        : relationshipsToOmit.has('Property')
          ? ({} as Property)
          : aProperty({}, relationshipsToOmit),
    publishedAt:
      overrides && overrides.hasOwnProperty('publishedAt')
        ? overrides.publishedAt!
        : 'eveniet quo quae at suscipit enim commodi',
    qasaGuarantee: overrides && overrides.hasOwnProperty('qasaGuarantee') ? overrides.qasaGuarantee! : true,
    qasaGuaranteeCost:
      overrides && overrides.hasOwnProperty('qasaGuaranteeCost') ? overrides.qasaGuaranteeCost! : 1806,
    recommendedRent:
      overrides && overrides.hasOwnProperty('recommendedRent') ? overrides.recommendedRent! : 296,
    recommendedRentNew:
      overrides && overrides.hasOwnProperty('recommendedRentNew') ? overrides.recommendedRentNew! : 6703,
    rent: overrides && overrides.hasOwnProperty('rent') ? overrides.rent! : 6208,
    rentalRequirement:
      overrides && overrides.hasOwnProperty('rentalRequirement')
        ? overrides.rentalRequirement!
        : relationshipsToOmit.has('RentalRequirement')
          ? ({} as RentalRequirement)
          : aRentalRequirement({}, relationshipsToOmit),
    rentalType:
      overrides && overrides.hasOwnProperty('rentalType')
        ? overrides.rentalType!
        : HomeRentalTypeEnum.long_term,
    responsibleForCleaning:
      overrides && overrides.hasOwnProperty('responsibleForCleaning')
        ? overrides.responsibleForCleaning!
        : ResponsibleForCleaningEnum.landlord,
    roomCount: overrides && overrides.hasOwnProperty('roomCount') ? overrides.roomCount! : 2.28,
    safeRental: overrides && overrides.hasOwnProperty('safeRental') ? overrides.safeRental! : true,
    seniorHome: overrides && overrides.hasOwnProperty('seniorHome') ? overrides.seniorHome! : true,
    shared: overrides && overrides.hasOwnProperty('shared') ? overrides.shared! : false,
    shortcut:
      overrides && overrides.hasOwnProperty('shortcut')
        ? overrides.shortcut!
        : relationshipsToOmit.has('Shortcut')
          ? ({} as Shortcut)
          : aShortcut({}, relationshipsToOmit),
    showtime: overrides && overrides.hasOwnProperty('showtime') ? overrides.showtime! : false,
    simplifiedStatus:
      overrides && overrides.hasOwnProperty('simplifiedStatus')
        ? overrides.simplifiedStatus!
        : SimplifiedHomeStatusEnum.archived,
    squareMeters: overrides && overrides.hasOwnProperty('squareMeters') ? overrides.squareMeters! : 5393,
    status: overrides && overrides.hasOwnProperty('status') ? overrides.status! : HomeStatusEnum.archived,
    studentHome: overrides && overrides.hasOwnProperty('studentHome') ? overrides.studentHome! : true,
    tenantBaseFee: overrides && overrides.hasOwnProperty('tenantBaseFee') ? overrides.tenantBaseFee! : 1878,
    tenantCount: overrides && overrides.hasOwnProperty('tenantCount') ? overrides.tenantCount! : 5010,
    tenantFees:
      overrides && overrides.hasOwnProperty('tenantFees')
        ? overrides.tenantFees!
        : relationshipsToOmit.has('HomeTenantFees')
          ? ({} as HomeTenantFees)
          : aHomeTenantFees({}, relationshipsToOmit),
    tenureType:
      overrides && overrides.hasOwnProperty('tenureType')
        ? overrides.tenureType!
        : TenureTypeEnum.condominium,
    title: overrides && overrides.hasOwnProperty('title') ? overrides.title! : 'molestias',
    toiletCount: overrides && overrides.hasOwnProperty('toiletCount') ? overrides.toiletCount! : 8826,
    traits:
      overrides && overrides.hasOwnProperty('traits')
        ? overrides.traits!
        : [relationshipsToOmit.has('Trait') ? ({} as Trait) : aTrait({}, relationshipsToOmit)],
    translatedDescriptions:
      overrides && overrides.hasOwnProperty('translatedDescriptions')
        ? overrides.translatedDescriptions!
        : [
            relationshipsToOmit.has('TranslatedDescription')
              ? ({} as TranslatedDescription)
              : aTranslatedDescription({}, relationshipsToOmit),
          ],
    type: overrides && overrides.hasOwnProperty('type') ? overrides.type! : HomeTypeEnum.apartment,
    unreadConversationsCount:
      overrides && overrides.hasOwnProperty('unreadConversationsCount')
        ? overrides.unreadConversationsCount!
        : 1826,
    unreadMessagesCount:
      overrides && overrides.hasOwnProperty('unreadMessagesCount') ? overrides.unreadMessagesCount! : 5913,
    updatedAt:
      overrides && overrides.hasOwnProperty('updatedAt')
        ? overrides.updatedAt!
        : 'aut assumenda qui voluptas dolorem magnam minus',
    uploads:
      overrides && overrides.hasOwnProperty('uploads')
        ? overrides.uploads!
        : [relationshipsToOmit.has('Upload') ? ({} as Upload) : anUpload({}, relationshipsToOmit)],
    user:
      overrides && overrides.hasOwnProperty('user')
        ? overrides.user!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
    userApplication:
      overrides && overrides.hasOwnProperty('userApplication')
        ? overrides.userApplication!
        : relationshipsToOmit.has('HomeApplication')
          ? ({} as HomeApplication)
          : aHomeApplication({}, relationshipsToOmit),
    videos:
      overrides && overrides.hasOwnProperty('videos')
        ? overrides.videos!
        : [relationshipsToOmit.has('Blob') ? ({} as Blob) : aBlob({}, relationshipsToOmit)],
    viewingCalendar:
      overrides && overrides.hasOwnProperty('viewingCalendar')
        ? overrides.viewingCalendar!
        : relationshipsToOmit.has('ViewingCalendar')
          ? ({} as ViewingCalendar)
          : aViewingCalendar({}, relationshipsToOmit),
  }
}

export const aKanyeHomeConnection = (
  overrides?: Partial<KanyeHomeConnection>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'KanyeHomeConnection' } & KanyeHomeConnection => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('KanyeHomeConnection')
  return {
    __typename: 'KanyeHomeConnection',
    edges:
      overrides && overrides.hasOwnProperty('edges')
        ? overrides.edges!
        : [
            relationshipsToOmit.has('KanyeHomeEdge')
              ? ({} as KanyeHomeEdge)
              : aKanyeHomeEdge({}, relationshipsToOmit),
          ],
    nodes:
      overrides && overrides.hasOwnProperty('nodes')
        ? overrides.nodes!
        : [relationshipsToOmit.has('KanyeHome') ? ({} as KanyeHome) : aKanyeHome({}, relationshipsToOmit)],
    pageInfo:
      overrides && overrides.hasOwnProperty('pageInfo')
        ? overrides.pageInfo!
        : relationshipsToOmit.has('PageInfo')
          ? ({} as PageInfo)
          : aPageInfo({}, relationshipsToOmit),
    totalCount: overrides && overrides.hasOwnProperty('totalCount') ? overrides.totalCount! : 2174,
  }
}

export const aKanyeHomeEdge = (
  overrides?: Partial<KanyeHomeEdge>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'KanyeHomeEdge' } & KanyeHomeEdge => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('KanyeHomeEdge')
  return {
    __typename: 'KanyeHomeEdge',
    cursor: overrides && overrides.hasOwnProperty('cursor') ? overrides.cursor! : 'sapiente',
    node:
      overrides && overrides.hasOwnProperty('node')
        ? overrides.node!
        : relationshipsToOmit.has('KanyeHome')
          ? ({} as KanyeHome)
          : aKanyeHome({}, relationshipsToOmit),
  }
}

export const aKanyeHomeReviewData = (
  overrides?: Partial<KanyeHomeReviewData>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'KanyeHomeReviewData' } & KanyeHomeReviewData => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('KanyeHomeReviewData')
  return {
    __typename: 'KanyeHomeReviewData',
    adReviewExplanation:
      overrides && overrides.hasOwnProperty('adReviewExplanation') ? overrides.adReviewExplanation! : 'atque',
    adReviewPrediction:
      overrides && overrides.hasOwnProperty('adReviewPrediction') ? overrides.adReviewPrediction! : 8.13,
    archivedHomesCount:
      overrides && overrides.hasOwnProperty('archivedHomesCount') ? overrides.archivedHomesCount! : 5232,
    publishedHomesCount:
      overrides && overrides.hasOwnProperty('publishedHomesCount') ? overrides.publishedHomesCount! : 9701,
    signedLandlordContractCount:
      overrides && overrides.hasOwnProperty('signedLandlordContractCount')
        ? overrides.signedLandlordContractCount!
        : 4977,
    signedTenantContractCount:
      overrides && overrides.hasOwnProperty('signedTenantContractCount')
        ? overrides.signedTenantContractCount!
        : 5205,
  }
}

export const aKanyeInputObjectsCreatePublicDocumentInput = (
  overrides?: Partial<KanyeInputObjectsCreatePublicDocumentInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): KanyeInputObjectsCreatePublicDocumentInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('KanyeInputObjectsCreatePublicDocumentInput')
  return {
    file:
      overrides && overrides.hasOwnProperty('file')
        ? overrides.file!
        : '70dd3355-9c48-4a85-bc13-394d7d6de7ba',
    name: overrides && overrides.hasOwnProperty('name') ? overrides.name! : 'tenetur',
  }
}

export const aKanyeUser = (
  overrides?: Partial<KanyeUser>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'KanyeUser' } & KanyeUser => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('KanyeUser')
  return {
    __typename: 'KanyeUser',
    activeIdentifications:
      overrides && overrides.hasOwnProperty('activeIdentifications')
        ? overrides.activeIdentifications!
        : [
            relationshipsToOmit.has('Identification')
              ? ({} as Identification)
              : anIdentification({}, relationshipsToOmit),
          ],
    adminFields:
      overrides && overrides.hasOwnProperty('adminFields')
        ? overrides.adminFields!
        : relationshipsToOmit.has('AdminFields')
          ? ({} as AdminFields)
          : anAdminFields({}, relationshipsToOmit),
    adminNotes:
      overrides && overrides.hasOwnProperty('adminNotes')
        ? overrides.adminNotes!
        : [relationshipsToOmit.has('AdminNote') ? ({} as AdminNote) : anAdminNote({}, relationshipsToOmit)],
    affiliateCode:
      overrides && overrides.hasOwnProperty('affiliateCode')
        ? overrides.affiliateCode!
        : relationshipsToOmit.has('AffiliateCode')
          ? ({} as AffiliateCode)
          : anAffiliateCode({}, relationshipsToOmit),
    age: overrides && overrides.hasOwnProperty('age') ? overrides.age! : 4863,
    applicationStats:
      overrides && overrides.hasOwnProperty('applicationStats')
        ? overrides.applicationStats!
        : relationshipsToOmit.has('UserApplicationStats')
          ? ({} as UserApplicationStats)
          : aUserApplicationStats({}, relationshipsToOmit),
    bio:
      overrides && overrides.hasOwnProperty('bio')
        ? overrides.bio!
        : relationshipsToOmit.has('UserBio')
          ? ({} as UserBio)
          : aUserBio({}, relationshipsToOmit),
    bookings:
      overrides && overrides.hasOwnProperty('bookings')
        ? overrides.bookings!
        : [relationshipsToOmit.has('Booking') ? ({} as Booking) : aBooking({}, relationshipsToOmit)],
    certificate:
      overrides && overrides.hasOwnProperty('certificate')
        ? overrides.certificate!
        : relationshipsToOmit.has('Certificate')
          ? ({} as Certificate)
          : aCertificate({}, relationshipsToOmit),
    companyName: overrides && overrides.hasOwnProperty('companyName') ? overrides.companyName! : 'est',
    contactLocation:
      overrides && overrides.hasOwnProperty('contactLocation')
        ? overrides.contactLocation!
        : relationshipsToOmit.has('Location')
          ? ({} as Location)
          : aLocation({}, relationshipsToOmit),
    contracts:
      overrides && overrides.hasOwnProperty('contracts')
        ? overrides.contracts!
        : [relationshipsToOmit.has('Contract') ? ({} as Contract) : aContract({}, relationshipsToOmit)],
    contractsCount:
      overrides && overrides.hasOwnProperty('contractsCount') ? overrides.contractsCount! : 6335,
    conversationForHome:
      overrides && overrides.hasOwnProperty('conversationForHome')
        ? overrides.conversationForHome!
        : relationshipsToOmit.has('Conversation')
          ? ({} as Conversation)
          : aConversation({}, relationshipsToOmit),
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'enim velit aliquam placeat magni enim ut',
    creditReport:
      overrides && overrides.hasOwnProperty('creditReport')
        ? overrides.creditReport!
        : relationshipsToOmit.has('CreditReport')
          ? ({} as CreditReport)
          : aCreditReport({}, relationshipsToOmit),
    creditStatusSummary:
      overrides && overrides.hasOwnProperty('creditStatusSummary')
        ? overrides.creditStatusSummary!
        : relationshipsToOmit.has('CreditStatusSummary')
          ? ({} as CreditStatusSummary)
          : aCreditStatusSummary({}, relationshipsToOmit),
    currentIdentification:
      overrides && overrides.hasOwnProperty('currentIdentification')
        ? overrides.currentIdentification!
        : relationshipsToOmit.has('Identification')
          ? ({} as Identification)
          : anIdentification({}, relationshipsToOmit),
    deliveryTraces:
      overrides && overrides.hasOwnProperty('deliveryTraces')
        ? overrides.deliveryTraces!
        : relationshipsToOmit.has('DeliveryTraceConnection')
          ? ({} as DeliveryTraceConnection)
          : aDeliveryTraceConnection({}, relationshipsToOmit),
    doNotContact: overrides && overrides.hasOwnProperty('doNotContact') ? overrides.doNotContact! : false,
    doNotSms: overrides && overrides.hasOwnProperty('doNotSms') ? overrides.doNotSms! : true,
    doNotVacationSms:
      overrides && overrides.hasOwnProperty('doNotVacationSms') ? overrides.doNotVacationSms! : false,
    emailConfirmed:
      overrides && overrides.hasOwnProperty('emailConfirmed') ? overrides.emailConfirmed! : true,
    emailConfirmedAt:
      overrides && overrides.hasOwnProperty('emailConfirmedAt')
        ? overrides.emailConfirmedAt!
        : 'quia non enim eum autem accusantium ut',
    employmentVerification:
      overrides && overrides.hasOwnProperty('employmentVerification')
        ? overrides.employmentVerification!
        : relationshipsToOmit.has('EmploymentVerification')
          ? ({} as EmploymentVerification)
          : anEmploymentVerification({}, relationshipsToOmit),
    evictionProcesses:
      overrides && overrides.hasOwnProperty('evictionProcesses')
        ? overrides.evictionProcesses!
        : relationshipsToOmit.has('EvictionProcessConnection')
          ? ({} as EvictionProcessConnection)
          : anEvictionProcessConnection({}, relationshipsToOmit),
    firstName: overrides && overrides.hasOwnProperty('firstName') ? overrides.firstName! : 'soluta',
    homeApplicationsToMyHomes:
      overrides && overrides.hasOwnProperty('homeApplicationsToMyHomes')
        ? overrides.homeApplicationsToMyHomes!
        : [
            relationshipsToOmit.has('HomeApplication')
              ? ({} as HomeApplication)
              : aHomeApplication({}, relationshipsToOmit),
          ],
    homes:
      overrides && overrides.hasOwnProperty('homes')
        ? overrides.homes!
        : [relationshipsToOmit.has('Home') ? ({} as Home) : aHome({}, relationshipsToOmit)],
    householdMembersBirthYears:
      overrides && overrides.hasOwnProperty('householdMembersBirthYears')
        ? overrides.householdMembersBirthYears!
        : [4972],
    housingSituation:
      overrides && overrides.hasOwnProperty('housingSituation')
        ? overrides.housingSituation!
        : relationshipsToOmit.has('HousingSituation')
          ? ({} as HousingSituation)
          : aHousingSituation({}, relationshipsToOmit),
    idNumber: overrides && overrides.hasOwnProperty('idNumber') ? overrides.idNumber! : 'commodi',
    idNumberConfirmed:
      overrides && overrides.hasOwnProperty('idNumberConfirmed') ? overrides.idNumberConfirmed! : true,
    idNumberPretty:
      overrides && overrides.hasOwnProperty('idNumberPretty') ? overrides.idNumberPretty! : 'accusamus',
    identityConfirmed:
      overrides && overrides.hasOwnProperty('identityConfirmed') ? overrides.identityConfirmed! : false,
    identityConfirmedAt:
      overrides && overrides.hasOwnProperty('identityConfirmedAt')
        ? overrides.identityConfirmedAt!
        : 'qui sit dignissimos dolores est omnis quam',
    incomeVerifiedAt:
      overrides && overrides.hasOwnProperty('incomeVerifiedAt')
        ? overrides.incomeVerifiedAt!
        : 'nesciunt sed consequuntur similique magnam voluptatum a',
    landlord: overrides && overrides.hasOwnProperty('landlord') ? overrides.landlord! : true,
    landlordApplicationResponseRate:
      overrides && overrides.hasOwnProperty('landlordApplicationResponseRate')
        ? overrides.landlordApplicationResponseRate!
        : 9.38,
    landlordApplicationResponseTimeHours:
      overrides && overrides.hasOwnProperty('landlordApplicationResponseTimeHours')
        ? overrides.landlordApplicationResponseTimeHours!
        : 4044,
    locale: overrides && overrides.hasOwnProperty('locale') ? overrides.locale! : 'voluptas',
    mainPlatform:
      overrides && overrides.hasOwnProperty('mainPlatform') ? overrides.mainPlatform! : PlatformEnum.blocket,
    matchingProfile:
      overrides && overrides.hasOwnProperty('matchingProfile')
        ? overrides.matchingProfile!
        : relationshipsToOmit.has('UserMatchingProfile')
          ? ({} as UserMatchingProfile)
          : aUserMatchingProfile({}, relationshipsToOmit),
    movingReason: overrides && overrides.hasOwnProperty('movingReason') ? overrides.movingReason! : 'amet',
    notificationSpan:
      overrides && overrides.hasOwnProperty('notificationSpan')
        ? overrides.notificationSpan!
        : NotificationSpanEnum.EVERY_HOUR,
    nrFirsthandHousingUnits:
      overrides && overrides.hasOwnProperty('nrFirsthandHousingUnits')
        ? overrides.nrFirsthandHousingUnits!
        : NrFirsthandHousingUnitsEnum.option_1_24,
    occupations:
      overrides && overrides.hasOwnProperty('occupations')
        ? overrides.occupations!
        : [
            relationshipsToOmit.has('Occupation')
              ? ({} as Occupation)
              : anOccupation({}, relationshipsToOmit),
          ],
    onfidoApplicantId:
      overrides && overrides.hasOwnProperty('onfidoApplicantId') ? overrides.onfidoApplicantId! : 'voluptas',
    pep: overrides && overrides.hasOwnProperty('pep') ? overrides.pep! : false,
    premium: overrides && overrides.hasOwnProperty('premium') ? overrides.premium! : true,
    private:
      overrides && overrides.hasOwnProperty('private')
        ? overrides.private!
        : relationshipsToOmit.has('UserPrivate')
          ? ({} as UserPrivate)
          : aUserPrivate({}, relationshipsToOmit),
    proAgent: overrides && overrides.hasOwnProperty('proAgent') ? overrides.proAgent! : false,
    proPilot: overrides && overrides.hasOwnProperty('proPilot') ? overrides.proPilot! : false,
    proUser: overrides && overrides.hasOwnProperty('proUser') ? overrides.proUser! : true,
    professional: overrides && overrides.hasOwnProperty('professional') ? overrides.professional! : false,
    profilePicture:
      overrides && overrides.hasOwnProperty('profilePicture')
        ? overrides.profilePicture!
        : relationshipsToOmit.has('Upload')
          ? ({} as Upload)
          : anUpload({}, relationshipsToOmit),
    prospectOnly: overrides && overrides.hasOwnProperty('prospectOnly') ? overrides.prospectOnly! : true,
    publishedHomes:
      overrides && overrides.hasOwnProperty('publishedHomes')
        ? overrides.publishedHomes!
        : [relationshipsToOmit.has('Home') ? ({} as Home) : aHome({}, relationshipsToOmit)],
    rca: overrides && overrides.hasOwnProperty('rca') ? overrides.rca! : false,
    referenceCheck:
      overrides && overrides.hasOwnProperty('referenceCheck') ? overrides.referenceCheck! : 'sit',
    removed: overrides && overrides.hasOwnProperty('removed') ? overrides.removed! : true,
    removedAt:
      overrides && overrides.hasOwnProperty('removedAt')
        ? overrides.removedAt!
        : 'autem placeat quasi quidem aliquid qui non',
    rentingOutFirsthandHomes:
      overrides && overrides.hasOwnProperty('rentingOutFirsthandHomes')
        ? overrides.rentingOutFirsthandHomes!
        : false,
    reportCount: overrides && overrides.hasOwnProperty('reportCount') ? overrides.reportCount! : 9765,
    safeRental: overrides && overrides.hasOwnProperty('safeRental') ? overrides.safeRental! : false,
    seenAt:
      overrides && overrides.hasOwnProperty('seenAt')
        ? overrides.seenAt!
        : 'et sit velit laudantium animi sed in',
    sendMonitoringEvents:
      overrides && overrides.hasOwnProperty('sendMonitoringEvents') ? overrides.sendMonitoringEvents! : false,
    showAge: overrides && overrides.hasOwnProperty('showAge') ? overrides.showAge! : false,
    smoker: overrides && overrides.hasOwnProperty('smoker') ? overrides.smoker! : true,
    studentVerification:
      overrides && overrides.hasOwnProperty('studentVerification')
        ? overrides.studentVerification!
        : relationshipsToOmit.has('StudentVerification')
          ? ({} as StudentVerification)
          : aStudentVerification({}, relationshipsToOmit),
    suspended: overrides && overrides.hasOwnProperty('suspended') ? overrides.suspended! : false,
    suspicions:
      overrides && overrides.hasOwnProperty('suspicions')
        ? overrides.suspicions!
        : relationshipsToOmit.has('SuspicionConnection')
          ? ({} as SuspicionConnection)
          : aSuspicionConnection({}, relationshipsToOmit),
    tenant: overrides && overrides.hasOwnProperty('tenant') ? overrides.tenant! : true,
    tenantAd:
      overrides && overrides.hasOwnProperty('tenantAd')
        ? overrides.tenantAd!
        : relationshipsToOmit.has('TenantAd')
          ? ({} as TenantAd)
          : aTenantAd({}, relationshipsToOmit),
    tenantProfileCompleteness:
      overrides && overrides.hasOwnProperty('tenantProfileCompleteness')
        ? overrides.tenantProfileCompleteness!
        : relationshipsToOmit.has('TenantProfileCompleteness')
          ? ({} as TenantProfileCompleteness)
          : aTenantProfileCompleteness({}, relationshipsToOmit),
    uid:
      overrides && overrides.hasOwnProperty('uid') ? overrides.uid! : '144cdb6b-749d-4736-88b8-0f921b6aa301',
    updatedAt:
      overrides && overrides.hasOwnProperty('updatedAt')
        ? overrides.updatedAt!
        : 'dolor sunt et maiores corrupti ad et',
    userNotes:
      overrides && overrides.hasOwnProperty('userNotes')
        ? overrides.userNotes!
        : [relationshipsToOmit.has('UserNote') ? ({} as UserNote) : aUserNote({}, relationshipsToOmit)],
    userReports:
      overrides && overrides.hasOwnProperty('userReports')
        ? overrides.userReports!
        : relationshipsToOmit.has('UserReportConnection')
          ? ({} as UserReportConnection)
          : aUserReportConnection({}, relationshipsToOmit),
  }
}

export const aKanyeUserConnection = (
  overrides?: Partial<KanyeUserConnection>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'KanyeUserConnection' } & KanyeUserConnection => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('KanyeUserConnection')
  return {
    __typename: 'KanyeUserConnection',
    edges:
      overrides && overrides.hasOwnProperty('edges')
        ? overrides.edges!
        : [
            relationshipsToOmit.has('KanyeUserEdge')
              ? ({} as KanyeUserEdge)
              : aKanyeUserEdge({}, relationshipsToOmit),
          ],
    nodes:
      overrides && overrides.hasOwnProperty('nodes')
        ? overrides.nodes!
        : [relationshipsToOmit.has('KanyeUser') ? ({} as KanyeUser) : aKanyeUser({}, relationshipsToOmit)],
    pageInfo:
      overrides && overrides.hasOwnProperty('pageInfo')
        ? overrides.pageInfo!
        : relationshipsToOmit.has('PageInfo')
          ? ({} as PageInfo)
          : aPageInfo({}, relationshipsToOmit),
    totalCount: overrides && overrides.hasOwnProperty('totalCount') ? overrides.totalCount! : 9660,
  }
}

export const aKanyeUserEdge = (
  overrides?: Partial<KanyeUserEdge>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'KanyeUserEdge' } & KanyeUserEdge => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('KanyeUserEdge')
  return {
    __typename: 'KanyeUserEdge',
    cursor: overrides && overrides.hasOwnProperty('cursor') ? overrides.cursor! : 'odit',
    node:
      overrides && overrides.hasOwnProperty('node')
        ? overrides.node!
        : relationshipsToOmit.has('KanyeUser')
          ? ({} as KanyeUser)
          : aKanyeUser({}, relationshipsToOmit),
  }
}

export const aLandlordContactInfo = (
  overrides?: Partial<LandlordContactInfo>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'LandlordContactInfo' } & LandlordContactInfo => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('LandlordContactInfo')
  return {
    __typename: 'LandlordContactInfo',
    familyName: overrides && overrides.hasOwnProperty('familyName') ? overrides.familyName! : 'sint',
    phoneNumber: overrides && overrides.hasOwnProperty('phoneNumber') ? overrides.phoneNumber! : 'maxime',
  }
}

export const aLastSeenMessage = (
  overrides?: Partial<LastSeenMessage>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'LastSeenMessage' } & LastSeenMessage => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('LastSeenMessage')
  return {
    __typename: 'LastSeenMessage',
    messageId:
      overrides && overrides.hasOwnProperty('messageId')
        ? overrides.messageId!
        : 'f9497330-7161-42df-b7e1-b3679a11a862',
    uid:
      overrides && overrides.hasOwnProperty('uid') ? overrides.uid! : '1fac0919-5646-43c3-80d1-8aca1ea936f1',
  }
}

export const aLink = (
  overrides?: Partial<Link>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Link' } & Link => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Link')
  return {
    __typename: 'Link',
    locale:
      overrides && overrides.hasOwnProperty('locale')
        ? overrides.locale!
        : '906c8fd7-ceb9-4178-a614-7399162a9110',
    url: overrides && overrides.hasOwnProperty('url') ? overrides.url! : 'recusandae',
  }
}

export const aLocalization = (
  overrides?: Partial<Localization>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Localization' } & Localization => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Localization')
  return {
    __typename: 'Localization',
    availableLanguages:
      overrides && overrides.hasOwnProperty('availableLanguages') ? overrides.availableLanguages! : ['et'],
    countryCode: overrides && overrides.hasOwnProperty('countryCode') ? overrides.countryCode! : 'modi',
    currency: overrides && overrides.hasOwnProperty('currency') ? overrides.currency! : 'reiciendis',
    defaultLanguage:
      overrides && overrides.hasOwnProperty('defaultLanguage') ? overrides.defaultLanguage! : 'ducimus',
  }
}

export const aLocation = (
  overrides?: Partial<Location>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Location' } & Location => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Location')
  return {
    __typename: 'Location',
    country: overrides && overrides.hasOwnProperty('country') ? overrides.country! : 'ipsam',
    countryCode: overrides && overrides.hasOwnProperty('countryCode') ? overrides.countryCode! : 'esse',
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'qui iure a omnis omnis est consequatur',
    formattedAddress:
      overrides && overrides.hasOwnProperty('formattedAddress') ? overrides.formattedAddress! : 'fugit',
    googleMapsPlaceId:
      overrides && overrides.hasOwnProperty('googleMapsPlaceId') ? overrides.googleMapsPlaceId! : 'dolorum',
    granularity:
      overrides && overrides.hasOwnProperty('granularity')
        ? overrides.granularity!
        : LocationGranularityEnum.city,
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'bfe52c08-bd42-41df-a3d4-364c80b41fe8',
    latitude: overrides && overrides.hasOwnProperty('latitude') ? overrides.latitude! : 2.76,
    locality: overrides && overrides.hasOwnProperty('locality') ? overrides.locality! : 'est',
    longitude: overrides && overrides.hasOwnProperty('longitude') ? overrides.longitude! : 9.76,
    placeId: overrides && overrides.hasOwnProperty('placeId') ? overrides.placeId! : 'sunt',
    pointsOfInterest:
      overrides && overrides.hasOwnProperty('pointsOfInterest')
        ? overrides.pointsOfInterest!
        : relationshipsToOmit.has('PointOfInterestConnection')
          ? ({} as PointOfInterestConnection)
          : aPointOfInterestConnection({}, relationshipsToOmit),
    postalCode: overrides && overrides.hasOwnProperty('postalCode') ? overrides.postalCode! : 'et',
    route: overrides && overrides.hasOwnProperty('route') ? overrides.route! : 'culpa',
    shortName: overrides && overrides.hasOwnProperty('shortName') ? overrides.shortName! : 'voluptatem',
    streetNumber:
      overrides && overrides.hasOwnProperty('streetNumber') ? overrides.streetNumber! : 'similique',
    sublocality: overrides && overrides.hasOwnProperty('sublocality') ? overrides.sublocality! : 'molestiae',
    updatedAt:
      overrides && overrides.hasOwnProperty('updatedAt')
        ? overrides.updatedAt!
        : 'culpa laboriosam molestiae dolor nulla et omnis',
  }
}

export const aLocationInput = (
  overrides?: Partial<LocationInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): LocationInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('LocationInput')
  return {
    locality: overrides && overrides.hasOwnProperty('locality') ? overrides.locality! : 'rerum',
    postalCode: overrides && overrides.hasOwnProperty('postalCode') ? overrides.postalCode! : 'vel',
    route: overrides && overrides.hasOwnProperty('route') ? overrides.route! : 'voluptatem',
    streetNumber: overrides && overrides.hasOwnProperty('streetNumber') ? overrides.streetNumber! : 'officia',
  }
}

export const aLoginPayload = (
  overrides?: Partial<LoginPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'LoginPayload' } & LoginPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('LoginPayload')
  return {
    __typename: 'LoginPayload',
    accessToken: overrides && overrides.hasOwnProperty('accessToken') ? overrides.accessToken! : 'nobis',
    user:
      overrides && overrides.hasOwnProperty('user')
        ? overrides.user!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
  }
}

export const aLoginUrl = (
  overrides?: Partial<LoginUrl>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'LoginUrl' } & LoginUrl => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('LoginUrl')
  return {
    __typename: 'LoginUrl',
    path: overrides && overrides.hasOwnProperty('path') ? overrides.path! : 'aut',
  }
}

export const aMagicNumberTooLowError = (
  overrides?: Partial<MagicNumberTooLowError>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'MagicNumberTooLowError' } & MagicNumberTooLowError => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('MagicNumberTooLowError')
  return {
    __typename: 'MagicNumberTooLowError',
    message: overrides && overrides.hasOwnProperty('message') ? overrides.message! : 'deserunt',
  }
}

export const aMarkApplicationAsSeenPayload = (
  overrides?: Partial<MarkApplicationAsSeenPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'MarkApplicationAsSeenPayload' } & MarkApplicationAsSeenPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('MarkApplicationAsSeenPayload')
  return {
    __typename: 'MarkApplicationAsSeenPayload',
    homeApplication:
      overrides && overrides.hasOwnProperty('homeApplication')
        ? overrides.homeApplication!
        : relationshipsToOmit.has('HomeApplication')
          ? ({} as HomeApplication)
          : aHomeApplication({}, relationshipsToOmit),
  }
}

export const aMarkAsPaidError = (
  overrides?: Partial<MarkAsPaidError>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'MarkAsPaidError' } & MarkAsPaidError => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('MarkAsPaidError')
  return {
    __typename: 'MarkAsPaidError',
    message: overrides && overrides.hasOwnProperty('message') ? overrides.message! : 'voluptatum',
  }
}

export const aMarkConversationInput = (
  overrides?: Partial<MarkConversationInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): MarkConversationInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('MarkConversationInput')
  return {
    conversationId:
      overrides && overrides.hasOwnProperty('conversationId')
        ? overrides.conversationId!
        : 'b8b5653f-d106-4a7d-97f3-ae1cada4d7fc',
    lastSeenMessageId:
      overrides && overrides.hasOwnProperty('lastSeenMessageId')
        ? overrides.lastSeenMessageId!
        : 'f453f6f6-9959-4859-b52f-b0974446a284',
    read: overrides && overrides.hasOwnProperty('read') ? overrides.read! : true,
  }
}

export const aMarkConversationPayload = (
  overrides?: Partial<MarkConversationPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'MarkConversationPayload' } & MarkConversationPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('MarkConversationPayload')
  return {
    __typename: 'MarkConversationPayload',
    conversation:
      overrides && overrides.hasOwnProperty('conversation')
        ? overrides.conversation!
        : relationshipsToOmit.has('Conversation')
          ? ({} as Conversation)
          : aConversation({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const aMarket = (
  overrides?: Partial<Market>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Market' } & Market => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Market')
  return {
    __typename: 'Market',
    currency:
      overrides && overrides.hasOwnProperty('currency') ? overrides.currency! : MarketCurrencyEnum.EUR,
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '69c03df6-7754-4292-b23b-f1bc80e4747d',
    instantSign:
      overrides && overrides.hasOwnProperty('instantSign')
        ? overrides.instantSign!
        : relationshipsToOmit.has('InstantSignType')
          ? ({} as InstantSignType)
          : anInstantSignType({}, relationshipsToOmit),
    invoiceSettings:
      overrides && overrides.hasOwnProperty('invoiceSettings')
        ? overrides.invoiceSettings!
        : relationshipsToOmit.has('InvoiceSettingsType')
          ? ({} as InvoiceSettingsType)
          : anInvoiceSettingsType({}, relationshipsToOmit),
    name: overrides && overrides.hasOwnProperty('name') ? overrides.name! : MarketNameEnum.finland,
    timeZone: overrides && overrides.hasOwnProperty('timeZone') ? overrides.timeZone! : 'totam',
  }
}

export const aMarketSettings = (
  overrides?: Partial<MarketSettings>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'MarketSettings' } & MarketSettings => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('MarketSettings')
  return {
    __typename: 'MarketSettings',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'd7d22efc-1ceb-464d-8fc1-84ab53fec9b9',
    marketName:
      overrides && overrides.hasOwnProperty('marketName')
        ? overrides.marketName!
        : MarketNameTypeEnum.finland,
    shortcutEnlistNewHomes:
      overrides && overrides.hasOwnProperty('shortcutEnlistNewHomes')
        ? overrides.shortcutEnlistNewHomes!
        : true,
    shortcutMaxPendingListings:
      overrides && overrides.hasOwnProperty('shortcutMaxPendingListings')
        ? overrides.shortcutMaxPendingListings!
        : 842,
    shortcutStartRange:
      overrides && overrides.hasOwnProperty('shortcutStartRange')
        ? overrides.shortcutStartRange!
        : relationshipsToOmit.has('DateRange')
          ? ({} as DateRange)
          : aDateRange({}, relationshipsToOmit),
  }
}

export const aMatchingAreaMetadata = (
  overrides?: Partial<MatchingAreaMetadata>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'MatchingAreaMetadata' } & MatchingAreaMetadata => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('MatchingAreaMetadata')
  return {
    __typename: 'MatchingAreaMetadata',
    city: overrides && overrides.hasOwnProperty('city') ? overrides.city! : 'qui',
    name: overrides && overrides.hasOwnProperty('name') ? overrides.name! : 'delectus',
    osmId:
      overrides && overrides.hasOwnProperty('osmId')
        ? overrides.osmId!
        : '16394ee2-4ae3-4656-8b46-391210e2d3a5',
    polygonCount: overrides && overrides.hasOwnProperty('polygonCount') ? overrides.polygonCount! : 4692,
  }
}

export const aMatchingAreaMetadataInput = (
  overrides?: Partial<MatchingAreaMetadataInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): MatchingAreaMetadataInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('MatchingAreaMetadataInput')
  return {
    city: overrides && overrides.hasOwnProperty('city') ? overrides.city! : 'omnis',
    name: overrides && overrides.hasOwnProperty('name') ? overrides.name! : 'deleniti',
    osmId:
      overrides && overrides.hasOwnProperty('osmId')
        ? overrides.osmId!
        : '42f1482c-fb86-4598-b83d-60a7f9f13d75',
    polygonCount: overrides && overrides.hasOwnProperty('polygonCount') ? overrides.polygonCount! : 6009,
  }
}

export const aMatchingScore = (
  overrides?: Partial<MatchingScore>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'MatchingScore' } & MatchingScore => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('MatchingScore')
  return {
    __typename: 'MatchingScore',
    homeId:
      overrides && overrides.hasOwnProperty('homeId')
        ? overrides.homeId!
        : '86bb5291-ac71-42e1-a537-0a71cbc3170d',
    score: overrides && overrides.hasOwnProperty('score') ? overrides.score! : 3907,
    uid:
      overrides && overrides.hasOwnProperty('uid') ? overrides.uid! : '56fc503a-07f3-4e70-ba88-72fdd35905be',
  }
}

export const aMessageMacro = (
  overrides?: Partial<MessageMacro>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'MessageMacro' } & MessageMacro => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('MessageMacro')
  return {
    __typename: 'MessageMacro',
    description: overrides && overrides.hasOwnProperty('description') ? overrides.description! : 'corporis',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '519538f1-bb79-495d-bf76-eff0b5322501',
    text: overrides && overrides.hasOwnProperty('text') ? overrides.text! : 'odit',
    title: overrides && overrides.hasOwnProperty('title') ? overrides.title! : 'tenetur',
  }
}

export const aMessageMacroConnection = (
  overrides?: Partial<MessageMacroConnection>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'MessageMacroConnection' } & MessageMacroConnection => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('MessageMacroConnection')
  return {
    __typename: 'MessageMacroConnection',
    edges:
      overrides && overrides.hasOwnProperty('edges')
        ? overrides.edges!
        : [
            relationshipsToOmit.has('MessageMacroEdge')
              ? ({} as MessageMacroEdge)
              : aMessageMacroEdge({}, relationshipsToOmit),
          ],
    nodes:
      overrides && overrides.hasOwnProperty('nodes')
        ? overrides.nodes!
        : [
            relationshipsToOmit.has('MessageMacro')
              ? ({} as MessageMacro)
              : aMessageMacro({}, relationshipsToOmit),
          ],
    pageInfo:
      overrides && overrides.hasOwnProperty('pageInfo')
        ? overrides.pageInfo!
        : relationshipsToOmit.has('PageInfo')
          ? ({} as PageInfo)
          : aPageInfo({}, relationshipsToOmit),
  }
}

export const aMessageMacroEdge = (
  overrides?: Partial<MessageMacroEdge>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'MessageMacroEdge' } & MessageMacroEdge => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('MessageMacroEdge')
  return {
    __typename: 'MessageMacroEdge',
    cursor: overrides && overrides.hasOwnProperty('cursor') ? overrides.cursor! : 'quia',
    node:
      overrides && overrides.hasOwnProperty('node')
        ? overrides.node!
        : relationshipsToOmit.has('MessageMacro')
          ? ({} as MessageMacro)
          : aMessageMacro({}, relationshipsToOmit),
  }
}

export const aMessagesSubscriptionPayload = (
  overrides?: Partial<MessagesSubscriptionPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'MessagesSubscriptionPayload' } & MessagesSubscriptionPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('MessagesSubscriptionPayload')
  return {
    __typename: 'MessagesSubscriptionPayload',
    unreadMessages:
      overrides && overrides.hasOwnProperty('unreadMessages')
        ? overrides.unreadMessages!
        : [
            relationshipsToOmit.has('ConversationMessage')
              ? ({} as ConversationMessage)
              : aConversationMessage({}, relationshipsToOmit),
          ],
  }
}

export const aMetadataAddressType = (
  overrides?: Partial<MetadataAddressType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'MetadataAddressType' } & MetadataAddressType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('MetadataAddressType')
  return {
    __typename: 'MetadataAddressType',
    countryCode: overrides && overrides.hasOwnProperty('countryCode') ? overrides.countryCode! : 'tenetur',
    locality: overrides && overrides.hasOwnProperty('locality') ? overrides.locality! : 'delectus',
    postalCode: overrides && overrides.hasOwnProperty('postalCode') ? overrides.postalCode! : 'maiores',
    streetAddress:
      overrides && overrides.hasOwnProperty('streetAddress') ? overrides.streetAddress! : 'recusandae',
  }
}

export const aMetadataFreeFormatAddressType = (
  overrides?: Partial<MetadataFreeFormatAddressType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'MetadataFreeFormatAddressType' } & MetadataFreeFormatAddressType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('MetadataFreeFormatAddressType')
  return {
    __typename: 'MetadataFreeFormatAddressType',
    address: overrides && overrides.hasOwnProperty('address') ? overrides.address! : 'laborum',
    countryCode: overrides && overrides.hasOwnProperty('countryCode') ? overrides.countryCode! : 'possimus',
    locality: overrides && overrides.hasOwnProperty('locality') ? overrides.locality! : 'quo',
  }
}

export const aMoney = (
  overrides?: Partial<Money>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Money' } & Money => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Money')
  return {
    __typename: 'Money',
    currency: overrides && overrides.hasOwnProperty('currency') ? overrides.currency! : CurrencyEnum.EUR,
    decimalMark: overrides && overrides.hasOwnProperty('decimalMark') ? overrides.decimalMark! : 'dicta',
    fractional: overrides && overrides.hasOwnProperty('fractional') ? overrides.fractional! : 2201,
    humanized: overrides && overrides.hasOwnProperty('humanized') ? overrides.humanized! : 'omnis',
    symbol: overrides && overrides.hasOwnProperty('symbol') ? overrides.symbol! : 'omnis',
    symbolFirst: overrides && overrides.hasOwnProperty('symbolFirst') ? overrides.symbolFirst! : false,
    thousandsSeparator:
      overrides && overrides.hasOwnProperty('thousandsSeparator') ? overrides.thousandsSeparator! : 'iusto',
  }
}

export const aMoveInfoType = (
  overrides?: Partial<MoveInfoType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'MoveInfoType' } & MoveInfoType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('MoveInfoType')
  return {
    __typename: 'MoveInfoType',
    count: overrides && overrides.hasOwnProperty('count') ? overrides.count! : 1725,
    years: overrides && overrides.hasOwnProperty('years') ? overrides.years! : 9505,
  }
}

export const aMutationRoot = (
  overrides?: Partial<MutationRoot>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'MutationRoot' } & MutationRoot => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('MutationRoot')
  return {
    __typename: 'MutationRoot',
    acceptBooking:
      overrides && overrides.hasOwnProperty('acceptBooking')
        ? overrides.acceptBooking!
        : relationshipsToOmit.has('AcceptBookingPayload')
          ? ({} as AcceptBookingPayload)
          : anAcceptBookingPayload({}, relationshipsToOmit),
    acceptHomeApplication:
      overrides && overrides.hasOwnProperty('acceptHomeApplication')
        ? overrides.acceptHomeApplication!
        : relationshipsToOmit.has('AcceptHomeApplicationPayload')
          ? ({} as AcceptHomeApplicationPayload)
          : anAcceptHomeApplicationPayload({}, relationshipsToOmit),
    acceptHomeApplications:
      overrides && overrides.hasOwnProperty('acceptHomeApplications')
        ? overrides.acceptHomeApplications!
        : relationshipsToOmit.has('AcceptHomeApplicationsPayload')
          ? ({} as AcceptHomeApplicationsPayload)
          : anAcceptHomeApplicationsPayload({}, relationshipsToOmit),
    acceptOffering:
      overrides && overrides.hasOwnProperty('acceptOffering')
        ? overrides.acceptOffering!
        : relationshipsToOmit.has('AcceptOfferingPayload')
          ? ({} as AcceptOfferingPayload)
          : anAcceptOfferingPayload({}, relationshipsToOmit),
    acceptShortcutProposal:
      overrides && overrides.hasOwnProperty('acceptShortcutProposal')
        ? overrides.acceptShortcutProposal!
        : relationshipsToOmit.has('AcceptShortcutProposalPayload')
          ? ({} as AcceptShortcutProposalPayload)
          : anAcceptShortcutProposalPayload({}, relationshipsToOmit),
    addHomeApplicationsToBoardList:
      overrides && overrides.hasOwnProperty('addHomeApplicationsToBoardList')
        ? overrides.addHomeApplicationsToBoardList!
        : relationshipsToOmit.has('AddHomeApplicationsToBoardListPayload')
          ? ({} as AddHomeApplicationsToBoardListPayload)
          : anAddHomeApplicationsToBoardListPayload({}, relationshipsToOmit),
    approveDepositPayout:
      overrides && overrides.hasOwnProperty('approveDepositPayout')
        ? overrides.approveDepositPayout!
        : relationshipsToOmit.has('ApproveDepositPayoutPayload')
          ? ({} as ApproveDepositPayoutPayload)
          : anApproveDepositPayoutPayload({}, relationshipsToOmit),
    approveIdentification:
      overrides && overrides.hasOwnProperty('approveIdentification')
        ? overrides.approveIdentification!
        : relationshipsToOmit.has('ApproveIdentificationPayload')
          ? ({} as ApproveIdentificationPayload)
          : anApproveIdentificationPayload({}, relationshipsToOmit),
    archiveConversation:
      overrides && overrides.hasOwnProperty('archiveConversation')
        ? overrides.archiveConversation!
        : relationshipsToOmit.has('ArchiveConversationPayload')
          ? ({} as ArchiveConversationPayload)
          : anArchiveConversationPayload({}, relationshipsToOmit),
    archiveHome:
      overrides && overrides.hasOwnProperty('archiveHome')
        ? overrides.archiveHome!
        : relationshipsToOmit.has('ArchiveHomePayload')
          ? ({} as ArchiveHomePayload)
          : anArchiveHomePayload({}, relationshipsToOmit),
    assignTag:
      overrides && overrides.hasOwnProperty('assignTag')
        ? overrides.assignTag!
        : relationshipsToOmit.has('AssignTagsPayload')
          ? ({} as AssignTagsPayload)
          : anAssignTagsPayload({}, relationshipsToOmit),
    bumpListing:
      overrides && overrides.hasOwnProperty('bumpListing')
        ? overrides.bumpListing!
        : relationshipsToOmit.has('BumpListingPayload')
          ? ({} as BumpListingPayload)
          : aBumpListingPayload({}, relationshipsToOmit),
    bumpListingForPremiumUser:
      overrides && overrides.hasOwnProperty('bumpListingForPremiumUser')
        ? overrides.bumpListingForPremiumUser!
        : relationshipsToOmit.has('BumpListingPayload')
          ? ({} as BumpListingPayload)
          : aBumpListingPayload({}, relationshipsToOmit),
    bumpVacationAd:
      overrides && overrides.hasOwnProperty('bumpVacationAd')
        ? overrides.bumpVacationAd!
        : relationshipsToOmit.has('BumpVacationAdPayload')
          ? ({} as BumpVacationAdPayload)
          : aBumpVacationAdPayload({}, relationshipsToOmit),
    cancelBooking:
      overrides && overrides.hasOwnProperty('cancelBooking')
        ? overrides.cancelBooking!
        : relationshipsToOmit.has('CancelBookingPayload')
          ? ({} as CancelBookingPayload)
          : aCancelBookingPayload({}, relationshipsToOmit),
    cancelContractSigning:
      overrides && overrides.hasOwnProperty('cancelContractSigning')
        ? overrides.cancelContractSigning!
        : relationshipsToOmit.has('CancelContractSigningPayload')
          ? ({} as CancelContractSigningPayload)
          : aCancelContractSigningPayload({}, relationshipsToOmit),
    cancelViewing:
      overrides && overrides.hasOwnProperty('cancelViewing')
        ? overrides.cancelViewing!
        : relationshipsToOmit.has('CancelViewingPayload')
          ? ({} as CancelViewingPayload)
          : aCancelViewingPayload({}, relationshipsToOmit),
    claimContractInvitation:
      overrides && overrides.hasOwnProperty('claimContractInvitation')
        ? overrides.claimContractInvitation!
        : relationshipsToOmit.has('ClaimContractInvitationPayload')
          ? ({} as ClaimContractInvitationPayload)
          : aClaimContractInvitationPayload({}, relationshipsToOmit),
    closeConversation:
      overrides && overrides.hasOwnProperty('closeConversation')
        ? overrides.closeConversation!
        : relationshipsToOmit.has('CloseConversationPayload')
          ? ({} as CloseConversationPayload)
          : aCloseConversationPayload({}, relationshipsToOmit),
    completeOffering:
      overrides && overrides.hasOwnProperty('completeOffering')
        ? overrides.completeOffering!
        : relationshipsToOmit.has('CompleteOfferingPayload')
          ? ({} as CompleteOfferingPayload)
          : aCompleteOfferingPayload({}, relationshipsToOmit),
    confirmEmail:
      overrides && overrides.hasOwnProperty('confirmEmail')
        ? overrides.confirmEmail!
        : relationshipsToOmit.has('ConfirmEmailPayload')
          ? ({} as ConfirmEmailPayload)
          : aConfirmEmailPayload({}, relationshipsToOmit),
    connectHomeqUser:
      overrides && overrides.hasOwnProperty('connectHomeqUser')
        ? overrides.connectHomeqUser!
        : relationshipsToOmit.has('ConnectHubHomeQConnectUserPayload')
          ? ({} as ConnectHubHomeQConnectUserPayload)
          : aConnectHubHomeQConnectUserPayload({}, relationshipsToOmit),
    createAccessToken:
      overrides && overrides.hasOwnProperty('createAccessToken')
        ? overrides.createAccessToken!
        : relationshipsToOmit.has('AccessToken')
          ? ({} as AccessToken)
          : anAccessToken({}, relationshipsToOmit),
    createAdminAccessToken:
      overrides && overrides.hasOwnProperty('createAdminAccessToken')
        ? overrides.createAdminAccessToken!
        : relationshipsToOmit.has('AccessToken')
          ? ({} as AccessToken)
          : anAccessToken({}, relationshipsToOmit),
    createAdminNote:
      overrides && overrides.hasOwnProperty('createAdminNote')
        ? overrides.createAdminNote!
        : relationshipsToOmit.has('CreateAdminNotePayload')
          ? ({} as CreateAdminNotePayload)
          : aCreateAdminNotePayload({}, relationshipsToOmit),
    createApplicationBoardList:
      overrides && overrides.hasOwnProperty('createApplicationBoardList')
        ? overrides.createApplicationBoardList!
        : relationshipsToOmit.has('CreateApplicationBoardListPayload')
          ? ({} as CreateApplicationBoardListPayload)
          : aCreateApplicationBoardListPayload({}, relationshipsToOmit),
    createBlob:
      overrides && overrides.hasOwnProperty('createBlob')
        ? overrides.createBlob!
        : relationshipsToOmit.has('CreateBlobPayload')
          ? ({} as CreateBlobPayload)
          : aCreateBlobPayload({}, relationshipsToOmit),
    createBookingOffer:
      overrides && overrides.hasOwnProperty('createBookingOffer')
        ? overrides.createBookingOffer!
        : relationshipsToOmit.has('CreateBookingOfferPayload')
          ? ({} as CreateBookingOfferPayload)
          : aCreateBookingOfferPayload({}, relationshipsToOmit),
    createBookingOfferFromBooking:
      overrides && overrides.hasOwnProperty('createBookingOfferFromBooking')
        ? overrides.createBookingOfferFromBooking!
        : relationshipsToOmit.has('CreateBookingOfferFromBookingPayload')
          ? ({} as CreateBookingOfferFromBookingPayload)
          : aCreateBookingOfferFromBookingPayload({}, relationshipsToOmit),
    createCertificate:
      overrides && overrides.hasOwnProperty('createCertificate')
        ? overrides.createCertificate!
        : relationshipsToOmit.has('CreateCertificatePayload')
          ? ({} as CreateCertificatePayload)
          : aCreateCertificatePayload({}, relationshipsToOmit),
    createCompensationClaim:
      overrides && overrides.hasOwnProperty('createCompensationClaim')
        ? overrides.createCompensationClaim!
        : relationshipsToOmit.has('CreateCompensationClaimPayload')
          ? ({} as CreateCompensationClaimPayload)
          : aCreateCompensationClaimPayload({}, relationshipsToOmit),
    createCompensationReservations:
      overrides && overrides.hasOwnProperty('createCompensationReservations')
        ? overrides.createCompensationReservations!
        : relationshipsToOmit.has('CreateCompensationReservationsPayload')
          ? ({} as CreateCompensationReservationsPayload)
          : aCreateCompensationReservationsPayload({}, relationshipsToOmit),
    createConversationMessage:
      overrides && overrides.hasOwnProperty('createConversationMessage')
        ? overrides.createConversationMessage!
        : relationshipsToOmit.has('CreateConversationMessagePayload')
          ? ({} as CreateConversationMessagePayload)
          : aCreateConversationMessagePayload({}, relationshipsToOmit),
    createCreditReport:
      overrides && overrides.hasOwnProperty('createCreditReport')
        ? overrides.createCreditReport!
        : relationshipsToOmit.has('CreateCreditReportPayload')
          ? ({} as CreateCreditReportPayload)
          : aCreateCreditReportPayload({}, relationshipsToOmit),
    createExternalContractTakeover:
      overrides && overrides.hasOwnProperty('createExternalContractTakeover')
        ? overrides.createExternalContractTakeover!
        : relationshipsToOmit.has('FranceCreateExternalContractTakeoverPayload')
          ? ({} as FranceCreateExternalContractTakeoverPayload)
          : aFranceCreateExternalContractTakeoverPayload({}, relationshipsToOmit),
    createHome:
      overrides && overrides.hasOwnProperty('createHome')
        ? overrides.createHome!
        : relationshipsToOmit.has('CreateHomePayload')
          ? ({} as CreateHomePayload)
          : aCreateHomePayload({}, relationshipsToOmit),
    createHomeFromTemplate:
      overrides && overrides.hasOwnProperty('createHomeFromTemplate')
        ? overrides.createHomeFromTemplate!
        : relationshipsToOmit.has('CreateHomeFromTemplatePayload')
          ? ({} as CreateHomeFromTemplatePayload)
          : aCreateHomeFromTemplatePayload({}, relationshipsToOmit),
    createHomeqApplication:
      overrides && overrides.hasOwnProperty('createHomeqApplication')
        ? overrides.createHomeqApplication!
        : relationshipsToOmit.has('ConnectHubHomeQCreateApplicationPayload')
          ? ({} as ConnectHubHomeQCreateApplicationPayload)
          : aConnectHubHomeQCreateApplicationPayload({}, relationshipsToOmit),
    createHomeqClaim:
      overrides && overrides.hasOwnProperty('createHomeqClaim')
        ? overrides.createHomeqClaim!
        : relationshipsToOmit.has('CreateClaimPayload')
          ? ({} as CreateClaimPayload)
          : aCreateClaimPayload({}, relationshipsToOmit),
    createHomeqFileReference:
      overrides && overrides.hasOwnProperty('createHomeqFileReference')
        ? overrides.createHomeqFileReference!
        : relationshipsToOmit.has('ConnectHubHomeQCreateFileReferencePayload')
          ? ({} as ConnectHubHomeQCreateFileReferencePayload)
          : aConnectHubHomeQCreateFileReferencePayload({}, relationshipsToOmit),
    createInstantSignContract:
      overrides && overrides.hasOwnProperty('createInstantSignContract')
        ? overrides.createInstantSignContract!
        : relationshipsToOmit.has('CreateInstantSignContractPayload')
          ? ({} as CreateInstantSignContractPayload)
          : aCreateInstantSignContractPayload({}, relationshipsToOmit),
    createLandlordHomeApplication:
      overrides && overrides.hasOwnProperty('createLandlordHomeApplication')
        ? overrides.createLandlordHomeApplication!
        : relationshipsToOmit.has('CreateLandlordHomeApplicationPayload')
          ? ({} as CreateLandlordHomeApplicationPayload)
          : aCreateLandlordHomeApplicationPayload({}, relationshipsToOmit),
    createListingPlatformSelectorEvent:
      overrides && overrides.hasOwnProperty('createListingPlatformSelectorEvent')
        ? overrides.createListingPlatformSelectorEvent!
        : relationshipsToOmit.has('CreateListingPlatformSelectorEventPayload')
          ? ({} as CreateListingPlatformSelectorEventPayload)
          : aCreateListingPlatformSelectorEventPayload({}, relationshipsToOmit),
    createMatchingScoreFeedback:
      overrides && overrides.hasOwnProperty('createMatchingScoreFeedback')
        ? overrides.createMatchingScoreFeedback!
        : relationshipsToOmit.has('CreateMatchingScoreFeedbackPayload')
          ? ({} as CreateMatchingScoreFeedbackPayload)
          : aCreateMatchingScoreFeedbackPayload({}, relationshipsToOmit),
    createOffering:
      overrides && overrides.hasOwnProperty('createOffering')
        ? overrides.createOffering!
        : relationshipsToOmit.has('CreateOfferingPayload')
          ? ({} as CreateOfferingPayload)
          : aCreateOfferingPayload({}, relationshipsToOmit),
    createOnfidoVerificationLink:
      overrides && overrides.hasOwnProperty('createOnfidoVerificationLink')
        ? overrides.createOnfidoVerificationLink!
        : relationshipsToOmit.has('CreateOnfidoVerificationLinkPayload')
          ? ({} as CreateOnfidoVerificationLinkPayload)
          : aCreateOnfidoVerificationLinkPayload({}, relationshipsToOmit),
    createPublicDocument:
      overrides && overrides.hasOwnProperty('createPublicDocument')
        ? overrides.createPublicDocument!
        : relationshipsToOmit.has('AuthorizationError')
          ? ({} as AuthorizationError)
          : anAuthorizationError({}, relationshipsToOmit),
    createSavedSearch:
      overrides && overrides.hasOwnProperty('createSavedSearch')
        ? overrides.createSavedSearch!
        : relationshipsToOmit.has('CreateSavedSearchPayload')
          ? ({} as CreateSavedSearchPayload)
          : aCreateSavedSearchPayload({}, relationshipsToOmit),
    createSavingsVerification:
      overrides && overrides.hasOwnProperty('createSavingsVerification')
        ? overrides.createSavingsVerification!
        : relationshipsToOmit.has('CreateSavingsVerificationPayload')
          ? ({} as CreateSavingsVerificationPayload)
          : aCreateSavingsVerificationPayload({}, relationshipsToOmit),
    createSubUser:
      overrides && overrides.hasOwnProperty('createSubUser')
        ? overrides.createSubUser!
        : relationshipsToOmit.has('CreateSubUserPayload')
          ? ({} as CreateSubUserPayload)
          : aCreateSubUserPayload({}, relationshipsToOmit),
    createTenantHomeApplication:
      overrides && overrides.hasOwnProperty('createTenantHomeApplication')
        ? overrides.createTenantHomeApplication!
        : relationshipsToOmit.has('CreateTenantHomeApplicationPayload')
          ? ({} as CreateTenantHomeApplicationPayload)
          : aCreateTenantHomeApplicationPayload({}, relationshipsToOmit),
    createTenantInvitation:
      overrides && overrides.hasOwnProperty('createTenantInvitation')
        ? overrides.createTenantInvitation!
        : relationshipsToOmit.has('CreateTenantInvitationPayload')
          ? ({} as CreateTenantInvitationPayload)
          : aCreateTenantInvitationPayload({}, relationshipsToOmit),
    createUpload:
      overrides && overrides.hasOwnProperty('createUpload')
        ? overrides.createUpload!
        : relationshipsToOmit.has('CreateUploadPayload')
          ? ({} as CreateUploadPayload)
          : aCreateUploadPayload({}, relationshipsToOmit),
    createUserNote:
      overrides && overrides.hasOwnProperty('createUserNote')
        ? overrides.createUserNote!
        : relationshipsToOmit.has('CreateUserNotePayload')
          ? ({} as CreateUserNotePayload)
          : aCreateUserNotePayload({}, relationshipsToOmit),
    createViewing:
      overrides && overrides.hasOwnProperty('createViewing')
        ? overrides.createViewing!
        : relationshipsToOmit.has('CreateViewingPayload')
          ? ({} as CreateViewingPayload)
          : aCreateViewingPayload({}, relationshipsToOmit),
    declineHomeApplication:
      overrides && overrides.hasOwnProperty('declineHomeApplication')
        ? overrides.declineHomeApplication!
        : relationshipsToOmit.has('DeclineHomeApplicationPayload')
          ? ({} as DeclineHomeApplicationPayload)
          : aDeclineHomeApplicationPayload({}, relationshipsToOmit),
    declineHomeApplications:
      overrides && overrides.hasOwnProperty('declineHomeApplications')
        ? overrides.declineHomeApplications!
        : relationshipsToOmit.has('DeclineHomeApplicationsPayload')
          ? ({} as DeclineHomeApplicationsPayload)
          : aDeclineHomeApplicationsPayload({}, relationshipsToOmit),
    declineOffering:
      overrides && overrides.hasOwnProperty('declineOffering')
        ? overrides.declineOffering!
        : relationshipsToOmit.has('DeclineOfferingPayload')
          ? ({} as DeclineOfferingPayload)
          : aDeclineOfferingPayload({}, relationshipsToOmit),
    declineOrRevokeBooking:
      overrides && overrides.hasOwnProperty('declineOrRevokeBooking')
        ? overrides.declineOrRevokeBooking!
        : relationshipsToOmit.has('DeclineOrRevokeBookingPayload')
          ? ({} as DeclineOrRevokeBookingPayload)
          : aDeclineOrRevokeBookingPayload({}, relationshipsToOmit),
    declineShortcutProposal:
      overrides && overrides.hasOwnProperty('declineShortcutProposal')
        ? overrides.declineShortcutProposal!
        : relationshipsToOmit.has('DeclineShortcutProposalPayload')
          ? ({} as DeclineShortcutProposalPayload)
          : aDeclineShortcutProposalPayload({}, relationshipsToOmit),
    deleteHome:
      overrides && overrides.hasOwnProperty('deleteHome')
        ? overrides.deleteHome!
        : relationshipsToOmit.has('DeleteHomePayload')
          ? ({} as DeleteHomePayload)
          : aDeleteHomePayload({}, relationshipsToOmit),
    deleteHomeqApplicationInterest:
      overrides && overrides.hasOwnProperty('deleteHomeqApplicationInterest')
        ? overrides.deleteHomeqApplicationInterest!
        : relationshipsToOmit.has('ConnectHubHomeQDeleteApplicationInterestPayload')
          ? ({} as ConnectHubHomeQDeleteApplicationInterestPayload)
          : aConnectHubHomeQDeleteApplicationInterestPayload({}, relationshipsToOmit),
    deleteHomeqClaim:
      overrides && overrides.hasOwnProperty('deleteHomeqClaim')
        ? overrides.deleteHomeqClaim!
        : relationshipsToOmit.has('ConnectHubHomeQDeleteClaimPayload')
          ? ({} as ConnectHubHomeQDeleteClaimPayload)
          : aConnectHubHomeQDeleteClaimPayload({}, relationshipsToOmit),
    denyDepositPayout:
      overrides && overrides.hasOwnProperty('denyDepositPayout')
        ? overrides.denyDepositPayout!
        : relationshipsToOmit.has('DenyDepositPayoutPayload')
          ? ({} as DenyDepositPayoutPayload)
          : aDenyDepositPayoutPayload({}, relationshipsToOmit),
    destroyAdminNote:
      overrides && overrides.hasOwnProperty('destroyAdminNote')
        ? overrides.destroyAdminNote!
        : relationshipsToOmit.has('DestroyAdminNotePayload')
          ? ({} as DestroyAdminNotePayload)
          : aDestroyAdminNotePayload({}, relationshipsToOmit),
    destroyApplicationBoardList:
      overrides && overrides.hasOwnProperty('destroyApplicationBoardList')
        ? overrides.destroyApplicationBoardList!
        : relationshipsToOmit.has('DestroyApplicationBoardListPayload')
          ? ({} as DestroyApplicationBoardListPayload)
          : aDestroyApplicationBoardListPayload({}, relationshipsToOmit),
    destroyCreditReport:
      overrides && overrides.hasOwnProperty('destroyCreditReport')
        ? overrides.destroyCreditReport!
        : relationshipsToOmit.has('DestroyCreditReportPayload')
          ? ({} as DestroyCreditReportPayload)
          : aDestroyCreditReportPayload({}, relationshipsToOmit),
    destroyEmployeeGroup:
      overrides && overrides.hasOwnProperty('destroyEmployeeGroup')
        ? overrides.destroyEmployeeGroup!
        : relationshipsToOmit.has('AuthorizationError')
          ? ({} as AuthorizationError)
          : anAuthorizationError({}, relationshipsToOmit),
    destroyHomeAvailability:
      overrides && overrides.hasOwnProperty('destroyHomeAvailability')
        ? overrides.destroyHomeAvailability!
        : relationshipsToOmit.has('DestroyHomeAvailabilityPayload')
          ? ({} as DestroyHomeAvailabilityPayload)
          : aDestroyHomeAvailabilityPayload({}, relationshipsToOmit),
    destroyIncomeVerification:
      overrides && overrides.hasOwnProperty('destroyIncomeVerification')
        ? overrides.destroyIncomeVerification!
        : relationshipsToOmit.has('DestroyIncomeVerificationPayload')
          ? ({} as DestroyIncomeVerificationPayload)
          : aDestroyIncomeVerificationPayload({}, relationshipsToOmit),
    destroyMessageMacro:
      overrides && overrides.hasOwnProperty('destroyMessageMacro')
        ? overrides.destroyMessageMacro!
        : relationshipsToOmit.has('DestroyMessageMacroPayload')
          ? ({} as DestroyMessageMacroPayload)
          : aDestroyMessageMacroPayload({}, relationshipsToOmit),
    destroyOccupation:
      overrides && overrides.hasOwnProperty('destroyOccupation')
        ? overrides.destroyOccupation!
        : relationshipsToOmit.has('DestroyOccupationPayload')
          ? ({} as DestroyOccupationPayload)
          : aDestroyOccupationPayload({}, relationshipsToOmit),
    destroyOccupationVerification:
      overrides && overrides.hasOwnProperty('destroyOccupationVerification')
        ? overrides.destroyOccupationVerification!
        : relationshipsToOmit.has('DestroyOccupationVerificationPayload')
          ? ({} as DestroyOccupationVerificationPayload)
          : aDestroyOccupationVerificationPayload({}, relationshipsToOmit),
    destroyPublicDocument:
      overrides && overrides.hasOwnProperty('destroyPublicDocument')
        ? overrides.destroyPublicDocument!
        : relationshipsToOmit.has('AuthorizationError')
          ? ({} as AuthorizationError)
          : anAuthorizationError({}, relationshipsToOmit),
    destroySavedSearch:
      overrides && overrides.hasOwnProperty('destroySavedSearch')
        ? overrides.destroySavedSearch!
        : relationshipsToOmit.has('DestroySavedSearchPayload')
          ? ({} as DestroySavedSearchPayload)
          : aDestroySavedSearchPayload({}, relationshipsToOmit),
    destroyUpload:
      overrides && overrides.hasOwnProperty('destroyUpload')
        ? overrides.destroyUpload!
        : relationshipsToOmit.has('DestroyUploadPayload')
          ? ({} as DestroyUploadPayload)
          : aDestroyUploadPayload({}, relationshipsToOmit),
    destroyUser:
      overrides && overrides.hasOwnProperty('destroyUser')
        ? overrides.destroyUser!
        : relationshipsToOmit.has('DestroyUserPayload')
          ? ({} as DestroyUserPayload)
          : aDestroyUserPayload({}, relationshipsToOmit),
    destroyUserNote:
      overrides && overrides.hasOwnProperty('destroyUserNote')
        ? overrides.destroyUserNote!
        : relationshipsToOmit.has('DestroyUserNotePayload')
          ? ({} as DestroyUserNotePayload)
          : aDestroyUserNotePayload({}, relationshipsToOmit),
    destroyViewingSlots:
      overrides && overrides.hasOwnProperty('destroyViewingSlots')
        ? overrides.destroyViewingSlots!
        : relationshipsToOmit.has('AuthorizationError')
          ? ({} as AuthorizationError)
          : anAuthorizationError({}, relationshipsToOmit),
    discardIdentification:
      overrides && overrides.hasOwnProperty('discardIdentification')
        ? overrides.discardIdentification!
        : relationshipsToOmit.has('DiscardIdentificationPayload')
          ? ({} as DiscardIdentificationPayload)
          : aDiscardIdentificationPayload({}, relationshipsToOmit),
    disconnectHomeqUser:
      overrides && overrides.hasOwnProperty('disconnectHomeqUser')
        ? overrides.disconnectHomeqUser!
        : relationshipsToOmit.has('ConnectHubHomeQDisconnectUserPayload')
          ? ({} as ConnectHubHomeQDisconnectUserPayload)
          : aConnectHubHomeQDisconnectUserPayload({}, relationshipsToOmit),
    duplicateHome:
      overrides && overrides.hasOwnProperty('duplicateHome')
        ? overrides.duplicateHome!
        : relationshipsToOmit.has('DuplicateHomePayload')
          ? ({} as DuplicateHomePayload)
          : aDuplicateHomePayload({}, relationshipsToOmit),
    enrollHomeInShortcut:
      overrides && overrides.hasOwnProperty('enrollHomeInShortcut')
        ? overrides.enrollHomeInShortcut!
        : relationshipsToOmit.has('EnrollHomeInShortcutPayload')
          ? ({} as EnrollHomeInShortcutPayload)
          : anEnrollHomeInShortcutPayload({}, relationshipsToOmit),
    evaluateHomeForShortcut:
      overrides && overrides.hasOwnProperty('evaluateHomeForShortcut')
        ? overrides.evaluateHomeForShortcut!
        : relationshipsToOmit.has('EvaluateHomeForShortcutPayload')
          ? ({} as EvaluateHomeForShortcutPayload)
          : anEvaluateHomeForShortcutPayload({}, relationshipsToOmit),
    exchangeLoginToken:
      overrides && overrides.hasOwnProperty('exchangeLoginToken')
        ? overrides.exchangeLoginToken!
        : relationshipsToOmit.has('ExchangeLoginTokenPayload')
          ? ({} as ExchangeLoginTokenPayload)
          : anExchangeLoginTokenPayload({}, relationshipsToOmit),
    exchangeSignInCode:
      overrides && overrides.hasOwnProperty('exchangeSignInCode')
        ? overrides.exchangeSignInCode!
        : relationshipsToOmit.has('ExchangeSignInCodePayload')
          ? ({} as ExchangeSignInCodePayload)
          : anExchangeSignInCodePayload({}, relationshipsToOmit),
    extendContract:
      overrides && overrides.hasOwnProperty('extendContract')
        ? overrides.extendContract!
        : relationshipsToOmit.has('ExtendContractPayload')
          ? ({} as ExtendContractPayload)
          : anExtendContractPayload({}, relationshipsToOmit),
    favoriteMarkHome:
      overrides && overrides.hasOwnProperty('favoriteMarkHome')
        ? overrides.favoriteMarkHome!
        : relationshipsToOmit.has('FavoriteMarkHomePayload')
          ? ({} as FavoriteMarkHomePayload)
          : aFavoriteMarkHomePayload({}, relationshipsToOmit),
    finalizeUpload:
      overrides && overrides.hasOwnProperty('finalizeUpload')
        ? overrides.finalizeUpload!
        : relationshipsToOmit.has('FinalizeUploadPayload')
          ? ({} as FinalizeUploadPayload)
          : aFinalizeUploadPayload({}, relationshipsToOmit),
    financeTestModePayInvoice:
      overrides && overrides.hasOwnProperty('financeTestModePayInvoice')
        ? overrides.financeTestModePayInvoice!
        : relationshipsToOmit.has('FinanceTestModePayInvoicePayload')
          ? ({} as FinanceTestModePayInvoicePayload)
          : aFinanceTestModePayInvoicePayload({}, relationshipsToOmit),
    finlandUpsertContract:
      overrides && overrides.hasOwnProperty('finlandUpsertContract')
        ? overrides.finlandUpsertContract!
        : relationshipsToOmit.has('FinlandUpsertContractPayload')
          ? ({} as FinlandUpsertContractPayload)
          : aFinlandUpsertContractPayload({}, relationshipsToOmit),
    franceCancelContractDraft:
      overrides && overrides.hasOwnProperty('franceCancelContractDraft')
        ? overrides.franceCancelContractDraft!
        : relationshipsToOmit.has('FranceCancelContractDraftPayload')
          ? ({} as FranceCancelContractDraftPayload)
          : aFranceCancelContractDraftPayload({}, relationshipsToOmit),
    franceCreateInsuranceRequest:
      overrides && overrides.hasOwnProperty('franceCreateInsuranceRequest')
        ? overrides.franceCreateInsuranceRequest!
        : relationshipsToOmit.has('FranceCreateInsuranceRequestPayload')
          ? ({} as FranceCreateInsuranceRequestPayload)
          : aFranceCreateInsuranceRequestPayload({}, relationshipsToOmit),
    franceInitiateContractSigning:
      overrides && overrides.hasOwnProperty('franceInitiateContractSigning')
        ? overrides.franceInitiateContractSigning!
        : relationshipsToOmit.has('FranceInitiateContractSigningPayload')
          ? ({} as FranceInitiateContractSigningPayload)
          : aFranceInitiateContractSigningPayload({}, relationshipsToOmit),
    franceTransitionInsuranceRequest:
      overrides && overrides.hasOwnProperty('franceTransitionInsuranceRequest')
        ? overrides.franceTransitionInsuranceRequest!
        : relationshipsToOmit.has('FranceTransitionInsuranceRequestPayload')
          ? ({} as FranceTransitionInsuranceRequestPayload)
          : aFranceTransitionInsuranceRequestPayload({}, relationshipsToOmit),
    franceUpdateDocumentRequest:
      overrides && overrides.hasOwnProperty('franceUpdateDocumentRequest')
        ? overrides.franceUpdateDocumentRequest!
        : relationshipsToOmit.has('FranceUpdateDocumentRequestPayload')
          ? ({} as FranceUpdateDocumentRequestPayload)
          : aFranceUpdateDocumentRequestPayload({}, relationshipsToOmit),
    franceUpsertContract:
      overrides && overrides.hasOwnProperty('franceUpsertContract')
        ? overrides.franceUpsertContract!
        : relationshipsToOmit.has('FranceUpsertContractPayload')
          ? ({} as FranceUpsertContractPayload)
          : aFranceUpsertContractPayload({}, relationshipsToOmit),
    identify:
      overrides && overrides.hasOwnProperty('identify')
        ? overrides.identify!
        : relationshipsToOmit.has('IdentifyPayload')
          ? ({} as IdentifyPayload)
          : anIdentifyPayload({}, relationshipsToOmit),
    ifInsurancePromotionSignUp:
      overrides && overrides.hasOwnProperty('ifInsurancePromotionSignUp')
        ? overrides.ifInsurancePromotionSignUp!
        : relationshipsToOmit.has('IfInsurancePromotionSignUpPayload')
          ? ({} as IfInsurancePromotionSignUpPayload)
          : anIfInsurancePromotionSignUpPayload({}, relationshipsToOmit),
    importTinkBankAccount:
      overrides && overrides.hasOwnProperty('importTinkBankAccount')
        ? overrides.importTinkBankAccount!
        : relationshipsToOmit.has('ImportTinkBankAccountPayload')
          ? ({} as ImportTinkBankAccountPayload)
          : anImportTinkBankAccountPayload({}, relationshipsToOmit),
    initiateBookingCardPayment:
      overrides && overrides.hasOwnProperty('initiateBookingCardPayment')
        ? overrides.initiateBookingCardPayment!
        : relationshipsToOmit.has('InitiateBookingCardPaymentPayload')
          ? ({} as InitiateBookingCardPaymentPayload)
          : anInitiateBookingCardPaymentPayload({}, relationshipsToOmit),
    initiateContractSigning:
      overrides && overrides.hasOwnProperty('initiateContractSigning')
        ? overrides.initiateContractSigning!
        : relationshipsToOmit.has('InitiateContractSigningPayload')
          ? ({} as InitiateContractSigningPayload)
          : anInitiateContractSigningPayload({}, relationshipsToOmit),
    initiateListingBumpPayment:
      overrides && overrides.hasOwnProperty('initiateListingBumpPayment')
        ? overrides.initiateListingBumpPayment!
        : relationshipsToOmit.has('InitiateListingBumpPaymentPayload')
          ? ({} as InitiateListingBumpPaymentPayload)
          : anInitiateListingBumpPaymentPayload({}, relationshipsToOmit),
    initiateListingPublicationFeePayment:
      overrides && overrides.hasOwnProperty('initiateListingPublicationFeePayment')
        ? overrides.initiateListingPublicationFeePayment!
        : relationshipsToOmit.has('InitiateListingPublicationFeePaymentPayload')
          ? ({} as InitiateListingPublicationFeePaymentPayload)
          : anInitiateListingPublicationFeePaymentPayload({}, relationshipsToOmit),
    initiateOnfidoCheck:
      overrides && overrides.hasOwnProperty('initiateOnfidoCheck')
        ? overrides.initiateOnfidoCheck!
        : relationshipsToOmit.has('InitiateOnfidoCheckPayload')
          ? ({} as InitiateOnfidoCheckPayload)
          : anInitiateOnfidoCheckPayload({}, relationshipsToOmit),
    initiateSwishPayment:
      overrides && overrides.hasOwnProperty('initiateSwishPayment')
        ? overrides.initiateSwishPayment!
        : relationshipsToOmit.has('InitiateSwishPaymentPayload')
          ? ({} as InitiateSwishPaymentPayload)
          : anInitiateSwishPaymentPayload({}, relationshipsToOmit),
    initiateTinkIncomeCheck:
      overrides && overrides.hasOwnProperty('initiateTinkIncomeCheck')
        ? overrides.initiateTinkIncomeCheck!
        : relationshipsToOmit.has('InitiateTinkIncomeCheckPayload')
          ? ({} as InitiateTinkIncomeCheckPayload)
          : anInitiateTinkIncomeCheckPayload({}, relationshipsToOmit),
    markApplicationAsSeen:
      overrides && overrides.hasOwnProperty('markApplicationAsSeen')
        ? overrides.markApplicationAsSeen!
        : relationshipsToOmit.has('MarkApplicationAsSeenPayload')
          ? ({} as MarkApplicationAsSeenPayload)
          : aMarkApplicationAsSeenPayload({}, relationshipsToOmit),
    markConversation:
      overrides && overrides.hasOwnProperty('markConversation')
        ? overrides.markConversation!
        : relationshipsToOmit.has('MarkConversationPayload')
          ? ({} as MarkConversationPayload)
          : aMarkConversationPayload({}, relationshipsToOmit),
    markInvoiceAsPaid:
      overrides && overrides.hasOwnProperty('markInvoiceAsPaid')
        ? overrides.markInvoiceAsPaid!
        : relationshipsToOmit.has('AuthorizationError')
          ? ({} as AuthorizationError)
          : anAuthorizationError({}, relationshipsToOmit),
    milaTestCommand:
      overrides && overrides.hasOwnProperty('milaTestCommand')
        ? overrides.milaTestCommand!
        : relationshipsToOmit.has('AuthorizationError')
          ? ({} as AuthorizationError)
          : anAuthorizationError({}, relationshipsToOmit),
    openConversation:
      overrides && overrides.hasOwnProperty('openConversation')
        ? overrides.openConversation!
        : relationshipsToOmit.has('OpenConversationPayload')
          ? ({} as OpenConversationPayload)
          : anOpenConversationPayload({}, relationshipsToOmit),
    payoutCompensationClaimReservation:
      overrides && overrides.hasOwnProperty('payoutCompensationClaimReservation')
        ? overrides.payoutCompensationClaimReservation!
        : relationshipsToOmit.has('PayoutCompensationClaimReservationPayload')
          ? ({} as PayoutCompensationClaimReservationPayload)
          : aPayoutCompensationClaimReservationPayload({}, relationshipsToOmit),
    proContactForm:
      overrides && overrides.hasOwnProperty('proContactForm')
        ? overrides.proContactForm!
        : relationshipsToOmit.has('ProContactFormPayload')
          ? ({} as ProContactFormPayload)
          : aProContactFormPayload({}, relationshipsToOmit),
    publishHome:
      overrides && overrides.hasOwnProperty('publishHome')
        ? overrides.publishHome!
        : relationshipsToOmit.has('PublishHomePayload')
          ? ({} as PublishHomePayload)
          : aPublishHomePayload({}, relationshipsToOmit),
    publishHomeV2:
      overrides && overrides.hasOwnProperty('publishHomeV2')
        ? overrides.publishHomeV2!
        : relationshipsToOmit.has('AuthorizationError')
          ? ({} as AuthorizationError)
          : anAuthorizationError({}, relationshipsToOmit),
    publishTenantAd:
      overrides && overrides.hasOwnProperty('publishTenantAd')
        ? overrides.publishTenantAd!
        : relationshipsToOmit.has('PublishTenantAdPayload')
          ? ({} as PublishTenantAdPayload)
          : aPublishTenantAdPayload({}, relationshipsToOmit),
    rejectIdentification:
      overrides && overrides.hasOwnProperty('rejectIdentification')
        ? overrides.rejectIdentification!
        : relationshipsToOmit.has('RejectIdentificationPayload')
          ? ({} as RejectIdentificationPayload)
          : aRejectIdentificationPayload({}, relationshipsToOmit),
    removeCosigningTenant:
      overrides && overrides.hasOwnProperty('removeCosigningTenant')
        ? overrides.removeCosigningTenant!
        : relationshipsToOmit.has('RemoveCosigningTenantPayload')
          ? ({} as RemoveCosigningTenantPayload)
          : aRemoveCosigningTenantPayload({}, relationshipsToOmit),
    removeHomeApplicationsFromBoardList:
      overrides && overrides.hasOwnProperty('removeHomeApplicationsFromBoardList')
        ? overrides.removeHomeApplicationsFromBoardList!
        : relationshipsToOmit.has('RemoveHomeApplicationsFromBoardListPayload')
          ? ({} as RemoveHomeApplicationsFromBoardListPayload)
          : aRemoveHomeApplicationsFromBoardListPayload({}, relationshipsToOmit),
    renewHomeqClaim:
      overrides && overrides.hasOwnProperty('renewHomeqClaim')
        ? overrides.renewHomeqClaim!
        : relationshipsToOmit.has('ConnectHubHomeQRenewClaimPayload')
          ? ({} as ConnectHubHomeQRenewClaimPayload)
          : aConnectHubHomeQRenewClaimPayload({}, relationshipsToOmit),
    reportUser:
      overrides && overrides.hasOwnProperty('reportUser')
        ? overrides.reportUser!
        : relationshipsToOmit.has('ReportUserPayload')
          ? ({} as ReportUserPayload)
          : aReportUserPayload({}, relationshipsToOmit),
    resendConfirmBankAccountEmail:
      overrides && overrides.hasOwnProperty('resendConfirmBankAccountEmail')
        ? overrides.resendConfirmBankAccountEmail!
        : relationshipsToOmit.has('ResendConfirmBankAccountEmailPayload')
          ? ({} as ResendConfirmBankAccountEmailPayload)
          : aResendConfirmBankAccountEmailPayload({}, relationshipsToOmit),
    revokeHomeApplication:
      overrides && overrides.hasOwnProperty('revokeHomeApplication')
        ? overrides.revokeHomeApplication!
        : relationshipsToOmit.has('RevokeHomeApplicationPayload')
          ? ({} as RevokeHomeApplicationPayload)
          : aRevokeHomeApplicationPayload({}, relationshipsToOmit),
    revokeOffering:
      overrides && overrides.hasOwnProperty('revokeOffering')
        ? overrides.revokeOffering!
        : relationshipsToOmit.has('RevokeOfferingPayload')
          ? ({} as RevokeOfferingPayload)
          : aRevokeOfferingPayload({}, relationshipsToOmit),
    schibstedLogin:
      overrides && overrides.hasOwnProperty('schibstedLogin')
        ? overrides.schibstedLogin!
        : relationshipsToOmit.has('SchibstedLoginPayload')
          ? ({} as SchibstedLoginPayload)
          : aSchibstedLoginPayload({}, relationshipsToOmit),
    sendBookingEmails:
      overrides && overrides.hasOwnProperty('sendBookingEmails')
        ? overrides.sendBookingEmails!
        : relationshipsToOmit.has('SendBookingEmailsPayload')
          ? ({} as SendBookingEmailsPayload)
          : aSendBookingEmailsPayload({}, relationshipsToOmit),
    sendEmailConfirmationToken:
      overrides && overrides.hasOwnProperty('sendEmailConfirmationToken')
        ? overrides.sendEmailConfirmationToken!
        : relationshipsToOmit.has('SendEmailConfirmationTokenPayload')
          ? ({} as SendEmailConfirmationTokenPayload)
          : aSendEmailConfirmationTokenPayload({}, relationshipsToOmit),
    sendEvictionEmailToSoc:
      overrides && overrides.hasOwnProperty('sendEvictionEmailToSoc')
        ? overrides.sendEvictionEmailToSoc!
        : relationshipsToOmit.has('AuthorizationError')
          ? ({} as AuthorizationError)
          : anAuthorizationError({}, relationshipsToOmit),
    sendMassMessage:
      overrides && overrides.hasOwnProperty('sendMassMessage')
        ? overrides.sendMassMessage!
        : relationshipsToOmit.has('SendMassMessagePayload')
          ? ({} as SendMassMessagePayload)
          : aSendMassMessagePayload({}, relationshipsToOmit),
    sendSignInCode:
      overrides && overrides.hasOwnProperty('sendSignInCode')
        ? overrides.sendSignInCode!
        : relationshipsToOmit.has('SendSignInCodePayload')
          ? ({} as SendSignInCodePayload)
          : aSendSignInCodePayload({}, relationshipsToOmit),
    setActiveBankAccount:
      overrides && overrides.hasOwnProperty('setActiveBankAccount')
        ? overrides.setActiveBankAccount!
        : relationshipsToOmit.has('SetActiveBankAccountPayload')
          ? ({} as SetActiveBankAccountPayload)
          : aSetActiveBankAccountPayload({}, relationshipsToOmit),
    setDepositFree:
      overrides && overrides.hasOwnProperty('setDepositFree')
        ? overrides.setDepositFree!
        : relationshipsToOmit.has('SetDepositFreePayload')
          ? ({} as SetDepositFreePayload)
          : aSetDepositFreePayload({}, relationshipsToOmit),
    setScriveFields:
      overrides && overrides.hasOwnProperty('setScriveFields')
        ? overrides.setScriveFields!
        : relationshipsToOmit.has('SetScriveFieldsPayload')
          ? ({} as SetScriveFieldsPayload)
          : aSetScriveFieldsPayload({}, relationshipsToOmit),
    signVacationContract:
      overrides && overrides.hasOwnProperty('signVacationContract')
        ? overrides.signVacationContract!
        : relationshipsToOmit.has('SignVacationContractPayload')
          ? ({} as SignVacationContractPayload)
          : aSignVacationContractPayload({}, relationshipsToOmit),
    starMarkApplication:
      overrides && overrides.hasOwnProperty('starMarkApplication')
        ? overrides.starMarkApplication!
        : relationshipsToOmit.has('StarMarkApplicationPayload')
          ? ({} as StarMarkApplicationPayload)
          : aStarMarkApplicationPayload({}, relationshipsToOmit),
    starMarkConversation:
      overrides && overrides.hasOwnProperty('starMarkConversation')
        ? overrides.starMarkConversation!
        : relationshipsToOmit.has('StarMarkConversationPayload')
          ? ({} as StarMarkConversationPayload)
          : aStarMarkConversationPayload({}, relationshipsToOmit),
    startBankIdTransaction:
      overrides && overrides.hasOwnProperty('startBankIdTransaction')
        ? overrides.startBankIdTransaction!
        : relationshipsToOmit.has('StartBankIdTransactionPayload')
          ? ({} as StartBankIdTransactionPayload)
          : aStartBankIdTransactionPayload({}, relationshipsToOmit),
    subUserCreateResetPasswordToken:
      overrides && overrides.hasOwnProperty('subUserCreateResetPasswordToken')
        ? overrides.subUserCreateResetPasswordToken!
        : relationshipsToOmit.has('SubUserCreateResetPasswordTokenPayload')
          ? ({} as SubUserCreateResetPasswordTokenPayload)
          : aSubUserCreateResetPasswordTokenPayload({}, relationshipsToOmit),
    subUserLogin:
      overrides && overrides.hasOwnProperty('subUserLogin')
        ? overrides.subUserLogin!
        : relationshipsToOmit.has('SubUserLoginPayload')
          ? ({} as SubUserLoginPayload)
          : aSubUserLoginPayload({}, relationshipsToOmit),
    subUserResetPassword:
      overrides && overrides.hasOwnProperty('subUserResetPassword')
        ? overrides.subUserResetPassword!
        : relationshipsToOmit.has('SubUserResetPasswordPayload')
          ? ({} as SubUserResetPasswordPayload)
          : aSubUserResetPasswordPayload({}, relationshipsToOmit),
    terminateContract:
      overrides && overrides.hasOwnProperty('terminateContract')
        ? overrides.terminateContract!
        : relationshipsToOmit.has('TerminateContractPayload')
          ? ({} as TerminateContractPayload)
          : aTerminateContractPayload({}, relationshipsToOmit),
    testErrors:
      overrides && overrides.hasOwnProperty('testErrors')
        ? overrides.testErrors!
        : relationshipsToOmit.has('AuthorizationError')
          ? ({} as AuthorizationError)
          : anAuthorizationError({}, relationshipsToOmit),
    transitionBookingOffer:
      overrides && overrides.hasOwnProperty('transitionBookingOffer')
        ? overrides.transitionBookingOffer!
        : relationshipsToOmit.has('TransitionBookingOfferPayload')
          ? ({} as TransitionBookingOfferPayload)
          : aTransitionBookingOfferPayload({}, relationshipsToOmit),
    transitionShortcut:
      overrides && overrides.hasOwnProperty('transitionShortcut')
        ? overrides.transitionShortcut!
        : relationshipsToOmit.has('TransitionShortcutPayload')
          ? ({} as TransitionShortcutPayload)
          : aTransitionShortcutPayload({}, relationshipsToOmit),
    unarchiveConversation:
      overrides && overrides.hasOwnProperty('unarchiveConversation')
        ? overrides.unarchiveConversation!
        : relationshipsToOmit.has('UnarchiveConversationPayload')
          ? ({} as UnarchiveConversationPayload)
          : anUnarchiveConversationPayload({}, relationshipsToOmit),
    unmarkFavoriteHome:
      overrides && overrides.hasOwnProperty('unmarkFavoriteHome')
        ? overrides.unmarkFavoriteHome!
        : relationshipsToOmit.has('UnmarkFavoriteHomePayload')
          ? ({} as UnmarkFavoriteHomePayload)
          : anUnmarkFavoriteHomePayload({}, relationshipsToOmit),
    unpublishTenantAd:
      overrides && overrides.hasOwnProperty('unpublishTenantAd')
        ? overrides.unpublishTenantAd!
        : relationshipsToOmit.has('UnpublishTenantAdPayload')
          ? ({} as UnpublishTenantAdPayload)
          : anUnpublishTenantAdPayload({}, relationshipsToOmit),
    updateApplicationBoardList:
      overrides && overrides.hasOwnProperty('updateApplicationBoardList')
        ? overrides.updateApplicationBoardList!
        : relationshipsToOmit.has('UpdateApplicationBoardListPayload')
          ? ({} as UpdateApplicationBoardListPayload)
          : anUpdateApplicationBoardListPayload({}, relationshipsToOmit),
    updateApplicationBoardListOrder:
      overrides && overrides.hasOwnProperty('updateApplicationBoardListOrder')
        ? overrides.updateApplicationBoardListOrder!
        : relationshipsToOmit.has('UpdateApplicationBoardListOrderPayload')
          ? ({} as UpdateApplicationBoardListOrderPayload)
          : anUpdateApplicationBoardListOrderPayload({}, relationshipsToOmit),
    updateContractScriveId:
      overrides && overrides.hasOwnProperty('updateContractScriveId')
        ? overrides.updateContractScriveId!
        : relationshipsToOmit.has('UpdateContractScriveIdPayload')
          ? ({} as UpdateContractScriveIdPayload)
          : anUpdateContractScriveIdPayload({}, relationshipsToOmit),
    updateContractWaterFee:
      overrides && overrides.hasOwnProperty('updateContractWaterFee')
        ? overrides.updateContractWaterFee!
        : relationshipsToOmit.has('UpdateContractWaterFeePayload')
          ? ({} as UpdateContractWaterFeePayload)
          : anUpdateContractWaterFeePayload({}, relationshipsToOmit),
    updateEmployee:
      overrides && overrides.hasOwnProperty('updateEmployee')
        ? overrides.updateEmployee!
        : relationshipsToOmit.has('AuthorizationError')
          ? ({} as AuthorizationError)
          : anAuthorizationError({}, relationshipsToOmit),
    updateEvictionProcess:
      overrides && overrides.hasOwnProperty('updateEvictionProcess')
        ? overrides.updateEvictionProcess!
        : relationshipsToOmit.has('AuthorizationError')
          ? ({} as AuthorizationError)
          : anAuthorizationError({}, relationshipsToOmit),
    updateHome:
      overrides && overrides.hasOwnProperty('updateHome')
        ? overrides.updateHome!
        : relationshipsToOmit.has('UpdateHomePayload')
          ? ({} as UpdateHomePayload)
          : anUpdateHomePayload({}, relationshipsToOmit),
    updateHomeApplicationPosition:
      overrides && overrides.hasOwnProperty('updateHomeApplicationPosition')
        ? overrides.updateHomeApplicationPosition!
        : relationshipsToOmit.has('UpdateHomeApplicationPositionPayload')
          ? ({} as UpdateHomeApplicationPositionPayload)
          : anUpdateHomeApplicationPositionPayload({}, relationshipsToOmit),
    updateHomeqClaim:
      overrides && overrides.hasOwnProperty('updateHomeqClaim')
        ? overrides.updateHomeqClaim!
        : relationshipsToOmit.has('ConnectHubHomeQUpdateClaimPayload')
          ? ({} as ConnectHubHomeQUpdateClaimPayload)
          : aConnectHubHomeQUpdateClaimPayload({}, relationshipsToOmit),
    updateInstantSignContract:
      overrides && overrides.hasOwnProperty('updateInstantSignContract')
        ? overrides.updateInstantSignContract!
        : relationshipsToOmit.has('UpdateInstantSignContractPayload')
          ? ({} as UpdateInstantSignContractPayload)
          : anUpdateInstantSignContractPayload({}, relationshipsToOmit),
    updateLandlordPostSignDocuments:
      overrides && overrides.hasOwnProperty('updateLandlordPostSignDocuments')
        ? overrides.updateLandlordPostSignDocuments!
        : relationshipsToOmit.has('UpdateLandlordPostSignDocumentsPayload')
          ? ({} as UpdateLandlordPostSignDocumentsPayload)
          : anUpdateLandlordPostSignDocumentsPayload({}, relationshipsToOmit),
    updateMarketSettings:
      overrides && overrides.hasOwnProperty('updateMarketSettings')
        ? overrides.updateMarketSettings!
        : relationshipsToOmit.has('UpdateMarketSettingsPayload')
          ? ({} as UpdateMarketSettingsPayload)
          : anUpdateMarketSettingsPayload({}, relationshipsToOmit),
    updateSavedSearch:
      overrides && overrides.hasOwnProperty('updateSavedSearch')
        ? overrides.updateSavedSearch!
        : relationshipsToOmit.has('UpdateSavedSearchPayload')
          ? ({} as UpdateSavedSearchPayload)
          : anUpdateSavedSearchPayload({}, relationshipsToOmit),
    updateShortcut:
      overrides && overrides.hasOwnProperty('updateShortcut')
        ? overrides.updateShortcut!
        : relationshipsToOmit.has('UpdateShortcutPayload')
          ? ({} as UpdateShortcutPayload)
          : anUpdateShortcutPayload({}, relationshipsToOmit),
    updateTenantPostSignDocuments:
      overrides && overrides.hasOwnProperty('updateTenantPostSignDocuments')
        ? overrides.updateTenantPostSignDocuments!
        : relationshipsToOmit.has('UpdateTenantPostSignDocumentsPayload')
          ? ({} as UpdateTenantPostSignDocumentsPayload)
          : anUpdateTenantPostSignDocumentsPayload({}, relationshipsToOmit),
    updateUpload:
      overrides && overrides.hasOwnProperty('updateUpload')
        ? overrides.updateUpload!
        : relationshipsToOmit.has('UpdateUploadPayload')
          ? ({} as UpdateUploadPayload)
          : anUpdateUploadPayload({}, relationshipsToOmit),
    updateUser:
      overrides && overrides.hasOwnProperty('updateUser')
        ? overrides.updateUser!
        : relationshipsToOmit.has('UpdateUserPayload')
          ? ({} as UpdateUserPayload)
          : anUpdateUserPayload({}, relationshipsToOmit),
    upsertApplicationBoardCardInfo:
      overrides && overrides.hasOwnProperty('upsertApplicationBoardCardInfo')
        ? overrides.upsertApplicationBoardCardInfo!
        : relationshipsToOmit.has('UpsertApplicationBoardCardInfoPayload')
          ? ({} as UpsertApplicationBoardCardInfoPayload)
          : anUpsertApplicationBoardCardInfoPayload({}, relationshipsToOmit),
    upsertBankAccount:
      overrides && overrides.hasOwnProperty('upsertBankAccount')
        ? overrides.upsertBankAccount!
        : relationshipsToOmit.has('UpsertBankAccountPayload')
          ? ({} as UpsertBankAccountPayload)
          : anUpsertBankAccountPayload({}, relationshipsToOmit),
    upsertBooking:
      overrides && overrides.hasOwnProperty('upsertBooking')
        ? overrides.upsertBooking!
        : relationshipsToOmit.has('UpsertBookingPayload')
          ? ({} as UpsertBookingPayload)
          : anUpsertBookingPayload({}, relationshipsToOmit),
    upsertBuilding:
      overrides && overrides.hasOwnProperty('upsertBuilding')
        ? overrides.upsertBuilding!
        : relationshipsToOmit.has('UpsertBuildingPayload')
          ? ({} as UpsertBuildingPayload)
          : anUpsertBuildingPayload({}, relationshipsToOmit),
    upsertContract:
      overrides && overrides.hasOwnProperty('upsertContract')
        ? overrides.upsertContract!
        : relationshipsToOmit.has('UpsertContractPayload')
          ? ({} as UpsertContractPayload)
          : anUpsertContractPayload({}, relationshipsToOmit),
    upsertEmployeeGroup:
      overrides && overrides.hasOwnProperty('upsertEmployeeGroup')
        ? overrides.upsertEmployeeGroup!
        : relationshipsToOmit.has('AuthorizationError')
          ? ({} as AuthorizationError)
          : anAuthorizationError({}, relationshipsToOmit),
    upsertEmploymentVerification:
      overrides && overrides.hasOwnProperty('upsertEmploymentVerification')
        ? overrides.upsertEmploymentVerification!
        : relationshipsToOmit.has('UpsertEmploymentVerificationPayload')
          ? ({} as UpsertEmploymentVerificationPayload)
          : anUpsertEmploymentVerificationPayload({}, relationshipsToOmit),
    upsertHomeAvailability:
      overrides && overrides.hasOwnProperty('upsertHomeAvailability')
        ? overrides.upsertHomeAvailability!
        : relationshipsToOmit.has('UpsertHomeAvailabilityPayload')
          ? ({} as UpsertHomeAvailabilityPayload)
          : anUpsertHomeAvailabilityPayload({}, relationshipsToOmit),
    upsertHomeAvailabilitySettings:
      overrides && overrides.hasOwnProperty('upsertHomeAvailabilitySettings')
        ? overrides.upsertHomeAvailabilitySettings!
        : relationshipsToOmit.has('UpsertHomeAvailabilitySettingsPayload')
          ? ({} as UpsertHomeAvailabilitySettingsPayload)
          : anUpsertHomeAvailabilitySettingsPayload({}, relationshipsToOmit),
    upsertHomeTemplate:
      overrides && overrides.hasOwnProperty('upsertHomeTemplate')
        ? overrides.upsertHomeTemplate!
        : relationshipsToOmit.has('UpsertHomeTemplatePayload')
          ? ({} as UpsertHomeTemplatePayload)
          : anUpsertHomeTemplatePayload({}, relationshipsToOmit),
    upsertIncomeVerification:
      overrides && overrides.hasOwnProperty('upsertIncomeVerification')
        ? overrides.upsertIncomeVerification!
        : relationshipsToOmit.has('UpsertIncomeVerificationPayload')
          ? ({} as UpsertIncomeVerificationPayload)
          : anUpsertIncomeVerificationPayload({}, relationshipsToOmit),
    upsertInspection:
      overrides && overrides.hasOwnProperty('upsertInspection')
        ? overrides.upsertInspection!
        : relationshipsToOmit.has('UpsertInspectionPayload')
          ? ({} as UpsertInspectionPayload)
          : anUpsertInspectionPayload({}, relationshipsToOmit),
    upsertInstantSignContract:
      overrides && overrides.hasOwnProperty('upsertInstantSignContract')
        ? overrides.upsertInstantSignContract!
        : relationshipsToOmit.has('TemporaryContract')
          ? ({} as TemporaryContract)
          : aTemporaryContract({}, relationshipsToOmit),
    upsertMessageMacro:
      overrides && overrides.hasOwnProperty('upsertMessageMacro')
        ? overrides.upsertMessageMacro!
        : relationshipsToOmit.has('UpsertMessageMacroPayload')
          ? ({} as UpsertMessageMacroPayload)
          : anUpsertMessageMacroPayload({}, relationshipsToOmit),
    upsertOccupation:
      overrides && overrides.hasOwnProperty('upsertOccupation')
        ? overrides.upsertOccupation!
        : relationshipsToOmit.has('UpsertOccupationPayload')
          ? ({} as UpsertOccupationPayload)
          : anUpsertOccupationPayload({}, relationshipsToOmit),
    upsertOccupationVerification:
      overrides && overrides.hasOwnProperty('upsertOccupationVerification')
        ? overrides.upsertOccupationVerification!
        : relationshipsToOmit.has('UpsertOccupationVerificationPayload')
          ? ({} as UpsertOccupationVerificationPayload)
          : anUpsertOccupationVerificationPayload({}, relationshipsToOmit),
    upsertRentalRequirement:
      overrides && overrides.hasOwnProperty('upsertRentalRequirement')
        ? overrides.upsertRentalRequirement!
        : relationshipsToOmit.has('UpsertRentalRequirementPayload')
          ? ({} as UpsertRentalRequirementPayload)
          : anUpsertRentalRequirementPayload({}, relationshipsToOmit),
    upsertStudentVerification:
      overrides && overrides.hasOwnProperty('upsertStudentVerification')
        ? overrides.upsertStudentVerification!
        : relationshipsToOmit.has('UpsertStudentVerificationPayload')
          ? ({} as UpsertStudentVerificationPayload)
          : anUpsertStudentVerificationPayload({}, relationshipsToOmit),
    upsertTenantAd:
      overrides && overrides.hasOwnProperty('upsertTenantAd')
        ? overrides.upsertTenantAd!
        : relationshipsToOmit.has('UpsertTenantAdPayload')
          ? ({} as UpsertTenantAdPayload)
          : anUpsertTenantAdPayload({}, relationshipsToOmit),
    upsertViewingCalendar:
      overrides && overrides.hasOwnProperty('upsertViewingCalendar')
        ? overrides.upsertViewingCalendar!
        : relationshipsToOmit.has('UpsertViewingCalendarPayload')
          ? ({} as UpsertViewingCalendarPayload)
          : anUpsertViewingCalendarPayload({}, relationshipsToOmit),
    verifyBankAccount:
      overrides && overrides.hasOwnProperty('verifyBankAccount')
        ? overrides.verifyBankAccount!
        : relationshipsToOmit.has('VerifyBankAccountPayload')
          ? ({} as VerifyBankAccountPayload)
          : aVerifyBankAccountPayload({}, relationshipsToOmit),
  }
}

export const aNode = (
  overrides?: Partial<Node>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Node' } & Node => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Node')
  return {
    __typename: 'Node',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '95bb2f34-6c86-495f-bfdc-f25b025cdba5',
  }
}

export const aNotFoundError = (
  overrides?: Partial<NotFoundError>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'NotFoundError' } & NotFoundError => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('NotFoundError')
  return {
    __typename: 'NotFoundError',
    message: overrides && overrides.hasOwnProperty('message') ? overrides.message! : 'voluptatum',
  }
}

export const anOccupation = (
  overrides?: Partial<Occupation>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Occupation' } & Occupation => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Occupation')
  return {
    __typename: 'Occupation',
    current: overrides && overrides.hasOwnProperty('current') ? overrides.current! : false,
    endedAt:
      overrides && overrides.hasOwnProperty('endedAt')
        ? overrides.endedAt!
        : 'dolor et aperiam aut commodi aspernatur illum',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '42a61f5f-c756-47bb-9066-3c0b0c9fefc6',
    occupationScheduleType:
      overrides && overrides.hasOwnProperty('occupationScheduleType')
        ? overrides.occupationScheduleType!
        : OccupationScheduleTypeEnum.full_time,
    occupationType:
      overrides && overrides.hasOwnProperty('occupationType')
        ? overrides.occupationType!
        : OccupationTypeEnum.other,
    occupationVerifications:
      overrides && overrides.hasOwnProperty('occupationVerifications')
        ? overrides.occupationVerifications!
        : [
            relationshipsToOmit.has('OccupationVerification')
              ? ({} as OccupationVerification)
              : anOccupationVerification({}, relationshipsToOmit),
          ],
    startedAt:
      overrides && overrides.hasOwnProperty('startedAt')
        ? overrides.startedAt!
        : 'vitae qui quo qui tenetur in eligendi',
    subtitle: overrides && overrides.hasOwnProperty('subtitle') ? overrides.subtitle! : 'et',
    title: overrides && overrides.hasOwnProperty('title') ? overrides.title! : 'unde',
  }
}

export const anOccupationVerification = (
  overrides?: Partial<OccupationVerification>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'OccupationVerification' } & OccupationVerification => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('OccupationVerification')
  return {
    __typename: 'OccupationVerification',
    document:
      overrides && overrides.hasOwnProperty('document')
        ? overrides.document!
        : relationshipsToOmit.has('Blob')
          ? ({} as Blob)
          : aBlob({}, relationshipsToOmit),
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'f08425dc-b1f0-4c07-b652-f456f9778c10',
    verificationType:
      overrides && overrides.hasOwnProperty('verificationType')
        ? overrides.verificationType!
        : VerificationTypeEnum.employment,
  }
}

export const anOffering = (
  overrides?: Partial<Offering>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Offering' } & Offering => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Offering')
  return {
    __typename: 'Offering',
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'enim ex rerum consequatur maiores et enim',
    home:
      overrides && overrides.hasOwnProperty('home')
        ? overrides.home!
        : relationshipsToOmit.has('Home')
          ? ({} as Home)
          : aHome({}, relationshipsToOmit),
    homeApplication:
      overrides && overrides.hasOwnProperty('homeApplication')
        ? overrides.homeApplication!
        : relationshipsToOmit.has('HomeApplication')
          ? ({} as HomeApplication)
          : aHomeApplication({}, relationshipsToOmit),
    homeTemplate:
      overrides && overrides.hasOwnProperty('homeTemplate')
        ? overrides.homeTemplate!
        : relationshipsToOmit.has('HomeTemplate')
          ? ({} as HomeTemplate)
          : aHomeTemplate({}, relationshipsToOmit),
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'ab9ffa47-3608-4caa-bdd7-8e2f6eca8a44',
    landlord:
      overrides && overrides.hasOwnProperty('landlord')
        ? overrides.landlord!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
    message: overrides && overrides.hasOwnProperty('message') ? overrides.message! : 'sint',
    offeringStatus:
      overrides && overrides.hasOwnProperty('offeringStatus')
        ? overrides.offeringStatus!
        : OfferingStatusEnum.accepted,
    showingAt:
      overrides && overrides.hasOwnProperty('showingAt')
        ? overrides.showingAt!
        : 'sunt laudantium debitis id qui nam porro',
    showingContactInformation:
      overrides && overrides.hasOwnProperty('showingContactInformation')
        ? overrides.showingContactInformation!
        : 'sed',
    showingStatus:
      overrides && overrides.hasOwnProperty('showingStatus')
        ? overrides.showingStatus!
        : ShowingStatusEnum.current_tenant_has_showing,
    tenant:
      overrides && overrides.hasOwnProperty('tenant')
        ? overrides.tenant!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
  }
}

export const anOfferingConnection = (
  overrides?: Partial<OfferingConnection>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'OfferingConnection' } & OfferingConnection => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('OfferingConnection')
  return {
    __typename: 'OfferingConnection',
    edges:
      overrides && overrides.hasOwnProperty('edges')
        ? overrides.edges!
        : [
            relationshipsToOmit.has('OfferingEdge')
              ? ({} as OfferingEdge)
              : anOfferingEdge({}, relationshipsToOmit),
          ],
    nodes:
      overrides && overrides.hasOwnProperty('nodes')
        ? overrides.nodes!
        : [relationshipsToOmit.has('Offering') ? ({} as Offering) : anOffering({}, relationshipsToOmit)],
    pageInfo:
      overrides && overrides.hasOwnProperty('pageInfo')
        ? overrides.pageInfo!
        : relationshipsToOmit.has('PageInfo')
          ? ({} as PageInfo)
          : aPageInfo({}, relationshipsToOmit),
    totalCount: overrides && overrides.hasOwnProperty('totalCount') ? overrides.totalCount! : 2084,
  }
}

export const anOfferingEdge = (
  overrides?: Partial<OfferingEdge>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'OfferingEdge' } & OfferingEdge => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('OfferingEdge')
  return {
    __typename: 'OfferingEdge',
    cursor: overrides && overrides.hasOwnProperty('cursor') ? overrides.cursor! : 'est',
    node:
      overrides && overrides.hasOwnProperty('node')
        ? overrides.node!
        : relationshipsToOmit.has('Offering')
          ? ({} as Offering)
          : anOffering({}, relationshipsToOmit),
  }
}

export const anOpenConversationPayload = (
  overrides?: Partial<OpenConversationPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'OpenConversationPayload' } & OpenConversationPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('OpenConversationPayload')
  return {
    __typename: 'OpenConversationPayload',
    conversation:
      overrides && overrides.hasOwnProperty('conversation')
        ? overrides.conversation!
        : relationshipsToOmit.has('Conversation')
          ? ({} as Conversation)
          : aConversation({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const anOrganization = (
  overrides?: Partial<Organization>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Organization' } & Organization => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Organization')
  return {
    __typename: 'Organization',
    buildings:
      overrides && overrides.hasOwnProperty('buildings')
        ? overrides.buildings!
        : relationshipsToOmit.has('BuildingConnection')
          ? ({} as BuildingConnection)
          : aBuildingConnection({}, relationshipsToOmit),
    homeTemplates:
      overrides && overrides.hasOwnProperty('homeTemplates')
        ? overrides.homeTemplates!
        : relationshipsToOmit.has('HomeTemplateConnection')
          ? ({} as HomeTemplateConnection)
          : aHomeTemplateConnection({}, relationshipsToOmit),
    homes:
      overrides && overrides.hasOwnProperty('homes')
        ? overrides.homes!
        : relationshipsToOmit.has('HomeConnection')
          ? ({} as HomeConnection)
          : aHomeConnection({}, relationshipsToOmit),
    name: overrides && overrides.hasOwnProperty('name') ? overrides.name! : 'cumque',
    uid: overrides && overrides.hasOwnProperty('uid') ? overrides.uid! : 'ut',
  }
}

export const aPageInfo = (
  overrides?: Partial<PageInfo>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'PageInfo' } & PageInfo => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('PageInfo')
  return {
    __typename: 'PageInfo',
    endCursor: overrides && overrides.hasOwnProperty('endCursor') ? overrides.endCursor! : 'id',
    hasNextPage: overrides && overrides.hasOwnProperty('hasNextPage') ? overrides.hasNextPage! : true,
    hasPreviousPage:
      overrides && overrides.hasOwnProperty('hasPreviousPage') ? overrides.hasPreviousPage! : false,
    startCursor: overrides && overrides.hasOwnProperty('startCursor') ? overrides.startCursor! : 'eum',
  }
}

export const aPayoutCompensationClaimReservationPayload = (
  overrides?: Partial<PayoutCompensationClaimReservationPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): {
  __typename: 'PayoutCompensationClaimReservationPayload'
} & PayoutCompensationClaimReservationPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('PayoutCompensationClaimReservationPayload')
  return {
    __typename: 'PayoutCompensationClaimReservationPayload',
    reservation:
      overrides && overrides.hasOwnProperty('reservation')
        ? overrides.reservation!
        : relationshipsToOmit.has('CompensationClaimReservationType')
          ? ({} as CompensationClaimReservationType)
          : aCompensationClaimReservationType({}, relationshipsToOmit),
  }
}

export const aPayoutOnboardingUrlQueryInput = (
  overrides?: Partial<PayoutOnboardingUrlQueryInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): PayoutOnboardingUrlQueryInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('PayoutOnboardingUrlQueryInput')
  return {
    refreshUrl: overrides && overrides.hasOwnProperty('refreshUrl') ? overrides.refreshUrl! : 'ut',
    returnUrl: overrides && overrides.hasOwnProperty('returnUrl') ? overrides.returnUrl! : 'quos',
  }
}

export const aPlatformConfiguration = (
  overrides?: Partial<PlatformConfiguration>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'PlatformConfiguration' } & PlatformConfiguration => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('PlatformConfiguration')
  return {
    __typename: 'PlatformConfiguration',
    localization:
      overrides && overrides.hasOwnProperty('localization')
        ? overrides.localization!
        : relationshipsToOmit.has('Localization')
          ? ({} as Localization)
          : aLocalization({}, relationshipsToOmit),
    platformName:
      overrides && overrides.hasOwnProperty('platformName') ? overrides.platformName! : 'molestiae',
  }
}

export const aPointOfInterest = (
  overrides?: Partial<PointOfInterest>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'PointOfInterest' } & PointOfInterest => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('PointOfInterest')
  return {
    __typename: 'PointOfInterest',
    category:
      overrides && overrides.hasOwnProperty('category')
        ? overrides.category!
        : PointOfInterestEnum.metro_station,
    distance: overrides && overrides.hasOwnProperty('distance') ? overrides.distance! : 8.28,
    latitude: overrides && overrides.hasOwnProperty('latitude') ? overrides.latitude! : 8.94,
    longitude: overrides && overrides.hasOwnProperty('longitude') ? overrides.longitude! : 1.08,
    metadata: overrides && overrides.hasOwnProperty('metadata') ? overrides.metadata! : {},
    name: overrides && overrides.hasOwnProperty('name') ? overrides.name! : 'aspernatur',
  }
}

export const aPointOfInterestConnection = (
  overrides?: Partial<PointOfInterestConnection>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'PointOfInterestConnection' } & PointOfInterestConnection => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('PointOfInterestConnection')
  return {
    __typename: 'PointOfInterestConnection',
    edges:
      overrides && overrides.hasOwnProperty('edges')
        ? overrides.edges!
        : [
            relationshipsToOmit.has('PointOfInterestEdge')
              ? ({} as PointOfInterestEdge)
              : aPointOfInterestEdge({}, relationshipsToOmit),
          ],
    nodes:
      overrides && overrides.hasOwnProperty('nodes')
        ? overrides.nodes!
        : [
            relationshipsToOmit.has('PointOfInterest')
              ? ({} as PointOfInterest)
              : aPointOfInterest({}, relationshipsToOmit),
          ],
    pageInfo:
      overrides && overrides.hasOwnProperty('pageInfo')
        ? overrides.pageInfo!
        : relationshipsToOmit.has('PageInfo')
          ? ({} as PageInfo)
          : aPageInfo({}, relationshipsToOmit),
  }
}

export const aPointOfInterestEdge = (
  overrides?: Partial<PointOfInterestEdge>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'PointOfInterestEdge' } & PointOfInterestEdge => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('PointOfInterestEdge')
  return {
    __typename: 'PointOfInterestEdge',
    cursor: overrides && overrides.hasOwnProperty('cursor') ? overrides.cursor! : 'quae',
    node:
      overrides && overrides.hasOwnProperty('node')
        ? overrides.node!
        : relationshipsToOmit.has('PointOfInterest')
          ? ({} as PointOfInterest)
          : aPointOfInterest({}, relationshipsToOmit),
  }
}

export const aPricePreview = (
  overrides?: Partial<PricePreview>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'PricePreview' } & PricePreview => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('PricePreview')
  return {
    __typename: 'PricePreview',
    averagePricePerNight:
      overrides && overrides.hasOwnProperty('averagePricePerNight') ? overrides.averagePricePerNight! : 3486,
    cleaningFee: overrides && overrides.hasOwnProperty('cleaningFee') ? overrides.cleaningFee! : 6917,
    landlordFee: overrides && overrides.hasOwnProperty('landlordFee') ? overrides.landlordFee! : 1540,
    rentPrice: overrides && overrides.hasOwnProperty('rentPrice') ? overrides.rentPrice! : 1194,
    tenantFee: overrides && overrides.hasOwnProperty('tenantFee') ? overrides.tenantFee! : 6094,
    totalTenantPrice:
      overrides && overrides.hasOwnProperty('totalTenantPrice') ? overrides.totalTenantPrice! : 8323,
  }
}

export const aPricingModel = (
  overrides?: Partial<PricingModel>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'PricingModel' } & PricingModel => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('PricingModel')
  return {
    __typename: 'PricingModel',
    depositFreeCostFactor:
      overrides && overrides.hasOwnProperty('depositFreeCostFactor')
        ? overrides.depositFreeCostFactor!
        : 7.67,
    depositFreeIncluded:
      overrides && overrides.hasOwnProperty('depositFreeIncluded') ? overrides.depositFreeIncluded! : false,
    insuranceCostFactor:
      overrides && overrides.hasOwnProperty('insuranceCostFactor') ? overrides.insuranceCostFactor! : 0.83,
    landlordBaseFeeFactor:
      overrides && overrides.hasOwnProperty('landlordBaseFeeFactor')
        ? overrides.landlordBaseFeeFactor!
        : 2.65,
    listingBumpFee:
      overrides && overrides.hasOwnProperty('listingBumpFee') ? overrides.listingBumpFee! : 7737,
    listingPublicationFee:
      overrides && overrides.hasOwnProperty('listingPublicationFee')
        ? overrides.listingPublicationFee!
        : 7373,
    modelName:
      overrides && overrides.hasOwnProperty('modelName')
        ? overrides.modelName!
        : PricingModelNameEnum.landlord_fees_effective,
    notCreditWorthyDepositMonths:
      overrides && overrides.hasOwnProperty('notCreditWorthyDepositMonths')
        ? overrides.notCreditWorthyDepositMonths!
        : 3160,
    qasaGuaranteeCostFactor:
      overrides && overrides.hasOwnProperty('qasaGuaranteeCostFactor')
        ? overrides.qasaGuaranteeCostFactor!
        : 6.31,
    tenantBaseFeeFactor:
      overrides && overrides.hasOwnProperty('tenantBaseFeeFactor') ? overrides.tenantBaseFeeFactor! : 6.84,
  }
}

export const aPrivacyControllerByPlatform = (
  overrides?: Partial<PrivacyControllerByPlatform>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'PrivacyControllerByPlatform' } & PrivacyControllerByPlatform => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('PrivacyControllerByPlatform')
  return {
    __typename: 'PrivacyControllerByPlatform',
    isTransactionCustomer:
      overrides && overrides.hasOwnProperty('isTransactionCustomer')
        ? overrides.isTransactionCustomer!
        : true,
    marketplaceController:
      overrides && overrides.hasOwnProperty('marketplaceController')
        ? overrides.marketplaceController!
        : BrandControllerEnum.qasa_ab,
    marketplaceTerms:
      overrides && overrides.hasOwnProperty('marketplaceTerms') ? overrides.marketplaceTerms! : 'consectetur',
    transactionController:
      overrides && overrides.hasOwnProperty('transactionController')
        ? overrides.transactionController!
        : BrandControllerEnum.qasa_ab,
    transactionTerms:
      overrides && overrides.hasOwnProperty('transactionTerms') ? overrides.transactionTerms! : 'aut',
  }
}

export const aProContactFormInput = (
  overrides?: Partial<ProContactFormInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): ProContactFormInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ProContactFormInput')
  return {
    companyName: overrides && overrides.hasOwnProperty('companyName') ? overrides.companyName! : 'quaerat',
    email: overrides && overrides.hasOwnProperty('email') ? overrides.email! : 'ipsam',
    numberOfHomes: overrides && overrides.hasOwnProperty('numberOfHomes') ? overrides.numberOfHomes! : 'aut',
    other: overrides && overrides.hasOwnProperty('other') ? overrides.other! : 'voluptatem',
    phoneNumber: overrides && overrides.hasOwnProperty('phoneNumber') ? overrides.phoneNumber! : 'voluptas',
  }
}

export const aProContactFormPayload = (
  overrides?: Partial<ProContactFormPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ProContactFormPayload' } & ProContactFormPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ProContactFormPayload')
  return {
    __typename: 'ProContactFormPayload',
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : true,
  }
}

export const aProperty = (
  overrides?: Partial<Property>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Property' } & Property => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Property')
  return {
    __typename: 'Property',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'ce59297e-5434-44e6-a9dc-b18fd8e21368',
    maxRent: overrides && overrides.hasOwnProperty('maxRent') ? overrides.maxRent! : 1449,
    maxRoomCount: overrides && overrides.hasOwnProperty('maxRoomCount') ? overrides.maxRoomCount! : 1.48,
    maxSquareMeters:
      overrides && overrides.hasOwnProperty('maxSquareMeters') ? overrides.maxSquareMeters! : 9552,
    minRent: overrides && overrides.hasOwnProperty('minRent') ? overrides.minRent! : 5292,
    minRoomCount: overrides && overrides.hasOwnProperty('minRoomCount') ? overrides.minRoomCount! : 1.62,
    minSquareMeters:
      overrides && overrides.hasOwnProperty('minSquareMeters') ? overrides.minSquareMeters! : 6324,
    numberOfHomes: overrides && overrides.hasOwnProperty('numberOfHomes') ? overrides.numberOfHomes! : 2128,
  }
}

export const aPublicDocument = (
  overrides?: Partial<PublicDocument>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'PublicDocument' } & PublicDocument => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('PublicDocument')
  return {
    __typename: 'PublicDocument',
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'harum eveniet modi nulla accusamus et et',
    fileUrl: overrides && overrides.hasOwnProperty('fileUrl') ? overrides.fileUrl! : 'facere',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'da8f336d-4d5c-4349-ba53-d267134ab570',
    name: overrides && overrides.hasOwnProperty('name') ? overrides.name! : 'hic',
  }
}

export const aPublicDocumentConnection = (
  overrides?: Partial<PublicDocumentConnection>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'PublicDocumentConnection' } & PublicDocumentConnection => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('PublicDocumentConnection')
  return {
    __typename: 'PublicDocumentConnection',
    edges:
      overrides && overrides.hasOwnProperty('edges')
        ? overrides.edges!
        : [
            relationshipsToOmit.has('PublicDocumentEdge')
              ? ({} as PublicDocumentEdge)
              : aPublicDocumentEdge({}, relationshipsToOmit),
          ],
    nodes:
      overrides && overrides.hasOwnProperty('nodes')
        ? overrides.nodes!
        : [
            relationshipsToOmit.has('PublicDocument')
              ? ({} as PublicDocument)
              : aPublicDocument({}, relationshipsToOmit),
          ],
    pageInfo:
      overrides && overrides.hasOwnProperty('pageInfo')
        ? overrides.pageInfo!
        : relationshipsToOmit.has('PageInfo')
          ? ({} as PageInfo)
          : aPageInfo({}, relationshipsToOmit),
  }
}

export const aPublicDocumentEdge = (
  overrides?: Partial<PublicDocumentEdge>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'PublicDocumentEdge' } & PublicDocumentEdge => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('PublicDocumentEdge')
  return {
    __typename: 'PublicDocumentEdge',
    cursor: overrides && overrides.hasOwnProperty('cursor') ? overrides.cursor! : 'modi',
    node:
      overrides && overrides.hasOwnProperty('node')
        ? overrides.node!
        : relationshipsToOmit.has('PublicDocument')
          ? ({} as PublicDocument)
          : aPublicDocument({}, relationshipsToOmit),
  }
}

export const aPublishHomePayload = (
  overrides?: Partial<PublishHomePayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'PublishHomePayload' } & PublishHomePayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('PublishHomePayload')
  return {
    __typename: 'PublishHomePayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    home:
      overrides && overrides.hasOwnProperty('home')
        ? overrides.home!
        : relationshipsToOmit.has('Home')
          ? ({} as Home)
          : aHome({}, relationshipsToOmit),
  }
}

export const aPublishTenantAdPayload = (
  overrides?: Partial<PublishTenantAdPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'PublishTenantAdPayload' } & PublishTenantAdPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('PublishTenantAdPayload')
  return {
    __typename: 'PublishTenantAdPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    tenantAd:
      overrides && overrides.hasOwnProperty('tenantAd')
        ? overrides.tenantAd!
        : relationshipsToOmit.has('TenantAd')
          ? ({} as TenantAd)
          : aTenantAd({}, relationshipsToOmit),
  }
}

export const aQueryRoot = (
  overrides?: Partial<QueryRoot>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'QueryRoot' } & QueryRoot => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('QueryRoot')
  return {
    __typename: 'QueryRoot',
    adminContracts:
      overrides && overrides.hasOwnProperty('adminContracts')
        ? overrides.adminContracts!
        : relationshipsToOmit.has('ContractConnection')
          ? ({} as ContractConnection)
          : aContractConnection({}, relationshipsToOmit),
    adminSession:
      overrides && overrides.hasOwnProperty('adminSession')
        ? overrides.adminSession!
        : relationshipsToOmit.has('AdminInfo')
          ? ({} as AdminInfo)
          : anAdminInfo({}, relationshipsToOmit),
    bookings:
      overrides && overrides.hasOwnProperty('bookings')
        ? overrides.bookings!
        : [relationshipsToOmit.has('Booking') ? ({} as Booking) : aBooking({}, relationshipsToOmit)],
    building:
      overrides && overrides.hasOwnProperty('building')
        ? overrides.building!
        : relationshipsToOmit.has('Building')
          ? ({} as Building)
          : aBuilding({}, relationshipsToOmit),
    buildings:
      overrides && overrides.hasOwnProperty('buildings')
        ? overrides.buildings!
        : relationshipsToOmit.has('BuildingConnection')
          ? ({} as BuildingConnection)
          : aBuildingConnection({}, relationshipsToOmit),
    checkoutToken:
      overrides && overrides.hasOwnProperty('checkoutToken') ? overrides.checkoutToken! : 'accusantium',
    compensationClaim:
      overrides && overrides.hasOwnProperty('compensationClaim')
        ? overrides.compensationClaim!
        : relationshipsToOmit.has('CompensationClaim')
          ? ({} as CompensationClaim)
          : aCompensationClaim({}, relationshipsToOmit),
    contract:
      overrides && overrides.hasOwnProperty('contract')
        ? overrides.contract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    contractTenantInvitation:
      overrides && overrides.hasOwnProperty('contractTenantInvitation')
        ? overrides.contractTenantInvitation!
        : relationshipsToOmit.has('ContractTenantInvitation')
          ? ({} as ContractTenantInvitation)
          : aContractTenantInvitation({}, relationshipsToOmit),
    contracts:
      overrides && overrides.hasOwnProperty('contracts')
        ? overrides.contracts!
        : relationshipsToOmit.has('ContractConnection')
          ? ({} as ContractConnection)
          : aContractConnection({}, relationshipsToOmit),
    contractsCounts:
      overrides && overrides.hasOwnProperty('contractsCounts')
        ? overrides.contractsCounts!
        : relationshipsToOmit.has('ContractsCounts')
          ? ({} as ContractsCounts)
          : aContractsCounts({}, relationshipsToOmit),
    conversation:
      overrides && overrides.hasOwnProperty('conversation')
        ? overrides.conversation!
        : relationshipsToOmit.has('Conversation')
          ? ({} as Conversation)
          : aConversation({}, relationshipsToOmit),
    conversations:
      overrides && overrides.hasOwnProperty('conversations')
        ? overrides.conversations!
        : relationshipsToOmit.has('ConversationConnection')
          ? ({} as ConversationConnection)
          : aConversationConnection({}, relationshipsToOmit),
    coordinatesStatistics:
      overrides && overrides.hasOwnProperty('coordinatesStatistics')
        ? overrides.coordinatesStatistics!
        : relationshipsToOmit.has('CoordinatesStatistics')
          ? ({} as CoordinatesStatistics)
          : aCoordinatesStatistics({}, relationshipsToOmit),
    cronofyElementData:
      overrides && overrides.hasOwnProperty('cronofyElementData')
        ? overrides.cronofyElementData!
        : relationshipsToOmit.has('CronofyElementData')
          ? ({} as CronofyElementData)
          : aCronofyElementData({}, relationshipsToOmit),
    employee:
      overrides && overrides.hasOwnProperty('employee')
        ? overrides.employee!
        : relationshipsToOmit.has('AuthorizationError')
          ? ({} as AuthorizationError)
          : anAuthorizationError({}, relationshipsToOmit),
    employeeGroups:
      overrides && overrides.hasOwnProperty('employeeGroups')
        ? overrides.employeeGroups!
        : relationshipsToOmit.has('AuthorizationError')
          ? ({} as AuthorizationError)
          : anAuthorizationError({}, relationshipsToOmit),
    employeeRoles:
      overrides && overrides.hasOwnProperty('employeeRoles')
        ? overrides.employeeRoles!
        : relationshipsToOmit.has('AuthorizationError')
          ? ({} as AuthorizationError)
          : anAuthorizationError({}, relationshipsToOmit),
    employees:
      overrides && overrides.hasOwnProperty('employees')
        ? overrides.employees!
        : relationshipsToOmit.has('EmployeeConnection')
          ? ({} as EmployeeConnection)
          : anEmployeeConnection({}, relationshipsToOmit),
    evictionProcess:
      overrides && overrides.hasOwnProperty('evictionProcess')
        ? overrides.evictionProcess!
        : [
            relationshipsToOmit.has('EvictionProcess')
              ? ({} as EvictionProcess)
              : anEvictionProcess({}, relationshipsToOmit),
          ],
    favoriteHomes:
      overrides && overrides.hasOwnProperty('favoriteHomes')
        ? overrides.favoriteHomes!
        : relationshipsToOmit.has('HomeSearch')
          ? ({} as HomeSearch)
          : aHomeSearch({}, relationshipsToOmit),
    generateBlockListingPayload:
      overrides && overrides.hasOwnProperty('generateBlockListingPayload')
        ? overrides.generateBlockListingPayload!
        : relationshipsToOmit.has('BlockListingPayload')
          ? ({} as BlockListingPayload)
          : aBlockListingPayload({}, relationshipsToOmit),
    generateContractPreviewPdf:
      overrides && overrides.hasOwnProperty('generateContractPreviewPdf')
        ? overrides.generateContractPreviewPdf!
        : relationshipsToOmit.has('ContractPreview')
          ? ({} as ContractPreview)
          : aContractPreview({}, relationshipsToOmit),
    home:
      overrides && overrides.hasOwnProperty('home')
        ? overrides.home!
        : relationshipsToOmit.has('Home')
          ? ({} as Home)
          : aHome({}, relationshipsToOmit),
    homeApplication:
      overrides && overrides.hasOwnProperty('homeApplication')
        ? overrides.homeApplication!
        : relationshipsToOmit.has('HomeApplication')
          ? ({} as HomeApplication)
          : aHomeApplication({}, relationshipsToOmit),
    homeApplications:
      overrides && overrides.hasOwnProperty('homeApplications')
        ? overrides.homeApplications!
        : relationshipsToOmit.has('HomeApplicationConnection')
          ? ({} as HomeApplicationConnection)
          : aHomeApplicationConnection({}, relationshipsToOmit),
    homeApplicationsByBoardList:
      overrides && overrides.hasOwnProperty('homeApplicationsByBoardList')
        ? overrides.homeApplicationsByBoardList!
        : relationshipsToOmit.has('HomeApplicationConnection')
          ? ({} as HomeApplicationConnection)
          : aHomeApplicationConnection({}, relationshipsToOmit),
    homeAvailabilities:
      overrides && overrides.hasOwnProperty('homeAvailabilities')
        ? overrides.homeAvailabilities!
        : [
            relationshipsToOmit.has('HomeAvailability')
              ? ({} as HomeAvailability)
              : aHomeAvailability({}, relationshipsToOmit),
          ],
    homeAvailabilitySettingsQuery:
      overrides && overrides.hasOwnProperty('homeAvailabilitySettingsQuery')
        ? overrides.homeAvailabilitySettingsQuery!
        : relationshipsToOmit.has('HomeAvailabilitySettings')
          ? ({} as HomeAvailabilitySettings)
          : aHomeAvailabilitySettings({}, relationshipsToOmit),
    homeEvaluation:
      overrides && overrides.hasOwnProperty('homeEvaluation')
        ? overrides.homeEvaluation!
        : relationshipsToOmit.has('HomeEvaluation')
          ? ({} as HomeEvaluation)
          : aHomeEvaluation({}, relationshipsToOmit),
    homeIds:
      overrides && overrides.hasOwnProperty('homeIds')
        ? overrides.homeIds!
        : ['0c5f4606-8e42-4c55-b52d-230815339e18'],
    homeIndexSearch:
      overrides && overrides.hasOwnProperty('homeIndexSearch')
        ? overrides.homeIndexSearch!
        : relationshipsToOmit.has('HomeIndexSearchQuery')
          ? ({} as HomeIndexSearchQuery)
          : aHomeIndexSearchQuery({}, relationshipsToOmit),
    homeSearch:
      overrides && overrides.hasOwnProperty('homeSearch')
        ? overrides.homeSearch!
        : relationshipsToOmit.has('HomeSearch')
          ? ({} as HomeSearch)
          : aHomeSearch({}, relationshipsToOmit),
    homeSearchCoords:
      overrides && overrides.hasOwnProperty('homeSearchCoords')
        ? overrides.homeSearchCoords!
        : relationshipsToOmit.has('HomeSearchCoords')
          ? ({} as HomeSearchCoords)
          : aHomeSearchCoords({}, relationshipsToOmit),
    homeStatsQuery:
      overrides && overrides.hasOwnProperty('homeStatsQuery')
        ? overrides.homeStatsQuery!
        : relationshipsToOmit.has('HomeStats')
          ? ({} as HomeStats)
          : aHomeStats({}, relationshipsToOmit),
    homeTemplate:
      overrides && overrides.hasOwnProperty('homeTemplate')
        ? overrides.homeTemplate!
        : relationshipsToOmit.has('HomeTemplate')
          ? ({} as HomeTemplate)
          : aHomeTemplate({}, relationshipsToOmit),
    homeTemplates:
      overrides && overrides.hasOwnProperty('homeTemplates')
        ? overrides.homeTemplates!
        : relationshipsToOmit.has('HomeTemplateConnection')
          ? ({} as HomeTemplateConnection)
          : aHomeTemplateConnection({}, relationshipsToOmit),
    homeqConnectAccountsPreview:
      overrides && overrides.hasOwnProperty('homeqConnectAccountsPreview')
        ? overrides.homeqConnectAccountsPreview!
        : relationshipsToOmit.has('ConnectHubConnectAccountsPreviewType')
          ? ({} as ConnectHubConnectAccountsPreviewType)
          : aConnectHubConnectAccountsPreviewType({}, relationshipsToOmit),
    homeqHomeApplicationPreconditions:
      overrides && overrides.hasOwnProperty('homeqHomeApplicationPreconditions')
        ? overrides.homeqHomeApplicationPreconditions!
        : relationshipsToOmit.has('ConnectHubHomeQHomeApplicationPreconditions')
          ? ({} as ConnectHubHomeQHomeApplicationPreconditions)
          : aConnectHubHomeQHomeApplicationPreconditions({}, relationshipsToOmit),
    homeqHomeApplications:
      overrides && overrides.hasOwnProperty('homeqHomeApplications')
        ? overrides.homeqHomeApplications!
        : relationshipsToOmit.has('ConnectHubHomeQHomeApplicationsType')
          ? ({} as ConnectHubHomeQHomeApplicationsType)
          : aConnectHubHomeQHomeApplicationsType({}, relationshipsToOmit),
    homeqLandlordCompanies:
      overrides && overrides.hasOwnProperty('homeqLandlordCompanies')
        ? overrides.homeqLandlordCompanies!
        : [
            relationshipsToOmit.has('ConnectHubHomeQLandlordCompanyType')
              ? ({} as ConnectHubHomeQLandlordCompanyType)
              : aConnectHubHomeQLandlordCompanyType({}, relationshipsToOmit),
          ],
    homeqUser:
      overrides && overrides.hasOwnProperty('homeqUser')
        ? overrides.homeqUser!
        : relationshipsToOmit.has('ConnectHubHomeQUserType')
          ? ({} as ConnectHubHomeQUserType)
          : aConnectHubHomeQUserType({}, relationshipsToOmit),
    homeqUserClaims:
      overrides && overrides.hasOwnProperty('homeqUserClaims')
        ? overrides.homeqUserClaims!
        : relationshipsToOmit.has('ConnectHubHomeQUserClaimsType')
          ? ({} as ConnectHubHomeQUserClaimsType)
          : aConnectHubHomeQUserClaimsType({}, relationshipsToOmit),
    homeqUserTokenState:
      overrides && overrides.hasOwnProperty('homeqUserTokenState')
        ? overrides.homeqUserTokenState!
        : 'officiis',
    homes:
      overrides && overrides.hasOwnProperty('homes')
        ? overrides.homes!
        : relationshipsToOmit.has('HomeConnection')
          ? ({} as HomeConnection)
          : aHomeConnection({}, relationshipsToOmit),
    identificationStatus:
      overrides && overrides.hasOwnProperty('identificationStatus')
        ? overrides.identificationStatus!
        : IdentificationStatusEnum.accepted,
    identificationToken:
      overrides && overrides.hasOwnProperty('identificationToken') ? overrides.identificationToken! : 'in',
    incomeVerification:
      overrides && overrides.hasOwnProperty('incomeVerification')
        ? overrides.incomeVerification!
        : relationshipsToOmit.has('IncomeVerification')
          ? ({} as IncomeVerification)
          : anIncomeVerification({}, relationshipsToOmit),
    instantMatches:
      overrides && overrides.hasOwnProperty('instantMatches')
        ? overrides.instantMatches!
        : relationshipsToOmit.has('TenantAdOffsetLimit')
          ? ({} as TenantAdOffsetLimit)
          : aTenantAdOffsetLimit({}, relationshipsToOmit),
    kanyeCurrentUser:
      overrides && overrides.hasOwnProperty('kanyeCurrentUser')
        ? overrides.kanyeCurrentUser!
        : relationshipsToOmit.has('AuthorizationError')
          ? ({} as AuthorizationError)
          : anAuthorizationError({}, relationshipsToOmit),
    kanyeHomes:
      overrides && overrides.hasOwnProperty('kanyeHomes')
        ? overrides.kanyeHomes!
        : relationshipsToOmit.has('AuthorizationError')
          ? ({} as AuthorizationError)
          : anAuthorizationError({}, relationshipsToOmit),
    kanyeUser:
      overrides && overrides.hasOwnProperty('kanyeUser')
        ? overrides.kanyeUser!
        : relationshipsToOmit.has('KanyeUser')
          ? ({} as KanyeUser)
          : aKanyeUser({}, relationshipsToOmit),
    marketSettings:
      overrides && overrides.hasOwnProperty('marketSettings')
        ? overrides.marketSettings!
        : relationshipsToOmit.has('MarketSettings')
          ? ({} as MarketSettings)
          : aMarketSettings({}, relationshipsToOmit),
    marketsSettings:
      overrides && overrides.hasOwnProperty('marketsSettings')
        ? overrides.marketsSettings!
        : [
            relationshipsToOmit.has('MarketSettings')
              ? ({} as MarketSettings)
              : aMarketSettings({}, relationshipsToOmit),
          ],
    matchingScoreAws:
      overrides && overrides.hasOwnProperty('matchingScoreAws')
        ? overrides.matchingScoreAws!
        : relationshipsToOmit.has('MatchingScore')
          ? ({} as MatchingScore)
          : aMatchingScore({}, relationshipsToOmit),
    me:
      overrides && overrides.hasOwnProperty('me')
        ? overrides.me!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
    messageMacros:
      overrides && overrides.hasOwnProperty('messageMacros')
        ? overrides.messageMacros!
        : relationshipsToOmit.has('MessageMacroConnection')
          ? ({} as MessageMacroConnection)
          : aMessageMacroConnection({}, relationshipsToOmit),
    newInbox:
      overrides && overrides.hasOwnProperty('newInbox')
        ? overrides.newInbox!
        : relationshipsToOmit.has('ConversationConnection')
          ? ({} as ConversationConnection)
          : aConversationConnection({}, relationshipsToOmit),
    onfidoSdkToken:
      overrides && overrides.hasOwnProperty('onfidoSdkToken') ? overrides.onfidoSdkToken! : 'nam',
    organization:
      overrides && overrides.hasOwnProperty('organization')
        ? overrides.organization!
        : relationshipsToOmit.has('Organization')
          ? ({} as Organization)
          : anOrganization({}, relationshipsToOmit),
    payoutOnboardingUrl:
      overrides && overrides.hasOwnProperty('payoutOnboardingUrl')
        ? overrides.payoutOnboardingUrl!
        : 'adipisci',
    platformConfiguration:
      overrides && overrides.hasOwnProperty('platformConfiguration')
        ? overrides.platformConfiguration!
        : relationshipsToOmit.has('PlatformConfiguration')
          ? ({} as PlatformConfiguration)
          : aPlatformConfiguration({}, relationshipsToOmit),
    pricePreview:
      overrides && overrides.hasOwnProperty('pricePreview')
        ? overrides.pricePreview!
        : relationshipsToOmit.has('PricePreview')
          ? ({} as PricePreview)
          : aPricePreview({}, relationshipsToOmit),
    privacyControllerByPlatform:
      overrides && overrides.hasOwnProperty('privacyControllerByPlatform')
        ? overrides.privacyControllerByPlatform!
        : relationshipsToOmit.has('PrivacyControllerByPlatform')
          ? ({} as PrivacyControllerByPlatform)
          : aPrivacyControllerByPlatform({}, relationshipsToOmit),
    proPilotConnectedUids:
      overrides && overrides.hasOwnProperty('proPilotConnectedUids')
        ? overrides.proPilotConnectedUids!
        : ['4137269f-7e78-4bd8-a998-4afcaa292a74'],
    proPilotUsers:
      overrides && overrides.hasOwnProperty('proPilotUsers')
        ? overrides.proPilotUsers!
        : relationshipsToOmit.has('UserConnection')
          ? ({} as UserConnection)
          : aUserConnection({}, relationshipsToOmit),
    publicDocuments:
      overrides && overrides.hasOwnProperty('publicDocuments')
        ? overrides.publicDocuments!
        : relationshipsToOmit.has('PublicDocumentConnection')
          ? ({} as PublicDocumentConnection)
          : aPublicDocumentConnection({}, relationshipsToOmit),
    rentHistogram:
      overrides && overrides.hasOwnProperty('rentHistogram')
        ? overrides.rentHistogram!
        : [
            relationshipsToOmit.has('RentHistogram')
              ? ({} as RentHistogram)
              : aRentHistogram({}, relationshipsToOmit),
          ],
    savedSearch:
      overrides && overrides.hasOwnProperty('savedSearch')
        ? overrides.savedSearch!
        : [
            relationshipsToOmit.has('SavedSearch')
              ? ({} as SavedSearch)
              : aSavedSearch({}, relationshipsToOmit),
          ],
    searchArea:
      overrides && overrides.hasOwnProperty('searchArea')
        ? overrides.searchArea!
        : [relationshipsToOmit.has('SearchArea') ? ({} as SearchArea) : aSearchArea({}, relationshipsToOmit)],
    searchAreaHierarchy:
      overrides && overrides.hasOwnProperty('searchAreaHierarchy')
        ? overrides.searchAreaHierarchy!
        : relationshipsToOmit.has('SearchArea')
          ? ({} as SearchArea)
          : aSearchArea({}, relationshipsToOmit),
    searchAreaKeyword:
      overrides && overrides.hasOwnProperty('searchAreaKeyword')
        ? overrides.searchAreaKeyword!
        : [
            relationshipsToOmit.has('SearchAreaMatcher')
              ? ({} as SearchAreaMatcher)
              : aSearchAreaMatcher({}, relationshipsToOmit),
          ],
    sentOfferings:
      overrides && overrides.hasOwnProperty('sentOfferings')
        ? overrides.sentOfferings!
        : relationshipsToOmit.has('OfferingConnection')
          ? ({} as OfferingConnection)
          : anOfferingConnection({}, relationshipsToOmit),
    similarHomes:
      overrides && overrides.hasOwnProperty('similarHomes')
        ? overrides.similarHomes!
        : relationshipsToOmit.has('SimilarHomes')
          ? ({} as SimilarHomes)
          : aSimilarHomes({}, relationshipsToOmit),
    suspiciousUsers:
      overrides && overrides.hasOwnProperty('suspiciousUsers')
        ? overrides.suspiciousUsers!
        : relationshipsToOmit.has('AuthorizationError')
          ? ({} as AuthorizationError)
          : anAuthorizationError({}, relationshipsToOmit),
    tenantAd:
      overrides && overrides.hasOwnProperty('tenantAd')
        ? overrides.tenantAd!
        : relationshipsToOmit.has('TenantAd')
          ? ({} as TenantAd)
          : aTenantAd({}, relationshipsToOmit),
    tenantAds:
      overrides && overrides.hasOwnProperty('tenantAds')
        ? overrides.tenantAds!
        : relationshipsToOmit.has('TenantAdOffsetLimit')
          ? ({} as TenantAdOffsetLimit)
          : aTenantAdOffsetLimit({}, relationshipsToOmit),
    unreadConversationsCount:
      overrides && overrides.hasOwnProperty('unreadConversationsCount')
        ? overrides.unreadConversationsCount!
        : relationshipsToOmit.has('UnreadConversationsCount')
          ? ({} as UnreadConversationsCount)
          : anUnreadConversationsCount({}, relationshipsToOmit),
    user:
      overrides && overrides.hasOwnProperty('user')
        ? overrides.user!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
    userContracts:
      overrides && overrides.hasOwnProperty('userContracts')
        ? overrides.userContracts!
        : relationshipsToOmit.has('UserContracts')
          ? ({} as UserContracts)
          : aUserContracts({}, relationshipsToOmit),
    userIds:
      overrides && overrides.hasOwnProperty('userIds')
        ? overrides.userIds!
        : ['922c7272-5311-44f4-bf0c-800743b2f645'],
    users:
      overrides && overrides.hasOwnProperty('users')
        ? overrides.users!
        : relationshipsToOmit.has('KanyeUserConnection')
          ? ({} as KanyeUserConnection)
          : aKanyeUserConnection({}, relationshipsToOmit),
    viewingCalendar:
      overrides && overrides.hasOwnProperty('viewingCalendar')
        ? overrides.viewingCalendar!
        : relationshipsToOmit.has('ViewingCalendar')
          ? ({} as ViewingCalendar)
          : aViewingCalendar({}, relationshipsToOmit),
  }
}

export const aRateLimitExceeded = (
  overrides?: Partial<RateLimitExceeded>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'RateLimitExceeded' } & RateLimitExceeded => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('RateLimitExceeded')
  return {
    __typename: 'RateLimitExceeded',
    message: overrides && overrides.hasOwnProperty('message') ? overrides.message! : 'quae',
  }
}

export const aRejectIdentificationPayload = (
  overrides?: Partial<RejectIdentificationPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'RejectIdentificationPayload' } & RejectIdentificationPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('RejectIdentificationPayload')
  return {
    __typename: 'RejectIdentificationPayload',
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : true,
  }
}

export const aRemoveCosigningTenantPayload = (
  overrides?: Partial<RemoveCosigningTenantPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'RemoveCosigningTenantPayload' } & RemoveCosigningTenantPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('RemoveCosigningTenantPayload')
  return {
    __typename: 'RemoveCosigningTenantPayload',
    uid:
      overrides && overrides.hasOwnProperty('uid') ? overrides.uid! : 'a9f67267-f9fe-4868-b706-9e896b2b9fa3',
  }
}

export const aRemoveHomeApplicationsFromBoardListPayload = (
  overrides?: Partial<RemoveHomeApplicationsFromBoardListPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): {
  __typename: 'RemoveHomeApplicationsFromBoardListPayload'
} & RemoveHomeApplicationsFromBoardListPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('RemoveHomeApplicationsFromBoardListPayload')
  return {
    __typename: 'RemoveHomeApplicationsFromBoardListPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    homeApplications:
      overrides && overrides.hasOwnProperty('homeApplications')
        ? overrides.homeApplications!
        : [
            relationshipsToOmit.has('HomeApplication')
              ? ({} as HomeApplication)
              : aHomeApplication({}, relationshipsToOmit),
          ],
  }
}

export const aRentHistogram = (
  overrides?: Partial<RentHistogram>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'RentHistogram' } & RentHistogram => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('RentHistogram')
  return {
    __typename: 'RentHistogram',
    count: overrides && overrides.hasOwnProperty('count') ? overrides.count! : 6336,
    normalizedCount:
      overrides && overrides.hasOwnProperty('normalizedCount') ? overrides.normalizedCount! : 0.2,
    rent: overrides && overrides.hasOwnProperty('rent') ? overrides.rent! : 5168,
  }
}

export const aRentalRequirement = (
  overrides?: Partial<RentalRequirement>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'RentalRequirement' } & RentalRequirement => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('RentalRequirement')
  return {
    __typename: 'RentalRequirement',
    approvedCreditCheck:
      overrides && overrides.hasOwnProperty('approvedCreditCheck') ? overrides.approvedCreditCheck! : true,
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'quae perspiciatis ea cum occaecati voluptatibus enim',
    home:
      overrides && overrides.hasOwnProperty('home')
        ? overrides.home!
        : relationshipsToOmit.has('Home')
          ? ({} as Home)
          : aHome({}, relationshipsToOmit),
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '42648766-7b87-48e0-b02c-986a04ea187f',
    rentMultiplier:
      overrides && overrides.hasOwnProperty('rentMultiplier') ? overrides.rentMultiplier! : 4.01,
    studentVerification:
      overrides && overrides.hasOwnProperty('studentVerification') ? overrides.studentVerification! : true,
    updatedAt:
      overrides && overrides.hasOwnProperty('updatedAt')
        ? overrides.updatedAt!
        : 'quas sapiente nesciunt veritatis cupiditate officiis ad',
    verifiedIdNumber:
      overrides && overrides.hasOwnProperty('verifiedIdNumber') ? overrides.verifiedIdNumber! : false,
    verifiedIncome:
      overrides && overrides.hasOwnProperty('verifiedIncome') ? overrides.verifiedIncome! : false,
  }
}

export const aReportUserPayload = (
  overrides?: Partial<ReportUserPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ReportUserPayload' } & ReportUserPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ReportUserPayload')
  return {
    __typename: 'ReportUserPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    userReport: overrides && overrides.hasOwnProperty('userReport') ? overrides.userReport! : false,
  }
}

export const aResendConfirmBankAccountEmailPayload = (
  overrides?: Partial<ResendConfirmBankAccountEmailPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ResendConfirmBankAccountEmailPayload' } & ResendConfirmBankAccountEmailPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ResendConfirmBankAccountEmailPayload')
  return {
    __typename: 'ResendConfirmBankAccountEmailPayload',
    emailQueued: overrides && overrides.hasOwnProperty('emailQueued') ? overrides.emailQueued! : false,
  }
}

export const aRevokeHomeApplicationPayload = (
  overrides?: Partial<RevokeHomeApplicationPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'RevokeHomeApplicationPayload' } & RevokeHomeApplicationPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('RevokeHomeApplicationPayload')
  return {
    __typename: 'RevokeHomeApplicationPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    homeApplication:
      overrides && overrides.hasOwnProperty('homeApplication')
        ? overrides.homeApplication!
        : relationshipsToOmit.has('HomeApplication')
          ? ({} as HomeApplication)
          : aHomeApplication({}, relationshipsToOmit),
  }
}

export const aRevokeOfferingPayload = (
  overrides?: Partial<RevokeOfferingPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'RevokeOfferingPayload' } & RevokeOfferingPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('RevokeOfferingPayload')
  return {
    __typename: 'RevokeOfferingPayload',
    offering:
      overrides && overrides.hasOwnProperty('offering')
        ? overrides.offering!
        : relationshipsToOmit.has('Offering')
          ? ({} as Offering)
          : anOffering({}, relationshipsToOmit),
  }
}

export const aSavedSearch = (
  overrides?: Partial<SavedSearch>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'SavedSearch' } & SavedSearch => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('SavedSearch')
  return {
    __typename: 'SavedSearch',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '487b9dc6-0b14-4068-bd92-a724244257b5',
    monitoring: overrides && overrides.hasOwnProperty('monitoring') ? overrides.monitoring! : false,
    rentalType:
      overrides && overrides.hasOwnProperty('rentalType')
        ? overrides.rentalType!
        : HomeRentalTypeEnum.long_term,
    searchParams:
      overrides && overrides.hasOwnProperty('searchParams')
        ? overrides.searchParams!
        : relationshipsToOmit.has('HomeSearchParams')
          ? ({} as HomeSearchParams)
          : aHomeSearchParams({}, relationshipsToOmit),
    title: overrides && overrides.hasOwnProperty('title') ? overrides.title! : 'ex',
    visibility:
      overrides && overrides.hasOwnProperty('visibility')
        ? overrides.visibility!
        : SavedSearchVisibilityEnum.private,
  }
}

export const aSavingsVerification = (
  overrides?: Partial<SavingsVerification>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'SavingsVerification' } & SavingsVerification => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('SavingsVerification')
  return {
    __typename: 'SavingsVerification',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'aa8873d1-f64c-4532-9207-f9dd63452192',
    uploads:
      overrides && overrides.hasOwnProperty('uploads')
        ? overrides.uploads!
        : [relationshipsToOmit.has('Upload') ? ({} as Upload) : anUpload({}, relationshipsToOmit)],
    verifiedAt:
      overrides && overrides.hasOwnProperty('verifiedAt')
        ? overrides.verifiedAt!
        : 'et iste quasi dicta ipsum vel quisquam',
  }
}

export const aSchibstedLoginInput = (
  overrides?: Partial<SchibstedLoginInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): SchibstedLoginInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('SchibstedLoginInput')
  return {
    accountEnvironment:
      overrides && overrides.hasOwnProperty('accountEnvironment')
        ? overrides.accountEnvironment!
        : SchibstedAccountEnvironmentEnum.com,
    authorizationCode:
      overrides && overrides.hasOwnProperty('authorizationCode') ? overrides.authorizationCode! : 'dolor',
    companyName: overrides && overrides.hasOwnProperty('companyName') ? overrides.companyName! : 'deleniti',
    landlord: overrides && overrides.hasOwnProperty('landlord') ? overrides.landlord! : true,
    mainPlatform:
      overrides && overrides.hasOwnProperty('mainPlatform') ? overrides.mainPlatform! : PlatformEnum.blocket,
    orgNumber: overrides && overrides.hasOwnProperty('orgNumber') ? overrides.orgNumber! : 'perferendis',
    professional: overrides && overrides.hasOwnProperty('professional') ? overrides.professional! : true,
    redirectUrl: overrides && overrides.hasOwnProperty('redirectUrl') ? overrides.redirectUrl! : 'numquam',
    tenant: overrides && overrides.hasOwnProperty('tenant') ? overrides.tenant! : false,
  }
}

export const aSchibstedLoginPayload = (
  overrides?: Partial<SchibstedLoginPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'SchibstedLoginPayload' } & SchibstedLoginPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('SchibstedLoginPayload')
  return {
    __typename: 'SchibstedLoginPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    payload:
      overrides && overrides.hasOwnProperty('payload')
        ? overrides.payload!
        : relationshipsToOmit.has('LoginPayload')
          ? ({} as LoginPayload)
          : aLoginPayload({}, relationshipsToOmit),
  }
}

export const aScriveEidTransaction = (
  overrides?: Partial<ScriveEidTransaction>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ScriveEidTransaction' } & ScriveEidTransaction => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ScriveEidTransaction')
  return {
    __typename: 'ScriveEidTransaction',
    autoStartToken:
      overrides && overrides.hasOwnProperty('autoStartToken') ? overrides.autoStartToken! : 'reprehenderit',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'rerum',
  }
}

export const aSearchArea = (
  overrides?: Partial<SearchArea>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'SearchArea' } & SearchArea => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('SearchArea')
  return {
    __typename: 'SearchArea',
    children:
      overrides && overrides.hasOwnProperty('children')
        ? overrides.children!
        : relationshipsToOmit.has('SearchAreaConnection')
          ? ({} as SearchAreaConnection)
          : aSearchAreaConnection({}, relationshipsToOmit),
    city: overrides && overrides.hasOwnProperty('city') ? overrides.city! : 'ullam',
    countryCode: overrides && overrides.hasOwnProperty('countryCode') ? overrides.countryCode! : 'non',
    displayName:
      overrides && overrides.hasOwnProperty('displayName') ? overrides.displayName! : 'consequatur',
    fallback: overrides && overrides.hasOwnProperty('fallback') ? overrides.fallback! : false,
    geojson: overrides && overrides.hasOwnProperty('geojson') ? overrides.geojson! : {},
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '363eb22d-a94e-4dc8-85e2-557c3d2edebf',
    identifier: overrides && overrides.hasOwnProperty('identifier') ? overrides.identifier! : 'excepturi',
    name: overrides && overrides.hasOwnProperty('name') ? overrides.name! : 'aut',
    parent:
      overrides && overrides.hasOwnProperty('parent')
        ? overrides.parent!
        : relationshipsToOmit.has('SearchArea')
          ? ({} as SearchArea)
          : aSearchArea({}, relationshipsToOmit),
  }
}

export const aSearchAreaConnection = (
  overrides?: Partial<SearchAreaConnection>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'SearchAreaConnection' } & SearchAreaConnection => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('SearchAreaConnection')
  return {
    __typename: 'SearchAreaConnection',
    edges:
      overrides && overrides.hasOwnProperty('edges')
        ? overrides.edges!
        : [
            relationshipsToOmit.has('SearchAreaEdge')
              ? ({} as SearchAreaEdge)
              : aSearchAreaEdge({}, relationshipsToOmit),
          ],
    nodes:
      overrides && overrides.hasOwnProperty('nodes')
        ? overrides.nodes!
        : [relationshipsToOmit.has('SearchArea') ? ({} as SearchArea) : aSearchArea({}, relationshipsToOmit)],
    pageInfo:
      overrides && overrides.hasOwnProperty('pageInfo')
        ? overrides.pageInfo!
        : relationshipsToOmit.has('PageInfo')
          ? ({} as PageInfo)
          : aPageInfo({}, relationshipsToOmit),
  }
}

export const aSearchAreaEdge = (
  overrides?: Partial<SearchAreaEdge>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'SearchAreaEdge' } & SearchAreaEdge => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('SearchAreaEdge')
  return {
    __typename: 'SearchAreaEdge',
    cursor: overrides && overrides.hasOwnProperty('cursor') ? overrides.cursor! : 'fugiat',
    node:
      overrides && overrides.hasOwnProperty('node')
        ? overrides.node!
        : relationshipsToOmit.has('SearchArea')
          ? ({} as SearchArea)
          : aSearchArea({}, relationshipsToOmit),
  }
}

export const aSearchAreaMatcher = (
  overrides?: Partial<SearchAreaMatcher>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'SearchAreaMatcher' } & SearchAreaMatcher => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('SearchAreaMatcher')
  return {
    __typename: 'SearchAreaMatcher',
    city: overrides && overrides.hasOwnProperty('city') ? overrides.city! : 'voluptate',
    countryCode: overrides && overrides.hasOwnProperty('countryCode') ? overrides.countryCode! : 'pariatur',
    identifier: overrides && overrides.hasOwnProperty('identifier') ? overrides.identifier! : 'est',
    name: overrides && overrides.hasOwnProperty('name') ? overrides.name! : 'quod',
  }
}

export const aSearchPreference = (
  overrides?: Partial<SearchPreference>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'SearchPreference' } & SearchPreference => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('SearchPreference')
  return {
    __typename: 'SearchPreference',
    areaIdentifier:
      overrides && overrides.hasOwnProperty('areaIdentifier')
        ? overrides.areaIdentifier!
        : ['b74161c8-194a-461d-9ea2-542eaf8f42a3'],
    balcony: overrides && overrides.hasOwnProperty('balcony') ? overrides.balcony! : true,
    bathtub: overrides && overrides.hasOwnProperty('bathtub') ? overrides.bathtub! : false,
    bikeRoom: overrides && overrides.hasOwnProperty('bikeRoom') ? overrides.bikeRoom! : true,
    corporateHome: overrides && overrides.hasOwnProperty('corporateHome') ? overrides.corporateHome! : true,
    countryCode: overrides && overrides.hasOwnProperty('countryCode') ? overrides.countryCode! : 'facilis',
    currency: overrides && overrides.hasOwnProperty('currency') ? overrides.currency! : CurrencyEnum.EUR,
    dishWasher: overrides && overrides.hasOwnProperty('dishWasher') ? overrides.dishWasher! : true,
    elevator: overrides && overrides.hasOwnProperty('elevator') ? overrides.elevator! : false,
    endOptimal:
      overrides && overrides.hasOwnProperty('endOptimal')
        ? overrides.endOptimal!
        : 'aut quisquam consequatur dolorum qui cum omnis',
    endUfn: overrides && overrides.hasOwnProperty('endUfn') ? overrides.endUfn! : false,
    firsthand: overrides && overrides.hasOwnProperty('firsthand') ? overrides.firsthand! : true,
    furniture: overrides && overrides.hasOwnProperty('furniture') ? overrides.furniture! : true,
    homeType:
      overrides && overrides.hasOwnProperty('homeType') ? overrides.homeType! : [HomeTypeEnum.apartment],
    householdSize: overrides && overrides.hasOwnProperty('householdSize') ? overrides.householdSize! : 4746,
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '3fb094bd-4a0a-416a-8cc4-0bd371495223',
    inhomeSauna: overrides && overrides.hasOwnProperty('inhomeSauna') ? overrides.inhomeSauna! : true,
    internet: overrides && overrides.hasOwnProperty('internet') ? overrides.internet! : false,
    matchingAreaMetadata:
      overrides && overrides.hasOwnProperty('matchingAreaMetadata')
        ? overrides.matchingAreaMetadata!
        : [
            relationshipsToOmit.has('MatchingAreaMetadata')
              ? ({} as MatchingAreaMetadata)
              : aMatchingAreaMetadata({}, relationshipsToOmit),
          ],
    maxMonthlyCost:
      overrides && overrides.hasOwnProperty('maxMonthlyCost') ? overrides.maxMonthlyCost! : 9222,
    maxMonthlyCostCents:
      overrides && overrides.hasOwnProperty('maxMonthlyCostCents') ? overrides.maxMonthlyCostCents! : 7228,
    maxRoomCount: overrides && overrides.hasOwnProperty('maxRoomCount') ? overrides.maxRoomCount! : 627,
    maxSquareMeters:
      overrides && overrides.hasOwnProperty('maxSquareMeters') ? overrides.maxSquareMeters! : 5572,
    minRoomCount: overrides && overrides.hasOwnProperty('minRoomCount') ? overrides.minRoomCount! : 8164,
    minSquareMeters:
      overrides && overrides.hasOwnProperty('minSquareMeters') ? overrides.minSquareMeters! : 7050,
    parking: overrides && overrides.hasOwnProperty('parking') ? overrides.parking! : true,
    pets: overrides && overrides.hasOwnProperty('pets') ? overrides.pets! : true,
    recycling: overrides && overrides.hasOwnProperty('recycling') ? overrides.recycling! : false,
    searchAreas:
      overrides && overrides.hasOwnProperty('searchAreas')
        ? overrides.searchAreas!
        : [relationshipsToOmit.has('SearchArea') ? ({} as SearchArea) : aSearchArea({}, relationshipsToOmit)],
    seniorHome: overrides && overrides.hasOwnProperty('seniorHome') ? overrides.seniorHome! : false,
    shared: overrides && overrides.hasOwnProperty('shared') ? overrides.shared! : true,
    sharedWashingArea:
      overrides && overrides.hasOwnProperty('sharedWashingArea') ? overrides.sharedWashingArea! : false,
    shower: overrides && overrides.hasOwnProperty('shower') ? overrides.shower! : true,
    smoker: overrides && overrides.hasOwnProperty('smoker') ? overrides.smoker! : true,
    startAsap: overrides && overrides.hasOwnProperty('startAsap') ? overrides.startAsap! : false,
    startOptimal:
      overrides && overrides.hasOwnProperty('startOptimal')
        ? overrides.startOptimal!
        : 'dolores alias et vero quis doloremque quia',
    storage: overrides && overrides.hasOwnProperty('storage') ? overrides.storage! : true,
    studentHome: overrides && overrides.hasOwnProperty('studentHome') ? overrides.studentHome! : true,
    toilet: overrides && overrides.hasOwnProperty('toilet') ? overrides.toilet! : false,
    tumbleDryer: overrides && overrides.hasOwnProperty('tumbleDryer') ? overrides.tumbleDryer! : true,
    washingMachine:
      overrides && overrides.hasOwnProperty('washingMachine') ? overrides.washingMachine! : false,
    wheelchairAccessible:
      overrides && overrides.hasOwnProperty('wheelchairAccessible') ? overrides.wheelchairAccessible! : true,
  }
}

export const aSearchPreferenceInput = (
  overrides?: Partial<SearchPreferenceInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): SearchPreferenceInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('SearchPreferenceInput')
  return {
    areaIdentifier:
      overrides && overrides.hasOwnProperty('areaIdentifier')
        ? overrides.areaIdentifier!
        : ['5daf0e3d-9686-463e-898a-59eb10d07af7'],
    balcony: overrides && overrides.hasOwnProperty('balcony') ? overrides.balcony! : true,
    bathtub: overrides && overrides.hasOwnProperty('bathtub') ? overrides.bathtub! : false,
    bikeRoom: overrides && overrides.hasOwnProperty('bikeRoom') ? overrides.bikeRoom! : true,
    corporateHome: overrides && overrides.hasOwnProperty('corporateHome') ? overrides.corporateHome! : true,
    countryCode: overrides && overrides.hasOwnProperty('countryCode') ? overrides.countryCode! : 'recusandae',
    currency: overrides && overrides.hasOwnProperty('currency') ? overrides.currency! : CurrencyEnum.EUR,
    dishWasher: overrides && overrides.hasOwnProperty('dishWasher') ? overrides.dishWasher! : false,
    elevator: overrides && overrides.hasOwnProperty('elevator') ? overrides.elevator! : false,
    endOptimal:
      overrides && overrides.hasOwnProperty('endOptimal')
        ? overrides.endOptimal!
        : 'ipsum minus mollitia quos fugit eaque iusto',
    endUfn: overrides && overrides.hasOwnProperty('endUfn') ? overrides.endUfn! : false,
    firsthand: overrides && overrides.hasOwnProperty('firsthand') ? overrides.firsthand! : false,
    furniture: overrides && overrides.hasOwnProperty('furniture') ? overrides.furniture! : false,
    homeType:
      overrides && overrides.hasOwnProperty('homeType') ? overrides.homeType! : [HomeTypeEnum.apartment],
    householdSize: overrides && overrides.hasOwnProperty('householdSize') ? overrides.householdSize! : 8701,
    inhomeSauna: overrides && overrides.hasOwnProperty('inhomeSauna') ? overrides.inhomeSauna! : true,
    internet: overrides && overrides.hasOwnProperty('internet') ? overrides.internet! : false,
    matchingAreaMetadata:
      overrides && overrides.hasOwnProperty('matchingAreaMetadata')
        ? overrides.matchingAreaMetadata!
        : [
            relationshipsToOmit.has('MatchingAreaMetadataInput')
              ? ({} as MatchingAreaMetadataInput)
              : aMatchingAreaMetadataInput({}, relationshipsToOmit),
          ],
    maxMonthlyCost:
      overrides && overrides.hasOwnProperty('maxMonthlyCost') ? overrides.maxMonthlyCost! : 4076,
    maxRoomCount: overrides && overrides.hasOwnProperty('maxRoomCount') ? overrides.maxRoomCount! : 7561,
    maxSquareMeters:
      overrides && overrides.hasOwnProperty('maxSquareMeters') ? overrides.maxSquareMeters! : 6123,
    minRoomCount: overrides && overrides.hasOwnProperty('minRoomCount') ? overrides.minRoomCount! : 6605,
    minSquareMeters:
      overrides && overrides.hasOwnProperty('minSquareMeters') ? overrides.minSquareMeters! : 8296,
    parking: overrides && overrides.hasOwnProperty('parking') ? overrides.parking! : true,
    pets: overrides && overrides.hasOwnProperty('pets') ? overrides.pets! : true,
    recycling: overrides && overrides.hasOwnProperty('recycling') ? overrides.recycling! : false,
    seniorHome: overrides && overrides.hasOwnProperty('seniorHome') ? overrides.seniorHome! : true,
    shared: overrides && overrides.hasOwnProperty('shared') ? overrides.shared! : false,
    sharedWashingArea:
      overrides && overrides.hasOwnProperty('sharedWashingArea') ? overrides.sharedWashingArea! : true,
    shower: overrides && overrides.hasOwnProperty('shower') ? overrides.shower! : true,
    smoker: overrides && overrides.hasOwnProperty('smoker') ? overrides.smoker! : false,
    startAsap: overrides && overrides.hasOwnProperty('startAsap') ? overrides.startAsap! : true,
    startOptimal:
      overrides && overrides.hasOwnProperty('startOptimal')
        ? overrides.startOptimal!
        : 'officiis laborum molestias nemo velit et qui',
    storage: overrides && overrides.hasOwnProperty('storage') ? overrides.storage! : false,
    studentHome: overrides && overrides.hasOwnProperty('studentHome') ? overrides.studentHome! : true,
    toilet: overrides && overrides.hasOwnProperty('toilet') ? overrides.toilet! : false,
    tumbleDryer: overrides && overrides.hasOwnProperty('tumbleDryer') ? overrides.tumbleDryer! : true,
    washingMachine:
      overrides && overrides.hasOwnProperty('washingMachine') ? overrides.washingMachine! : false,
    wheelchairAccessible:
      overrides && overrides.hasOwnProperty('wheelchairAccessible') ? overrides.wheelchairAccessible! : false,
  }
}

export const aSendBookingEmailsPayload = (
  overrides?: Partial<SendBookingEmailsPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'SendBookingEmailsPayload' } & SendBookingEmailsPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('SendBookingEmailsPayload')
  return {
    __typename: 'SendBookingEmailsPayload',
    errorMessage: overrides && overrides.hasOwnProperty('errorMessage') ? overrides.errorMessage! : 'est',
    status: overrides && overrides.hasOwnProperty('status') ? overrides.status! : SendEmailStatusEnum.invalid,
  }
}

export const aSendEmailConfirmationTokenPayload = (
  overrides?: Partial<SendEmailConfirmationTokenPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'SendEmailConfirmationTokenPayload' } & SendEmailConfirmationTokenPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('SendEmailConfirmationTokenPayload')
  return {
    __typename: 'SendEmailConfirmationTokenPayload',
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : true,
  }
}

export const aSendMassMessagePayload = (
  overrides?: Partial<SendMassMessagePayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'SendMassMessagePayload' } & SendMassMessagePayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('SendMassMessagePayload')
  return {
    __typename: 'SendMassMessagePayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : false,
  }
}

export const aSendSignInCodePayload = (
  overrides?: Partial<SendSignInCodePayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'SendSignInCodePayload' } & SendSignInCodePayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('SendSignInCodePayload')
  return {
    __typename: 'SendSignInCodePayload',
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : true,
  }
}

export const aService = (
  overrides?: Partial<Service>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Service' } & Service => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Service')
  return {
    __typename: 'Service',
    availability:
      overrides && overrides.hasOwnProperty('availability')
        ? overrides.availability!
        : ServiceAvailabilityEnum.included,
    cost:
      overrides && overrides.hasOwnProperty('cost')
        ? overrides.cost!
        : relationshipsToOmit.has('Cost')
          ? ({} as Cost)
          : aCost({}, relationshipsToOmit),
    isActive: overrides && overrides.hasOwnProperty('isActive') ? overrides.isActive! : false,
  }
}

export const aSetActiveBankAccountPayload = (
  overrides?: Partial<SetActiveBankAccountPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'SetActiveBankAccountPayload' } & SetActiveBankAccountPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('SetActiveBankAccountPayload')
  return {
    __typename: 'SetActiveBankAccountPayload',
    bankAccount:
      overrides && overrides.hasOwnProperty('bankAccount')
        ? overrides.bankAccount!
        : relationshipsToOmit.has('BankAccount')
          ? ({} as BankAccount)
          : aBankAccount({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const aSetDepositFreePayload = (
  overrides?: Partial<SetDepositFreePayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'SetDepositFreePayload' } & SetDepositFreePayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('SetDepositFreePayload')
  return {
    __typename: 'SetDepositFreePayload',
    contract:
      overrides && overrides.hasOwnProperty('contract')
        ? overrides.contract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const aSetScriveFieldsInput = (
  overrides?: Partial<SetScriveFieldsInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): SetScriveFieldsInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('SetScriveFieldsInput')
  return {
    email: overrides && overrides.hasOwnProperty('email') ? overrides.email! : 'amet',
    familyName: overrides && overrides.hasOwnProperty('familyName') ? overrides.familyName! : 'reprehenderit',
    firstName: overrides && overrides.hasOwnProperty('firstName') ? overrides.firstName! : 'ratione',
    mobile: overrides && overrides.hasOwnProperty('mobile') ? overrides.mobile! : 'impedit',
  }
}

export const aSetScriveFieldsPayload = (
  overrides?: Partial<SetScriveFieldsPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'SetScriveFieldsPayload' } & SetScriveFieldsPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('SetScriveFieldsPayload')
  return {
    __typename: 'SetScriveFieldsPayload',
    contract:
      overrides && overrides.hasOwnProperty('contract')
        ? overrides.contract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const aShortcut = (
  overrides?: Partial<Shortcut>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Shortcut' } & Shortcut => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Shortcut')
  return {
    __typename: 'Shortcut',
    agent:
      overrides && overrides.hasOwnProperty('agent')
        ? overrides.agent!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
    agentHome:
      overrides && overrides.hasOwnProperty('agentHome')
        ? overrides.agentHome!
        : relationshipsToOmit.has('Home')
          ? ({} as Home)
          : aHome({}, relationshipsToOmit),
    allowedTransitions:
      overrides && overrides.hasOwnProperty('allowedTransitions')
        ? overrides.allowedTransitions!
        : [ShortcutStatesEnum.aborted],
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'cupiditate atque numquam earum repellendus tempore quisquam',
    currentState:
      overrides && overrides.hasOwnProperty('currentState')
        ? overrides.currentState!
        : ShortcutStatesEnum.aborted,
    homePitch: overrides && overrides.hasOwnProperty('homePitch') ? overrides.homePitch! : 'accusamus',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '7592b26e-4488-41b1-bc1b-d1067542410c',
    landlordHome:
      overrides && overrides.hasOwnProperty('landlordHome')
        ? overrides.landlordHome!
        : relationshipsToOmit.has('Home')
          ? ({} as Home)
          : aHome({}, relationshipsToOmit),
    updatedAt:
      overrides && overrides.hasOwnProperty('updatedAt')
        ? overrides.updatedAt!
        : 'ab autem optio sed nam ad deleniti',
  }
}

export const aSignVacationContractPayload = (
  overrides?: Partial<SignVacationContractPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'SignVacationContractPayload' } & SignVacationContractPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('SignVacationContractPayload')
  return {
    __typename: 'SignVacationContractPayload',
    contract:
      overrides && overrides.hasOwnProperty('contract')
        ? overrides.contract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const aSignatory = (
  overrides?: Partial<Signatory>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Signatory' } & Signatory => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Signatory')
  return {
    __typename: 'Signatory',
    canSign: overrides && overrides.hasOwnProperty('canSign') ? overrides.canSign! : false,
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'a5662e3e-7ce3-47cb-9b32-da82e3f81de8',
    invitationMethod:
      overrides && overrides.hasOwnProperty('invitationMethod') ? overrides.invitationMethod! : 'ipsum',
    order: overrides && overrides.hasOwnProperty('order') ? overrides.order! : 665,
    role:
      overrides && overrides.hasOwnProperty('role') ? overrides.role! : SignatoryRoleEnum.cosigning_tenant,
    signUrl: overrides && overrides.hasOwnProperty('signUrl') ? overrides.signUrl! : 'aut',
    signedAt:
      overrides && overrides.hasOwnProperty('signedAt')
        ? overrides.signedAt!
        : 'quo numquam et ut non quo magni',
    signingDeadline:
      overrides && overrides.hasOwnProperty('signingDeadline')
        ? overrides.signingDeadline!
        : 'quasi veritatis nemo temporibus consequuntur sit eum',
    user:
      overrides && overrides.hasOwnProperty('user')
        ? overrides.user!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
  }
}

export const aSimilarHomes = (
  overrides?: Partial<SimilarHomes>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'SimilarHomes' } & SimilarHomes => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('SimilarHomes')
  return {
    __typename: 'SimilarHomes',
    homeId:
      overrides && overrides.hasOwnProperty('homeId')
        ? overrides.homeId!
        : '547785bc-2c4e-4745-aa17-1e27e1691840',
    similarHomes:
      overrides && overrides.hasOwnProperty('similarHomes')
        ? overrides.similarHomes!
        : [relationshipsToOmit.has('Home') ? ({} as Home) : aHome({}, relationshipsToOmit)],
  }
}

export const aSmoochParams = (
  overrides?: Partial<SmoochParams>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'SmoochParams' } & SmoochParams => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('SmoochParams')
  return {
    __typename: 'SmoochParams',
    smoochJwt: overrides && overrides.hasOwnProperty('smoochJwt') ? overrides.smoochJwt! : 'dignissimos',
  }
}

export const aSocEvictionEmailQasaInfo = (
  overrides?: Partial<SocEvictionEmailQasaInfo>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'SocEvictionEmailQasaInfo' } & SocEvictionEmailQasaInfo => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('SocEvictionEmailQasaInfo')
  return {
    __typename: 'SocEvictionEmailQasaInfo',
    adress: overrides && overrides.hasOwnProperty('adress') ? overrides.adress! : 'consequatur',
    bankgiro: overrides && overrides.hasOwnProperty('bankgiro') ? overrides.bankgiro! : 'enim',
    firma: overrides && overrides.hasOwnProperty('firma') ? overrides.firma! : 'dolorum',
    orgnummer: overrides && overrides.hasOwnProperty('orgnummer') ? overrides.orgnummer! : 'harum',
    ort: overrides && overrides.hasOwnProperty('ort') ? overrides.ort! : 'ut',
    postnummer: overrides && overrides.hasOwnProperty('postnummer') ? overrides.postnummer! : 'similique',
  }
}

export const aStarMarkApplicationPayload = (
  overrides?: Partial<StarMarkApplicationPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'StarMarkApplicationPayload' } & StarMarkApplicationPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('StarMarkApplicationPayload')
  return {
    __typename: 'StarMarkApplicationPayload',
    homeApplication:
      overrides && overrides.hasOwnProperty('homeApplication')
        ? overrides.homeApplication!
        : relationshipsToOmit.has('HomeApplication')
          ? ({} as HomeApplication)
          : aHomeApplication({}, relationshipsToOmit),
  }
}

export const aStarMarkConversationPayload = (
  overrides?: Partial<StarMarkConversationPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'StarMarkConversationPayload' } & StarMarkConversationPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('StarMarkConversationPayload')
  return {
    __typename: 'StarMarkConversationPayload',
    conversation:
      overrides && overrides.hasOwnProperty('conversation')
        ? overrides.conversation!
        : relationshipsToOmit.has('Conversation')
          ? ({} as Conversation)
          : aConversation({}, relationshipsToOmit),
  }
}

export const aStartBankIdTransactionPayload = (
  overrides?: Partial<StartBankIdTransactionPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'StartBankIdTransactionPayload' } & StartBankIdTransactionPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('StartBankIdTransactionPayload')
  return {
    __typename: 'StartBankIdTransactionPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : true,
    transaction:
      overrides && overrides.hasOwnProperty('transaction')
        ? overrides.transaction!
        : relationshipsToOmit.has('ScriveEidTransaction')
          ? ({} as ScriveEidTransaction)
          : aScriveEidTransaction({}, relationshipsToOmit),
  }
}

export const aStripeCheckoutInput = (
  overrides?: Partial<StripeCheckoutInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): StripeCheckoutInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('StripeCheckoutInput')
  return {
    cancelUrl: overrides && overrides.hasOwnProperty('cancelUrl') ? overrides.cancelUrl! : 'et',
    successUrl: overrides && overrides.hasOwnProperty('successUrl') ? overrides.successUrl! : 'qui',
  }
}

export const aStripePayment = (
  overrides?: Partial<StripePayment>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'StripePayment' } & StripePayment => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('StripePayment')
  return {
    __typename: 'StripePayment',
    currency: overrides && overrides.hasOwnProperty('currency') ? overrides.currency! : CurrencyEnum.EUR,
    price: overrides && overrides.hasOwnProperty('price') ? overrides.price! : 2411,
    stripeSKU: overrides && overrides.hasOwnProperty('stripeSKU') ? overrides.stripeSKU! : 'nihil',
  }
}

export const aStripeSepaTransferMoneyAction = (
  overrides?: Partial<StripeSepaTransferMoneyAction>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'StripeSepaTransferMoneyAction' } & StripeSepaTransferMoneyAction => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('StripeSepaTransferMoneyAction')
  return {
    __typename: 'StripeSepaTransferMoneyAction',
    amount:
      overrides && overrides.hasOwnProperty('amount')
        ? overrides.amount!
        : relationshipsToOmit.has('Money')
          ? ({} as Money)
          : aMoney({}, relationshipsToOmit),
    bic: overrides && overrides.hasOwnProperty('bic') ? overrides.bic! : 'quaerat',
    currency: overrides && overrides.hasOwnProperty('currency') ? overrides.currency! : CurrencyEnum.EUR,
    iban: overrides && overrides.hasOwnProperty('iban') ? overrides.iban! : 'eum',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'f4231796-a77b-48ec-8573-74fb056272be',
    reference: overrides && overrides.hasOwnProperty('reference') ? overrides.reference! : 'adipisci',
  }
}

export const aStripeSetupAccountAction = (
  overrides?: Partial<StripeSetupAccountAction>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'StripeSetupAccountAction' } & StripeSetupAccountAction => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('StripeSetupAccountAction')
  return {
    __typename: 'StripeSetupAccountAction',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '5c4db564-b463-43fd-b937-06d47f895d65',
    url: overrides && overrides.hasOwnProperty('url') ? overrides.url! : 'eos',
  }
}

export const aStudentVerification = (
  overrides?: Partial<StudentVerification>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'StudentVerification' } & StudentVerification => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('StudentVerification')
  return {
    __typename: 'StudentVerification',
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'in blanditiis ut omnis nulla eos et',
    educationType:
      overrides && overrides.hasOwnProperty('educationType')
        ? overrides.educationType!
        : StudentVerificationEducationTypeEnum.municipal_adult_education,
    expectedFinishDate:
      overrides && overrides.hasOwnProperty('expectedFinishDate')
        ? overrides.expectedFinishDate!
        : 'officia ut suscipit quia debitis quia quo',
    extent:
      overrides && overrides.hasOwnProperty('extent')
        ? overrides.extent!
        : StudentVerificationExtentEnum.full_time,
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '386dab0a-f60a-4f07-b947-5e6571451c2b',
    program: overrides && overrides.hasOwnProperty('program') ? overrides.program! : 'est',
    school: overrides && overrides.hasOwnProperty('school') ? overrides.school! : 'dicta',
    startDate:
      overrides && overrides.hasOwnProperty('startDate')
        ? overrides.startDate!
        : 'nulla impedit enim natus rem repudiandae minima',
    updatedAt:
      overrides && overrides.hasOwnProperty('updatedAt')
        ? overrides.updatedAt!
        : 'modi ut qui facilis molestias facere asperiores',
    upload:
      overrides && overrides.hasOwnProperty('upload')
        ? overrides.upload!
        : relationshipsToOmit.has('Upload')
          ? ({} as Upload)
          : anUpload({}, relationshipsToOmit),
  }
}

export const aSubUser = (
  overrides?: Partial<SubUser>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'SubUser' } & SubUser => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('SubUser')
  return {
    __typename: 'SubUser',
    email: overrides && overrides.hasOwnProperty('email') ? overrides.email! : 'vel',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'f5089fbe-ef4a-4659-a5db-c23c2096c325',
  }
}

export const aSubUserCreateResetPasswordTokenPayload = (
  overrides?: Partial<SubUserCreateResetPasswordTokenPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'SubUserCreateResetPasswordTokenPayload' } & SubUserCreateResetPasswordTokenPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('SubUserCreateResetPasswordTokenPayload')
  return {
    __typename: 'SubUserCreateResetPasswordTokenPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : true,
  }
}

export const aSubUserLoginPayload = (
  overrides?: Partial<SubUserLoginPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'SubUserLoginPayload' } & SubUserLoginPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('SubUserLoginPayload')
  return {
    __typename: 'SubUserLoginPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    payload:
      overrides && overrides.hasOwnProperty('payload')
        ? overrides.payload!
        : relationshipsToOmit.has('LoginPayload')
          ? ({} as LoginPayload)
          : aLoginPayload({}, relationshipsToOmit),
  }
}

export const aSubUserResetPasswordPayload = (
  overrides?: Partial<SubUserResetPasswordPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'SubUserResetPasswordPayload' } & SubUserResetPasswordPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('SubUserResetPasswordPayload')
  return {
    __typename: 'SubUserResetPasswordPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : true,
  }
}

export const aSubscriptionRoot = (
  overrides?: Partial<SubscriptionRoot>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'SubscriptionRoot' } & SubscriptionRoot => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('SubscriptionRoot')
  return {
    __typename: 'SubscriptionRoot',
    messagesSubscription:
      overrides && overrides.hasOwnProperty('messagesSubscription')
        ? overrides.messagesSubscription!
        : relationshipsToOmit.has('MessagesSubscriptionPayload')
          ? ({} as MessagesSubscriptionPayload)
          : aMessagesSubscriptionPayload({}, relationshipsToOmit),
  }
}

export const aSuccess = (
  overrides?: Partial<Success>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Success' } & Success => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Success')
  return {
    __typename: 'Success',
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : false,
  }
}

export const aSuspicion = (
  overrides?: Partial<Suspicion>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Suspicion' } & Suspicion => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Suspicion')
  return {
    __typename: 'Suspicion',
    adminComment:
      overrides && overrides.hasOwnProperty('adminComment') ? overrides.adminComment! : 'laboriosam',
    flaggedAsFalseAt:
      overrides && overrides.hasOwnProperty('flaggedAsFalseAt')
        ? overrides.flaggedAsFalseAt!
        : 'nisi sed aut voluptatibus quasi eos autem',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '60767d5e-8f3c-47fa-8eb0-2df33b9a1991',
    type:
      overrides && overrides.hasOwnProperty('type')
        ? overrides.type!
        : SuspicionTypeEnum.home_application_spam,
  }
}

export const aSuspicionConnection = (
  overrides?: Partial<SuspicionConnection>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'SuspicionConnection' } & SuspicionConnection => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('SuspicionConnection')
  return {
    __typename: 'SuspicionConnection',
    edges:
      overrides && overrides.hasOwnProperty('edges')
        ? overrides.edges!
        : [
            relationshipsToOmit.has('SuspicionEdge')
              ? ({} as SuspicionEdge)
              : aSuspicionEdge({}, relationshipsToOmit),
          ],
    nodes:
      overrides && overrides.hasOwnProperty('nodes')
        ? overrides.nodes!
        : [relationshipsToOmit.has('Suspicion') ? ({} as Suspicion) : aSuspicion({}, relationshipsToOmit)],
    pageInfo:
      overrides && overrides.hasOwnProperty('pageInfo')
        ? overrides.pageInfo!
        : relationshipsToOmit.has('PageInfo')
          ? ({} as PageInfo)
          : aPageInfo({}, relationshipsToOmit),
    totalCount: overrides && overrides.hasOwnProperty('totalCount') ? overrides.totalCount! : 6306,
  }
}

export const aSuspicionEdge = (
  overrides?: Partial<SuspicionEdge>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'SuspicionEdge' } & SuspicionEdge => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('SuspicionEdge')
  return {
    __typename: 'SuspicionEdge',
    cursor: overrides && overrides.hasOwnProperty('cursor') ? overrides.cursor! : 'quae',
    node:
      overrides && overrides.hasOwnProperty('node')
        ? overrides.node!
        : relationshipsToOmit.has('Suspicion')
          ? ({} as Suspicion)
          : aSuspicion({}, relationshipsToOmit),
  }
}

export const aSwishPayment = (
  overrides?: Partial<SwishPayment>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'SwishPayment' } & SwishPayment => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('SwishPayment')
  return {
    __typename: 'SwishPayment',
    amountCents: overrides && overrides.hasOwnProperty('amountCents') ? overrides.amountCents! : 631,
    currency: overrides && overrides.hasOwnProperty('currency') ? overrides.currency! : 'minima',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'c1ae4907-e4f7-410e-8b3e-495ccd8306ac',
    paymentReferenceId:
      overrides && overrides.hasOwnProperty('paymentReferenceId')
        ? overrides.paymentReferenceId!
        : '450b3069-81f0-4e6a-bb47-4d1f81f14769',
    status:
      overrides && overrides.hasOwnProperty('status') ? overrides.status! : SwishPaymentStatusEnum.CANCELLED,
  }
}

export const aTagInput = (
  overrides?: Partial<TagInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): TagInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('TagInput')
  return {
    uploadTagInput:
      overrides && overrides.hasOwnProperty('uploadTagInput')
        ? overrides.uploadTagInput!
        : [
            relationshipsToOmit.has('UploadTaggableInput')
              ? ({} as UploadTaggableInput)
              : anUploadTaggableInput({}, relationshipsToOmit),
          ],
  }
}

export const aTaggableInput = (
  overrides?: Partial<TaggableInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): TaggableInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('TaggableInput')
  return {
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '9b93e31e-f10b-4a1c-a73b-1b886317e4ce',
    type: overrides && overrides.hasOwnProperty('type') ? overrides.type! : TaggableTypeEnum.Upload,
  }
}

export const aTaxReportAddressType = (
  overrides?: Partial<TaxReportAddressType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'TaxReportAddressType' } & TaxReportAddressType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('TaxReportAddressType')
  return {
    __typename: 'TaxReportAddressType',
    countryCode: overrides && overrides.hasOwnProperty('countryCode') ? overrides.countryCode! : 'dolorum',
    locality: overrides && overrides.hasOwnProperty('locality') ? overrides.locality! : 'totam',
    postalCode: overrides && overrides.hasOwnProperty('postalCode') ? overrides.postalCode! : 'et',
    streetAddress:
      overrides && overrides.hasOwnProperty('streetAddress') ? overrides.streetAddress! : 'vitae',
  }
}

export const aTaxReportMetadataType = (
  overrides?: Partial<TaxReportMetadataType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'TaxReportMetadataType' } & TaxReportMetadataType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('TaxReportMetadataType')
  return {
    __typename: 'TaxReportMetadataType',
    address:
      overrides && overrides.hasOwnProperty('address')
        ? overrides.address!
        : relationshipsToOmit.has('MetadataAddressType')
          ? ({} as MetadataAddressType)
          : aMetadataAddressType({}, relationshipsToOmit),
    bankAccount:
      overrides && overrides.hasOwnProperty('bankAccount')
        ? overrides.bankAccount!
        : relationshipsToOmit.has('BankAccountType')
          ? ({} as BankAccountType)
          : aBankAccountType({}, relationshipsToOmit),
    companyName: overrides && overrides.hasOwnProperty('companyName') ? overrides.companyName! : 'nam',
    familyName: overrides && overrides.hasOwnProperty('familyName') ? overrides.familyName! : 'facere',
    firstName: overrides && overrides.hasOwnProperty('firstName') ? overrides.firstName! : 'voluptas',
    freeFormatAddress:
      overrides && overrides.hasOwnProperty('freeFormatAddress')
        ? overrides.freeFormatAddress!
        : relationshipsToOmit.has('MetadataFreeFormatAddressType')
          ? ({} as MetadataFreeFormatAddressType)
          : aMetadataFreeFormatAddressType({}, relationshipsToOmit),
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'f50dcf71-5df9-4158-bc14-461f037d6823',
    idNumber: overrides && overrides.hasOwnProperty('idNumber') ? overrides.idNumber! : 'ut',
    orgNo: overrides && overrides.hasOwnProperty('orgNo') ? overrides.orgNo! : 'voluptas',
    professional: overrides && overrides.hasOwnProperty('professional') ? overrides.professional! : false,
    taxYear: overrides && overrides.hasOwnProperty('taxYear') ? overrides.taxYear! : 4459,
    user:
      overrides && overrides.hasOwnProperty('user')
        ? overrides.user!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
  }
}

export const aTaxReportQuarterType = (
  overrides?: Partial<TaxReportQuarterType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'TaxReportQuarterType' } & TaxReportQuarterType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('TaxReportQuarterType')
  return {
    __typename: 'TaxReportQuarterType',
    compensation: overrides && overrides.hasOwnProperty('compensation') ? overrides.compensation! : 5375,
    fees: overrides && overrides.hasOwnProperty('fees') ? overrides.fees! : 4277,
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'f094617c-7057-433f-9301-f5e1584f35b0',
    numberOfTransactions:
      overrides && overrides.hasOwnProperty('numberOfTransactions') ? overrides.numberOfTransactions! : 4023,
    quarter: overrides && overrides.hasOwnProperty('quarter') ? overrides.quarter! : 7747,
    taxes: overrides && overrides.hasOwnProperty('taxes') ? overrides.taxes! : 5497,
  }
}

export const aTaxReportType = (
  overrides?: Partial<TaxReportType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'TaxReportType' } & TaxReportType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('TaxReportType')
  return {
    __typename: 'TaxReportType',
    address:
      overrides && overrides.hasOwnProperty('address')
        ? overrides.address!
        : relationshipsToOmit.has('TaxReportAddressType')
          ? ({} as TaxReportAddressType)
          : aTaxReportAddressType({}, relationshipsToOmit),
    contracts:
      overrides && overrides.hasOwnProperty('contracts')
        ? overrides.contracts!
        : [relationshipsToOmit.has('Contract') ? ({} as Contract) : aContract({}, relationshipsToOmit)],
    daysRentedOut: overrides && overrides.hasOwnProperty('daysRentedOut') ? overrides.daysRentedOut! : 9068,
    home:
      overrides && overrides.hasOwnProperty('home')
        ? overrides.home!
        : relationshipsToOmit.has('Home')
          ? ({} as Home)
          : aHome({}, relationshipsToOmit),
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'ca06b837-8d27-41ae-87e0-48d4ab617e04',
    metadata:
      overrides && overrides.hasOwnProperty('metadata')
        ? overrides.metadata!
        : relationshipsToOmit.has('TaxReportMetadataType')
          ? ({} as TaxReportMetadataType)
          : aTaxReportMetadataType({}, relationshipsToOmit),
    quarters:
      overrides && overrides.hasOwnProperty('quarters')
        ? overrides.quarters!
        : [
            relationshipsToOmit.has('TaxReportQuarterType')
              ? ({} as TaxReportQuarterType)
              : aTaxReportQuarterType({}, relationshipsToOmit),
          ],
    taxYear: overrides && overrides.hasOwnProperty('taxYear') ? overrides.taxYear! : 8653,
    user:
      overrides && overrides.hasOwnProperty('user')
        ? overrides.user!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
  }
}

export const aTemporaryContract = (
  overrides?: Partial<TemporaryContract>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'TemporaryContract' } & TemporaryContract => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('TemporaryContract')
  return {
    __typename: 'TemporaryContract',
    acceptsCosignatures:
      overrides && overrides.hasOwnProperty('acceptsCosignatures') ? overrides.acceptsCosignatures! : false,
    adminNotes:
      overrides && overrides.hasOwnProperty('adminNotes')
        ? overrides.adminNotes!
        : [relationshipsToOmit.has('AdminNote') ? ({} as AdminNote) : anAdminNote({}, relationshipsToOmit)],
    annualRentIncrease:
      overrides && overrides.hasOwnProperty('annualRentIncrease') ? overrides.annualRentIncrease! : 8.61,
    apartmentNumber:
      overrides && overrides.hasOwnProperty('apartmentNumber') ? overrides.apartmentNumber! : 'sit',
    availablePreviewLanguages:
      overrides && overrides.hasOwnProperty('availablePreviewLanguages')
        ? overrides.availablePreviewLanguages!
        : [ContractLanguageEnum.en],
    booking:
      overrides && overrides.hasOwnProperty('booking')
        ? overrides.booking!
        : relationshipsToOmit.has('Booking')
          ? ({} as Booking)
          : aBooking({}, relationshipsToOmit),
    canUse: overrides && overrides.hasOwnProperty('canUse') ? overrides.canUse! : ['porro'],
    cleaningFee: overrides && overrides.hasOwnProperty('cleaningFee') ? overrides.cleaningFee! : 551,
    contract:
      overrides && overrides.hasOwnProperty('contract')
        ? overrides.contract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    contractDetails:
      overrides && overrides.hasOwnProperty('contractDetails')
        ? overrides.contractDetails!
        : relationshipsToOmit.has('ContractDetails')
          ? ({} as ContractDetails)
          : aContractDetails({}, relationshipsToOmit),
    conversation:
      overrides && overrides.hasOwnProperty('conversation')
        ? overrides.conversation!
        : relationshipsToOmit.has('Conversation')
          ? ({} as Conversation)
          : aConversation({}, relationshipsToOmit),
    cosigningTenants:
      overrides && overrides.hasOwnProperty('cosigningTenants')
        ? overrides.cosigningTenants!
        : [relationshipsToOmit.has('User') ? ({} as User) : aUser({}, relationshipsToOmit)],
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'nobis exercitationem maxime voluptatem distinctio dolores ipsa',
    currency: overrides && overrides.hasOwnProperty('currency') ? overrides.currency! : CurrencyEnum.EUR,
    currentUserSignatory:
      overrides && overrides.hasOwnProperty('currentUserSignatory')
        ? overrides.currentUserSignatory!
        : relationshipsToOmit.has('Signatory')
          ? ({} as Signatory)
          : aSignatory({}, relationshipsToOmit),
    depositFree: overrides && overrides.hasOwnProperty('depositFree') ? overrides.depositFree! : true,
    depositFreeCost:
      overrides && overrides.hasOwnProperty('depositFreeCost') ? overrides.depositFreeCost! : 8555,
    depositPayoutApprovedAt:
      overrides && overrides.hasOwnProperty('depositPayoutApprovedAt')
        ? overrides.depositPayoutApprovedAt!
        : 'ad molestiae impedit sequi aut autem eos',
    depositPayoutDeniedAt:
      overrides && overrides.hasOwnProperty('depositPayoutDeniedAt')
        ? overrides.depositPayoutDeniedAt!
        : 'labore molestiae sunt et eos accusantium velit',
    duration:
      overrides && overrides.hasOwnProperty('duration')
        ? overrides.duration!
        : relationshipsToOmit.has('Duration')
          ? ({} as Duration)
          : aDuration({}, relationshipsToOmit),
    electricityFee:
      overrides && overrides.hasOwnProperty('electricityFee')
        ? overrides.electricityFee!
        : relationshipsToOmit.has('ElectricityFeeType')
          ? ({} as ElectricityFeeType)
          : anElectricityFeeType({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    evictionProcess:
      overrides && overrides.hasOwnProperty('evictionProcess')
        ? overrides.evictionProcess!
        : relationshipsToOmit.has('EvictionProcess')
          ? ({} as EvictionProcess)
          : anEvictionProcess({}, relationshipsToOmit),
    extensionContract:
      overrides && overrides.hasOwnProperty('extensionContract')
        ? overrides.extensionContract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    externalContractTakeover:
      overrides && overrides.hasOwnProperty('externalContractTakeover')
        ? overrides.externalContractTakeover!
        : relationshipsToOmit.has('ExternalContractTakeover')
          ? ({} as ExternalContractTakeover)
          : anExternalContractTakeover({}, relationshipsToOmit),
    extraCosts:
      overrides && overrides.hasOwnProperty('extraCosts')
        ? overrides.extraCosts!
        : [relationshipsToOmit.has('ExtraCost') ? ({} as ExtraCost) : anExtraCost({}, relationshipsToOmit)],
    fee: overrides && overrides.hasOwnProperty('fee') ? overrides.fee! : 5000,
    financeDepositInvoices:
      overrides && overrides.hasOwnProperty('financeDepositInvoices')
        ? overrides.financeDepositInvoices!
        : [
            relationshipsToOmit.has('FinanceDepositInvoice')
              ? ({} as FinanceDepositInvoice)
              : aFinanceDepositInvoice({}, relationshipsToOmit),
          ],
    financeInvoices:
      overrides && overrides.hasOwnProperty('financeInvoices')
        ? overrides.financeInvoices!
        : [
            relationshipsToOmit.has('InvoiceInterface')
              ? ({} as InvoiceInterface)
              : anInvoiceInterface({}, relationshipsToOmit),
          ],
    financePayouts:
      overrides && overrides.hasOwnProperty('financePayouts')
        ? overrides.financePayouts!
        : [
            relationshipsToOmit.has('FinancePayoutType')
              ? ({} as FinancePayoutType)
              : aFinancePayoutType({}, relationshipsToOmit),
          ],
    financeRentInvoices:
      overrides && overrides.hasOwnProperty('financeRentInvoices')
        ? overrides.financeRentInvoices!
        : [
            relationshipsToOmit.has('FinanceMonthlyRentInvoice')
              ? ({} as FinanceMonthlyRentInvoice)
              : aFinanceMonthlyRentInvoice({}, relationshipsToOmit),
          ],
    furniture: overrides && overrides.hasOwnProperty('furniture') ? overrides.furniture! : true,
    home:
      overrides && overrides.hasOwnProperty('home')
        ? overrides.home!
        : relationshipsToOmit.has('Home')
          ? ({} as Home)
          : aHome({}, relationshipsToOmit),
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '96df4bee-6c67-421b-88ea-3b7b3eb3eb16',
    ifInsurancePromotion:
      overrides && overrides.hasOwnProperty('ifInsurancePromotion')
        ? overrides.ifInsurancePromotion!
        : relationshipsToOmit.has('IfInsurancePromotion')
          ? ({} as IfInsurancePromotion)
          : anIfInsurancePromotion({}, relationshipsToOmit),
    inspectionProtocol:
      overrides && overrides.hasOwnProperty('inspectionProtocol')
        ? overrides.inspectionProtocol!
        : relationshipsToOmit.has('Blob')
          ? ({} as Blob)
          : aBlob({}, relationshipsToOmit),
    insurance:
      overrides && overrides.hasOwnProperty('insurance')
        ? overrides.insurance!
        : HomeInsuranceEnum.insurance_landlord,
    insuranceCost: overrides && overrides.hasOwnProperty('insuranceCost') ? overrides.insuranceCost! : 3854,
    insuranceRecord:
      overrides && overrides.hasOwnProperty('insuranceRecord')
        ? overrides.insuranceRecord!
        : relationshipsToOmit.has('Insurance')
          ? ({} as Insurance)
          : anInsurance({}, relationshipsToOmit),
    insuranceResponsibility:
      overrides && overrides.hasOwnProperty('insuranceResponsibility')
        ? overrides.insuranceResponsibility!
        : InsuranceResponsibilityEnum.insurance_responsibility_landlord,
    inventory: overrides && overrides.hasOwnProperty('inventory') ? overrides.inventory! : ['voluptatum'],
    inventoryLists:
      overrides && overrides.hasOwnProperty('inventoryLists')
        ? overrides.inventoryLists!
        : [relationshipsToOmit.has('Blob') ? ({} as Blob) : aBlob({}, relationshipsToOmit)],
    invoicedThrough:
      overrides && overrides.hasOwnProperty('invoicedThrough')
        ? overrides.invoicedThrough!
        : 'sunt corporis officia qui doloribus tempore aut',
    invoices:
      overrides && overrides.hasOwnProperty('invoices')
        ? overrides.invoices!
        : [relationshipsToOmit.has('Invoice') ? ({} as Invoice) : anInvoice({}, relationshipsToOmit)],
    landlord:
      overrides && overrides.hasOwnProperty('landlord')
        ? overrides.landlord!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
    landlordAffiliateCode:
      overrides && overrides.hasOwnProperty('landlordAffiliateCode')
        ? overrides.landlordAffiliateCode!
        : relationshipsToOmit.has('AffiliateCode')
          ? ({} as AffiliateCode)
          : anAffiliateCode({}, relationshipsToOmit),
    landlordBaseFee:
      overrides && overrides.hasOwnProperty('landlordBaseFee') ? overrides.landlordBaseFee! : 9365,
    landlordContactInfo:
      overrides && overrides.hasOwnProperty('landlordContactInfo')
        ? overrides.landlordContactInfo!
        : relationshipsToOmit.has('LandlordContactInfo')
          ? ({} as LandlordContactInfo)
          : aLandlordContactInfo({}, relationshipsToOmit),
    landlordFees:
      overrides && overrides.hasOwnProperty('landlordFees')
        ? overrides.landlordFees!
        : relationshipsToOmit.has('ContractLandlordFees')
          ? ({} as ContractLandlordFees)
          : aContractLandlordFees({}, relationshipsToOmit),
    landlordHasKeys:
      overrides && overrides.hasOwnProperty('landlordHasKeys') ? overrides.landlordHasKeys! : false,
    landlordPostSignDocuments:
      overrides && overrides.hasOwnProperty('landlordPostSignDocuments')
        ? overrides.landlordPostSignDocuments!
        : [relationshipsToOmit.has('Blob') ? ({} as Blob) : aBlob({}, relationshipsToOmit)],
    landlordServices:
      overrides && overrides.hasOwnProperty('landlordServices')
        ? overrides.landlordServices!
        : relationshipsToOmit.has('ContractLandlordServices')
          ? ({} as ContractLandlordServices)
          : aContractLandlordServices({}, relationshipsToOmit),
    language:
      overrides && overrides.hasOwnProperty('language') ? overrides.language! : ContractLanguageEnum.en,
    market:
      overrides && overrides.hasOwnProperty('market')
        ? overrides.market!
        : relationshipsToOmit.has('Market')
          ? ({} as Market)
          : aMarket({}, relationshipsToOmit),
    marketDetails:
      overrides && overrides.hasOwnProperty('marketDetails')
        ? overrides.marketDetails!
        : relationshipsToOmit.has('FinlandMarketDetails')
          ? ({} as FinlandMarketDetails)
          : aFinlandMarketDetails({}, relationshipsToOmit),
    motherContract:
      overrides && overrides.hasOwnProperty('motherContract')
        ? overrides.motherContract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    numberOfTenants:
      overrides && overrides.hasOwnProperty('numberOfTenants') ? overrides.numberOfTenants! : 8013,
    originalFee: overrides && overrides.hasOwnProperty('originalFee') ? overrides.originalFee! : 3207,
    otherLandlordDocuments:
      overrides && overrides.hasOwnProperty('otherLandlordDocuments')
        ? overrides.otherLandlordDocuments!
        : [relationshipsToOmit.has('Blob') ? ({} as Blob) : aBlob({}, relationshipsToOmit)],
    overnightStay: overrides && overrides.hasOwnProperty('overnightStay') ? overrides.overnightStay! : false,
    ownHome: overrides && overrides.hasOwnProperty('ownHome') ? overrides.ownHome! : true,
    paymentDay: overrides && overrides.hasOwnProperty('paymentDay') ? overrides.paymentDay! : 3304,
    paymentInAdvance:
      overrides && overrides.hasOwnProperty('paymentInAdvance') ? overrides.paymentInAdvance! : false,
    payoutDay: overrides && overrides.hasOwnProperty('payoutDay') ? overrides.payoutDay! : 6270,
    payoutInAdvance:
      overrides && overrides.hasOwnProperty('payoutInAdvance') ? overrides.payoutInAdvance! : true,
    pendingTerminationEndDate:
      overrides && overrides.hasOwnProperty('pendingTerminationEndDate')
        ? overrides.pendingTerminationEndDate!
        : 'perspiciatis porro eum fugiat esse ipsum consequuntur',
    pets: overrides && overrides.hasOwnProperty('pets') ? overrides.pets! : true,
    postCleaning:
      overrides && overrides.hasOwnProperty('postCleaning')
        ? overrides.postCleaning!
        : ContractPostCleaningEnum.post_cleaning_landlord,
    preCleaning:
      overrides && overrides.hasOwnProperty('preCleaning')
        ? overrides.preCleaning!
        : ContractPreCleaningEnum.pre_cleaning_landlord,
    pricingModel:
      overrides && overrides.hasOwnProperty('pricingModel')
        ? overrides.pricingModel!
        : relationshipsToOmit.has('PricingModel')
          ? ({} as PricingModel)
          : aPricingModel({}, relationshipsToOmit),
    qasaGuarantee: overrides && overrides.hasOwnProperty('qasaGuarantee') ? overrides.qasaGuarantee! : true,
    qasaGuaranteeCost:
      overrides && overrides.hasOwnProperty('qasaGuaranteeCost') ? overrides.qasaGuaranteeCost! : 5378,
    qasaGuaranteeMonths:
      overrides && overrides.hasOwnProperty('qasaGuaranteeMonths') ? overrides.qasaGuaranteeMonths! : 9344,
    renovations: overrides && overrides.hasOwnProperty('renovations') ? overrides.renovations! : false,
    rent: overrides && overrides.hasOwnProperty('rent') ? overrides.rent! : 2517,
    rentExcludes: overrides && overrides.hasOwnProperty('rentExcludes') ? overrides.rentExcludes! : ['rem'],
    rentIncludes:
      overrides && overrides.hasOwnProperty('rentIncludes') ? overrides.rentIncludes! : ['pariatur'],
    rentalInsuranceMilaRequest:
      overrides && overrides.hasOwnProperty('rentalInsuranceMilaRequest')
        ? overrides.rentalInsuranceMilaRequest!
        : relationshipsToOmit.has('FranceInsuranceRequestType')
          ? ({} as FranceInsuranceRequestType)
          : aFranceInsuranceRequestType({}, relationshipsToOmit),
    rentalType:
      overrides && overrides.hasOwnProperty('rentalType')
        ? overrides.rentalType!
        : ContractRentalTypeEnum.long_term,
    scriveId:
      overrides && overrides.hasOwnProperty('scriveId')
        ? overrides.scriveId!
        : '1747aad4-bc45-4c33-97a6-0979355f9d10',
    signUrl: overrides && overrides.hasOwnProperty('signUrl') ? overrides.signUrl! : 'adipisci',
    signatories:
      overrides && overrides.hasOwnProperty('signatories')
        ? overrides.signatories!
        : [relationshipsToOmit.has('Signatory') ? ({} as Signatory) : aSignatory({}, relationshipsToOmit)],
    signedAt:
      overrides && overrides.hasOwnProperty('signedAt')
        ? overrides.signedAt!
        : 'ab dicta corrupti adipisci laudantium laboriosam voluptate',
    signingProcess:
      overrides && overrides.hasOwnProperty('signingProcess')
        ? overrides.signingProcess!
        : SigningProcessEnum.default,
    smallRepairs:
      overrides && overrides.hasOwnProperty('smallRepairs')
        ? overrides.smallRepairs!
        : ContractSmallRepairsEnum.small_repairs_landlord,
    smoker: overrides && overrides.hasOwnProperty('smoker') ? overrides.smoker! : true,
    status:
      overrides && overrides.hasOwnProperty('status')
        ? overrides.status!
        : ContractStatusEnum.awaiting_insurance_approval,
    subletception: overrides && overrides.hasOwnProperty('subletception') ? overrides.subletception! : false,
    technicalDiagnoses:
      overrides && overrides.hasOwnProperty('technicalDiagnoses')
        ? overrides.technicalDiagnoses!
        : [relationshipsToOmit.has('Blob') ? ({} as Blob) : aBlob({}, relationshipsToOmit)],
    temporaryInstallations:
      overrides && overrides.hasOwnProperty('temporaryInstallations')
        ? overrides.temporaryInstallations!
        : true,
    tenant:
      overrides && overrides.hasOwnProperty('tenant')
        ? overrides.tenant!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
    tenantAffiliateCode:
      overrides && overrides.hasOwnProperty('tenantAffiliateCode')
        ? overrides.tenantAffiliateCode!
        : relationshipsToOmit.has('AffiliateCode')
          ? ({} as AffiliateCode)
          : anAffiliateCode({}, relationshipsToOmit),
    tenantBaseFee: overrides && overrides.hasOwnProperty('tenantBaseFee') ? overrides.tenantBaseFee! : 5993,
    tenantContactInfo:
      overrides && overrides.hasOwnProperty('tenantContactInfo')
        ? overrides.tenantContactInfo!
        : relationshipsToOmit.has('TenantContactInfo')
          ? ({} as TenantContactInfo)
          : aTenantContactInfo({}, relationshipsToOmit),
    tenantFees:
      overrides && overrides.hasOwnProperty('tenantFees')
        ? overrides.tenantFees!
        : relationshipsToOmit.has('ContractTenantFees')
          ? ({} as ContractTenantFees)
          : aContractTenantFees({}, relationshipsToOmit),
    tenantInvitations:
      overrides && overrides.hasOwnProperty('tenantInvitations')
        ? overrides.tenantInvitations!
        : [
            relationshipsToOmit.has('ContractTenantInvitation')
              ? ({} as ContractTenantInvitation)
              : aContractTenantInvitation({}, relationshipsToOmit),
          ],
    tenantPostSignDocuments:
      overrides && overrides.hasOwnProperty('tenantPostSignDocuments')
        ? overrides.tenantPostSignDocuments!
        : [relationshipsToOmit.has('Blob') ? ({} as Blob) : aBlob({}, relationshipsToOmit)],
    tenantServices:
      overrides && overrides.hasOwnProperty('tenantServices')
        ? overrides.tenantServices!
        : relationshipsToOmit.has('ContractTenantServices')
          ? ({} as ContractTenantServices)
          : aContractTenantServices({}, relationshipsToOmit),
    tenureType:
      overrides && overrides.hasOwnProperty('tenureType')
        ? overrides.tenureType!
        : TenureTypeEnum.condominium,
    terminationNotice:
      overrides && overrides.hasOwnProperty('terminationNotice') ? overrides.terminationNotice! : true,
    termsUrl: overrides && overrides.hasOwnProperty('termsUrl') ? overrides.termsUrl! : 'dolorem',
    type: overrides && overrides.hasOwnProperty('type') ? overrides.type! : ContractTypeEnum.extension,
    updatedAt:
      overrides && overrides.hasOwnProperty('updatedAt')
        ? overrides.updatedAt!
        : 'nihil esse unde cupiditate nulla deserunt omnis',
    upload:
      overrides && overrides.hasOwnProperty('upload')
        ? overrides.upload!
        : relationshipsToOmit.has('Upload')
          ? ({} as Upload)
          : anUpload({}, relationshipsToOmit),
    user:
      overrides && overrides.hasOwnProperty('user')
        ? overrides.user!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
  }
}

export const aTenantAd = (
  overrides?: Partial<TenantAd>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'TenantAd' } & TenantAd => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('TenantAd')
  return {
    __typename: 'TenantAd',
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'et et numquam facere ut soluta cum',
    familyMembers:
      overrides && overrides.hasOwnProperty('familyMembers')
        ? overrides.familyMembers!
        : [
            relationshipsToOmit.has('FamilyMember')
              ? ({} as FamilyMember)
              : aFamilyMember({}, relationshipsToOmit),
          ],
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'bcbef9c0-e2f9-4c9d-9632-d7ea87436627',
    publishedUntil:
      overrides && overrides.hasOwnProperty('publishedUntil')
        ? overrides.publishedUntil!
        : 'consequatur qui dolores in fuga expedita autem',
    score: overrides && overrides.hasOwnProperty('score') ? overrides.score! : 9327,
    searchPreference:
      overrides && overrides.hasOwnProperty('searchPreference')
        ? overrides.searchPreference!
        : relationshipsToOmit.has('SearchPreference')
          ? ({} as SearchPreference)
          : aSearchPreference({}, relationshipsToOmit),
    status: overrides && overrides.hasOwnProperty('status') ? overrides.status! : TenantAdStatusEnum.expired,
    updatedAt:
      overrides && overrides.hasOwnProperty('updatedAt')
        ? overrides.updatedAt!
        : 'ratione et voluptatem delectus sint rerum est',
    user:
      overrides && overrides.hasOwnProperty('user')
        ? overrides.user!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
  }
}

export const aTenantAdInput = (
  overrides?: Partial<TenantAdInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): TenantAdInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('TenantAdInput')
  return {
    familyMembers:
      overrides && overrides.hasOwnProperty('familyMembers')
        ? overrides.familyMembers!
        : [
            relationshipsToOmit.has('FamilyMemberInput')
              ? ({} as FamilyMemberInput)
              : aFamilyMemberInput({}, relationshipsToOmit),
          ],
    searchPreference:
      overrides && overrides.hasOwnProperty('searchPreference')
        ? overrides.searchPreference!
        : relationshipsToOmit.has('SearchPreferenceInput')
          ? ({} as SearchPreferenceInput)
          : aSearchPreferenceInput({}, relationshipsToOmit),
  }
}

export const aTenantAdOffsetLimit = (
  overrides?: Partial<TenantAdOffsetLimit>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'TenantAdOffsetLimit' } & TenantAdOffsetLimit => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('TenantAdOffsetLimit')
  return {
    __typename: 'TenantAdOffsetLimit',
    hasNextPage: overrides && overrides.hasOwnProperty('hasNextPage') ? overrides.hasNextPage! : true,
    hasPreviousPage:
      overrides && overrides.hasOwnProperty('hasPreviousPage') ? overrides.hasPreviousPage! : true,
    nodes:
      overrides && overrides.hasOwnProperty('nodes')
        ? overrides.nodes!
        : [relationshipsToOmit.has('TenantAd') ? ({} as TenantAd) : aTenantAd({}, relationshipsToOmit)],
    pagesCount: overrides && overrides.hasOwnProperty('pagesCount') ? overrides.pagesCount! : 5781,
    totalCount: overrides && overrides.hasOwnProperty('totalCount') ? overrides.totalCount! : 2359,
  }
}

export const aTenantContactInfo = (
  overrides?: Partial<TenantContactInfo>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'TenantContactInfo' } & TenantContactInfo => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('TenantContactInfo')
  return {
    __typename: 'TenantContactInfo',
    familyName: overrides && overrides.hasOwnProperty('familyName') ? overrides.familyName! : 'qui',
  }
}

export const aTenantInput = (
  overrides?: Partial<TenantInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): TenantInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('TenantInput')
  return {
    email: overrides && overrides.hasOwnProperty('email') ? overrides.email! : 'ut',
    familyName: overrides && overrides.hasOwnProperty('familyName') ? overrides.familyName! : 'ducimus',
    firstName: overrides && overrides.hasOwnProperty('firstName') ? overrides.firstName! : 'earum',
    phoneNumber: overrides && overrides.hasOwnProperty('phoneNumber') ? overrides.phoneNumber! : 'quam',
  }
}

export const aTenantProfileCompleteness = (
  overrides?: Partial<TenantProfileCompleteness>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'TenantProfileCompleteness' } & TenantProfileCompleteness => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('TenantProfileCompleteness')
  return {
    __typename: 'TenantProfileCompleteness',
    completenessScore:
      overrides && overrides.hasOwnProperty('completenessScore') ? overrides.completenessScore! : 584,
    tenantProfileFields:
      overrides && overrides.hasOwnProperty('tenantProfileFields')
        ? overrides.tenantProfileFields!
        : relationshipsToOmit.has('TenantProfileFields')
          ? ({} as TenantProfileFields)
          : aTenantProfileFields({}, relationshipsToOmit),
  }
}

export const aTenantProfileField = (
  overrides?: Partial<TenantProfileField>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'TenantProfileField' } & TenantProfileField => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('TenantProfileField')
  return {
    __typename: 'TenantProfileField',
    completed: overrides && overrides.hasOwnProperty('completed') ? overrides.completed! : false,
    weight: overrides && overrides.hasOwnProperty('weight') ? overrides.weight! : 7514,
  }
}

export const aTenantProfileFields = (
  overrides?: Partial<TenantProfileFields>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'TenantProfileFields' } & TenantProfileFields => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('TenantProfileFields')
  return {
    __typename: 'TenantProfileFields',
    bio:
      overrides && overrides.hasOwnProperty('bio')
        ? overrides.bio!
        : relationshipsToOmit.has('TenantProfileField')
          ? ({} as TenantProfileField)
          : aTenantProfileField({}, relationshipsToOmit),
    bioLived:
      overrides && overrides.hasOwnProperty('bioLived')
        ? overrides.bioLived!
        : relationshipsToOmit.has('TenantProfileField')
          ? ({} as TenantProfileField)
          : aTenantProfileField({}, relationshipsToOmit),
    bioPets:
      overrides && overrides.hasOwnProperty('bioPets')
        ? overrides.bioPets!
        : relationshipsToOmit.has('TenantProfileField')
          ? ({} as TenantProfileField)
          : aTenantProfileField({}, relationshipsToOmit),
    bioSpare:
      overrides && overrides.hasOwnProperty('bioSpare')
        ? overrides.bioSpare!
        : relationshipsToOmit.has('TenantProfileField')
          ? ({} as TenantProfileField)
          : aTenantProfileField({}, relationshipsToOmit),
    bioStudy:
      overrides && overrides.hasOwnProperty('bioStudy')
        ? overrides.bioStudy!
        : relationshipsToOmit.has('TenantProfileField')
          ? ({} as TenantProfileField)
          : aTenantProfileField({}, relationshipsToOmit),
    bioTitle:
      overrides && overrides.hasOwnProperty('bioTitle')
        ? overrides.bioTitle!
        : relationshipsToOmit.has('TenantProfileField')
          ? ({} as TenantProfileField)
          : aTenantProfileField({}, relationshipsToOmit),
    bioWork:
      overrides && overrides.hasOwnProperty('bioWork')
        ? overrides.bioWork!
        : relationshipsToOmit.has('TenantProfileField')
          ? ({} as TenantProfileField)
          : aTenantProfileField({}, relationshipsToOmit),
    certificate:
      overrides && overrides.hasOwnProperty('certificate')
        ? overrides.certificate!
        : relationshipsToOmit.has('TenantProfileField')
          ? ({} as TenantProfileField)
          : aTenantProfileField({}, relationshipsToOmit),
    currentAgreementType:
      overrides && overrides.hasOwnProperty('currentAgreementType')
        ? overrides.currentAgreementType!
        : relationshipsToOmit.has('TenantProfileField')
          ? ({} as TenantProfileField)
          : aTenantProfileField({}, relationshipsToOmit),
    emailConfirmed:
      overrides && overrides.hasOwnProperty('emailConfirmed')
        ? overrides.emailConfirmed!
        : relationshipsToOmit.has('TenantProfileField')
          ? ({} as TenantProfileField)
          : aTenantProfileField({}, relationshipsToOmit),
    firstName:
      overrides && overrides.hasOwnProperty('firstName')
        ? overrides.firstName!
        : relationshipsToOmit.has('TenantProfileField')
          ? ({} as TenantProfileField)
          : aTenantProfileField({}, relationshipsToOmit),
    idNumberConfirmed:
      overrides && overrides.hasOwnProperty('idNumberConfirmed')
        ? overrides.idNumberConfirmed!
        : relationshipsToOmit.has('TenantProfileField')
          ? ({} as TenantProfileField)
          : aTenantProfileField({}, relationshipsToOmit),
    matchingProfile:
      overrides && overrides.hasOwnProperty('matchingProfile')
        ? overrides.matchingProfile!
        : relationshipsToOmit.has('TenantProfileField')
          ? ({} as TenantProfileField)
          : aTenantProfileField({}, relationshipsToOmit),
    movingReason:
      overrides && overrides.hasOwnProperty('movingReason')
        ? overrides.movingReason!
        : relationshipsToOmit.has('TenantProfileField')
          ? ({} as TenantProfileField)
          : aTenantProfileField({}, relationshipsToOmit),
    occupations:
      overrides && overrides.hasOwnProperty('occupations')
        ? overrides.occupations!
        : relationshipsToOmit.has('TenantProfileField')
          ? ({} as TenantProfileField)
          : aTenantProfileField({}, relationshipsToOmit),
    profilePicture:
      overrides && overrides.hasOwnProperty('profilePicture')
        ? overrides.profilePicture!
        : relationshipsToOmit.has('TenantProfileField')
          ? ({} as TenantProfileField)
          : aTenantProfileField({}, relationshipsToOmit),
    referenceCheck:
      overrides && overrides.hasOwnProperty('referenceCheck')
        ? overrides.referenceCheck!
        : relationshipsToOmit.has('TenantProfileField')
          ? ({} as TenantProfileField)
          : aTenantProfileField({}, relationshipsToOmit),
  }
}

export const aTerminateContractPayload = (
  overrides?: Partial<TerminateContractPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'TerminateContractPayload' } & TerminateContractPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('TerminateContractPayload')
  return {
    __typename: 'TerminateContractPayload',
    contract:
      overrides && overrides.hasOwnProperty('contract')
        ? overrides.contract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [
            relationshipsToOmit.has('TerminateContractValidation')
              ? ({} as TerminateContractValidation)
              : aTerminateContractValidation({}, relationshipsToOmit),
          ],
  }
}

export const aTerminateContractValidation = (
  overrides?: Partial<TerminateContractValidation>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'TerminateContractValidation' } & TerminateContractValidation => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('TerminateContractValidation')
  return {
    __typename: 'TerminateContractValidation',
    codes:
      overrides && overrides.hasOwnProperty('codes')
        ? overrides.codes!
        : [TerminateContractValidationCodeEnum.end_date_too_early],
    codesWithMessage:
      overrides && overrides.hasOwnProperty('codesWithMessage')
        ? overrides.codesWithMessage!
        : [
            relationshipsToOmit.has('CodesWithMessage')
              ? ({} as CodesWithMessage)
              : aCodesWithMessage({}, relationshipsToOmit),
          ],
    field: overrides && overrides.hasOwnProperty('field') ? overrides.field! : 'et',
  }
}

export const aTestErrorsInput = (
  overrides?: Partial<TestErrorsInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): TestErrorsInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('TestErrorsInput')
  return {
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '3e54b69c-db0e-477f-87d2-3bb6a22071b6',
    magicNumber: overrides && overrides.hasOwnProperty('magicNumber') ? overrides.magicNumber! : 17,
  }
}

export const aTinkTransactionGroupType = (
  overrides?: Partial<TinkTransactionGroupType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'TinkTransactionGroupType' } & TinkTransactionGroupType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('TinkTransactionGroupType')
  return {
    __typename: 'TinkTransactionGroupType',
    month: overrides && overrides.hasOwnProperty('month') ? overrides.month! : MonthEnum.april,
    transactions:
      overrides && overrides.hasOwnProperty('transactions')
        ? overrides.transactions!
        : [
            relationshipsToOmit.has('TinkTransactionType')
              ? ({} as TinkTransactionType)
              : aTinkTransactionType({}, relationshipsToOmit),
          ],
  }
}

export const aTinkTransactionType = (
  overrides?: Partial<TinkTransactionType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'TinkTransactionType' } & TinkTransactionType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('TinkTransactionType')
  return {
    __typename: 'TinkTransactionType',
    amount:
      overrides && overrides.hasOwnProperty('amount')
        ? overrides.amount!
        : relationshipsToOmit.has('Money')
          ? ({} as Money)
          : aMoney({}, relationshipsToOmit),
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'cf551178-f69e-4aa0-a569-d5ef188a8488',
    time:
      overrides && overrides.hasOwnProperty('time')
        ? overrides.time!
        : 'cum minima assumenda aliquam explicabo natus doloribus',
    type: overrides && overrides.hasOwnProperty('type') ? overrides.type! : 'quidem',
  }
}

export const aTrait = (
  overrides?: Partial<Trait>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Trait' } & Trait => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Trait')
  return {
    __typename: 'Trait',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'ec55cdbf-a8de-486a-bb1f-cf58a3e272d1',
    type: overrides && overrides.hasOwnProperty('type') ? overrides.type! : TraitTypeEnum.archipelago,
  }
}

export const aTransitionBookingOfferInput = (
  overrides?: Partial<TransitionBookingOfferInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): TransitionBookingOfferInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('TransitionBookingOfferInput')
  return {
    bookingOfferId:
      overrides && overrides.hasOwnProperty('bookingOfferId')
        ? overrides.bookingOfferId!
        : '63e3bf34-0d6c-4c1f-90ee-2ebd5727254b',
    toState:
      overrides && overrides.hasOwnProperty('toState') ? overrides.toState! : BookingOfferStatesEnum.accepted,
  }
}

export const aTransitionBookingOfferPayload = (
  overrides?: Partial<TransitionBookingOfferPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'TransitionBookingOfferPayload' } & TransitionBookingOfferPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('TransitionBookingOfferPayload')
  return {
    __typename: 'TransitionBookingOfferPayload',
    bookingOffer:
      overrides && overrides.hasOwnProperty('bookingOffer')
        ? overrides.bookingOffer!
        : relationshipsToOmit.has('BookingOffer')
          ? ({} as BookingOffer)
          : aBookingOffer({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const aTransitionShortcutInput = (
  overrides?: Partial<TransitionShortcutInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): TransitionShortcutInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('TransitionShortcutInput')
  return {
    reason:
      overrides && overrides.hasOwnProperty('reason')
        ? overrides.reason!
        : ShortcutRejectionReasonEnum.NOT_INTERESTED_WILL_PUBLISH_THEMSELVES,
    shortcutId:
      overrides && overrides.hasOwnProperty('shortcutId')
        ? overrides.shortcutId!
        : 'e92f010b-b854-4a2d-b57a-41534752ab97',
    toState:
      overrides && overrides.hasOwnProperty('toState') ? overrides.toState! : ShortcutStatesEnum.aborted,
  }
}

export const aTransitionShortcutPayload = (
  overrides?: Partial<TransitionShortcutPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'TransitionShortcutPayload' } & TransitionShortcutPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('TransitionShortcutPayload')
  return {
    __typename: 'TransitionShortcutPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    shortcut:
      overrides && overrides.hasOwnProperty('shortcut')
        ? overrides.shortcut!
        : relationshipsToOmit.has('Shortcut')
          ? ({} as Shortcut)
          : aShortcut({}, relationshipsToOmit),
  }
}

export const aTranslatedDescription = (
  overrides?: Partial<TranslatedDescription>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'TranslatedDescription' } & TranslatedDescription => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('TranslatedDescription')
  return {
    __typename: 'TranslatedDescription',
    description: overrides && overrides.hasOwnProperty('description') ? overrides.description! : 'eum',
    language: overrides && overrides.hasOwnProperty('language') ? overrides.language! : 'unde',
  }
}

export const aTranslatedDescriptionInput = (
  overrides?: Partial<TranslatedDescriptionInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): TranslatedDescriptionInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('TranslatedDescriptionInput')
  return {
    description: overrides && overrides.hasOwnProperty('description') ? overrides.description! : 'ipsam',
    language: overrides && overrides.hasOwnProperty('language') ? overrides.language! : 'blanditiis',
  }
}

export const anUnarchiveConversationPayload = (
  overrides?: Partial<UnarchiveConversationPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UnarchiveConversationPayload' } & UnarchiveConversationPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UnarchiveConversationPayload')
  return {
    __typename: 'UnarchiveConversationPayload',
    conversation:
      overrides && overrides.hasOwnProperty('conversation')
        ? overrides.conversation!
        : relationshipsToOmit.has('Conversation')
          ? ({} as Conversation)
          : aConversation({}, relationshipsToOmit),
  }
}

export const anUnmarkFavoriteHomePayload = (
  overrides?: Partial<UnmarkFavoriteHomePayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UnmarkFavoriteHomePayload' } & UnmarkFavoriteHomePayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UnmarkFavoriteHomePayload')
  return {
    __typename: 'UnmarkFavoriteHomePayload',
    home:
      overrides && overrides.hasOwnProperty('home')
        ? overrides.home!
        : relationshipsToOmit.has('Home')
          ? ({} as Home)
          : aHome({}, relationshipsToOmit),
  }
}

export const anUnpublishTenantAdPayload = (
  overrides?: Partial<UnpublishTenantAdPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UnpublishTenantAdPayload' } & UnpublishTenantAdPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UnpublishTenantAdPayload')
  return {
    __typename: 'UnpublishTenantAdPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    tenantAd:
      overrides && overrides.hasOwnProperty('tenantAd')
        ? overrides.tenantAd!
        : relationshipsToOmit.has('TenantAd')
          ? ({} as TenantAd)
          : aTenantAd({}, relationshipsToOmit),
  }
}

export const anUnreadConversationsCount = (
  overrides?: Partial<UnreadConversationsCount>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UnreadConversationsCount' } & UnreadConversationsCount => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UnreadConversationsCount')
  return {
    __typename: 'UnreadConversationsCount',
    count: overrides && overrides.hasOwnProperty('count') ? overrides.count! : 5069,
  }
}

export const anUpcomingPayoutType = (
  overrides?: Partial<UpcomingPayoutType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpcomingPayoutType' } & UpcomingPayoutType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpcomingPayoutType')
  return {
    __typename: 'UpcomingPayoutType',
    charges:
      overrides && overrides.hasOwnProperty('charges')
        ? overrides.charges!
        : [
            relationshipsToOmit.has('FinanceChargeType')
              ? ({} as FinanceChargeType)
              : aFinanceChargeType({}, relationshipsToOmit),
          ],
    dueDate:
      overrides && overrides.hasOwnProperty('dueDate')
        ? overrides.dueDate!
        : 'libero ut labore voluptatum enim et animi',
    periodEnd:
      overrides && overrides.hasOwnProperty('periodEnd')
        ? overrides.periodEnd!
        : 'consequatur quos dolores est nihil atque pariatur',
    periodStart:
      overrides && overrides.hasOwnProperty('periodStart')
        ? overrides.periodStart!
        : 'nisi est ut est culpa repudiandae amet',
    sumAmount:
      overrides && overrides.hasOwnProperty('sumAmount')
        ? overrides.sumAmount!
        : relationshipsToOmit.has('Money')
          ? ({} as Money)
          : aMoney({}, relationshipsToOmit),
  }
}

export const anUpdateApplicationBoardListOrderPayload = (
  overrides?: Partial<UpdateApplicationBoardListOrderPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpdateApplicationBoardListOrderPayload' } & UpdateApplicationBoardListOrderPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpdateApplicationBoardListOrderPayload')
  return {
    __typename: 'UpdateApplicationBoardListOrderPayload',
    success: overrides && overrides.hasOwnProperty('success') ? overrides.success! : false,
  }
}

export const anUpdateApplicationBoardListPayload = (
  overrides?: Partial<UpdateApplicationBoardListPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpdateApplicationBoardListPayload' } & UpdateApplicationBoardListPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpdateApplicationBoardListPayload')
  return {
    __typename: 'UpdateApplicationBoardListPayload',
    applicationBoardList:
      overrides && overrides.hasOwnProperty('applicationBoardList')
        ? overrides.applicationBoardList!
        : relationshipsToOmit.has('ApplicationBoardList')
          ? ({} as ApplicationBoardList)
          : anApplicationBoardList({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const anUpdateContractScriveIdPayload = (
  overrides?: Partial<UpdateContractScriveIdPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpdateContractScriveIdPayload' } & UpdateContractScriveIdPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpdateContractScriveIdPayload')
  return {
    __typename: 'UpdateContractScriveIdPayload',
    contract:
      overrides && overrides.hasOwnProperty('contract')
        ? overrides.contract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const anUpdateContractWaterFeePayload = (
  overrides?: Partial<UpdateContractWaterFeePayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpdateContractWaterFeePayload' } & UpdateContractWaterFeePayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpdateContractWaterFeePayload')
  return {
    __typename: 'UpdateContractWaterFeePayload',
    contract:
      overrides && overrides.hasOwnProperty('contract')
        ? overrides.contract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const anUpdateHomeApplicationPositionPayload = (
  overrides?: Partial<UpdateHomeApplicationPositionPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpdateHomeApplicationPositionPayload' } & UpdateHomeApplicationPositionPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpdateHomeApplicationPositionPayload')
  return {
    __typename: 'UpdateHomeApplicationPositionPayload',
    homeApplication:
      overrides && overrides.hasOwnProperty('homeApplication')
        ? overrides.homeApplication!
        : relationshipsToOmit.has('HomeApplication')
          ? ({} as HomeApplication)
          : aHomeApplication({}, relationshipsToOmit),
  }
}

export const anUpdateHomeInput = (
  overrides?: Partial<UpdateHomeInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): UpdateHomeInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpdateHomeInput')
  return {
    apartmentNumber:
      overrides && overrides.hasOwnProperty('apartmentNumber') ? overrides.apartmentNumber! : 'hic',
    applicationDeadline:
      overrides && overrides.hasOwnProperty('applicationDeadline')
        ? overrides.applicationDeadline!
        : 'beatae ipsa facere sit rerum animi molestiae',
    bathroomRenovationYear:
      overrides && overrides.hasOwnProperty('bathroomRenovationYear')
        ? overrides.bathroomRenovationYear!
        : 2868,
    bedCount: overrides && overrides.hasOwnProperty('bedCount') ? overrides.bedCount! : 6173,
    bedroomCount: overrides && overrides.hasOwnProperty('bedroomCount') ? overrides.bedroomCount! : 2169,
    buildYear: overrides && overrides.hasOwnProperty('buildYear') ? overrides.buildYear! : 8314,
    buildingFloors:
      overrides && overrides.hasOwnProperty('buildingFloors') ? overrides.buildingFloors! : 9546,
    cancellationPolicyName:
      overrides && overrides.hasOwnProperty('cancellationPolicyName')
        ? overrides.cancellationPolicyName!
        : CancellationPolicyNameEnum.flexible,
    cleaningFee: overrides && overrides.hasOwnProperty('cleaningFee') ? overrides.cleaningFee! : 7914,
    condition:
      overrides && overrides.hasOwnProperty('condition') ? overrides.condition! : HomeConditionEnum.good,
    contractTemplateAttributes:
      overrides && overrides.hasOwnProperty('contractTemplateAttributes')
        ? overrides.contractTemplateAttributes!
        : relationshipsToOmit.has('ContractTemplateInput')
          ? ({} as ContractTemplateInput)
          : aContractTemplateInput({}, relationshipsToOmit),
    corporateHome: overrides && overrides.hasOwnProperty('corporateHome') ? overrides.corporateHome! : false,
    description: overrides && overrides.hasOwnProperty('description') ? overrides.description! : 'totam',
    descriptionBuilding:
      overrides && overrides.hasOwnProperty('descriptionBuilding') ? overrides.descriptionBuilding! : 'odit',
    descriptionContract:
      overrides && overrides.hasOwnProperty('descriptionContract') ? overrides.descriptionContract! : 'animi',
    descriptionTransportation:
      overrides && overrides.hasOwnProperty('descriptionTransportation')
        ? overrides.descriptionTransportation!
        : 'sed',
    duration:
      overrides && overrides.hasOwnProperty('duration')
        ? overrides.duration!
        : relationshipsToOmit.has('CreateDurationInput')
          ? ({} as CreateDurationInput)
          : aCreateDurationInput({}, relationshipsToOmit),
    electricityFee:
      overrides && overrides.hasOwnProperty('electricityFee')
        ? overrides.electricityFee!
        : relationshipsToOmit.has('ElectricityFeeInput')
          ? ({} as ElectricityFeeInput)
          : anElectricityFeeInput({}, relationshipsToOmit),
    energyClass: overrides && overrides.hasOwnProperty('energyClass') ? overrides.energyClass! : 'esse',
    external: overrides && overrides.hasOwnProperty('external') ? overrides.external! : true,
    externalApplicationUrl:
      overrides && overrides.hasOwnProperty('externalApplicationUrl')
        ? overrides.externalApplicationUrl!
        : 'dolor',
    externalEmail:
      overrides && overrides.hasOwnProperty('externalEmail') ? overrides.externalEmail! : 'temporibus',
    externalInfoPage:
      overrides && overrides.hasOwnProperty('externalInfoPage') ? overrides.externalInfoPage! : 'at',
    extraCosts:
      overrides && overrides.hasOwnProperty('extraCosts')
        ? overrides.extraCosts!
        : [
            relationshipsToOmit.has('ExtraCostInput')
              ? ({} as ExtraCostInput)
              : anExtraCostInput({}, relationshipsToOmit),
          ],
    firsthand: overrides && overrides.hasOwnProperty('firsthand') ? overrides.firsthand! : false,
    floor: overrides && overrides.hasOwnProperty('floor') ? overrides.floor! : 7516,
    hasKitchen: overrides && overrides.hasOwnProperty('hasKitchen') ? overrides.hasKitchen! : false,
    houseRules: overrides && overrides.hasOwnProperty('houseRules') ? overrides.houseRules! : 'cupiditate',
    housingAssociation:
      overrides && overrides.hasOwnProperty('housingAssociation') ? overrides.housingAssociation! : 'sed',
    instantSignSettingAttributes:
      overrides && overrides.hasOwnProperty('instantSignSettingAttributes')
        ? overrides.instantSignSettingAttributes!
        : relationshipsToOmit.has('InstantSignSettingInput')
          ? ({} as InstantSignSettingInput)
          : anInstantSignSettingInput({}, relationshipsToOmit),
    insurance:
      overrides && overrides.hasOwnProperty('insurance')
        ? overrides.insurance!
        : HomeInsuranceEnum.insurance_landlord,
    kitchenRenovationYear:
      overrides && overrides.hasOwnProperty('kitchenRenovationYear')
        ? overrides.kitchenRenovationYear!
        : 8710,
    location:
      overrides && overrides.hasOwnProperty('location')
        ? overrides.location!
        : relationshipsToOmit.has('CreateLocationInput')
          ? ({} as CreateLocationInput)
          : aCreateLocationInput({}, relationshipsToOmit),
    matterportEmbedUrl:
      overrides && overrides.hasOwnProperty('matterportEmbedUrl') ? overrides.matterportEmbedUrl! : 'hic',
    maxRent: overrides && overrides.hasOwnProperty('maxRent') ? overrides.maxRent! : 1934,
    maxRoomCount: overrides && overrides.hasOwnProperty('maxRoomCount') ? overrides.maxRoomCount! : 3.8,
    maxSquareMeters:
      overrides && overrides.hasOwnProperty('maxSquareMeters') ? overrides.maxSquareMeters! : 1832,
    maxTenantCount:
      overrides && overrides.hasOwnProperty('maxTenantCount') ? overrides.maxTenantCount! : 8879,
    minRent: overrides && overrides.hasOwnProperty('minRent') ? overrides.minRent! : 4693,
    minRoomCount: overrides && overrides.hasOwnProperty('minRoomCount') ? overrides.minRoomCount! : 8.35,
    minSquareMeters:
      overrides && overrides.hasOwnProperty('minSquareMeters') ? overrides.minSquareMeters! : 2733,
    minTenantCount:
      overrides && overrides.hasOwnProperty('minTenantCount') ? overrides.minTenantCount! : 1335,
    numberOfHomes: overrides && overrides.hasOwnProperty('numberOfHomes') ? overrides.numberOfHomes! : 5810,
    origin: overrides && overrides.hasOwnProperty('origin') ? overrides.origin! : 'nihil',
    ownHome: overrides && overrides.hasOwnProperty('ownHome') ? overrides.ownHome! : false,
    qasaGuarantee: overrides && overrides.hasOwnProperty('qasaGuarantee') ? overrides.qasaGuarantee! : false,
    rent: overrides && overrides.hasOwnProperty('rent') ? overrides.rent! : 751,
    rentalType:
      overrides && overrides.hasOwnProperty('rentalType')
        ? overrides.rentalType!
        : HomeRentalTypeEnum.long_term,
    responsibleForCleaning:
      overrides && overrides.hasOwnProperty('responsibleForCleaning')
        ? overrides.responsibleForCleaning!
        : ResponsibleForCleaningEnum.landlord,
    roomCount: overrides && overrides.hasOwnProperty('roomCount') ? overrides.roomCount! : 8.66,
    seniorHome: overrides && overrides.hasOwnProperty('seniorHome') ? overrides.seniorHome! : true,
    shared: overrides && overrides.hasOwnProperty('shared') ? overrides.shared! : true,
    squareMeters: overrides && overrides.hasOwnProperty('squareMeters') ? overrides.squareMeters! : 4316,
    studentHome: overrides && overrides.hasOwnProperty('studentHome') ? overrides.studentHome! : false,
    tenantCount: overrides && overrides.hasOwnProperty('tenantCount') ? overrides.tenantCount! : 3450,
    tenureType:
      overrides && overrides.hasOwnProperty('tenureType')
        ? overrides.tenureType!
        : TenureTypeEnum.condominium,
    title: overrides && overrides.hasOwnProperty('title') ? overrides.title! : 'quia',
    toiletCount: overrides && overrides.hasOwnProperty('toiletCount') ? overrides.toiletCount! : 9041,
    traits: overrides && overrides.hasOwnProperty('traits') ? overrides.traits! : [TraitTypeEnum.archipelago],
    translatedDescriptions:
      overrides && overrides.hasOwnProperty('translatedDescriptions')
        ? overrides.translatedDescriptions!
        : [
            relationshipsToOmit.has('TranslatedDescriptionInput')
              ? ({} as TranslatedDescriptionInput)
              : aTranslatedDescriptionInput({}, relationshipsToOmit),
          ],
    type: overrides && overrides.hasOwnProperty('type') ? overrides.type! : HomeTypeEnum.apartment,
    waterFeePerTenant:
      overrides && overrides.hasOwnProperty('waterFeePerTenant') ? overrides.waterFeePerTenant! : 4278,
  }
}

export const anUpdateHomePayload = (
  overrides?: Partial<UpdateHomePayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpdateHomePayload' } & UpdateHomePayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpdateHomePayload')
  return {
    __typename: 'UpdateHomePayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    home:
      overrides && overrides.hasOwnProperty('home')
        ? overrides.home!
        : relationshipsToOmit.has('Home')
          ? ({} as Home)
          : aHome({}, relationshipsToOmit),
  }
}

export const anUpdateInstantSignContractInput = (
  overrides?: Partial<UpdateInstantSignContractInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): UpdateInstantSignContractInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpdateInstantSignContractInput')
  return {
    durationAttributes:
      overrides && overrides.hasOwnProperty('durationAttributes')
        ? overrides.durationAttributes!
        : relationshipsToOmit.has('InstantSignDurationInput')
          ? ({} as InstantSignDurationInput)
          : anInstantSignDurationInput({}, relationshipsToOmit),
  }
}

export const anUpdateInstantSignContractPayload = (
  overrides?: Partial<UpdateInstantSignContractPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpdateInstantSignContractPayload' } & UpdateInstantSignContractPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpdateInstantSignContractPayload')
  return {
    __typename: 'UpdateInstantSignContractPayload',
    contract:
      overrides && overrides.hasOwnProperty('contract')
        ? overrides.contract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const anUpdateLandlordPostSignDocumentsInput = (
  overrides?: Partial<UpdateLandlordPostSignDocumentsInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): UpdateLandlordPostSignDocumentsInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpdateLandlordPostSignDocumentsInput')
  return {
    landlordPostSignDocuments:
      overrides && overrides.hasOwnProperty('landlordPostSignDocuments')
        ? overrides.landlordPostSignDocuments!
        : ['42f105e2-d46b-479a-a88f-739b6d6f9bcd'],
  }
}

export const anUpdateLandlordPostSignDocumentsPayload = (
  overrides?: Partial<UpdateLandlordPostSignDocumentsPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpdateLandlordPostSignDocumentsPayload' } & UpdateLandlordPostSignDocumentsPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpdateLandlordPostSignDocumentsPayload')
  return {
    __typename: 'UpdateLandlordPostSignDocumentsPayload',
    contract:
      overrides && overrides.hasOwnProperty('contract')
        ? overrides.contract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const anUpdateMarketSettingsInput = (
  overrides?: Partial<UpdateMarketSettingsInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): UpdateMarketSettingsInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpdateMarketSettingsInput')
  return {
    shortcutEnlistNewHomes:
      overrides && overrides.hasOwnProperty('shortcutEnlistNewHomes')
        ? overrides.shortcutEnlistNewHomes!
        : true,
    shortcutMaxPendingListings:
      overrides && overrides.hasOwnProperty('shortcutMaxPendingListings')
        ? overrides.shortcutMaxPendingListings!
        : 5890,
    shortcutStartRange:
      overrides && overrides.hasOwnProperty('shortcutStartRange')
        ? overrides.shortcutStartRange!
        : relationshipsToOmit.has('DateRangeInput')
          ? ({} as DateRangeInput)
          : aDateRangeInput({}, relationshipsToOmit),
  }
}

export const anUpdateMarketSettingsPayload = (
  overrides?: Partial<UpdateMarketSettingsPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpdateMarketSettingsPayload' } & UpdateMarketSettingsPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpdateMarketSettingsPayload')
  return {
    __typename: 'UpdateMarketSettingsPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    marketSettings:
      overrides && overrides.hasOwnProperty('marketSettings')
        ? overrides.marketSettings!
        : relationshipsToOmit.has('MarketSettings')
          ? ({} as MarketSettings)
          : aMarketSettings({}, relationshipsToOmit),
  }
}

export const anUpdateSavedSearchInput = (
  overrides?: Partial<UpdateSavedSearchInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): UpdateSavedSearchInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpdateSavedSearchInput')
  return {
    monitoring: overrides && overrides.hasOwnProperty('monitoring') ? overrides.monitoring! : true,
    searchParams:
      overrides && overrides.hasOwnProperty('searchParams')
        ? overrides.searchParams!
        : relationshipsToOmit.has('HomeSearchParamsInput')
          ? ({} as HomeSearchParamsInput)
          : aHomeSearchParamsInput({}, relationshipsToOmit),
    title: overrides && overrides.hasOwnProperty('title') ? overrides.title! : 'culpa',
    visibility:
      overrides && overrides.hasOwnProperty('visibility')
        ? overrides.visibility!
        : SavedSearchVisibilityEnum.private,
  }
}

export const anUpdateSavedSearchPayload = (
  overrides?: Partial<UpdateSavedSearchPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpdateSavedSearchPayload' } & UpdateSavedSearchPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpdateSavedSearchPayload')
  return {
    __typename: 'UpdateSavedSearchPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    savedSearch:
      overrides && overrides.hasOwnProperty('savedSearch')
        ? overrides.savedSearch!
        : relationshipsToOmit.has('SavedSearch')
          ? ({} as SavedSearch)
          : aSavedSearch({}, relationshipsToOmit),
  }
}

export const anUpdateShortcutInput = (
  overrides?: Partial<UpdateShortcutInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): UpdateShortcutInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpdateShortcutInput')
  return {
    homePitch: overrides && overrides.hasOwnProperty('homePitch') ? overrides.homePitch! : 'autem',
  }
}

export const anUpdateShortcutPayload = (
  overrides?: Partial<UpdateShortcutPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpdateShortcutPayload' } & UpdateShortcutPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpdateShortcutPayload')
  return {
    __typename: 'UpdateShortcutPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    shortcut:
      overrides && overrides.hasOwnProperty('shortcut')
        ? overrides.shortcut!
        : relationshipsToOmit.has('Shortcut')
          ? ({} as Shortcut)
          : aShortcut({}, relationshipsToOmit),
  }
}

export const anUpdateTenantPostSignDocumentsInput = (
  overrides?: Partial<UpdateTenantPostSignDocumentsInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): UpdateTenantPostSignDocumentsInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpdateTenantPostSignDocumentsInput')
  return {
    tenantPostSignDocuments:
      overrides && overrides.hasOwnProperty('tenantPostSignDocuments')
        ? overrides.tenantPostSignDocuments!
        : ['5967f4d2-fedb-4d6f-abe3-33b3fa384916'],
  }
}

export const anUpdateTenantPostSignDocumentsPayload = (
  overrides?: Partial<UpdateTenantPostSignDocumentsPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpdateTenantPostSignDocumentsPayload' } & UpdateTenantPostSignDocumentsPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpdateTenantPostSignDocumentsPayload')
  return {
    __typename: 'UpdateTenantPostSignDocumentsPayload',
    contract:
      overrides && overrides.hasOwnProperty('contract')
        ? overrides.contract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const anUpdateUploadInput = (
  overrides?: Partial<UpdateUploadInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): UpdateUploadInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpdateUploadInput')
  return {
    metadata:
      overrides && overrides.hasOwnProperty('metadata')
        ? overrides.metadata!
        : relationshipsToOmit.has('UploadMetadataInput')
          ? ({} as UploadMetadataInput)
          : anUploadMetadataInput({}, relationshipsToOmit),
  }
}

export const anUpdateUploadPayload = (
  overrides?: Partial<UpdateUploadPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpdateUploadPayload' } & UpdateUploadPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpdateUploadPayload')
  return {
    __typename: 'UpdateUploadPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    upload:
      overrides && overrides.hasOwnProperty('upload')
        ? overrides.upload!
        : relationshipsToOmit.has('Upload')
          ? ({} as Upload)
          : anUpload({}, relationshipsToOmit),
  }
}

export const anUpdateUserInput = (
  overrides?: Partial<UpdateUserInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): UpdateUserInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpdateUserInput')
  return {
    affiliateCode:
      overrides && overrides.hasOwnProperty('affiliateCode') ? overrides.affiliateCode! : 'consequuntur',
    bio: overrides && overrides.hasOwnProperty('bio') ? overrides.bio! : 'amet',
    bioLived: overrides && overrides.hasOwnProperty('bioLived') ? overrides.bioLived! : 'hic',
    bioPets: overrides && overrides.hasOwnProperty('bioPets') ? overrides.bioPets! : 'doloribus',
    bioSpareTime: overrides && overrides.hasOwnProperty('bioSpareTime') ? overrides.bioSpareTime! : 'rerum',
    bioStudy: overrides && overrides.hasOwnProperty('bioStudy') ? overrides.bioStudy! : 'ullam',
    bioTitle: overrides && overrides.hasOwnProperty('bioTitle') ? overrides.bioTitle! : 'perspiciatis',
    bioWhen: overrides && overrides.hasOwnProperty('bioWhen') ? overrides.bioWhen! : 'asperiores',
    bioWhere: overrides && overrides.hasOwnProperty('bioWhere') ? overrides.bioWhere! : 'totam',
    bioWho: overrides && overrides.hasOwnProperty('bioWho') ? overrides.bioWho! : 'et',
    bioWork: overrides && overrides.hasOwnProperty('bioWork') ? overrides.bioWork! : 'magni',
    biometricConsent:
      overrides && overrides.hasOwnProperty('biometricConsent') ? overrides.biometricConsent! : true,
    companyName: overrides && overrides.hasOwnProperty('companyName') ? overrides.companyName! : 'rem',
    contactLocation:
      overrides && overrides.hasOwnProperty('contactLocation')
        ? overrides.contactLocation!
        : relationshipsToOmit.has('CreateLocationInput')
          ? ({} as CreateLocationInput)
          : aCreateLocationInput({}, relationshipsToOmit),
    doNotContact: overrides && overrides.hasOwnProperty('doNotContact') ? overrides.doNotContact! : true,
    doNotSms: overrides && overrides.hasOwnProperty('doNotSms') ? overrides.doNotSms! : false,
    doNotVacationSms:
      overrides && overrides.hasOwnProperty('doNotVacationSms') ? overrides.doNotVacationSms! : true,
    email: overrides && overrides.hasOwnProperty('email') ? overrides.email! : 'voluptas',
    expoPushToken:
      overrides && overrides.hasOwnProperty('expoPushToken') ? overrides.expoPushToken! : 'perspiciatis',
    familyName: overrides && overrides.hasOwnProperty('familyName') ? overrides.familyName! : 'qui',
    firstName: overrides && overrides.hasOwnProperty('firstName') ? overrides.firstName! : 'qui',
    gender: overrides && overrides.hasOwnProperty('gender') ? overrides.gender! : GenderEnum.female,
    hasPets: overrides && overrides.hasOwnProperty('hasPets') ? overrides.hasPets! : true,
    householdMembersBirthYears:
      overrides && overrides.hasOwnProperty('householdMembersBirthYears')
        ? overrides.householdMembersBirthYears!
        : [6362],
    housingSituation:
      overrides && overrides.hasOwnProperty('housingSituation')
        ? overrides.housingSituation!
        : relationshipsToOmit.has('HousingSituationInput')
          ? ({} as HousingSituationInput)
          : aHousingSituationInput({}, relationshipsToOmit),
    idNumber: overrides && overrides.hasOwnProperty('idNumber') ? overrides.idNumber! : 'aut',
    landlord: overrides && overrides.hasOwnProperty('landlord') ? overrides.landlord! : true,
    locale: overrides && overrides.hasOwnProperty('locale') ? overrides.locale! : 'et',
    mainPlatform:
      overrides && overrides.hasOwnProperty('mainPlatform') ? overrides.mainPlatform! : PlatformEnum.blocket,
    movingReason: overrides && overrides.hasOwnProperty('movingReason') ? overrides.movingReason! : 'quis',
    name: overrides && overrides.hasOwnProperty('name') ? overrides.name! : 'eveniet',
    notificationSpan:
      overrides && overrides.hasOwnProperty('notificationSpan')
        ? overrides.notificationSpan!
        : NotificationSpanEnum.EVERY_HOUR,
    nrFirsthandHousingUnits:
      overrides && overrides.hasOwnProperty('nrFirsthandHousingUnits')
        ? overrides.nrFirsthandHousingUnits!
        : NrFirsthandHousingUnitsEnum.option_1_24,
    occupation:
      overrides && overrides.hasOwnProperty('occupation') ? overrides.occupation! : OccupationEnum.fixed_term,
    orgNumber: overrides && overrides.hasOwnProperty('orgNumber') ? overrides.orgNumber! : 'earum',
    organisationNumberCountryCode:
      overrides && overrides.hasOwnProperty('organisationNumberCountryCode')
        ? overrides.organisationNumberCountryCode!
        : CountryCodeEnum.AD,
    pep: overrides && overrides.hasOwnProperty('pep') ? overrides.pep! : true,
    phoneNumber: overrides && overrides.hasOwnProperty('phoneNumber') ? overrides.phoneNumber! : 'beatae',
    professional: overrides && overrides.hasOwnProperty('professional') ? overrides.professional! : true,
    prospectOnly: overrides && overrides.hasOwnProperty('prospectOnly') ? overrides.prospectOnly! : false,
    rca: overrides && overrides.hasOwnProperty('rca') ? overrides.rca! : true,
    rentingOutFirsthandHomes:
      overrides && overrides.hasOwnProperty('rentingOutFirsthandHomes')
        ? overrides.rentingOutFirsthandHomes!
        : false,
    safeRental: overrides && overrides.hasOwnProperty('safeRental') ? overrides.safeRental! : false,
    sendMonitoringEvents:
      overrides && overrides.hasOwnProperty('sendMonitoringEvents') ? overrides.sendMonitoringEvents! : false,
    showAge: overrides && overrides.hasOwnProperty('showAge') ? overrides.showAge! : false,
    smoker: overrides && overrides.hasOwnProperty('smoker') ? overrides.smoker! : false,
    statedBirthDate:
      overrides && overrides.hasOwnProperty('statedBirthDate')
        ? overrides.statedBirthDate!
        : 'aut ullam sequi fuga voluptatibus voluptatibus recusandae',
    tenant: overrides && overrides.hasOwnProperty('tenant') ? overrides.tenant! : false,
    termsAccepted: overrides && overrides.hasOwnProperty('termsAccepted') ? overrides.termsAccepted! : false,
  }
}

export const anUpdateUserPayload = (
  overrides?: Partial<UpdateUserPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpdateUserPayload' } & UpdateUserPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpdateUserPayload')
  return {
    __typename: 'UpdateUserPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    user:
      overrides && overrides.hasOwnProperty('user')
        ? overrides.user!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
  }
}

export const anUpdateWaterFeeInput = (
  overrides?: Partial<UpdateWaterFeeInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): UpdateWaterFeeInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpdateWaterFeeInput')
  return {
    numberOfTenants:
      overrides && overrides.hasOwnProperty('numberOfTenants') ? overrides.numberOfTenants! : 3865,
    waterFeePerTenant:
      overrides && overrides.hasOwnProperty('waterFeePerTenant') ? overrides.waterFeePerTenant! : 8871,
  }
}

export const anUpload = (
  overrides?: Partial<Upload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Upload' } & Upload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Upload')
  return {
    __typename: 'Upload',
    contentLength: overrides && overrides.hasOwnProperty('contentLength') ? overrides.contentLength! : 2072,
    contentType: overrides && overrides.hasOwnProperty('contentType') ? overrides.contentType! : 'natus',
    fileType:
      overrides && overrides.hasOwnProperty('fileType') ? overrides.fileType! : UploadFileTypeEnum.document,
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'fc077cf7-cc62-4a78-8246-4f0326711952',
    metadata:
      overrides && overrides.hasOwnProperty('metadata')
        ? overrides.metadata!
        : relationshipsToOmit.has('UploadMetadata')
          ? ({} as UploadMetadata)
          : anUploadMetadata({}, relationshipsToOmit),
    tags:
      overrides && overrides.hasOwnProperty('tags')
        ? overrides.tags!
        : [
            relationshipsToOmit.has('UploadTagType')
              ? ({} as UploadTagType)
              : anUploadTagType({}, relationshipsToOmit),
          ],
    title: overrides && overrides.hasOwnProperty('title') ? overrides.title! : 'vero',
    type:
      overrides && overrides.hasOwnProperty('type')
        ? overrides.type!
        : UploadTypeEnum.activity_compensation_verification,
    url: overrides && overrides.hasOwnProperty('url') ? overrides.url! : 'velit',
  }
}

export const anUploadMetadata = (
  overrides?: Partial<UploadMetadata>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UploadMetadata' } & UploadMetadata => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UploadMetadata')
  return {
    __typename: 'UploadMetadata',
    order: overrides && overrides.hasOwnProperty('order') ? overrides.order! : 4687,
    primary: overrides && overrides.hasOwnProperty('primary') ? overrides.primary! : false,
    rotation: overrides && overrides.hasOwnProperty('rotation') ? overrides.rotation! : 3334,
  }
}

export const anUploadMetadataInput = (
  overrides?: Partial<UploadMetadataInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): UploadMetadataInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UploadMetadataInput')
  return {
    order: overrides && overrides.hasOwnProperty('order') ? overrides.order! : 3303,
    primary: overrides && overrides.hasOwnProperty('primary') ? overrides.primary! : true,
    rotation: overrides && overrides.hasOwnProperty('rotation') ? overrides.rotation! : 5257,
  }
}

export const anUploadTagType = (
  overrides?: Partial<UploadTagType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UploadTagType' } & UploadTagType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UploadTagType')
  return {
    __typename: 'UploadTagType',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '8b360ea1-1f1c-4725-9024-7f18f1c77e89',
    name: overrides && overrides.hasOwnProperty('name') ? overrides.name! : UploadTaggableNameEnum.layout,
  }
}

export const anUploadTaggableInput = (
  overrides?: Partial<UploadTaggableInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): UploadTaggableInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UploadTaggableInput')
  return {
    name: overrides && overrides.hasOwnProperty('name') ? overrides.name! : UploadTaggableNameEnum.layout,
  }
}

export const anUpsertApplicationBoardCardInfoInput = (
  overrides?: Partial<UpsertApplicationBoardCardInfoInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): UpsertApplicationBoardCardInfoInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertApplicationBoardCardInfoInput')
  return {
    showEmployerCertificate:
      overrides && overrides.hasOwnProperty('showEmployerCertificate')
        ? overrides.showEmployerCertificate!
        : false,
    showIdentification:
      overrides && overrides.hasOwnProperty('showIdentification') ? overrides.showIdentification! : false,
    showIncome: overrides && overrides.hasOwnProperty('showIncome') ? overrides.showIncome! : false,
    showLastMessage:
      overrides && overrides.hasOwnProperty('showLastMessage') ? overrides.showLastMessage! : false,
    showMatchingScore:
      overrides && overrides.hasOwnProperty('showMatchingScore') ? overrides.showMatchingScore! : false,
    showQueueDays: overrides && overrides.hasOwnProperty('showQueueDays') ? overrides.showQueueDays! : true,
    showStudyCertificate:
      overrides && overrides.hasOwnProperty('showStudyCertificate') ? overrides.showStudyCertificate! : true,
    showUc: overrides && overrides.hasOwnProperty('showUc') ? overrides.showUc! : true,
  }
}

export const anUpsertApplicationBoardCardInfoPayload = (
  overrides?: Partial<UpsertApplicationBoardCardInfoPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpsertApplicationBoardCardInfoPayload' } & UpsertApplicationBoardCardInfoPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertApplicationBoardCardInfoPayload')
  return {
    __typename: 'UpsertApplicationBoardCardInfoPayload',
    applicationBoardCardInfo:
      overrides && overrides.hasOwnProperty('applicationBoardCardInfo')
        ? overrides.applicationBoardCardInfo!
        : relationshipsToOmit.has('ApplicationBoardCardInfo')
          ? ({} as ApplicationBoardCardInfo)
          : anApplicationBoardCardInfo({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const anUpsertBankAccountInput = (
  overrides?: Partial<UpsertBankAccountInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): UpsertBankAccountInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertBankAccountInput')
  return {
    bankLocation:
      overrides && overrides.hasOwnProperty('bankLocation')
        ? overrides.bankLocation!
        : relationshipsToOmit.has('CreateLocationInput')
          ? ({} as CreateLocationInput)
          : aCreateLocationInput({}, relationshipsToOmit),
    bankName: overrides && overrides.hasOwnProperty('bankName') ? overrides.bankName! : 'amet',
    bic: overrides && overrides.hasOwnProperty('bic') ? overrides.bic! : 'est',
    clearingNumber:
      overrides && overrides.hasOwnProperty('clearingNumber') ? overrides.clearingNumber! : 'quisquam',
    countryCode: overrides && overrides.hasOwnProperty('countryCode') ? overrides.countryCode! : 'quidem',
    email: overrides && overrides.hasOwnProperty('email') ? overrides.email! : 'officiis',
    iban: overrides && overrides.hasOwnProperty('iban') ? overrides.iban! : 'facere',
    location:
      overrides && overrides.hasOwnProperty('location')
        ? overrides.location!
        : relationshipsToOmit.has('CreateLocationInput')
          ? ({} as CreateLocationInput)
          : aCreateLocationInput({}, relationshipsToOmit),
    name: overrides && overrides.hasOwnProperty('name') ? overrides.name! : 'voluptas',
    number: overrides && overrides.hasOwnProperty('number') ? overrides.number! : 'aut',
    ownerRelationType:
      overrides && overrides.hasOwnProperty('ownerRelationType')
        ? overrides.ownerRelationType!
        : BankAccountOwnerRelationTypeEnum.family,
    type: overrides && overrides.hasOwnProperty('type') ? overrides.type! : BankAccountTypeEnum.address,
  }
}

export const anUpsertBankAccountPayload = (
  overrides?: Partial<UpsertBankAccountPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpsertBankAccountPayload' } & UpsertBankAccountPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertBankAccountPayload')
  return {
    __typename: 'UpsertBankAccountPayload',
    bankAccount:
      overrides && overrides.hasOwnProperty('bankAccount')
        ? overrides.bankAccount!
        : relationshipsToOmit.has('BankAccount')
          ? ({} as BankAccount)
          : aBankAccount({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const anUpsertBookingInput = (
  overrides?: Partial<UpsertBookingInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): UpsertBookingInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertBookingInput')
  return {
    checkIn:
      overrides && overrides.hasOwnProperty('checkIn')
        ? overrides.checkIn!
        : 'asperiores possimus qui qui quia doloribus et',
    checkOut:
      overrides && overrides.hasOwnProperty('checkOut')
        ? overrides.checkOut!
        : 'tempora delectus sed accusamus consequuntur voluptate excepturi',
    homeId:
      overrides && overrides.hasOwnProperty('homeId')
        ? overrides.homeId!
        : '2855f73c-ad16-45bc-8199-85af5c6179df',
    message: overrides && overrides.hasOwnProperty('message') ? overrides.message! : 'corporis',
  }
}

export const anUpsertBookingPayload = (
  overrides?: Partial<UpsertBookingPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpsertBookingPayload' } & UpsertBookingPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertBookingPayload')
  return {
    __typename: 'UpsertBookingPayload',
    booking:
      overrides && overrides.hasOwnProperty('booking')
        ? overrides.booking!
        : relationshipsToOmit.has('Booking')
          ? ({} as Booking)
          : aBooking({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const anUpsertBuildingInput = (
  overrides?: Partial<UpsertBuildingInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): UpsertBuildingInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertBuildingInput')
  return {
    buildYear: overrides && overrides.hasOwnProperty('buildYear') ? overrides.buildYear! : 1087,
    buildingName: overrides && overrides.hasOwnProperty('buildingName') ? overrides.buildingName! : 'autem',
    descriptionBuilding:
      overrides && overrides.hasOwnProperty('descriptionBuilding') ? overrides.descriptionBuilding! : 'culpa',
    descriptionTransportation:
      overrides && overrides.hasOwnProperty('descriptionTransportation')
        ? overrides.descriptionTransportation!
        : 'quia',
    energyClass: overrides && overrides.hasOwnProperty('energyClass') ? overrides.energyClass! : 'voluptas',
    location:
      overrides && overrides.hasOwnProperty('location')
        ? overrides.location!
        : relationshipsToOmit.has('CreateLocationInput')
          ? ({} as CreateLocationInput)
          : aCreateLocationInput({}, relationshipsToOmit),
    organizationUid:
      overrides && overrides.hasOwnProperty('organizationUid')
        ? overrides.organizationUid!
        : '60264771-1975-43fb-adff-f562814f78ab',
  }
}

export const anUpsertBuildingPayload = (
  overrides?: Partial<UpsertBuildingPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpsertBuildingPayload' } & UpsertBuildingPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertBuildingPayload')
  return {
    __typename: 'UpsertBuildingPayload',
    building:
      overrides && overrides.hasOwnProperty('building')
        ? overrides.building!
        : relationshipsToOmit.has('Building')
          ? ({} as Building)
          : aBuilding({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const anUpsertContractDetailsInput = (
  overrides?: Partial<UpsertContractDetailsInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): UpsertContractDetailsInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertContractDetailsInput')
  return {
    additionalInformation:
      overrides && overrides.hasOwnProperty('additionalInformation')
        ? overrides.additionalInformation!
        : 'similique',
    bathroomShared:
      overrides && overrides.hasOwnProperty('bathroomShared') ? overrides.bathroomShared! : false,
    bindingTime: overrides && overrides.hasOwnProperty('bindingTime') ? overrides.bindingTime! : 6454,
    cadastralReference:
      overrides && overrides.hasOwnProperty('cadastralReference') ? overrides.cadastralReference! : 'fugiat',
    canBeTerminated:
      overrides && overrides.hasOwnProperty('canBeTerminated') ? overrides.canBeTerminated! : true,
    codeOfConduct:
      overrides && overrides.hasOwnProperty('codeOfConduct') ? overrides.codeOfConduct! : 'expedita',
    keySpecification:
      overrides && overrides.hasOwnProperty('keySpecification') ? overrides.keySpecification! : 'quaerat',
    kitchenShared: overrides && overrides.hasOwnProperty('kitchenShared') ? overrides.kitchenShared! : true,
    knownDamages: overrides && overrides.hasOwnProperty('knownDamages') ? overrides.knownDamages! : 'et',
    legalType:
      overrides && overrides.hasOwnProperty('legalType') ? overrides.legalType! : LegalTypeEnum.egen_bolig,
    municipality:
      overrides && overrides.hasOwnProperty('municipality') ? overrides.municipality! : 'aspernatur',
    rentedAsIs: overrides && overrides.hasOwnProperty('rentedAsIs') ? overrides.rentedAsIs! : false,
    sectionNumber:
      overrides && overrides.hasOwnProperty('sectionNumber') ? overrides.sectionNumber! : 'laudantium',
    shorterTerminationExplanation:
      overrides && overrides.hasOwnProperty('shorterTerminationExplanation')
        ? overrides.shorterTerminationExplanation!
        : 'culpa',
    tenantEmail: overrides && overrides.hasOwnProperty('tenantEmail') ? overrides.tenantEmail! : 'iusto',
    tenantFamilyName:
      overrides && overrides.hasOwnProperty('tenantFamilyName') ? overrides.tenantFamilyName! : 'velit',
    tenantFirstName:
      overrides && overrides.hasOwnProperty('tenantFirstName') ? overrides.tenantFirstName! : 'veniam',
    tenantPhoneNumber:
      overrides && overrides.hasOwnProperty('tenantPhoneNumber') ? overrides.tenantPhoneNumber! : 'sit',
    terminationNoticeMonths:
      overrides && overrides.hasOwnProperty('terminationNoticeMonths')
        ? overrides.terminationNoticeMonths!
        : 1002,
    titleNumber: overrides && overrides.hasOwnProperty('titleNumber') ? overrides.titleNumber! : 'vitae',
  }
}

export const anUpsertContractInput = (
  overrides?: Partial<UpsertContractInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): UpsertContractInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertContractInput')
  return {
    acceptsCosignatures:
      overrides && overrides.hasOwnProperty('acceptsCosignatures') ? overrides.acceptsCosignatures! : true,
    annualRentIncrease:
      overrides && overrides.hasOwnProperty('annualRentIncrease') ? overrides.annualRentIncrease! : 1.64,
    apartmentNumber:
      overrides && overrides.hasOwnProperty('apartmentNumber') ? overrides.apartmentNumber! : 'porro',
    canUse: overrides && overrides.hasOwnProperty('canUse') ? overrides.canUse! : ['cum'],
    contractDetails:
      overrides && overrides.hasOwnProperty('contractDetails')
        ? overrides.contractDetails!
        : relationshipsToOmit.has('UpsertContractDetailsInput')
          ? ({} as UpsertContractDetailsInput)
          : anUpsertContractDetailsInput({}, relationshipsToOmit),
    duration:
      overrides && overrides.hasOwnProperty('duration')
        ? overrides.duration!
        : relationshipsToOmit.has('CreateDurationInput')
          ? ({} as CreateDurationInput)
          : aCreateDurationInput({}, relationshipsToOmit),
    electricityFee:
      overrides && overrides.hasOwnProperty('electricityFee')
        ? overrides.electricityFee!
        : relationshipsToOmit.has('ElectricityFeeInput')
          ? ({} as ElectricityFeeInput)
          : anElectricityFeeInput({}, relationshipsToOmit),
    extraCosts:
      overrides && overrides.hasOwnProperty('extraCosts')
        ? overrides.extraCosts!
        : [
            relationshipsToOmit.has('ExtraCostInput')
              ? ({} as ExtraCostInput)
              : anExtraCostInput({}, relationshipsToOmit),
          ],
    furniture: overrides && overrides.hasOwnProperty('furniture') ? overrides.furniture! : false,
    homeId:
      overrides && overrides.hasOwnProperty('homeId')
        ? overrides.homeId!
        : 'b2b57e3a-3824-4247-9120-b694e78777cc',
    insurance:
      overrides && overrides.hasOwnProperty('insurance')
        ? overrides.insurance!
        : HomeInsuranceEnum.insurance_landlord,
    insuranceResponsibility:
      overrides && overrides.hasOwnProperty('insuranceResponsibility')
        ? overrides.insuranceResponsibility!
        : InsuranceResponsibilityEnum.insurance_responsibility_landlord,
    inventory: overrides && overrides.hasOwnProperty('inventory') ? overrides.inventory! : ['non'],
    landlordHasKeys:
      overrides && overrides.hasOwnProperty('landlordHasKeys') ? overrides.landlordHasKeys! : true,
    numberOfTenants:
      overrides && overrides.hasOwnProperty('numberOfTenants') ? overrides.numberOfTenants! : 4727,
    overnightStay: overrides && overrides.hasOwnProperty('overnightStay') ? overrides.overnightStay! : false,
    ownHome: overrides && overrides.hasOwnProperty('ownHome') ? overrides.ownHome! : false,
    paymentDay: overrides && overrides.hasOwnProperty('paymentDay') ? overrides.paymentDay! : 1503,
    pets: overrides && overrides.hasOwnProperty('pets') ? overrides.pets! : true,
    postCleaning:
      overrides && overrides.hasOwnProperty('postCleaning')
        ? overrides.postCleaning!
        : ContractPostCleaningEnum.post_cleaning_landlord,
    preCleaning:
      overrides && overrides.hasOwnProperty('preCleaning')
        ? overrides.preCleaning!
        : ContractPreCleaningEnum.pre_cleaning_landlord,
    qasaGuarantee: overrides && overrides.hasOwnProperty('qasaGuarantee') ? overrides.qasaGuarantee! : false,
    renovations: overrides && overrides.hasOwnProperty('renovations') ? overrides.renovations! : false,
    rent: overrides && overrides.hasOwnProperty('rent') ? overrides.rent! : 1688,
    rentExcludes:
      overrides && overrides.hasOwnProperty('rentExcludes') ? overrides.rentExcludes! : ['repellendus'],
    rentIncludes:
      overrides && overrides.hasOwnProperty('rentIncludes') ? overrides.rentIncludes! : ['architecto'],
    rentalType:
      overrides && overrides.hasOwnProperty('rentalType')
        ? overrides.rentalType!
        : ContractRentalTypeEnum.long_term,
    smallRepairs:
      overrides && overrides.hasOwnProperty('smallRepairs')
        ? overrides.smallRepairs!
        : ContractSmallRepairsEnum.small_repairs_landlord,
    smoker: overrides && overrides.hasOwnProperty('smoker') ? overrides.smoker! : false,
    subletception: overrides && overrides.hasOwnProperty('subletception') ? overrides.subletception! : false,
    temporaryInstallations:
      overrides && overrides.hasOwnProperty('temporaryInstallations')
        ? overrides.temporaryInstallations!
        : true,
    tenantUid:
      overrides && overrides.hasOwnProperty('tenantUid')
        ? overrides.tenantUid!
        : 'c19bbded-b189-49d5-a5c4-52c80e50d42a',
    tenureType:
      overrides && overrides.hasOwnProperty('tenureType')
        ? overrides.tenureType!
        : TenureTypeEnum.condominium,
    terminationNotice:
      overrides && overrides.hasOwnProperty('terminationNotice') ? overrides.terminationNotice! : false,
    waterFeePerTenant:
      overrides && overrides.hasOwnProperty('waterFeePerTenant') ? overrides.waterFeePerTenant! : 523,
  }
}

export const anUpsertContractPayload = (
  overrides?: Partial<UpsertContractPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpsertContractPayload' } & UpsertContractPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertContractPayload')
  return {
    __typename: 'UpsertContractPayload',
    contract:
      overrides && overrides.hasOwnProperty('contract')
        ? overrides.contract!
        : relationshipsToOmit.has('Contract')
          ? ({} as Contract)
          : aContract({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const anUpsertEmploymentVerificationInput = (
  overrides?: Partial<UpsertEmploymentVerificationInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): UpsertEmploymentVerificationInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertEmploymentVerificationInput')
  return {
    documents:
      overrides && overrides.hasOwnProperty('documents')
        ? overrides.documents!
        : ['16bc14a8-0d37-4921-8032-5c5b56a9cfca'],
    employerName: overrides && overrides.hasOwnProperty('employerName') ? overrides.employerName! : 'libero',
    role: overrides && overrides.hasOwnProperty('role') ? overrides.role! : 'quia',
  }
}

export const anUpsertEmploymentVerificationPayload = (
  overrides?: Partial<UpsertEmploymentVerificationPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpsertEmploymentVerificationPayload' } & UpsertEmploymentVerificationPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertEmploymentVerificationPayload')
  return {
    __typename: 'UpsertEmploymentVerificationPayload',
    employmentVerification:
      overrides && overrides.hasOwnProperty('employmentVerification')
        ? overrides.employmentVerification!
        : relationshipsToOmit.has('EmploymentVerification')
          ? ({} as EmploymentVerification)
          : anEmploymentVerification({}, relationshipsToOmit),
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
  }
}

export const anUpsertHomeAvailabilityInput = (
  overrides?: Partial<UpsertHomeAvailabilityInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): UpsertHomeAvailabilityInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertHomeAvailabilityInput')
  return {
    date:
      overrides && overrides.hasOwnProperty('date')
        ? overrides.date!
        : 'dicta aut voluptatem et quidem minus distinctio',
    price: overrides && overrides.hasOwnProperty('price') ? overrides.price! : 7534,
  }
}

export const anUpsertHomeAvailabilityPayload = (
  overrides?: Partial<UpsertHomeAvailabilityPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpsertHomeAvailabilityPayload' } & UpsertHomeAvailabilityPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertHomeAvailabilityPayload')
  return {
    __typename: 'UpsertHomeAvailabilityPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    upserted:
      overrides && overrides.hasOwnProperty('upserted')
        ? overrides.upserted!
        : [
            relationshipsToOmit.has('HomeAvailability')
              ? ({} as HomeAvailability)
              : aHomeAvailability({}, relationshipsToOmit),
          ],
  }
}

export const anUpsertHomeAvailabilitySettingsInput = (
  overrides?: Partial<UpsertHomeAvailabilitySettingsInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): UpsertHomeAvailabilitySettingsInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertHomeAvailabilitySettingsInput')
  return {
    defaultPricePerNight:
      overrides && overrides.hasOwnProperty('defaultPricePerNight') ? overrides.defaultPricePerNight! : 7583,
    homeId:
      overrides && overrides.hasOwnProperty('homeId')
        ? overrides.homeId!
        : '47c38338-797a-4a8d-8059-410884929ca1',
    minNightsStay: overrides && overrides.hasOwnProperty('minNightsStay') ? overrides.minNightsStay! : 7658,
    noCheckinDays:
      overrides && overrides.hasOwnProperty('noCheckinDays')
        ? overrides.noCheckinDays!
        : [DayOfTheWeekTypeEnum.friday],
    rentOnlyWeekly:
      overrides && overrides.hasOwnProperty('rentOnlyWeekly') ? overrides.rentOnlyWeekly! : true,
  }
}

export const anUpsertHomeAvailabilitySettingsPayload = (
  overrides?: Partial<UpsertHomeAvailabilitySettingsPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpsertHomeAvailabilitySettingsPayload' } & UpsertHomeAvailabilitySettingsPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertHomeAvailabilitySettingsPayload')
  return {
    __typename: 'UpsertHomeAvailabilitySettingsPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    homeAvailabilitySettings:
      overrides && overrides.hasOwnProperty('homeAvailabilitySettings')
        ? overrides.homeAvailabilitySettings!
        : relationshipsToOmit.has('HomeAvailabilitySettings')
          ? ({} as HomeAvailabilitySettings)
          : aHomeAvailabilitySettings({}, relationshipsToOmit),
  }
}

export const anUpsertHomeTemplateInput = (
  overrides?: Partial<UpsertHomeTemplateInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): UpsertHomeTemplateInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertHomeTemplateInput')
  return {
    apartmentNumber:
      overrides && overrides.hasOwnProperty('apartmentNumber') ? overrides.apartmentNumber! : 'quis',
    bathroomRenovationYear:
      overrides && overrides.hasOwnProperty('bathroomRenovationYear')
        ? overrides.bathroomRenovationYear!
        : 8928,
    buildingId:
      overrides && overrides.hasOwnProperty('buildingId')
        ? overrides.buildingId!
        : 'bc866d4c-7392-4758-92cd-6b1a40d8d0e9',
    corporateHome: overrides && overrides.hasOwnProperty('corporateHome') ? overrides.corporateHome! : false,
    description: overrides && overrides.hasOwnProperty('description') ? overrides.description! : 'optio',
    electricityFee:
      overrides && overrides.hasOwnProperty('electricityFee')
        ? overrides.electricityFee!
        : relationshipsToOmit.has('ElectricityFeeInput')
          ? ({} as ElectricityFeeInput)
          : anElectricityFeeInput({}, relationshipsToOmit),
    firsthand: overrides && overrides.hasOwnProperty('firsthand') ? overrides.firsthand! : false,
    floor: overrides && overrides.hasOwnProperty('floor') ? overrides.floor! : 1702,
    kitchenRenovationYear:
      overrides && overrides.hasOwnProperty('kitchenRenovationYear')
        ? overrides.kitchenRenovationYear!
        : 3633,
    location:
      overrides && overrides.hasOwnProperty('location')
        ? overrides.location!
        : relationshipsToOmit.has('CreateLocationInput')
          ? ({} as CreateLocationInput)
          : aCreateLocationInput({}, relationshipsToOmit),
    organizationUid:
      overrides && overrides.hasOwnProperty('organizationUid')
        ? overrides.organizationUid!
        : '717b74c7-2bb2-49f6-9098-cfdf6bea0df9',
    rent: overrides && overrides.hasOwnProperty('rent') ? overrides.rent! : 582,
    roomCount: overrides && overrides.hasOwnProperty('roomCount') ? overrides.roomCount! : 0.94,
    seniorHome: overrides && overrides.hasOwnProperty('seniorHome') ? overrides.seniorHome! : false,
    shared: overrides && overrides.hasOwnProperty('shared') ? overrides.shared! : false,
    squareMeters: overrides && overrides.hasOwnProperty('squareMeters') ? overrides.squareMeters! : 347,
    studentHome: overrides && overrides.hasOwnProperty('studentHome') ? overrides.studentHome! : true,
    tenantCount: overrides && overrides.hasOwnProperty('tenantCount') ? overrides.tenantCount! : 3306,
    tenureType:
      overrides && overrides.hasOwnProperty('tenureType')
        ? overrides.tenureType!
        : TenureTypeEnum.condominium,
    traits: overrides && overrides.hasOwnProperty('traits') ? overrides.traits! : [TraitTypeEnum.archipelago],
    type: overrides && overrides.hasOwnProperty('type') ? overrides.type! : HomeTypeEnum.apartment,
    waterFeePerTenant:
      overrides && overrides.hasOwnProperty('waterFeePerTenant') ? overrides.waterFeePerTenant! : 2710,
  }
}

export const anUpsertHomeTemplatePayload = (
  overrides?: Partial<UpsertHomeTemplatePayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpsertHomeTemplatePayload' } & UpsertHomeTemplatePayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertHomeTemplatePayload')
  return {
    __typename: 'UpsertHomeTemplatePayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    homeTemplate:
      overrides && overrides.hasOwnProperty('homeTemplate')
        ? overrides.homeTemplate!
        : relationshipsToOmit.has('HomeTemplate')
          ? ({} as HomeTemplate)
          : aHomeTemplate({}, relationshipsToOmit),
  }
}

export const anUpsertIncomeVerificationPayload = (
  overrides?: Partial<UpsertIncomeVerificationPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpsertIncomeVerificationPayload' } & UpsertIncomeVerificationPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertIncomeVerificationPayload')
  return {
    __typename: 'UpsertIncomeVerificationPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    incomeVerification:
      overrides && overrides.hasOwnProperty('incomeVerification')
        ? overrides.incomeVerification!
        : relationshipsToOmit.has('IncomeVerification')
          ? ({} as IncomeVerification)
          : anIncomeVerification({}, relationshipsToOmit),
  }
}

export const anUpsertInspectionPayload = (
  overrides?: Partial<UpsertInspectionPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpsertInspectionPayload' } & UpsertInspectionPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertInspectionPayload')
  return {
    __typename: 'UpsertInspectionPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    inspection:
      overrides && overrides.hasOwnProperty('inspection')
        ? overrides.inspection!
        : relationshipsToOmit.has('Inspection')
          ? ({} as Inspection)
          : anInspection({}, relationshipsToOmit),
  }
}

export const anUpsertInstantSignInput = (
  overrides?: Partial<UpsertInstantSignInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): UpsertInstantSignInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertInstantSignInput')
  return {
    durationAttributes:
      overrides && overrides.hasOwnProperty('durationAttributes')
        ? overrides.durationAttributes!
        : relationshipsToOmit.has('InstantSignDurationInput')
          ? ({} as InstantSignDurationInput)
          : anInstantSignDurationInput({}, relationshipsToOmit),
    homeId:
      overrides && overrides.hasOwnProperty('homeId')
        ? overrides.homeId!
        : 'd27390c9-8347-4945-ab4f-3d493ce57b9d',
  }
}

export const anUpsertMessageMacroInput = (
  overrides?: Partial<UpsertMessageMacroInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): UpsertMessageMacroInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertMessageMacroInput')
  return {
    description: overrides && overrides.hasOwnProperty('description') ? overrides.description! : 'id',
    text: overrides && overrides.hasOwnProperty('text') ? overrides.text! : 'quia',
    title: overrides && overrides.hasOwnProperty('title') ? overrides.title! : 'animi',
  }
}

export const anUpsertMessageMacroPayload = (
  overrides?: Partial<UpsertMessageMacroPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpsertMessageMacroPayload' } & UpsertMessageMacroPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertMessageMacroPayload')
  return {
    __typename: 'UpsertMessageMacroPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    messageMacro:
      overrides && overrides.hasOwnProperty('messageMacro')
        ? overrides.messageMacro!
        : relationshipsToOmit.has('MessageMacro')
          ? ({} as MessageMacro)
          : aMessageMacro({}, relationshipsToOmit),
  }
}

export const anUpsertOccupationInput = (
  overrides?: Partial<UpsertOccupationInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): UpsertOccupationInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertOccupationInput')
  return {
    endedAt:
      overrides && overrides.hasOwnProperty('endedAt')
        ? overrides.endedAt!
        : 'sint cum omnis iste veritatis aut cum',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'f273f08e-26b0-4fb8-bdb1-c28f8356ea12',
    occupationScheduleType:
      overrides && overrides.hasOwnProperty('occupationScheduleType')
        ? overrides.occupationScheduleType!
        : OccupationScheduleTypeEnum.full_time,
    occupationType:
      overrides && overrides.hasOwnProperty('occupationType')
        ? overrides.occupationType!
        : OccupationTypeEnum.other,
    startedAt:
      overrides && overrides.hasOwnProperty('startedAt')
        ? overrides.startedAt!
        : 'quidem accusamus voluptatem possimus ipsam nulla qui',
    subtitle: overrides && overrides.hasOwnProperty('subtitle') ? overrides.subtitle! : 'et',
    title: overrides && overrides.hasOwnProperty('title') ? overrides.title! : 'illum',
  }
}

export const anUpsertOccupationPayload = (
  overrides?: Partial<UpsertOccupationPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpsertOccupationPayload' } & UpsertOccupationPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertOccupationPayload')
  return {
    __typename: 'UpsertOccupationPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    occupation:
      overrides && overrides.hasOwnProperty('occupation')
        ? overrides.occupation!
        : relationshipsToOmit.has('Occupation')
          ? ({} as Occupation)
          : anOccupation({}, relationshipsToOmit),
  }
}

export const anUpsertOccupationVerificationInput = (
  overrides?: Partial<UpsertOccupationVerificationInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): UpsertOccupationVerificationInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertOccupationVerificationInput')
  return {
    document:
      overrides && overrides.hasOwnProperty('document')
        ? overrides.document!
        : 'd281e816-e7e0-4532-9d0c-efe35ac1cc8a',
    occupationId:
      overrides && overrides.hasOwnProperty('occupationId')
        ? overrides.occupationId!
        : '8f75b959-15ad-4146-b162-5d03d84b6c20',
    verificationType:
      overrides && overrides.hasOwnProperty('verificationType')
        ? overrides.verificationType!
        : VerificationTypeEnum.employment,
  }
}

export const anUpsertOccupationVerificationPayload = (
  overrides?: Partial<UpsertOccupationVerificationPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpsertOccupationVerificationPayload' } & UpsertOccupationVerificationPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertOccupationVerificationPayload')
  return {
    __typename: 'UpsertOccupationVerificationPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    occupationVerification:
      overrides && overrides.hasOwnProperty('occupationVerification')
        ? overrides.occupationVerification!
        : relationshipsToOmit.has('OccupationVerification')
          ? ({} as OccupationVerification)
          : anOccupationVerification({}, relationshipsToOmit),
  }
}

export const anUpsertRentalRequirementInput = (
  overrides?: Partial<UpsertRentalRequirementInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): UpsertRentalRequirementInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertRentalRequirementInput')
  return {
    approvedCreditCheck:
      overrides && overrides.hasOwnProperty('approvedCreditCheck') ? overrides.approvedCreditCheck! : true,
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '5c2bf4ba-8644-4d41-99ac-3a0ca3ca1a54',
    rentMultiplier:
      overrides && overrides.hasOwnProperty('rentMultiplier') ? overrides.rentMultiplier! : 2.54,
    studentVerification:
      overrides && overrides.hasOwnProperty('studentVerification') ? overrides.studentVerification! : false,
    verifiedIdNumber:
      overrides && overrides.hasOwnProperty('verifiedIdNumber') ? overrides.verifiedIdNumber! : true,
    verifiedIncome:
      overrides && overrides.hasOwnProperty('verifiedIncome') ? overrides.verifiedIncome! : true,
  }
}

export const anUpsertRentalRequirementPayload = (
  overrides?: Partial<UpsertRentalRequirementPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpsertRentalRequirementPayload' } & UpsertRentalRequirementPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertRentalRequirementPayload')
  return {
    __typename: 'UpsertRentalRequirementPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    rentalRequirement:
      overrides && overrides.hasOwnProperty('rentalRequirement')
        ? overrides.rentalRequirement!
        : relationshipsToOmit.has('RentalRequirement')
          ? ({} as RentalRequirement)
          : aRentalRequirement({}, relationshipsToOmit),
  }
}

export const anUpsertStudentVerificationInput = (
  overrides?: Partial<UpsertStudentVerificationInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): UpsertStudentVerificationInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertStudentVerificationInput')
  return {
    educationType:
      overrides && overrides.hasOwnProperty('educationType')
        ? overrides.educationType!
        : StudentVerificationEducationTypeEnum.municipal_adult_education,
    expectedFinishDate:
      overrides && overrides.hasOwnProperty('expectedFinishDate')
        ? overrides.expectedFinishDate!
        : 'et vero et consequuntur officia quia et',
    extent:
      overrides && overrides.hasOwnProperty('extent')
        ? overrides.extent!
        : StudentVerificationExtentEnum.full_time,
    program: overrides && overrides.hasOwnProperty('program') ? overrides.program! : 'iste',
    school: overrides && overrides.hasOwnProperty('school') ? overrides.school! : 'in',
    startDate:
      overrides && overrides.hasOwnProperty('startDate')
        ? overrides.startDate!
        : 'distinctio ex rerum vitae accusantium labore et',
  }
}

export const anUpsertStudentVerificationPayload = (
  overrides?: Partial<UpsertStudentVerificationPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpsertStudentVerificationPayload' } & UpsertStudentVerificationPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertStudentVerificationPayload')
  return {
    __typename: 'UpsertStudentVerificationPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    studentVerification:
      overrides && overrides.hasOwnProperty('studentVerification')
        ? overrides.studentVerification!
        : relationshipsToOmit.has('StudentVerification')
          ? ({} as StudentVerification)
          : aStudentVerification({}, relationshipsToOmit),
  }
}

export const anUpsertTenantAdPayload = (
  overrides?: Partial<UpsertTenantAdPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpsertTenantAdPayload' } & UpsertTenantAdPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertTenantAdPayload')
  return {
    __typename: 'UpsertTenantAdPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    tenantAd:
      overrides && overrides.hasOwnProperty('tenantAd')
        ? overrides.tenantAd!
        : relationshipsToOmit.has('TenantAd')
          ? ({} as TenantAd)
          : aTenantAd({}, relationshipsToOmit),
  }
}

export const anUpsertViewingCalendarPayload = (
  overrides?: Partial<UpsertViewingCalendarPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UpsertViewingCalendarPayload' } & UpsertViewingCalendarPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UpsertViewingCalendarPayload')
  return {
    __typename: 'UpsertViewingCalendarPayload',
    errors:
      overrides && overrides.hasOwnProperty('errors')
        ? overrides.errors!
        : [relationshipsToOmit.has('Validation') ? ({} as Validation) : aValidation({}, relationshipsToOmit)],
    information: overrides && overrides.hasOwnProperty('information') ? overrides.information! : 'eligendi',
    slots:
      overrides && overrides.hasOwnProperty('slots')
        ? overrides.slots!
        : [
            relationshipsToOmit.has('ViewingSlot')
              ? ({} as ViewingSlot)
              : aViewingSlot({}, relationshipsToOmit),
          ],
  }
}

export const aUser = (
  overrides?: Partial<User>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'User' } & User => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('User')
  return {
    __typename: 'User',
    activeIdentifications:
      overrides && overrides.hasOwnProperty('activeIdentifications')
        ? overrides.activeIdentifications!
        : [
            relationshipsToOmit.has('Identification')
              ? ({} as Identification)
              : anIdentification({}, relationshipsToOmit),
          ],
    adminFields:
      overrides && overrides.hasOwnProperty('adminFields')
        ? overrides.adminFields!
        : relationshipsToOmit.has('AdminFields')
          ? ({} as AdminFields)
          : anAdminFields({}, relationshipsToOmit),
    affiliateCode:
      overrides && overrides.hasOwnProperty('affiliateCode')
        ? overrides.affiliateCode!
        : relationshipsToOmit.has('AffiliateCode')
          ? ({} as AffiliateCode)
          : anAffiliateCode({}, relationshipsToOmit),
    age: overrides && overrides.hasOwnProperty('age') ? overrides.age! : 667,
    applicationStats:
      overrides && overrides.hasOwnProperty('applicationStats')
        ? overrides.applicationStats!
        : relationshipsToOmit.has('UserApplicationStats')
          ? ({} as UserApplicationStats)
          : aUserApplicationStats({}, relationshipsToOmit),
    bio:
      overrides && overrides.hasOwnProperty('bio')
        ? overrides.bio!
        : relationshipsToOmit.has('UserBio')
          ? ({} as UserBio)
          : aUserBio({}, relationshipsToOmit),
    bookings:
      overrides && overrides.hasOwnProperty('bookings')
        ? overrides.bookings!
        : [relationshipsToOmit.has('Booking') ? ({} as Booking) : aBooking({}, relationshipsToOmit)],
    certificate:
      overrides && overrides.hasOwnProperty('certificate')
        ? overrides.certificate!
        : relationshipsToOmit.has('Certificate')
          ? ({} as Certificate)
          : aCertificate({}, relationshipsToOmit),
    companyName: overrides && overrides.hasOwnProperty('companyName') ? overrides.companyName! : 'placeat',
    contactLocation:
      overrides && overrides.hasOwnProperty('contactLocation')
        ? overrides.contactLocation!
        : relationshipsToOmit.has('Location')
          ? ({} as Location)
          : aLocation({}, relationshipsToOmit),
    conversationForHome:
      overrides && overrides.hasOwnProperty('conversationForHome')
        ? overrides.conversationForHome!
        : relationshipsToOmit.has('Conversation')
          ? ({} as Conversation)
          : aConversation({}, relationshipsToOmit),
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'asperiores esse hic amet iusto aperiam aut',
    creditReport:
      overrides && overrides.hasOwnProperty('creditReport')
        ? overrides.creditReport!
        : relationshipsToOmit.has('CreditReport')
          ? ({} as CreditReport)
          : aCreditReport({}, relationshipsToOmit),
    currentIdentification:
      overrides && overrides.hasOwnProperty('currentIdentification')
        ? overrides.currentIdentification!
        : relationshipsToOmit.has('Identification')
          ? ({} as Identification)
          : anIdentification({}, relationshipsToOmit),
    doNotContact: overrides && overrides.hasOwnProperty('doNotContact') ? overrides.doNotContact! : true,
    doNotSms: overrides && overrides.hasOwnProperty('doNotSms') ? overrides.doNotSms! : true,
    doNotVacationSms:
      overrides && overrides.hasOwnProperty('doNotVacationSms') ? overrides.doNotVacationSms! : true,
    emailConfirmed:
      overrides && overrides.hasOwnProperty('emailConfirmed') ? overrides.emailConfirmed! : true,
    employmentVerification:
      overrides && overrides.hasOwnProperty('employmentVerification')
        ? overrides.employmentVerification!
        : relationshipsToOmit.has('EmploymentVerification')
          ? ({} as EmploymentVerification)
          : anEmploymentVerification({}, relationshipsToOmit),
    firstName: overrides && overrides.hasOwnProperty('firstName') ? overrides.firstName! : 'qui',
    homeApplicationsToMyHomes:
      overrides && overrides.hasOwnProperty('homeApplicationsToMyHomes')
        ? overrides.homeApplicationsToMyHomes!
        : [
            relationshipsToOmit.has('HomeApplication')
              ? ({} as HomeApplication)
              : aHomeApplication({}, relationshipsToOmit),
          ],
    homes:
      overrides && overrides.hasOwnProperty('homes')
        ? overrides.homes!
        : [relationshipsToOmit.has('Home') ? ({} as Home) : aHome({}, relationshipsToOmit)],
    householdMembersBirthYears:
      overrides && overrides.hasOwnProperty('householdMembersBirthYears')
        ? overrides.householdMembersBirthYears!
        : [7480],
    housingSituation:
      overrides && overrides.hasOwnProperty('housingSituation')
        ? overrides.housingSituation!
        : relationshipsToOmit.has('HousingSituation')
          ? ({} as HousingSituation)
          : aHousingSituation({}, relationshipsToOmit),
    idNumberConfirmed:
      overrides && overrides.hasOwnProperty('idNumberConfirmed') ? overrides.idNumberConfirmed! : true,
    identityConfirmed:
      overrides && overrides.hasOwnProperty('identityConfirmed') ? overrides.identityConfirmed! : true,
    identityConfirmedAt:
      overrides && overrides.hasOwnProperty('identityConfirmedAt')
        ? overrides.identityConfirmedAt!
        : 'sunt ea tempore aliquid quas eos in',
    incomeVerifiedAt:
      overrides && overrides.hasOwnProperty('incomeVerifiedAt')
        ? overrides.incomeVerifiedAt!
        : 'asperiores sed aut sint ut nam sit',
    landlord: overrides && overrides.hasOwnProperty('landlord') ? overrides.landlord! : false,
    landlordApplicationResponseRate:
      overrides && overrides.hasOwnProperty('landlordApplicationResponseRate')
        ? overrides.landlordApplicationResponseRate!
        : 4.06,
    landlordApplicationResponseTimeHours:
      overrides && overrides.hasOwnProperty('landlordApplicationResponseTimeHours')
        ? overrides.landlordApplicationResponseTimeHours!
        : 7655,
    locale: overrides && overrides.hasOwnProperty('locale') ? overrides.locale! : 'aut',
    mainPlatform:
      overrides && overrides.hasOwnProperty('mainPlatform') ? overrides.mainPlatform! : PlatformEnum.blocket,
    matchingProfile:
      overrides && overrides.hasOwnProperty('matchingProfile')
        ? overrides.matchingProfile!
        : relationshipsToOmit.has('UserMatchingProfile')
          ? ({} as UserMatchingProfile)
          : aUserMatchingProfile({}, relationshipsToOmit),
    movingReason: overrides && overrides.hasOwnProperty('movingReason') ? overrides.movingReason! : 'ipsam',
    notificationSpan:
      overrides && overrides.hasOwnProperty('notificationSpan')
        ? overrides.notificationSpan!
        : NotificationSpanEnum.EVERY_HOUR,
    nrFirsthandHousingUnits:
      overrides && overrides.hasOwnProperty('nrFirsthandHousingUnits')
        ? overrides.nrFirsthandHousingUnits!
        : NrFirsthandHousingUnitsEnum.option_1_24,
    occupations:
      overrides && overrides.hasOwnProperty('occupations')
        ? overrides.occupations!
        : [
            relationshipsToOmit.has('Occupation')
              ? ({} as Occupation)
              : anOccupation({}, relationshipsToOmit),
          ],
    pep: overrides && overrides.hasOwnProperty('pep') ? overrides.pep! : false,
    premium: overrides && overrides.hasOwnProperty('premium') ? overrides.premium! : false,
    private:
      overrides && overrides.hasOwnProperty('private')
        ? overrides.private!
        : relationshipsToOmit.has('UserPrivate')
          ? ({} as UserPrivate)
          : aUserPrivate({}, relationshipsToOmit),
    proAgent: overrides && overrides.hasOwnProperty('proAgent') ? overrides.proAgent! : true,
    proPilot: overrides && overrides.hasOwnProperty('proPilot') ? overrides.proPilot! : false,
    proUser: overrides && overrides.hasOwnProperty('proUser') ? overrides.proUser! : true,
    professional: overrides && overrides.hasOwnProperty('professional') ? overrides.professional! : true,
    profilePicture:
      overrides && overrides.hasOwnProperty('profilePicture')
        ? overrides.profilePicture!
        : relationshipsToOmit.has('Upload')
          ? ({} as Upload)
          : anUpload({}, relationshipsToOmit),
    prospectOnly: overrides && overrides.hasOwnProperty('prospectOnly') ? overrides.prospectOnly! : false,
    publishedHomes:
      overrides && overrides.hasOwnProperty('publishedHomes')
        ? overrides.publishedHomes!
        : [relationshipsToOmit.has('Home') ? ({} as Home) : aHome({}, relationshipsToOmit)],
    rca: overrides && overrides.hasOwnProperty('rca') ? overrides.rca! : true,
    referenceCheck:
      overrides && overrides.hasOwnProperty('referenceCheck') ? overrides.referenceCheck! : 'voluptates',
    removed: overrides && overrides.hasOwnProperty('removed') ? overrides.removed! : false,
    rentingOutFirsthandHomes:
      overrides && overrides.hasOwnProperty('rentingOutFirsthandHomes')
        ? overrides.rentingOutFirsthandHomes!
        : true,
    safeRental: overrides && overrides.hasOwnProperty('safeRental') ? overrides.safeRental! : false,
    seenAt:
      overrides && overrides.hasOwnProperty('seenAt')
        ? overrides.seenAt!
        : 'suscipit corrupti nemo a commodi atque reprehenderit',
    sendMonitoringEvents:
      overrides && overrides.hasOwnProperty('sendMonitoringEvents') ? overrides.sendMonitoringEvents! : false,
    showAge: overrides && overrides.hasOwnProperty('showAge') ? overrides.showAge! : false,
    smoker: overrides && overrides.hasOwnProperty('smoker') ? overrides.smoker! : true,
    studentVerification:
      overrides && overrides.hasOwnProperty('studentVerification')
        ? overrides.studentVerification!
        : relationshipsToOmit.has('StudentVerification')
          ? ({} as StudentVerification)
          : aStudentVerification({}, relationshipsToOmit),
    suspended: overrides && overrides.hasOwnProperty('suspended') ? overrides.suspended! : true,
    tenant: overrides && overrides.hasOwnProperty('tenant') ? overrides.tenant! : false,
    tenantAd:
      overrides && overrides.hasOwnProperty('tenantAd')
        ? overrides.tenantAd!
        : relationshipsToOmit.has('TenantAd')
          ? ({} as TenantAd)
          : aTenantAd({}, relationshipsToOmit),
    tenantProfileCompleteness:
      overrides && overrides.hasOwnProperty('tenantProfileCompleteness')
        ? overrides.tenantProfileCompleteness!
        : relationshipsToOmit.has('TenantProfileCompleteness')
          ? ({} as TenantProfileCompleteness)
          : aTenantProfileCompleteness({}, relationshipsToOmit),
    uid:
      overrides && overrides.hasOwnProperty('uid') ? overrides.uid! : 'a1706041-54b6-497b-869f-7c690a4ed356',
    updatedAt:
      overrides && overrides.hasOwnProperty('updatedAt')
        ? overrides.updatedAt!
        : 'facilis nam nemo provident et cupiditate consequatur',
    userNotes:
      overrides && overrides.hasOwnProperty('userNotes')
        ? overrides.userNotes!
        : [relationshipsToOmit.has('UserNote') ? ({} as UserNote) : aUserNote({}, relationshipsToOmit)],
  }
}

export const aUserAction = (
  overrides?: Partial<UserAction>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UserAction' } & UserAction => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UserAction')
  return {
    __typename: 'UserAction',
    actionable:
      overrides && overrides.hasOwnProperty('actionable')
        ? overrides.actionable!
        : relationshipsToOmit.has('StripeSepaTransferMoneyAction')
          ? ({} as StripeSepaTransferMoneyAction)
          : aStripeSepaTransferMoneyAction({}, relationshipsToOmit),
    completedAt:
      overrides && overrides.hasOwnProperty('completedAt')
        ? overrides.completedAt!
        : 'ut deleniti et et vel molestiae blanditiis',
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'ullam culpa quia et similique expedita saepe',
    dismissedAt:
      overrides && overrides.hasOwnProperty('dismissedAt')
        ? overrides.dismissedAt!
        : 'possimus ratione omnis omnis occaecati et minus',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '2b15c9d1-0168-455c-bb67-e569f238deb6',
    updatedAt:
      overrides && overrides.hasOwnProperty('updatedAt')
        ? overrides.updatedAt!
        : 'sit ut occaecati libero quo accusamus dolor',
  }
}

export const aUserApplicationStats = (
  overrides?: Partial<UserApplicationStats>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UserApplicationStats' } & UserApplicationStats => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UserApplicationStats')
  return {
    __typename: 'UserApplicationStats',
    count: overrides && overrides.hasOwnProperty('count') ? overrides.count! : 926,
    inContactCount:
      overrides && overrides.hasOwnProperty('inContactCount') ? overrides.inContactCount! : 1135,
    locales: overrides && overrides.hasOwnProperty('locales') ? overrides.locales! : ['nam'],
    priceMax: overrides && overrides.hasOwnProperty('priceMax') ? overrides.priceMax! : 7364,
    priceMin: overrides && overrides.hasOwnProperty('priceMin') ? overrides.priceMin! : 8617,
    roomMax: overrides && overrides.hasOwnProperty('roomMax') ? overrides.roomMax! : 652,
    roomMin: overrides && overrides.hasOwnProperty('roomMin') ? overrides.roomMin! : 9594,
    uid:
      overrides && overrides.hasOwnProperty('uid') ? overrides.uid! : '2a0376c9-5405-4c54-a549-90c4a756a539',
  }
}

export const aUserBio = (
  overrides?: Partial<UserBio>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UserBio' } & UserBio => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UserBio')
  return {
    __typename: 'UserBio',
    intro: overrides && overrides.hasOwnProperty('intro') ? overrides.intro! : 'necessitatibus',
    lived: overrides && overrides.hasOwnProperty('lived') ? overrides.lived! : 'at',
    pets: overrides && overrides.hasOwnProperty('pets') ? overrides.pets! : 'esse',
    spareTime: overrides && overrides.hasOwnProperty('spareTime') ? overrides.spareTime! : 'natus',
    study: overrides && overrides.hasOwnProperty('study') ? overrides.study! : 'modi',
    title: overrides && overrides.hasOwnProperty('title') ? overrides.title! : 'et',
    uid:
      overrides && overrides.hasOwnProperty('uid') ? overrides.uid! : '3c9e9583-bc98-4ade-80ef-19566a312653',
    where: overrides && overrides.hasOwnProperty('where') ? overrides.where! : 'veritatis',
    who: overrides && overrides.hasOwnProperty('who') ? overrides.who! : 'autem',
    work: overrides && overrides.hasOwnProperty('work') ? overrides.work! : 'temporibus',
  }
}

export const aUserConnection = (
  overrides?: Partial<UserConnection>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UserConnection' } & UserConnection => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UserConnection')
  return {
    __typename: 'UserConnection',
    edges:
      overrides && overrides.hasOwnProperty('edges')
        ? overrides.edges!
        : [relationshipsToOmit.has('UserEdge') ? ({} as UserEdge) : aUserEdge({}, relationshipsToOmit)],
    nodes:
      overrides && overrides.hasOwnProperty('nodes')
        ? overrides.nodes!
        : [relationshipsToOmit.has('User') ? ({} as User) : aUser({}, relationshipsToOmit)],
    pageInfo:
      overrides && overrides.hasOwnProperty('pageInfo')
        ? overrides.pageInfo!
        : relationshipsToOmit.has('PageInfo')
          ? ({} as PageInfo)
          : aPageInfo({}, relationshipsToOmit),
    totalCount: overrides && overrides.hasOwnProperty('totalCount') ? overrides.totalCount! : 3509,
  }
}

export const aUserContracts = (
  overrides?: Partial<UserContracts>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UserContracts' } & UserContracts => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UserContracts')
  return {
    __typename: 'UserContracts',
    activeContracts:
      overrides && overrides.hasOwnProperty('activeContracts')
        ? overrides.activeContracts!
        : relationshipsToOmit.has('ContractConnection')
          ? ({} as ContractConnection)
          : aContractConnection({}, relationshipsToOmit),
    draftContracts:
      overrides && overrides.hasOwnProperty('draftContracts')
        ? overrides.draftContracts!
        : relationshipsToOmit.has('ContractConnection')
          ? ({} as ContractConnection)
          : aContractConnection({}, relationshipsToOmit),
    pastContracts:
      overrides && overrides.hasOwnProperty('pastContracts')
        ? overrides.pastContracts!
        : relationshipsToOmit.has('ContractConnection')
          ? ({} as ContractConnection)
          : aContractConnection({}, relationshipsToOmit),
  }
}

export const aUserEdge = (
  overrides?: Partial<UserEdge>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UserEdge' } & UserEdge => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UserEdge')
  return {
    __typename: 'UserEdge',
    cursor: overrides && overrides.hasOwnProperty('cursor') ? overrides.cursor! : 'optio',
    node:
      overrides && overrides.hasOwnProperty('node')
        ? overrides.node!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
  }
}

export const aUserMatchingProfile = (
  overrides?: Partial<UserMatchingProfile>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UserMatchingProfile' } & UserMatchingProfile => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UserMatchingProfile')
  return {
    __typename: 'UserMatchingProfile',
    commuteLocation:
      overrides && overrides.hasOwnProperty('commuteLocation')
        ? overrides.commuteLocation!
        : relationshipsToOmit.has('Location')
          ? ({} as Location)
          : aLocation({}, relationshipsToOmit),
    corporateHome: overrides && overrides.hasOwnProperty('corporateHome') ? overrides.corporateHome! : true,
    firsthand: overrides && overrides.hasOwnProperty('firsthand') ? overrides.firsthand! : false,
    furniture: overrides && overrides.hasOwnProperty('furniture') ? overrides.furniture! : true,
    homeType:
      overrides && overrides.hasOwnProperty('homeType') ? overrides.homeType! : [HomeTypeEnum.apartment],
    householdSize: overrides && overrides.hasOwnProperty('householdSize') ? overrides.householdSize! : 364,
    matchingAreaMetadata:
      overrides && overrides.hasOwnProperty('matchingAreaMetadata')
        ? overrides.matchingAreaMetadata!
        : [
            relationshipsToOmit.has('MatchingAreaMetadata')
              ? ({} as MatchingAreaMetadata)
              : aMatchingAreaMetadata({}, relationshipsToOmit),
          ],
    maxMonthlyCost:
      overrides && overrides.hasOwnProperty('maxMonthlyCost') ? overrides.maxMonthlyCost! : 4131,
    maxRent: overrides && overrides.hasOwnProperty('maxRent') ? overrides.maxRent! : 8120,
    maxRentalLength:
      overrides && overrides.hasOwnProperty('maxRentalLength') ? overrides.maxRentalLength! : 3094,
    minRentalLength:
      overrides && overrides.hasOwnProperty('minRentalLength') ? overrides.minRentalLength! : 7762,
    minRoomCount: overrides && overrides.hasOwnProperty('minRoomCount') ? overrides.minRoomCount! : 3653,
    minSquareMeters:
      overrides && overrides.hasOwnProperty('minSquareMeters') ? overrides.minSquareMeters! : 2773,
    moveInEarliest:
      overrides && overrides.hasOwnProperty('moveInEarliest')
        ? overrides.moveInEarliest!
        : 'vel optio repellendus fugit voluptatum ad sit',
    pets: overrides && overrides.hasOwnProperty('pets') ? overrides.pets! : true,
    safeRental: overrides && overrides.hasOwnProperty('safeRental') ? overrides.safeRental! : true,
    seniorHome: overrides && overrides.hasOwnProperty('seniorHome') ? overrides.seniorHome! : false,
    sharedHomeOk: overrides && overrides.hasOwnProperty('sharedHomeOk') ? overrides.sharedHomeOk! : true,
    studentHome: overrides && overrides.hasOwnProperty('studentHome') ? overrides.studentHome! : false,
    uid:
      overrides && overrides.hasOwnProperty('uid') ? overrides.uid! : 'f045ae1b-e5dc-4c43-959e-aec2210d3eb8',
    wheelchairAccessible:
      overrides && overrides.hasOwnProperty('wheelchairAccessible') ? overrides.wheelchairAccessible! : false,
  }
}

export const aUserNote = (
  overrides?: Partial<UserNote>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UserNote' } & UserNote => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UserNote')
  return {
    __typename: 'UserNote',
    author:
      overrides && overrides.hasOwnProperty('author')
        ? overrides.author!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'non aut aliquid quisquam minima dolor dolore',
    homeApplication:
      overrides && overrides.hasOwnProperty('homeApplication')
        ? overrides.homeApplication!
        : relationshipsToOmit.has('HomeApplication')
          ? ({} as HomeApplication)
          : aHomeApplication({}, relationshipsToOmit),
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '3afd9f74-10e7-4590-8a5f-b185cdafc76a',
    note: overrides && overrides.hasOwnProperty('note') ? overrides.note! : 'ut',
    updatedAt:
      overrides && overrides.hasOwnProperty('updatedAt')
        ? overrides.updatedAt!
        : 'id tempore facere perspiciatis aut distinctio officiis',
  }
}

export const aUserPrivate = (
  overrides?: Partial<UserPrivate>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UserPrivate' } & UserPrivate => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UserPrivate')
  return {
    __typename: 'UserPrivate',
    activeBankAccount:
      overrides && overrides.hasOwnProperty('activeBankAccount')
        ? overrides.activeBankAccount!
        : relationshipsToOmit.has('BankAccount')
          ? ({} as BankAccount)
          : aBankAccount({}, relationshipsToOmit),
    amplitudeId: overrides && overrides.hasOwnProperty('amplitudeId') ? overrides.amplitudeId! : 'occaecati',
    bankAccounts:
      overrides && overrides.hasOwnProperty('bankAccounts')
        ? overrides.bankAccounts!
        : [
            relationshipsToOmit.has('BankAccount')
              ? ({} as BankAccount)
              : aBankAccount({}, relationshipsToOmit),
          ],
    biometricConsent:
      overrides && overrides.hasOwnProperty('biometricConsent') ? overrides.biometricConsent! : false,
    biometricConsentAt:
      overrides && overrides.hasOwnProperty('biometricConsentAt')
        ? overrides.biometricConsentAt!
        : 'sunt sint labore beatae aut asperiores sit',
    birthDate:
      overrides && overrides.hasOwnProperty('birthDate')
        ? overrides.birthDate!
        : 'quis magnam asperiores non vero fuga culpa',
    contactLocation:
      overrides && overrides.hasOwnProperty('contactLocation')
        ? overrides.contactLocation!
        : relationshipsToOmit.has('Location')
          ? ({} as Location)
          : aLocation({}, relationshipsToOmit),
    email: overrides && overrides.hasOwnProperty('email') ? overrides.email! : 'tempore',
    familyName: overrides && overrides.hasOwnProperty('familyName') ? overrides.familyName! : 'est',
    financeDepositInvoices:
      overrides && overrides.hasOwnProperty('financeDepositInvoices')
        ? overrides.financeDepositInvoices!
        : [
            relationshipsToOmit.has('FinanceDepositInvoice')
              ? ({} as FinanceDepositInvoice)
              : aFinanceDepositInvoice({}, relationshipsToOmit),
          ],
    financeInvoices:
      overrides && overrides.hasOwnProperty('financeInvoices')
        ? overrides.financeInvoices!
        : [
            relationshipsToOmit.has('InvoiceInterface')
              ? ({} as InvoiceInterface)
              : anInvoiceInterface({}, relationshipsToOmit),
          ],
    financePayouts:
      overrides && overrides.hasOwnProperty('financePayouts')
        ? overrides.financePayouts!
        : [
            relationshipsToOmit.has('FinancePayoutType')
              ? ({} as FinancePayoutType)
              : aFinancePayoutType({}, relationshipsToOmit),
          ],
    financeRentInvoices:
      overrides && overrides.hasOwnProperty('financeRentInvoices')
        ? overrides.financeRentInvoices!
        : [
            relationshipsToOmit.has('FinanceMonthlyRentInvoice')
              ? ({} as FinanceMonthlyRentInvoice)
              : aFinanceMonthlyRentInvoice({}, relationshipsToOmit),
          ],
    gender: overrides && overrides.hasOwnProperty('gender') ? overrides.gender! : GenderEnum.female,
    homeqUid: overrides && overrides.hasOwnProperty('homeqUid') ? overrides.homeqUid! : 'reprehenderit',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '9e4b22cf-34df-4ba2-9fd8-f2678b82ade3',
    idNumber: overrides && overrides.hasOwnProperty('idNumber') ? overrides.idNumber! : 'suscipit',
    identificationStatus:
      overrides && overrides.hasOwnProperty('identificationStatus')
        ? overrides.identificationStatus!
        : IdentificationStatusEnum.accepted,
    incomeVerification:
      overrides && overrides.hasOwnProperty('incomeVerification')
        ? overrides.incomeVerification!
        : relationshipsToOmit.has('IncomeVerification')
          ? ({} as IncomeVerification)
          : anIncomeVerification({}, relationshipsToOmit),
    invoiceAdsFrom:
      overrides && overrides.hasOwnProperty('invoiceAdsFrom')
        ? overrides.invoiceAdsFrom!
        : 'qui qui est quasi reprehenderit atque exercitationem',
    isOnfidoReverificationAvailable:
      overrides && overrides.hasOwnProperty('isOnfidoReverificationAvailable')
        ? overrides.isOnfidoReverificationAvailable!
        : false,
    orgNumber: overrides && overrides.hasOwnProperty('orgNumber') ? overrides.orgNumber! : 'aut',
    organisationNumberCountryCode:
      overrides && overrides.hasOwnProperty('organisationNumberCountryCode')
        ? overrides.organisationNumberCountryCode!
        : CountryCodeEnum.AD,
    organizations:
      overrides && overrides.hasOwnProperty('organizations')
        ? overrides.organizations!
        : [
            relationshipsToOmit.has('Organization')
              ? ({} as Organization)
              : anOrganization({}, relationshipsToOmit),
          ],
    passportUpload:
      overrides && overrides.hasOwnProperty('passportUpload')
        ? overrides.passportUpload!
        : relationshipsToOmit.has('Upload')
          ? ({} as Upload)
          : anUpload({}, relationshipsToOmit),
    phoneNumber: overrides && overrides.hasOwnProperty('phoneNumber') ? overrides.phoneNumber! : 'aut',
    requiredUserActions:
      overrides && overrides.hasOwnProperty('requiredUserActions')
        ? overrides.requiredUserActions!
        : [relationshipsToOmit.has('UserAction') ? ({} as UserAction) : aUserAction({}, relationshipsToOmit)],
    savingsVerification:
      overrides && overrides.hasOwnProperty('savingsVerification')
        ? overrides.savingsVerification!
        : relationshipsToOmit.has('SavingsVerification')
          ? ({} as SavingsVerification)
          : aSavingsVerification({}, relationshipsToOmit),
    schibstedAccountId:
      overrides && overrides.hasOwnProperty('schibstedAccountId')
        ? overrides.schibstedAccountId!
        : 'praesentium',
    selfieUpload:
      overrides && overrides.hasOwnProperty('selfieUpload')
        ? overrides.selfieUpload!
        : relationshipsToOmit.has('Upload')
          ? ({} as Upload)
          : anUpload({}, relationshipsToOmit),
    smoochParams:
      overrides && overrides.hasOwnProperty('smoochParams')
        ? overrides.smoochParams!
        : relationshipsToOmit.has('SmoochParams')
          ? ({} as SmoochParams)
          : aSmoochParams({}, relationshipsToOmit),
    statedBirthDate:
      overrides && overrides.hasOwnProperty('statedBirthDate')
        ? overrides.statedBirthDate!
        : 'quod perferendis nostrum aut labore voluptatem quo',
    stripeAccount:
      overrides && overrides.hasOwnProperty('stripeAccount')
        ? overrides.stripeAccount!
        : relationshipsToOmit.has('AccountResource')
          ? ({} as AccountResource)
          : anAccountResource({}, relationshipsToOmit),
    subUser:
      overrides && overrides.hasOwnProperty('subUser')
        ? overrides.subUser!
        : relationshipsToOmit.has('SubUser')
          ? ({} as SubUser)
          : aSubUser({}, relationshipsToOmit),
    taxReportMetadata:
      overrides && overrides.hasOwnProperty('taxReportMetadata')
        ? overrides.taxReportMetadata!
        : relationshipsToOmit.has('TaxReportMetadataType')
          ? ({} as TaxReportMetadataType)
          : aTaxReportMetadataType({}, relationshipsToOmit),
    taxReports:
      overrides && overrides.hasOwnProperty('taxReports')
        ? overrides.taxReports!
        : [
            relationshipsToOmit.has('TaxReportType')
              ? ({} as TaxReportType)
              : aTaxReportType({}, relationshipsToOmit),
          ],
    termsAccepted: overrides && overrides.hasOwnProperty('termsAccepted') ? overrides.termsAccepted! : false,
    termsAcceptedAt:
      overrides && overrides.hasOwnProperty('termsAcceptedAt')
        ? overrides.termsAcceptedAt!
        : 'ea ex dignissimos eaque est in nam',
    unreadConversationsCount:
      overrides && overrides.hasOwnProperty('unreadConversationsCount')
        ? overrides.unreadConversationsCount!
        : 134,
    verifiedAccess:
      overrides && overrides.hasOwnProperty('verifiedAccess') ? overrides.verifiedAccess! : false,
  }
}

export const aUserReport = (
  overrides?: Partial<UserReport>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UserReport' } & UserReport => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UserReport')
  return {
    __typename: 'UserReport',
    createdAt:
      overrides && overrides.hasOwnProperty('createdAt')
        ? overrides.createdAt!
        : 'magnam iure dolor officiis unde enim molestias',
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'ec1e8e49-adb0-4d65-b205-77681278cceb',
    message: overrides && overrides.hasOwnProperty('message') ? overrides.message! : 'adipisci',
    reportType:
      overrides && overrides.hasOwnProperty('reportType')
        ? overrides.reportType!
        : UserReportTypeEnum.absent_during_viewing,
    reportedUser:
      overrides && overrides.hasOwnProperty('reportedUser')
        ? overrides.reportedUser!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
    reportingUser:
      overrides && overrides.hasOwnProperty('reportingUser')
        ? overrides.reportingUser!
        : relationshipsToOmit.has('User')
          ? ({} as User)
          : aUser({}, relationshipsToOmit),
    updatedAt:
      overrides && overrides.hasOwnProperty('updatedAt')
        ? overrides.updatedAt!
        : 'sed numquam corrupti harum enim id rerum',
  }
}

export const aUserReportConnection = (
  overrides?: Partial<UserReportConnection>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UserReportConnection' } & UserReportConnection => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UserReportConnection')
  return {
    __typename: 'UserReportConnection',
    edges:
      overrides && overrides.hasOwnProperty('edges')
        ? overrides.edges!
        : [
            relationshipsToOmit.has('UserReportEdge')
              ? ({} as UserReportEdge)
              : aUserReportEdge({}, relationshipsToOmit),
          ],
    nodes:
      overrides && overrides.hasOwnProperty('nodes')
        ? overrides.nodes!
        : [relationshipsToOmit.has('UserReport') ? ({} as UserReport) : aUserReport({}, relationshipsToOmit)],
    pageInfo:
      overrides && overrides.hasOwnProperty('pageInfo')
        ? overrides.pageInfo!
        : relationshipsToOmit.has('PageInfo')
          ? ({} as PageInfo)
          : aPageInfo({}, relationshipsToOmit),
  }
}

export const aUserReportEdge = (
  overrides?: Partial<UserReportEdge>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'UserReportEdge' } & UserReportEdge => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UserReportEdge')
  return {
    __typename: 'UserReportEdge',
    cursor: overrides && overrides.hasOwnProperty('cursor') ? overrides.cursor! : 'eum',
    node:
      overrides && overrides.hasOwnProperty('node')
        ? overrides.node!
        : relationshipsToOmit.has('UserReport')
          ? ({} as UserReport)
          : aUserReport({}, relationshipsToOmit),
  }
}

export const aUsersSearchInput = (
  overrides?: Partial<UsersSearchInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): UsersSearchInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('UsersSearchInput')
  return {
    email: overrides && overrides.hasOwnProperty('email') ? overrides.email! : 'sit',
    phoneNumber: overrides && overrides.hasOwnProperty('phoneNumber') ? overrides.phoneNumber! : 'itaque',
    proAgent: overrides && overrides.hasOwnProperty('proAgent') ? overrides.proAgent! : false,
    schibstedAccountId:
      overrides && overrides.hasOwnProperty('schibstedAccountId') ? overrides.schibstedAccountId! : 'eius',
    uid: overrides && overrides.hasOwnProperty('uid') ? overrides.uid! : 'debitis',
  }
}

export const aValidation = (
  overrides?: Partial<Validation>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Validation' } & Validation => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Validation')
  return {
    __typename: 'Validation',
    codes: overrides && overrides.hasOwnProperty('codes') ? overrides.codes! : ['enim'],
    codesWithMessage:
      overrides && overrides.hasOwnProperty('codesWithMessage')
        ? overrides.codesWithMessage!
        : [
            relationshipsToOmit.has('CodesWithMessage')
              ? ({} as CodesWithMessage)
              : aCodesWithMessage({}, relationshipsToOmit),
          ],
    field: overrides && overrides.hasOwnProperty('field') ? overrides.field! : 'maxime',
  }
}

export const aValidationError = (
  overrides?: Partial<ValidationError>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ValidationError' } & ValidationError => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ValidationError')
  return {
    __typename: 'ValidationError',
    attributeErrors:
      overrides && overrides.hasOwnProperty('attributeErrors')
        ? overrides.attributeErrors!
        : [
            relationshipsToOmit.has('AttributeError')
              ? ({} as AttributeError)
              : anAttributeError({}, relationshipsToOmit),
          ],
    message: overrides && overrides.hasOwnProperty('message') ? overrides.message! : 'eius',
  }
}

export const aVerifyBankAccountPayload = (
  overrides?: Partial<VerifyBankAccountPayload>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'VerifyBankAccountPayload' } & VerifyBankAccountPayload => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('VerifyBankAccountPayload')
  return {
    __typename: 'VerifyBankAccountPayload',
    bankAccount:
      overrides && overrides.hasOwnProperty('bankAccount')
        ? overrides.bankAccount!
        : relationshipsToOmit.has('BankAccount')
          ? ({} as BankAccount)
          : aBankAccount({}, relationshipsToOmit),
  }
}

export const aViewing = (
  overrides?: Partial<Viewing>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'Viewing' } & Viewing => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('Viewing')
  return {
    __typename: 'Viewing',
    bookingStatus:
      overrides && overrides.hasOwnProperty('bookingStatus')
        ? overrides.bookingStatus!
        : ViewingStatusEnum.cancelled,
    cronofyBookingId:
      overrides && overrides.hasOwnProperty('cronofyBookingId') ? overrides.cronofyBookingId! : 'doloribus',
    endsAt:
      overrides && overrides.hasOwnProperty('endsAt')
        ? overrides.endsAt!
        : 'eligendi sed illo ipsam voluptatem similique tempore',
    homeApplication:
      overrides && overrides.hasOwnProperty('homeApplication')
        ? overrides.homeApplication!
        : relationshipsToOmit.has('HomeApplication')
          ? ({} as HomeApplication)
          : aHomeApplication({}, relationshipsToOmit),
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'aa34188e-f109-4db7-b37a-5d7ffc4d5480',
    startsAt:
      overrides && overrides.hasOwnProperty('startsAt')
        ? overrides.startsAt!
        : 'sunt voluptatem molestias sit placeat ad dolor',
  }
}

export const aViewingBooking = (
  overrides?: Partial<ViewingBooking>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ViewingBooking' } & ViewingBooking => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ViewingBooking')
  return {
    __typename: 'ViewingBooking',
    bookingId:
      overrides && overrides.hasOwnProperty('bookingId')
        ? overrides.bookingId!
        : '03fb99f3-02ee-4bf5-a312-9429f3977d7b',
    endTime:
      overrides && overrides.hasOwnProperty('endTime')
        ? overrides.endTime!
        : 'voluptate quae excepturi debitis labore ipsum et',
    startTime:
      overrides && overrides.hasOwnProperty('startTime')
        ? overrides.startTime!
        : 'laudantium earum eveniet beatae quia eligendi ullam',
  }
}

export const aViewingCalendar = (
  overrides?: Partial<ViewingCalendar>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ViewingCalendar' } & ViewingCalendar => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ViewingCalendar')
  return {
    __typename: 'ViewingCalendar',
    bookings:
      overrides && overrides.hasOwnProperty('bookings')
        ? overrides.bookings!
        : [
            relationshipsToOmit.has('ViewingBooking')
              ? ({} as ViewingBooking)
              : aViewingBooking({}, relationshipsToOmit),
          ],
    cronofyId: overrides && overrides.hasOwnProperty('cronofyId') ? overrides.cronofyId! : 'dolorem',
    cronofySub: overrides && overrides.hasOwnProperty('cronofySub') ? overrides.cronofySub! : 'asperiores',
    home:
      overrides && overrides.hasOwnProperty('home')
        ? overrides.home!
        : relationshipsToOmit.has('Home')
          ? ({} as Home)
          : aHome({}, relationshipsToOmit),
    id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '4cd4f84f-779d-458e-a3a6-4362534cb6f5',
    information: overrides && overrides.hasOwnProperty('information') ? overrides.information! : 'corporis',
    schedule:
      overrides && overrides.hasOwnProperty('schedule')
        ? overrides.schedule!
        : [
            relationshipsToOmit.has('ViewingBooking')
              ? ({} as ViewingBooking)
              : aViewingBooking({}, relationshipsToOmit),
          ],
    slots:
      overrides && overrides.hasOwnProperty('slots')
        ? overrides.slots!
        : [
            relationshipsToOmit.has('ViewingSlot')
              ? ({} as ViewingSlot)
              : aViewingSlot({}, relationshipsToOmit),
          ],
    viewings:
      overrides && overrides.hasOwnProperty('viewings')
        ? overrides.viewings!
        : [relationshipsToOmit.has('Viewing') ? ({} as Viewing) : aViewing({}, relationshipsToOmit)],
  }
}

export const aViewingSlot = (
  overrides?: Partial<ViewingSlot>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ViewingSlot' } & ViewingSlot => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ViewingSlot')
  return {
    __typename: 'ViewingSlot',
    endTime:
      overrides && overrides.hasOwnProperty('endTime')
        ? overrides.endTime!
        : 'nam delectus voluptatem tenetur qui ut accusamus',
    slotId:
      overrides && overrides.hasOwnProperty('slotId')
        ? overrides.slotId!
        : 'd0c121f5-e8a2-4349-aa8f-4046f28ed232',
    startTime:
      overrides && overrides.hasOwnProperty('startTime')
        ? overrides.startTime!
        : 'non omnis sequi incidunt iste ducimus sequi',
  }
}

export const aViewingSlotConnection = (
  overrides?: Partial<ViewingSlotConnection>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ViewingSlotConnection' } & ViewingSlotConnection => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ViewingSlotConnection')
  return {
    __typename: 'ViewingSlotConnection',
    edges:
      overrides && overrides.hasOwnProperty('edges')
        ? overrides.edges!
        : [
            relationshipsToOmit.has('ViewingSlotEdge')
              ? ({} as ViewingSlotEdge)
              : aViewingSlotEdge({}, relationshipsToOmit),
          ],
    nodes:
      overrides && overrides.hasOwnProperty('nodes')
        ? overrides.nodes!
        : [
            relationshipsToOmit.has('ViewingSlot')
              ? ({} as ViewingSlot)
              : aViewingSlot({}, relationshipsToOmit),
          ],
    pageInfo:
      overrides && overrides.hasOwnProperty('pageInfo')
        ? overrides.pageInfo!
        : relationshipsToOmit.has('PageInfo')
          ? ({} as PageInfo)
          : aPageInfo({}, relationshipsToOmit),
  }
}

export const aViewingSlotEdge = (
  overrides?: Partial<ViewingSlotEdge>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'ViewingSlotEdge' } & ViewingSlotEdge => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ViewingSlotEdge')
  return {
    __typename: 'ViewingSlotEdge',
    cursor: overrides && overrides.hasOwnProperty('cursor') ? overrides.cursor! : 'animi',
    node:
      overrides && overrides.hasOwnProperty('node')
        ? overrides.node!
        : relationshipsToOmit.has('ViewingSlot')
          ? ({} as ViewingSlot)
          : aViewingSlot({}, relationshipsToOmit),
  }
}

export const aViewingSlotInput = (
  overrides?: Partial<ViewingSlotInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): ViewingSlotInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('ViewingSlotInput')
  return {
    endTime:
      overrides && overrides.hasOwnProperty('endTime')
        ? overrides.endTime!
        : 'commodi id non illum totam ullam facere',
    slotId:
      overrides && overrides.hasOwnProperty('slotId')
        ? overrides.slotId!
        : '803b168d-f932-424c-b4c5-fbb9979af619',
    startTime:
      overrides && overrides.hasOwnProperty('startTime')
        ? overrides.startTime!
        : 'minima voluptatem quo repellendus mollitia similique et',
  }
}

export const aWithinDistanceFromInput = (
  overrides?: Partial<WithinDistanceFromInput>,
  _relationshipsToOmit: Set<string> = new Set(),
): WithinDistanceFromInput => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('WithinDistanceFromInput')
  return {
    dist: overrides && overrides.hasOwnProperty('dist') ? overrides.dist! : 2.16,
    lat: overrides && overrides.hasOwnProperty('lat') ? overrides.lat! : 6.43,
    lng: overrides && overrides.hasOwnProperty('lng') ? overrides.lng! : 2.59,
  }
}

export const aYearlyIncomeStatementsType = (
  overrides?: Partial<YearlyIncomeStatementsType>,
  _relationshipsToOmit: Set<string> = new Set(),
): { __typename: 'YearlyIncomeStatementsType' } & YearlyIncomeStatementsType => {
  const relationshipsToOmit: Set<string> = new Set(_relationshipsToOmit)
  relationshipsToOmit.add('YearlyIncomeStatementsType')
  return {
    __typename: 'YearlyIncomeStatementsType',
    amount: overrides && overrides.hasOwnProperty('amount') ? overrides.amount! : 7.91,
    year: overrides && overrides.hasOwnProperty('year') ? overrides.year! : 'non',
  }
}
