import { EditDialog } from '@qasa/app/src/modules/edit-dialog'
import { useTranslation } from 'react-i18next'
import type { ReactNode } from 'react'
import { useState } from 'react'
import type { UpsertRentalRequirementInput } from '@qasa/graphql'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { Paragraph } from '@qasa/qds-ui'

import { mutations } from '../../data/edit-listing'
import { ListingSectionEnum, useListingContext } from '../../context/listing-context'
import type { UpdateHomeInput } from '../../data/graphql/types/__generated__/globalTypes'
import type {
  UpdateHomeMutation,
  UpdateHomeMutationVariables,
} from '../../data/graphql/types/__generated__/UpdateHomeMutation'
import type {
  UpsertRentalRequirement,
  UpsertRentalRequirementVariables,
} from '../../data/graphql/types/__generated__/UpsertRentalRequirement'
import { useUserContext } from '../../context/user-context'
import type {
  UpdateShortcut,
  UpdateShortcutVariables,
} from '../../data/graphql/types/__generated__/UpdateShortcut'

import { useEditListingInput } from './use-edit-listing-input'

export type FormValues = UpdateHomeInput & { rentalRequirements: UpsertRentalRequirementInput } & {
  homePitch?: string | null
}

type ListingSectionTypes =
  | 'rental_details'
  | 'home_images'
  | 'description'
  | 'amenities'
  | 'building_details'
  | 'building_location'
  | 'building_description'
  | 'lease_details'

type UpdateHomeDialogProps = {
  children: ReactNode
}
export function UpdateHomeDialog({ children }: UpdateHomeDialogProps) {
  const { listingId } = useParams<{ listingId: string }>()
  const { isEditDialogOpen, setIsEditDialogOpen, activeEditSection, home } = useListingContext()
  const { listingInput, rentalRequirements, homePitch } = useEditListingInput()
  const { t } = useTranslation('edit_listing')
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const { authBody } = useUserContext()
  const isProAgent = authBody?.isProAgent
  const { shortcut } = home || {}

  const methods = useForm<FormValues>({
    defaultValues: {
      ...listingInput,
      rentalRequirements,
      homePitch,
    },
    mode: 'onChange',
  })

  const [editListing, { loading: isLoading }] = useMutation<UpdateHomeMutation, UpdateHomeMutationVariables>(
    mutations.EDIT_LISTING,
    {
      variables: {
        updateHomeId: listingId!,
        updateHomeInput: listingInput,
      },
      onCompleted: (data) => {
        if (data.updateHome?.errors) {
          setErrorMessage(t('errors.save_failed_check_data'))
        }
        onEditComplete(data)
      },
      onError: (e) => {
        setErrorMessage(t('errors.save_failed_check_data'))
        // eslint-disable-next-line no-console
        console.error(e)
      },
      refetchQueries: ['ListingQuery'],
    },
  )

  const onEditComplete = (data: UpdateHomeMutation) => {
    if (data.updateHome?.errors) {
      const errors = data.updateHome.errors.reduce((acc, error) => {
        Object(acc)[error.field] = error.codes
        return acc
      }, {})
      // eslint-disable-next-line no-console
      console.error(errors)
    } else {
      setIsEditDialogOpen(false)
    }
  }

  const [upsertRentalRequirement, { loading: isLoadingRentalRequirement }] = useMutation<
    UpsertRentalRequirement,
    UpsertRentalRequirementVariables
  >(mutations.UPSERT_RENTAL_REQUIREMENT, {
    variables: {
      homeId: listingId!,
      rentalRequirementInput: rentalRequirements,
    },
  })

  const [updateShortcut, { loading: isLoadingUpdateShortcut }] = useMutation<
    UpdateShortcut,
    UpdateShortcutVariables
  >(mutations.UPDATE_SHORTCUT, {
    onError: (e) => {
      setErrorMessage(t('errors.save_failed_check_data'))
      // eslint-disable-next-line no-console
      console.error(e)
    },
  })

  const onSubmit = (formValues: FormValues) => {
    const { rentalRequirements, homePitch, ...otherFormValues } = formValues
    setErrorMessage(null)

    Promise.all([
      upsertRentalRequirement({
        variables: {
          homeId: listingId!,
          rentalRequirementInput: rentalRequirements,
        },
      }),
      ...(isProAgent && activeEditSection === ListingSectionEnum.DESCRIPTION && homePitch
        ? [
            updateShortcut({
              variables: { updateShortcutId: shortcut?.id ?? '', input: { homePitch } },
            }),
          ]
        : []),
      editListing({
        variables: {
          updateHomeId: listingId!,
          updateHomeInput: otherFormValues,
        },
      }),
    ])
  }

  return (
    <EditDialog
      isOpen={isEditDialogOpen}
      onOpenChange={setIsEditDialogOpen}
      title={t(`edit_sections.${activeEditSection?.toLowerCase() as ListingSectionTypes}`)}
      formMethods={methods}
      formId="edit-listing-form"
      onSubmit={onSubmit}
      isLoading={isLoadingRentalRequirement || isLoading || isLoadingUpdateShortcut}
    >
      <>
        {children}
        {errorMessage && <Paragraph color="negative">{errorMessage}</Paragraph>}
      </>
    </EditDialog>
  )
}
