import { useLocation, useNavigate, Routes, Route, Navigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { useQuery } from '@apollo/client'
import { useState, useEffect } from 'react'
import { Spacer, Heading, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { queries } from '../data/applications'
import { DrawerPrimitive } from '../ui-atoms/drawer'
import { TableTopSection } from '../ui-atoms/top-section'
import { DrawerProvider } from '../context/drawer-context'
import { queries as listingQueries } from '../data/listing'
import { getLastUrlSegment } from '../util-functions/general.util'
import { Segment, SegmentControl } from '../ui-atoms/segment-control'
import type {
  GetTotalDeclinedCountQuery,
  GetTotalDeclinedCountQueryVariables,
} from '../data/graphql/types/__generated__/GetTotalDeclinedCountQuery'
import type {
  GetTotalApplicationsCountQuery,
  GetTotalApplicationsCountQueryVariables,
} from '../data/graphql/types/__generated__/GetTotalApplicationsCountQuery'
import { ListingsBar } from '../ui-feature-modules/applications/listings/listings-bar'
import { ApplicationsBoard } from '../ui-feature-modules/applications/board/applications-board'
import type { ListingLocationQuery } from '../data/graphql/types/__generated__/ListingLocationQuery'
import { RemovedList } from '../ui-feature-modules/applications/removed/removed-list'
import { WhiteBoxWrapper } from '../ui-shared/wrapper'
import { ApplicationsProvider } from '../context/applications-context'
import { NoListingSelected } from '../ui-feature-modules/applications/no-listing-selected'

import { Applicant } from './applicant'

const Wrapper = styled.div({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  overflowY: 'hidden',
})
const ContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  overflowX: 'scroll',
  flexGrow: 1,
})

const LightHeading = styled(Heading)(({ theme }) => ({
  fontWeight: theme.typography.body.md.fontWeight,
}))

const InteractiveHeading = styled(LightHeading)({
  cursor: 'pointer',
  position: 'sticky',
  left: 0,
})

const Content = styled(WhiteBoxWrapper)({
  overflow: 'hidden',
})

export function Applications() {
  const { t } = useTranslation(['applications', 'commons'])
  const navigate = useNavigate()
  const location = useLocation()
  const [currentListing, setCurrentListing] = useState<string | undefined>(undefined)
  const [currentApplicant, setCurrentApplicant] = useState<string | undefined>(undefined)
  const [isOpen, setIsOpen] = useState(false)
  const listingParams = location.search

  const {
    loading: isLoadingApplicationsCount,
    error: applicationsCountError,
    data: applicationsCountData,
  } = useQuery<GetTotalApplicationsCountQuery, GetTotalApplicationsCountQueryVariables>(
    queries.GET_TOTAL_APPLICATIONS_COUNT,
    {
      fetchPolicy: 'no-cache',
      variables: {
        homeApplicationsHomeId: currentListing !== undefined ? [currentListing] : null,
      },
    },
  )

  const {
    loading: isLoadingDeclinedCount,
    error: declinedCountError,
    data: declinedCountData,
  } = useQuery<GetTotalDeclinedCountQuery, GetTotalDeclinedCountQueryVariables>(
    queries.GET_TOTAL_DECLINED_COUNT,
    {
      fetchPolicy: 'no-cache',
      variables: {
        homeApplicationsHomeId: currentListing !== undefined ? [currentListing] : null,
      },
    },
  )

  const handleListingClick = (id: string | undefined) => {
    navigate(location.pathname + '?listing=' + id)
  }

  const handleClose = () => {
    navigate({
      search: `?listing=${currentListing}`,
    })
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const hasListing = params.get('listing') ? true : false
    const hasApplicant = params.get('applicant') ? true : false
    setCurrentListing(hasListing ? params.get('listing')?.toString() : undefined)

    setCurrentApplicant(hasApplicant ? params.get('applicant')?.toString() : undefined)
    setIsOpen(hasApplicant)
  }, [location])

  const listingId = currentListing ? currentListing : ''
  const {
    loading: isLoadingListingLocation,
    error: listingLocationError,
    data: listingLocationData,
  } = useQuery<ListingLocationQuery>(listingQueries.GET_LISTING_LOCATION, {
    variables: { listingId },
    skip: !currentListing,
  })

  //const [isScrollBottom, setScrollBottom] = useState<boolean>(false)

  const redirectToListing = () => {
    const params = new URLSearchParams(location.search)
    navigate(`/listings/${params.get('listing')}`)
  }

  if (listingLocationError) {
    // eslint-disable-next-line no-console
    console.log(listingLocationError)
  }
  if (applicationsCountError) {
    // eslint-disable-next-line no-console
    console.log(applicationsCountError)
  }
  if (declinedCountError) {
    // eslint-disable-next-line no-console
    console.log(declinedCountError)
  }

  if (getLastUrlSegment() === 'applications') {
    return <Navigate to="/applications/requests" replace />
  }

  const declinedCount = isLoadingDeclinedCount
    ? 'laddar'
    : declinedCountData?.homeApplications.totalCount || '0'
  const applicationsCount = isLoadingApplicationsCount
    ? 'laddar'
    : applicationsCountData?.homeApplications.totalCount || '0'

  return (
    <>
      <Wrapper>
        <Stack gap="6x">
          <Heading as="h1" size="md">
            {t('title')}
          </Heading>
          <ListingsBar currentListing={currentListing} setCurrentListing={handleListingClick} />
        </Stack>
        <Spacer axis="x" size="6x" />
        {currentListing ? (
          <ContentWrapper>
            <TableTopSection>
              {!isLoadingListingLocation ? (
                <InteractiveHeading size="sm" onClick={() => redirectToListing()}>
                  {listingLocationData?.home?.location?.route}{' '}
                  {listingLocationData?.home?.location?.streetNumber}{' '}
                  {listingLocationData?.home?.apartmentNumber &&
                    `(${listingLocationData?.home?.apartmentNumber})`}
                </InteractiveHeading>
              ) : (
                <LightHeading size="sm">{t('commons:loading')}</LightHeading>
              )}
            </TableTopSection>
            <Spacer size="6x" />
            <Content>
              <TableTopSection>
                <SegmentControl>
                  <Segment
                    id={`requests${listingParams}`}
                    name={t('applications_board.tabs.requested')}
                    count={applicationsCount}
                  />

                  <Segment
                    id={`rejected${listingParams}`}
                    name={t('applications_board.tabs.rejected')}
                    count={declinedCount}
                  />
                </SegmentControl>
              </TableTopSection>
              <Spacer size="6x" />
              <ApplicationsProvider>
                <Routes>
                  <Route path={'requests'} element={<ApplicationsBoard currentListing={currentListing} />} />
                  <Route path={'rejected'} element={<RemovedList />} />
                </Routes>
              </ApplicationsProvider>
            </Content>
          </ContentWrapper>
        ) : (
          <NoListingSelected />
        )}
      </Wrapper>
      <DrawerProvider>
        <DrawerPrimitive onClose={() => handleClose()} {...{ isOpen }}>
          {currentApplicant && <Applicant id={currentApplicant} />}
        </DrawerPrimitive>
      </DrawerProvider>
    </>
  )
}
