import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Avatar, Paragraph, Stack } from '@qasa/qds-ui'

import type { UnreadConversationsQuery_conversations_edges_node } from '../../../data/graphql/types/__generated__/UnreadConversationsQuery'
import { getTimeAgo } from '../../../util-functions/date.util'
import { LastMessage } from '../../applications/board/last-message'
import { ApplicantTab } from '../../../context/applicant-context'

type MessageProps = {
  conversation?: UnreadConversationsQuery_conversations_edges_node
}

const FullWidthLink = styled(Link)({
  width: '100%',
})
const Wrapper = styled(Stack)(({ theme }) => ({
  position: 'relative',
  height: theme.sizes['20x'],
  padding: theme.spacing['3x'],
  cursor: 'pointer',
  borderBottom: `1px solid ${theme.colors.border.default}`,
  ':hover': {
    backgroundColor: theme.colors.core.gray20,
  },
}))

const SentDate = styled(Paragraph)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing['3x'],
  right: theme.spacing['3x'],
}))

export function Message({ conversation }: MessageProps) {
  const { t } = useTranslation(['unread_applications', 'commons'])

  if (!conversation) return null

  const { homeApplication, messages } = conversation
  if (!homeApplication) return null

  const applicantName = homeApplication.tenant.firstName || null
  const homeRoute = homeApplication.home.location?.route
  const homeStreetNumber = homeApplication.home.location?.streetNumber
  const homeApartmentNumber = homeApplication.home.apartmentNumber
  const listingId = homeApplication.home.id
  const applicantId = homeApplication.id

  const formattedAddress = homeApartmentNumber
    ? `${homeRoute} ${homeStreetNumber}, ${t('apartment_number', { apartmentNumber: homeApartmentNumber })}`
    : `${homeRoute} ${homeStreetNumber}`

  const messagesLink = `/applications/requests?listing=${listingId}&applicant=${applicantId}&tab=${ApplicantTab.Conversation}`

  return (
    <FullWidthLink to={messagesLink}>
      <Wrapper direction="row" gap="2x" alignItems="center">
        <Avatar src={homeApplication.tenant.profilePicture?.url} />
        <Stack>
          <LastMessage
            tenantName={applicantName || ''}
            lastMessage={messages.nodes[0]}
            isUnread={!conversation.read}
            messageSize="large"
          />
          <Paragraph size="sm" numberOfLines={1}>
            {formattedAddress}
          </Paragraph>
        </Stack>
        {messages.nodes[0]?.createdAt && (
          <SentDate size="xs" color="subtle">
            {getTimeAgo(new Date(messages.nodes[0].createdAt), 'short')}
          </SentDate>
        )}
      </Wrapper>
    </FullWidthLink>
  )
}
