import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ApolloClient, ApolloLink, concat, createHttpLink, InMemoryCache } from '@apollo/client'
import { ApolloProvider } from '@apollo/client/react'
import { relayStylePagination } from '@apollo/client/utilities'
import { Suspense } from 'react'

import { App } from './App'
import { buildingsPagination, generalPagination, tablePagination } from './data/pagination'
import './i18n'
import { Loading } from './ui-atoms/loading'
import { getAccessToken } from './context/user-context'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_SERVER_URL + 'graphql',
  credentials: 'include',
})

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      'Access-Token': getAccessToken() || null,
    },
  })
  return forward(operation)
})

const cache = new InMemoryCache({
  typePolicies: {
    Home: {
      merge: true,
      fields: {
        location: { merge: true },
      },
    },
    Location: { merge: true },
    HomeApplication: {
      merge: true,
      fields: {
        tenant: { merge: true },
      },
    },
    UserNotes: { merge: true },
    Query: {
      fields: {
        homes: relayStylePagination([
          'homeStatuses',
          'searchString',
          'onlyApplicationDeadlinePassed',
          'excludeApplicationDeadlinePassed',
        ]),
        buildings: buildingsPagination(),
        homeTemplates: relayStylePagination(),
        homeApplications: relayStylePagination(['status', 'homeId']),
        homeApplicationsByBoardList: relayStylePagination(['id']),
        sentOfferings: tablePagination(),
        conversations: relayStylePagination(),
        me: generalPagination(),
      },
    },
  },
})

const client = new ApolloClient({
  cache,
  link: concat(authMiddleware, httpLink),
})

const rootContainer = document.getElementById('root')

if (rootContainer) {
  const root = createRoot(rootContainer!)
  root.render(
    <Suspense fallback={<Loading />}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ApolloProvider>
    </Suspense>,
  )
}
