import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import type { UpdateHomeInput } from '../../data/graphql/types/__generated__/globalTypes'
import { InformationBox } from '../../ui-atoms/information-box'
import { Input } from '../../ui-atoms/input'
import { Stack } from '../../ui-shared/_core/stack'
import { Typography } from '../../ui-shared/_core/typography'

const SyledInformationBox = styled(InformationBox)({
  width: 'fit-content',
})

type Props = {
  form: UpdateHomeInput
  errors?: Record<string, string[]>
  onInputChange: (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void
}

// TODO: note that this is a duplicate of the old edit-listing-form renovation-section - it should be removed when we
// can refactor the apartment-form to use react-hook-form as well (that was the only reason it was split up into two components)
export function RenovationSection({ form, errors, onInputChange }: Props) {
  const { t } = useTranslation(['apartment', 'edit_listing'])

  return (
    <Stack gap={'medium'}>
      <Typography variant={'heading3'}>
        {t('edit_listing:additional_listing_info.renovation_label')}
      </Typography>
      <SyledInformationBox>
        {t('edit_listing:additional_listing_info.renovation_description')}
      </SyledInformationBox>
      <Typography variant={'subhead1'}>{t('apartment_form.latest_kitchen_renovation_label')}</Typography>

      <Input
        name="kitchenRenovationYear"
        type="number"
        min={0}
        errorMessage={
          errors?.kitchen_renovation_year
            ? t('edit_listing:errors.renovation_error_message', { buildYear: form.buildYear })
            : undefined
        }
        value={form.kitchenRenovationYear}
        placeholder={t('apartment_form.renovation_placeholder')}
        onChange={onInputChange}
        width={'400px'}
        optional
      />

      <Typography variant={'subhead1'}>{t('apartment_form.latest_bath_renovation_label')}</Typography>

      <Input
        name="bathroomRenovationYear"
        type="number"
        min={0}
        errorMessage={
          errors?.bathroom_renovation_year
            ? t('edit_listing:errors.renovation_error_message', { buildYear: form.buildYear })
            : undefined
        }
        value={form.bathroomRenovationYear}
        placeholder={t('apartment_form.renovation_placeholder')}
        onChange={onInputChange}
        width={'400px'}
        optional
      />
    </Stack>
  )
}
