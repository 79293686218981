import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import type { CSSObject } from 'styled-components/macro'
import styled, { keyframes, css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { palette } from '../styling/colors'
import { spaces } from '../styling/defaults'
import { useDrawerContext } from '../context/drawer-context'

import { Spacer } from './help-components'

export const DRAWER_ANIMATION_TIME_MS = 300
const DESKTOP_WIDTH = 600

export const animateInDesktop = keyframes({
  from: {
    left: '100vw',
  },
  to: {
    left: `calc(100vw - ${DESKTOP_WIDTH}px)`,
  },
})
export const animateOutDesktop = keyframes({
  from: {
    left: `calc(100vw - ${DESKTOP_WIDTH}px)`,
  },
  to: {
    left: '100vw',
  },
})

type DrawerContainerProps = {
  isClosing: boolean
}

const DrawerContainer = styled.div<DrawerContainerProps>(
  {
    width: DESKTOP_WIDTH,
    background: palette.white,
    maxWidth: '600px',
    height: '100%',
    bottom: 0,
    right: 0,
    left: `calc(100vw - ${DESKTOP_WIDTH}px)`,

    overflowY: 'hidden',
    overflowX: 'hidden',
    zIndex: 999,
    //borderRadius: `${dafaults.borderRadius}px 0 0 ${theme.borderRadiuses.default}px`,
    position: 'fixed',
  },
  // Keyframes can't be added with object notation at this time, so that's why this is done via the css function. See https://spectrum.chat/styled-components/help/how-can-i-use-a-custom-animation-with-the-style-object-syntax~91d624ea-a5a3-4d0e-9314-4da860059112 // Anton Lejon 2021-11-01
  ({ isClosing }) => {
    const animationDesktop = isClosing ? animateOutDesktop : animateInDesktop
    return css`
      animation: ${animationDesktop} ${DRAWER_ANIMATION_TIME_MS}ms ease-out forwards;
    `
  },
)

const getOverriddenStyle = ({ backdropBackgroundColor }: { backdropBackgroundColor?: string }) => {
  const contentReset: CSSObject = {
    position: 'relative',
    top: 'initial',
    bottom: 'initial',
    left: 'initial',
    right: 'initial',
    WebkitOverflowScrolling: 'auto',
  }

  const content: CSSObject = {
    background: 'transparent',
    borderRadius: 'inherit',
    border: 'none',
    padding: 0,
    ...contentReset,
  }
  const overlay: CSSObject = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: backdropBackgroundColor ? backdropBackgroundColor : 'rgba(0, 0, 0, 0.25)',
    zIndex: 11,
  }
  return { content, overlay }
}
const StickyHeader = styled.div({
  background: palette.white,
  position: 'sticky',
  top: 0,
  marginLeft: -spaces.medium,
  marginRight: -spaces.medium,
  paddingBottom: spaces.small,
  paddingTop: spaces.small,
  borderColor: palette.blocketBlack,
  zIndex: 10,
})

const CloseButton = styled(FontAwesomeIcon)({
  position: 'absolute',
  left: spaces.medium,
  marginTop: 4,
  marginLeft: 24,
})

// The conditional is here to not mess up with Jest tests
if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#root')

type Props = {
  isOpen: boolean
  onClose: () => void
  backdropBackgroundColor?: string
  className?: string
  children: ReactNode
  isCloseButtonVisible?: boolean
}

export function DrawerPrimitive({
  isOpen,
  onClose,
  isCloseButtonVisible = true,
  backdropBackgroundColor,
  children,
  className,
}: Props) {
  //const { isMobile } = useViewportContext()
  const overriddenStyle = getOverriddenStyle({ backdropBackgroundColor })
  const handleClose = () => {
    setIsDrawerClosing(true)
    setIsClosing(true)
    setTimeout(() => {
      onClose()
    }, DRAWER_ANIMATION_TIME_MS)
  }
  const [isClosing, setIsClosing] = useState(false)
  const { setIsDrawerClosing } = useDrawerContext()
  useEffect(() => {
    if (isOpen) {
      setIsDrawerClosing(false)
      setIsClosing(false)
    }
  }, [isOpen, setIsClosing, setIsDrawerClosing])
  return (
    <ReactModal
      onAfterOpen={() => {
        const body = document.body
        body.style.overflow = 'hidden'
      }}
      onAfterClose={() => {
        const body = document.body
        const isAnyModalOpen = document.body.classList.contains('ReactModal__Body--open')
        if (!isAnyModalOpen) {
          body.style.overflow = 'unset'
        }
      }}
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={overriddenStyle}
      contentLabel="Modal"
    >
      <DrawerContainer isClosing={isClosing}>
        <StickyHeader>
          {isCloseButtonVisible && <CloseButton icon={['fas', 'times']} size="sm" onClick={handleClose} />}
        </StickyHeader>
        <Spacer factor={2} />
        {children}
      </DrawerContainer>
    </ReactModal>
  )
}
