import styled from '@emotion/styled'
import { Stack } from '@qasa/qds-ui'

export const WhiteBoxWrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.colors.bg.default,
  border: `1px solid ${theme.colors.border.default}`,
  borderRadius: theme.radii.lg,
  padding: `${theme.spacing['6x']} ${theme.spacing['5x']}`,
  height: '100%',
  overflow: 'scroll',
}))
