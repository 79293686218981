import styled from 'styled-components/macro'
import snakeCase from 'lodash/snakeCase'

import type {
  ProfilePreferredAmenitiesEnum,
  ProfileRequiredAmenitiesEnum,
} from '../../../types/profile.types'
import type { IconNames } from '../../../ui-shared/_core/icon'
import { getTraits } from '../../../util-functions/traits.util'
import type { TraitTypeEnum } from '../../../data/graphql/types/__generated__/globalTypes'

import { SearchPreferenceListItem } from './search-preference-list-item'

type Props = {
  preferredAmenities: (ProfilePreferredAmenitiesEnum | ProfileRequiredAmenitiesEnum)[]
}

const AmenityGrid = styled.ul({
  listStyle: 'none',
  margin: 0,
  padding: 0,
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 16,
})

const PROFILE_AMENITY_ICONS: Partial<
  Record<ProfilePreferredAmenitiesEnum | ProfileRequiredAmenitiesEnum, IconNames>
> = {
  balcony: 'balcony',
  storage: 'checkmark',
  dishWasher: 'dishwasher',
  washingMachine: 'washingMachine',
  inhomeSauna: 'sauna',
  wheelchairAccessible: 'wheelchair',
  pets: 'pets',
  smoker: 'smoking',
}

export function OverviewPreferredAmenities({ preferredAmenities }: Props) {
  return (
    <AmenityGrid>
      {Object.values(preferredAmenities).map((preference) => (
        <SearchPreferenceListItem
          key={preference}
          value={getTraits(snakeCase(preference) as TraitTypeEnum)}
          icon={PROFILE_AMENITY_ICONS[preference] || 'checkmark'}
        />
      ))}
    </AmenityGrid>
  )
}
