import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

import { Input } from '../../ui-atoms/input'

import { InputHeader, InputRow } from './input-grid-styles'

export function BuildingDetailsSection() {
  const { t } = useTranslation(['building', 'edit_listing', 'commons'])
  const { control } = useFormContext()

  return (
    <InputRow>
      <InputHeader size="2xs">{t('building_form.construction_year')}</InputHeader>
      <Controller
        name="buildYear"
        control={control}
        render={({ field: { onChange, value, name } }) => {
          return (
            <Input
              name={name}
              type="number"
              placeholder={t('building_form.construction_year_placeholder')}
              onChange={(event) => {
                onChange(event.target.value !== '' ? Number(event.target.value) : null)
              }}
              value={value}
              optional
            />
          )
        }}
      />
      <InputHeader size="2xs">{t('building_form.energy_class')}</InputHeader>
      <Controller
        name="energyClass"
        control={control}
        render={({ field: { onChange, value, name } }) => {
          return (
            <Input
              name={name}
              type="text"
              placeholder={t('building_form.energy_class_placeholder')}
              onChange={(event) => onChange(event.target.value)}
              value={value}
              optional
            />
          )
        }}
      />
    </InputRow>
  )
}
