import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { IconButton, Paragraph, Stack, createIcon, Button, Textarea } from '@qasa/qds-ui'
import { Dialog } from '@qasa/app/src/components/dialog'

import { mutations } from '../../../data/applications-by-board-list'
import { useApplicationsContext } from '../../../context/applications-context'
import { ToolTip } from '../../../ui-atoms/tooltip'

const MessageIcon = createIcon({
  displayName: 'MessageIcon',
  viewBox: '0 0 512 512',
  d: 'M123.6 391.3c12.9-9.4 29.6-11.8 44.6-6.4c26.5 9.6 56.2 15.1 87.8 15.1c124.7 0 208-80.5 208-160s-83.3-160-208-160S48 160.5 48 240c0 32 12.4 62.8 35.7 89.2c8.6 9.7 12.8 22.5 11.8 35.5c-1.4 18.1-5.7 34.7-11.3 49.4c17-7.9 31.1-16.7 39.4-22.7zM21.2 431.9c1.8-2.7 3.5-5.4 5.1-8.1c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208s-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6c-15.1 6.6-32.3 12.6-50.1 16.1c-.8 .2-1.6 .3-2.4 .5c-4.4 .8-8.7 1.5-13.2 1.9c-.2 0-.5 .1-.7 .1c-5.1 .5-10.2 .8-15.3 .8c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c4.1-4.2 7.8-8.7 11.3-13.5c1.7-2.3 3.3-4.6 4.8-6.9c.1-.2 .2-.3 .3-.5z',
})

type MassMessageProps = {
  isInContact: boolean
  inContactColumn: string
  isHeaderActionsActive: boolean
}

export function MassMessage({ isInContact, inContactColumn, isHeaderActionsActive }: MassMessageProps) {
  const [messageInput, setMessageInput] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const { selectedApplicantIds, selectedConversationIds, resetColumnSelection } = useApplicationsContext()

  const { t } = useTranslation(['applications', 'commons'])

  const [sendMassMessage, { loading: isLoading }] = useMutation(mutations.SEND_MASS_MESSAGE, {
    refetchQueries: ['HomeApplicationsByBoardList'],
  })

  const [addHomeApplicationToBoardList] = useMutation(mutations.ADD_HOME_APPLICATIONS_TO_BOARD_LIST, {
    refetchQueries: ['HomeApplicationsByBoardList'],
  })

  const handleSendMassMessage = () => {
    sendMassMessage({ variables: { conversationIds: selectedConversationIds, message: messageInput } })
    if (!isInContact) {
      addHomeApplicationToBoardList({
        variables: { applicationBoardListId: inContactColumn, homeApplicationIds: selectedApplicantIds },
      })
    }
    resetColumnSelection()

    setIsOpen(false)
  }

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessageInput(e.target.value)
  }

  const handleAbort = () => {
    setMessageInput('')
    setIsOpen(false)
  }
  const inContactInstructions = t('mass_message.send_messages', {
    numOfApplicants: selectedApplicantIds.length,
  })
  const pendingInstructions = t('mass_message.send_and_move_messages', {
    numOfApplicants: selectedApplicantIds.length,
  })

  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={(isOpen) => setIsOpen(isOpen)}
      size="sm"
      trigger={
        <ToolTip content={t('column_header.message')} isDisabled={!isHeaderActionsActive}>
          <IconButton
            icon={MessageIcon}
            label={t('column_header.message')}
            onClick={() => setIsOpen(true)}
            size="xs"
          />
        </ToolTip>
      }
    >
      <Dialog.Body>
        <Stack gap="4x">
          <Dialog.Header>
            <Dialog.Title>{t('column_header.message')}</Dialog.Title>
          </Dialog.Header>
          <Paragraph>{isInContact ? inContactInstructions : pendingInstructions}</Paragraph>
          <Textarea label="" name="mass-message" onChange={handleChange} value={messageInput} />
        </Stack>
      </Dialog.Body>
      <Dialog.Footer>
        <Stack gap="2x" direction={'row'} justifyContent={'center'}>
          <Button variant="tertiary" onClick={handleAbort}>
            {t('commons:ctas.cancel')}
          </Button>
          <Button
            variant="primary"
            isLoading={isLoading}
            disabled={messageInput === '' || isLoading}
            onClick={handleSendMassMessage}
          >
            {t('commons:ctas.send')}
          </Button>
        </Stack>
      </Dialog.Footer>
    </Dialog>
  )
}
