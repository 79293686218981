import gql from 'graphql-tag'

const ADD_BUILDING = gql`
  mutation UpsertBuildingMutation($upsertBuildingId: ID, $upsertBuildingInput: UpsertBuildingInput!) {
    upsertBuilding(id: $upsertBuildingId, input: $upsertBuildingInput) {
      building {
        id
      }
      errors {
        codes
        field
      }
    }
  }
`

const mutations = { ADD_BUILDING }

export { mutations }
