import { useMutation } from '@apollo/client'

import type {
  UpdateUploadMutation,
  UpdateUploadMutationVariables,
} from '../../data/graphql/types/__generated__/UpdateUploadMutation'

import { UPDATE_UPLOAD } from './use-optimistic-image-upload.gql'

export const useOptimisticUpdateOfHomeUpload = () => {
  const [updateUpload] = useMutation<UpdateUploadMutation, UpdateUploadMutationVariables>(UPDATE_UPLOAD)

  const updateFunction = ({ variables }: { variables: UpdateUploadMutationVariables }) => {
    const {
      id,
      input: { metadata },
    } = variables

    return updateUpload({
      variables: {
        id,
        input: { metadata },
      },
      refetchQueries: ['ListingQuery'],
      optimisticResponse: {
        updateUpload: {
          errors: null,
          upload: {
            id,
            __typename: 'Upload',
            metadata: {
              __typename: 'UploadMetadata',
              order: metadata?.order ?? null,
              primary: null,
              rotation: null,
            },
          },
          __typename: 'UpdateUploadPayload',
        },
      },
    })
  }
  return updateFunction
}
