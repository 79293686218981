import { createContext, useContext, useState } from 'react'
import type { ReactNode } from 'react'
import { useQuery } from '@apollo/client'

import { CONTRACT } from '../data/contract'
import type {
  ContractQueryVariables,
  ContractQuery_contract,
} from '../data/graphql/types/__generated__/ContractQuery'
import { Loader } from '../ui-shared/_core/loader'
import type {
  KickoffHomeQuery,
  KickoffHomeQueryVariables,
} from '../data/graphql/types/__generated__/KickoffHomeQuery'
import { KICKOFF_HOME } from '../data/kickoff-home'
import {
  HomeApplicationOrderEnum,
  HomeApplicationStatusEnum,
} from '../data/graphql/types/__generated__/globalTypes'

type ContractContextProps = {
  tenantUid: string
  homeId: string
  contractId: string | undefined
  children: ReactNode
}

export enum ContractPage {
  NO_CONTRACT = 'NO_CONTRACT',
  KICKOFF = 'KICKOFF',
  PREVIEW = 'PREVIEW',
  EDIT = 'EDIT',
}

type ContractActions = {
  tenantUid: string
  currentPage: ContractPage
  contract?: ContractQuery_contract
  openContract: (page: ContractPage) => void
  kickOffData?: KickoffHomeQuery
  isKickOffDataLoading: boolean
  refetchContract: (variables?: Partial<ContractQueryVariables>) => void
}

const ContractContext = createContext<ContractActions>({
  tenantUid: '',
  currentPage: ContractPage.NO_CONTRACT,
  openContract: () => undefined,
  kickOffData: undefined,
  isKickOffDataLoading: false,
  refetchContract: () => undefined,
})

export function ContractProvider({ homeId, contractId, children, tenantUid }: ContractContextProps) {
  const [currentPage, setCurrentPage] = useState(contractId ? ContractPage.PREVIEW : ContractPage.NO_CONTRACT)
  const {
    data: contractData,
    loading: isContractLoading,
    refetch,
  } = useQuery(CONTRACT, {
    variables: { id: contractId ?? '' },
    skip: contractId === undefined,
  })

  const { data: kickOffData, loading: isKickOffDataLoading } = useQuery<
    KickoffHomeQuery,
    KickoffHomeQueryVariables
  >(KICKOFF_HOME, {
    variables: {
      id: homeId,
      home_id: [homeId],
      status: [HomeApplicationStatusEnum.in_contact],
      orderBy: HomeApplicationOrderEnum.NEWEST_FIRST,
    },
    skip: contractId !== undefined,
    onError: (e) => {
      // eslint-disable-next-line no-console
      console.error(e)
    },
  })

  const contract = contractData?.contract

  return (
    <ContractContext.Provider
      value={{
        tenantUid,
        currentPage,
        contract,
        openContract: (page: ContractPage) => {
          setCurrentPage(page)
        },
        kickOffData,
        isKickOffDataLoading,
        refetchContract: refetch,
      }}
    >
      {isContractLoading ? <Loader /> : children}
    </ContractContext.Provider>
  )
}

export const useContractContext = () => useContext(ContractContext)
