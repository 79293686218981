import { RadioCard, RadioGroup } from '@qasa/app/src/components/radio-group'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ContractSmallRepairsEnum } from '../../../data/graphql/types/__generated__/globalTypes'

export function SmallRepairsRadioGroup() {
  const { t } = useTranslation('edit_listing')
  const { control } = useFormContext()
  return (
    <Controller
      name="contractTemplateAttributes.smallRepairs"
      control={control}
      render={({ field: { onChange, value, name } }) => {
        return (
          <RadioGroup
            label={t('lease_details.small_repairs_label')}
            name={name}
            value={value}
            onChange={(newValue: string) => onChange(newValue)}
          >
            <RadioCard
              value={ContractSmallRepairsEnum.small_repairs_landlord}
              label={t('lease_details.small_repairs_landlord')}
            />
            <RadioCard
              value={ContractSmallRepairsEnum.small_repairs_tenant}
              label={t('lease_details.small_repairs_tenant')}
            />
          </RadioGroup>
        )
      }}
    />
  )
}
