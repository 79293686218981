import gql from 'graphql-tag'

export const BUMP_LISTING = gql`
  mutation BumpListingMutation($homeId: ID!) {
    bumpListing(homeId: $homeId) {
      home {
        id
      }
    }
  }
`
