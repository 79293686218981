import type {
  ListingQuery_home,
  ListingQuery_home_property,
} from '../data/graphql/types/__generated__/ListingQuery'
import { ContractTypesEnum } from '../types/contract-types'

type IsBlockListingProps = {
  property?: ListingQuery_home_property | null
  numberOfHomes: ListingQuery_home['numberOfHomes']
}

export function getIsBlockListing({ property, numberOfHomes }: IsBlockListingProps) {
  return property
    ? Boolean(property.numberOfHomes && property.numberOfHomes > 1)
    : Boolean(numberOfHomes && numberOfHomes > 1)
}

type ContractTypeProps = Pick<ListingQuery_home, 'seniorHome' | 'firsthand' | 'studentHome' | 'corporateHome'>

export const getContractType = ({ seniorHome, firsthand, studentHome, corporateHome }: ContractTypeProps) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention

  if (firsthand) return ContractTypesEnum.firsthand
  if (seniorHome) return ContractTypesEnum.seniorHome
  if (studentHome) return ContractTypesEnum.studentHome
  if (corporateHome) return ContractTypesEnum.corporateHome

  return ContractTypesEnum.secondhand
}
