import type { IconName, IconPrefix } from '@fortawesome/fontawesome-common-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components/macro'

import { fontSize, palette, pixelAmount } from '../../styling'

type ApplicantBadgeProps = {
  title: string
  faIcon: IconName
  iconPrefix?: IconPrefix
  iconColor?: string
  margin?: string | undefined
}

const BadgeWrapper = styled.div({})

const BadgeTitle = styled.div({
  marginLeft: pixelAmount.xl,
  color: palette.textPrimary,
  fontWeight: 400,
  fontSize: fontSize.textSm,
})

export function ApplicantBadge(props: ApplicantBadgeProps) {
  const { title, faIcon, iconColor, iconPrefix } = props
  return (
    <BadgeWrapper>
      <FontAwesomeIcon
        icon={[iconPrefix || 'fal', faIcon]}
        pull="left"
        style={{ color: iconColor || palette.blue500 }}
      />
      <BadgeTitle>{title}</BadgeTitle>
    </BadgeWrapper>
  )
}
