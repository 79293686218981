import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpBackend from 'i18next-http-backend'

import type amenities from '../public/locales/sources/amenities.i18n.json'
import type add_listing from '../public/locales/sources/add_listing.i18n.json'
import type apartment from '../public/locales/sources/apartment.i18n.json'
import type applicant from '../public/locales/sources/applicant.i18n.json'
import type applications from '../public/locales/sources/applications.i18n.json'
import type building from '../public/locales/sources/building.i18n.json'
import type commons from '../public/locales/sources/commons.i18n.json'
import type edit_listing from '../public/locales/sources/edit_listing.i18n.json'
import type listing from '../public/locales/sources/listing.i18n.json'
import type listings from '../public/locales/sources/listings.i18n.json'
import type login from '../public/locales/sources/login.i18n.json'
import type miscellaneous from '../public/locales/sources/miscellaneous.i18n.json'
import type my_homes from '../public/locales/sources/my_homes.i18n.json'
import type navbar from '../public/locales/sources/navbar.i18n.json'
import type overview from '../public/locales/sources/overview.i18n.json'
import type settings from '../public/locales/sources/settings.i18n.json'
import type system_messages from '../public/locales/sources/system_messages.i18n.json'
import type page_not_found from '../public/locales/sources/page_not_found.i18n.json'
import type password from '../public/locales/sources/password.i18n.json'
import type unread_applications from '../public/locales/sources/unread_applications.i18n.json'

type I18nNamespaces = {
  amenities: typeof amenities
  add_listing: typeof add_listing
  apartment: typeof apartment
  applicant: typeof applicant
  applications: typeof applications
  building: typeof building
  commons: typeof commons
  edit_listing: typeof edit_listing
  listing: typeof listing
  listings: typeof listings
  login: typeof login
  miscellaneous: typeof miscellaneous
  my_homes: typeof my_homes
  navbar: typeof navbar
  overview: typeof overview
  settings: typeof settings
  system_messages: typeof system_messages
  page_not_found: typeof page_not_found
  password: typeof password
  unread_applications: typeof unread_applications
}

declare module 'i18next' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface CustomTypeOptions {
    resources: I18nNamespaces
    returnNull: false
  }
}

const loadPath = (lngs: string[], namespaces: string[]) => {
  // Using af as the fallback language if some translation is missing
  if (lngs[0] === 'af') {
    return `/locales/sources/${namespaces[0]}.i18n.json`
  }
  return '/locales/__generated__/{{lng}}/{{ns}}.json'
}

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    returnNull: false,
    lng: 'sv-SE',
    fallbackLng: 'af',
    debug: process.env.NODE_ENV === 'development' ? true : false,
    partialBundledLanguages: true,
    ns: ['commons', 'amenities'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      loadPath,
    },
  })
