import { Fragment } from 'react'

import { isStringEmpty } from '../../../../../utils/string'
import { FormattedText } from '../formatted-text'
import { InlineLink } from '../../../../../components/inline-link'

import { EmptyLine } from './empty-line'

export const getTextWithRenderedLinks = ({ text }: { text: string }) => {
  const urlPattern =
    // Ok to disable this lint rule since it's only run on the client
    // eslint-disable-next-line security/detect-unsafe-regex
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gi

  const words = text.split(' ')
  const formattedText = words.map((word, i) => {
    const isLink = urlPattern.test(word.trim())
    const href = isLink && !word.startsWith('http') ? `https://${word}` : word
    if (isLink) {
      return (
        <Fragment key={i}>
          {'\n'}
          <InlineLink href={href}>{href}</InlineLink>
          {'\n'}
        </Fragment>
      )
    }
    // eslint-disable-next-line react-native/no-raw-text
    return <Fragment key={i}>{` ${word}`}</Fragment>
  })

  return formattedText
}

export function getDangerousDescriptionFirstHandOnly({ description }: { description: string }) {
  const lines = description.split('\n')
  const formattedLines = lines.map((line, i) => {
    if (isStringEmpty(line)) {
      return <EmptyLine key={i} />
    }
    return (
      <FormattedText size="lg" key={i}>
        {getTextWithRenderedLinks({ text: line })}
      </FormattedText>
    )
  })

  return formattedLines
}
