import styled from 'styled-components/macro'
import type { CSSObject, DefaultTheme, StyledComponent, StyledComponentProps } from 'styled-components/macro'
import type { LinkProps } from 'react-router-dom'
import { Link as RouterLink } from 'react-router-dom'
import { forwardRef } from 'react'

import { brandTheme } from '../../ui-styles/brand-themes'
import { baseTheme } from '../../ui-styles/base'

import { LoadingDots } from './loader'

const LoadingWrapper = styled.div({
  position: 'relative',
})
const HiddenChildrenWrapper = styled.div({
  visibility: 'hidden',
})

type ButtonProps = {
  size: 'major' | 'medium' | 'minor' | 'inherit'
  variant: 'filled' | 'important' | 'money' | 'outlined' | 'outlined-colored' | 'text'
  hasSharedWidth: boolean
  isLoading: boolean
  isWrapping: boolean
  disabled: boolean
}

const baseStyle: CSSObject = {
  font: 'inherit',
  color: brandTheme.colors.text.default,
  backgroundColor: 'transparent',
  borderRadius: 0,
  border: 'none',
  padding: 0,
  cursor: 'pointer',
  fontSize: baseTheme.fontSizes.default,
  textDecoration: 'none',
  display: 'block',
  ':disabled': {
    cursor: 'not-allowed',
  },
}

const getStyles = ({
  theme,
  size = 'major',
  variant = 'filled',
  disabled = false,
  isLoading = false,
  hasSharedWidth = false,
  isWrapping = false,
}: Partial<ButtonProps> & { theme: DefaultTheme }): CSSObject => ({
  ...baseStyle,
  border: '1px solid',
  borderRadius: theme.borderRadiuses.small,
  fontWeight: theme.fontWeights.semibold,
  cursor: isLoading ? 'progress' : disabled ? 'not-allowed' : 'pointer',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  paddingLeft: 10,
  paddingRight: 10,
  ...(isWrapping && {
    textAlign: 'left',
    whiteSpace: 'inherit',
  }),
  ...(hasSharedWidth ? { width: '50%' } : { minWidth: 78 }),
  ...(hasSharedWidth && {
    ':first-child': {
      marginRight: 4,
    },
    ':last-child': {
      marginLeft: 4,
    },
  }),
  ...(size === 'major' && {
    lineHeight: '46px',
  }),
  ...(size === 'medium' && {
    lineHeight: '38px',
  }),
  ...(size === 'minor' && {
    fontSize: variant === 'text' ? theme.fontSizes[14] : theme.fontSizes[16],
    lineHeight: '30px',
    paddingLeft: 20,
    paddingRight: 20,
  }),
  ...(size === 'inherit' && {
    fontSize: 'inherit',
  }),
  ...(variant === 'outlined' && {
    color: theme.colors.text.default,
    //TODO: refactor borderColors to match instead of using palette value
    borderColor: theme.colors.text.default,
  }),
  ...(variant === 'outlined-colored' && {
    color: theme.colors.buttons.secondary.text,
  }),
  ...(variant === 'filled' && {
    backgroundColor: theme.colors.buttons.default.fill,
    borderColor: theme.colors.buttons.default.fill,
    color: theme.colors.buttons.default.text,
  }),
  ...(variant === 'important' && {
    backgroundColor: theme.colors.buttons.important.fill,
    borderColor: theme.colors.buttons.important.fill,
    color: theme.colors.buttons.important.text,
  }),
  ...(variant === 'money' && {
    backgroundColor: theme.colors.buttons.money.fill,
    borderColor: theme.colors.buttons.money.fill,
    color: theme.colors.buttons.money.text,
  }),
  ...(variant === 'text' && {
    color: theme.colors.text.default,
    border: 'none',
    lineHeight: 'inherit',
    fontWeight: theme.fontWeights.normal,
    paddingLeft: 0,
    paddingRight: 0,
    minWidth: 0,
    display: 'inline-block',
    textDecoration: 'underline',
    textUnderlineOffset: 1,
    textAlign: 'initial',
    whiteSpace: 'initial',
  }),
  ...(disabled &&
    !isLoading && {
      background: variant !== 'text' ? theme.disabledButton.backgroundColor : 'unset',
      borderColor: theme.disabledButton.borderColor,
      color: theme.disabledButton.color,
    }),
})

type StyledLinkProps = Partial<LinkProps & ButtonProps> & {
  href?: string
}

const linkBuilder = styled(({ href, to, hasSharedWidth, isLoading, disabled, ...rest }: StyledLinkProps) => {
  if (disabled) {
    return <a {...rest}>{rest.children}</a>
  } else if (to) {
    return <RouterLink to={to} {...rest} />
  } else {
    return (
      <a href={href} {...rest}>
        {rest.children}
      </a>
    )
  }
})

export const Link = linkBuilder(getStyles)

const Loader = styled(LoadingDots)({
  fontSize: 20,
  color: 'currentcolor',
})

const StyledButton = styled.button.withConfig({
  shouldForwardProp: (prop, defaultValidationFunction) =>
    !['theme', 'size', 'variant', 'hasSharedWidth', 'isWrapping', 'isLoading'].includes(prop) &&
    defaultValidationFunction(prop),
})<ButtonProps>(getStyles) as StyledComponent<'button', LegitimateAny, Partial<ButtonProps>, never>

type ButtonComponentProps = StyledComponentProps<'button', LegitimateAny, Partial<ButtonProps>, never>
const ButtonComponent = forwardRef<HTMLButtonElement, ButtonComponentProps>(
  ({ isLoading, children, disabled, variant, ...rest }, forwardedRef) => {
    const content = isLoading ? (
      <LoadingWrapper>
        <HiddenChildrenWrapper>{children}</HiddenChildrenWrapper>
        <Loader
          variant={variant}
          css={{ position: 'absolute', top: 0, left: '50%', transform: 'translateX(-50%)' }}
        />
      </LoadingWrapper>
    ) : (
      children
    )

    return (
      <StyledButton
        ref={forwardedRef}
        disabled={disabled || isLoading}
        isLoading={isLoading}
        variant={variant}
        {...rest}
      >
        {content}
      </StyledButton>
    )
  },
)

/**
 * @deprecated Use `Button` from `@qasa/qds-ui` instead
 */
export const Button = ButtonComponent
