import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { defaultStyles } from '../../styling/defaults'
import { VerticalSpacer } from '../../ui-atoms/help-components/spacer'
import { getPixelAmount } from '../../styling/pixel-amount'

type ListingStatusProps = {
  isListed: boolean
}

const Wrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const StatusDot = styled.div<Partial<ListingStatusProps>>(({ isListed }) => ({
  width: getPixelAmount(2),
  height: getPixelAmount(2),
  borderRadius: defaultStyles.borderRadiusRound,
  backgroundColor: isListed ? 'green' : 'gray',
}))

const StatusText = styled.div({})

export function ListingStatus(props: ListingStatusProps) {
  const { t } = useTranslation('apartment', {
    keyPrefix: 'listing_status',
  })
  return (
    <Wrapper>
      <StatusDot isListed={props.isListed} />
      <VerticalSpacer factor={1} />
      <StatusText>{props.isListed ? t('listed') : t('not_listed')}</StatusText>
    </Wrapper>
  )
}
