import styled from 'styled-components/macro'

/**
 * @deprecated Use `Spacer` from `@qasa/qds-ui` instead
 */
export const Spacer = styled.div<{ factor: number }>(({ factor }) => ({
  marginTop: factor * 8,
}))

/**
 * @deprecated Use `Spacer` from `@qasa/qds-ui` instead
 */
export const VerticalSpacer = styled.div<{ factor: number }>(({ factor }) => ({
  width: 8 * factor + 'px',
}))
