import { baseTheme } from './base'
import type { BaseTheme } from './base'
import { brandTheme } from './brand-themes'
import type { BrandTheme } from './brand-themes/index.types'

export type CurrentTheme = BrandTheme & BaseTheme

export const currentTheme = {
  ...brandTheme,
  ...baseTheme,
}
