import { Suspense } from 'react'
import { LoadingDots } from '@qasa/qds-ui'

import { ListingSectionEnum, useListingContext } from '../../context/listing-context'

import { HomeAmenities } from './home-amenities'
import { RentalDetailsSection } from './rental-details-section'
import { BuildingDetailsSection } from './building-details-section'
import { DescriptionSection } from './description-section'
import { HomeDetails } from './home-details'
import { UpdateHomeDialog } from './update-home-dialog'
import { UpdateImagesDialog } from './update-images-dialog'
import { BuildingDescriptionSection } from './building-description-section'
import { BuildingLocationSection } from './building-location-section'
import { LeaseDetailsSection } from './lease-details-section'

export function EditListing() {
  const { activeEditSection, isBlockListing } = useListingContext()

  if (activeEditSection === ListingSectionEnum.HOME_IMAGES) {
    return <UpdateImagesDialog />
  }

  const renderEditElement = () => {
    switch (activeEditSection) {
      case ListingSectionEnum.RENTAL_DETAILS:
        return <RentalDetailsSection />
      case ListingSectionEnum.APARTMENT_DETAILS:
        return <HomeDetails isBlockListing={isBlockListing} />
      case ListingSectionEnum.DESCRIPTION:
        return <DescriptionSection isBlockListing={isBlockListing} />
      case ListingSectionEnum.AMENITIES:
        return <HomeAmenities />
      case ListingSectionEnum.BUILDING_DETAILS:
        return <BuildingDetailsSection />
      case ListingSectionEnum.BUILDING_DESCRIPTION:
        return <BuildingDescriptionSection />
      case ListingSectionEnum.BUILDING_LOCATION:
        return <BuildingLocationSection />
      case ListingSectionEnum.LEASE_DETAILS:
        return <LeaseDetailsSection />
      default:
        return null
    }
  }
  return (
    <UpdateHomeDialog>
      <Suspense fallback={<LoadingDots />}>{renderEditElement()}</Suspense>
    </UpdateHomeDialog>
  )
}
