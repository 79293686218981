import styled from '@emotion/styled'
import { Paragraph, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import type { TFuncKey } from 'i18next'
import debounce from 'lodash/debounce'

import { Reset } from '../filters/reset'
import { Checkbox } from '../../ui-atoms/checkbox'
import { DualSlider } from '../../ui-atoms/dual-slider'
import { ButtonsContainer } from '../filters/buttons-container'
import type { DualSliderChangeEvent } from '../../ui-atoms/dual-slider'
import { TenantVerificationEnum } from '../../data/graphql/types/__generated__/globalTypes'

export type IncomeRange = { min: number; max: number }

type ApplicationListFiltersProps = {
  reset: () => void
  values: TenantVerificationEnum[]
  onChange: (value: TenantVerificationEnum[]) => void
  onRangeChange: (incomeRange: IncomeRange) => void
  incomeRange?: IncomeRange
}

type CheckFields = {
  name: TenantVerificationEnum
  label: string
  translatedLabel: TFuncKey<'applications'>
}[]

const checkFields: CheckFields = [
  {
    name: TenantVerificationEnum.identity_verified,
    label: 'Verified ID',
    translatedLabel: 'verifications_filter.verified_id',
  },
  {
    name: TenantVerificationEnum.income_verified,
    label: 'Verified income',
    translatedLabel: 'verifications_filter.verified_income',
  },
  {
    name: TenantVerificationEnum.credit_check_verified,
    label: 'No payment remarks',
    translatedLabel: 'verifications_filter.no_payment_remarks',
  },
]

const FilterCategoryLabel = styled(Paragraph)({
  fontWeight: 600,
})
const FilterToggles = Stack
const IncomeRangeWrapper = Stack

const SLIDER_STEP_SIZE = 500

export function ApplicationListFilters({
  reset,
  values,
  onChange,
  onRangeChange,
  incomeRange,
}: ApplicationListFiltersProps) {
  const { t } = useTranslation(['applications', 'commons'])

  const handleCheckBoxClick = (name: TenantVerificationEnum) => {
    if (values.includes(name)) {
      onChange(values.filter((filterName) => filterName !== name))
    } else {
      onChange([...values, name])
    }
  }

  const debouncedIncomeUpdate = debounce((incomeRange: IncomeRange) => {
    onRangeChange(incomeRange)
  }, 250)

  const handleSliderChange = (event: DualSliderChangeEvent) => {
    if (event.type === 'min') {
      debouncedIncomeUpdate({
        min: Math.ceil(event.value / SLIDER_STEP_SIZE) * SLIDER_STEP_SIZE,
        max: incomeRange?.max ?? 100_000,
      })
    } else {
      debouncedIncomeUpdate({
        min: incomeRange?.min ?? 0,
        max: Math.ceil(event.value / SLIDER_STEP_SIZE) * SLIDER_STEP_SIZE,
      })
    }
  }

  const handleRangeInputChange = (value: string | number | null | undefined, type: 'min' | 'max') => {
    if (value) {
      let numberValue = typeof value === 'string' ? parseInt(value, 10) : value
      if (type === 'min') {
        if (numberValue < 0) {
          numberValue = 0
        }
        debouncedIncomeUpdate({
          min: numberValue,
          max: incomeRange?.max ?? 100_000,
        })
      } else {
        if (numberValue > 100_000) {
          numberValue = 100_000
        }
        debouncedIncomeUpdate({
          min: incomeRange?.min ?? 0,
          max: numberValue,
        })
      }
    }
  }

  return (
    <Stack gap="3x">
      <Paragraph color="subtle">{t('commons:filter')}</Paragraph>
      <FilterToggles gap="2x">
        {checkFields.map((field) => {
          const { name, translatedLabel } = field
          return (
            <Checkbox
              key={name}
              name={name}
              type="thumb"
              label={t(translatedLabel) as string}
              isChecked={values.includes(name)}
              onClick={() => handleCheckBoxClick(name)}
            />
          )
        })}
      </FilterToggles>
      {values.includes(TenantVerificationEnum.income_verified) && (
        <IncomeRangeWrapper gap="1x">
          <FilterCategoryLabel size="sm">{t('verifications_filter.income')}</FilterCategoryLabel>
          <DualSlider
            minName="minIncome"
            maxName="maxIncome"
            range={{ min: 0, max: 100_000 }}
            value={{ min: incomeRange?.min, max: incomeRange?.max }}
            allowBelowMin={false}
            allowOverMax={false}
            rangeNumberCount={3}
            stepSize={SLIDER_STEP_SIZE}
            onChange={handleSliderChange}
            onInputChange={handleRangeInputChange}
          />
        </IncomeRangeWrapper>
      )}
      <ButtonsContainer>
        <Reset onClick={reset}>{t('commons:ctas.reset')}</Reset>
      </ButtonsContainer>
    </Stack>
  )
}
