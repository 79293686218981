import styled from 'styled-components/macro'
import type { ReactElement } from 'react'
import type { FontawesomeObject } from '@fortawesome/fontawesome-svg-core'

import { defaultStyles } from '../styling/defaults'
import { fontSize, palette, pixelAmount } from '../styling'

import { RadioButton } from './radio-button'

const Span = styled.span({
  margin: `0 ${pixelAmount.md}`,
})
const Wrapper = styled.div<{ isChecked: boolean }>(({ isChecked }) => ({
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `0 ${pixelAmount.lg}`,
  height: pixelAmount.xl4,
  border: `${pixelAmount.xxs} solid ${palette.textSecondary}`,
  borderRadius: defaultStyles.borderRadius,
  userSelect: 'none',
  ...(isChecked && {
    border: `${pixelAmount.xxs} solid ${palette.primary}`,
  }),
}))
const Label = styled.div({
  display: 'flex',
})
const IconContainer = styled.div<{ isChecked: boolean }>(({ isChecked }) => ({
  fontSize: fontSize.textXl,
  color: palette.textSecondary,
  ...(isChecked && {
    color: palette.primary,
  }),
}))

type RadioProps = {
  title: string
  name?: string
  onClick: (name?: string, event?: React.MouseEvent<HTMLDivElement>) => void
  isChecked: boolean
  icon?: ReactElement<FontawesomeObject>
}

export function Radio(props: RadioProps) {
  const { title, name, onClick, isChecked, icon } = props
  return (
    <Wrapper
      onClick={(event: React.MouseEvent<HTMLDivElement>) => onClick(name, event)}
      isChecked={isChecked}
    >
      <Label>
        <RadioButton isChecked={isChecked} name={name} />
        <Span>{title}</Span>
      </Label>
      {icon && <IconContainer isChecked={props.isChecked}>{icon}</IconContainer>}
    </Wrapper>
  )
}
