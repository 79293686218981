import { useMutation } from '@apollo/client'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Spacer } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { palette } from '../../../styling'
import { mutations } from '../../../data/application-board-lists'

import { ActionContainer, ActionName, SectionTitle } from './column-menu'
import { EditColumn } from './edit-column'

const Icon = styled(FontAwesomeIcon)<{ $isDisabled?: boolean }>(({ $isDisabled }) => ({
  color: $isDisabled ? palette.blocketIconDisabled : palette.blocketBlack,
  cursor: 'pointer',
  alignSelf: 'center',
}))

type ColumnSettingsProps = {
  columnId: string
  refetch: () => void
  isEmpty: boolean
  columnName: string
}

export function ColumnSettings({ refetch, isEmpty, columnId, columnName }: ColumnSettingsProps) {
  const [DestroyApplicationBoardList] = useMutation(mutations.DESTROY_BOARD_LIST, {
    onCompleted: () => {
      refetch()
    },
  })

  const { t } = useTranslation('applications', {
    keyPrefix: 'column_settings',
  })

  const handleRemoveColumn = () => {
    if (!isEmpty) return
    DestroyApplicationBoardList({ variables: { destroyApplicationBoardListId: columnId } })
  }
  return (
    <>
      <Spacer size="3x" />
      <SectionTitle>{t('title')}</SectionTitle>
      <Spacer size="4x" />
      <EditColumn refetch={refetch} columnId={columnId} columnTitle={columnName} />
      <Spacer size="4x" />
      <ActionContainer onClick={handleRemoveColumn} direction="row" alignItems="center" gap="4x">
        <Icon icon={['fal', 'trash-alt']} size="sm" $isDisabled={!isEmpty} />
        <ActionName color={isEmpty ? 'default' : 'disabled'}>{t('remove_column')}</ActionName>
      </ActionContainer>
      <Spacer size="3x" />
    </>
  )
}
