import { createContext, useContext, useState } from 'react'
import type { ReactNode } from 'react'
import { useQuery } from '@apollo/client'

import type { ListingQuery_home } from '../data/graphql/types/__generated__/ListingQuery'
import type {
  ListingStatsQuery,
  ListingStatsQuery_homeStatsQuery,
} from '../data/graphql/types/__generated__/ListingStatsQuery'
import { queries } from '../data/listing'
import { getIsBlockListing } from '../util-functions/listing.util'

export enum ListingSectionEnum {
  RENTAL_DETAILS = 'RENTAL_DETAILS',
  HOME_IMAGES = 'HOME_IMAGES',
  APARTMENT_DETAILS = 'APARTMENT_DETAILS',
  DESCRIPTION = 'DESCRIPTION',
  AMENITIES = 'AMENITIES',
  BUILDING_DETAILS = 'BUILDING_DETAILS',
  BUILDING_DESCRIPTION = 'BUILDING_DESCRIPTION',
  BUILDING_LOCATION = 'BUILDING_LOCATION',
  LEASE_DETAILS = 'LEASE_DETAILS',
}

type HomeContextValue = {
  home: ListingQuery_home
  listingStats: ListingStatsQuery_homeStatsQuery | null | undefined
  isBlockListing: boolean
  activeEditSection: ListingSectionEnum | null
  setActiveEditSection: (newSection: ListingSectionEnum | null) => void
  isEditDialogOpen: boolean
  setIsEditDialogOpen: (newValue: boolean) => void
}

const ListingContext = createContext<HomeContextValue>(undefined!)

type Props = {
  children: ReactNode
  home: ListingQuery_home
}

export function ListingContextProvider({ children, home }: Props) {
  const { id, property, numberOfHomes } = home || {}
  const [activeEditSection, setActiveEditSection] = useState<ListingSectionEnum | null>(null)
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false)

  const { data: listingStatsData } = useQuery<ListingStatsQuery>(queries.GET_LISTING_STATS, {
    variables: { listingId: id },
  })

  const isBlockListing = getIsBlockListing({
    property,
    numberOfHomes,
  })

  return (
    <ListingContext.Provider
      value={{
        home,
        listingStats: listingStatsData?.homeStatsQuery,
        isBlockListing,
        activeEditSection,
        setActiveEditSection,
        isEditDialogOpen,
        setIsEditDialogOpen,
      }}
    >
      {children}
    </ListingContext.Provider>
  )
}

export const useListingContext = () => {
  const context = useContext<HomeContextValue>(ListingContext)

  if (context === undefined) {
    throw new Error('useListingContext must be used within a provider')
  }
  return context
}
