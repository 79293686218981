import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ErrorMessage } from '../../../ui-shared/_core/error-message'
import { RadioButton } from '../../../ui-atoms/radio-button'
import { Stack } from '../../../ui-shared/_core/stack'

import { ContractFormInputHeader } from './contract-form-input-header'

export function ContractFormOwnHome() {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  const { t } = useTranslation('applicant', {
    keyPrefix: 'contract_form',
  })

  return (
    <Stack gap={1}>
      <ContractFormInputHeader label={t('landlords_only_rental.question')} />
      <Controller
        name="ownHome"
        control={control}
        rules={{ validate: (value) => value !== null }}
        render={({ field: { onChange, value } }) => (
          <>
            <RadioButton
              label={t('landlords_only_rental.yes')}
              isChecked={value}
              onClick={() => onChange(true)}
            />
            <RadioButton
              label={t('landlords_only_rental.no')}
              isChecked={!value}
              onClick={() => onChange(false)}
            />
          </>
        )}
      />
      {errors['ownHome'] && <ErrorMessage>{t('mandatory')}</ErrorMessage>}
    </Stack>
  )
}
