import { useTranslation } from 'react-i18next'
import { Stack } from '@qasa/qds-ui'

import { useUserContext } from '../../context/user-context'

import { BasicListingInformation } from './basic-listing-information'
import { ListingRentalRequirements } from './listing-rental-requirements'
import { ViewingInformation } from './viewing-information'
import { InputHeader, InputRow } from './input-grid-styles'
import { InstantSignCheckbox } from './instant-sign-checkbox'

export function RentalDetailsSection() {
  const { t } = useTranslation('listing')
  const { authBody } = useUserContext()
  const isProAgent = authBody?.isProAgent

  return (
    <Stack gap={'8x'}>
      <InstantSignCheckbox />
      <BasicListingInformation />
      {isProAgent && (
        <InputRow isRowsFirst>
          <InputHeader isFullWidth size="2xs">
            {t('shortcut_details.matterport_label')}
          </InputHeader>
          <ViewingInformation />
        </InputRow>
      )}
      <InputRow isRowsFirst>
        <InputHeader isFullWidth size="2xs">
          {t('rental_requirements.title')}
        </InputHeader>
        <ListingRentalRequirements />
      </InputRow>
    </Stack>
  )
}
