import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useApplicantContext } from '../../context/applicant-context'
import { ContractProvider } from '../../context/contract-context'
import { useUserContext } from '../../context/user-context'
import { HomeApplicationStatusEnum } from '../../data/graphql/types/__generated__/globalTypes'
import { Spacer } from '../../ui-atoms/help-components'
import { Stack } from '../../ui-shared/_core/stack'
import { Typography } from '../../ui-shared/_core/typography'

import { ContractContent } from './contract-content'

const Wrapper = Stack

export function Contract() {
  const { applicantData } = useApplicantContext()
  const { contract, status, tenant } = applicantData!
  const { authBody } = useUserContext()
  const isProAgent = authBody?.isProAgent
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const homeId = searchParams.get('listing')

  const { t } = useTranslation('applicant')

  if (!isProAgent) return null
  return (
    <Wrapper>
      <Spacer factor={4} />
      {status === HomeApplicationStatusEnum.in_contact ? (
        <ContractProvider homeId={homeId!} tenantUid={tenant.uid} contractId={contract?.id}>
          <ContractContent />
        </ContractProvider>
      ) : (
        <Typography>{t('move_application_info')}</Typography>
      )}
    </Wrapper>
  )
}
