import { OverviewComments } from '../overview/overview-comments'
import { useApplicantContext } from '../../../context/applicant-context'

export function Comments() {
  const { applicantData } = useApplicantContext()
  const homeApplicationsToMyHomes = applicantData!.tenant.homeApplicationsToMyHomes
  const userNotes = applicantData!.tenant.userNotes
  const homeApplicationId = applicantData!.id

  const conversationData = {
    homeApplicationsToMyHomes,
    userNotes,
    homeApplicationId,
  }

  return <OverviewComments data={conversationData} isWritingAvailable={true} />
}
