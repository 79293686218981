import styled from '@emotion/styled'
import { IconButton, Spacer, Stack, createIcon, getFormFieldBaseStyles } from '@qasa/qds-ui'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { mutations } from '../../../data/applicant-chat'
import type { SetHomeApplicationChatMessage } from '../../../data/graphql/types/__generated__/SetHomeApplicationChatMessage'
import { InformationBox } from '../../../ui-atoms/information-box'
import { useUserContext } from '../../../context/user-context'
import { ApplicantTab, useApplicantContext } from '../../../context/applicant-context'

import { useMessageDraft } from './use-message-draft'
import { Macros } from './macros'
import { TEXT_AREA_MIN_HEIGHT, useTextareaDynamicResize } from './use-textarea-dyanamic-resize'

const Form = styled.form(({ theme }) => ({
  borderTop: '1px solid',
  borderColor: theme.colors.border.default,
  padding: theme.spacing['4x'],
}))
const TextAreaWrapper = styled.div({
  position: 'relative',
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'flex-end',
})
const StyledTextArea = styled.textarea(({ theme }) => ({
  ...getFormFieldBaseStyles(theme),
  paddingBlock: theme.spacing['3x'],
  paddingRight: theme.spacing['12x'], // To accommodate the icon
  height: TEXT_AREA_MIN_HEIGHT,
  maxHeight: theme.sizes['320'],
  resize: 'none',
}))
const SendIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing['3x'],
  bottom: theme.spacing['1x'],
}))
const PaperPlaneIcon = createIcon({
  displayName: 'PaperPlane',
  viewBox: '0 0 512 512',
  d: 'M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z',
})

type MessageInputSectionProps = {
  conversationId?: string
  isChatInactive: boolean
  homeApplicationId: string | null
}

export function MessageInputSection({
  conversationId,
  isChatInactive,
  homeApplicationId,
}: MessageInputSectionProps) {
  const { t } = useTranslation('applications', { keyPrefix: 'chat' })
  const { messageDraft, setMessageDraft } = useMessageDraft({ applicantId: homeApplicationId ?? '' })
  const { currentTab } = useApplicantContext()
  const { authBody } = useUserContext()
  const isProAgent = authBody?.isProAgent
  const { textAreaRef } = useTextareaDynamicResize({
    messageDraft,
    isResizeDisabled: currentTab !== ApplicantTab.Conversation,
  })

  const [submitUserMessage, { loading: isLoading }] = useMutation<SetHomeApplicationChatMessage>(
    mutations.SET_MESSAGE_CONVERSATION_INPUT,
    {
      refetchQueries: ['HomeApplicationChatConversation'],
      onCompleted: () => {
        setMessageDraft('')
      },
      onError: (error) => {
        // eslint-disable-next-line no-console
        console.error(error)
      },
    },
  )

  const handleSubmit = async (event: React.FormEvent<EventTarget>) => {
    if (messageDraft.trim().length === 0) {
      event.preventDefault()
      return
    }

    event.preventDefault()
    if (!isLoading) {
      await submitUserMessage({
        variables: {
          createConversationMessageInput: {
            message: messageDraft,
            conversationId,
          },
        },
      })
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      handleSubmit(event)
    }
  }

  const isChatInactiveOrDraftEmpty = isChatInactive || messageDraft.length === 0

  return (
    <Form onSubmit={handleSubmit}>
      <Stack direction="row" gap="2x" alignItems="flex-end">
        <TextAreaWrapper>
          <StyledTextArea
            name="userMessage"
            disabled={isChatInactive}
            value={messageDraft}
            ref={textAreaRef}
            placeholder={t('input_placeholder')}
            onChange={(event) => setMessageDraft(event.target.value)}
            onKeyDown={handleKeyDown}
          />
          <SendIconButton
            label="Send message"
            type="submit"
            icon={PaperPlaneIcon}
            disabled={isChatInactiveOrDraftEmpty || isLoading}
            size="sm"
          />
        </TextAreaWrapper>
        {isProAgent && <Macros setMessageDraft={setMessageDraft} />}
      </Stack>
      {isChatInactive && (
        <>
          <Spacer size="4x" />
          <InformationBox>{t('inactive_chat_info')}</InformationBox>
        </>
      )}
    </Form>
  )
}
