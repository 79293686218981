import styled from 'styled-components/macro'

import { fontSize } from '../styling'

/**
 * @deprecated
 */
export const MediumHeader = styled.p({
  fontSize: fontSize.textLg,
  fontWeight: 600,
})

/**
 * @deprecated
 */
export const HeaderText = styled.p({
  fontSize: fontSize.textXl,
  fontWeight: 600,
})
