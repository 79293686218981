import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Stack, Button, TextField, PlusIcon } from '@qasa/qds-ui'
import { Dialog } from '@qasa/app/src/components/dialog'

import { mutations } from '../../../data/application-board-lists'
import { InformationBox } from '../../../ui-atoms/information-box'

export function CreateNewColumn() {
  const [isOpen, setIsOpen] = useState(false)
  const [inputColumnName, setInputColumnName] = useState('')
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const homeId = searchParams.get('listing') || ''

  const { t } = useTranslation(['applications', 'commons'])

  const [CreateApplicationBoardList, { loading: isLoading }] = useMutation(mutations.CREATE_BOARD_LIST, {
    refetchQueries: ['ApplicationBoardLists'],
    onCompleted: () => {
      setIsOpen(false)
    },
  })

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { value: name } = event.target
    setInputColumnName(name)
  }

  const onSubmit = () => {
    if (inputColumnName === '') {
      setErrorMessage(t('create_column.error_name_missing'))
    } else {
      setErrorMessage(undefined)
      CreateApplicationBoardList({ variables: { homeId, name: inputColumnName } })
    }
  }
  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={(isOpen) => setIsOpen(isOpen)}
      size="sm"
      trigger={
        <Button variant="tertiary" size="sm" onClick={() => setIsOpen(true)}>
          <Stack gap="2x" direction="row" alignItems="center">
            <PlusIcon size={16} />
            {t('board_actions.create_new_column')}
          </Stack>
        </Button>
      }
    >
      <Dialog.Body>
        <Stack gap="4x">
          <Dialog.Header>
            <Dialog.Title>{t('board_actions.new_column')}</Dialog.Title>
          </Dialog.Header>
          <TextField
            label=""
            name={t('commons:name')}
            type="text"
            placeholder={t('create_column.name_of_column')}
            onChange={handleChange}
            errorMessage={errorMessage}
          />
          <InformationBox>{t('create_column.new_column_info')}</InformationBox>
        </Stack>
      </Dialog.Body>
      <Dialog.Footer>
        <Button variant="primary" onClick={onSubmit} isLoading={isLoading}>
          {t('board_actions.create_new_column')}
        </Button>
      </Dialog.Footer>
    </Dialog>
  )
}
