import gql from 'graphql-tag'

const RESET_PASSWORD = gql`
  mutation SubUserResetPasswordMutation(
    $subUserResetPasswordPassword: String!
    $subUserResetPasswordResetPasswordToken: String!
  ) {
    subUserResetPassword(
      password: $subUserResetPasswordPassword
      resetPasswordToken: $subUserResetPasswordResetPasswordToken
    ) {
      success
    }
  }
`

export const mutations = {
  RESET_PASSWORD,
}
