import styled from '@emotion/styled'

const Wrapper = styled.div({
  position: 'relative',
  // NOTE: needed for correct positioning of the badge when the navbar is collapsed
  minHeight: 18,
})
const BadgeContainer = styled.div({
  position: 'absolute',
  right: -18,
  top: -12,
})

const Badge = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 6px',
  minWidth: 18,
  height: 18,
  color: theme.colors.core.white,
  borderRadius: theme.radii.full,
  backgroundColor: theme.colors.bg.brandPrimary,
  ...theme.typography.caption.sm,
}))

type NavbarLabelProps = {
  label: string
  badgeCount?: number
  isNavbarCollapsed: boolean
}

export function NavbarLabel({ label, badgeCount, isNavbarCollapsed }: NavbarLabelProps) {
  return (
    <Wrapper>
      {!isNavbarCollapsed && label}
      {Boolean(badgeCount) && (
        <BadgeContainer>
          <Badge>{badgeCount}</Badge>
        </BadgeContainer>
      )}
    </Wrapper>
  )
}
