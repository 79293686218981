import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ApartmentForm } from '../ui-atoms/apartment-form'

export function AddApartment() {
  const location = useLocation()
  const { t } = useTranslation('commons')

  const searchParams = new URLSearchParams(location.search)
  const buildingId = searchParams.get('buildingId') ? searchParams.get('buildingId') : null

  return <ApartmentForm buildingId={buildingId} title={t('actions.add_apartment')} />
}
