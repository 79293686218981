import { useCallback, useState } from 'react'
import { useMutation } from '@apollo/client'

import { CREATE_UPLOAD } from '../data/create-upload'
import { FINALIZE_UPLOAD } from '../data/finalize-upload'
import type {
  CreateUploadMutation,
  CreateUploadMutationVariables,
} from '../data/graphql/types/__generated__/CreateUploadMutation'
import type {
  FinalizeUploadMutation,
  FinalizeUploadMutationVariables,
} from '../data/graphql/types/__generated__/FinalizeUploadMutation'
import type { UploadTypeEnum, OwnerTypeEnum } from '../data/graphql/types/__generated__/globalTypes'
import { uploadToS3 } from '../util-functions/upload-to-S3'
/**
 * Hook for common functionality when uploading images.
 * Returns a function that takes care of creating a upload request, uploading the image to S3 and finalizing the upload.
 * Intended to be used with file-upload.tsx
 */
export function useUploadImage(options?: { onCompleted?: () => void }) {
  const [isLoading, setIsLoading] = useState(false)
  const [createUpload] = useMutation<CreateUploadMutation, CreateUploadMutationVariables>(CREATE_UPLOAD)
  const [finalizeUpload] = useMutation<FinalizeUploadMutation, FinalizeUploadMutationVariables>(
    FINALIZE_UPLOAD,
  )
  const upload = useCallback(
    async (
      file: File,
      payload: {
        uploadType: UploadTypeEnum
        ownerType: OwnerTypeEnum
        ownerId: string
        primary?: boolean
        order?: number
      },
    ) => {
      setIsLoading(true)
      const result = await createUpload({
        variables: {
          input: {
            title: file.name,
            contentLength: file.size,
            contentType: file.type,
            uploadType: payload.uploadType,
            ownerType: payload.ownerType,
            ownerId: payload.ownerId,
            metadata: {
              order: payload.order || null,
              primary: payload.primary,
            },
          },
        },
      })
      if (!result.data?.createUpload?.upload || result.errors) {
        setIsLoading(false)
        throw new Error('Upload failed')
      }
      const { action, fields, id } = result.data?.createUpload?.upload || {}
      const s3Params = await uploadToS3(file, action, fields as Record<string, string | Blob>)
      await finalizeUpload({
        variables: {
          id,
          etag: s3Params.etag,
        },
        refetchQueries: ['ListingQuery'],
      })
      setIsLoading(false)
      if (options?.onCompleted) {
        options.onCompleted()
      }
      return s3Params.location.replace('tmp%2F', 'img/')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createUpload, finalizeUpload],
  )
  const uploadImage = useCallback(
    (
      file: File,
      options: {
        uploadType: UploadTypeEnum
        ownerType: OwnerTypeEnum
        ownerId: string
        primary?: boolean
        order?: number
      },
    ) => {
      return upload(file, options)
    },
    [upload],
  )
  return [
    uploadImage,
    {
      isLoading,
    },
  ] as const
}
