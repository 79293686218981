import styled from 'styled-components/macro'

import footerbar from '../assets/footer-bar.svg'

export const FooterBar = styled.div({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  height: '10px',
  width: '100%',
  backgroundImage: `url(${footerbar})`,
  backgroundRepeat: 'repeat-x',
  backgroundSize: 'auto',
  transform: 'translateY(-20px)',
})
