import { Stack } from '@qasa/qds-ui'

import { AmenitiesSection } from './amenities-section'
import { RulesAndAccessibilitySection } from './rules-and-accessibility-section'
import { FurnishedSection } from './furnished-section'

export function HomeAmenities() {
  return (
    <Stack gap={'6x'}>
      <AmenitiesSection />
      <RulesAndAccessibilitySection />
      <FurnishedSection />
    </Stack>
  )
}
