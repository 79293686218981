import { Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { BaseInfoItem } from '../listing/base-info-item'
import { useListingContext } from '../../context/listing-context'
import { useUserContext } from '../../context/user-context'

export function ApartmentDescription() {
  const { home } = useListingContext()
  const { authBody } = useUserContext()
  const isProAgent = authBody?.isProAgent
  const { description, descriptionContract, shortcut } = home || {}
  const { homePitch } = shortcut || {}
  const { t } = useTranslation(['apartment', 'commons'])

  return (
    <Stack gap="8x">
      <BaseInfoItem
        heading={t('apartment_form.description_label')}
        value={description || t('commons:placeholders.not_filled_in')}
        longText
      />
      <BaseInfoItem
        heading={t('apartment_form.contract_description_label')}
        value={descriptionContract || t('commons:placeholders.not_filled_in')}
        longText
      />
      {isProAgent && (
        <BaseInfoItem
          heading={t('apartment_form.home_pitch_label')}
          value={homePitch || t('commons:placeholders.not_filled_in')}
          longText
        />
      )}
    </Stack>
  )
}
