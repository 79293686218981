import styled from 'styled-components/macro'

import blocketLogo from '../assets/logos/blocket-round.svg'

export const ChatBlocketLogo = styled.img.attrs({
  alt: `Blocket logo`,
  src: blocketLogo,
})({
  width: 16,
  height: 16,
})
