import { HomeStatusEnum } from '../../data/graphql/types/__generated__/globalTypes'

import { ListingsStatusFilters } from './listings-status-navigation'

export const getListingsStatusVariables = (listingsStatus: ListingsStatusFilters) => {
  switch (listingsStatus) {
    case ListingsStatusFilters.ARCHIVED:
      return {
        homeStatuses: [HomeStatusEnum.archived],
        excludeApplicationDeadlinePassed: true,
        onlyApplicationDeadlinePassed: false,
      }
    case ListingsStatusFilters.DRAFT:
      return {
        homeStatuses: [HomeStatusEnum.pending],
        onlyApplicationDeadlinePassed: false,
        excludeApplicationDeadlinePassed: false,
      }
    case ListingsStatusFilters.PASSED_DEADLINE:
      return {
        homeStatuses: [HomeStatusEnum.archived],
        onlyApplicationDeadlinePassed: true,
        excludeApplicationDeadlinePassed: false,
      }
    case ListingsStatusFilters.PUBLISHED:
      return {
        homeStatuses: [HomeStatusEnum.normal],
        onlyApplicationDeadlinePassed: false,
        excludeApplicationDeadlinePassed: false,
      }
    case ListingsStatusFilters.ALL:
    default:
      return {
        homeStatuses: [HomeStatusEnum.pending, HomeStatusEnum.normal, HomeStatusEnum.archived],
        onlyApplicationDeadlinePassed: false,
        excludeApplicationDeadlinePassed: false,
      }
  }
}
