import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { Paragraph, Stack } from '@qasa/qds-ui'
import { PillButton } from '@qasa/app/src/components/pill-button'

import { SmallHeader } from '../../ui-atoms/small-header'
import { TraitTypeEnum } from '../../data/graphql/types/__generated__/globalTypes'
import type { UpdateHomeInput } from '../../data/graphql/types/__generated__/globalTypes'
import { getTraits } from '../../util-functions/traits.util'

import { CheckboxLine, getTraitsAfterClick } from './amenities-section'

const RULES_AND_ACCESSIBILITY = [
  TraitTypeEnum.pets,
  TraitTypeEnum.wheelchair_accessible,
  TraitTypeEnum.smoker,
]

export function RulesAndAccessibilitySection() {
  const { t } = useTranslation('amenities', { keyPrefix: 'categories' })
  const { control, setValue } = useFormContext<UpdateHomeInput>()

  return (
    <Controller
      name="traits"
      control={control}
      render={({ field: { value } }) => {
        return (
          <Stack gap={'4x'}>
            <SmallHeader>{t('rules_and_accessibility')}</SmallHeader>

            <CheckboxLine direction="row" gap={'2x'}>
              {RULES_AND_ACCESSIBILITY.map((trait) => (
                <PillButton
                  key={trait}
                  size="small"
                  type="button"
                  onPress={() => {
                    const newTraits = getTraitsAfterClick(trait, value)
                    setValue('traits', newTraits)
                  }}
                  isActive={Boolean(value?.includes(trait))}
                >
                  <Paragraph size="sm">{getTraits(trait)}</Paragraph>
                </PillButton>
              ))}
            </CheckboxLine>
          </Stack>
        )
      }}
    />
  )
}
