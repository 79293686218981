import styled from '@emotion/styled'

import { defaultStyles } from '../../../styling/defaults'
import { getPixelAmount } from '../../../styling/pixel-amount'
import { ColumnWithLoading } from '../../../ui-atoms/table'

export const ListedStatusIndicator = styled.div(({ theme }) => ({
  height: getPixelAmount(1),
  width: getPixelAmount(1),
  borderRadius: defaultStyles.borderRadiusRound,
  backgroundColor: '#00CC70',
  border: `0.5px solid ${theme.colors.text.positive}`,
}))

export const ApartmentImageWrapper = styled(ColumnWithLoading)({
  gap: 8,
  minWidth: '90px',
})

export const ApartmentImage = styled.img({
  width: 36,
  height: 36,
  objectFit: 'cover',
  borderRadius: defaultStyles.borderRadius,
})

export const BuildingName = styled(ColumnWithLoading)({
  minWidth: '150px',
})
export const ApartmentNumber = styled(ColumnWithLoading)({
  minWidth: '150px',
})
export const Address = styled(ColumnWithLoading)({
  minWidth: '250px',
})
export const Size = styled(ColumnWithLoading)({
  minWidth: '120px',
})
export const ListingStatus = styled(ColumnWithLoading)<{ $isListed?: boolean }>(({ theme, $isListed }) => ({
  display: 'flex',
  gap: 8,
  minWidth: '120px',
  ...($isListed && {
    color: theme.colors.bg.positive,
  }),
}))
export const LastUpdate = styled(ColumnWithLoading)({
  minWidth: '100px',
})
