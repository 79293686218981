import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useParams, useNavigate, Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { queries } from '../data/edit-building'
import { BuildingForm } from '../ui-atoms/building-form'
import type { BuildingInput } from '../ui-atoms/building-form'
import type { BuildingForEditQuery_building_uploads } from '../data/graphql/types/__generated__/BuildingForEditQuery'

type TParams = { buildingId: string }

export function EditBuilding() {
  const navigate = useNavigate()
  const { t } = useTranslation('commons', { keyPrefix: 'actions' })

  const params = useParams<TParams>()

  const [building, setBuilding] = useState<BuildingInput>()
  const [uploads, setUploads] = useState<BuildingForEditQuery_building_uploads[]>([])

  const {
    data,
    error,
    loading: isLoading,
  } = useQuery(queries.GET_BUILDING, {
    fetchPolicy: 'no-cache',
    variables: { buildingId: params.buildingId },
  })

  useEffect(() => {
    if (data) {
      const {
        buildingName,
        buildYear,
        descriptionBuilding,
        descriptionTransportation,
        energyClass,
        uploads,
        location: locationData,
      } = data.building

      const {
        country,
        countryCode,
        googleMapsPlaceId,
        latitude,
        longitude,
        locality,
        postalCode,
        route,
        streetNumber,
      } = locationData

      setBuilding({
        buildingName,
        buildYear,
        descriptionBuilding,
        descriptionTransportation,
        energyClass,
        location: {
          country,
          countryCode,
          googleMapsPlaceId,
          latitude,
          longitude,
          locality,
          postalCode,
          route,
          streetNumber,
        },
      })

      if (uploads) {
        setUploads(uploads)
      }
    }
  }, [data])

  const cancel = () => {
    navigate(`/homes/buildings/${params.buildingId}`, { replace: true })
  }

  if (isLoading) return null

  if (error) {
    switch (error.message) {
      case 'not_found':
        return <Navigate to="/homes/buildings" replace />
      default:
        // eslint-disable-next-line no-console
        console.log(error.message)
    }
  }

  return (
    <BuildingForm
      title={t('edit_building')}
      building={building}
      uploads={uploads}
      buildingId={params.buildingId}
      cancel={cancel}
    />
  )
}
