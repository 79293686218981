import type { ReactNode } from 'react'

import { HorizontalDivider } from '../../../ui-atoms/help-components'
import { Typography } from '../../../ui-shared/_core/typography'
import { Stack } from '../../../ui-shared/_core/stack'

type Props = {
  title?: string
  children: ReactNode
}
export function ContractFormSection({ title, children }: Props) {
  return (
    <>
      <HorizontalDivider />
      {title && <Typography variant={'subhead1'}>{title}</Typography>}
      <Stack gap={3}>{children}</Stack>
    </>
  )
}
