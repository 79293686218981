import { gql } from '@apollo/client'

export const contract = gql`
  fragment BaseContract on Contract {
    id
    type
    terminationNotice
    tenureType
    fee
    qasaGuarantee
    qasaGuaranteeCost
    insuranceCost
    signedAt
    status
    landlordHasKeys
    insuranceResponsibility
    rent
    inventory
    payoutDay
    paymentDay
    canUse
    rentIncludes
    rentExcludes
    subletception
    renovations
    payoutDay
    preCleaning
    postCleaning
    temporaryInstallations
    smallRepairs
    pets
    smoker
    payoutDay
    ownHome
    furniture
    overnightStay
    tenant {
      uid
      firstName
      companyName
      professional
      profilePicture {
        url
      }
    }
    landlord {
      uid
      firstName
      safeRental
      companyName
      professional
      profilePicture {
        url
      }
    }
    duration {
      id
      endOptimal
      endUfn
      startAsap
      startOptimal
      updatedAt
    }
    home {
      ...BaseHome
      ownHome
      tenureType
      tenantFees {
        waterFeePerTenant {
          availability
          cost {
            fixed
          }
        }
      }
    }
    upload {
      url
    }
    extraCosts {
      costFor
      plan
    }
    signatories {
      id
      role
      signedAt
      order
    }
    landlordServices {
      insurance {
        availability
        cost {
          factor
          isFree
          total
        }
        isActive
      }
      qasaGuarantee {
        availability
        cost {
          isFree
          factor
          total
        }
        isActive
      }
    }
  }
`
