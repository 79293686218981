import styled from '@emotion/styled'
import { Heading } from '@qasa/qds-ui'

export const InputRow = styled.div<{ isRowsFirst?: boolean }>(({ theme, isRowsFirst = false }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: 'auto auto',
  gap: theme.spacing['4x'],
  gridAutoFlow: isRowsFirst ? 'row' : 'column',
  maxWidth: 720,
}))

export const InputHeader = styled(Heading)<{ isFullWidth?: boolean }>(({ isFullWidth = false }) => ({
  ...(isFullWidth && { gridColumn: '1 / 3' }),
}))
