import styled from '@emotion/styled'

type FormWindowProps = {
  children: React.ReactNode
}

const Wrapper = styled.form(({ theme }) => ({
  backgroundColor: theme.colors.bg.default,
  border: `1px solid ${theme.colors.border.default}`,
  borderRadius: theme.radii.lg,
  padding: 32,
  width: '366px',
}))

export function FormWindow(props: FormWindowProps) {
  const preventSubmit = (event: React.FormEvent<EventTarget>) => {
    event.preventDefault()
  }

  return <Wrapper onSubmit={preventSubmit}>{props.children}</Wrapper>
}
