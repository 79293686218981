import { gql } from '@apollo/client'
export const FINALIZE_UPLOAD = gql`
  mutation FinalizeUploadMutation($etag: String!, $id: ID!) {
    finalizeUpload(etag: $etag, id: $id) {
      errors {
        codes
        field
      }
      upload {
        fileType
        id
        title
        type
        url
        metadata {
          order
          primary
          rotation
        }
      }
    }
  }
`
