import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { fontSize, palette } from '../styling'
import { MobileIndicatorGlobalStyles } from '../styling/global-styles'
import logo from '../assets/icons/BlocketVertical.svg'

import { TopBar } from './top-bar'
import { FooterBar } from './footer'
import { Spacer } from './help-components'

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  overflow: 'hidden',
  height: '100vh',
  width: '100%',
  backgroundColor: palette.white,
})

const MobileFotter = styled(FooterBar)({
  bottom: -3,
  top: 'unset',
  height: 15,
  transform: 'none',
  backgroundSize: 'cover',
})

const Logo = styled.img({})

const BoldText = styled.p({
  maxWidth: '80%',
  margin: 0,
  textAlign: 'center',
  fontSize: fontSize.textXl,
  fontWeight: 600,
})

const Text = styled.p({
  maxWidth: '70%',
  margin: 0,
  textAlign: 'center',
  fontSize: fontSize.textBase,
  color: palette.blocketGreyDark,
  fontWeight: 200,
})

export function MobileIndicator() {
  const { t } = useTranslation('miscellaneous', { keyPrefix: 'mobile_indicator' })

  return (
    <Wrapper>
      <MobileIndicatorGlobalStyles />
      <TopBar />
      <Logo src={logo} />
      <Spacer factor={5} />
      <BoldText>{t('title')}</BoldText>
      <Spacer factor={1} />
      <Text>{t('description')}</Text>
      <Spacer factor={20} />
      <MobileFotter />
    </Wrapper>
  )
}
