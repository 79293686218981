import { useQuery } from '@apollo/client'
import get from 'lodash/get'
import { useTranslation } from 'react-i18next'
import { createEnumParam, useQueryParams, withDefault } from 'use-query-params'
import styled from '@emotion/styled'

import { ApplicantNameCard } from '../ui-feature-modules/applications/applicant-name-card'
import { queries as applicantQueries } from '../data/applicant'
import type { HomeApplicationOverview } from '../data/graphql/types/__generated__/HomeApplicationOverview'
import { ApplicantProvider, ApplicantTab } from '../context/applicant-context'
import { ApplicantContent } from '../ui-feature-modules/applicant/applicant-content'
import { HomeApplicationStatusEnum } from '../data/graphql/types/__generated__/globalTypes'
import { ApplicantFooter } from '../ui-feature-modules/applications/applicant-footer'
import { LoadingDots } from '../ui-shared/loading-dots'

import { PageNotFound } from './page-not-found'

const Wrapper = styled.div({
  height: 'calc(100% - 40px)',
  width: '100%',
  overflowY: 'auto',
})
const PageWrapper = styled.div<{ hasFooter: boolean }>(({ hasFooter }) => ({
  height: '100%',
  paddingBottom: hasFooter ? 70 : 0,
  boxSizing: 'border-box',
}))
const ApplicantCardWrapper = styled.div(({ theme }) => ({
  paddingInline: theme.spacing['6x'],
}))

const { GET_APPLICANT_OVERVIEW } = applicantQueries
type ApplicantParams = { id: string }

export function Applicant(props: ApplicantParams) {
  const [{ tab: applicantTab }] = useQueryParams({
    tab: withDefault(createEnumParam(Object.values(ApplicantTab)), null),
  })
  const { t } = useTranslation('applicant')
  const { id } = props
  const {
    loading: isLoadingOverview,
    error: ErrorOverview,
    data: OverviewRawData,
  } = useQuery<HomeApplicationOverview>(GET_APPLICANT_OVERVIEW, {
    variables: {
      homeApplicationId: id,
    },
    fetchPolicy: 'no-cache',
  })

  const dataOverview = get(OverviewRawData, 'homeApplication')

  if (isLoadingOverview) {
    return <LoadingDots />
  }

  // TODO -> make better error handling
  if (!dataOverview) return <PageNotFound>{t('no_data')}</PageNotFound>

  if (ErrorOverview) {
    // eslint-disable-next-line no-console
    console.log(ErrorOverview)
  }

  return (
    <Wrapper>
      <PageWrapper hasFooter={dataOverview!.status !== HomeApplicationStatusEnum.revoked}>
        <ApplicantProvider data={dataOverview} applicantId={id} initialTab={applicantTab}>
          <ApplicantCardWrapper>
            <ApplicantNameCard />
          </ApplicantCardWrapper>
          <ApplicantContent />
          {dataOverview!.status !== HomeApplicationStatusEnum.revoked && <ApplicantFooter />}
        </ApplicantProvider>
      </PageWrapper>
    </Wrapper>
  )
}
