import { createRef, useEffect } from 'react'

export const TEXT_AREA_MIN_HEIGHT = 48

type UseTextareaDynamicResizeParams = {
  messageDraft: string
  isResizeDisabled: boolean
}

export const useTextareaDynamicResize = ({
  messageDraft,
  isResizeDisabled,
}: UseTextareaDynamicResizeParams) => {
  const textAreaRef = createRef<HTMLTextAreaElement>()

  useEffect(() => {
    if (!isResizeDisabled && textAreaRef.current != null) {
      textAreaRef.current.style.height = TEXT_AREA_MIN_HEIGHT + 'px'
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px'
    }
  }, [isResizeDisabled, messageDraft, textAreaRef])
  return { textAreaRef }
}
