import { Stack, Paragraph, Heading } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { BulletSeperator } from '../../../ui-shared/_core/bullet-seperator'

const PublishDate = styled(Paragraph)({
  marginRight: 8,
})

type Props = {
  route?: string | null
  streetNumber?: string | null
  locality?: string | null
  postalCode?: string | null
  numberOfHomes?: number | null
  roomCount?: number | null | string
  apartmentNumber?: string | null
  squareMeters?: number | null | string
  publishedAt?: string
}

export function AddressWithApartmentDetails({
  route,
  streetNumber,
  numberOfHomes,
  roomCount,
  apartmentNumber,
  squareMeters,
  publishedAt,
}: Props) {
  const { t } = useTranslation(['applications', 'commons'])

  if (!route) return null

  return (
    <Stack gap="1x">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {numberOfHomes && numberOfHomes > 1 ? (
          <Paragraph size="xs">{t('commons:group_listing')}</Paragraph>
        ) : (
          <BulletSeperator>
            <Paragraph as="span" size="xs">
              {t('listings.address_details.rooms', { roomCount })}
            </Paragraph>
            <Paragraph as="span" size="xs">
              {t('listings.address_details.sqm', { squareMeters, unit: t('commons:square_meter') })}
            </Paragraph>
          </BulletSeperator>
        )}
        <PublishDate size="xs" color="subtle">
          {publishedAt}
        </PublishDate>
      </Stack>
      <Heading as="p" size="3xs" numberOfLines={1}>
        {apartmentNumber || route}
      </Heading>
      <Paragraph size="sm" numberOfLines={1}>
        {route} {streetNumber}
      </Paragraph>
    </Stack>
  )
}
