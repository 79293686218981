import { useRef, useState } from 'react'
import { Button, Paragraph, Stack } from '@qasa/qds-ui'
import { Link as RouterLink } from 'react-router-dom'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { Input } from '../../../ui-atoms/input'
import { Table, TableColumn } from '../../../ui-atoms/table'
import { HomeTemplateOrderEnum } from '../../../data/graphql/types/__generated__/globalTypes'
import { ContextMenu } from '../../../ui-atoms/context-menu'
import { FilterDisplay } from '../../filters/filter-display'
import { RoomCountFilter } from '../room-count-filter'
import { SizeFilter } from '../size-filter'
import { BuildingFilter } from '../building-filter'
import { SortingSelect } from '../../sorting/sorting-select'

import { useGetApartmentsData } from './use-get-apartments-data'
import { constructApartmentsTableRows } from './apartments-table.utils'

type ApartmentsTableProps = {
  setTotalCount: React.Dispatch<React.SetStateAction<number | undefined>>
}
export type ApartmentFilterAttribute = 'minRoomCount' | 'maxRoomCount' | 'minSquareMeters' | 'maxSquareMeters'

const ROOM_COUNT_MIN_MAX = {
  min: 0,
  max: 8,
}
const SIZE_MIN_MAX = {
  min: 0,
  max: 150,
}
enum FilterTypes {
  ROOM_COUNT = 'ROOM_COUNT',
  SIZE = 'SIZE',
  BUILDING = 'BUILDING',
}

export const Filters = Stack
const TableActions = styled(Stack)({
  width: '100%',
})

export function ApartmentsTable({ setTotalCount }: ApartmentsTableProps) {
  const [currentFilter, setCurrentFilter] = useState<FilterTypes | null>(null)
  const [chosenApartmentIds, setChosenApartments] = useState<Array<string>>([])
  const { t } = useTranslation(['my_homes', 'commons'])
  const scrollElementRef = useRef<HTMLTableElement>(null)

  const {
    resetFilterAttributes,
    addFilter,
    filterByBuildingId,
    onSearch,
    onSort: handleChangeSorting,
    apartmentsData: { isLoading, error, data },
    buildingData,
    handleScroll,
    homeTemplateVars,
  } = useGetApartmentsData({
    setTotalCount,
    setChosenApartments,
    scrollElementRef,
  })

  const closeFilterWindow = () => setCurrentFilter(null)

  const sortingOptions = [
    { label: t('apartments_table.sort_options.building'), value: HomeTemplateOrderEnum.BUILDING_NAME },
    { label: t('apartments_table.sort_options.published_date'), value: HomeTemplateOrderEnum.IS_LISTED },
    { label: t('apartments_table.sort_options.last_update'), value: HomeTemplateOrderEnum.UPDATED_LATEST },
  ]

  const constructApartmentsTableColumns = () => {
    return [
      /* eslint-disable react/jsx-key */
      <TableColumn>{t('table_columns.apartment')}</TableColumn>,
      <TableColumn>{t('table_columns.apartment_number')}</TableColumn>,
      <TableColumn>{t('table_columns.size')}</TableColumn>,
      <TableColumn>{t('table_columns.address')}</TableColumn>,
      <TableColumn>{t('table_columns.building')}</TableColumn>,
      <TableColumn>{t('table_columns.status')}</TableColumn>,
      <TableColumn>{t('table_columns.update')}</TableColumn>,
      /* eslint-enable react/jsx-key */
    ]
  }

  const handleChooseApartment = (apartmentId: string) => {
    let newChosenApartmentIds
    if (chosenApartmentIds.includes(apartmentId)) {
      newChosenApartmentIds = chosenApartmentIds.filter((id) => id !== apartmentId)
    } else {
      newChosenApartmentIds = [...chosenApartmentIds, apartmentId]
    }
    setChosenApartments(newChosenApartmentIds)
  }

  if (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    return null
  }

  const shouldRoomCountFilterBeActive =
    Boolean(homeTemplateVars.minRoomCount) || Boolean(homeTemplateVars.maxRoomCount)
  const shouldSizeFilterBeActive =
    Boolean(homeTemplateVars.minSquareMeters) || Boolean(homeTemplateVars.maxSquareMeters)
  const shouldBuildingFilterBeActive =
    Boolean(homeTemplateVars.homeTemplatesBuildingId) && Boolean(buildingData)

  return (
    <>
      <Stack gap={'2x'}>
        <TableActions gap={'4x'} direction="row" alignItems="center" justifyContent="space-between">
          <Input
            value={homeTemplateVars.homeTemplatesSearchString}
            type="search"
            name="search"
            placeholder={t('apartments_table.search_placeholder')}
            width="300px"
            onChange={onSearch}
          />
          <Stack direction="row" gap={'2x'}>
            <Button variant={'tertiary'} size="sm" as={RouterLink} to={`/homes/apartments/add/`}>
              {t('commons:actions.add_apartment')}
            </Button>
            <RouterLink
              to={`/listings/add/${chosenApartmentIds.length === 1 ? chosenApartmentIds[0] : ''}`}
              state={chosenApartmentIds.length > 1 && { apartmentIds: chosenApartmentIds }}
            >
              <Button isDisabled={chosenApartmentIds.length === 0} variant={'tertiary'} size="sm">
                {chosenApartmentIds.length > 1
                  ? t('commons:actions.create_group_listing')
                  : t('commons:actions.create_listing')}
              </Button>
            </RouterLink>
          </Stack>
        </TableActions>
        <Filters direction="row" gap={'2x'} justifyContent="flex-start">
          <div>
            <SortingSelect
              placeholder={t('apartments_table.sort_placeholder')}
              value={homeTemplateVars.homeTemplatesOrderBy as string}
              onChange={handleChangeSorting}
              options={sortingOptions}
            />
          </div>
          <FilterDisplay
            onClick={() => setCurrentFilter(FilterTypes.ROOM_COUNT)}
            reset={() => resetFilterAttributes(['minRoomCount', 'maxRoomCount'])}
            hasFilter={shouldRoomCountFilterBeActive}
            name={t('filters.room_count_title')}
            activeFilter={`
                ${homeTemplateVars.minRoomCount ?? 0}
                -
                ${homeTemplateVars.maxRoomCount ?? `${ROOM_COUNT_MIN_MAX.max}+`}
              `}
          />
          <FilterDisplay
            onClick={() => setCurrentFilter(FilterTypes.SIZE)}
            reset={() => resetFilterAttributes(['minSquareMeters', 'maxSquareMeters'])}
            hasFilter={shouldSizeFilterBeActive}
            name={t('filters.size_filter_title', { unit: t('commons:square_meter') })}
            activeFilter={`
                ${homeTemplateVars.minSquareMeters ?? 0}
                -
                ${homeTemplateVars.maxSquareMeters ?? `${SIZE_MIN_MAX.max}+`}
              `}
          />
          <FilterDisplay
            onClick={() => setCurrentFilter(FilterTypes.BUILDING)}
            reset={() => filterByBuildingId(undefined)}
            hasFilter={shouldBuildingFilterBeActive}
            name={t('filters.building_title')}
            activeFilter={buildingData?.building.buildingName}
          />
        </Filters>
      </Stack>

      <Paragraph>
        {isLoading
          ? t('apartments_table.loading_aparments')
          : t('apartments_table.view_loaded_apartments_of_total', {
              loadedCount: data?.homeTemplates?.edges?.length,
              totalCount: data?.homeTemplates?.totalCount,
            })}
      </Paragraph>

      <Table
        isLoading={isLoading}
        tableRef="/homes/apartments"
        columns={constructApartmentsTableColumns()}
        rows={constructApartmentsTableRows(
          handleChooseApartment,
          chosenApartmentIds,
          data?.homeTemplates,
          isLoading,
        )}
        scrollRef={scrollElementRef}
        handleOnScroll={handleScroll}
      />

      <ContextMenu isOpen={Boolean(currentFilter)} onClose={closeFilterWindow}>
        {(() => {
          switch (currentFilter) {
            case FilterTypes.SIZE:
              return (
                <SizeFilter
                  range={SIZE_MIN_MAX}
                  filter={{
                    minSquareMeters: homeTemplateVars.minSquareMeters,
                    maxSquareMeters: homeTemplateVars.maxSquareMeters,
                  }}
                  totalCount={data?.homeTemplates.totalCount}
                  isLoading={isLoading}
                  onChange={addFilter}
                  close={closeFilterWindow}
                  reset={() => {
                    closeFilterWindow()
                    resetFilterAttributes(['minSquareMeters', 'maxSquareMeters'])
                  }}
                />
              )
            case FilterTypes.BUILDING:
              return (
                <BuildingFilter
                  totalCount={data?.homeTemplates.totalCount}
                  isLoading={isLoading}
                  buildingId={buildingData?.building?.id}
                  buildingName={buildingData?.building.buildingName}
                  onChoice={(buildingId) => filterByBuildingId(buildingId)}
                  close={closeFilterWindow}
                  reset={() => {
                    closeFilterWindow()
                    filterByBuildingId(undefined)
                  }}
                />
              )
            case FilterTypes.ROOM_COUNT:
            default:
              return (
                <RoomCountFilter
                  range={ROOM_COUNT_MIN_MAX}
                  filter={{
                    minRoomCount: homeTemplateVars.minRoomCount,
                    maxRoomCount: homeTemplateVars.maxRoomCount,
                  }}
                  totalCount={data?.homeTemplates.totalCount}
                  isLoading={isLoading}
                  onChange={addFilter}
                  close={closeFilterWindow}
                  reset={() => {
                    closeFilterWindow()
                    resetFilterAttributes(['minRoomCount', 'maxRoomCount'])
                  }}
                />
              )
          }
        })()}
      </ContextMenu>
    </>
  )
}
