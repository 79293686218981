import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { queries } from '../../data/add-apartment'
import type {
  BuildingsForAddApartmentQuery,
  BuildingsForAddApartmentQuery_buildings,
} from '../../data/graphql/types/__generated__/BuildingsForAddApartmentQuery'
import { fontSize } from '../../styling'
import { getPixelAmount } from '../../styling/pixel-amount'
import { Button } from '../../ui-shared/_core/links-buttons'
import type { DropDownElement } from '../../ui-atoms/dropdown'
import { DropDown, DropDownElementWrapper } from '../../ui-atoms/dropdown'
import { HeaderText } from '../../ui-atoms/header-text'
import { HorizontalDivider, Spacer } from '../../ui-atoms/help-components'
import { formatLocation } from '../../util-functions/general.util'
import { ButtonsContainer } from '../filters/buttons-container'
import { Reset } from '../filters/reset'

type BuildingFilterProps = {
  totalCount?: number
  reset: () => void
  close: () => void
  isLoading?: boolean
  buildingId?: string | null
  buildingName?: string | null
  onChoice: (buildingId: string) => void
}

const Wrapper = styled.div({
  width: '400px',
  display: 'flex',
  flexDirection: 'column',
  padding: getPixelAmount(3),
})

const BuildingName = styled.p({
  fontWeight: 500,
})

const BuildingLocation = styled.p({
  fontSize: fontSize.textXs,
  opacity: 0.5,
})

export function BuildingFilter(props: BuildingFilterProps) {
  const [buildingSearch, setBuildingSearch] = useState('')
  const { t } = useTranslation(['my_homes', 'commons'])

  const { data: buildingsData, refetch } = useQuery<BuildingsForAddApartmentQuery>(queries.GET_BUILDINGS, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (props.buildingName) {
      setBuildingSearch(props.buildingName)
    }
  }, [props.buildingName])

  const loadBuildingDropDownValues = (
    buildings: BuildingsForAddApartmentQuery_buildings,
  ): Array<DropDownElement> => {
    return buildings?.nodes.map(({ id, buildingName, location }) => {
      const buildingDisplay = (
        <DropDownElementWrapper key={id}>
          <BuildingName>{buildingName}</BuildingName>
          <BuildingLocation>{formatLocation(location)}</BuildingLocation>
        </DropDownElementWrapper>
      )
      return {
        id,
        value: buildingDisplay,
      }
    })
  }

  const handleBuildingInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.buildingId) {
      props.reset()
    }
    setBuildingSearch(event.target.value)
    refetch({
      buildingsSearchString: event.target.value,
    })
  }

  const handleChoice = (buildingId: string) => {
    props.onChoice(buildingId)
    const buildingChoice = buildingsData?.buildings.nodes.find((node) => node.id === buildingId)
    setBuildingSearch(buildingChoice!.buildingName!)
  }

  return (
    <Wrapper>
      <HeaderText>{t('filters.building_title')}</HeaderText>
      <Spacer factor={1} />
      <HorizontalDivider />
      <Spacer factor={2} />
      <DropDown
        name="buildings"
        value={buildingSearch}
        values={buildingsData ? loadBuildingDropDownValues(buildingsData.buildings) : []}
        width={'100%'}
        placeholder={t('filters.building_placeholder')}
        onChange={handleBuildingInputChange}
        onChoice={handleChoice}
        isValid={Boolean(props.buildingId)}
      />
      <Spacer factor={2} />
      <HorizontalDivider />
      <Spacer factor={2} />
      <ButtonsContainer>
        <Reset onClick={props.reset}>{t('commons:ctas.reset')}</Reset>
        <Button variant={'important'} onClick={props.close}>
          {props.isLoading
            ? t('filters.loading_amount_label')
            : t('filters.view_apartment', {
                count: props.totalCount,
              })}
        </Button>
      </ButtonsContainer>
    </Wrapper>
  )
}
