import { useContext, createContext, useState } from 'react'
import type { ReactNode } from 'react'

type DrawerContextProps = {
  children?: ReactNode
}

type DrawerActions = {
  isDrawerClosing: boolean
  setIsDrawerClosing: React.Dispatch<React.SetStateAction<boolean>>
}
const DrawerContext = createContext<DrawerActions>({
  isDrawerClosing: false,
  setIsDrawerClosing: () => undefined,
})

export function DrawerProvider(props: DrawerContextProps) {
  const { children } = props
  const [isDrawerClosing, setIsDrawerClosing] = useState(false)

  return (
    <DrawerContext.Provider
      value={{
        isDrawerClosing,
        setIsDrawerClosing,
      }}
    >
      {children}
    </DrawerContext.Provider>
  )
}

export const useDrawerContext = () => useContext(DrawerContext)
