import { useRef } from 'react'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { palette } from '../styling'
import { getPixelAmount } from '../styling/pixel-amount'
import { useDetectOutsideClick } from '../util-functions/use-detect-outside-click'

import { Button } from './button'
import { Loader } from './loader'

const Wrapper = styled.div({
  position: 'relative',
})

const DropdownActive = styled(Button)<{ isMenuOpen: boolean; noArrow?: boolean }>(
  ({ isMenuOpen, noArrow }) => ({
    padding: '0 20px',
    display: 'flex',
    justifyContent: noArrow ? 'center' : 'space-between',
    ...(isMenuOpen && {
      borderRadius: '4px 4px 0 0',
    }),
  }),
)

const DropdownArrow = styled(FontAwesomeIcon)({
  marginLeft: getPixelAmount(1),
})

const DropdownMenu = styled.div({
  left: 0,
  position: 'absolute',
  zIndex: 10,
  boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.3)',
  borderRadius: '0 0 8px 8px',
  border: `1px solid ${palette.blocketGrey}`,
})

const DropdownSelection = styled(Button).attrs({ secondary: true })({
  border: 'none',
  borderBottom: `1px solid ${palette.blocketGrey}`,
  backgroundColor: palette.white,
  display: 'flex',
  justifyContent: 'center',
  borderRadius: '0',
  fontSize: 16,
  minWidth: '65px',
  ':hover': {
    backgroundColor: palette.blocketGreyLight,
  },
  ':last-of-type': {
    borderBottom: 'none',
    borderRadius: '0 0 4px 4px',
  },
})

type DropdownButtonProps = {
  text?: string
  values: (string | number)[]
  value?: string | number
  disabled?: boolean
  isLoading?: boolean
  noArrow?: boolean
  secondary?: boolean
  third?: boolean
  buttonStyle?: Record<string, string | number>
  onSelect: (value: string | number) => void
}

export function DropdownButton({
  text,
  values,
  onSelect,
  value,
  isLoading = false,
  disabled = false,
  noArrow = false,
  secondary = false,
  third = false,
  buttonStyle,
}: DropdownButtonProps) {
  const dropDownRef = useRef(null)
  const [isMenuDropdownOpen, setIsMenuDropdownOpen] = useDetectOutsideClick(dropDownRef, false)

  const handleSelectionClick = (value: string | number) => {
    onSelect(value)
    setIsMenuDropdownOpen(false)
  }

  return (
    <Wrapper>
      <DropdownActive
        onClick={() => setIsMenuDropdownOpen(!isMenuDropdownOpen)}
        isMenuOpen={isMenuDropdownOpen}
        disabled={disabled}
        secondary={secondary}
        third={third}
        style={buttonStyle}
        type="button"
      >
        {isLoading ? <Loader /> : value ? value : text}
        {!noArrow && <DropdownArrow icon={['fal', isMenuDropdownOpen ? 'chevron-up' : 'chevron-down']} />}
      </DropdownActive>

      {isMenuDropdownOpen && (
        <DropdownMenu ref={dropDownRef}>
          {values
            .filter((val) => val !== value)
            .map((value) => (
              <DropdownSelection key={value} onClick={() => handleSelectionClick(value)} type="button">
                {value}
              </DropdownSelection>
            ))}
        </DropdownMenu>
      )}
    </Wrapper>
  )
}
