import {
  faCheck,
  faBars,
  faBell,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faAngleDown,
  faTimes,
  faPlus,
  faLightbulbOn,
  faInfoCircle,
  faExclamationCircle,
  faQuestionCircle,
  faCheckCircle,
  faLock,
  faPaperPlane,
  faPaperclip,
  faCalendarAlt,
  faLanguage,
  faShare,
  faCopy,
  faSearch,
  faFileContract,
  faCog,
  faUserCircle,
  faUserFriends,
  faCommentAlt,
  faHome,
  faShield,
  faHeart,
  faPaw,
  faBadge,
  faCoins,
  faUser,
  faFileAlt,
  faComments,
  faMapPin,
  faLongArrowUp,
  faLongArrowDown,
  faArrowRight,
  faTrashAlt,
  faGripLines,
  faTimesCircle,
  faPeopleCarry,
  faHouseLeave,
  faHouseDay,
  faHouse,
  faHouseNight,
  faHouseUser,
  faPawClaws,
  faPencil,
  faCircle,
  faWasher,
  faWifi,
  faTvRetro,
  faHamburger,
  faCarSide,
  faChargingStation,
  faHeat,
  faHotTub,
  faAnchor,
  faBicycle,
  faFireplace,
  faUmbrellaBeach,
  faGolfBall,
  faWater,
  faSmoking,
  faWheelchair,
  faUtensils,
  faTree,
  faDoorOpen,
  faSwimmingPool,
  faFish,
  faHiking,
  faSkiing,
  faTrees,
  faHatChef,
  faUserLock,
  faExchange,
  faMinus,
  faCouch,
  faSackDollar,
  faMapMarkerAlt,
  faCalendarCheck,
} from '@fortawesome/pro-light-svg-icons'
import {
  faBadgeCheck,
  faShieldCheck,
  faSort,
  faCheckCircle as checkFilled,
  faMap as faMapFilled,
  faList as faListFilled,
  faPen,
  faThumbtack as pinFilled,
  faStar as starFilled,
  faCircle as circleFilled,
  faPaw as pawFilled,
} from '@fortawesome/pro-solid-svg-icons'
import {
  faThumbtack as pin,
  faStar as star,
  faFilter,
  faChevronLeft as chevronLeftRegular,
  faAngleDown as angleDownRegular,
  faChevronDown as chevronDownRegular,
  faChevronRight as chevronRightRegular,
  faEllipsisV,
  faCheck as checkRegular,
  faExclamationTriangle,
  faSearch as searchRegular,
  faTrash as trashRegular,
  faArrowLeft,
  faBell as bellRegular,
  faBellSlash as bellSlashRegular,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components/macro'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'

import { basePalette, blocketPalette } from '../../styling/colors'
const StyledIcon = styled(FontAwesomeIcon)({
  fontSize: '1.25em',
})

type IconColor = {
  default: string
  informative: string
  usp: string
  verified: string
  interactive: string
  disabled: string
}
const colors: IconColor = {
  default: blocketPalette.black,
  informative: blocketPalette.red,
  usp: blocketPalette.red,
  verified: blocketPalette.red,
  interactive: blocketPalette.black,
  disabled: basePalette.grey60,
}

// For a visual overview see Design system in Figma https://www.figma.com/file/WlEAJF8b5wefLtwzgQ1JbR/Design-System-%F0%9F%8F%98%EF%B8%8F?node-id=80%3A22
export const ICONS = {
  mainMenu: faBars,
  close: faTimes,
  add: faPlus,
  minus: faMinus,
  checkRegular,
  checkmark: faCheck,
  left: faChevronLeft,
  right: faChevronRight,
  up: faChevronUp,
  down: faChevronDown,
  dropdown: faAngleDown,
  sort: faSort,
  tips: faLightbulbOn,
  info: faInfoCircle,
  caution: faExclamationCircle,
  help: faQuestionCircle,
  warning: faExclamationTriangle,
  checkCircle: faCheckCircle,
  checkFilled,
  locked: faLock,
  send: faPaperPlane,
  attachment: faPaperclip,
  datePicker: faCalendarAlt,
  language: faLanguage,
  menu: faEllipsisV,
  share: faShare,
  copy: faCopy,
  notification: faBell,
  magnifyingGlass: faSearch,
  leases: faFileContract,
  account: faCog,
  profile: faUserCircle,
  applications: faUserFriends,
  messages: faCommentAlt,
  listings: faHome,
  shield: faShield,
  shieldCheck: faShieldCheck,
  interests: faHeart,
  pets: faPaw,
  badge: faBadge,
  badgeCheck: faBadgeCheck,
  coins: faCoins,
  user: faUser,
  file: faFileAlt,
  comments: faComments,
  mapPin: faMapPin,
  longArrowUp: faLongArrowUp,
  longArrowDown: faLongArrowDown,
  arrowRight: faArrowRight,
  trash: faTrashAlt,
  gripLines: faGripLines,
  closeCircle: faTimesCircle,
  filter: faFilter,
  map: faMapFilled,
  list: faListFilled,
  moveIn: faPeopleCarry,
  moveOut: faHouseLeave,
  houseDay: faHouseDay,
  pen: faPen,
  pencil: faPencil,
  pin,
  pinFilled,
  star,
  starFilled,
  house: faHouse,
  houseNight: faHouseNight,
  houseUser: faHouseUser,
  petClaw: faPawClaws,
  circle: faCircle,
  chevronLeft: chevronLeftRegular,
  angleDown: angleDownRegular,
  chevronDown: chevronDownRegular,
  circleFilled,
  pawFilled,
  washingMachine: faWasher,
  internet: faWifi,
  television: faTvRetro,
  grill: faHamburger,
  parking: faCarSide,
  electricCarCharging: faChargingStation,
  sauna: faHeat,
  jacuzzi: faHotTub,
  boatKayak: faAnchor,
  bikes: faBicycle,
  fireplace: faFireplace,
  beach: faUmbrellaBeach,
  golf: faGolfBall,
  archipelago: faWater,
  smoking: faSmoking,
  wheelchair: faWheelchair,
  dishwasher: faUtensils,
  garden: faTree,
  balcony: faDoorOpen,
  pool: faSwimmingPool,
  fishing: faFish,
  hiking: faHiking,
  skiing: faSkiing,
  restaurants: faHatChef,
  remote: faTrees,
  chevronRight: chevronRightRegular,
  userLock: faUserLock,
  exchange: faExchange,
  search: searchRegular,
  trashRegular,
  arrowLeft: faArrowLeft,
  furniture: faCouch,
  moneybag: faSackDollar,
  mapMarker: faMapMarkerAlt,
  bellRegular,
  bellSlashRegular,
  calendarCheck: faCalendarCheck,
}

export type IconNames = keyof typeof ICONS

type IconProps = {
  icon: IconNames
  color?: keyof IconColor
  hasDynamicWidth?: boolean
  className?: string
}

/**
 * @deprecated Use icon components or `createIcon` function from `@qasa/qds-ui` instead.
 * For a list of the available icons [see the docs](https://qasa.supernova-docs.io/qds/latest/guidelines/foundations/iconography.html)
 */
export function Icon({ icon, color = 'default', hasDynamicWidth = false, className }: IconProps) {
  return (
    <StyledIcon
      icon={ICONS[icon] as IconProp}
      fixedWidth={!hasDynamicWidth}
      color={colors[color]}
      {...{ className }}
    />
  )
}
