import { Stack, Paragraph } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { useListingContext } from '../../context/listing-context'
import { formatDate } from '../../util-functions/date.util'
import { BumpedIcon } from '../../ui-shared/bump-icon'

import { ListingStatusIndicator } from './listing-status-indicator'

const Wrapper = Stack
const BumpedTimeStamp = Stack

export function ListingDates() {
  const { t } = useTranslation('listing')
  const { home } = useListingContext()
  const {
    status,
    archivedBecauseOfApplicationDeadlinePassed: isArchivedBecauseOfApplicationDeadlinePassed,
    publishedAt,
    lastBumpedAt,
  } = home || {}

  return (
    <Wrapper gap="3x" alignItems="flex-end">
      <ListingStatusIndicator
        status={status}
        archivedBecauseOfApplicationDeadlinePassed={isArchivedBecauseOfApplicationDeadlinePassed}
        publishedAt={publishedAt}
      />
      {lastBumpedAt && (
        <BumpedTimeStamp gap="1x" direction="row" alignItems="center">
          <BumpedIcon />
          <Paragraph>{t('bumped_date', { date: formatDate(new Date(lastBumpedAt || '')) })}</Paragraph>
        </BumpedTimeStamp>
      )}
    </Wrapper>
  )
}
