import { gql } from '@apollo/client'

const GET_APPLICATION_BOARD_LISTS = gql`
  query ApplicationBoardLists($homeId: ID!) {
    home(id: $homeId) {
      applicationBoardLists {
        id
        isDefault
        name
        order
        defaultType
        applicationCount
      }
      applicationBoardCardInfo {
        id
        showIdentification
        showIncome
        showLastMessage
        showQueueDays
        showMatchingScore
        showUc
      }
    }
  }
`

const CREATE_BOARD_LIST = gql`
  mutation CreateApplicationBoardList($homeId: ID!, $name: String!) {
    createApplicationBoardList(homeId: $homeId, name: $name) {
      errors {
        codes
      }
    }
  }
`

const UPDATE_BOARD_LIST = gql`
  mutation UpdateApplicationBoardList($updateApplicationBoardListId: ID!, $name: String!) {
    updateApplicationBoardList(id: $updateApplicationBoardListId, name: $name) {
      errors {
        codes
      }
    }
  }
`

const DESTROY_BOARD_LIST = gql`
  mutation destroyApplicationBoardList($destroyApplicationBoardListId: ID!) {
    destroyApplicationBoardList(id: $destroyApplicationBoardListId) {
      id
    }
  }
`

const UPSERT_APPLICATION_BOARD_CARD_INFO = gql`
  mutation upsertApplicationBoardCardInfo($homeId: ID!, $params: UpsertApplicationBoardCardInfoInput) {
    upsertApplicationBoardCardInfo(homeId: $homeId, params: $params) {
      errors {
        codes
      }
    }
  }
`

export const queries = {
  GET_APPLICATION_BOARD_LISTS,
}

export const mutations = {
  CREATE_BOARD_LIST,
  UPDATE_BOARD_LIST,
  DESTROY_BOARD_LIST,
  UPSERT_APPLICATION_BOARD_CARD_INFO,
}
