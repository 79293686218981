import styled from '@emotion/styled'
import { Paragraph, Stack, createStyleVariants } from '@qasa/qds-ui'

export enum Status {
  Positive = 'positive',
  Default = 'default',
  Negative = 'negative',
}

type VerificationBadgeProps = {
  details?: string
  label: string
  margin?: string | undefined
  status: Status
}

const getVerificationVariant = createStyleVariants((theme) => ({
  positive: {
    backgroundColor: theme.colors.core.blue20,
  },
  negative: {
    backgroundColor: theme.colors.core.red20,
  },
  default: {
    backgroundColor: theme.colors.core.yellow20,
  },
}))

const BadgeLabel = styled(Paragraph)<{ status: Status }>(({ theme, status }) => ({
  flexShrink: 0,
  color: theme.colors.text.default,
  padding: '5px',
  borderRadius: '5px',
  margin: 0,
  ...getVerificationVariant(theme)[status],
}))

export function VerificationBadge({ details, status, label }: VerificationBadgeProps) {
  return (
    <Stack direction="row" alignItems="center" gap="2x">
      <BadgeLabel size="xs" status={status}>
        {label}
      </BadgeLabel>
      {details && <Paragraph size="sm">{details}</Paragraph>}
    </Stack>
  )
}
