import { Stack, Paragraph } from '@qasa/qds-ui'

import { UserName } from '../applications/requests/user-name'

type LandlordPreviewProps = {
  fullName: string
  email: string
  phoneNumber: string | null
  profilePictureUrl?: string
}
export function LandlordPreview({ fullName, email, phoneNumber, profilePictureUrl }: LandlordPreviewProps) {
  return (
    <Stack gap={'1x'}>
      <UserName name={fullName} avatar={profilePictureUrl} />
      <div>
        <Paragraph>{email}</Paragraph>
        {phoneNumber && <Paragraph>{phoneNumber}</Paragraph>}
      </div>
    </Stack>
  )
}
