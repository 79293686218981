import styled from 'styled-components/macro'
import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { ContractPage, useContractContext } from '../../../context/contract-context'
import {
  ContractStatusEnum,
  ScriveInvitationOptionEnum,
} from '../../../data/graphql/types/__generated__/globalTypes'
import { Button } from '../../../ui-shared/_core/links-buttons'
import { Stack } from '../../../ui-shared/_core/stack'
import { INITIATE_CONTRACT_SIGN } from '../../../data/initiate-contract-sign'
import type {
  InitiateContractSignMutation,
  InitiateContractSignMutationVariables,
} from '../../../data/graphql/types/__generated__/InitiateContractSignMutation'
import { ErrorMessage } from '../../../ui-shared/_core/error-message'
import type {
  CancelContractSigning,
  CancelContractSigningVariables,
} from '../../../data/graphql/types/__generated__/CancelContractSigning'
import { CANCEL_CONTRACT_SIGNING } from '../../../data/cancel-contract-signing'

const Wrapper = Stack
const EditButton = styled(Button).attrs({ variant: 'outlined' })({})
const SendButton = styled(Button).attrs({ variant: 'money' })({
  marginLeft: 'auto',
})
export function ContractPreviewActions() {
  const { contract, openContract, refetchContract } = useContractContext()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const { t } = useTranslation('applicant', {
    keyPrefix: 'contract_preview_actions',
  })

  const [initiateSign, { loading: isLoading }] = useMutation<
    InitiateContractSignMutation,
    InitiateContractSignMutationVariables
  >(INITIATE_CONTRACT_SIGN, {
    onCompleted: (data) => {
      refetchContract()
      if (data.initiateContractSigning?.errors) {
        setErrorMessage(t('error_cannot_send'))
      }
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.log(error)
    },
  })

  const [cancelContractSigning] = useMutation<CancelContractSigning, CancelContractSigningVariables>(
    CANCEL_CONTRACT_SIGNING,
    {
      onCompleted: () => {
        refetchContract()
      },
      onError: (error) => {
        // eslint-disable-next-line no-console
        console.log(error)
      },
    },
  )

  const handleInitiateSign = () => {
    initiateSign({
      variables: {
        id: contract!.id,
        landlordInvitationMethod: ScriveInvitationOptionEnum.api,
        tenantInvitationMethod: ScriveInvitationOptionEnum.api,
        sendInitialInvitation: true,
      },
    })
  }

  const handleCancelSign = () => {
    cancelContractSigning({
      variables: {
        id: contract!.id,
      },
    })
  }
  const isContractInDraft = contract!.status === ContractStatusEnum.draft
  const isContractPending = contract!.status === ContractStatusEnum.pending

  return (
    <div>
      <Wrapper direction={'row'} gap={1}>
        {isContractInDraft && (
          <EditButton disabled={isLoading} onClick={() => openContract(ContractPage.EDIT)}>
            {t('edit')}
          </EditButton>
        )}
        <SendButton isLoading={isLoading} disabled={!isContractInDraft} onClick={handleInitiateSign}>
          {isContractInDraft ? t('send_to_parties') : t('sent_for_signing')}
        </SendButton>
        {isContractPending && (
          <Button isLoading={isLoading} onClick={handleCancelSign}>
            {t('cancel_sign')}
          </Button>
        )}
      </Wrapper>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  )
}
