import gql from 'graphql-tag'

const GET_BUILDING = gql`
  query BuildingForEditQuery($buildingId: ID!) {
    building(id: $buildingId) {
      id
      buildingName
      buildYear
      energyClass
      descriptionBuilding
      descriptionTransportation
      uploads {
        id
        url
      }
      location {
        route
        longitude
        latitude
        postalCode
        locality
        streetNumber
        googleMapsPlaceId
        countryCode
        country
      }
    }
  }
`

export const queries = { GET_BUILDING }
