import { gql } from '@apollo/client'

export const NEW_APPLICATIONS = gql`
  query NewApplicationsQuery(
    $userRole: HomeUserRoleEnum
    $orderBy: HomeApplicationOrderEnum
    $first: Int
    $status: [HomeApplicationStatusEnum!]
    $origin: [HomeApplicationOriginEnum!]
    $after: String
  ) {
    homeApplications(
      userRole: $userRole
      orderBy: $orderBy
      first: $first
      status: $status
      origin: $origin
      after: $after
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          createdAt
          tenantFamilyName
          tenant {
            uid
            firstName
            profilePicture {
              url
            }
            userNotes {
              note
            }
            homeApplicationsToMyHomes {
              matchInterest
            }
            currentIdentification {
              identityConfirmed
              identityConfirmedAt
            }
            incomeVerifiedAt
            creditReport {
              id
              fetchStatus
              numberPaymentRemarks
            }
          }
          home {
            id
            location {
              route
              streetNumber
            }
            apartmentNumber
            duration {
              startAsap
              startOptimal
            }
          }
          status
          startOptimal
        }
        cursor
      }
    }
  }
`
