import { useTranslation } from 'react-i18next'

import { ContractPage, useContractContext } from '../../context/contract-context'
import { Button } from '../../ui-shared/_core/links-buttons'
import { Loader } from '../../ui-shared/_core/loader'

import { ContractCreate } from './contract-create'
import { ContractEdit } from './contract-edit'
import { ContractPreview } from './contract-preview'

export function ContractContent() {
  const { currentPage, openContract, isKickOffDataLoading } = useContractContext()

  const { t } = useTranslation('applicant', {
    keyPrefix: 'contract_form',
  })

  if (isKickOffDataLoading) return <Loader />

  switch (currentPage) {
    case ContractPage.NO_CONTRACT:
      return <Button onClick={() => openContract(ContractPage.KICKOFF)}>{t('create_draft')}</Button>
    case ContractPage.KICKOFF:
      return <ContractCreate />
    default:
    case ContractPage.PREVIEW:
      return <ContractPreview />
    case ContractPage.EDIT:
      return <ContractEdit />
  }
}
