import gql from 'graphql-tag'

const GET_APARTMENT = gql`
  query ApartmentQuery($homeTemplateId: ID!) {
    homeTemplate(id: $homeTemplateId) {
      apartmentNumber
      rent
      seniorHome
      studentHome
      firsthand
      corporateHome
      squareMeters
      roomCount
      tenantCount
      description
      floor
      kitchenRenovationYear
      bathroomRenovationYear
      type
      publishedHomes {
        id
      }
      traits {
        type
        id
      }
      location {
        longitude
        latitude
        locality
        streetNumber
        postalCode
        route
      }
      uploads {
        type
        url
        metadata {
          primary
          order
        }
      }
      building {
        id
        buildingName
        descriptionBuilding
        descriptionTransportation
        buildYear
        energyClass
        location {
          streetNumber
          route
          postalCode
          longitude
          latitude
          locality
        }
        uploads {
          url
          metadata {
            primary
          }
        }
      }
    }
  }
`

export const queries = { GET_APARTMENT }
