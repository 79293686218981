import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Stack } from '../../../ui-shared/_core/stack'
import { RadioButton } from '../../../ui-atoms/radio-button'

import { ContractFormInputHeader } from './contract-form-input-header'
import { ContractFormSection } from './contract-form-section'

export function ContractFormAboutHome() {
  const { control } = useFormContext()
  const { t } = useTranslation('applicant', {
    keyPrefix: 'contract_form',
  })

  return (
    <ContractFormSection title={t('about_home_title')}>
      <Stack gap={1}>
        <ContractFormInputHeader label={t('is_aparment_furnished')} />
        <Controller
          name={'furniture'}
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <>
                <RadioButton
                  label={t('aparment_is_furnished')}
                  isChecked={value}
                  onClick={() => onChange(true)}
                />
                <RadioButton
                  label={t('aparment_not_furnished')}
                  isChecked={!value}
                  onClick={() => onChange(false)}
                />
              </>
            )
          }}
        />
      </Stack>
    </ContractFormSection>
  )
}
