import { useEffect } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components/macro'
import type { ReactNode } from 'react'

import { defaultStyles } from '../styling/defaults'
import { palette } from '../styling'

type ContextMenuProps = {
  isOpen: boolean
  children: ReactNode
  onClose: () => void
  position?: { x?: number; y?: number }
  zIndex?: number
  right?: number | string
}

const Wrapper = styled.div<Partial<ContextMenuProps>>(({ isOpen, zIndex }) => ({
  display: 'none',
  position: 'fixed',
  transition: 'opacity 0.2',
  opacity: 0,
  backgroundColor: palette.shadow,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: zIndex ? zIndex : 10,
  overflow: 'auto',
  ...(isOpen && {
    display: 'block',
    animation: 'fadeIn 0.2s forwards',
  }),
  '@keyframes fadeIn': {
    from: {
      opacity: '0',
    },
    to: {
      opacity: '1',
    },
  },
}))

const ContextMenuWrapper = styled.div<Partial<ContextMenuProps>>(({ position, right }) => ({
  position: 'absolute',
  ...(position
    ? {
        top: position?.y,
        left: position?.x,
      }
    : {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }),
  right,
  borderRadius: defaultStyles.borderRadius,
  backgroundColor: palette.white,
  boxShadow: `0 0 5px ${palette.shadow}`,
  animation: `fadeIn 0.2s forwards`,
}))

export const ContextMenu = (props: ContextMenuProps) => {
  useEffect(() => {
    if (props.isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return createPortal(
    <Wrapper {...props} onMouseDown={props.onClose}>
      <ContextMenuWrapper
        onMouseDown={(e) => e.stopPropagation()}
        position={props.position}
        right={props.right}
      >
        {props.isOpen && props.children}
      </ContextMenuWrapper>
    </Wrapper>,
    document.getElementById('context-menu-root')!,
  )
}
