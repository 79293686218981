import { useLazyQuery, useQuery } from '@apollo/client'
import type { ChangeEvent, Dispatch, RefObject, SetStateAction } from 'react'
import { useState, useRef, useEffect } from 'react'
import debounce from 'lodash/debounce'
import { useLocation, useNavigate } from 'react-router-dom'

import type {
  ApartmentsQuery,
  ApartmentsQueryVariables,
} from '../../../data/graphql/types/__generated__/ApartmentsQuery'
import type {
  BuildingQuery,
  BuildingQueryVariables,
} from '../../../data/graphql/types/__generated__/BuildingQuery'
import { queries } from '../../../data/my-homes'
import { queries as buildingQueries } from '../../../data/building'
import type { RoomCountFilterType } from '../room-count-filter'
import type { SizeFilterType } from '../size-filter'
import { HomeTemplateOrderEnum } from '../../../data/graphql/types/__generated__/globalTypes'
import { useFetchMoreOnScrollToBottom } from '../../../hooks/use-fetch-more-on-scroll-to-bottom'

import type { ApartmentFilterAttribute } from './apartments-table'

const BULK_SIZE = 20
const START_COUNT = 50

const initialHomeTemplateArgs = {
  homeTemplatesAfter: null,
  homeTemplatesBuildingId: null,
  homeTemplatesFirst: START_COUNT,
  maxRoomCount: null,
  maxSquareMeters: null,
  minRoomCount: null,
  minSquareMeters: null,
  homeTemplatesOrderBy: HomeTemplateOrderEnum.UPDATED_LATEST,
  homeTemplatesSearchString: '',
}

type GetApartmentsDataParams = {
  setTotalCount: Dispatch<SetStateAction<number | undefined>>
  setChosenApartments: Dispatch<SetStateAction<Array<string>>>
  scrollElementRef: RefObject<HTMLTableElement>
}
export function useGetApartmentsData({
  setTotalCount,
  setChosenApartments,
  scrollElementRef,
}: GetApartmentsDataParams) {
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)
  const [homeTemplateVars, setHomeTemplateVars] = useState<ApartmentsQueryVariables>(initialHomeTemplateArgs)
  const {
    loading: isLoading,
    error,
    data,
    fetchMore,
    refetch,
  } = useQuery<ApartmentsQuery, ApartmentsQueryVariables>(queries.GET_APARTMENTS, {
    variables: initialHomeTemplateArgs,
    notifyOnNetworkStatusChange: true,
  })
  const [getBuilding, { data: buildingData }] = useLazyQuery<BuildingQuery, BuildingQueryVariables>(
    buildingQueries.GET_BUILDING,
  )
  const { handleScroll } = useFetchMoreOnScrollToBottom({
    handleFetchMore: () =>
      fetchMore({
        variables: {
          homeTemplatesAfter: data?.homeTemplates.pageInfo.endCursor,
          homeTemplatesFirst: BULK_SIZE,
        },
      }),
    shouldFetchMore: data?.homeTemplates.pageInfo.hasNextPage,
  })

  useEffect(() => {
    if (setTotalCount) {
      setTotalCount(data?.homeTemplates.totalCount)
    }

    return () => {
      if (setTotalCount) {
        setTotalCount(undefined)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.homeTemplates.totalCount])

  useEffect(() => {
    const buildingId = searchParams.get('buildingId')
    if (buildingId) {
      refetchApartments({ homeTemplatesBuildingId: buildingId })
      getBuilding({ variables: { buildingId } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const refetchApartments = async (variables: ApartmentsQueryVariables) => {
    // reset scroll on refetch to prevent scrollBottom functionality from being called unnecessarily
    const scrollLeft = scrollElementRef?.current?.scrollLeft ?? 0
    scrollElementRef?.current?.scrollTo(scrollLeft, 0)
    setChosenApartments([])
    setHomeTemplateVars((prev) => ({
      ...prev,
      ...variables,
      homeTemplatesAfter: null,
      homeTemplatesFirst: START_COUNT,
    }))
    await refetch({
      ...variables,
      homeTemplatesAfter: null,
      homeTemplatesFirst: START_COUNT,
    })
  }

  const filterByBuildingId = (newBuildingId?: string) => {
    refetchApartments({
      homeTemplatesBuildingId: newBuildingId,
    })
    if (newBuildingId) {
      getBuilding({ variables: { buildingId: newBuildingId } })
    } else if (searchParams.get('buildingId') !== null) {
      navigate('/homes/apartments', { replace: true })
    }
  }

  const delaySearchRef = useRef(
    debounce((searchString) => {
      refetchApartments({
        homeTemplatesSearchString: searchString,
      })
    }, 250),
  )
  const onSearch = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value: homeTemplatesSearchString } = event.target
    // setting the variable before debounce so the input can show correct value without lag
    setHomeTemplateVars((prev) => ({
      ...prev,
      homeTemplatesSearchString,
    }))
    delaySearchRef.current(homeTemplatesSearchString)
  }

  const resetFilterAttributes = (resetFilterAttributes: Array<ApartmentFilterAttribute>) => {
    const filterAtributesToReset = resetFilterAttributes.reduce(
      (acc: Record<ApartmentFilterAttribute, undefined>, value) => {
        acc[value] = undefined
        return acc
      },
      {} as Record<ApartmentFilterAttribute, undefined>,
    )
    refetchApartments({
      ...filterAtributesToReset,
    })
  }

  const addFilter = (newFilter: RoomCountFilterType | SizeFilterType) => {
    refetchApartments({
      ...newFilter,
    })
  }

  const handleChangeSorting = (e: ChangeEvent<HTMLSelectElement>) => {
    const orderBy = e.target.value as HomeTemplateOrderEnum
    refetchApartments({
      homeTemplatesOrderBy: orderBy,
    })
  }

  return {
    resetFilterAttributes,
    addFilter,
    filterByBuildingId,
    onSearch,
    onSort: handleChangeSorting,
    apartmentsData: {
      isLoading,
      error,
      data,
    },
    buildingData,
    handleScroll,
    homeTemplateVars,
  }
}
