import { gql } from '@apollo/client'

export const homeApplication = gql`
  fragment BaseHomeApplication on HomeApplication {
    id
    message
    createdAt
    endOptimal
    endUfn
    matchInterest
    home {
      ...BaseHome
    }
    startOptimal
    status
    origin
    tenant {
      ...BaseUser
    }
    conversation {
      id
    }
  }
`
