import React from 'react'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Link } from '../../ui-shared/_core/links-buttons'
import { HorizontalDivider, Spacer } from '../../ui-atoms/help-components'
import { SmallHeader } from '../../ui-atoms/small-header'
import type { ApartmentQuery_homeTemplate } from '../../data/graphql/types/__generated__/ApartmentQuery'
import { SmallData } from '../../ui-atoms/small-data'
import { Map } from '../../ui-atoms/map'
import { formatApartmentSize, formatLocation } from '../../util-functions/general.util'
import { ContractTypesEnum } from '../../types/contract-types'
import { palette } from '../../styling'
import { VerticalSpacer } from '../../ui-atoms/help-components/spacer'
import { UploadTypeEnum } from '../../data/graphql/types/__generated__/globalTypes'
import { ObjectImage, ImagesContainer } from '../../ui-atoms/object-image'
import { getPixelAmount } from '../../styling/pixel-amount'
import { EndSpace } from '../applications/overview/overview'
import { getTraits } from '../../util-functions/traits.util'

const Wrapper = styled.div({
  flexGrow: 1,
  overflow: 'auto',
  position: 'relative',
})

const Trait = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const TraitGrid = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 250px)',
  gridAutoRows: '35px',
})

const EditLink = styled(Link)({
  position: 'absolute',
  right: 0,
  top: getPixelAmount(3),
})

type TParams = { apartmentId: string }

export function ApartmentInfo(props: ApartmentQuery_homeTemplate) {
  const params = useParams<TParams>()

  const images = {
    apartmentPictures: props.uploads.filter((upload) => upload.type === UploadTypeEnum.home_picture),
    layoutPictures: props.uploads.filter((upload) => upload.type === UploadTypeEnum.home_layout_picture),
  }

  const getContractType = () => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { studentHome, seniorHome, corporateHome, firsthand } = props

    if (firsthand) return ContractTypesEnum.firsthand
    if (seniorHome) return ContractTypesEnum.seniorHome
    if (studentHome) return ContractTypesEnum.studentHome
    if (corporateHome) return ContractTypesEnum.corporateHome

    return ContractTypesEnum.secondhand
  }

  const { t } = useTranslation(['apartment', 'commons'])

  return (
    <Wrapper>
      <EditLink variant={'text'} to={`/homes/apartments/edit/${params.apartmentId}`}>
        {t('commons:actions.edit_apartment')}
      </EditLink>
      <Spacer factor={3} />
      <SmallHeader>{t('details')}</SmallHeader>
      <Spacer factor={1} />
      <SmallData>{t(`commons:home_types.${props.type}`) + ' • ' + props.apartmentNumber}</SmallData>
      <Spacer factor={4} />
      <SmallHeader>{t('apartment_form.address_label')}</SmallHeader>
      <Spacer factor={1} />
      <SmallData>{formatLocation(props.location!)}</SmallData>
      <Spacer factor={1} />
      <Map
        width={400}
        isInteractionDisabled={true}
        markers={[
          {
            longitude: props.location!.longitude!,
            latitude: props.location!.latitude!,
            dragable: false,
          },
        ]}
      />
      <Spacer factor={4} />
      <SmallHeader>{t('apartment_form.size_label')}</SmallHeader>
      <Spacer factor={1} />
      <SmallData>{formatApartmentSize(props.roomCount, props.squareMeters)}</SmallData>
      <Spacer factor={4} />
      <SmallHeader>{t('apartment_form.rent_details_label')}</SmallHeader>
      <Spacer factor={1} />
      <SmallData>
        {props.rent ? `${props.rent} SEK • ` : ''}
        {t(`commons:contract_types.${getContractType()}`)}
      </SmallData>
      <Spacer factor={3} />
      <HorizontalDivider />
      <Spacer factor={4} />
      <SmallHeader>{t('apartment_form.amenities_label')}</SmallHeader>
      <Spacer factor={1} />
      {props.traits.length > 0 ? (
        <TraitGrid>
          {props.traits.map((trait) => (
            <React.Fragment key={trait.id}>
              <Trait>
                <FontAwesomeIcon icon={['fal', 'check-circle']} style={{ color: palette.primary }} />
                <VerticalSpacer factor={1} />
                <SmallData style={{ display: 'flex' }}>{getTraits(trait.type)}</SmallData>
              </Trait>
            </React.Fragment>
          ))}
        </TraitGrid>
      ) : (
        <SmallData transparent>{t('missing_info.no_amenities_added')}</SmallData>
      )}
      <Spacer factor={4} />
      <SmallHeader>{t('apartment_form.description_label')}</SmallHeader>
      <Spacer factor={1} />
      {props.description ? (
        props.description
          .split('\n')
          .map((descriptionLine, index) => <SmallData key={index}>{descriptionLine}</SmallData>)
      ) : (
        <SmallData transparent>{t('missing_info.no_description')}</SmallData>
      )}
      <Spacer factor={4} />
      <SmallHeader>{t('apartment_form.floor_label')}</SmallHeader>
      <Spacer factor={1} />
      {props.floor !== null || props.floor !== undefined ? ( //Because it can be 0
        <SmallData>{props.floor}</SmallData>
      ) : (
        <SmallData transparent>{t('missing_info.no_floor')}</SmallData>
      )}
      <Spacer factor={4} />
      <SmallHeader>{t('apartment_form.latest_kitchen_renovation_label')}</SmallHeader>
      <Spacer factor={1} />
      {props.kitchenRenovationYear ? (
        <SmallData>{props.kitchenRenovationYear}</SmallData>
      ) : (
        <SmallData transparent>{t('missing_info.no_kitchen_renovation_year')}</SmallData>
      )}
      <Spacer factor={4} />
      <SmallHeader>{t('apartment_form.latest_bath_renovation_label')}</SmallHeader>
      <Spacer factor={1} />
      {props.kitchenRenovationYear ? (
        <SmallData>{props.bathroomRenovationYear}</SmallData>
      ) : (
        <SmallData transparent>{t('missing_info.no_bath_renovation_year')}</SmallData>
      )}
      <Spacer factor={4} />

      <HorizontalDivider />

      <Spacer factor={4} />

      <SmallHeader>{t('apartment_form.apartment_images_label')}</SmallHeader>
      <Spacer factor={1} />

      {images.apartmentPictures.length > 0 ? (
        <ImagesContainer>
          {images.apartmentPictures.map((upload) => (
            <ObjectImage src={upload.url} key={upload.url} />
          ))}
        </ImagesContainer>
      ) : (
        <SmallData transparent>{t('missing_info.no_images_added')}</SmallData>
      )}

      <Spacer factor={4} />

      <SmallHeader>{t('apartment_form.layout_images_label')}</SmallHeader>
      <Spacer factor={1} />

      {images.layoutPictures.length > 0 ? (
        <ImagesContainer>
          {images.layoutPictures.map((upload) => (
            <ObjectImage key={upload.url} src={upload.url} />
          ))}
        </ImagesContainer>
      ) : (
        <SmallData transparent>{t('missing_info.no_images_added')}</SmallData>
      )}

      <EndSpace />
    </Wrapper>
  )
}
