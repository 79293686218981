import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown'

const Chevron = styled(FontAwesomeIcon)<{
  $isOpen: boolean
}>(({ $isOpen }) => ({
  transform: $isOpen ? 'rotate(-180deg)' : 'initial',
  transition: 'transform 0.3s ease-in-out',
  marginLeft: 8,
}))

type RotatingChevronProps = { onClick?: () => void; isOpen: boolean; className?: string }

export function RotatingChevron({ onClick, isOpen, className }: RotatingChevronProps) {
  return (
    <Chevron
      onClick={onClick ? onClick : () => null}
      $isOpen={isOpen}
      icon={faChevronDown}
      {...{ className }}
    />
  )
}
