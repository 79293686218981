import { createIcon } from '@qasa/qds-ui'

export const OverviewIcon = createIcon({
  displayName: 'OverviewIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9Z"
        stroke="#000"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <path
        d="M9 22V12h6v10"
        stroke="#000"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
    </>
  ),
})

export const ApplicationsIcon = createIcon({
  displayName: 'ApplicationsIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d="M16 18a4 4 0 1 0-8 0"
        stroke="#000"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <path
        d="M12 14a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
        stroke="#000"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <path
        d="M19 4H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2ZM8 2v2m8-2v2"
        stroke="#000"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
    </>
  ),
})

export const ListingsIcon = createIcon({
  displayName: 'ListingsIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d="M15 2.172H9a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1Z"
        stroke="#342620"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <path
        d="M16 4.172h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-14a2 2 0 0 1 2-2h2m4 7h4m-4 5h4m-8-5h.01m-.01 5h.01"
        stroke="#342620"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
    </>
  ),
})

export const BuildingsIcon = createIcon({
  displayName: 'BuildingsIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d="M18 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2Z"
        stroke="#000"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <path
        d="M9 22v-4h6v4M8 6h.01M16 6h.01M12 6h.01M12 10h.01M12 14h.01M16 10h.01M16 14h.01M8 10h.01M8 14h.01"
        stroke="#000"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
    </>
  ),
})

export const HelpcenterIcon = createIcon({
  displayName: 'HelpcenterIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d="M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7 7-7Z"
        stroke="#000"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <path
        d="M12 5 9.04 7.96a2.17 2.17 0 0 0 0 3.08c.82.82 2.13.85 3 .07l2.07-1.9a2.82 2.82 0 0 1 3.79 0l2.96 2.66M18 15l-2-2m-1 5-2-2"
        stroke="#000"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
    </>
  ),
})

export const ArrowLeftFromLineIcon = createIcon({
  displayName: 'ArrowLeftFromLineIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      d="m9 6.897-6 6 6 6m-6-6h14m4 7v-14"
      stroke="#342620"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
  ),
})

export const ArrowRightFromLineIcon = createIcon({
  displayName: 'ArrowRightFromLineIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      d="m15 6 6 6-6 6m6-6H7m-4 7V5"
      stroke="#342620"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
  ),
})

export const SignOutIcon = createIcon({
  viewBox: '0 0 25 22',
  path: (
    <path
      d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4m-5-4 5-5-5-5m5 5H3"
      stroke="#000"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
  ),
  displayName: 'SignOutIcon',
})
