import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

import { Checkbox } from '../../ui-atoms/checkbox'
import { VerticalSpacer } from '../../ui-atoms/help-components/spacer'
import { palette } from '../../styling/index'
import { getPixelAmount } from '../../styling/pixel-amount'
import { DropdownButton } from '../../ui-atoms/dropdown-button'

import type { FormValues } from './update-home-dialog'

const Wrapper = styled.div({})

const RequirementRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  height: 50,
})

const IncomeTimesBox = styled.div({
  width: 300,
  height: 50,
  borderRadius: '0 4px 4px 0',
  border: `1px solid ${palette.blocketGrey}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const IncomeDropdown = styled(DropdownButton).attrs({ noArrow: true, secondary: true })({
  left: 0,
  top: 0,
  width: getPixelAmount(6),
  height: getPixelAmount(6),
})

export function ListingRentalRequirements() {
  const { t } = useTranslation(['listing', 'applications'])
  const { control, watch } = useFormContext<FormValues>()

  const hasVerifiedIncome = watch('rentalRequirements.verifiedIncome')

  return (
    <Wrapper>
      <RequirementRow>
        <Controller
          name="rentalRequirements.verifiedIdNumber"
          control={control}
          render={({ field: { onChange, value, name } }) => {
            return (
              <Checkbox
                name={name}
                label={t('applications:verifications_filter.verified_id')}
                type="thumb"
                isChecked={Boolean(value)}
                onClick={() => onChange(!value)}
              />
            )
          }}
        />
      </RequirementRow>
      <RequirementRow>
        <Controller
          name="rentalRequirements.verifiedIncome"
          control={control}
          render={({ field: { onChange, value, name } }) => {
            return (
              <Checkbox
                name={name}
                label={t('applications:verifications_filter.verified_income')}
                type="thumb"
                isChecked={Boolean(value)}
                onClick={() => onChange(!value)}
              />
            )
          }}
        />
        {hasVerifiedIncome && (
          <>
            <VerticalSpacer factor={2} />
            <Controller
              name="rentalRequirements.rentMultiplier"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <>
                    <IncomeDropdown
                      value={value + 'x' || '2x'}
                      values={['1.5x', '2x', '2.5x', '3x', '3.5x', '4x', '4.5x', '5x']}
                      onSelect={(newValue) => onChange(parseFloat(newValue as string))}
                      buttonStyle={{
                        borderRadius: '4px 0 0 4px',
                        borderRight: 'none',
                        width: '65px',
                        justifyContent: 'center',
                      }}
                    />
                    <IncomeTimesBox>
                      {t('rental_requirements.rent_multiplier_description', {
                        rentMultiplier: value,
                      })}
                    </IncomeTimesBox>
                  </>
                )
              }}
            />
          </>
        )}
      </RequirementRow>
      <RequirementRow>
        <Controller
          name="rentalRequirements.approvedCreditCheck"
          control={control}
          render={({ field: { onChange, value, name } }) => {
            return (
              <Checkbox
                name={name}
                label={t('applications:verifications_filter.no_payment_remarks')}
                type="thumb"
                isChecked={Boolean(value)}
                onClick={() => onChange(!value)}
              />
            )
          }}
        />
      </RequirementRow>
    </Wrapper>
  )
}
