import gql from 'graphql-tag'

const EDIT_LISTING = gql`
  mutation UpdateHomeMutation($updateHomeId: ID!, $updateHomeInput: UpdateHomeInput!) {
    updateHome(id: $updateHomeId, input: $updateHomeInput) {
      home {
        id
      }
      errors {
        codes
        field
      }
    }
  }
`

const UPSERT_RENTAL_REQUIREMENT = gql`
  mutation UpsertRentalRequirement($homeId: ID!, $rentalRequirementInput: UpsertRentalRequirementInput!) {
    upsertRentalRequirement(homeId: $homeId, input: $rentalRequirementInput) {
      rentalRequirement {
        id
        approvedCreditCheck
        createdAt
        rentMultiplier
        updatedAt
        verifiedIdNumber
        verifiedIncome
      }
    }
  }
`

const UPDATE_SHORTCUT = gql`
  mutation UpdateShortcut($updateShortcutId: ID!, $input: UpdateShortcutInput!) {
    updateShortcut(id: $updateShortcutId, input: $input) {
      shortcut {
        id
      }
      errors {
        codes
        field
      }
    }
  }
`

const SUGGESTED_DESCRIPTION = gql`
  query SuggestedDescription($homeId: ID!) {
    homeEvaluation(homeId: $homeId) {
      suggestedDescription
    }
  }
`

export const queries = { SUGGESTED_DESCRIPTION }

export const mutations = {
  EDIT_LISTING,
  UPSERT_RENTAL_REQUIREMENT,
  UPDATE_SHORTCUT,
}
