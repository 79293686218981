import { Stack, Heading, Divider, Spacer, Link } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import { EditDialog } from '@qasa/app/src/modules/edit-dialog'
import styled from '@emotion/styled'
import { Link as RouterLink } from 'react-router-dom'

import { ApartmentDetailsBlock } from '../apartment/apartment-details-block'
import { ApartmentDescription } from '../apartment/apartment-description'
import { ListingSectionEnum, useListingContext } from '../../context/listing-context'
import { Explanation } from '../../ui-atoms/explanation-text'

const LeftContainer = styled(Stack)({
  flexGrow: 1,
})
const RightContainer = Stack

export function ApartmentContentBlockListing() {
  const { t } = useTranslation(['apartment', 'commons'])
  const { setIsEditDialogOpen, setActiveEditSection, home } = useListingContext()

  return (
    <Stack gap="12x" divider={<Divider />}>
      <Stack direction="row" justifyContent="space-between">
        <LeftContainer gap="6x">
          <Heading>{t('commons:apartment_other')}</Heading>
          <ApartmentDetailsBlock />
        </LeftContainer>
        <RightContainer>
          <EditDialog.Trigger
            onClick={() => {
              setIsEditDialogOpen(true)
              setActiveEditSection(ListingSectionEnum.APARTMENT_DETAILS)
            }}
          />
        </RightContainer>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <LeftContainer>
          <ApartmentDescription />
        </LeftContainer>
        <RightContainer>
          <EditDialog.Trigger
            onClick={() => {
              setIsEditDialogOpen(true)
              setActiveEditSection(ListingSectionEnum.DESCRIPTION)
            }}
          />
        </RightContainer>
      </Stack>
      <Stack gap="1x">
        <Heading size="3xs">{t('apartment_form.amenities_label')}</Heading>
        <Spacer size="2x" />
        <Explanation>
          <Stack gap="2x" direction="row">
            {t('apartment_form.amenities_group_listing')}
            <Link as={RouterLink} to={`/listings/${home.id}/apartments`}>
              {t('commons:actions.view_apartments')}
            </Link>
          </Stack>
        </Explanation>
      </Stack>
    </Stack>
  )
}
