import { useMutation } from '@apollo/client'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { useContractContext, ContractPage } from '../../context/contract-context'
import type { UpsertContractInput } from '../../data/graphql/types/__generated__/globalTypes'
import type {
  UpsertContractMutation,
  UpsertContractMutationVariables,
} from '../../data/graphql/types/__generated__/UpsertContractMutation'
import { UPSERT_CONTRACT } from '../../data/upsert-contract'
import { Spacer } from '../../ui-atoms/help-components'
import { Button } from '../../ui-shared/_core/links-buttons'
import { Stack } from '../../ui-shared/_core/stack'
import { Typography } from '../../ui-shared/_core/typography'

import { ContractPreviewHome } from './contract-form/contract-preview-home'
import { ContractPreviewLandlord } from './contract-form/contract-preview-landlord'
import { ContractFormRentField } from './contract-form/contract-form-rent-field'
import { ContractFormDate } from './contract-form/contract-form-date'
import { ContractFormTenureType } from './contract-form/contract-form-tenure-type'
import { ContractFormOwnHome } from './contract-form/contract-form-own-home'
import { ContractFormAboutRent } from './contract-form/contract-form-about-rent'
import { ContractFormAboutHome } from './contract-form/contract-form-about-home'
import { ContractFormOtherTerms } from './contract-form/contract-form-other-terms'

const StyledButton = styled(Button)({
  width: '100%',
})

const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.error100,
}))

export type ContractFormValues = Pick<
  UpsertContractInput,
  | 'homeId'
  | 'rent'
  | 'duration'
  | 'tenantUid'
  | 'qasaGuarantee'
  | 'insurance'
  | 'tenureType'
  | 'ownHome'
  | 'canUse'
  | 'rentIncludes'
  | 'rentExcludes'
  | 'landlordHasKeys'
  | 'smallRepairs'
  | 'insuranceResponsibility'
  | 'overnightStay'
  | 'pets'
  | 'smoker'
  | 'furniture'
>

const CONTRACT_FIELDS = [
  ContractFormRentField,
  ContractFormDate,
  ContractFormTenureType,
  ContractFormOwnHome,
  ContractFormAboutRent,
  ContractFormAboutHome,
  ContractFormOtherTerms,
]

type ContractFormProps = {
  isEdit?: boolean
  onCompletedUpsert: () => void
  defaultValues: ContractFormValues
}
export function ContractForm({ isEdit = false, defaultValues, onCompletedUpsert }: ContractFormProps) {
  const { contract, openContract } = useContractContext()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const { t } = useTranslation(['applicant', 'commons'])

  const methods = useForm<ContractFormValues>({
    defaultValues,
    mode: 'all',
  })
  const [upsertContract] = useMutation<UpsertContractMutation, UpsertContractMutationVariables>(
    UPSERT_CONTRACT,
    {
      onCompleted: () => {
        onCompletedUpsert()
      },
      onError: (e) => {
        setErrorMessage(
          isEdit ? t('contract_form.cannot_save_draft') : t('contract_form.cannot_create_draft'),
        )
        // eslint-disable-next-line no-console
        console.error(e)
      },
      refetchQueries: ['HomeApplicationOverview'],
    },
  )

  const onSubmit = (formValues: ContractFormValues) => {
    upsertContract({
      variables: {
        input: formValues,
        id: contract?.id,
      },
    })
  }

  return (
    <FormProvider {...methods}>
      <Stack gap={8} hasDivider>
        <ContractPreviewHome />
        <ContractPreviewLandlord />
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack gap={4}>
            {CONTRACT_FIELDS.map((ContractField, index) => (
              <ContractField key={index} />
            ))}
            {errorMessage && <ErrorMessage variant={'bodySmallBold'}>{errorMessage}</ErrorMessage>}
            <Stack direction="row" gap={1}>
              {isEdit && (
                <StyledButton
                  variant="outlined"
                  type="button"
                  onClick={() => openContract(ContractPage.PREVIEW)}
                >
                  {t('commons:ctas.cancel')}
                </StyledButton>
              )}
              <StyledButton variant="money">
                {isEdit ? t('commons:ctas.save') : t('contract_form.create_draft')}
              </StyledButton>
            </Stack>
          </Stack>
          <Spacer factor={8} />
        </form>
      </Stack>
    </FormProvider>
  )
}
