import { useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { Stack } from '@qasa/qds-ui'

import { mutations } from '../data/reset-password'
import { Button } from '../ui-atoms/button'
import { FormWindow } from '../ui-atoms/form-window'
import { Spacer } from '../ui-atoms/help-components'
import { Input } from '../ui-atoms/input'
import { fontSize } from '../styling'

import { UnauthenticatedPageWrapper } from './login'

const SuccessText = styled.p({
  fontSize: fontSize.textLg,
})

type TParams = { resetPasswordToken: string }

export function ResetPassword() {
  const params = useParams<TParams>()
  const { t } = useTranslation('password')
  const { resetPasswordToken } = params

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [hasPasswordChanged, setPasswordChanged] = useState(false)

  const [resetPassword, { data: resetData }] = useMutation(mutations.RESET_PASSWORD)

  useEffect(() => {
    if (resetData !== undefined) {
      if (resetData.subUserResetPassword.success) {
        setPasswordChanged(true)
      }
    }
  }, [resetData])

  const passwordsMatch = () => {
    return (password !== '' || confirmPassword !== '') && password === confirmPassword
  }

  if (hasPasswordChanged) {
    return (
      <UnauthenticatedPageWrapper>
        <Stack gap="2x">
          <SuccessText>{t('reset_password.confirmation_message')}</SuccessText>
          <Link to="/">
            <Button width="180px">{t('return_to_login_after_confirmation')}</Button>
          </Link>
        </Stack>
      </UnauthenticatedPageWrapper>
    )
  }

  return (
    <UnauthenticatedPageWrapper>
      <FormWindow>
        <Input
          name="password"
          type="password"
          placeholder={t('reset_password.password_input_label')}
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
        <Spacer factor={2} />
        <Input
          name="confirmPassword"
          type="password"
          placeholder={t('reset_password.confirm_password_input_label')}
          value={confirmPassword}
          onChange={(event) => setConfirmPassword(event.target.value)}
        />
        <Spacer factor={2} />
        <Button
          width="100%"
          inactive={!passwordsMatch()}
          onClick={() =>
            resetPassword({
              variables: {
                subUserResetPasswordPassword: password,
                subUserResetPasswordResetPasswordToken: resetPasswordToken,
              },
            })
          }
        >
          {t('reset_password.create_new_password')}
        </Button>
      </FormWindow>
    </UnauthenticatedPageWrapper>
  )
}
