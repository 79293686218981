import { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { getPixelAmount } from '../../styling/pixel-amount'
import { Button } from '../../ui-shared/_core/links-buttons'
import { DualSlider } from '../../ui-atoms/dual-slider'
import type { DualSliderChangeEvent } from '../../ui-atoms/dual-slider'
import { HeaderText } from '../../ui-atoms/header-text'
import { HorizontalDivider, Spacer } from '../../ui-atoms/help-components'
import { ButtonsContainer } from '../filters/buttons-container'
import { Reset } from '../filters/reset'

export type RoomCountFilterType = {
  minRoomCount?: number | null
  maxRoomCount?: number | null
}

type RoomCountFilterProps = {
  onChange: (filter: RoomCountFilterType) => void
  totalCount?: number
  filter: RoomCountFilterType
  range: { min: number; max: number }
  reset?: () => void
  close: () => void
  isLoading?: boolean
}

const Wrapper = styled.div({
  width: '400px',
  display: 'flex',
  flexDirection: 'column',
  padding: getPixelAmount(3),
})

export function RoomCountFilter(props: RoomCountFilterProps) {
  const [filter, setFilters] = useState<RoomCountFilterType>({
    minRoomCount: undefined,
    maxRoomCount: undefined,
  })
  const { t } = useTranslation(['my_homes', 'commons'])

  useEffect(() => {
    if (
      props.filter.minRoomCount !== filter.minRoomCount ||
      props.filter.maxRoomCount !== filter.maxRoomCount
    ) {
      setFilters({
        maxRoomCount: props.filter.maxRoomCount,
        minRoomCount: props.filter.minRoomCount,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filter.maxRoomCount, props.filter.minRoomCount])

  const onDrag = (event: DualSliderChangeEvent) => {
    const newFilter = { ...filter, [event.name]: event.isMax || event.isMin ? undefined : event.value }
    setFilters(newFilter)
  }

  const onChoice = (event: DualSliderChangeEvent) => {
    const newFilter = { ...filter, [event.name]: event.isMax || event.isMin ? undefined : event.value }
    props.onChange(newFilter)
  }

  const onInputChange = (inputValue: string | number | null | undefined, type: 'min' | 'max') => {
    const value = inputValue === '' ? 0 : Number(inputValue)
    if (type === 'min') {
      if (value <= props.range.max && value >= props.range.min) {
        const maxMinValue =
          props.filter.maxRoomCount !== undefined && props.filter.maxRoomCount !== null
            ? props.filter.maxRoomCount
            : props.range.max
        if (!(value > maxMinValue)) {
          const newFilter: RoomCountFilterType = { ...filter, minRoomCount: value }
          props.onChange(newFilter)
        }
      }
    }

    if (type === 'max') {
      if (value <= props.range.max && value >= props.range.min) {
        const minMaxValue =
          props.filter.minRoomCount !== undefined && props.filter.minRoomCount !== null
            ? props.filter.minRoomCount
            : props.range.min
        let newFilter: RoomCountFilterType
        if (!(value < minMaxValue)) {
          newFilter = { ...filter, maxRoomCount: value }
        } else {
          newFilter = { minRoomCount: value, maxRoomCount: value }
        }
        props.onChange(newFilter)
      }
    }
  }

  const { min = 0, max = 5 } = props.range

  return (
    <Wrapper>
      <HeaderText>{t('filters.room_count_title')}</HeaderText>
      <Spacer factor={1} />
      <HorizontalDivider />
      <Spacer factor={1} />
      <DualSlider
        minName="minRoomCount"
        maxName="maxRoomCount"
        range={{ min, max }}
        value={{
          min: props.filter.minRoomCount,
          max: props.filter.maxRoomCount,
        }}
        allowBelowMin={false}
        allowOverMax={false}
        onChange={onDrag}
        onDragStop={onChoice}
        onInputChange={onInputChange}
      />
      <Spacer factor={2} />
      <HorizontalDivider />
      <Spacer factor={2} />
      <ButtonsContainer>
        <Reset onClick={props.reset}>{t('commons:ctas.reset')}</Reset>
        <Button variant={'important'} onClick={props.close}>
          {props.isLoading
            ? t('filters.loading_amount_label')
            : t('filters.view_apartment', {
                count: props.totalCount,
              })}
        </Button>
      </ButtonsContainer>
    </Wrapper>
  )
}
