import { gql } from '@apollo/client'

export const CANCEL_CONTRACT_SIGNING = gql`
  mutation CancelContractSigning($id: ID!) {
    cancelContractSigning(id: $id) {
      contract {
        id
        status
      }
      errors {
        field
        codes
      }
    }
  }
`
