import styled from 'styled-components/macro'

import { pixelAmount, palette, fontSize } from '../styling'
import { defaultStyles } from '../styling/defaults'

export const InformationBox = styled.div({
  display: 'flex',
  padding: `${pixelAmount.lg}`,
  backgroundColor: palette.grey20,
  height: 'fit-content',
  borderRadius: defaultStyles.borderRadius,
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: fontSize.textSm,
  fontWeight: 400,
})
