import gql from 'graphql-tag'

const GET_BUILDINGS = gql`
  query BuildingsForAddApartmentQuery($buildingsSearchString: String = "") {
    buildings(searchString: $buildingsSearchString, first: 10) {
      nodes {
        id
        buildingName
        location {
          route
          streetNumber
          postalCode
          locality
        }
      }
    }
  }
`

const GET_BUILDING_LOCATION = gql`
  query BuildingLocationQuery($buildingId: ID!) {
    building(id: $buildingId) {
      location {
        longitude
        latitude
        postalCode
        route
        streetNumber
        locality
        country
        countryCode
      }
    }
  }
`

const GET_APARTMENTS = gql`
  query GetApartmentsForTemplateChoiceQuery(
    $homeTemplatesBuildingId: ID
    $homeTemplatesSearchString: String = ""
  ) {
    homeTemplates(buildingId: $homeTemplatesBuildingId, searchString: $homeTemplatesSearchString, first: 10) {
      nodes {
        id
        apartmentNumber
      }
    }
  }
`

const GET_APARTMENT = gql`
  query ApartmentTemplateQuery($homeTemplateId: ID!) {
    homeTemplate(id: $homeTemplateId) {
      roomCount
      squareMeters
      tenantCount
      rent
      description
      floor
      kitchenRenovationYear
      bathroomRenovationYear
      traits {
        id
        type
      }
      firsthand
      corporateHome
      studentHome
      seniorHome
    }
  }
`

const ADD_APARTMENT = gql`
  mutation UpsertHomeTemplateMutation(
    $upsertHomeTemplateInput: UpsertHomeTemplateInput!
    $upsertHomeTemplateId: ID
  ) {
    upsertHomeTemplate(input: $upsertHomeTemplateInput, id: $upsertHomeTemplateId) {
      homeTemplate {
        id
      }
      errors {
        codes
        field
      }
    }
  }
`

export const queries = {
  GET_BUILDINGS,
  GET_APARTMENTS,
  GET_APARTMENT,
  GET_BUILDING_LOCATION,
}

export const mutations = {
  ADD_APARTMENT,
}
