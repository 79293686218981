import { useTranslation } from 'react-i18next'
import { Paragraph, Stack } from '@qasa/qds-ui'
import { PillButton } from '@qasa/app/src/components/pill-button'

import { SmallHeader } from '../../ui-atoms/small-header'
import type { TraitTypeEnum, UpdateHomeInput } from '../../data/graphql/types/__generated__/globalTypes'
import { getTraits } from '../../util-functions/traits.util'
import type { AmenityCategory } from '../edit-listing/amenities-section'
import { CheckboxLine, TRAITS } from '../edit-listing/amenities-section'

type Props = {
  form: UpdateHomeInput
  onTraitChange: (trait: TraitTypeEnum) => void
}
// TODO: note that this is a duplicate of the old edit-listing-form amenities-section - it should be removed when we
export function AmenitiesSection({ form, onTraitChange }: Props) {
  const { t } = useTranslation('amenities', { keyPrefix: 'categories' })

  return (
    <Stack gap={'6x'}>
      {TRAITS.map(({ category, traits }) => (
        <Stack gap={'4x'} key={category}>
          <SmallHeader>{t(category as AmenityCategory)}</SmallHeader>
          <CheckboxLine direction="row" gap={'2x'}>
            {traits.map((trait) => (
              <PillButton
                key={trait}
                size="small"
                type="button"
                onPress={() => onTraitChange(trait)}
                isActive={form.traits!.includes(trait)}
              >
                <Paragraph size="sm">{getTraits(trait)}</Paragraph>
              </PillButton>
            ))}
          </CheckboxLine>
        </Stack>
      ))}
    </Stack>
  )
}
