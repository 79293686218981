import styled from 'styled-components/macro'

import { ColumnWithLoading } from '../../ui-atoms/table'
import { HomeStatusEnum } from '../../data/graphql/types/__generated__/globalTypes'
import { getPixelAmount } from '../../styling/pixel-amount'
import { defaultStyles } from '../../styling/defaults'
import { blocketPalette, palette } from '../../styling/colors'

const Status = styled(ColumnWithLoading)<{
  status?: HomeStatusEnum
  archivedBecauseOfApplicationDeadlinePassed?: boolean
}>(({ status, archivedBecauseOfApplicationDeadlinePassed }) => ({
  display: 'flex',
  alignItems: 'center',

  ':before': {
    content: '""',
    display: 'block',
    width: getPixelAmount(1.5),
    height: getPixelAmount(1.5),
    borderRadius: defaultStyles.borderRadiusRound,
    marginRight: getPixelAmount(1),
    ...(archivedBecauseOfApplicationDeadlinePassed && {
      backgroundColor: blocketPalette.orange,
    }),
    ...(status === HomeStatusEnum.normal && {
      backgroundColor: palette.blocketGreen,
    }),
    ...(status === HomeStatusEnum.pending && {
      backgroundColor: palette.blocketGrey,
    }),
    ...(status === HomeStatusEnum.archived &&
      !archivedBecauseOfApplicationDeadlinePassed && {
        backgroundColor: palette.blocketRed,
      }),
    ...(status === HomeStatusEnum.in_review && {
      backgroundColor: palette.blocketYellow,
    }),
  },
}))

export const ApartmentNumber = styled(ColumnWithLoading)({
  minWidth: '140px',
})
export const ListingStatus = styled(Status)({
  minWidth: '130px',
})
export const ApplicationDeadline = styled(ColumnWithLoading)({
  minWidth: '130px',
})
export const Address = styled(ColumnWithLoading)({
  minWidth: '300px',
})
export const Rooms = styled(ColumnWithLoading)({
  minWidth: '80px',
})
export const Size = styled(ColumnWithLoading)({
  minWidth: '100px',
})
export const Rent = styled(ColumnWithLoading)({
  minWidth: '140px',
})
export const MoveIn = styled(ColumnWithLoading)({
  minWidth: '120px',
})
export const Requests = styled(ColumnWithLoading)({
  minWidth: '120px',
})
export const PublishDate = styled(ColumnWithLoading)({
  minWidth: '120px',
})
