import { Paragraph } from '@qasa/qds-ui'
import styled from '@emotion/styled'

export const Reset = styled(Paragraph)(({ theme }) => ({
  textDecoration: 'underline',
  fontWeight: 500,
  cursor: 'pointer',
  color: theme.colors.text.subtle,
  ':hover': {
    color: theme.colors.text.default,
  },
}))
