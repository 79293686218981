import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { fontSize } from '../styling'

import { RotatingChevron } from './rotating-chevron'

const StyledButton = styled.button({
  textDecoration: 'underline',
  fontSize: fontSize.textSm,
  backgroundColor: 'transparent',
  border: 'none',
})

const StyledChevron = styled(RotatingChevron)({
  marginLeft: 0,
  marginRight: 8,
  fontSize: fontSize.textSm,
})

type ExpandButtonProps = {
  isOpen: boolean
  onToggleOpen: () => void
  title: string
}
export function ExpandButton({ isOpen, onToggleOpen, title }: ExpandButtonProps) {
  const { t } = useTranslation('commons', {
    keyPrefix: 'ctas',
  })
  return (
    <StyledButton onClick={onToggleOpen}>
      <StyledChevron isOpen={isOpen} />
      {isOpen ? t('show_less') : title}
    </StyledButton>
  )
}
