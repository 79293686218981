import { ContractPage, useContractContext } from '../../context/contract-context'
import type { KickoffHomeQuery_home_traits } from '../../data/graphql/types/__generated__/KickoffHomeQuery'

import { ContractForm } from './contract-form'
import type { ContractFormValues } from './contract-form'

type GetTraitValueParams = {
  traitType: string
  traits?: KickoffHomeQuery_home_traits[]
}
const getTraitValue = ({ traits, traitType }: GetTraitValueParams) =>
  Boolean(traits?.find(({ type }) => type === traitType))

export function ContractCreate() {
  const { kickOffData, tenantUid, openContract } = useContractContext()

  const traits = kickOffData?.home?.traits
  const DEFAULT_CONTRACT_VALUES: ContractFormValues = {
    homeId: kickOffData?.home?.id,
    rent: kickOffData?.home?.rent,
    tenantUid,
    duration: {
      startOptimal: kickOffData?.home?.duration.startOptimal,
      endOptimal: kickOffData?.home?.duration.endOptimal,
      startAsap: kickOffData?.home?.duration.startAsap,
      endUfn: kickOffData?.home?.duration.endUfn,
    },
    qasaGuarantee: kickOffData?.home?.qasaGuarantee,
    insurance: kickOffData?.home?.insurance,
    tenureType: kickOffData?.home?.tenureType,
    ownHome: kickOffData?.home?.ownHome || true,
    canUse: undefined,
    rentIncludes: undefined,
    rentExcludes: undefined,
    landlordHasKeys: undefined,
    smallRepairs: undefined,
    insuranceResponsibility: undefined,
    overnightStay: undefined,
    pets: getTraitValue({ traits, traitType: 'pets' }),
    smoker: getTraitValue({ traits, traitType: 'smoker' }),
    furniture: getTraitValue({ traits, traitType: 'furniture' }),
  }

  const handleCreatedContract = () => {
    // TODO: we need to get the contract data updated
    openContract(ContractPage.PREVIEW)
  }

  return <ContractForm defaultValues={DEFAULT_CONTRACT_VALUES} onCompletedUpsert={handleCreatedContract} />
}
