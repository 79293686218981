/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react'
import type { ReactNode } from 'react'
import isArray from 'lodash/isArray'

import { Input } from './input'
import type { InputProps } from './input'
import { DropDown } from './dropdown'

type InputHandlerProps = {
  children: ReactNode
  setValid: (isValid: boolean) => void
}

export function InputHandler(props: InputHandlerProps) {
  useEffect(() => {
    const inputProps: Array<InputProps> = []
    populateChildrenInputs(props.children, inputProps)
    checkInputs(inputProps)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.children])

  // For more validation add more conditionals here
  const checkInputs = (inputs: Array<InputProps>) => {
    const isValid = mandatoryInputsAreFilled(inputs) && inputsAreValid(inputs)
    props.setValid(isValid)
  }

  const mandatoryInputsAreFilled = (inputsProps: Array<InputProps>) => {
    const illegalValues: Array<string | number | null | undefined> = ['', null, undefined]
    const mandatoryInputs = inputsProps.filter((props) => !props.optional)

    return mandatoryInputs.every((props) => {
      return illegalValues.indexOf(props.value) === -1
    })
  }

  const inputsAreValid = (inputProps: Array<InputProps>) => {
    return !inputProps.some((props) => props.isValid === false)
  }

  return <div>{props.children}</div>
}

// TODO -> Remove all 'any' types and replace with better alternatives
function populateChildrenInputs(children: any, inputs: Array<any>) {
  if (React.isValidElement(children) && (children.type === Input || children.type === DropDown)) {
    inputs.push(children.props)
    return
  }

  if (isArray(children)) {
    children.forEach((child) => populateChildrenInputs(child, inputs))
  }

  if (children && children.props && children.props.children && isArray(children.props.children)) {
    children.props.children.forEach((child: any) => populateChildrenInputs(child, inputs))
  }
}
