import { gql } from '@apollo/client'

const GET_APPLICANT_CHAT_CONVERSATION = gql`
  query HomeApplicationChatConversation(
    $homeApplicationId: ID!
    $messagesLast: Int
    $messagesBefore: String
  ) {
    homeApplication(id: $homeApplicationId) {
      status
      conversation {
        id
        messages(last: $messagesLast, before: $messagesBefore) {
          edges {
            cursor
            node {
              message
              id
              messageType
              createdAt
              user {
                uid
              }
              upload {
                id
                title
                fileType
                type
                url
              }
            }
          }
          pageInfo {
            hasPreviousPage
            startCursor
          }
        }
        homeApplication {
          tenantPhoneNumber
          tenantEmail
        }
        lastSeenMessageIds {
          uid
          messageId
        }
        initiator
      }
      offering {
        offeringStatus
      }
    }
  }
`

const GET_CHAT_MACROS = gql`
  query ChatMacros($first: Int) {
    messageMacros(first: $first) {
      edges {
        node {
          id
          description
          text
          title
        }
      }
    }
  }
`

const SET_MESSAGE_CONVERSATION_INPUT = gql`
  mutation SetHomeApplicationChatMessage($createConversationMessageInput: CreateConversationMessageInput!) {
    createConversationMessage(input: $createConversationMessageInput) {
      errors {
        codes
      }
    }
  }
`

const SET_CONVERSATION_READ = gql`
  mutation MarkConversationMutation($markConversationInput: MarkConversationInput!) {
    markConversation(input: $markConversationInput) {
      errors {
        field
        codes
      }
    }
  }
`

export const queries = {
  GET_CHAT_MACROS,
  GET_APPLICANT_CHAT_CONVERSATION,
}

export const mutations = {
  SET_MESSAGE_CONVERSATION_INPUT,
  SET_CONVERSATION_READ,
}
