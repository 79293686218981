import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { palette } from '../styling'

export const RotatingCaret = styled(({ isAngleUp, ...rest }) => (
  <FontAwesomeIcon {...rest} icon={['fal', 'angle-down']} />
))<{
  isAngleUp: boolean
}>(({ isAngleUp }) => ({
  color: palette.blocketBlack,
  fontSize: 32,
  transition: 'all 200ms',
  transform: `rotate(${isAngleUp ? '180deg' : '0'})`,
}))
