import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components/macro'

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export function Loading() {
  return (
    <Wrapper>
      <FontAwesomeIcon icon={['fas', 'ellipsis']} fade size="4x" />
    </Wrapper>
  )
}
