import { Stack, Heading, Link, Divider } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import { useListingContext } from '../../context/listing-context'
import { BuildingDetails } from '../building/building-details'
import { BuildingDescription } from '../building/building-description'
import { BuildingLocation } from '../building/building-location'

export const LeftContainer = styled(Stack)({
  flexGrow: 1,
})
export const RightContainer = Stack

export function BuildingContent() {
  const { t } = useTranslation('commons')
  const { home } = useListingContext()
  const { homeTemplates } = home || {}

  const buildingId = homeTemplates[0]!.building?.id

  return (
    <Stack gap="12x">
      <Stack direction="row" justifyContent="space-between">
        <LeftContainer>
          <Heading>{t('building_one')}</Heading>
        </LeftContainer>
        <RightContainer>
          <Link href={`/homes/buildings/${buildingId}`}>{t('actions.view_building')}</Link>
        </RightContainer>
      </Stack>
      <Stack gap="8x" divider={<Divider />}>
        <BuildingDetails />
        <BuildingDescription />
        <BuildingLocation />
      </Stack>
    </Stack>
  )
}
