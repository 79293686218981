import { useQuery, useMutation, gql } from '@apollo/client'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, IconButton, Stack, StarFilledIcon, StarIcon, TrashIcon } from '@qasa/qds-ui'

import { ApplicantTab, useApplicantContext } from '../../context/applicant-context'
import type { ApplicationBoardLists } from '../../data/graphql/types/__generated__/ApplicationBoardLists'
import {
  ApplicationBoardListDefaultTypeEnum,
  HomeApplicationStatusEnum,
} from '../../data/graphql/types/__generated__/globalTypes'
import { DropDown } from '../../ui-atoms/dropdown'
import { ToolTip } from '../../ui-atoms/tooltip'
import { queries } from '../../data/application-board-lists'
import { mutations as applicantMutations } from '../../data/applicant'
import { mutations as applicantChatMutations } from '../../data/applicant-chat'
import { mutations } from '../../data/applications-by-board-list'
import type {
  MarkConversationMutation,
  MarkConversationMutationVariables,
} from '../../data/graphql/types/__generated__/MarkConversationMutation'

const Wrapper = styled(Stack)({ width: '100%' })
const LeftColumn = 'div'
const RightColumn = Stack

const ongoing = ApplicationBoardListDefaultTypeEnum.ongoing
const pending = ApplicationBoardListDefaultTypeEnum.pending

export function ApplicantActions() {
  const { t } = useTranslation('applicant', { keyPrefix: 'applicant_actions' })
  const { applicantData, currentTab } = useApplicantContext()
  const { id } = applicantData!
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const currentColumnId = searchParams.get('list')
  const listingId = searchParams.get('listing')
  const isStarmarked = applicantData!.starMarkedByLandlord

  const { error, data: columnsData } = useQuery<ApplicationBoardLists>(queries.GET_APPLICATION_BOARD_LISTS, {
    variables: {
      homeId: listingId,
    },
  })

  const [addHomeApplicationToBoardList] = useMutation(mutations.ADD_HOME_APPLICATIONS_TO_BOARD_LIST, {
    refetchQueries: ['HomeApplicationsByBoardList'],
    onCompleted: () => navigate(`/applications/requests?listing=${listingId}`),
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error)
    },
  })
  const [removeHomeApplicationFromBoardList] = useMutation(
    mutations.REMOVE_HOME_APPLICATIONS_FROM_BOARD_LIST,
    {
      refetchQueries: ['HomeApplicationsByBoardList'],
      onCompleted: () => navigate(`/applications/requests?listing=${listingId}`),
    },
  )
  const [markChatAsUnread] = useMutation<MarkConversationMutation, MarkConversationMutationVariables>(
    applicantChatMutations.SET_CONVERSATION_READ,
    {
      refetchQueries: [
        'HomeApplicationsByBoardList',
        'UnreadMessagesCountQuery',
        'UnreadConversationsCountQuery',
      ],
      variables: {
        markConversationInput: {
          conversationId: applicantData?.conversation?.id ?? '',
          read: false,
        },
      },
      onCompleted: () => {
        navigate(`/applications/requests?listing=${listingId}`)
      },
    },
  )

  const [setStarMarked] = useMutation(applicantMutations.SET_STARMARKED, {
    refetchQueries: ['HomeApplicationsByBoardList', 'HomeApplicationOverview'],
    update: (
      cache,
      {
        data: {
          starMarkApplication: { homeApplication },
        },
      },
    ) => {
      cache.modify({
        fields: {
          homeApplication(existingHomeApplication = {}) {
            const updatedHomeApplicationRef = cache.writeFragment({
              data: homeApplication,
              fragment: gql`
                fragment UpdatedApplication on homeApplication {
                  id
                  starMarkedByLandlord
                }
              `,
            })
            return { ...existingHomeApplication, updatedHomeApplicationRef }
          },
        },
      })
    },
  })

  const getDropDownColumnChoices = () => {
    if (!columnsData) {
      return []
    } else {
      const values: { id: string; value: string }[] = []

      columnsData?.home?.applicationBoardLists.map((c) => {
        const name = c.name
          ? c.name
          : c.defaultType === pending
            ? t('incoming')
            : c.defaultType === ongoing
              ? t('ongoing')
              : t('assigned')

        if (c.id === currentColumnId || c.defaultType === pending) {
          return values
        } else {
          values.push({ id: c.id, value: name })
          return values
        }
      })

      return values
    }
  }

  const handleDecline = () => {
    removeHomeApplicationFromBoardList({ variables: { homeApplicationIds: [id] } })
  }

  const handleStarmark = () => {
    setStarMarked({
      variables: { homeApplicationId: id, starMarkedByLandlord: !isStarmarked },
    })
  }

  const handleOnSelect = (value: string | number) => {
    addHomeApplicationToBoardList({
      variables: { applicationBoardListId: value, homeApplicationIds: [id] },
    })
  }

  const getOngoingColumn = () => {
    return columnsData?.home?.applicationBoardLists[1].id
  }
  const handleMoveFromDeclined = () => {
    addHomeApplicationToBoardList({
      variables: { applicationBoardListId: getOngoingColumn(), homeApplicationIds: [id] },
    }).then(() => navigate(0))
  }

  const getCurrentColumnName = () => {
    if (columnsData && columnsData.home) {
      const length = columnsData.home?.applicationBoardLists.length || 0
      for (let i = 0; i < length; ++i) {
        if (columnsData?.home?.applicationBoardLists[i].id === currentColumnId) {
          const columnName = columnsData?.home?.applicationBoardLists[i].name
          const columnType = columnsData?.home?.applicationBoardLists[i].defaultType
          const name = columnName
            ? columnName
            : columnType === pending
              ? t('incoming')
              : columnType === ongoing
                ? t('ongoing')
                : t('assigned')
          return name
        }
      }
    }
    return ''
  }

  if (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }

  const isMessageTab = currentTab === ApplicantTab.Conversation

  const isDeclined = applicantData!.status === HomeApplicationStatusEnum.declined
  const isClosed = applicantData!.status === HomeApplicationStatusEnum.closed

  return (
    <Wrapper direction="row" alignItems="center" justifyContent="space-between">
      <LeftColumn>
        {isMessageTab && (
          <Button onClick={() => markChatAsUnread()} variant="tertiary">
            {t('mark_as_unread')}
          </Button>
        )}
      </LeftColumn>
      <RightColumn gap="2x" direction="row" alignItems="center">
        {!isDeclined && !isClosed && (
          <ToolTip content={t('remove')}>
            <IconButton label={t('remove')} icon={TrashIcon} onClick={handleDecline} size="sm" />
          </ToolTip>
        )}
        <ToolTip content={t('star_mark')}>
          <IconButton
            label={t('star_mark')}
            icon={isStarmarked ? StarFilledIcon : StarIcon}
            onClick={handleStarmark}
            size="sm"
          />
        </ToolTip>
        {isDeclined || isClosed ? (
          <Button variant="tertiary" onClick={handleMoveFromDeclined}>
            {t('move_to_ongoing')}
          </Button>
        ) : (
          <DropDown
            name="moveApplicationTo"
            placeholder={t('move_to')}
            value={getCurrentColumnName()}
            values={getDropDownColumnChoices()}
            onChoice={handleOnSelect}
            onChange={() => {
              return
            }}
            width="200px"
            isMenuPlacementTop={true}
          />
        )}
      </RightColumn>
    </Wrapper>
  )
}
