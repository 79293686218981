import { useQuery } from '@apollo/client'
import type { Dispatch, RefObject, SetStateAction } from 'react'
import { useState, useRef, useEffect } from 'react'
import debounce from 'lodash/debounce'

import type {
  ListingsQuery,
  ListingsQueryVariables,
} from '../../data/graphql/types/__generated__/ListingsQuery'
import { queries } from '../../data/my-homes'
import type { RoomCountFilterType } from '../my-homes/room-count-filter'
import type { SizeFilterType } from '../my-homes/size-filter'
import {
  HomeSearchOrderByEnum,
  HomeSearchOrderEnum,
} from '../../data/graphql/types/__generated__/globalTypes'
import { useFetchMoreOnScrollToBottom } from '../../hooks/use-fetch-more-on-scroll-to-bottom'

import type { ListingFilterAttribute } from './listings-table'
import { getListingsStatusVariables } from './get-listings-data.utils'
import type { ListingsStatusFilters } from './listings-status-navigation'

const DEFAULT_START_COUNT = 50
const DEFAULT_BULK_COUNT = 20

type GetListingsDataParams = {
  setTotalCount: Dispatch<SetStateAction<number | undefined>>
  listingStatus: ListingsStatusFilters
  scrollElementRef: RefObject<HTMLTableElement>
}
export function useGetListingsData({
  setTotalCount,
  listingStatus,
  scrollElementRef,
}: GetListingsDataParams) {
  const [localArgs, setLocalArgs] = useState<ListingsQueryVariables>({
    homesAfter: null,
    homesFirst: DEFAULT_START_COUNT,
    homesOrder: HomeSearchOrderEnum.DESCENDING,
    homesOrderBy: HomeSearchOrderByEnum.PUBLISHED_AT,
    homesSearchString: '',
    maxRoomCount: null,
    maxSquareMeters: null,
    minRoomCount: null,
    minSquareMeters: null,
    ...getListingsStatusVariables(listingStatus),
  })

  const {
    loading: isLoading,
    error,
    data,
    fetchMore,
    refetch,
  } = useQuery<ListingsQuery, ListingsQueryVariables>(queries.GET_LISTINGS, {
    variables: {
      ...localArgs,
      // NOTE: specifically adding it here too because that enables us to trigger a refetch when status changes
      ...getListingsStatusVariables(listingStatus),
    },
    notifyOnNetworkStatusChange: true,
  })
  const { handleScroll } = useFetchMoreOnScrollToBottom({
    handleFetchMore: () =>
      fetchMore({
        variables: {
          homesAfter: data?.homes.pageInfo.endCursor,
          homesFirst: DEFAULT_BULK_COUNT,
        },
      }),
    shouldFetchMore: data?.homes.pageInfo.hasNextPage,
  })

  useEffect(() => {
    if (setTotalCount) {
      setTotalCount(isLoading ? undefined : data?.homes.totalCount)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.homes.totalCount, isLoading])

  const refetchListings = async (newVariables: ListingsQueryVariables) => {
    // reset scroll on refetch to prevent scrollBottom functionality from being called unnecessarily
    const scrollLeft = scrollElementRef?.current?.scrollLeft ?? 0
    scrollElementRef?.current?.scrollTo(scrollLeft, 0)
    setLocalArgs((prev) => ({ ...prev, ...newVariables }))
    await refetch({
      ...newVariables,
      homesAfter: null,
      homesFirst: DEFAULT_START_COUNT,
    })
  }

  const delaySearchRef = useRef(
    debounce((searchString) => {
      refetchListings({
        homesSearchString: searchString,
      })
    }, 250),
  )
  const onSearch = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value: homesSearchString } = event.target
    setLocalArgs((prev) => ({ ...prev, homesSearchString }))
    delaySearchRef.current(homesSearchString)
  }

  const resetFilterAttributes = (resetFilterAttributes: Array<ListingFilterAttribute>) => {
    const filterAtributesToReset = resetFilterAttributes.reduce(
      (acc: Record<ListingFilterAttribute, undefined>, value) => {
        acc[value] = undefined
        return acc
      },
      {} as Record<ListingFilterAttribute, undefined>,
    )
    refetchListings({
      ...filterAtributesToReset,
    })
  }

  const addFilter = (newFilter: RoomCountFilterType | SizeFilterType) => {
    refetchListings({
      ...newFilter,
    })
  }

  const handleChangeSorting = (newOrderBy: HomeSearchOrderByEnum) => {
    let newOrder = HomeSearchOrderEnum.ASCENDING
    if (newOrderBy !== localArgs.homesOrderBy || localArgs.homesOrder === HomeSearchOrderEnum.ASCENDING) {
      newOrder = HomeSearchOrderEnum.DESCENDING
    }

    refetchListings({
      homesOrderBy: newOrderBy,
      homesOrder: newOrder,
    })
  }

  return {
    resetFilterAttributes,
    addFilter,
    onSearch,
    onSort: handleChangeSorting,
    listingsData: {
      isLoading,
      error,
      data,
    },
    // loadMoreListings,
    localArgs,
    handleScroll,
  }
}
