import type { HomeApplicationOverview_homeApplication_tenant_tenantAd_searchPreference } from '../../../data/graphql/types/__generated__/HomeApplicationOverview'
import { ProfilePreferredAmenitiesEnum, ProfileRequiredAmenitiesEnum } from '../../../types/profile.types'

type ParsePreferredAmenitiesParams = {
  searchPreference:
    | Partial<HomeApplicationOverview_homeApplication_tenant_tenantAd_searchPreference>
    | null
    | undefined
}
export const parsePreferredAmenities = ({ searchPreference }: ParsePreferredAmenitiesParams) => {
  const {
    /* eslint-disable @typescript-eslint/naming-convention */
    pets,
    smoker,
    wheelchairAccessible,
    balcony,
    bathtub,
    bikeRoom,
    dishWasher,
    inhomeSauna,
    parking,
    recycling,
    storage,
    tumbleDryer,
    washingMachine,
    /* eslint-enable @typescript-eslint/naming-convention */
  } = searchPreference || {}
  return [
    ...(pets ? [ProfileRequiredAmenitiesEnum.pets] : []),
    ...(smoker ? [ProfileRequiredAmenitiesEnum.smoker] : []),
    ...(wheelchairAccessible ? [ProfileRequiredAmenitiesEnum.wheelchairAccessible] : []),
    ...(balcony ? [ProfilePreferredAmenitiesEnum.balcony] : []),
    ...(bathtub ? [ProfilePreferredAmenitiesEnum.bathtub] : []),
    ...(bikeRoom ? [ProfilePreferredAmenitiesEnum.bikeRoom] : []),
    ...(dishWasher ? [ProfilePreferredAmenitiesEnum.dishWasher] : []),
    ...(inhomeSauna ? [ProfilePreferredAmenitiesEnum.inhomeSauna] : []),
    ...(parking ? [ProfilePreferredAmenitiesEnum.parking] : []),
    ...(recycling ? [ProfilePreferredAmenitiesEnum.recycling] : []),
    ...(storage ? [ProfilePreferredAmenitiesEnum.storage] : []),
    ...(tumbleDryer ? [ProfilePreferredAmenitiesEnum.tumbleDryer] : []),
    ...(washingMachine ? [ProfilePreferredAmenitiesEnum.washingMachine] : []),
  ]
}
