import { gql } from '@apollo/client'

import * as fragments from './fragments'

export const CONTRACT = gql`
  query ContractQuery($id: ID!) {
    contract(id: $id) {
      ...BaseContract
      extensionContract {
        id
        status
        signUrl
        rent
        duration {
          startOptimal
          endOptimal
          endUfn
        }
        motherContract {
          id
        }
      }
      signingProcess
      qasaGuarantee
      insurance
      termsUrl
      depositFree
      depositFreeCost
      signUrl
      language
      availablePreviewLanguages
      numberOfTenants
      pendingTerminationEndDate
      tenantFees {
        waterFeePerTenant {
          availability
          cost {
            fixed
            isFree
          }
        }
      }
      invoices {
        amount
        currency
        guaranteed
        periodEndsAt
        periodStartsAt
        type
        dueAt
        paidAt
        id
        sentAt
        sendAt
        creditedAmount
        batch
        url
        invoiceEntries {
          amount
          costType
          entryType
          id
        }
      }
    }
  }
  ${fragments.home}
  ${fragments.contract}
`
