import { useCallback, useState } from 'react'

import type { UseCheckboxParams } from './checkbox.types'

export const useCheckbox = ({ onChange, isChecked: isCheckedProp }: UseCheckboxParams) => {
  const [isFocused, setIsFocused] = useState(false)
  const isChecked = Boolean(isCheckedProp)

  const handleChange = useCallback(
    (isCheckedNewValue: boolean) => {
      onChange && onChange(isCheckedNewValue)
    },
    [onChange],
  )

  // Only relevant for the web, not used on native
  const inputProps = {
    type: 'checkbox',
    onFocus: () => setIsFocused(true),
    onBlur: () => setIsFocused(false),
  }

  return { isChecked, handleChange, isFocused, inputProps }
}
