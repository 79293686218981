import isArray from 'lodash/isArray'
import isPlainObject from 'lodash/isPlainObject'
import i18next from 'i18next'

import type { ApartmentsQuery_homeTemplates_edges_node_location } from '../data/graphql/types/__generated__/ApartmentsQuery'
import type { BuildingsQuery_buildings_edges_node_location } from '../data/graphql/types/__generated__/BuildingsQuery'
import {
  HomeApplicationStatusEnum,
  OfferingStatusEnum,
} from '../data/graphql/types/__generated__/globalTypes'

type Location =
  | BuildingsQuery_buildings_edges_node_location
  | ApartmentsQuery_homeTemplates_edges_node_location

export const formatLocation = (location: Location): string => {
  const { route, streetNumber, postalCode, locality } = location
  return `${route ? route : ''} ${streetNumber ? streetNumber : ''}${postalCode ? ', ' + postalCode : ''}${
    locality ? ', ' + locality : ''
  }`
}

// TODO: refactor this util when we have brands and currencies + locales set up
// simpler version for format amount
export const formatMoney = (total = 0, curr = 'kr/mån') => {
  return `${total.toLocaleString('sv-SE')} ${curr}`
}

export const formatApartmentSize = (
  roomCount: number | null,
  squareMeters: number | null,
  numberOfPeople: number | null = null,
): string => {
  const formattedRoomCount = `${roomCount} ${i18next.t('commons:room', { count: roomCount ?? 0 })}`
  const formattedSquareMeters = `${squareMeters} ${i18next.t('commons:square_meter')}`
  const formattedNumOfPeople = `${numberOfPeople} ${i18next.t('commons:person', {
    count: numberOfPeople ?? 0,
  })}`

  if (roomCount && squareMeters && numberOfPeople) {
    return `${formattedRoomCount} • ${formattedSquareMeters} • ${formattedNumOfPeople}`
  }
  if (roomCount && squareMeters) {
    return `${formattedRoomCount}, ${formattedSquareMeters}`
  }
  if (roomCount) {
    return `${formattedRoomCount}`
  }
  if (squareMeters) {
    return `${formattedSquareMeters}`
  }
  return ''
}

export type ListingSpan = {
  min?: number | null
  max?: number | null
}

export const formatBlockSizes = (roomCountSpan: ListingSpan, squareMeterSpan: ListingSpan): string => {
  let sizeString = formatBasicInfo({
    isBlock: true,
    span: roomCountSpan,
    unit: i18next.t('commons:room', { count: 2 }),
  })
  if (squareMeterSpan.min && squareMeterSpan.max) {
    sizeString += `${sizeString && ', '} 
    ${formatBasicInfo({
      isBlock: true,
      span: squareMeterSpan,
      unit: i18next.t('commons:square_meter'),
    })}`
  } else {
    // Needed after addition of tenant count span
    sizeString += `${sizeString && ', '}
    }`
  }

  return sizeString
}

type FormatBasicProps = {
  isBlock: boolean
  value?: number | null
  span?: ListingSpan
  unit?: string
}

export const formatBasicInfo = ({ isBlock, value, span, unit }: FormatBasicProps): string => {
  return (
    (isBlock && span?.min !== span?.max ? `${formatNumber(span?.min || 0)} - ` : '') +
    `${formatNumber(isBlock ? span?.max || 0 : value || 0)}${unit ? ` ${unit}` : ''}`
  )
}

export function formatNumber(num: number) {
  // Ok to disable this lint rule since it's only run on the client
  // eslint-disable-next-line security/detect-unsafe-regex
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const getLastUrlSegment = () => {
  const pageSegments = window.location.pathname.split('/').filter((segment) => segment !== '')
  return pageSegments[pageSegments.length - 1]
}

export const cloneWithoutTypename = <T>(obj: T): Omit<T, '__typename'> => {
  if (!isPlainObject(obj)) {
    return obj
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: any = {}
  for (const key in obj) {
    if (key === '__typename') {
      continue
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const value = (obj as any)[key]
    if (isPlainObject(value)) {
      result[key] = cloneWithoutTypename(value)
    } else if (isArray(value)) {
      result[key] = value.map(cloneWithoutTypename)
    } else {
      result[key] = value
    }
  }
  return result
}

export const resolveStatus = (
  applicantStatus?: HomeApplicationStatusEnum,
  offerStatus?: OfferingStatusEnum,
) => {
  if (applicantStatus && offerStatus) {
    switch (applicantStatus) {
      case HomeApplicationStatusEnum.closed:
        return HomeApplicationStatusEnum.closed
      case HomeApplicationStatusEnum.declined:
        return HomeApplicationStatusEnum.declined
      case HomeApplicationStatusEnum.pending:
        return HomeApplicationStatusEnum.pending
      case HomeApplicationStatusEnum.revoked:
        return HomeApplicationStatusEnum.revoked
      case HomeApplicationStatusEnum.in_contact:
        return offerStatus !== OfferingStatusEnum.completed
          ? i18next.t(`commons:offer_statuses.${offerStatus}`)
          : i18next.t('commons:offer_statuses.fallback')
      default:
        return applicantStatus
    }
  }
  return applicantStatus
}

export const translateApplicantStatus = (status?: HomeApplicationStatusEnum | string) => {
  return status && Object.values(HomeApplicationStatusEnum).includes(status as HomeApplicationStatusEnum)
    ? i18next.t(`commons:application_statuses.${status as HomeApplicationStatusEnum}`)
    : status
}
