import gql from 'graphql-tag'

export const TRANSITION_SHORTCUT = gql`
  mutation TransitionShortcutMutation($transitionShortcutInput: TransitionShortcutInput!) {
    transitionShortcut(input: $transitionShortcutInput) {
      errors {
        codes
      }
    }
  }
`
