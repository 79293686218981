import { gql } from '@apollo/client'

const GET_OVERVIEW_INCOME = gql`
  query OverviewIncome($incomeVerificationUid: ID!) {
    incomeVerification(uid: $incomeVerificationUid) {
      id
      otherIncomesMean
      totalIncomeMean
      salaryMean
    }
  }
`

export const queries = {
  GET_OVERVIEW_INCOME,
}
