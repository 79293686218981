import styled from '@emotion/styled'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import type { ReactNode } from 'react'

const Trigger = styled(TooltipPrimitive.Trigger)({ padding: 0 })
const Content = styled(TooltipPrimitive.Content)(({ theme }) => ({
  ...theme.typography.body.xs,
  paddingBlock: theme.spacing['1x'],
  paddingInline: theme.spacing['3x'],
  borderRadius: theme.radii.sm,
  boxShadow: theme.shadows.md,
  backgroundColor: theme.colors.bg.brandSecondary,
  color: theme.colors.text.onBrandSecondary,
  zIndex: theme.zIndices.tooltip,
}))
const Arrow = styled(TooltipPrimitive.Arrow)(({ theme }) => ({
  fill: theme.colors.bg.brandSecondary,
}))

type TooltipProps = {
  children?: ReactNode
  content?: string
  isDisabled?: boolean
}

export function ToolTip({ children, content, isDisabled }: TooltipProps) {
  if (isDisabled) return <>{children}</>
  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root delayDuration={0}>
        <Trigger asChild>{children}</Trigger>
        <TooltipPrimitive.Portal>
          <Content sideOffset={8}>
            {content}
            <Arrow width={12} height={4} />
          </Content>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  )
}
