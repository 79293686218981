import { gql } from '@apollo/client'

import * as fragments from '../data/fragments'

export const LOGIN = gql`
  mutation SubUserLoginMutation($subUserLoginEmail: String!, $subUserLoginPassword: String!) {
    subUserLogin(email: $subUserLoginEmail, password: $subUserLoginPassword) {
      payload {
        accessToken
        user {
          userId: uid
          firstName
          isProAgent: proAgent
          professional
          companyName
          profilePicture {
            url
          }
          currentIdentification {
            identityConfirmed
            identityConfirmedAt
          }
          emailConfirmed
        }
      }
      errors {
        codes
        field
      }
    }
  }
`

export const ME = gql`
  query MeQuery {
    me {
      uid
      firstName
      isProAgent: proAgent
      professional
      companyName
      profilePicture {
        url
      }
      currentIdentification {
        id
        identificationType
        identityConfirmed
        identityConfirmedAt
      }
      activeIdentifications {
        id
        identificationType
        identityConfirmedAt
        identityConfirmed
      }
      emailConfirmed
      landlord
      tenant
      premium
      private {
        id
        familyName
        phoneNumber
        invoiceAdsFrom
        verifiedAccess
        activeBankAccount {
          id
        }
        homeqUid
      }
      ...TenantProfileCompleteness
      identityConfirmedAt
      age
      certificate {
        id
        status
      }
    }
  }
  ${fragments.tenantProfileCompleteness}
`
