import styled from '@emotion/styled'
import { Heading, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import { EditDialog } from '@qasa/app/src/modules/edit-dialog'
import type { TFunction } from 'i18next'

import { ListingSectionEnum, useListingContext } from '../../context/listing-context'
import { BaseInfoList } from '../apartment/apartment-details'
import { ContractSmallRepairsEnum } from '../../data/graphql/types/__generated__/globalTypes'

import { BaseInfoItem } from './base-info-item'

const LeftContainer = styled(Stack)({
  flexGrow: 1,
})
const RightContainer = Stack

const getTextFromBooleanValue = (t: TFunction, value?: boolean | null) => {
  if (value === null || value === undefined) {
    return t('commons:placeholders.not_filled_in')
  }
  return value ? t('commons:yes') : t('commons:no')
}
export function LeaseContent() {
  const { t } = useTranslation(['listing', 'commons'])
  const { home, setActiveEditSection, setIsEditDialogOpen } = useListingContext()
  const { contractTemplate } = home
  const {
    landlordHasKeys: hasLandlordKeys,
    smallRepairs,
    rentExcludes,
    rentIncludes,
    canUse,
  } = contractTemplate || {}

  const notFilledIn = t('commons:placeholders.not_filled_in')

  const fields = [
    {
      heading: t('lease_details.ll_has_keys_label'),
      value: getTextFromBooleanValue(t, hasLandlordKeys),
    },
    {
      heading: t('lease_details.small_repairs_label'),
      value: smallRepairs
        ? t(
            `commons:${
              smallRepairs === ContractSmallRepairsEnum.small_repairs_landlord ? 'landlord' : 'tenant'
            }`,
          )
        : notFilledIn,
    },
    {
      heading: t('lease_details.included_in_rent_label'),
      value: rentIncludes?.[0] || notFilledIn,
    },
    {
      heading: t('lease_details.not_included_in_rent_label'),
      value: rentExcludes?.[0] || notFilledIn,
    },
    {
      heading: t('lease_details.can_use_label'),
      value: canUse?.[0] || notFilledIn,
    },
  ]

  const handleOpenEditDialog = () => {
    setIsEditDialogOpen(true)
    setActiveEditSection(ListingSectionEnum.LEASE_DETAILS)
  }

  return (
    <Stack gap="12x">
      <Stack direction="row" justifyContent="space-between">
        <LeftContainer>
          <Heading>{t('lease_details.title')}</Heading>
        </LeftContainer>
        <RightContainer>
          <EditDialog.Trigger onClick={handleOpenEditDialog} />
        </RightContainer>
      </Stack>
      <BaseInfoList>
        {fields.map((field) => {
          const { heading, value } = field
          return <BaseInfoItem key={heading} heading={heading} value={value} />
        })}
      </BaseInfoList>
    </Stack>
  )
}
