import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components/macro'
import { Spacer, Paragraph } from '@qasa/qds-ui'

import { defaultStyles } from '../styling/defaults'
import { palette } from '../styling'
import { getPixelAmount } from '../styling/pixel-amount'

type CheckBoxProps = {
  type?: 'thumb' | 'default'
  name: string
  label?: string
  isChecked: boolean
  inactive?: boolean
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
}

const Wrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const Track = styled.div({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  height: getPixelAmount(2),
  width: getPixelAmount(5),
  backgroundColor: palette.grayAlpha20,
  borderRadius: defaultStyles.borderRadiusRound,
})

const Thumb = styled.div<{ isChecked: boolean }>(({ isChecked }) => ({
  position: 'relative',
  height: getPixelAmount(3),
  width: getPixelAmount(3),
  borderRadius: defaultStyles.borderRadiusRound,
  backgroundColor: palette.textSecondary,
  transition: 'transform 0.1s linear',
  ...(isChecked && {
    transform: `translateX(${getPixelAmount(2)})`,
    backgroundColor: palette.primary,
  }),
}))

const DefaultBox = styled.div<Partial<CheckBoxProps>>(({ isChecked, inactive }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: getPixelAmount(2),
  width: getPixelAmount(2),
  border: `1px solid ${palette.textSecondary}`,
  borderRadius: '2px',
  backgroundColor: 'white',
  ...(isChecked && {
    backgroundColor: palette.blocketBlack,
    border: 'none',
  }),
  ...(inactive && {
    pointerEvents: 'none',
    opacity: 0.2,
  }),
}))

const Check = styled(FontAwesomeIcon)({
  color: palette.white,
  fontSize: 10,
})

export function Checkbox(props: CheckBoxProps) {
  const handleCheck = (event: React.MouseEvent<HTMLDivElement>) => {
    if (props.onClick) {
      props.onClick(event)
    }
  }

  if (props.type === 'thumb') {
    return (
      <Wrapper>
        <Track onClick={props.onClick}>
          <Thumb isChecked={props.isChecked} />
        </Track>
        <Spacer axis="x" size="4x" />
        {props.label && <Paragraph size="sm">{props.label}</Paragraph>}
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <DefaultBox onClick={handleCheck} isChecked={props.isChecked} inactive={props.inactive}>
        {props.isChecked && <Check icon={['fas', 'check']} size="sm" />}
      </DefaultBox>
    </Wrapper>
  )
}
