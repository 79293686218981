import { theme } from '@qasa/qds-ui'
import type { NavLinkProps as RouterNavLinkProps } from 'react-router-dom'
import { NavLink as RouterNavLink } from 'react-router-dom'

type NavLinkProps = Partial<RouterNavLinkProps> & {
  href?: string
}

export function NavLink({ href, to, children, ...rest }: NavLinkProps) {
  return to ? (
    <RouterNavLink
      to={to}
      style={({ isActive }) => ({
        ...(isActive && { backgroundColor: theme.colors.core.gray30, fontWeight: 600 }),
      })}
      {...rest}
    >
      {children}
    </RouterNavLink>
  ) : (
    <a href={href} className={rest.className as string}>
      {children as React.ReactNode}
    </a>
  )
}
