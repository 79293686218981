import styled from 'styled-components/macro'

import { defaultStyles } from '../styling/defaults'
import { palette } from '../styling'
import { getPixelAmount } from '../styling/pixel-amount'

import { VerticalSpacer } from './help-components/spacer'

type RadioButtonProps = {
  name?: string
  label?: string
  isChecked: boolean
  onClick?: (name?: string) => void
}

const Wrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  cursor: 'pointer',
})

const Label = styled.p({})

const RadioBackground = styled.div<{ isChecked: boolean }>(({ isChecked }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: defaultStyles.borderRadiusRound,
  height: getPixelAmount(2),
  width: getPixelAmount(2),
  border: `1px solid ${palette.textSecondary}`,
  ...(isChecked && {
    border: 'none',
    backgroundColor: palette.primary,
  }),
}))

const Dot = styled.div<{ isChecked: boolean }>(({ isChecked }) => ({
  borderRadius: defaultStyles.borderRadiusRound,
  height: getPixelAmount(1),
  width: getPixelAmount(1),
  transform: 'scale(0)',
  backgroundColor: 'transparent',
  ...(isChecked && {
    transform: 'scale(1)',
    backgroundColor: palette.white,
  }),
}))

export function RadioButton(props: RadioButtonProps) {
  return (
    <Wrapper {...(props.onClick && { onClick: () => props.onClick!(props.name) })}>
      <RadioBackground isChecked={props.isChecked}>
        <Dot isChecked={props.isChecked} />
      </RadioBackground>
      <VerticalSpacer factor={1} />
      {props.label && <Label>{props.label}</Label>}
    </Wrapper>
  )
}
