import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import i18next from 'i18next'
import { Stack } from '@qasa/qds-ui'

import { Input } from '../../ui-atoms/input'
import { fontSize, palette } from '../../styling'
import { getPixelAmount } from '../../styling/pixel-amount'
import { defaultStyles } from '../../styling/defaults'
import { Map } from '../../ui-atoms/map'
import { VerticalSpacer } from '../../ui-atoms/help-components/spacer'

import { InputHeader, InputRow } from './input-grid-styles'

const LngLat = styled.div({
  display: 'flex',
})

const LngLatDisplay = styled.p({
  fontSize: fontSize.textXs,
  borderRadius: defaultStyles.borderRadius,
  backgroundColor: palette.grayAlpha20,
  width: 'fit-content',
  padding: getPixelAmount(0.5),
  margin: `${getPixelAmount(0.5)} 0`,
  label: {
    display: 'inline-block',
    width: '60px',
  },
})

export function BuildingLocationSection() {
  const { t } = useTranslation(['building', 'edit_listing', 'commons'])
  const { control, watch, setValue } = useFormContext()

  const location = watch('location')

  const setLngLat = (coordinates: { longitude: number; latitude: number }) => {
    setValue('location.longitude', coordinates.longitude)
    setValue('location.latitude', coordinates.latitude)
  }

  return (
    <Stack gap={'8x'}>
      <InputRow isRowsFirst>
        <InputHeader isFullWidth size="2xs">
          {t('edit_listing:basic_listing_information.address')}
        </InputHeader>
        <Controller
          name="location.route"
          control={control}
          rules={{
            required: {
              value: true,
              message: i18next.t('commons:errors.mandatory_field'),
            },
          }}
          render={({ field: { value, onChange, name }, fieldState: { error } }) => (
            <Input
              name={name}
              type="text"
              value={value}
              errorMessage={error?.message}
              placeholder={t('edit_listing:basic_listing_information.street_placeholder')}
              onChange={onChange}
            />
          )}
        />
        <Controller
          name="location.streetNumber"
          control={control}
          rules={{
            required: {
              value: true,
              message: i18next.t('commons:errors.mandatory_field'),
            },
          }}
          render={({ field: { value, onChange, name }, fieldState: { error } }) => (
            <Input
              name={name}
              type="text"
              value={value}
              errorMessage={error?.message}
              placeholder={t('edit_listing:basic_listing_information.street_number_placeholder')}
              onChange={onChange}
            />
          )}
        />
        <Controller
          name="location.postalCode"
          control={control}
          rules={{
            required: {
              value: true,
              message: i18next.t('commons:errors.mandatory_field'),
            },
          }}
          render={({ field: { value, onChange, name }, fieldState: { error } }) => (
            <Input
              name={name}
              type="text"
              value={value}
              errorMessage={error?.message}
              placeholder={t('edit_listing:basic_listing_information.postal_code_placeholder')}
              onChange={onChange}
            />
          )}
        />
      </InputRow>
      <Map
        width="400px"
        {...(location?.longitude &&
          location?.latitude && {
            markers: [
              {
                longitude: location?.longitude,
                latitude: location?.latitude,
                dragable: true,
              },
            ],
          })}
        isZoomDisabled={true}
        onLatLngChange={setLngLat}
      />
      <LngLat>
        <LngLatDisplay>{t('commons:latitude', { latitude: location?.latitude })}</LngLatDisplay>
        <VerticalSpacer factor={1} />
        <LngLatDisplay>{t('commons:longitude', { longitude: location?.longitude })}</LngLatDisplay>
      </LngLat>
    </Stack>
  )
}
