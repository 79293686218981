import { useTranslation } from 'react-i18next'
import { createStyleVariants } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import { getTextWithRenderedLinks } from '@qasa/app/src/features/home/modules/description/dangerous-description-first-hand-only'

import { placeholder } from '../../../styling'
import type { HomeApplicationChatConversation_homeApplication_conversation_messages_edges } from '../../../data/graphql/types/__generated__/HomeApplicationChatConversation'

import { MessageWrapper } from './message-wrapper'
import { MessageImage } from './message-image'

export type MessageVariant = 'sent' | 'received'

const messageBubbleStyleVariants = createStyleVariants((theme) => ({
  sent: {
    backgroundColor: theme.colors.core.blue60,
    color: theme.colors.core.offWhite,
    borderTopRightRadius: theme.radii.xs,
    alignSelf: 'flex-end',
  },
  received: {
    backgroundColor: theme.colors.bg.brandTertiary,
    borderTopLeftRadius: theme.radii.xs,
    alignSelf: 'flex-start',
  },
}))

const MessageBubble = styled.div<{ variant: MessageVariant }>(({ variant, theme }) => ({
  maxWidth: theme.sizes['384'],
  borderRadius: theme.radii.md,
  padding: theme.spacing['5x'],
  ...theme.typography.body.md,
  ...messageBubbleStyleVariants(theme)[variant],
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
}))

type MessageProps = {
  variant: MessageVariant
  message: HomeApplicationChatConversation_homeApplication_conversation_messages_edges
  isMessageRead: boolean
}

export function Message({ variant, message, isMessageRead }: MessageProps) {
  const { t } = useTranslation('commons')
  const messageText = message.node.message || ''
  const messageWithRenderedLinks = getTextWithRenderedLinks({ text: messageText })

  return (
    <MessageWrapper
      variant={variant}
      date={new Date(message.node.createdAt || placeholder.emptyString)}
      isRead={isMessageRead}
    >
      {message.node.upload ? (
        <MessageImage variant={variant} message={message.node.upload} />
      ) : (
        <MessageBubble variant={variant}>
          {messageWithRenderedLinks || t('placeholders.no_message')}
        </MessageBubble>
      )}
    </MessageWrapper>
  )
}
