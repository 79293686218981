import debounce from 'lodash/debounce'
import { useState, useEffect } from 'react'

const MESSAGE_DRAFT_SESSION_KEY_PREFIX = 'applicant_message_draft::'

type Props = {
  applicantId: string
}

export const useMessageDraft = ({ applicantId }: Props) => {
  const sessionStorageKey = MESSAGE_DRAFT_SESSION_KEY_PREFIX + applicantId
  const savedMessageDraft = sessionStorage.getItem(sessionStorageKey)
  const messageDraftInitialValue = savedMessageDraft ? JSON.parse(savedMessageDraft) : ''

  const [messageDraft, setMessageDraft] = useState<string>(messageDraftInitialValue)

  const debouncedMessageDraft = debounce(() => {
    if ([messageDraft, applicantId].every((value) => value !== '')) {
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(messageDraft))
    } else {
      // note: clear the saved session value if the user clears the message input to stop unwanted rehydration with persisted state
      sessionStorage.removeItem(sessionStorageKey)
    }
  }, 250)

  useEffect(() => {
    debouncedMessageDraft()
  }, [debouncedMessageDraft])

  return {
    messageDraft,
    setMessageDraft: (newDraft: string) => setMessageDraft(newDraft),
  }
}
