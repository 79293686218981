import { gql } from '@apollo/client'

import * as fragments from './fragments'

export const KICKOFF_HOME = gql`
  query KickoffHomeQuery(
    $id: ID!
    $orderBy: HomeApplicationOrderEnum
    $status: [HomeApplicationStatusEnum!]
    $home_id: [ID!]
  ) {
    home(id: $id) {
      id
      rent
      duration {
        endOptimal
        endUfn
        startAsap
        startOptimal
      }
      traits {
        id
        type
      }
      insurance
      qasaGuarantee
      tenureType
      ownHome
      tenantFees {
        waterFeePerTenant {
          availability
          cost {
            fixed
          }
        }
      }
      landlordServices {
        qasaGuarantee {
          isActive
          availability
          cost {
            total
          }
        }
        insurance {
          isActive
          availability
          cost {
            total
          }
        }
      }
    }
    homeApplications(orderBy: $orderBy, status: $status, homeId: $home_id) {
      totalCount
      edges {
        node {
          ...BaseHomeApplication
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
      }
    }
  }
  ${fragments.user}
  ${fragments.home}
  ${fragments.homeApplication}
`
