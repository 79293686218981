import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { palette } from '../../styling'
import { defaultStyles } from '../../styling/defaults'
import { Input } from '../../ui-atoms/input'
import { VerticalSpacer } from '../../ui-atoms/help-components/spacer'

type RangeValuesProps = {
  min?: string | number | null
  max?: string | number | null
  maxAllowed?: number | null
  minAllowed?: number | null
  minName: string
  maxName: string
  onChange: (value: string, type: 'min' | 'max') => void
  onInputFocusChange: (hasFocus: boolean, type: 'min' | 'max') => void
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, type: 'min' | 'max') => void
}

export function RangeValues(props: RangeValuesProps) {
  const { t } = useTranslation('commons')

  return (
    <Wrapper>
      <RangeValue
        name={props.minName}
        type="number"
        placeholder={t('min')}
        value={props.min ?? ''}
        min={props.minAllowed ?? 0}
        onChange={(e) => props.onChange(e.target.value, 'min')}
        onFocusChange={(hasFocus) => props.onInputFocusChange(hasFocus, 'min')}
        onKeyDown={(e) => props.onKeyDown(e, 'min')}
      />
      <VerticalSpacer factor={1} />
      <div>{'-'}</div>
      <VerticalSpacer factor={1} />
      <RangeValue
        name={props.maxName}
        type="number"
        placeholder={t('max')}
        max={props.maxAllowed ?? 100_000}
        value={props.max ?? ''}
        onChange={(e) => props.onChange(e.target.value, 'max')}
        onFocusChange={(hasFocus) => props.onInputFocusChange(hasFocus, 'max')}
        onKeyDown={(e) => props.onKeyDown(e, 'max')}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
})

const RangeValue = styled(Input)({
  display: 'flex',
  justifyContent: 'center',
  border: `1px solid ${palette.blocketGrey}`,
  borderRadius: defaultStyles.borderRadius,
  width: '40%',
  flexGrow: 1,
  fontSize: 22,
  fontWeight: 300,
  height: '35px',
  textAlign: 'center',
  ':focus': {
    outline: 'none',
    boxSizing: 'border-box',
    border: `2px solid ${palette.primary}`,
  },
  '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '[type=number]': {
    '-moz-appearance': 'textfield',
  },
})
