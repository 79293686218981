/* eslint-disable @typescript-eslint/no-explicit-any */
import type { StoreValue } from '@apollo/client'
import type { SafeReadonly } from '@apollo/client/cache/core/types/common'

import type { BuildingsQuery_buildings } from './graphql/types/__generated__/BuildingsQuery'
import type {
  HomeApplicationStatusEnum,
  TenantVerificationEnum,
} from './graphql/types/__generated__/globalTypes'
import type {
  HomeApplicationsRequest_homeApplications,
  HomeApplicationsRequest_homeApplications_edges_node,
} from './graphql/types/__generated__/HomeApplicationsRequest'

const EMPTY_EXISTING_PAGE_INFO = {
  hasNextPage: true,
  endCursor: '',
}

export const generalPagination = (): any => ({
  keyArgs: false,
  merge(existing: any, incoming: any, { args }: any) {
    return (args?.searchString && !args?.after) || !args?.after || !existing
      ? incoming
      : { ...incoming, edges: [...existing.edges, ...incoming.edges] }
  },
})

export const buildingsPagination = (): any => ({
  keyArgs: false,
  merge(existing: BuildingsQuery_buildings, incoming: BuildingsQuery_buildings, { args }: unknown) {
    return (args?.searchString && !args?.after) || !args?.after || !existing
      ? incoming
      : { ...incoming, edges: [...existing.edges, ...incoming.edges] }
  },
})

type MergePageInfoType = {
  existing: Omit<HomeApplicationsRequest_homeApplications, '__typename'>
  incoming: HomeApplicationsRequest_homeApplications
}

function mergePageInfo({ existing, incoming }: MergePageInfoType) {
  const existingPageInfo = existing?.pageInfo ? existing.pageInfo : EMPTY_EXISTING_PAGE_INFO

  if (incoming.edges.length === 0) return existingPageInfo

  if (!incoming.pageInfo) return existingPageInfo

  const endCursor = incoming?.pageInfo?.endCursor ? incoming.pageInfo.endCursor : existingPageInfo.endCursor

  const hasNextPage = incoming?.pageInfo.hasNextPage
    ? incoming.pageInfo.hasNextPage
    : existingPageInfo.hasNextPage

  return {
    ...existingPageInfo,
    endCursor,
    hasNextPage,
  }
}

type HomeApplicationsReadField = (
  fieldName: string,
  from?: HomeApplicationsRequest_homeApplications_edges_node,
) => SafeReadonly<StoreValue> | undefined

type HomeApplicationsRequestArgs = {
  args: {
    after?: string
    first?: string
    homeId?: string
    searchString?: string
    status?: HomeApplicationStatusEnum[]
    tenantVerifications?: TenantVerificationEnum[]
  }
  readField: HomeApplicationsReadField
}

function removeExistingNodesWithDuplicates(
  existing: HomeApplicationsRequest_homeApplications,
  incoming: HomeApplicationsRequest_homeApplications,
  readField: HomeApplicationsReadField,
) {
  const existingIds = existing?.edges.map((e) => readField('id', e.node) as string)
  const duplicatesRemoved = incoming?.edges.filter((edge) => {
    const incomingNodeId = readField('id', edge.node) as string
    return !existingIds.includes(incomingNodeId)
  })

  return duplicatesRemoved
}

export const tablePagination = (): any => ({
  keyArgs: false,
  merge(
    existing: HomeApplicationsRequest_homeApplications,
    incoming: HomeApplicationsRequest_homeApplications,
    { args, readField }: HomeApplicationsRequestArgs,
  ) {
    let newEdges = incoming.edges
    if (existing) {
      newEdges = removeExistingNodesWithDuplicates(existing, incoming, readField)
    }
    const updatedPageInfo = mergePageInfo({ existing, incoming })
    return (args?.searchString && !args?.after) || !args?.after || !existing
      ? incoming
      : { ...incoming, edges: [...existing.edges, ...newEdges], pageInfo: updatedPageInfo }
  },
})
