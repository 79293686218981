import i18next from 'i18next'

import type { DocumentIncomeVerification_user_creditReport } from '../../../data/graphql/types/__generated__/DocumentIncomeVerification'
import { formatMoney } from '../../../util-functions/general.util'

export const formatCreditReportData = (
  creditReport?: DocumentIncomeVerification_user_creditReport | null,
) => {
  if (!creditReport) {
    return null
  }

  // TODO: fix currency
  const currency = 'kr'
  const translationPrefix = 'applications:documents.credit_report_data'

  const {
    currentAddress,
    moveInfo,
    numberPaymentRemarks,
    totalDebt,
    yearlyIncomeStatements,
    debtSanitization: hasHadDebtSanitization,
    guardianship: isUnderGuardianship,
    seizureAttempt: hasHadSeizureAttempt,
  } = creditReport || {}

  const getDataPointAnswer = ({
    i18nKey,
    data,
  }: {
    i18nKey: 'guardianship' | 'debt_sanitation' | 'seizure_attempt'
    data?: boolean | null
  }) => {
    switch (data) {
      case true:
        return i18next.t(`${translationPrefix}.${i18nKey}`)
      case false:
        return i18next.t(`${translationPrefix}.${i18nKey}_none`)
      default:
        return i18next.t(`${translationPrefix}.missing`)
    }
  }

  const formattedAddress = currentAddress
    ? `${currentAddress?.street}${currentAddress?.street && ', '}${currentAddress?.postalCode} ${
        currentAddress?.city
      }`
    : i18next.t('commons:placeholders.missing_information')

  const formattedPaymentRemarks =
    numberPaymentRemarks === 0
      ? i18next.t(`${translationPrefix}.no_payment_remarks`)
      : numberPaymentRemarks?.toString() ?? i18next.t('commons:placeholders.missing_information')

  const formattedDebt =
    totalDebt === 0
      ? i18next.t(`${translationPrefix}.no_debt`)
      : formatMoney(totalDebt ?? undefined, 'kr') ?? i18next.t('commons:placeholders.missing_information')

  let incomePerYear = yearlyIncomeStatements ?? []
  if (yearlyIncomeStatements?.length && yearlyIncomeStatements?.length > 2) {
    incomePerYear = yearlyIncomeStatements.slice(-2)
  }

  const movesTranslationString =
    moveInfo?.count === 0
      ? i18next.t(`${translationPrefix}.has_not_moved_years`, { count: moveInfo?.years })
      : moveInfo?.count
        ? i18next.t(`${translationPrefix}.has_moved_years`, {
            count: moveInfo?.years,
            moveCount: moveInfo?.count,
          })
        : i18next.t('commons:placeholders.missing_information')

  return [
    { title: i18next.t(`${translationPrefix}.address`), content: formattedAddress },
    {
      title: i18next.t(`${translationPrefix}.moves`),
      content: movesTranslationString,
    },
    ...(incomePerYear &&
      incomePerYear.map(({ year, amount }) => ({
        title: i18next.t(`${translationPrefix}.taxed_income`, { year }),
        content: amount
          ? formatMoney(amount, currency)
          : i18next.t('commons:placeholders.missing_information'),
      }))),
    {
      title: i18next.t(`${translationPrefix}.debt_balance`),
      content: formattedDebt,
    },
    {
      title: i18next.t(`${translationPrefix}.payment_remarks`),
      content: formattedPaymentRemarks,
    },
    {
      title: i18next.t(`${translationPrefix}.guardianship_label`),
      content: getDataPointAnswer({ i18nKey: 'guardianship', data: isUnderGuardianship }),
    },
    {
      title: i18next.t(`${translationPrefix}.debt_sanitation_label`),
      content: getDataPointAnswer({ i18nKey: 'debt_sanitation', data: hasHadDebtSanitization }),
    },
    {
      title: i18next.t(`${translationPrefix}.seizure_attempt_label`),
      content: getDataPointAnswer({ i18nKey: 'seizure_attempt', data: hasHadSeizureAttempt }),
    },
  ]
}
