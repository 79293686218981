import { Stack, Heading } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { useListingContext } from '../../context/listing-context'
import { formatNumber } from '../../util-functions/general.util'
import { BaseInfoList } from '../apartment/apartment-details'

import { LandlordPreview } from './landlord-preview'
import { BaseInfoItem } from './base-info-item'

export function ShortcutDetails() {
  const { t } = useTranslation(['listing', 'commons'])
  const { home } = useListingContext()
  const { landlord, recommendedRentNew } = home || {}
  const fullLandlordName = `${landlord.firstName} ${landlord.private?.familyName}`

  return (
    <BaseInfoList>
      <Stack gap="1x">
        <Heading size="3xs">{t('shortcut_details.landlord_preview')}</Heading>
        <LandlordPreview
          fullName={fullLandlordName}
          email={landlord.private.email}
          phoneNumber={landlord.private?.phoneNumber}
          profilePictureUrl={landlord.profilePicture?.url}
        />
      </Stack>
      <BaseInfoItem
        heading={t('shortcut_details.recommended_rent')}
        value={
          recommendedRentNew
            ? formatNumber(recommendedRentNew)
            : t('commons:placeholders.missing_information')
        }
      />
    </BaseInfoList>
  )
}
