import React, { useContext, createContext, useState } from 'react'
import type { ReactNode } from 'react'

import type { TenantVerificationEnum } from '../data/graphql/types/__generated__/globalTypes'

type UpdateColumnSelectionParams = {
  applicantIds: string[]
  conversationIds: string[]
  columnId: string
}
type IncomeRange = { min: number; max: number }
type PendingColumnSettings = {
  shouldFulfillRequirements: boolean
  filters: TenantVerificationEnum[]
  incomeRange: IncomeRange
}

type ApplicationsContextProps = {
  children?: ReactNode
}

type ApplicationsContextValues = {
  selectedApplicantIds: string[]
  selectedConversationIds: string[]
  selectedColumnId: string
  updateColumnSelection: (newSelection: UpdateColumnSelectionParams) => void
  resetColumnSelection: () => void
  pendingColumnSettings: PendingColumnSettings
  updatePendingColumnSettings: (newSettings: Partial<PendingColumnSettings>) => void
}
const ApplicationsContext = createContext<ApplicationsContextValues>({
  selectedApplicantIds: [],
  selectedConversationIds: [],
  selectedColumnId: '',
  updateColumnSelection: () => undefined,
  resetColumnSelection: () => undefined,
  pendingColumnSettings: {
    shouldFulfillRequirements: true,
    filters: [],
    incomeRange: { min: 0, max: 100_000 },
  },
  updatePendingColumnSettings: () => undefined,
})

export function ApplicationsProvider({ children }: ApplicationsContextProps) {
  const [selectedColumnId, setSelectedColumnId] = useState<string>('')
  const [selectedApplicantIds, setSelectedApplicantIds] = useState<string[]>([])
  const [selectedConversationIds, setSelectedConversationIds] = useState<string[]>([])

  const [pendingColumnSettings, setPendingColumnSettings] = useState<PendingColumnSettings>({
    shouldFulfillRequirements: true,
    filters: [],
    incomeRange: { min: 0, max: 100_000 },
  })

  const updateColumnSelection = ({
    applicantIds,
    conversationIds,
    columnId,
  }: UpdateColumnSelectionParams) => {
    setSelectedApplicantIds(applicantIds)
    setSelectedConversationIds(conversationIds)
    setSelectedColumnId(columnId)
  }

  const resetColumnSelection = () => {
    setSelectedApplicantIds([])
    setSelectedConversationIds([])
    setSelectedColumnId('')
  }

  const updatePendingColumnSettings = (newSettings: Partial<PendingColumnSettings>) => {
    setPendingColumnSettings((existingSettings) => ({ ...existingSettings, ...newSettings }))
  }

  return (
    <ApplicationsContext.Provider
      value={{
        selectedApplicantIds,
        selectedConversationIds,
        selectedColumnId,
        pendingColumnSettings,
        updateColumnSelection,
        resetColumnSelection,
        updatePendingColumnSettings,
      }}
    >
      {children}
    </ApplicationsContext.Provider>
  )
}

export const useApplicationsContext = () => useContext(ApplicationsContext)
