import gql from 'graphql-tag'

const GET_ME = gql`
  query MeInformationQuery {
    me {
      companyName
      firstName
      profilePicture {
        url
      }
    }
  }
`

const queries = { GET_ME }

export { queries }
