import { useMutation } from '@apollo/client'
import { IconButton } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import type { BumpListingMutation } from '../../data/graphql/types/__generated__/BumpListingMutation'
import { BUMP_LISTING } from '../../data/bump-listing'
import { BumpedIcon } from '../../ui-shared/bump-icon'
import { useListingContext } from '../../context/listing-context'

export function BumpListing() {
  const { t } = useTranslation('listing')
  const { home } = useListingContext()
  const { id } = home || {}
  const [bumpListingAction] = useMutation<BumpListingMutation>(BUMP_LISTING, {
    refetchQueries: ['ListingQuery'],
    onError: (e) => {
      // eslint-disable-next-line no-console
      console.log(e)
    },
  })

  const handleClick = () => {
    bumpListingAction({
      variables: {
        homeId: id,
      },
    })
  }
  return (
    <IconButton
      variant="tertiary"
      onClick={handleClick}
      icon={BumpedIcon}
      label="bump-listing-button"
      title={t('icon_tooltips.bump')}
    />
  )
}
