import i18next from 'i18next'

import { HomeSearchOrderByEnum, HomeStatusEnum } from '../../data/graphql/types/__generated__/globalTypes'
import { TableColumn, TableImageCell, TableText } from '../../ui-atoms/table'
import type { TableRowType, Order } from '../../ui-atoms/table'
import type {
  ListingsQueryVariables,
  ListingsQuery_homes,
} from '../../data/graphql/types/__generated__/ListingsQuery'
import { formatDate } from '../../util-functions/date.util'
import { formatBasicInfo, formatLocation, formatMoney } from '../../util-functions/general.util'
import { getListingSpans } from '../listing/listing.util'
import buildingPlaceholder from '../../assets/placeholders/building.png'

import {
  Address,
  ApartmentNumber,
  ApplicationDeadline,
  ListingStatus,
  Rent,
  Rooms,
  Size,
  MoveIn,
  Requests,
  PublishDate,
} from './listings-table-components'

type ConstructListingsTableColumnsParams = {
  localArgs: ListingsQueryVariables
  onSort: (sortBy: HomeSearchOrderByEnum) => void
}
export const constructListingsTableColumns = ({ localArgs, onSort }: ConstructListingsTableColumnsParams) => {
  const translationPrefix = 'listings:listings_table.columns'
  const order = localArgs as unknown as Order
  return [
    /* eslint-disable react/jsx-key */
    <TableColumn>{i18next.t(`${translationPrefix}.apartment`)}</TableColumn>,
    <TableColumn>{i18next.t(`${translationPrefix}.status`)}</TableColumn>,
    <TableColumn>{i18next.t(`${translationPrefix}.application_deadline`)}</TableColumn>,
    <TableColumn
      order={order}
      active={localArgs.homesOrderBy === HomeSearchOrderByEnum.ROUTE}
      onClick={() => onSort(HomeSearchOrderByEnum.ROUTE)}
    >
      {i18next.t(`${translationPrefix}.address`)}
    </TableColumn>,
    <TableColumn
      order={order}
      active={localArgs.homesOrderBy === HomeSearchOrderByEnum.ROOM_COUNT}
      onClick={() => onSort(HomeSearchOrderByEnum.ROOM_COUNT)}
    >
      {i18next.t(`${translationPrefix}.rooms`)}
    </TableColumn>,
    <TableColumn
      order={order}
      active={localArgs.homesOrderBy === HomeSearchOrderByEnum.SQUARE_METERS}
      onClick={() => onSort(HomeSearchOrderByEnum.SQUARE_METERS)}
    >
      {i18next.t(`${translationPrefix}.size`)}
    </TableColumn>,
    <TableColumn
      order={order}
      active={localArgs.homesOrderBy === HomeSearchOrderByEnum.RENT}
      onClick={() => onSort(HomeSearchOrderByEnum.RENT)}
    >
      {i18next.t(`${translationPrefix}.rent`)}
    </TableColumn>,
    <TableColumn
      order={order}
      active={localArgs.homesOrderBy === HomeSearchOrderByEnum.MOVE_IN}
      onClick={() => onSort(HomeSearchOrderByEnum.MOVE_IN)}
    >
      {i18next.t(`${translationPrefix}.move_in`)}
    </TableColumn>,
    <TableColumn>{i18next.t(`${translationPrefix}.applications`)}</TableColumn>,
    <TableColumn
      order={order}
      active={localArgs.homesOrderBy === HomeSearchOrderByEnum.PUBLISHED_AT}
      onClick={() => onSort(HomeSearchOrderByEnum.PUBLISHED_AT)}
    >
      {i18next.t(`${translationPrefix}.published_at`)}
    </TableColumn>,
    /* eslint-enable react/jsx-key */
  ]
}

type ConstructListingsTableRowsParams = {
  homes?: ListingsQuery_homes
  isLoading?: boolean
}
export const constructListingsTableRows = ({ homes, isLoading }: ConstructListingsTableRowsParams) => {
  if (isLoading) {
    return Array<TableRowType>(20)
      .fill({ id: '', row: [] })
      .map((_, index) => ({
        id: Math.random().toString(),
        row: [
          /* eslint-disable react/jsx-key */
          <ApartmentNumber isLoading={true} />,
          <ListingStatus isLoading={true} />,
          <ApplicationDeadline isLoading={true} />,
          <Address isLoading={true} />,
          <Rooms isLoading={true} />,
          <Size isLoading={true} />,
          <Rent isLoading={true} />,
          <MoveIn isLoading={true} />,
          <Requests isLoading={true} />,
          <PublishDate isLoading={true} />,
          /* eslint-enable react/jsx-key */
        ],
      }))
  }
  const rows = homes?.edges.map(({ node: listing }) => {
    const translationPrefix = 'listings:listings_table'

    const isBlockListing =
      Number(listing?.property?.numberOfHomes) > 1 ? listing.property?.numberOfHomes : listing.numberOfHomes
    const listingSpans = getListingSpans({ home: listing })
    const apartmentImage = listing.uploads[0]?.url || buildingPlaceholder
    let apartmentNumber
    if (isBlockListing) {
      apartmentNumber = (
        <ApartmentNumber>
          <TableImageCell
            imageUrl={apartmentImage}
            imageLabel={<TableText>{i18next.t('commons:group_listing')}</TableText>}
          />
        </ApartmentNumber>
      )
    } else {
      apartmentNumber = (
        <TableImageCell
          imageUrl={apartmentImage}
          imageLabel={<TableText>{listing.apartmentNumber}</TableText>}
        />
      )
    }

    const getListingStatus = (
      status: HomeStatusEnum,
      archivedBecauseOfApplicationDeadlinePassed: boolean,
    ) => {
      if (status === HomeStatusEnum.pending) {
        return i18next.t(`${translationPrefix}.listing_status.draft`)
      } else if (status === HomeStatusEnum.in_review) {
        return i18next.t(`${translationPrefix}.listing_status.in_review`)
      } else if (status === HomeStatusEnum.archived && !archivedBecauseOfApplicationDeadlinePassed) {
        return i18next.t(`${translationPrefix}.listing_status.archived`)
      } else if (archivedBecauseOfApplicationDeadlinePassed) {
        return i18next.t(`${translationPrefix}.listing_status.expired`)
      }
      return i18next.t(`${translationPrefix}.listing_status.published`)
    }
    const listingStatus = (
      <ListingStatus
        status={listing.status}
        archivedBecauseOfApplicationDeadlinePassed={listing.archivedBecauseOfApplicationDeadlinePassed}
      >
        {getListingStatus(listing.status, listing.archivedBecauseOfApplicationDeadlinePassed)}
      </ListingStatus>
    )

    let applicationDeadline = (
      <ApplicationDeadline>{i18next.t('commons:placeholders.not_filled_in')}</ApplicationDeadline>
    )
    if (listing.applicationDeadline) {
      applicationDeadline = (
        <ApplicationDeadline>{formatDate(new Date(listing.applicationDeadline))}</ApplicationDeadline>
      )
    }

    let address = <Address>{i18next.t(`${translationPrefix}.unavailable`)}</Address>
    if (listing.location) {
      address = <Address>{formatLocation(listing.location)}</Address>
    }

    let roomCount
    if (isBlockListing) {
      roomCount = <Rooms>{formatBasicInfo({ isBlock: true, span: listingSpans.roomCountSpan })}</Rooms>
    } else {
      roomCount = <Rooms>{listing.roomCount}</Rooms>
    }

    let squareMeters
    if (isBlockListing) {
      squareMeters = (
        <Size>
          {formatBasicInfo({
            isBlock: true,
            span: listingSpans.squareMeterSpan,
            unit: i18next.t('commons:square_meter'),
          })}
        </Size>
      )
    } else {
      squareMeters = <Size>{`${listing.squareMeters} ${i18next.t('commons:square_meter')}`}</Size>
    }

    let rent
    // TODO: use currency based on brand
    if (isBlockListing) {
      rent = <Rent>{formatBasicInfo({ isBlock: true, span: listingSpans.rentSpan, unit: 'SEK' })}</Rent>
    } else {
      rent = <Rent>{listing.rent && formatMoney(listing.rent, 'SEK')}</Rent>
    }

    let moveIn = <MoveIn>{i18next.t(`${translationPrefix}.unavailable`)}</MoveIn>
    if (listing.duration) {
      if (listing.duration.startOptimal) {
        moveIn = <MoveIn>{formatDate(new Date(listing.duration.startOptimal))}</MoveIn>
      } else if (listing.duration.startAsap) {
        moveIn = <MoveIn>{i18next.t(`${translationPrefix}.as_soon_as_possible`)}</MoveIn>
      }
    }

    const applicationCount = <Requests>{listing.applicationStats.applicationCount}</Requests>

    let listingDate = <PublishDate>{i18next.t(`${translationPrefix}.unavailable`)}</PublishDate>
    if (listing.publishedAt) {
      listingDate = <PublishDate>{formatDate(new Date(listing.publishedAt))}</PublishDate>
    }

    return {
      id: listing.id,
      row: [
        apartmentNumber,
        listingStatus,
        applicationDeadline,
        address,
        roomCount,
        squareMeters,
        rent,
        moveIn,
        applicationCount,
        listingDate,
      ],
    }
  })

  return rows ? rows : []
}
