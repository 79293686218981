import styled from '@emotion/styled'
import { useEffect, useRef, useState } from 'react'
import { Paragraph, Stack } from '@qasa/qds-ui'

import { palette } from '../../../styling'

const ColumnMenuWrapper = styled.div({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  userSelect: 'none',
})

const Menu = styled.div<{ width?: string }>(({ width }) => ({
  maxHeight: 'calc(100vh - 290px)',
  overflow: 'scroll',
  width: width ? width : '250px',
  backgroundColor: 'white',
  boxShadow: `0 0 20px ${palette.shadow}`,
  position: 'absolute',
  display: 'flex',
  alginItems: 'center',
  right: -10,
  top: 15,
  zIndex: 20,
  flexDirection: 'column',
  padding: 12,
}))

export const SectionTitle = styled(Paragraph)(({ theme }) => ({
  color: theme.colors.text.subtle,
}))

export const ActionContainer = styled(Stack)({
  cursor: 'pointer',
})

export const ActionName = Paragraph

type ColumnMenuProps = {
  label?: string
  children?: React.ReactNode
  width?: string
  Icon: JSX.Element
}

export function ColumnMenu({ children, label, width, Icon }: ColumnMenuProps) {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const handleClick = (e: MouseEvent) => {
    if (ref.current && e.target && !ref.current.contains(e.target as Node)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  return (
    <ColumnMenuWrapper ref={ref}>
      <div onClick={() => setIsOpen(!isOpen)}>
        {label ? (
          <Stack direction="row" alignItems="center">
            <Paragraph size="sm">{label}</Paragraph>
            {Icon}
          </Stack>
        ) : (
          Icon
        )}
      </div>
      {isOpen && <Menu width={width}>{children}</Menu>}
    </ColumnMenuWrapper>
  )
}
