import styled from 'styled-components/macro'
import { useRef } from 'react'
import type { ReactElement } from 'react'
import type { FontawesomeObject } from '@fortawesome/fontawesome-svg-core'

import { fontSize, palette } from '../styling'
import { getPixelAmount } from '../styling/pixel-amount'

type InputOptionProps = {
  icon: ReactElement<FontawesomeObject>
  disabled?: boolean
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void
}

const InputOptionWrapper = styled.button({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'transparent',
  outline: 'none',
  border: 'none',
  fontSize: fontSize.textXl,
  padding: `0 ${getPixelAmount(1)}`,
  color: palette.primary,
  cursor: 'pointer',
  ':hover': {
    color: palette.primary,
  },
  ':focus': {
    color: palette.primary,
  },
  '&:disabled': {
    color: palette.grey40,
    cursor: 'default',
  },
})

export function InputOption(props: InputOptionProps) {
  const optionRef = useRef<HTMLButtonElement>(null)

  /**
   * When using tab and enter to trigger onClick we want the button to
   * not be focused.
   */
  const handleClick = (event?: React.MouseEvent<HTMLButtonElement>) => {
    props.onClick && props.onClick(event)
    if (optionRef.current !== undefined || optionRef.current !== null) {
      optionRef.current!.blur()
    }
  }

  return (
    <InputOptionWrapper onClick={handleClick} ref={optionRef} disabled={props.disabled}>
      {props.icon}
    </InputOptionWrapper>
  )
}
