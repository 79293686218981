import moment from 'moment'
import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { useEffectOnMount } from '../../../hooks/use-effect-on-mount'
import { ContextMenu } from '../../../ui-atoms/context-menu'
import { DatePicker } from '../../../ui-atoms/date-picker'
import { Input } from '../../../ui-atoms/input'
import { Radio } from '../../../ui-atoms/radio'
import { Stack } from '../../../ui-shared/_core/stack'
import { Typography } from '../../../ui-shared/_core/typography'
import { formatDateYYYYMMDD } from '../../../util-functions/date.util'

import { ContractFormInputHeader } from './contract-form-input-header'

const Wrapper = Stack
const FlexGrow = styled.div({ flex: 1 })

const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.error100,
}))

export function ContractFormDate() {
  const [isStartOpen, setStartOpen] = useState(false)
  const [isEndOpen, setEndOpen] = useState(false)
  const [datePickerPosition, setDatePickerPosition] = useState({ x: 0, y: 0 })

  const { control, setValue, getValues } = useFormContext()

  const { t } = useTranslation('applicant', {
    keyPrefix: 'contract_form',
  })

  const handleOpenStartDatePicker = (event?: React.MouseEvent<HTMLDivElement>) => {
    if (event) {
      setStartOpen(true)
      setDatePickerPosition({ x: event.pageX, y: event.pageY })
    }
  }
  const handleOpenEndDatePicker = (name?: string, event?: React.MouseEvent<HTMLDivElement>) => {
    if (event) {
      if (!getValues('duration.endOptimal')) {
        setValue('duration.endOptimal', new Date())
      }
      setEndOpen(true)
      setDatePickerPosition({ x: event.pageX, y: event.pageY })
      setValue('duration.endUfn', false)
    }
  }

  const isMoveInAfterMoveOut = () => {
    const moveIn = getValues('duration.startOptimal')
    const moveOut = getValues('duration.endOptimal')
    if (moveOut) {
      const moveOutMoment = moment(moveOut).add('days', 1)
      return moment(moveOutMoment).isAfter(moment(moveIn))
    }
    return true
  }

  useEffectOnMount(() => {
    if (getValues('duration.startOptimal') === null) {
      setValue('duration.startOptimal', new Date())
      setValue('duration.startAsap', false)
    }
  })

  return (
    <Wrapper gap={2}>
      <ContractFormInputHeader label={t('move_in')} />
      <Controller
        name="duration.startOptimal"
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, value, name }, fieldState }) => {
          return (
            <>
              <Input
                name={name}
                value={value ? formatDateYYYYMMDD(new Date(value)) : formatDateYYYYMMDD(new Date())}
                onClick={handleOpenStartDatePicker}
                onChange={onChange}
                readOnly
                errorMessage={fieldState.error?.message}
              />
              <ContextMenu
                isOpen={isStartOpen}
                onClose={() => setStartOpen(false)}
                position={datePickerPosition}
              >
                <DatePicker
                  activeDate={new Date(value) || new Date()}
                  onChange={(date) => onChange(formatDateYYYYMMDD(new Date(date.date)))}
                />
              </ContextMenu>
            </>
          )
        }}
      />

      <ContractFormInputHeader label={t('move_out')} />
      <Controller
        name="duration.endUfn"
        control={control}
        render={({ field: { name } }) => {
          return (
            <Stack gap={1} direction={['column', 'row']}>
              <FlexGrow>
                <Radio
                  title={t('ufn')}
                  name={name}
                  isChecked={getValues('duration.endUfn')}
                  onClick={() => {
                    setValue('duration.endOptimal', null)
                    setValue('duration.endUfn', true)
                  }}
                />
              </FlexGrow>
            </Stack>
          )
        }}
      />
      <Controller
        name="duration.endOptimal"
        control={control}
        rules={{
          validate: isMoveInAfterMoveOut,
        }}
        render={({ field: { onChange, value, name }, fieldState }) => {
          return (
            <Stack gap={1} direction={['column', 'row']}>
              <FlexGrow>
                <Radio
                  title={value ? formatDateYYYYMMDD(new Date(value)) : t('choose_date')}
                  name={name}
                  isChecked={!getValues('duration.endUfn')}
                  onClick={handleOpenEndDatePicker}
                />
                <ContextMenu
                  isOpen={isEndOpen}
                  onClose={() => setEndOpen(false)}
                  position={datePickerPosition}
                >
                  <DatePicker
                    activeDate={new Date(value) || new Date()}
                    onChange={(date) => onChange(formatDateYYYYMMDD(new Date(date.date)))}
                  />
                </ContextMenu>
              </FlexGrow>
              {fieldState.error && (
                <ErrorMessage variant={'bodySmallBold'}>{t('date_invalid_message')}</ErrorMessage>
              )}
            </Stack>
          )
        }}
      />
    </Wrapper>
  )
}
