import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from '@emotion/styled'
import { Button, Stack } from '@qasa/qds-ui'

import { fontSize } from '../../styling'

type FilterDisplayProps = {
  hasFilter: boolean
  name: string
  activeFilter?: string | null
  onClick: (event?: React.MouseEvent<HTMLButtonElement>) => void
  reset: () => void
}

const PillButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.radii.full,
}))

const Wrapper = styled.div<{ hasFilter: boolean }>(({ hasFilter }) => ({
  fontSize: fontSize.textSm,
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  fontWeight: hasFilter ? 600 : 400,
}))

export function FilterDisplay(props: FilterDisplayProps) {
  const handleResetClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    props.reset()
  }

  return (
    <PillButton onClick={props.onClick} size="xs" variant="tertiary">
      <Wrapper hasFilter={props.hasFilter}>
        {props.name}
        {props.hasFilter && (
          <Stack gap="1x" direction="row" alignItems="center">
            {`: ${props.activeFilter}`}
            <FontAwesomeIcon icon={['fal', 'times']} onClick={handleResetClick} />
          </Stack>
        )}
      </Wrapper>
    </PillButton>
  )
}
