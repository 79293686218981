import styled from '@emotion/styled'
import { Paragraph, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { HomeStatusEnum } from '../../data/graphql/types/__generated__/globalTypes'
import type { ListingQuery_home } from '../../data/graphql/types/__generated__/ListingQuery'
import { formatDate } from '../../util-functions/date.util'

type ListingStatusProps = {
  showLabel?: boolean
} & Pick<ListingQuery_home, 'status' | 'archivedBecauseOfApplicationDeadlinePassed' | 'publishedAt'>

const StatusDot = styled.div<Partial<ListingStatusProps>>(
  ({ theme, status, archivedBecauseOfApplicationDeadlinePassed }) => ({
    width: 8,
    height: 8,
    borderRadius: theme.radii.full,
    ...((status === HomeStatusEnum.normal || status === HomeStatusEnum.in_review) && {
      backgroundColor: theme.colors.icon.positive,
    }),
    ...(status === HomeStatusEnum.pending && {
      backgroundColor: theme.colors.icon.default,
    }),
    ...(status === HomeStatusEnum.archived &&
      archivedBecauseOfApplicationDeadlinePassed && {
        backgroundColor: theme.colors.icon.warning,
      }),
    ...(status === HomeStatusEnum.archived &&
      !archivedBecauseOfApplicationDeadlinePassed && {
        backgroundColor: theme.colors.icon.disabled,
      }),
  }),
)

const SyledParagraph = styled(Paragraph)<Partial<ListingStatusProps>>(
  ({ theme, status, archivedBecauseOfApplicationDeadlinePassed }) => ({
    ...((status === HomeStatusEnum.normal || status === HomeStatusEnum.in_review) && {
      color: theme.colors.text.positive,
    }),
    ...(status === HomeStatusEnum.pending && {
      color: theme.colors.text.default,
    }),
    ...(status === HomeStatusEnum.archived &&
      archivedBecauseOfApplicationDeadlinePassed && {
        color: theme.colors.text.warning,
      }),
    ...(status === HomeStatusEnum.archived &&
      !archivedBecauseOfApplicationDeadlinePassed && {
        color: theme.colors.text.disabled,
      }),
  }),
)

export function ListingStatusIndicator({
  status,
  archivedBecauseOfApplicationDeadlinePassed,
  showLabel = true,
  publishedAt,
}: ListingStatusProps) {
  const { t } = useTranslation('listings', { keyPrefix: 'listings_table' })
  const getText = () => {
    // NOTE: adding the in_review here since it should be momentary (all pro listings should get autopublished) so we can show correct state to the user in that phase
    if (status === HomeStatusEnum.normal || status === HomeStatusEnum.in_review) {
      return publishedAt ? formatDate(new Date(publishedAt)) : t('listing_status.published')
    }
    if (status === HomeStatusEnum.pending) {
      return t('listing_status.draft')
    }
    if (status === HomeStatusEnum.archived && archivedBecauseOfApplicationDeadlinePassed) {
      return t('listing_status.expired')
    }
    if (status === HomeStatusEnum.archived && !archivedBecauseOfApplicationDeadlinePassed) {
      return t('listing_status.archived')
    }
    return ''
  }

  return (
    <Stack direction={'row'} alignItems={'center'} gap="1x">
      <StatusDot
        status={status}
        archivedBecauseOfApplicationDeadlinePassed={archivedBecauseOfApplicationDeadlinePassed}
      />
      {showLabel && <SyledParagraph status={status}>{getText()}</SyledParagraph>}
    </Stack>
  )
}
