import { isSameDay } from 'date-fns'

import type {
  HomeApplicationsByBoardList_homeApplicationsByBoardList_edges_node,
  HomeApplicationsByBoardList_homeApplicationsByBoardList_edges_node_home_duration,
} from '../../../data/graphql/types/__generated__/HomeApplicationsByBoardList'
import { Status } from '../../../ui-atoms/verification-badge'

export type MoveInPreferenceMatch = {
  status: Status
  label: string
  title: string
}
type GetMoveInPreferenceMatchParams = {
  duration: HomeApplicationsByBoardList_homeApplicationsByBoardList_edges_node_home_duration
  startOptimal: HomeApplicationsByBoardList_homeApplicationsByBoardList_edges_node['startOptimal']
}
export const getMoveInPreferenceMatch = ({
  duration,
  startOptimal,
}: GetMoveInPreferenceMatchParams): MoveInPreferenceMatch => {
  const { startOptimal: homeStartOptimal, startAsap: isStartAsap } = duration

  if (startOptimal !== null) {
    if (isStartAsap) {
      return {
        status: Status.Default,
        label: 'move_in_with_date',
        title: 'move_in_no_landlord_date',
      }
    } else if (homeStartOptimal && isSameDay(new Date(startOptimal), new Date(homeStartOptimal))) {
      return {
        status: Status.Positive,
        label: 'move_in',
        title: 'move_in_matching',
      }
    } else {
      return {
        status: Status.Negative,
        label: 'move_in_with_date',
        title: 'move_in_not_matching',
      }
    }
  }

  if (isStartAsap) {
    return {
      status: Status.Default,
      label: 'move_in',
      title: 'move_in_no_dates',
    }
  }

  return {
    status: Status.Default,
    label: 'move_in',
    title: 'move_in_no_tenant_date',
  }
}
