import styled, { css } from 'styled-components/macro'

import { useDrawerContext } from '../../context/drawer-context'
import { palette } from '../../styling'
import { animateInDesktop, animateOutDesktop, DRAWER_ANIMATION_TIME_MS } from '../../ui-atoms/drawer'
import { ApplicantActions } from '../applicant/applicant-actions'
import { ApplicantTab, useApplicantContext } from '../../context/applicant-context'

const Footer = styled.div<{ isClosing: boolean }>(
  {
    position: 'fixed',
    height: '70px',
    zIndex: 10,
    bottom: 0,
    right: 0,
    backgroundColor: palette.white,
    boxShadow: `rgb(0 0 0 / 18%) 4px 0px 4px`,
    paddingRight: '24px',
    paddingLeft: '24px',
    display: 'flex',
    justifyContent: 'center',
  },
  ({ isClosing }) => {
    const animationDesktop = isClosing ? animateOutDesktop : animateInDesktop
    return css`
      animation: ${animationDesktop} ${DRAWER_ANIMATION_TIME_MS}ms ease-out forwards;
    `
  },
)

export function ApplicantFooter() {
  const { isDrawerClosing } = useDrawerContext()
  const { currentTab } = useApplicantContext()
  return (
    <Footer isClosing={isDrawerClosing}>
      {currentTab === ApplicantTab.Contract ? null : <ApplicantActions />}
    </Footer>
  )
}
