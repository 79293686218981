import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { useMutation } from '@apollo/client'
import { Heading, Button, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'

import type {
  CreateHomeInput,
  UpsertRentalRequirementInput,
} from '../../data/graphql/types/__generated__/globalTypes'
import type {
  ApartmentForAddListingQuery_homeTemplate_building_uploads,
  ApartmentForAddListingQuery_homeTemplate_uploads,
} from '../../data/graphql/types/__generated__/ApartmentForAddListingQuery'
import type {
  CreateHomeMutation,
  CreateHomeMutationVariables,
} from '../../data/graphql/types/__generated__/CreateHomeMutation'
import { mutations as addListingMutations } from '../../data/add-listing'
import { mutations as editListingMutations } from '../../data/edit-listing'
import { TopSection } from '../../ui-atoms/top-section'
import { fontSize } from '../../styling'
import type { UpsertRentalRequirement } from '../../data/graphql/types/__generated__/UpsertRentalRequirement'
import { blocketPalette } from '../../styling/colors'
import { BasicListingInformation } from '../edit-listing/basic-listing-information'
import { WhiteBoxWrapper } from '../../ui-shared/wrapper'
import { LoadingDots } from '../../ui-shared/loading-dots'
import { ViewingInformation } from '../edit-listing/viewing-information'
import { HomeDetails } from '../edit-listing/home-details'
import { ListingRentalRequirements } from '../edit-listing/listing-rental-requirements'
import { useUserContext } from '../../context/user-context'

import { FormSection } from './form-section'
import { HomeTemplateImages } from './home-template-images'

export type ListingFormValues = CreateHomeInput & { rentalRequirements: UpsertRentalRequirementInput }

type AddListingFormProps = {
  title: string
  isBlockListing: boolean
  listingInput: CreateHomeInput
  imageUploads?: (
    | ApartmentForAddListingQuery_homeTemplate_uploads
    | ApartmentForAddListingQuery_homeTemplate_building_uploads
  )[]
}

const Wrapper = styled.div({
  height: '100%',
  display: 'flex',
})

const Content = styled(Stack)({
  maxWidth: '1280px',
  width: '100%',
  height: '100%',
})

const ButtonsContainer = Stack

const ErrorText = styled.p({
  color: blocketPalette.orange,
  fontSize: fontSize.textSm,
})

export function AddListingForm({ title, isBlockListing, listingInput, imageUploads }: AddListingFormProps) {
  const navigate = useNavigate()
  const { authBody } = useUserContext()
  const isProAgent = authBody?.isProAgent
  const [errors, setErrors] = useState<Record<string, string[]>>()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const { t } = useTranslation(['add_listing', 'edit_listing', 'listing', 'commons'])
  const formMethods = useForm<ListingFormValues>({
    defaultValues: {
      ...listingInput,
      rentalRequirements: {
        verifiedIncome: false,
        rentMultiplier: 2,
        verifiedIdNumber: false,
        approvedCreditCheck: false,
      },
    },
  })

  const [createListing, { loading: isLoading }] = useMutation<
    CreateHomeMutation,
    CreateHomeMutationVariables
  >(addListingMutations.ADD_LISTING, {
    variables: { createHomeInput: listingInput },
    onCompleted: (data) => onCreationComplete(data),
    onError: (e) => {
      setErrorMessage(t('edit_listing:errors.save_failed_check_data'))
      // eslint-disable-next-line no-console
      console.log(e)
    },
  })

  const [upsertRentalRequirement] = useMutation<UpsertRentalRequirement>(
    editListingMutations.UPSERT_RENTAL_REQUIREMENT,
    {
      variables: {
        rentalRequirementInput: formMethods.getValues('rentalRequirements'),
      },
      onError: (e) => {
        setErrorMessage(t('edit_listing:errors.save_failed_check_data'))
        // eslint-disable-next-line no-console
        console.log(e)
      },
    },
  )

  const handleCreateListing = (formValues: ListingFormValues) => {
    const { rentalRequirements, ...createHomeInput } = formValues
    createListing({ variables: { createHomeInput } })
  }

  const onCreationComplete = (data: CreateHomeMutation) => {
    if (data.createHome?.errors) {
      const errors = data.createHome.errors.reduce((acc, error) => {
        Object(acc)[error.field] = error.codes
        return acc
      }, {})
      setErrors(errors)
      setErrorMessage(t('edit_listing:errors.save_failed_check_data'))
    } else {
      upsertRentalRequirement({
        variables: {
          homeId: data.createHome?.home?.id,
          rentalRequirementInput: formMethods.getValues('rentalRequirements'),
        },
      }).then(() => {
        navigate(`/listings/${data.createHome?.home?.id}`, {
          replace: true,
        })
      })
    }
  }

  if (errors) {
    // eslint-disable-next-line no-console
    console.log(errors)
  }

  if (isLoading) {
    return <LoadingDots />
  }

  return (
    <Wrapper>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleCreateListing)}>
          <Content gap={'6x'}>
            <TopSection>
              <Heading as="h1" size="md">
                {title}
              </Heading>
              <Stack gap={'3x'}>
                <ButtonsContainer direction={'row'} gap={'3x'}>
                  <Button type="submit" variant="secondary">
                    {t('add_listing_form.save_draft')}
                  </Button>
                  <Button onClick={() => navigate(`/listings`, { replace: true })} variant="tertiary">
                    {t('commons:ctas.cancel')}
                  </Button>
                </ButtonsContainer>
                {errorMessage && <ErrorText> {errorMessage}</ErrorText>}
              </Stack>
            </TopSection>
            <WhiteBoxWrapper gap="8x">
              <FormSection
                title={t('edit_listing:basic_listing_information.title')}
                description={t('edit_listing:basic_listing_information.description')}
              >
                <BasicListingInformation />
              </FormSection>

              {isProAgent && (
                <FormSection title={t('listing:shortcut_details.matterport_label')}>
                  <ViewingInformation />
                </FormSection>
              )}

              <FormSection
                title={t('listing:rental_requirements.title')}
                description={t('listing:rental_requirements.description')}
              >
                <ListingRentalRequirements />
              </FormSection>

              <FormSection
                title={t('edit_listing:basic_listing_information.home_details_title')}
                description={t('edit_listing:basic_listing_information.mandatory_info_description')}
              >
                <HomeDetails isBlockListing={isBlockListing} />
              </FormSection>

              <HomeTemplateImages data={imageUploads ?? []} />
              {errorMessage && <ErrorText> {errorMessage}</ErrorText>}
            </WhiteBoxWrapper>
          </Content>
        </form>
      </FormProvider>
    </Wrapper>
  )
}
