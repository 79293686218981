import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import type { ListingsQuery_homes_edges_node } from '../../../data/graphql/types/__generated__/ListingsQuery'
import { placeholder } from '../../../styling'
import { formatDateYYYYMMDD } from '../../../util-functions/date.util'
import { ListingApplicationStats } from '../listing-application-stats'
import { queries } from '../../../data/listing'
import type { UnreadMessagesCountQuery } from '../../../data/graphql/types/__generated__/UnreadMessagesCountQuery'
import buildingPlaceholder from '../../../assets/placeholders/building.png'

import { AddressWithApartmentDetails } from './listing-address-details'

type ListingCardProps = {
  data: ListingsQuery_homes_edges_node
  currentListing: string | undefined
  setCurrentListing: (id: string | undefined) => void
}

const CardWrapper = styled(Stack)<{ isCurrentListing: boolean }>(({ theme, isCurrentListing }) => ({
  width: '100%',
  justifySelf: 'stretch',
  cursor: 'pointer',
  backgroundColor: isCurrentListing ? theme.colors.core.gray20 : theme.colors.bg.default,
  padding: theme.spacing['3x'],
  borderRadius: theme.radii.xs,
}))

const Image = styled.img(({ theme }) => ({
  minWidth: theme.sizes['24x'],
  width: theme.sizes['24x'],
  height: theme.sizes['24x'],
  objectFit: 'cover',
  borderRadius: theme.radii.xs,
}))

const ListingInformation = styled(Stack)({
  flex: 1,
})

export function ListingCard({ data, currentListing, setCurrentListing }: ListingCardProps) {
  const dateToShow = data.lastBumpedAt ?? data.publishedAt
  const formattedPublishedOrBumpedDate = dateToShow
    ? formatDateYYYYMMDD(new Date(dateToShow))
    : placeholder.emptyString
  const primaryImage = data!.uploads[0]

  const applicationCount = data.applicationStats.applicationCount || '0'
  const { id, location, roomCount, apartmentNumber, squareMeters, numberOfHomes } = data!

  const isCurrentListing = currentListing === id ? true : false

  const handleClick = () => {
    setCurrentListing(id)
  }

  const { data: messageData } = useQuery<UnreadMessagesCountQuery>(queries.GET_UNREAD_MESSAGES_COUNT, {
    variables: {
      homeId: data.id,
    },
    fetchPolicy: 'no-cache',
  })

  const { t } = useTranslation('applications', { keyPrefix: 'listings.listing_card' })

  const unreadMessagesCount = messageData?.conversations.nodes.length || '0'
  return (
    <CardWrapper direction="row" gap="3x" isCurrentListing={isCurrentListing} onClick={handleClick}>
      <Image src={primaryImage?.url ?? buildingPlaceholder} alt={'building'} />
      <ListingInformation gap="1x">
        <AddressWithApartmentDetails
          route={location?.route}
          streetNumber={location?.streetNumber}
          locality={location?.locality}
          squareMeters={squareMeters}
          roomCount={roomCount}
          apartmentNumber={apartmentNumber}
          numberOfHomes={numberOfHomes}
          publishedAt={formattedPublishedOrBumpedDate}
          postalCode={location?.postalCode}
        />
        <div>
          <ListingApplicationStats header={t('applications')} value={applicationCount} />
          <ListingApplicationStats header={t('unread_messages')} value={unreadMessagesCount} />
        </div>
      </ListingInformation>
    </CardWrapper>
  )
}
