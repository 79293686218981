import i18next from 'i18next'

import type { TraitTypeEnum } from '../data/graphql/types/__generated__/globalTypes'

export function getTraits(trait: TraitTypeEnum) {
  const myAmenity = `amenities:${trait}`
  if (i18next.exists(myAmenity)) {
    // NOTE: since there's traits we don't have translations for the types were complaining here
    return i18next.t(myAmenity as unknown as TemplateStringsArray)
  }
  return trait
}
