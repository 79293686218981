import type { ReactNode } from 'react'

import { HorizontalDivider } from '../../ui-atoms/help-components'
import { Stack } from '../../ui-shared/_core/stack'
import { Typography } from '../../ui-shared/_core/typography'

type Props = {
  title: string
  description?: string
  children?: ReactNode
}
export function FormSection({ title, description, children }: Props) {
  return (
    <Stack gap={6}>
      <Stack gap={1}>
        <Typography variant={'heading3'}> {title}</Typography>
        {description && <Typography colorVariant={'secondary'}>{description}</Typography>}
      </Stack>
      <Stack gap={1}>{children}</Stack>

      <HorizontalDivider />
    </Stack>
  )
}
