import styled from 'styled-components/macro'

import { pixelAmount } from '../../../styling'
import { Checkbox } from '../../../ui-atoms/checkbox'
import { useApplicationsContext } from '../../../context/applications-context'

import { CheckBoxWrapper } from './column-header'

const ImageWrapper = styled.div({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  userSelect: 'none',
})

const AvatarImage = styled.img({
  maxWidth: 'none',
  objectFit: 'cover',
  borderRadius: '50%',
  width: pixelAmount.xl4,
  height: pixelAmount.xl4,
})

const CheckboxPlacement = styled.div({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alginItems: 'center',
  left: -8,
  top: -14,
  padding: '0 6px',
})

type ApplicantCardAvatarProps = {
  id: string
  currentColumnId: string
  conversationId: string
  avatarSrc: string
  avatarAlt: string
  isCheckboxHidden?: boolean
}

export function ApplicantCardAvatar({
  id,
  currentColumnId,
  conversationId,
  avatarSrc,
  avatarAlt,
  isCheckboxHidden = false,
}: ApplicantCardAvatarProps) {
  const { selectedApplicantIds, selectedConversationIds, selectedColumnId, updateColumnSelection } =
    useApplicationsContext()

  const handleSelectApplicant = (applicantId: string) => {
    let newChosenIds
    let newColumnChosenConversations
    if (selectedApplicantIds && selectedApplicantIds.includes(applicantId)) {
      newChosenIds = selectedApplicantIds.filter((id) => id !== applicantId)
      newColumnChosenConversations = selectedConversationIds.filter((id) => id !== conversationId)
    } else {
      if (selectedColumnId !== currentColumnId) {
        newChosenIds = [applicantId]
        newColumnChosenConversations = [conversationId]
      } else {
        newChosenIds = [...selectedApplicantIds, applicantId]
        newColumnChosenConversations = [...selectedConversationIds, conversationId]
      }
    }
    updateColumnSelection({
      applicantIds: newChosenIds,
      conversationIds: newColumnChosenConversations,
      columnId: currentColumnId,
    })
  }

  return (
    <ImageWrapper>
      <AvatarImage src={avatarSrc} alt={avatarAlt} />
      {!isCheckboxHidden && (
        <CheckboxPlacement>
          <CheckBoxWrapper
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              handleSelectApplicant(id)
            }}
          >
            <Checkbox
              name={'RowCheck'}
              isChecked={selectedApplicantIds.includes(id) && selectedColumnId === currentColumnId}
            />
          </CheckBoxWrapper>
        </CheckboxPlacement>
      )}
    </ImageWrapper>
  )
}
