import { Stack, Heading } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import { EditDialog } from '@qasa/app/src/modules/edit-dialog'

import { ListingSectionEnum, useListingContext } from '../../context/listing-context'
import { useUserContext } from '../../context/user-context'

import { DurationInformation } from './duration-information'
import { RentalRequirements } from './rental-requirements'
import { BaseInfoItem } from './base-info-item'

const Wrapper = Stack
const DetailsWrapper = Stack
const LeftContainer = Stack
const RightContainer = Stack

export function RentalDetails() {
  const { t } = useTranslation(['listing', 'commons'])
  const { setActiveEditSection, setIsEditDialogOpen } = useListingContext()
  const { home } = useListingContext()
  const { matterportEmbedUrl, instantSignSetting } = home || {}
  const { authBody } = useUserContext()
  const isProAgent = authBody?.isProAgent

  const isInstantSignEnabled = Boolean(instantSignSetting?.enabled)
  const formattedInstantSign = isInstantSignEnabled
    ? t('instant_sign.enabled')
    : t('instant_sign.not_enabled')

  return (
    <Wrapper>
      <DetailsWrapper gap="6x" direction="row" justifyContent={'space-between'}>
        <LeftContainer gap="6x">
          <Heading size="md">{t('rental_details')}</Heading>
          {isProAgent && <BaseInfoItem heading={t('instant_sign.label')} value={formattedInstantSign} />}
          <DurationInformation />
          {isProAgent && (
            <BaseInfoItem
              heading={t('shortcut_details.matterport_label')}
              value={matterportEmbedUrl || t('commons:placeholders.not_filled_in')}
            />
          )}
          <RentalRequirements />
        </LeftContainer>
        <RightContainer gap="3x">
          <EditDialog.Trigger
            onClick={() => {
              setIsEditDialogOpen(true)
              setActiveEditSection(ListingSectionEnum.RENTAL_DETAILS)
            }}
          />
        </RightContainer>
      </DetailsWrapper>
    </Wrapper>
  )
}
