import userAvatar from '../../assets/icons/userAvatar.svg'
import interior from '../../assets/placeholders/ListingPlaceholder.jpg'

export const placeholder = {
  avatar: userAvatar,
  avatarAlt: 'avatar picture',
  interior,
  emptyString: '',
  zero: 0,
}
