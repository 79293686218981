import { useQuery } from '@apollo/client'
import { useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { Input } from '../../../ui-atoms/input'
import { Spacer } from '../../../ui-atoms/help-components'
import { queries } from '../../../data/application-board-lists'
import type { ApplicationBoardLists } from '../../../data/graphql/types/__generated__/ApplicationBoardLists'

import { BoardActions } from './board-actions'
import { ApplicationsColumn } from './applications-column'

const BoardContainer = styled.div({
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
})

const BoardWrapper = styled.div({
  width: '100%',
  display: 'flex',
  flexGrow: 1,
  overflowY: 'hidden',
  overflowX: 'auto',
})

const Container = styled.div({
  width: 'fit-content',
  height: '100%',
})

type ApplicationsBoardProps = {
  currentListing: string | undefined
}

const RowContainer = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})

export function ApplicationsBoard({ currentListing }: ApplicationsBoardProps) {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const [searchString, setSearchString] = useState<string>('')

  const { t } = useTranslation('applications', {
    keyPrefix: 'applications_board',
  })

  const { error, data, refetch } = useQuery<ApplicationBoardLists>(queries.GET_APPLICATION_BOARD_LISTS, {
    variables: {
      homeId: searchParams.get('listing') ? searchParams.get('listing') : currentListing,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  })

  const search = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value: searchString } = event.target
    setSearchString(searchString)
  }
  const applicationBoardCardInfo = data?.home?.applicationBoardCardInfo
  const dataForSorting = data && data.home ? data?.home?.applicationBoardLists : []
  const copyOfData = [...dataForSorting]
  const sortedData = copyOfData.sort((a, b) => {
    return a.order - b.order
  })

  if (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }

  if (!data) return null

  return (
    <>
      <RowContainer>
        <Input
          type="search"
          inputFocus="true"
          name="search"
          placeholder={t('search_placeholder')}
          width="300px"
          value={searchString}
          onChange={search}
        />
        <BoardActions applicationBoardCardInfo={applicationBoardCardInfo} refetch={refetch} />
      </RowContainer>

      <BoardWrapper>
        <Container>
          <Spacer factor={1} />
          <BoardContainer>
            {sortedData?.map((list) => (
              <ApplicationsColumn
                key={list.id}
                data={list}
                columns={data}
                applicationBoardCardInfo={applicationBoardCardInfo}
                refetchColumns={refetch}
                searchString={searchString}
              />
            ))}
          </BoardContainer>
        </Container>
      </BoardWrapper>
    </>
  )
}
