import { forwardRef } from 'react'
import styled from '@emotion/styled'

import { ImageWrapper, Image, RemoveImageButton } from '../../ui-atoms/object-image'

const StyledWrapper = styled(ImageWrapper)(({ theme }) => ({
  zIndex: theme.zIndices.popover,
  cursor: 'pointer',
}))

type HomeImageProps = {
  src: string
  handleRemove: () => void
}

export const HomeImage = forwardRef<HTMLDivElement, HomeImageProps>(({ src, handleRemove }, ref) => {
  return (
    <StyledWrapper ref={ref} alignItems="center" justifyContent="center">
      <Image src={src} />
      <RemoveImageButton onRemoveImage={handleRemove} />
    </StyledWrapper>
  )
})
