import { Stack } from '@qasa/qds-ui'

import { NavigationItem } from './navigation-item'
import type { NavigationOption } from './navigation-item'

export enum ListingsStatusFilters {
  ALL = 'ALL',
  PUBLISHED = 'ACTIVE',
  PASSED_DEADLINE = 'PASSED_DEADLINE',
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT',
}

type Props = {
  listingsCount?: string | number
  options: NavigationOption[]
  listingStatus?: ListingsStatusFilters
  onChangeListingStatus: (newStatus: ListingsStatusFilters) => void
}
export function ListingsStatusNavigation({
  listingsCount,
  options,
  listingStatus,
  onChangeListingStatus,
}: Props) {
  return (
    <Stack direction="row" gap="4x">
      {options.map(({ label, value }, index) => {
        return (
          <Stack key={index} direction="row" gap="4x">
            <NavigationItem
              label={label}
              isActive={value === listingStatus}
              onSelect={() => onChangeListingStatus(value)}
              listingsCount={listingsCount}
            />
          </Stack>
        )
      })}
    </Stack>
  )
}
