import { Fragment } from 'react'
import type { ReactNode } from 'react'
import { Spacer, CheckIcon } from '@qasa/qds-ui'

import { ActionContainer, ActionName } from '../ui-feature-modules/applications/board/column-menu'
import type {
  HomeApplicationOrderEnum,
  HomeSearchOrderEnum,
} from '../data/graphql/types/__generated__/globalTypes'

type MenuPickerProps = {
  value?: string
  values: Array<PickerElement>
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onChoice?: (id?: string, homeOrder?: HomeSearchOrderEnum) => void
  onSort?: (orderValue: HomeApplicationOrderEnum) => void
  onOrder?: (orderValue: HomeSearchOrderEnum) => void
}
type PickerElement = {
  id: string
  value: ReactNode
  isActive: boolean
  orderValue?: HomeApplicationOrderEnum
  order?: HomeSearchOrderEnum
}
export function MenuPicker(props: MenuPickerProps) {
  const { values, onChoice, onSort, onOrder } = props

  return (
    <div>
      {values.map(({ id, orderValue, order, value, isActive }) => (
        <Fragment key={id}>
          <ActionContainer
            direction="row"
            alignItems="center"
            gap="2x"
            onClick={() => {
              onChoice
                ? onChoice(id)
                : onSort && orderValue
                  ? onSort(orderValue)
                  : onOrder && order
                    ? onOrder(order)
                    : // eslint-disable-next-line no-console
                      console.log('')
            }}
          >
            <ActionName size="sm">{value}</ActionName>
            {isActive && <CheckIcon />}
          </ActionContainer>
          <Spacer size="3x" />
        </Fragment>
      ))}
    </div>
  )
}
