import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Heading, Paragraph, Stack } from '@qasa/qds-ui'
import styled from '@emotion/styled'

import { useContractContext } from '../../context/contract-context'
import { CONTRACT_PREVIEW } from '../../data/contract-preview'
import type {
  ContractPreviewQuery,
  ContractPreviewQueryVariables,
} from '../../data/graphql/types/__generated__/ContractPreviewQuery'
import {
  ContractStatusEnum,
  SignatoryRoleEnum,
  SigningProcessEnum,
} from '../../data/graphql/types/__generated__/globalTypes'
import { Loader } from '../../ui-atoms/loader'

import { StatusIndicator } from './contract-preview/status-indicator'
import { PdfReader } from './contract-preview/pdf-reader'
import { ContractPreviewActions } from './contract-preview/contract-preview-actions'

const TopSection = Stack
const InstantSignTag = styled(Paragraph)(({ theme }) => ({
  paddingBlock: theme.spacing['2x'],
  paddingInline: theme.spacing['3x'],
  borderRadius: theme.radii.full,
  backgroundColor: theme.colors.core.softYellow,
}))

export function ContractPreview() {
  const { contract } = useContractContext()

  const { data: contractPreview, loading: isLoading } = useQuery<
    ContractPreviewQuery,
    ContractPreviewQueryVariables
  >(CONTRACT_PREVIEW, {
    fetchPolicy: 'network-only',
    variables: {
      id: contract?.id ?? '',
    },
    skip: contract === undefined,
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.log(error)
    },
  })
  const { t } = useTranslation('applicant', {
    keyPrefix: 'contract_preview',
  })

  if (isLoading) {
    return <Loader />
  }

  if (!contract) {
    return null
  }

  const contractPreviewValidationError = contractPreview?.generateContractPreviewPdf.errors
  const contractPreviewSource = contractPreview?.generateContractPreviewPdf.previewSource

  if (contractPreviewValidationError) {
    // eslint-disable-next-line no-console
    console.log(contractPreviewValidationError)
    return null
  }

  const isContractInDraft = contract.status === ContractStatusEnum.draft
  const { hasLandlordSigned, hasTenantSigned } = contract.signatories.reduce(
    (previousValue, { role, signedAt }) => {
      const key = role === SignatoryRoleEnum.landlord ? 'hasLandlordSigned' : 'hasTenantSigned'
      return { ...previousValue, [key]: Boolean(signedAt) }
    },
    { hasLandlordSigned: false, hasTenantSigned: false },
  )
  const isInstantSign = contract.signingProcess === SigningProcessEnum.instant

  return (
    <Stack gap="2x">
      <TopSection direction="row" justifyContent="space-between">
        <Stack direction="row" gap="2x" alignItems="flex-start">
          <Heading as="h4">{isContractInDraft ? t('draft_lease') : t('lease')}</Heading>
          {isInstantSign && <InstantSignTag size="xs">{t('instant_sign')}</InstantSignTag>}
        </Stack>
        <div>
          {isContractInDraft ? (
            <Stack gap="2x">
              <Paragraph size="sm">{t('not_sent')}</Paragraph>
              <Paragraph size="sm">{t('not_visible_for_tenant')}</Paragraph>
            </Stack>
          ) : (
            <>
              <StatusIndicator landlord hasSigned={hasLandlordSigned} />
              <StatusIndicator hasSigned={hasTenantSigned} />
            </>
          )}
        </div>
      </TopSection>
      <ContractPreviewActions />
      {contractPreviewSource && <PdfReader pdf={contractPreviewSource} />}
    </Stack>
  )
}
