import { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { getPixelAmount } from '../../styling/pixel-amount'
import { Button } from '../../ui-shared/_core/links-buttons'
import type { DualSliderChangeEvent } from '../../ui-atoms/dual-slider'
import { DualSlider } from '../../ui-atoms/dual-slider'
import { HeaderText } from '../../ui-atoms/header-text'
import { HorizontalDivider, Spacer } from '../../ui-atoms/help-components'
import { Reset } from '../filters/reset'
import { ButtonsContainer } from '../filters/buttons-container'

export type SizeFilterType = {
  minSquareMeters?: number | null
  maxSquareMeters?: number | null
}

type SizeFilterProps = {
  onChange: (filter: SizeFilterType) => void
  totalCount?: number
  filter: SizeFilterType
  range: { min: number; max: number }
  close: () => void
  reset: () => void
  isLoading?: boolean
}

const Wrapper = styled.div({
  width: '400px',
  display: 'flex',
  flexDirection: 'column',
  padding: `${getPixelAmount(4)} ${getPixelAmount(3)} ${getPixelAmount(3)}`,
})

export function SizeFilter(props: SizeFilterProps) {
  const [filter, setFilters] = useState<SizeFilterType>({
    minSquareMeters: undefined,
    maxSquareMeters: undefined,
  })
  const { t } = useTranslation(['my_homes', 'commons'])

  useEffect(() => {
    if (
      props.filter.minSquareMeters !== filter.minSquareMeters ||
      props.filter.maxSquareMeters !== filter.maxSquareMeters
    ) {
      setFilters({
        maxSquareMeters: props.filter.maxSquareMeters,
        minSquareMeters: props.filter.minSquareMeters,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filter.maxSquareMeters, props.filter.minSquareMeters])

  const onDrag = (event: DualSliderChangeEvent) => {
    const newFilter: SizeFilterType = {
      ...filter,
      [event.name]: event.isMax || event.isMin ? undefined : event.value,
    }
    setFilters(newFilter)
  }

  const onChoice = (event: DualSliderChangeEvent) => {
    const newFilter: SizeFilterType = {
      ...filter,
      [event.name]: event.isMax || event.isMin ? undefined : event.value,
    }
    props.onChange(newFilter)
  }

  const onInputChange = (inputValue: string | number | null | undefined, type: 'min' | 'max') => {
    const value = inputValue === '' ? 0 : Number(inputValue)
    let newFilter: SizeFilterType
    if (type === 'min') {
      if (value <= props.range.max && value >= props.range.min) {
        const maxMinValue =
          props.filter.maxSquareMeters !== undefined && props.filter.maxSquareMeters !== null
            ? props.filter.maxSquareMeters
            : props.range.max
        if (!(value > maxMinValue)) {
          const newFilter: SizeFilterType = { ...filter, minSquareMeters: value }
          props.onChange(newFilter)
        }
      }
    }

    if (type === 'max') {
      if (value <= props.range.max && value >= props.range.min) {
        const minMaxValue =
          props.filter.minSquareMeters !== undefined && props.filter.minSquareMeters !== null
            ? props.filter.minSquareMeters
            : props.range.min
        if (!(value < minMaxValue)) {
          newFilter = { ...filter, maxSquareMeters: value }
        } else {
          newFilter = { minSquareMeters: value, maxSquareMeters: value }
        }
        props.onChange(newFilter)
      }
    }
  }

  //Defaults
  const { min = 0, max = 120 } = props.range
  return (
    <Wrapper>
      <HeaderText>{t('filters.size_filter_title', { unit: t('commons:square_meter') })}</HeaderText>
      <Spacer factor={1} />
      <HorizontalDivider />
      <Spacer factor={1} />
      <DualSlider
        minName="minSquareMeters"
        maxName="maxSquareMeters"
        range={{ min, max }}
        value={{
          min: props.filter.minSquareMeters,
          max: props.filter.maxSquareMeters,
        }}
        allowBelowMin={false}
        allowOverMax={false}
        onChange={onDrag}
        onDragStop={onChoice}
        onInputChange={onInputChange}
      />
      <Spacer factor={2} />
      <HorizontalDivider />
      <Spacer factor={2} />
      <ButtonsContainer>
        <Reset onClick={props.reset}>{t('commons:ctas.reset')}</Reset>
        <Button variant="important" onClick={props.close}>
          {props.isLoading
            ? t('filters.loading_amount_label')
            : t('filters.view_apartment', {
                count: props.totalCount,
              })}
        </Button>
      </ButtonsContainer>
    </Wrapper>
  )
}
