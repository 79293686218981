import { useQuery } from '@apollo/client'
import { useRef, useState } from 'react'
import debounce from 'lodash/debounce'
import styled from '@emotion/styled'
import { Paragraph, Stack, IconButton, ChevronDownIcon, LoadingDots } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import {
  HomeSearchOrderByEnum,
  HomeSearchOrderEnum,
} from '../../../data/graphql/types/__generated__/globalTypes'
import { Input } from '../../../ui-atoms/input'
import { queries } from '../../../data/my-homes'
import type {
  ListingsQuery,
  ListingsQueryVariables,
} from '../../../data/graphql/types/__generated__/ListingsQuery'
import { ColumnMenu } from '../board/column-menu'
import type { NavigationOption } from '../../listings/navigation-item'
import { ListingsStatusFilters, ListingsStatusNavigation } from '../../listings/listings-status-navigation'
import { WhiteBoxWrapper } from '../../../ui-shared/wrapper'
import { getListingsStatusVariables } from '../../listings/get-listings-data.utils'
import { useFetchMoreOnScrollToBottom } from '../../../hooks/use-fetch-more-on-scroll-to-bottom'

import { ListingCard } from './listing-card'
import { ListingsSorting } from './listings-sorting'

const DEFAULT_START_COUNT = 50

const Wrapper = styled(Stack)(({ theme }) => ({
  minWidth: theme.sizes[320],
  width: theme.sizes[320],
  overflow: 'hidden',
  flexGrow: 1,
}))

const LoadingDataWrapper = styled(Stack)({
  flexGrow: 1,
})

const ListingsList = styled(Stack)({
  gap: 4,
  overflowY: 'auto',
})

type ListingBarProps = {
  currentListing: string | undefined
  setCurrentListing: (id?: string | undefined) => void
}

export function ListingsBar({ currentListing, setCurrentListing }: ListingBarProps) {
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const listingStatusParam = searchParams.get('listingStatus')
  const [listingStatus, setListingStatus] = useState<ListingsStatusFilters>(
    (listingStatusParam as ListingsStatusFilters) ?? ListingsStatusFilters.PUBLISHED,
  )
  const [localArgs, setLocalArgs] = useState<ListingsQueryVariables>({
    homesAfter: null,
    homesFirst: DEFAULT_START_COUNT,
    homesOrder: HomeSearchOrderEnum.DESCENDING,
    homesOrderBy: HomeSearchOrderByEnum.PUBLISHED_AT,
    homesSearchString: '',
    maxRoomCount: null,
    maxSquareMeters: null,
    minRoomCount: null,
    minSquareMeters: null,
    ...getListingsStatusVariables(listingStatus),
  })

  const { t } = useTranslation(['applications', 'commons'])

  const {
    loading: isLoading,
    error,
    data,
    refetch,
    fetchMore,
  } = useQuery<ListingsQuery, ListingsQueryVariables>(queries.GET_LISTINGS, {
    variables: localArgs,
    fetchPolicy: 'network-only',
    // eslint-disable-next-line no-console
    onError: (e) => console.log(e),
  })
  const [isLoadingSomeData, setLoadingSomeData] = useState<boolean>(false)
  const { handleScroll, isFetchingMore } = useFetchMoreOnScrollToBottom({
    handleFetchMore: () =>
      fetchMore({
        variables: {
          homesAfter: data?.homes.pageInfo.endCursor,
          homesFirst: DEFAULT_START_COUNT,
        },
      }),
    shouldFetchMore: data?.homes.pageInfo.hasNextPage,
  })
  const delaySearchRef = useRef(
    debounce((searchString) => {
      refetchListings({
        homesSearchString: searchString,
      })
    }, 250),
  )

  const onSearch = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value: homesSearchString } = event.target
    setLocalArgs((prev) => ({ ...prev, homesSearchString }))
    delaySearchRef.current(homesSearchString)
  }

  const listingCount = isLoading || isLoadingSomeData ? t('commons:loading') : data?.homes.totalCount

  const refetchListings = async (newVariables: ListingsQueryVariables) => {
    setLocalArgs((prev) => ({ ...prev, ...newVariables }))
    setLoadingSomeData(true)
    await refetch({
      ...newVariables,
      homesAfter: null,
      homesFirst: DEFAULT_START_COUNT,
    })
    setLoadingSomeData(false)
    return
  }

  if (error) {
    // eslint-disable-next-line no-console
    console.log(error.message)
  }

  const handleOrderBy = (newOrderBy: HomeSearchOrderEnum) => {
    refetchListings({
      homesOrderBy: HomeSearchOrderByEnum.PUBLISHED_AT,
      homesOrder: newOrderBy,
    })
  }

  const handleChangeListingStatus = (newStatus: ListingsStatusFilters) => {
    setListingStatus(newStatus)
    refetchListings({
      ...getListingsStatusVariables(newStatus),
    })
  }

  const listingsNavigationOptions: NavigationOption[] = [
    {
      label: t('listings.listings_bar.active'),
      value: ListingsStatusFilters.PUBLISHED,
    },
    {
      label: t('listings.listings_bar.expired'),
      value: ListingsStatusFilters.PASSED_DEADLINE,
    },
    {
      label: t('listings.listings_bar.archived'),
      value: ListingsStatusFilters.ARCHIVED,
    },
  ]

  return (
    <WhiteBoxWrapper>
      <Wrapper gap="6x">
        <ListingsStatusNavigation
          options={listingsNavigationOptions}
          listingStatus={listingStatus}
          onChangeListingStatus={handleChangeListingStatus}
        />
        <Input
          type="search"
          name="search"
          placeholder={t('listings.listings_bar.search_placeholder')}
          width="100%"
          value={localArgs.homesSearchString}
          onChange={onSearch}
        />
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Paragraph size="sm">{t('listings.listings_bar.num_of_apartments', { listingCount })}</Paragraph>
          <ColumnMenu
            Icon={<IconButton icon={ChevronDownIcon} label="Column Requirements" size="xs" />}
            label={
              localArgs.homesOrder === HomeSearchOrderEnum.DESCENDING
                ? t('listings.listings_bar.last_published')
                : t('listings.listings_bar.oldest')
            }
            width={'180px'}
          >
            <ListingsSorting
              order={localArgs.homesOrder}
              active={localArgs.homesOrderBy === HomeSearchOrderByEnum.PUBLISHED_AT}
              handleOrderBy={handleOrderBy}
            />
          </ColumnMenu>
        </Stack>
        {isLoading || isLoadingSomeData ? (
          <LoadingDataWrapper alignItems="center" justifyContent="center">
            <LoadingDots />
          </LoadingDataWrapper>
        ) : (
          <ListingsList onScroll={handleScroll} alignItems="center">
            {data?.homes.edges.map((value) => (
              <ListingCard
                key={value.node.id}
                data={value.node}
                currentListing={currentListing}
                setCurrentListing={setCurrentListing}
              />
            ))}
            {isFetchingMore && <LoadingDots />}
          </ListingsList>
        )}
      </Wrapper>
    </WhiteBoxWrapper>
  )
}
