import styled from '@emotion/styled'
import { Heading, Paragraph, Stack, createIcon } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

const HomeWithHeartIcon = createIcon({
  displayName: 'HomeWithHeartIcon',
  viewBox: '0 0 57 56',
  d: 'M53.3694 31.0724C55.0222 31.0724 56.4806 29.7113 56.4806 27.9516C56.5778 27.0766 56.1889 26.2988 55.4111 25.6183L30.5903 3.79188C30.0069 3.30577 29.2292 3.11133 28.5486 3.11133C27.8681 3.11133 27.0903 3.20855 26.4097 3.88911L1.47222 25.6183C0.791667 26.2988 0.5 27.0766 0.5 27.9516C0.5 29.7016 1.86111 31.0724 3.61111 31.0724H6.72222V46.6669C6.72222 50.0988 9.5125 52.8891 12.9444 52.8891H44.1042C47.5556 52.8891 50.3458 50.0891 50.3264 46.6377L50.2583 31.0627H53.3694V31.0724ZM17.6111 29.2933C17.6111 26.0072 20.275 23.3336 23.5708 23.3336C25.1458 23.3336 26.6625 23.9655 27.7806 25.0738L28.5 25.7933L29.2194 25.0738C30.3375 23.9558 31.8542 23.3336 33.4292 23.3336C36.7153 23.3336 39.3889 25.9974 39.3889 29.2933C39.3889 30.8683 38.7569 32.3849 37.6486 33.503L29.6083 41.5433C29.0056 42.146 28.0139 42.146 27.4111 41.5433L19.3708 33.503C18.2528 32.3849 17.6306 30.8683 17.6306 29.2933H17.6111Z',
})

const ExtraLargeHomeIcon = styled(HomeWithHeartIcon)(({ theme }) => ({
  width: theme.spacing['16x'],
  height: theme.spacing['16x'],
  opacity: 0.2,
}))
const Wrapper = styled(Stack)({
  width: '100%',
  height: '100%',
})

export function NoListingSelected() {
  const { t } = useTranslation('applications', { keyPrefix: 'no_listing_selected' })
  return (
    <Wrapper alignItems="center" justifyContent="center" gap="6x">
      <ExtraLargeHomeIcon />
      <Stack gap="2x" alignItems="center">
        <Heading size="sm">{t('heading')}</Heading>
        <Paragraph>{t('description')}</Paragraph>
      </Stack>
    </Wrapper>
  )
}
