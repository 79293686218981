import type { OfferingStatusEnum } from '../data/graphql/types/__generated__/globalTypes'
import { ConversationMessageTypeEnum } from '../data/graphql/types/__generated__/globalTypes'

export const getSystemMessageTranslationPrefix = (
  messageType: ConversationMessageTypeEnum,
  applicationOriginKey: string,
  offeringStatus?: OfferingStatusEnum,
) => {
  switch (messageType) {
    case ConversationMessageTypeEnum.home_archived:
    case ConversationMessageTypeEnum.home_application_in_contact:
    case ConversationMessageTypeEnum.contract_draft_created:
    case ConversationMessageTypeEnum.contract_landlord_signed:
    case ConversationMessageTypeEnum.contract_tenant_signed:
    case ConversationMessageTypeEnum.conversation_closed:
    case ConversationMessageTypeEnum.conversation_reopened:
    case ConversationMessageTypeEnum.home_rented_out:
    case ConversationMessageTypeEnum.tenant_wants_contract:
    case ConversationMessageTypeEnum.shortcut_new_home_application:
    case ConversationMessageTypeEnum.shortcut_completed_old_conversation:
    case ConversationMessageTypeEnum.shortcut_canceled_old_conversation:
      return messageType
    case ConversationMessageTypeEnum.home_application_declined:
    case ConversationMessageTypeEnum.home_application_revoked:
      return `${messageType}_${applicationOriginKey}`
    case ConversationMessageTypeEnum.offering_received:
      return offeringStatus ? `${messageType}_${offeringStatus}` : null
    default:
      return null
  }
}
