import { useTranslation } from 'react-i18next'
import { PillButton } from '@qasa/app/src/components/pill-button'
import { Paragraph } from '@qasa/qds-ui'

import { TraitTypeEnum } from '../../data/graphql/types/__generated__/globalTypes'
import type { UpdateHomeInput } from '../../data/graphql/types/__generated__/globalTypes'
import { Spacer } from '../../ui-atoms/help-components'
import { SmallHeader } from '../../ui-atoms/small-header'
import { getTraits } from '../../util-functions/traits.util'
import { CheckboxLine } from '../edit-listing/amenities-section'

type Props = {
  form: UpdateHomeInput
  onTraitChange: (trait: TraitTypeEnum) => void
}

// TODO: note that this is a duplicate of the old edit-listing-form furnished-section - it should be removed when we
export function FurnishedSection({ form, onTraitChange }: Props) {
  const { t } = useTranslation('amenities', { keyPrefix: 'categories' })

  return (
    <>
      <SmallHeader>{t('furnished')}</SmallHeader>
      <Spacer factor={2} />
      <CheckboxLine direction="row" gap={'2x'}>
        {Object.values(TraitTypeEnum)
          .filter((traitVal) => {
            const traits = [TraitTypeEnum.furniture]
            return traits.includes(traitVal)
          })
          .map((value) => (
            <PillButton
              key={value}
              size="small"
              type="button"
              onPress={() => onTraitChange(value)}
              isActive={form.traits!.includes(value)}
            >
              <Paragraph size="sm">{getTraits(value)}</Paragraph>
            </PillButton>
          ))}
      </CheckboxLine>
    </>
  )
}
