import styled from 'styled-components/macro'

import { getPixelAmount } from '../styling/pixel-amount'
import topbarImage from '../assets/top-bar-image.svg'

const Wrapper = styled.div({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  height: getPixelAmount(1),
  width: '100%',
  backgroundImage: `url(${topbarImage})`,
  backgroundRepeat: 'repeat-x',
  backgroundSize: 'auto',
})

export function TopBar() {
  return <Wrapper />
}
