import styled from 'styled-components/macro'

import { fontSize } from '../styling'
import { getPixelAmount } from '../styling/pixel-amount'

export const SmallData = styled.p<{ transparent?: boolean; smaller?: boolean }>(
  ({ transparent, smaller }) => ({
    fontSize: fontSize.textBase,
    fontWeight: 300,
    minHeight: getPixelAmount(2),
    maxWidth: 500,
    wordBreak: 'break-word',
    opacity: transparent ? 0.3 : 1,
    hyphens: 'auto',
    lineHeight: fontSize.text2xl,
    ...(smaller && {
      fontSize: fontSize.textSm,
    }),
  }),
)
