import { useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Button, Heading, Spacer, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { mutations } from '../data/add-building'
import { OwnerTypeEnum, UploadTypeEnum } from '../data/graphql/types/__generated__/globalTypes'
import { useUploadImage } from '../hooks/use-upload-image'
import { defaultStyles } from '../styling/defaults'
import { TopSection } from '../ui-atoms/top-section'
import type {
  UpsertBuildingMutation,
  UpsertBuildingMutation_upsertBuilding,
} from '../data/graphql/types/__generated__/UpsertBuildingMutation'
import { fontSize, palette } from '../styling'
import { getPixelAmount } from '../styling/pixel-amount'
import type { DestroyUploadMutation } from '../data/graphql/types/__generated__/DestroyUploadMutation'
import { DESTROY_UPLOAD } from '../data/destroy-upload'
import type { BuildingForEditQuery_building_uploads } from '../data/graphql/types/__generated__/BuildingForEditQuery'
import type { GoogleLocation } from '../util-functions/google-places.util'
import { WhiteBoxWrapper } from '../ui-shared/wrapper'
import { LoadingDots } from '../ui-shared/loading-dots'

import { HorizontalDivider } from './help-components'
import { Input } from './input'
import { InputHandler } from './input-handler'
import { Map } from './map'
import { ImageUploadHandler } from './image-upload-handler'
import { SmallHeader } from './small-header'
import { ImagesContainer, ObjectImage, RemoveImageButton } from './object-image'

type BuildingFormProps = {
  buildingId?: string
  building?: BuildingInput
  title: string
  uploads?: BuildingForEditQuery_building_uploads[]
  cancel?: () => void
}

export type BuildingInput = {
  buildingName?: string | null
  descriptionBuilding?: string | null
  descriptionTransportation?: string | null
  buildYear?: number | null
  energyClass?: string | null
  location?: {
    postalCode?: string | null
    route?: string | null
    googleMapsPlaceId?: string | null
    longitude?: number | null
    latitude?: number | null
    streetNumber?: string | null
    locality?: string | null
    countryCode?: string | null
    country?: string | null
  }
}

const Wrapper = styled.div({
  height: '100%',
  display: 'flex',
})

const Content = styled(Stack)({
  maxWidth: '1280px',
  width: '100%',
})

const ButtonsContainer = Stack

const InputGroup = styled.div({
  display: 'grid',
  gridTemplateRows: '1fr 1fr',
  gridTemplateColumns: '2fr 1fr',
  gridGap: getPixelAmount(1),
  width: '400px',
})

const LngLatDisplay = styled.p({
  fontSize: fontSize.textXs,
  borderRadius: defaultStyles.borderRadius,
  backgroundColor: palette.grayAlpha20,
  width: 'fit-content',
  padding: getPixelAmount(0.5),
  margin: `${getPixelAmount(0.5)} 0`,
  label: {
    display: 'inline-block',
    width: '60px',
  },
})

export function BuildingForm(props: BuildingFormProps) {
  const navigate = useNavigate()
  const { t } = useTranslation(['building', 'commons'])

  const [isSavedEnabled, enableSave] = useState(false)
  const [buildingLatLng, setBuildingLatLng] = useState<{
    longitude: number
    latitude: number
    dragable: boolean
  }>()

  const [form, setForm] = useState<BuildingInput>({
    buildingName: '',
    descriptionBuilding: '',
    descriptionTransportation: '',
    buildYear: null,
    energyClass: '',
    location: {
      postalCode: '',
      route: '',
      googleMapsPlaceId: '',
      longitude: 0,
      latitude: 0,
      streetNumber: '',
      locality: '',
      countryCode: '',
      country: '',
    },
  })

  const [buildingImages, setBuildingImages] = useState<Array<File>>([])

  const [imagesToDestroy, setImagesToDestroy] = useState<BuildingForEditQuery_building_uploads[]>([])

  const [destroyUpload] = useMutation<DestroyUploadMutation>(DESTROY_UPLOAD)

  const [upsertBuilding] = useMutation<UpsertBuildingMutation>(mutations.ADD_BUILDING, {
    onCompleted: ({ upsertBuilding }) => onUpsertBuildingCompleted(upsertBuilding!),
    onError: (e) => {
      // eslint-disable-next-line no-console
      console.error(e)
    },
  })

  const [uploadBuildingImage, { isLoading }] = useUploadImage()

  const onUpsertBuildingCompleted = async ({ building }: UpsertBuildingMutation_upsertBuilding) => {
    if (building) {
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      const promises: Promise<any>[] = []
      if (buildingImages.length > 0) {
        buildingImages.forEach((image) => {
          promises.push(
            uploadBuildingImage(image, {
              ownerId: building.id,
              ownerType: OwnerTypeEnum.BUILDING,
              uploadType: UploadTypeEnum.home_building_picture,
            }),
          )
        })
      }
      if (imagesToDestroy.length > 0) {
        imagesToDestroy.forEach((image) => {
          promises.push(destroyImage(image.id))
        })
      }
      Promise.all(promises).then(() => {
        navigate(`/homes/buildings/${building.id}`, { replace: true })
      })
    }
  }

  useEffect(() => {
    if (props.building) {
      setForm(props.building)
      if (props.building.location) {
        const { longitude, latitude } = props.building.location
        if (longitude && latitude) {
          setBuildingLatLng({ longitude, latitude, dragable: true })
        }
      }
    }
  }, [props.building])

  const onSave = () => {
    const formData: BuildingInput = form

    upsertBuilding({
      variables: {
        upsertBuildingInput: formData,
        ...(props.buildingId ? { upsertBuildingId: props.buildingId } : {}),
      },
    })
  }

  const destroyImage = async (uploadId: string) => {
    return await destroyUpload({ variables: { uploadId } })
  }

  const onInputChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    let value
    if (event.target.value !== '' && event.target.type === 'number') {
      value = Number(event.target.value)
    } else {
      value = event.target.value
    }

    setForm({
      ...form,
      [event.target.name]: value,
    })
  }

  const onLocationInputChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setForm({
      ...form,
      location: {
        ...form.location,
        [event.target.name]: event.target.value,
      },
    })
  }

  const setValid = (isValid: boolean) => {
    enableSave(isValid)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onLocationChange = (location: GoogleLocation) => {
    setBuildingLatLng({
      longitude: location.longitude,
      latitude: location.latitude,
      dragable: true,
    })

    setForm({
      ...form,
      location,
    })
  }

  const setLatLng = (latLng: { longitude: number; latitude: number }) => {
    const { longitude, latitude } = latLng

    setBuildingLatLng({
      longitude,
      latitude,
      dragable: true,
    })

    setForm({
      ...form,
      location: {
        ...form.location,
        longitude,
        latitude,
      },
    })
  }

  if (isLoading) {
    return <LoadingDots />
  }

  return (
    <Wrapper>
      <Content gap="6x">
        <TopSection>
          <Heading as="h1" size="md">
            {props.title}
          </Heading>
          <ButtonsContainer direction={'row'} gap={'3x'}>
            <Button onClick={onSave} variant="secondary" disabled={!isSavedEnabled}>
              {t('commons:ctas.save')}
            </Button>
            <Button
              onClick={() => (props.cancel ? props.cancel() : navigate('/homes', { replace: true }))}
              variant="tertiary"
            >
              {t('commons:ctas.cancel')}
            </Button>
          </ButtonsContainer>
        </TopSection>
        <WhiteBoxWrapper>
          <InputHandler setValid={setValid}>
            <SmallHeader>{t('building_form.building_name')}</SmallHeader>
            <Spacer size="4x" />
            <Input
              width="400px"
              name="buildingName"
              value={form.buildingName}
              type="text"
              placeholder={t('building_form.building_name_placeholder')}
              onChange={onInputChange}
            />

            <Spacer size="8x" />

            <SmallHeader>{t('building_form.building_address')}</SmallHeader>

            <Spacer size="4x" />

            <InputGroup>
              <Input
                name="route"
                type="location"
                value={form.location?.route}
                placeholder={t('building_form.building_address_street')}
                onChange={onLocationInputChange}
                onLocationChange={onLocationChange}
              />
              <Input
                name="streetNumber"
                type="text"
                value={form.location?.streetNumber}
                placeholder={t('building_form.building_address_street_number')}
                onChange={onLocationInputChange}
              />
              <Input
                name="locality"
                type="text"
                value={form.location?.locality}
                placeholder={t('building_form.building_address_locality')}
                onChange={onLocationInputChange}
              />
              <Input
                name="postalCode"
                type="text"
                value={form.location?.postalCode}
                placeholder={t('building_form.building_address_post_number')}
                onChange={onLocationInputChange}
              />
            </InputGroup>

            <Spacer size="4x" />

            <Map
              width="400px"
              markers={buildingLatLng ? [buildingLatLng] : undefined}
              onLatLngChange={setLatLng}
            />
            <LngLatDisplay>{t('commons:latitude', { latitude: form.location?.latitude })}</LngLatDisplay>
            <LngLatDisplay>{t('commons:longitude', { longitude: form.location?.longitude })}</LngLatDisplay>

            <Spacer size="8x" />

            <HorizontalDivider />

            <Spacer size="8x" />

            <SmallHeader>{t('building_form.building_description')}</SmallHeader>

            <Spacer size="4x" />
            <Input
              name="descriptionBuilding"
              type="text"
              value={form.descriptionBuilding}
              placeholder={t('building_form.building_description_placeholder')}
              width="400px"
              onChange={onInputChange}
              optional
              multiline
            />

            <Spacer size="8x" />

            <SmallHeader>{t('building_form.transportation')}</SmallHeader>

            <Spacer size="4x" />

            <Input
              name="descriptionTransportation"
              type="text"
              value={form.descriptionTransportation}
              placeholder={t('building_form.transportation_placeholder')}
              width="400px"
              onChange={onInputChange}
              optional
              multiline
            />

            <Spacer size="8x" />

            <SmallHeader>{t('building_form.construction_year')}</SmallHeader>

            <Spacer size="4x" />

            <Input
              name="buildYear"
              type="number"
              min={0}
              value={form.buildYear}
              placeholder={t('building_form.construction_year_placeholder')}
              width="400px"
              onChange={onInputChange}
              optional
            />

            <Spacer size="8x" />

            <SmallHeader>{t('building_form.energy_class')}</SmallHeader>

            <Spacer size="4x" />

            <Input
              name="energyClass"
              type="text"
              value={form.energyClass}
              placeholder={t('building_form.energy_class_placeholder')}
              width="400px"
              onChange={onInputChange}
              optional
            />

            <Spacer size="8x" />

            <HorizontalDivider />

            <Spacer size="8x" />

            <SmallHeader>{t('building_form.images')}</SmallHeader>
            <Spacer size="4x" />
            <ImagesContainer>
              {props.uploads &&
                props.uploads.map(
                  (upload) =>
                    !imagesToDestroy.includes(upload) && (
                      <ObjectImage key={upload.url} src={upload.url}>
                        <RemoveImageButton
                          onRemoveImage={() => setImagesToDestroy([...imagesToDestroy, upload])}
                        />
                      </ObjectImage>
                    ),
                )}
              {buildingImages.map((file) => (
                <ObjectImage key={file.size} src={URL.createObjectURL(file)}>
                  <RemoveImageButton
                    onRemoveImage={() => setBuildingImages(buildingImages.filter((image) => image !== file))}
                  />
                </ObjectImage>
              ))}
              <ImageUploadHandler
                onAdd={(files) => setBuildingImages([...buildingImages, ...files])}
                onDelete={(imageToRemove) =>
                  setBuildingImages(buildingImages.filter((image) => image !== imageToRemove))
                }
              />
            </ImagesContainer>
          </InputHandler>
        </WhiteBoxWrapper>
      </Content>
    </Wrapper>
  )
}
