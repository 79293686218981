import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Heading, Paragraph, Stack } from '@qasa/qds-ui'

import { queries } from '../../../data/me'
import { ChatBlocketLogo } from '../../../ui-atoms/chat-blocket-logo'
import { placeholder } from '../../../styling'
import { getSystemMessageTranslationPrefix } from '../../../util-functions/system-messages.util'
import type { OfferingStatusEnum } from '../../../data/graphql/types/__generated__/globalTypes'
import type { MeInformationQuery } from '../../../data/graphql/types/__generated__/MeInformationQuery'
import type { HomeApplicationChatConversation_homeApplication_conversation_messages_edges_node } from '../../../data/graphql/types/__generated__/HomeApplicationChatConversation'
import { cleanupWord } from '../../../util-functions/string.util'
import { useApplicantContext } from '../../../context/applicant-context'

import { MessageWrapper } from './message-wrapper'

const SystemMessageWrapper = styled(Stack)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.colors.border.default,
  borderRadius: theme.radii.lg,
  padding: theme.spacing['6x'],
}))

type SystemMessageProps = {
  message: HomeApplicationChatConversation_homeApplication_conversation_messages_edges_node
  offeringStatus: OfferingStatusEnum | undefined
  isInitiator?: boolean
}

export function SystemMessage({ offeringStatus, message, isInitiator }: SystemMessageProps) {
  const { applicantData } = useApplicantContext()
  const { tenantEmail, tenantPhoneNumber, tenant } = applicantData || {}
  const tenantName = tenant?.firstName
  const { data } = useQuery<MeInformationQuery>(queries.GET_ME)

  const { t } = useTranslation(['applications', 'system_messages'])

  const companyName = data?.me?.companyName ? data?.me?.companyName : data?.me?.firstName
  const agentName = data?.me?.firstName
  const applicationOriginKey = isInitiator ? 'sender' : 'receiver'

  const translationPrefix = getSystemMessageTranslationPrefix(
    message.messageType,
    applicationOriginKey,
    offeringStatus,
  )
  const information = translationPrefix
    ? t(`system_messages:${translationPrefix}.information`, {
        tenantName: tenantName || placeholder.emptyString,
        defaultValue: placeholder.emptyString,
      })
    : ''
  const header = translationPrefix
    ? t(`system_messages:${translationPrefix}.header`, {
        tenantName: tenantName || placeholder.emptyString,
        landlordName: companyName || placeholder.emptyString,
        defaultValue: placeholder.emptyString,
      })
    : cleanupWord(message.messageType)
  const body = translationPrefix
    ? t(`system_messages:${translationPrefix}.body`, {
        phoneNumber: tenantPhoneNumber || t('chat.system_message.phone_number'),
        tenantName: tenantName || placeholder.emptyString,
        landlordName: companyName || placeholder.emptyString,
        emailAddress: tenantEmail
          ? t('chat.system_message.or_mail_to', { tenantEmail })
          : placeholder.emptyString,
        agentName: agentName || placeholder.emptyString,
        defaultValue: placeholder.emptyString,
      })
    : ''

  return (
    <MessageWrapper variant="received" date={new Date(message.createdAt)}>
      <SystemMessageWrapper gap="2x">
        <ChatBlocketLogo />
        <Paragraph size="sm" color="subtle">
          {information}
        </Paragraph>
        <Heading as="h5" size="2xs">
          {header}
        </Heading>
        <Paragraph size="sm">{body}</Paragraph>
      </SystemMessageWrapper>
    </MessageWrapper>
  )
}
