import { useQuery } from '@apollo/client'
import { Route, Routes } from 'react-router-dom'
import styled from '@emotion/styled'
import { useState } from 'react'
import { Heading, Spacer } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { TableTopSection } from '../ui-atoms/top-section'
import { SegmentControl, Segment } from '../ui-atoms/segment-control'
import { queries as homeQueries } from '../data/my-homes'
import { BuildingsTable } from '../ui-feature-modules/my-homes/buildings-table'
import { ApartmentsTable } from '../ui-feature-modules/my-homes/apartments-table/apartments-table'
import { WhiteBoxWrapper } from '../ui-shared/wrapper'

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  overflow: 'auto',
  maxWidth: '100vw',
  height: '100%',
})

const ColumnWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: 'fit-content',
  maxWidth: '100%',
})

const Content = styled(WhiteBoxWrapper)({
  overflow: 'hidden',
})

export function MyHomes() {
  const {
    loading: isLoading,
    error,
    data,
  } = useQuery(homeQueries.GET_HOME_COUNTS, {
    fetchPolicy: 'no-cache',
  })
  const { t } = useTranslation(['my_homes', 'commons'])

  const [apartmentsTotalCount, setApartmentsTotalCount] = useState<number | undefined>(undefined)

  // eslint-disable-next-line no-console
  if (error) console.log(error)

  const buildingCount = isLoading ? t('commons:loading') : data.buildings.totalCount
  const apartmentCount = isLoading
    ? t('commons:loading')
    : apartmentsTotalCount
      ? apartmentsTotalCount
      : data.homeTemplates.totalCount

  return (
    <Wrapper>
      <ColumnWrapper>
        <TableTopSection>
          <Heading as="h1" size="md">
            {t('title')}
          </Heading>
        </TableTopSection>
        <Spacer size="6x" />
        <Content gap={'6x'}>
          <TableTopSection>
            <SegmentControl>
              <Segment id="buildings" name={t('commons:building_other')} count={buildingCount} />
              <Segment id="apartments" name={t('commons:apartment_other')} count={apartmentCount} />
            </SegmentControl>
          </TableTopSection>
          <Routes>
            <Route path="/buildings/" element={<BuildingsTable />} />
            <Route
              path="/apartments/*"
              element={<ApartmentsTable setTotalCount={setApartmentsTotalCount} />}
            />
          </Routes>
        </Content>
      </ColumnWrapper>
    </Wrapper>
  )
}
