import { Divider, Heading, LoadingDots, Paragraph, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { useQuery } from '@apollo/client'

import { WhiteBoxWrapper } from '../../../ui-shared/wrapper'
import type {
  NewApplicationsQuery,
  NewApplicationsQueryVariables,
} from '../../../data/graphql/types/__generated__/NewApplicationsQuery'
import {
  HomeApplicationOrderEnum,
  HomeApplicationOriginEnum,
  HomeApplicationStatusEnum,
  HomeUserRoleEnum,
} from '../../../data/graphql/types/__generated__/globalTypes'
import { useFetchMoreOnScrollToBottom } from '../../../hooks/use-fetch-more-on-scroll-to-bottom'

import { NEW_APPLICATIONS } from './new-applications.gql'
import { NewApplicantCard } from './new-applicant-card'

const FETCH_AMOUNT = 20

const Wrapper = styled(Stack)(({ theme }) => ({
  flexGrow: 1,
  width: theme.sizes[384],
  overflowY: 'scroll',
  padding: `${theme.spacing['4x']} 0`,
}))

export function NewApplications() {
  const { t } = useTranslation('overview')

  const {
    data,
    error,
    loading: isLoading,
    fetchMore,
  } = useQuery<NewApplicationsQuery, NewApplicationsQueryVariables>(NEW_APPLICATIONS, {
    variables: {
      first: FETCH_AMOUNT,
      orderBy: HomeApplicationOrderEnum.NEWEST_FIRST,
      userRole: HomeUserRoleEnum.landlord,
      status: [HomeApplicationStatusEnum.pending],
      origin: [HomeApplicationOriginEnum.tenant_application],
    },
    fetchPolicy: 'cache-and-network',
  })
  const { handleScroll, isFetchingMore } = useFetchMoreOnScrollToBottom({
    handleFetchMore: () =>
      fetchMore({
        variables: {
          after: data?.homeApplications.pageInfo.endCursor,
          first: FETCH_AMOUNT,
        },
      }),
    shouldFetchMore: data?.homeApplications.pageInfo.hasNextPage,
  })

  if (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }

  if (isLoading) {
    return (
      <WhiteBoxWrapper>
        <Wrapper alignItems="center" justifyContent="center">
          <LoadingDots />
        </Wrapper>
      </WhiteBoxWrapper>
    )
  }

  const hasApplicants = Boolean(data?.homeApplications.edges.length)

  return (
    <WhiteBoxWrapper gap="6x">
      <Heading size="xs">{t('applications_header')}</Heading>
      {hasApplicants ? (
        <Wrapper gap="4x" divider={<Divider />} onScroll={handleScroll} alignItems="center">
          {data?.homeApplications.edges.map((application) => (
            <NewApplicantCard key={application.node.id} data={application.node} />
          ))}
          {isFetchingMore && <LoadingDots />}
        </Wrapper>
      ) : (
        <Wrapper alignItems="center" justifyContent="center">
          <Paragraph textAlign="center" color="subtle">
            {t('no_applications')}
          </Paragraph>
        </Wrapper>
      )}
    </WhiteBoxWrapper>
  )
}
