import styled from '@emotion/styled'
import { Paragraph } from '@qasa/qds-ui'

import type { ListingsStatusFilters } from './listings-status-navigation'

export type NavigationOption = {
  label: string
  value: ListingsStatusFilters
}

type NavigationItemProps = {
  label: string
  listingsCount?: string | number
  isActive: boolean
  onSelect: () => void
}

const Item = styled.div({
  cursor: 'pointer',
})

const Label = styled(Paragraph)<{ isActive: boolean }>(({ theme, isActive }) => ({
  height: 32,
  borderBottom: '3px solid',
  borderBottomColor: isActive ? theme.colors.border.defaultSelected : 'transparent',
  //TODO: Change to 700 after Qasa rebranding
  fontWeight: isActive ? 600 : 400,
  letterSpacing: isActive ? '0em' : '0.01em',
}))

export function NavigationItem({ label, listingsCount, isActive, onSelect }: NavigationItemProps) {
  return (
    <Item onClick={onSelect}>
      <Label color="default" isActive={isActive}>
        {label} {Boolean(listingsCount && isActive) && `(${listingsCount})`}
      </Label>
    </Item>
  )
}
