import { useEffect, useState } from 'react'

const GOOGLE_MAPS_API_URL = 'https://maps.googleapis.com/maps/api/js'
const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_ACCESS_TOKEN
const LANGUAGE = 'sv'

let googleMapsPlacesScript: HTMLScriptElement

export type Prediction = {
  description: string
  placeId: string
}

export type GoogleLocation = {
  country: string
  countryCode: string
  placeId: string
  latitude: number
  locality: string | null
  longitude: number
  postalCode: string | null
  route: string | null
  streetNumber: string | null
}

export const useGooglePlaces = () => {
  const [isLoading, setLoading] = useState(true)
  const [autoCompleteService, setAutoCompleteService] = useState<google.maps.places.AutocompleteService>()
  const [placeService, setPlaceService] = useState<google.maps.places.PlacesService>()
  useEffect(() => {
    if (!googleMapsPlacesScript) {
      const script = document.createElement('script')
      script.src = `${GOOGLE_MAPS_API_URL}?key=${API_KEY}&libraries=places&language=${LANGUAGE}`
      script.async = true
      googleMapsPlacesScript = script
      window.document.body.appendChild(script)
      const loadingInterval = setInterval(() => {
        const googlePlaces = window.google?.maps?.places
        if (googlePlaces) {
          clearInterval(loadingInterval)
          const map = new google.maps.Map(document.createElement('div'))
          setAutoCompleteService(new googlePlaces.AutocompleteService())
          setPlaceService(new googlePlaces.PlacesService(map))
          setLoading(false)
        }
      }, 100)
    }
  }, [])

  const getDetails = (placeId: string) => {
    return new Promise<GoogleLocation>((resolve, reject) => {
      if (!placeService) reject(new Error('place service was not initialized'))
      else {
        placeService.getDetails({ placeId }, (data) => {
          if (data?.address_components && data.geometry?.location) {
            resolve(addressComponentsToLocation(data.address_components, data.geometry.location, placeId))
          }
        })
      }
    })
  }

  const getPredictions = (searchString: string) => {
    return new Promise<Prediction[] | null>((resolve, reject) => {
      if (!autoCompleteService) reject(new Error('autocomplete service was not initialized'))
      else {
        autoCompleteService.getPlacePredictions(
          {
            input: searchString,
            componentRestrictions: { country: ['SE'] },
            types: ['address'],
          },
          (predictions, status) => {
            if (predictions) {
              const simplePredictions: Prediction[] = predictions.map((prediction) => {
                return {
                  description: prediction.description,
                  placeId: prediction.place_id,
                }
              })
              resolve(simplePredictions)
            } else {
              resolve(null)
            }
          },
        )
      }
    })
  }

  return {
    autoCompleteService,
    placeService,
    getDetails,
    getPredictions,
    isLoading,
  }
}

const addressComponentsToLocation = (
  components: google.maps.GeocoderAddressComponent[],
  location: google.maps.LatLng,
  placeId: string,
): GoogleLocation => {
  let country = 'Sverige'
  let countryCode = 'SE'
  let locality = null
  let route = null
  let postalCode = null
  let streetNumber = null
  const longitude = location.lng()
  const latitude = location.lat()

  components.forEach((addressComponent) => {
    if (addressComponent.types.includes('country')) {
      country = addressComponent.long_name
      countryCode = addressComponent.short_name
    }
    if (addressComponent.types.includes('postal_town')) {
      locality = addressComponent.long_name
    }
    if (addressComponent.types.includes('postal_code')) {
      postalCode = addressComponent.long_name
    }
    if (addressComponent.types.includes('route')) {
      route = addressComponent.long_name
    }
    if (addressComponent.types.includes('street_number')) {
      streetNumber = addressComponent.long_name
    }
  })

  return {
    country,
    countryCode,
    longitude,
    latitude,
    locality,
    postalCode,
    route,
    streetNumber,
    placeId,
  }
}
