import { RadioCard, RadioGroup } from '@qasa/app/src/components/radio-group'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export function DoesLandlordHaveKeysRadioGroup() {
  const { t } = useTranslation('edit_listing')
  const { control } = useFormContext()
  return (
    <Controller
      name="contractTemplateAttributes.landlordHasKeys"
      control={control}
      render={({ field: { onChange, value, name } }) => {
        return (
          <RadioGroup
            label={t('lease_details.ll_has_keys_label')}
            name={name}
            value={value ? 'yes' : 'no'}
            onChange={(newValue: string) => onChange(newValue === 'yes')}
          >
            <RadioCard value="yes" label={t('lease_details.ll_has_keys_yes')} />
            <RadioCard value="no" label={t('lease_details.ll_has_keys_no')} />
          </RadioGroup>
        )
      }}
    />
  )
}
