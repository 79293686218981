import { useRef, useState } from 'react'
import type { ReactNode } from 'react'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { defaultStyles } from '../styling/defaults'
import { palette, pixelAmount } from '../styling'
import { getPixelAmount } from '../styling/pixel-amount'

type DropDownProps = {
  width?: string
  style?: Record<string, string>
  name: string
  value?: string
  values: Array<DropDownElement>
  placeholder?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onChoice: (id: string) => void
  isValid?: boolean
  inactive?: boolean
  optional?: boolean
  isClearable?: boolean
  isMenuPlacementTop?: boolean
}

export type DropDownElement = {
  id: string
  value: ReactNode
}

const Wrapper = styled.div<Partial<DropDownProps>>(({ width, style, inactive }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
  pointerEvents: inactive ? 'none' : 'visible',
  opacity: inactive ? 0.3 : 1,
  backgroundColor: palette.white,
  padding: pixelAmount.md,
  borderRadius: defaultStyles.borderRadius,
  border: `${pixelAmount.xxs} solid ${palette.grey40}`,
  width: width ? width : 'auto',
  ':hover': {
    boxShadow: `0px 0px 5px ${palette.grayAlpha20}`,
  },
  ...{ style },
}))

const SearchInput = styled.input<{ isValid?: boolean }>(({ isValid }) => ({
  border: 'none',
  height: pixelAmount.xxl,
  outline: 'none',
  flexGrow: 1,
  pointerEvents: 'none',
  opacity: isValid === false ? 0.3 : 1,
  '&:focus': {
    pointerEvents: 'visible',
  },
  '&:not(:placeholder-shown)': {
    '& + div p': {
      backgroundColor: 'white',
      transform: 'translate(-5px, -120%) scale(0.8)',
      color: palette.textAlpha50,
      fontWeight: 500,
    },
  },
}))

const PlaceholderWrapper = styled.div({
  padding: `0 ${pixelAmount.md}`,
  pointerEvents: 'none',
  alignItems: 'center',
  position: 'absolute',
  display: 'flex',
  userSelect: 'none',
  bottom: 0,
  right: 0,
  left: 0,
  top: 0,
  paddingRight: getPixelAmount(3),
})

const Placeholder = styled.p({
  transition: `transform 0.3s, background-color 0.3s`,
  padding: `0 ${pixelAmount.sm}`,
  color: palette.textAlpha,
  transformOrigin: 'left',
  width: 'fit-content',
})

export const DropDownElementWrapper = styled.div({})

const Values = styled.div<{ isShown: boolean; isMenuPlacementTop?: boolean }>(
  ({ isShown, isMenuPlacementTop }) => ({
    position: 'absolute',
    left: 0,
    backgroundColor: palette.white,
    top: isMenuPlacementTop ? 'none' : `calc(${pixelAmount.xxl} + ${pixelAmount.md} * 2)`, //for both padding and height of parent
    borderRadius: defaultStyles.borderRadius,
    border: `${pixelAmount.xxs} solid ${palette.grey40}`,
    bottom: isMenuPlacementTop ? '100%' : 'none',
    overflow: 'auto',
    width: '100%',
    zIndex: 20,
    maxHeight: isShown ? getPixelAmount(30) : 0,
    opacity: isShown ? 1 : 0,
    transition: 'max-height 0.3s, opacity 0.3s',
  }),
)

const Value = styled.div({
  height: `calc(${pixelAmount.xxl} + ${pixelAmount.md} * 2)`,
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  padding: `0 ${pixelAmount.md}`,
  '&:hover': {
    backgroundColor: palette.grayAlpha20,
  },
})

export function DropDown({
  width,
  value,
  values,
  placeholder,
  onChange,
  onChoice,
  isValid,
  inactive,
  isClearable = false,
  isMenuPlacementTop = false,
}: DropDownProps) {
  const onClickTimeout = useRef<ReturnType<typeof setTimeout>>()
  const choiceList = useRef<HTMLDivElement>(null)
  const searchInput = useRef<HTMLInputElement>(null)

  const [hasFocus, setFocused] = useState(false)

  const setInputFocus = () => {
    searchInput?.current?.focus()
  }

  const handleChoice = (event: React.MouseEvent<HTMLDivElement | SVGSVGElement>, id: string) => {
    event.stopPropagation()
    onChoice(id)
  }

  const handleFocusChange = (isFocused: boolean, timeoutTime?: number) => {
    if (onClickTimeout.current) {
      clearTimeout(onClickTimeout.current)
    }

    //This timeout is set so that the clickevent is fired before focus gets off the input element.
    onClickTimeout.current = setTimeout(
      () => {
        if (isFocused) {
          choiceList.current?.scrollTo({ top: 0 })
        }
        setFocused(isFocused)
      },
      timeoutTime ? timeoutTime : 0,
    )
  }

  return (
    <Wrapper width={width} onClick={setInputFocus} inactive={inactive}>
      <SearchInput
        ref={searchInput}
        value={value || ''}
        placeholder=" "
        onChange={onChange}
        onFocus={() => handleFocusChange(true)}
        onBlur={() => handleFocusChange(false, 100)}
        isValid={isValid}
      />

      <PlaceholderWrapper>
        <Placeholder>{placeholder}</Placeholder>
      </PlaceholderWrapper>

      <Values isShown={hasFocus} ref={choiceList} isMenuPlacementTop={isMenuPlacementTop}>
        {values?.map((value) => (
          <Value key={value.id} onClick={(event) => handleChoice(event, value.id)}>
            {value.value}
          </Value>
        ))}
      </Values>
      {value && isClearable && (
        <FontAwesomeIcon
          icon={['fal', 'times-circle']}
          style={{ marginRight: 8 }}
          onClick={(event) => handleChoice(event, '')}
        />
      )}
      <FontAwesomeIcon
        icon={['fal', 'chevron-down']}
        style={{
          transform: hasFocus ? 'rotate(180deg)' : '',
          width: getPixelAmount(3),
        }}
        onClick={
          hasFocus
            ? (event) => {
                event.stopPropagation()
                handleFocusChange(false, 0)
              }
            : () => ''
        }
      />
    </Wrapper>
  )
}
