import { Stack, Heading, Link, Divider } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import { EditDialog } from '@qasa/app/src/modules/edit-dialog'
import styled from '@emotion/styled'

import { ListingSectionEnum, useListingContext } from '../../context/listing-context'
import { ApartmentDetails } from '../apartment/apartment-details'
import { ApartmentAmenities } from '../apartment/apartment-amenities'
import { ApartmentDescription } from '../apartment/apartment-description'

const LeftContainer = styled(Stack)({
  flexGrow: 1,
})
const RightContainer = Stack

export function ApartmentContent() {
  const { t } = useTranslation(['listing', 'commons'])
  const { home, setIsEditDialogOpen, setActiveEditSection } = useListingContext()
  const {
    rent,
    tenantCount,
    squareMeters,
    roomCount,
    kitchenRenovationYear,
    bathroomRenovationYear,
    floor,
    buildingFloors,
    seniorHome: isSeniorHome,
    studentHome: isStudentHome,
    firsthand: isFirsthand,
    corporateHome: isCorporateHome,
    homeTemplates,
    traits,
    apartmentNumber,
  } = home || {}

  const apartmentId = homeTemplates[0].id

  return (
    <Stack gap="12x">
      <Stack direction="row" justifyContent="space-between">
        <LeftContainer>
          <Heading>{t('commons:apartment_one')}</Heading>
        </LeftContainer>
        <RightContainer>
          <Link href={`/homes/apartments/${apartmentId}`}>{t('listing_actions.view_apartment')}</Link>
        </RightContainer>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <LeftContainer>
          <ApartmentDetails
            rent={rent}
            tenantCount={tenantCount}
            squareMeters={squareMeters}
            roomCount={roomCount}
            kitchenRenovationYear={kitchenRenovationYear}
            bathroomRenovationYear={bathroomRenovationYear}
            floor={floor}
            buildingFloors={buildingFloors}
            seniorHome={isSeniorHome}
            studentHome={isStudentHome}
            firsthand={isFirsthand}
            corporateHome={isCorporateHome}
            apartmentNumber={apartmentNumber}
          />
        </LeftContainer>
        <RightContainer>
          <EditDialog.Trigger
            onClick={() => {
              setIsEditDialogOpen(true)
              setActiveEditSection(ListingSectionEnum.APARTMENT_DETAILS)
            }}
          />
        </RightContainer>
      </Stack>
      <Divider />
      <Stack direction="row" justifyContent="space-between">
        <LeftContainer>
          <ApartmentDescription />
        </LeftContainer>
        <RightContainer>
          <EditDialog.Trigger
            onClick={() => {
              setIsEditDialogOpen(true)
              setActiveEditSection(ListingSectionEnum.DESCRIPTION)
            }}
          />
        </RightContainer>
      </Stack>
      <Divider />
      <ApartmentAmenities traits={traits} />
    </Stack>
  )
}
