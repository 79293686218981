import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { isBefore } from 'date-fns'

import { defaultStyles } from '../styling/defaults'
import { datesAreOnSameDay, getDayOfWeekIndex, getFullMonth } from '../util-functions/date.util'
import { fontSize, palette } from '../styling'

const DAY_SIZE = '30px'

const Wrapper = styled.div({
  userSelect: 'none',
  width: 'fit-content',
})
const CalendarHeader = styled.div({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '45px',
  backgroundColor: palette.primary,
  color: palette.white,
  borderRadius: `${defaultStyles.borderRadius} ${defaultStyles.borderRadius} 0 0`,
})
const Year = styled.p({
  fontSize: fontSize.textLg,
  fontWeight: 500,
})
const Month = styled.p({
  textTransform: 'capitalize',
  marginRight: '8px',
  fontSize: fontSize.textLg,
  fontWeight: 500,
})
const CalendarDays = styled.div({
  padding: '10px',
  display: 'grid',
  gridTemplateColumns: `repeat(7, ${DAY_SIZE})`,
  gridTemplateRows: '1fr',
  gridAutoRows: DAY_SIZE,
  gap: '10px',
  borderTop: 'none',
  borderRadius: `0 0 ${defaultStyles.borderRadius} ${defaultStyles.borderRadius}`,
})
const Day = styled.div<{ disabled?: boolean; active?: boolean }>(({ disabled, active }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: defaultStyles.borderRadiusRound,
  cursor: 'pointer',
  ':hover': {
    backgroundColor: palette.grayAlpha20,
  },
  ...(active && {
    fontWeight: 500,
    backgroundColor: palette.primary,
    color: palette.white,
    pointerEvents: 'none',
  }),
  ...(disabled && {
    pointerEvents: 'none',
    opacity: 0.2,
  }),
}))

type MonthChoiceProps = {
  previous?: boolean
  next?: boolean
}

const MonthChoice = styled.div<MonthChoiceProps>(({ previous, next }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '30px',
  width: '30px',
  position: 'absolute',
  cursor: 'pointer',
  borderRadius: defaultStyles.borderRadiusRound,
  ':hover': {
    backgroundColor: palette.white,
    color: palette.primary,
  },
  ...(next && {
    right: 5,
  }),
  ...(previous && {
    left: 5,
  }),
}))
const WeekDay = styled.p({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 600,
  fontSize: fontSize.textSm,
  color: palette.darkGray,
})

type DatePickerChangeEvent = {
  name?: string
  date: Date
}

type DatePickerProps = {
  name?: string
  onChange: (date: DatePickerChangeEvent) => void
  shouldProhibitPastDate?: boolean
  activeDate?: Date
  customAvailableStartDay?: Date
}

export function DatePicker({
  customAvailableStartDay,
  shouldProhibitPastDate,
  onChange,
  name,
  activeDate,
}: DatePickerProps) {
  const availableStartDate = customAvailableStartDay ?? new Date()

  const [currentMonth, setCurrentMonth] = useState<Date>(activeDate ?? availableStartDate)
  const { t } = useTranslation('commons', { keyPrefix: 'week_days_short' })

  const weekDays = [
    t('monday'),
    t('tuesday'),
    t('wednesday'),
    t('thursday'),
    t('friday'),
    t('saturday'),
    t('sunday'),
  ]

  const setNextMonth = () => {
    const newDate = new Date(currentMonth)
    newDate.setMonth(currentMonth.getMonth() + 1)
    setCurrentMonth(newDate)
  }

  const setPreviousMonth = () => {
    const newDate = new Date(currentMonth)
    newDate.setMonth(currentMonth.getMonth() - 1)
    setCurrentMonth(newDate)
  }

  const getDaysThisMonth = () => {
    const month = currentMonth.getMonth()
    const nextMonth = currentMonth.getMonth() + 1
    const year = currentMonth.getFullYear()

    const daysInLastMonth = new Date(year, month, 0).getDate()
    const daysInCurrentMonth = new Date(year, nextMonth, 0).getDate() // day 0 => the day before first day of next month

    const firstWeekDay = getDayOfWeekIndex(new Date(year, month, 1))
    const lastWeekDay = getDayOfWeekIndex(new Date(year, nextMonth, 0))

    const beforeThisMonth = Array(firstWeekDay)
      .fill('')
      .map((_, index) => (
        <Day disabled key={'before' + index}>
          {daysInLastMonth - firstWeekDay + index + 1}
        </Day>
      ))

    // Note: passing in availableStartDate caused a 1 day lag effect when using isWithinInterval & isBefore
    // creating a new date here with the year, month and day fixed it
    const calendarStartDate = new Date(
      availableStartDate.getFullYear(),
      availableStartDate.getMonth(),
      availableStartDate.getDate(),
    )

    const daysThisMonth = Array(daysInCurrentMonth)
      .fill('')
      .map((_, index) => {
        const dayDate = new Date(year, month, index + 1)

        const shouldCheckDayAgainstAvailableStartDate = shouldProhibitPastDate || customAvailableStartDay
        const isDayDisabled = shouldCheckDayAgainstAvailableStartDate
          ? isBefore(dayDate, calendarStartDate)
          : false

        return (
          <Day
            key={dayDate.toISOString()}
            disabled={isDayDisabled}
            onClick={() => onChange({ name, date: dayDate })}
            active={activeDate && datesAreOnSameDay(activeDate, dayDate)}
          >
            {index + 1}
          </Day>
        )
      })
    const afterThisMonth = Array(6 - lastWeekDay)
      .fill('')
      .map((_, index) => (
        <Day key={'after' + index} disabled>
          {index + 1}
        </Day>
      ))

    return [...beforeThisMonth, ...daysThisMonth, ...afterThisMonth]
  }

  function PreviousMonth() {
    return (
      <MonthChoice onClick={setPreviousMonth} previous>
        <FontAwesomeIcon icon={['fal', 'arrow-left']} />
      </MonthChoice>
    )
  }

  function NextMonth() {
    return (
      <MonthChoice onClick={setNextMonth} next>
        <FontAwesomeIcon icon={['fal', 'arrow-right']} />
      </MonthChoice>
    )
  }

  function WeekDays() {
    return (
      <>
        {weekDays.map((weekDay) => (
          <WeekDay key={weekDay}>{weekDay}</WeekDay>
        ))}
      </>
    )
  }

  function Days() {
    return <>{getDaysThisMonth()}</>
  }

  return (
    <Wrapper>
      <CalendarHeader>
        <PreviousMonth />
        <Month>{getFullMonth(currentMonth)}</Month>
        <Year>{currentMonth.getFullYear()}</Year>
        <NextMonth />
      </CalendarHeader>
      <CalendarDays>
        <WeekDays />
        <Days />
      </CalendarDays>
    </Wrapper>
  )
}
