import React, { Suspense, useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { pdfjs, Document, Page } from 'react-pdf'

import { ErrorMessage } from '../../../ui-shared/_core/error-message'
import { Loader } from '../../../ui-atoms/loader'
import { Spacer } from '../../../ui-atoms/help-components'

/* https://github.com/wojtekmaj/react-pdf/issues/991 */
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const ErrorContainer = styled.div({
  padding: 8,
  fontSize: 14,
  textAlign: 'center',
})
const StyledPage = styled(Page)({
  boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.2)',
  '> canvas': {
    width: '100% !important',
    height: '100% !important',
  },
})
export function PdfReader({ pdf }: { pdf: string | null }) {
  const [numPages, setNumPages] = useState<number>(0)
  const { t } = useTranslation('applicant', {
    keyPrefix: 'pdf_reader',
  })

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages)
  }

  return (
    <Suspense fallback={<Loader />}>
      <Document
        file={`data:application/pdf;base64,${pdf}`}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<Loader />}
        error={
          <ErrorContainer>
            <ErrorMessage>{t('error_cannot_load_pdf')}</ErrorMessage>
          </ErrorContainer>
        }
        noData={
          <ErrorContainer>
            <ErrorMessage>{t('error_pdf_not_found')}</ErrorMessage>
          </ErrorContainer>
        }
      >
        {Array.from(new Array(numPages), (page, index) => (
          <React.Fragment key={index}>
            <StyledPage
              key={`page-${index + 1}`}
              pageNumber={index + 1}
              renderTextLayer={false}
              loading={<Loader />}
              error={
                <ErrorContainer>
                  <ErrorMessage>{t('error_page_not_loading')}</ErrorMessage>
                </ErrorContainer>
              }
              noData={
                <ErrorContainer>
                  <ErrorMessage>{t('error_page_not_found')}</ErrorMessage>
                </ErrorContainer>
              }
            />
            <Spacer factor={3} />
          </React.Fragment>
        ))}
      </Document>
    </Suspense>
  )
}
