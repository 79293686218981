import styled from '@emotion/styled'
import { useQuery } from '@apollo/client'
import { LoadingDots, Heading, Paragraph, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

import { queries } from '../../../data/notifications'
import type {
  UnreadConversationsQuery,
  UnreadConversationsQueryVariables,
} from '../../../data/graphql/types/__generated__/UnreadConversationsQuery'
import { InformationBox } from '../../../ui-atoms/information-box'
import { WhiteBoxWrapper } from '../../../ui-shared/wrapper'
import { useFetchMoreOnScrollToBottom } from '../../../hooks/use-fetch-more-on-scroll-to-bottom'
import { Tabs } from '../../../ui-shared/tabs'
import { ConversationRoleTypeEnum } from '../../../data/graphql/types/__generated__/globalTypes'

import { Message } from './message'

const FETCH_AMOUNT = 10

enum MessagesTab {
  Unread = 'unread',
  Unanswered = 'unanswered',
}

const Wrapper = styled(Stack)(({ theme }) => ({
  flexGrow: 1,
  width: theme.sizes[384],
  border: `1px solid ${theme.colors.border.default}`,
  overflowY: 'auto',
}))

export function MessagesList() {
  const { t } = useTranslation(['unread_applications', 'overview'])
  const [currentTab, setCurrentTab] = useState(MessagesTab.Unread)

  const {
    data,
    error: conversationsError,
    loading: isLoadingConversations,
    fetchMore,
    refetch,
  } = useQuery<UnreadConversationsQuery, UnreadConversationsQueryVariables>(
    queries.GET_UNREAD_CONVERSATIONS,
    {
      variables: {
        onlyActiveApplications: true,
        first: FETCH_AMOUNT,
        read: false,
        lastMessageFrom: ConversationRoleTypeEnum.tenant,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
    },
  )

  const handleTabChange = (newTab: MessagesTab) => {
    setCurrentTab(newTab)
    refetch({
      onlyActiveApplications: true,
      first: FETCH_AMOUNT,
      after: null,
      read: Boolean(newTab === MessagesTab.Unanswered),
    })
  }

  const { handleScroll, isFetchingMore } = useFetchMoreOnScrollToBottom({
    handleFetchMore: () =>
      fetchMore({
        variables: {
          after: data?.conversations.pageInfo.endCursor,
          first: FETCH_AMOUNT,
        },
      }),
    shouldFetchMore: data?.conversations.pageInfo.hasNextPage,
  })

  if (conversationsError) {
    // eslint-disable-next-line no-console
    console.log(conversationsError)
  }

  const MESSAGES_TABS = [
    { value: MessagesTab.Unread, label: t('overview:messages_tabs.unread') },
    { value: MessagesTab.Unanswered, label: t('overview:messages_tabs.unanswered') },
  ]

  const conversations = data?.conversations.edges.map((edge) => edge.node)

  const renderContent = () => {
    if (isLoadingConversations && !data) {
      return (
        <WhiteBoxWrapper>
          <Wrapper alignItems="center" justifyContent="center">
            <LoadingDots />
          </Wrapper>
        </WhiteBoxWrapper>
      )
    }

    if (conversations?.length) {
      return (
        <Wrapper onScroll={handleScroll} alignItems="center">
          {conversations?.map((conversation) => {
            return <Message key={conversation.id} conversation={conversation} />
          })}
          {isFetchingMore && <LoadingDots />}
        </Wrapper>
      )
    }

    return (
      <Wrapper alignItems="center" justifyContent="center">
        <Paragraph textAlign="center" color="subtle">
          {t('overview:no_unread_messages')}
        </Paragraph>
      </Wrapper>
    )
  }

  return (
    <WhiteBoxWrapper gap="6x">
      <Heading size="xs">{t('overview:messages_header')}</Heading>
      <Tabs selectedOption={currentTab} onSelectChange={handleTabChange} options={MESSAGES_TABS} />
      <InformationBox>
        <Paragraph>{t('description')}</Paragraph>
      </InformationBox>
      {renderContent()}
    </WhiteBoxWrapper>
  )
}
