import { Navigate, Route, Routes } from 'react-router-dom'
import styled from '@emotion/styled'

import { useUserContext } from '../context/user-context'
import { Applications } from '../pages/applications'
import { Building } from '../pages/building'
import { EditBuilding } from '../pages/edit-building'
import { ForgotPassword } from '../pages/forgot-password'
import { Login } from '../pages/login'
import { MyHomes } from '../pages/my-homes'
import { ResetPassword } from '../pages/reset-password'
import { AddBuilding } from '../pages/add-building'
import { AddApartment } from '../pages/add-apartment'
import { Apartment } from '../pages/apartment'
import { EditApartment } from '../pages/edit-apartment'
import { Listing } from '../pages/listing'
import { AddListing } from '../pages/add-listing'
import { Settings } from '../pages/settings'
import { Listings } from '../pages/listings'
import { LoadingDots } from '../ui-shared/loading-dots'
import { Overview } from '../pages/overview'

const Wrapper = styled.div(({ theme }) => ({
  overflowX: 'hidden',
  height: '100%',
  width: '100%',
  padding: theme.spacing['6x'],
}))

export function AppRoutes() {
  const { isAuthenticated, isLoading } = useUserContext()

  if (isLoading) return <LoadingDots />

  return (
    <Wrapper>
      {isAuthenticated ? (
        <Routes>
          <Route path="/homes/buildings/add" element={<AddBuilding />} />
          <Route path="/homes/buildings/edit/:buildingId" element={<EditBuilding />} />
          <Route path="/homes/buildings/:buildingId" element={<Building />} />

          <Route path="/homes/apartments/add" element={<AddApartment />} />
          <Route path="/homes/apartments/edit/:apartmentId" element={<EditApartment />} />
          <Route path="/homes/apartments/:apartmentId/*" element={<Apartment />} />

          <Route path="/listings/add/" element={<AddListing />} />
          <Route path="/listings/add/:apartmentId" element={<AddListing />} />
          <Route path="/listings/:listingId/*" element={<Listing />} />

          <Route path="/homes" element={<Navigate to="/homes/buildings" replace />} />
          <Route path="/homes/*" element={<MyHomes />} />
          <Route path="/listings" element={<Listings />} />

          <Route path="/applications/*" element={<Applications />} />
          <Route path="/settings/" element={<Settings />} />

          <Route path="/" element={<Overview />} />
          <Route path="/*" element={<Navigate to="/" replace />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:resetPasswordToken" element={<ResetPassword />} />
          <Route path="/*" element={<Navigate to="/" replace />} />
        </Routes>
      )}
    </Wrapper>
  )
}
