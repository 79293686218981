import { useState } from 'react'
import styled from 'styled-components/macro'
import moment from 'moment'
import { useMutation } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import type { HomeApplicationOverview_homeApplication_tenant_userNotes } from '../../../data/graphql/types/__generated__/HomeApplicationOverview'
import { palette, pixelAmount } from '../../../styling'
import { mutations as applicationMutations } from '../../../data/applicant'
import { Spacer } from '../../../ui-atoms/help-components'
import { useUserContext } from '../../../context/user-context'

export const UserComment = styled.div({
  color: palette.textPrimary,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 0',
  width: '100%',
  backgroundColor: palette.blocketYellow + '10',
})

const CommentRow = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  maxWidth: '92%',
  marginLeft: pixelAmount.md,
})

const AuthorDateRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  fontSize: 11,
})

const DeleteButton = styled.div<{ isDisabled: boolean }>(({ isDisabled }) => ({
  marginRight: pixelAmount.md,
  cursor: 'pointer',
  color: palette.black,
  ...(isDisabled && {
    pointerEvents: 'none',
  }),
}))

type CommentProps = {
  userNote: HomeApplicationOverview_homeApplication_tenant_userNotes
}

export function OverViewComment(props: CommentProps) {
  const [isDisabled, setIsDisabled] = useState(false)
  const { userNote } = props
  const { authBody } = useUserContext()
  const userId = authBody?.uid
  const userNoteCreatedAt = moment(new Date(userNote.createdAt)).format('YYYY-MM-DD, HH:MM')
  const [destroyApplicationComment] = useMutation(applicationMutations.DESTROY_APPLICATION_COMMENT, {
    refetchQueries: ['HomeApplicationOverview'],
  })
  const route = userNote.homeApplication?.home.location?.route || ''
  const streetNumber = userNote.homeApplication?.home.location?.streetNumber || ''

  return (
    <UserComment key={userNote.id}>
      <CommentRow>
        <AuthorDateRow>{`(${userNoteCreatedAt}) - ${route} ${streetNumber}`}</AuthorDateRow>
        <Spacer factor={1} />
        <p>{userNote.note}</p>
      </CommentRow>
      {userId === userNote.author.uid && (
        <DeleteButton
          isDisabled={isDisabled}
          onClick={() => {
            setIsDisabled(true)
            destroyApplicationComment({ variables: { noteId: userNote.id } })
          }}
        >
          <FontAwesomeIcon icon={['fal', 'trash-alt']} />
        </DeleteButton>
      )}
    </UserComment>
  )
}
