import styled from '@emotion/styled'
import { useParams, useNavigate, Navigate, Link as RouterLink } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Button, Heading, Spacer, ChevronLeftIcon, IconButton, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { queries } from '../data/building'
import { HorizontalDivider } from '../ui-atoms/help-components'
import { formatLocation } from '../util-functions/general.util'
import { SmallHeader } from '../ui-atoms/small-header'
import { SmallData } from '../ui-atoms/small-data'
import { Map } from '../ui-atoms/map'
import { ImagesContainer, ObjectImage } from '../ui-atoms/object-image'
import type { BuildingQuery } from '../data/graphql/types/__generated__/BuildingQuery'
import { TopSection } from '../ui-atoms/top-section'
import { WhiteBoxWrapper } from '../ui-shared/wrapper'

const Wrapper = styled.div({
  display: 'flex',
  height: '100%',
})

const Content = styled(Stack)({
  maxWidth: '1280px',
  width: '100%',
})

const BuildingInfo = styled.div({
  position: 'relative',
  overflow: 'auto',
})

const EditLink = styled(RouterLink)({
  textDecoration: 'underline',
  position: 'absolute',
  right: 0,
  top: 0,
})

const ButtonsContainer = Stack

type TParams = { buildingId: string }

export function Building() {
  const { t } = useTranslation(['building', 'commons'])

  const params = useParams<TParams>()
  const { buildingId } = params

  const navigate = useNavigate()

  const {
    data,
    error,
    loading: isLoading,
  } = useQuery<BuildingQuery>(queries.GET_BUILDING, {
    fetchPolicy: 'no-cache',
    variables: { buildingId },
  })

  if (error) {
    switch (error?.message) {
      case 'not_found':
        return <Navigate to="/homes" replace />
      default:
        // eslint-disable-next-line no-console
        console.log(error?.message)
    }
  }

  if (isLoading) return null

  const { building } = data!

  return (
    <Wrapper>
      <Content gap="6x">
        <TopSection>
          <Stack direction={'row'} gap="6x" alignItems="center">
            <IconButton
              variant="tertiary"
              label="Go back"
              icon={ChevronLeftIcon}
              size="xs"
              onClick={() => navigate('/homes/buildings', { replace: true })}
            />
            <Heading as="h1" size="md">
              {t('building_title', { name: building.buildingName, count: building.homeTemplateCount })}
            </Heading>
          </Stack>
          <ButtonsContainer direction={'row'} gap="3x">
            <Button as={RouterLink} variant="tertiary" to={`/homes/apartments/add?buildingId=${building.id}`}>
              {t('commons:actions.add_apartment')}
            </Button>
            <Button as={RouterLink} variant="secondary" to={`/homes/apartments?buildingId=${building.id}`}>
              {t('commons:actions.view_apartments')}
            </Button>
          </ButtonsContainer>
        </TopSection>
        <WhiteBoxWrapper>
          <BuildingInfo>
            <EditLink to={`/homes/buildings/edit/${building.id}`}>
              {t('commons:actions.edit_building')}
            </EditLink>
            <SmallHeader>{t('fields.building_name')}</SmallHeader>
            <Spacer size="2x" />
            <SmallData>{building.buildingName}</SmallData>

            <Spacer size="8x" />

            <SmallHeader>{t('fields.building_address')}</SmallHeader>
            <Spacer size="2x" />
            <SmallData>{formatLocation(building.location)}</SmallData>
            <Spacer size="2x" />
            <Map
              width={400}
              isInteractionDisabled={true}
              markers={[
                {
                  longitude: building.location!.longitude!,
                  latitude: building.location!.latitude!,
                  dragable: false,
                },
              ]}
            />

            <Spacer size="8x" />
            <HorizontalDivider />
            <Spacer size="8x" />

            <SmallHeader>{t('fields.building_description')}</SmallHeader>
            {building.descriptionBuilding && (
              <>
                <Spacer size="2x" />
                {building.descriptionBuilding
                  ?.split('\n')
                  .map((descriptionLine) => <SmallData key={descriptionLine}>{descriptionLine}</SmallData>)}
              </>
            )}
            {building.descriptionTransportation && (
              <>
                <Spacer size="8x" />
                {building.descriptionTransportation
                  ?.split('\n')
                  .map((descriptionLine) => <SmallData key={descriptionLine}>{descriptionLine}</SmallData>)}
              </>
            )}
            {!(building.descriptionBuilding || building.descriptionTransportation) && (
              <>
                <Spacer size="2x" />
                <SmallData transparent>{t('missing_info.no_description')}</SmallData>
              </>
            )}

            <Spacer size="8x" />

            <SmallHeader>{t('fields.construction_year')}</SmallHeader>
            <Spacer size="2x" />
            {building.buildYear ? (
              <SmallData>{building.buildYear}</SmallData>
            ) : (
              <SmallData transparent>{t('missing_info.no_construction_year')}</SmallData>
            )}

            <Spacer size="8x" />

            <SmallHeader>{t('fields.energy_class')}</SmallHeader>
            <Spacer size="2x" />
            {building.energyClass ? (
              <SmallData>{building.energyClass}</SmallData>
            ) : (
              <SmallData transparent>{t('missing_info.no_energy_class')}</SmallData>
            )}

            <Spacer size="8x" />

            <SmallHeader>{t('fields.building_images')}</SmallHeader>
            <Spacer size="2x" />

            {building.uploads.length > 0 ? (
              <ImagesContainer>
                {building.uploads.map((upload) => (
                  <ObjectImage src={upload.url} key={upload.url} />
                ))}
              </ImagesContainer>
            ) : (
              <SmallData transparent>{t('missing_info.no_building_images')}</SmallData>
            )}
          </BuildingInfo>
        </WhiteBoxWrapper>
      </Content>
    </Wrapper>
  )
}
