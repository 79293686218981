import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { Link } from '../../ui-shared/_core/links-buttons'
import { HorizontalDivider, Spacer } from '../../ui-atoms/help-components'
import type { ApartmentQuery_homeTemplate_building } from '../../data/graphql/types/__generated__/ApartmentQuery'
import { SmallHeader } from '../../ui-atoms/small-header'
import { SmallData } from '../../ui-atoms/small-data'
import { formatLocation } from '../../util-functions/general.util'
import { Map } from '../../ui-atoms/map'
import { ObjectImage, ImagesContainer } from '../../ui-atoms/object-image'
import { getPixelAmount } from '../../styling/pixel-amount'
import { EndSpace } from '../applications/overview/overview'

const Wrapper = styled.div({
  flexGrow: 1,
  overflow: 'auto',
  position: 'relative',
})

const EditLink = styled(Link)({
  position: 'absolute',
  right: 0,
  top: getPixelAmount(3),
})

export function BuildingInfo(props: ApartmentQuery_homeTemplate_building) {
  const { t } = useTranslation(['building', 'commons'])
  return (
    <Wrapper>
      <EditLink variant="text" to={`/homes/buildings/edit/${props.id}`}>
        {t('commons:actions.edit_building')}
      </EditLink>
      <Spacer factor={3} />

      <SmallHeader>{t('fields.building_name')}</SmallHeader>
      <Spacer factor={1} />
      <SmallData>{props.buildingName}</SmallData>

      <Spacer factor={4} />

      <SmallHeader>{t('fields.building_address')}</SmallHeader>
      <Spacer factor={1} />
      <SmallData>{formatLocation(props.location!)}</SmallData>
      <Spacer factor={1} />
      <Map
        width={400}
        isInteractionDisabled={true}
        markers={[
          {
            longitude: props.location!.longitude!,
            latitude: props.location!.latitude!,
            dragable: false,
          },
        ]}
      />
      <Spacer factor={4} />
      <HorizontalDivider />
      <Spacer factor={4} />
      <SmallHeader>{t('fields.building_description')}</SmallHeader>
      <Spacer factor={1} />
      {props.descriptionBuilding ? (
        props.descriptionBuilding
          .split('\n')
          .map((descriptionPart) => <SmallData key={descriptionPart}>{descriptionPart}</SmallData>)
      ) : (
        <SmallData transparent>{t('missing_info.no_description')}</SmallData>
      )}
      <Spacer factor={1} />
      {props.descriptionTransportation ? (
        props.descriptionTransportation
          .split('\n')
          .map((descriptionPart) => <SmallData key={descriptionPart}>{descriptionPart}</SmallData>)
      ) : (
        <SmallData transparent>{t('missing_info.no_comms_description')}</SmallData>
      )}
      <Spacer factor={4} />
      <SmallHeader>{t('fields.construction_year')}</SmallHeader>
      <Spacer factor={1} />
      {props.buildYear ? (
        <SmallData>{props.buildYear}</SmallData>
      ) : (
        <SmallData transparent>{t('missing_info.no_construction_year')}</SmallData>
      )}
      <Spacer factor={4} />
      <SmallHeader>{t('fields.energy_class')}</SmallHeader>
      <Spacer factor={1} />
      {props.energyClass ? (
        <SmallData>{props.energyClass}</SmallData>
      ) : (
        <SmallData transparent>{t('missing_info.no_energy_class')}</SmallData>
      )}
      <Spacer factor={4} />

      <HorizontalDivider />
      <Spacer factor={4} />

      <SmallHeader>{t('fields.building_images')}</SmallHeader>
      <Spacer factor={1} />

      {props.uploads.length > 0 ? (
        <ImagesContainer>
          {props.uploads.map((upload) => (
            <ObjectImage src={upload.url} key={upload.url} />
          ))}
        </ImagesContainer>
      ) : (
        <SmallData transparent>{t('missing_info.no_building_images')}</SmallData>
      )}
      <EndSpace />
    </Wrapper>
  )
}
