import { Button, IconButton } from '@qasa/qds-ui'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { useListingContext } from '../../context/listing-context'
import type { TransitionShortcutInput } from '../../data/graphql/types/__generated__/globalTypes'
import { HomeStatusEnum, ShortcutStatesEnum } from '../../data/graphql/types/__generated__/globalTypes'
import { TRANSITION_SHORTCUT } from '../../data/transition-shortcut'
import type { PublishHomeMutation } from '../../data/graphql/types/__generated__/PublishHomeMutation'
import { mutations } from '../../data/add-listing'
import { useUserContext } from '../../context/user-context'
import { ListingsStatusFilters } from '../listings/listings-status-navigation'
import { EyeIcon } from '../../ui-shared/eye-icon'

import { BumpListing } from './bump-listing'
import { ArchiveListing } from './archive-listing'

export function useListingActions() {
  const navigate = useNavigate()
  const { authBody } = useUserContext()
  const isProAgent = authBody?.isProAgent
  const { home } = useListingContext()
  const { t } = useTranslation(['listing', 'commons'])

  const {
    id,
    status,
    archivedBecauseOfApplicationDeadlinePassed: isArchivedBecauseOfApplicationDeadlinePassed,
    shortcut,
  } = home || {}

  // NOTE: adding the in_review here since it should be momentary (all pro listings should get autopublished) so we can show correct state to the user in that phase
  const isListingActive = status === HomeStatusEnum.normal || status === HomeStatusEnum.in_review
  const isListingArchived =
    status === HomeStatusEnum.archived && !isArchivedBecauseOfApplicationDeadlinePassed
  const isPassedDeadline = status === HomeStatusEnum.archived && isArchivedBecauseOfApplicationDeadlinePassed

  const [publishListing, { loading: isLoading }] = useMutation<PublishHomeMutation>(
    mutations.PUBLISH_LISTING,
    {
      refetchQueries: ['ListingQuery'],
      onError: (e) => {
        // eslint-disable-next-line no-console
        console.error(e)
      },
    },
  )

  const handlePublish = () => {
    publishListing({ variables: { publishHomeId: id } })
  }

  const [transitionShortcut, { loading: isTransitionLoading }] = useMutation(TRANSITION_SHORTCUT, {
    onCompleted: () => navigate('/listings', { replace: true }),
    // eslint-disable-next-line no-console
    onError: (e) => console.error(e),
  })

  const handleQuitShortcut = () => {
    const input: TransitionShortcutInput = {
      shortcutId: shortcut?.id || '',
      toState: ShortcutStatesEnum.canceled,
    }
    transitionShortcut({
      variables: {
        transitionShortcutInput: input,
      },
    })
  }

  const showApplicationsCTA = () => {
    return (
      <Button
        as="a"
        href={`/applications/requests?listing=${id}&listingStatus=${
          status === HomeStatusEnum.archived
            ? ListingsStatusFilters.PASSED_DEADLINE
            : ListingsStatusFilters.PUBLISHED
        }`}
      >
        {t('listing_actions.view_applications')}
      </Button>
    )
  }

  const giveBackToLLCTA = () => {
    return (
      <Button isLoading={isTransitionLoading} onClick={handleQuitShortcut}>
        {t('listing_actions.give_shortcut_back_to_landlord')}
      </Button>
    )
  }

  const publishCTA = () => {
    return (
      <Button variant="secondary" isLoading={isLoading} onClick={() => handlePublish()}>
        {t('commons:actions.publish_listing')}
      </Button>
    )
  }

  const bumpListingCTA = () => <BumpListing />

  const archiveListingCTA = () => <ArchiveListing />

  const showAdCTA = () => (
    <IconButton
      icon={EyeIcon}
      label="show-listing-button"
      title={t('icon_tooltips.open')}
      as="a"
      variant="tertiary"
      href={`${process.env.REACT_APP_MAIN_APP_URL}sv/home/${id}`}
      target="_blank"
      rel="noreferrer"
    />
  )

  const iconActions = [
    ...(isListingActive ? [bumpListingCTA, showAdCTA] : []),
    ...(isListingArchived ? [] : [archiveListingCTA]),
  ]

  if (isListingActive || isPassedDeadline) {
    if (isProAgent) return [...iconActions, giveBackToLLCTA, showApplicationsCTA]
    return [...iconActions, showApplicationsCTA]
  }

  if (!isListingActive) {
    if (isProAgent) return [...iconActions, giveBackToLLCTA, publishCTA]
    return [...iconActions, publishCTA]
  }
  return []
}
