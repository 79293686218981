import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import toArray from 'lodash/toArray'
import { useRef, useState } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { Spacer } from './help-components/spacer'
import { ImageWrapper } from './object-image'

type ImageUploadHandlerProps = {
  onAdd: (files: Array<File>) => void
  onDelete: (file: File) => void
  numberOfAllowedImages?: number
}

const Wrapper = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
})

const HiddenFileInput = styled.input({
  display: 'none',
})

const UploadText = styled.p({})

const UploadContainer = styled(ImageWrapper)<{ isActive: boolean }>(({ isActive, theme }) => ({
  cursor: 'pointer',
  borderRadius: theme.radii.sm,
  backgroundColor: theme.colors.bg.brandTertiary,
  color: theme.colors.text.onBrandTertiary,
  userSelect: 'none',
  ':hover': {
    backgroundColor: theme.colors.bg.brandSecondaryHover,
    color: theme.colors.text.onBrandSecondary,
  },
  ...(isActive && {
    backgroundColor: theme.colors.bg.brandSecondaryHover,
    color: theme.colors.text.onBrandSecondary,
  }),
  '*': {
    pointerEvents: 'none',
  },
}))

export function ImageUploadHandler(props: ImageUploadHandlerProps) {
  const [shouldImageBeDraggedOver, setDragedOver] = useState(false)
  const { t } = useTranslation('miscellaneous', { keyPrefix: 'image_upload_handler' })

  const fileInput = useRef<HTMLInputElement>(null)

  const handleClick = () => {
    if (fileInput.current) {
      fileInput.current.click()
    }
  }

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    setDragedOver(true)
    event.preventDefault()
    event.stopPropagation()
  }
  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    setDragedOver(false)
    event.preventDefault()
    event.stopPropagation()
  }
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    setDragedOver(false)
    event.preventDefault()
    event.stopPropagation()
    if (event.dataTransfer.files) {
      props.onAdd(toArray(event.dataTransfer.files))
    }
  }

  const handleFileChoice = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      props.onAdd(toArray(event.target.files))
    }
  }

  return (
    <Wrapper>
      <UploadContainer
        onClick={handleClick}
        onDrop={(e: React.DragEvent<HTMLDivElement>) => handleDrop(e)}
        onDragOver={(e: React.DragEvent<HTMLDivElement>) => handleDragOver(e)}
        onDragEnter={(e: React.DragEvent<HTMLDivElement>) => handleDragEnter(e)}
        onDragLeave={(e: React.DragEvent<HTMLDivElement>) => handleDragLeave(e)}
        isActive={shouldImageBeDraggedOver}
      >
        <FontAwesomeIcon icon={['fal', 'cloud-upload']} size="3x" />
        <Spacer factor={1} />
        <UploadText>{t('label')}</UploadText>
      </UploadContainer>
      <HiddenFileInput
        type="file"
        multiple
        ref={fileInput}
        onChange={handleFileChoice}
        accept=".jpg, .jpeg, .png"
      />
    </Wrapper>
  )
}
