import styled from 'styled-components/macro'

import { fontSize, palette } from '../../styling'

const Display = styled.div({})

const Header = styled.p({
  fontSize: fontSize.textSm,
  fontWeight: 200,
  color: palette.textSecondary,
})
const Value = styled.p({
  fontSize: fontSize.textBase,
  fontWeight: 400,
  lineHeight: 2,
})

type ApplicantDocumentInfoDisplayProps = {
  header: string
  value?: string | number
  children?: JSX.Element
}
export function InfoDisplay(props: ApplicantDocumentInfoDisplayProps) {
  const { header, value, children } = props
  return (
    <Display>
      <Header>{header}</Header>
      {value && <Value>{value}</Value>}
      {children}
    </Display>
  )
}
