import styled from '@emotion/styled'
import { SelectBase } from '@qasa/qds-ui'

type FilterDisplayProps = {
  value?: string
  onChange: React.ChangeEventHandler<HTMLSelectElement>
  placeholder: string
  options: { value: string; label: string }[]
}

const PillSelect = styled(SelectBase)(({ theme }) => ({
  borderRadius: theme.radii.full,
  height: 32,
}))

export function SortingSelect({ value, onChange, placeholder, options }: FilterDisplayProps) {
  return (
    <PillSelect onChange={onChange} value={value ?? ''}>
      {placeholder && (
        <option value="" disabled>
          {placeholder}
        </option>
      )}
      {options.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </PillSelect>
  )
}
