import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Stack, createIcon, TextField, Button } from '@qasa/qds-ui'
import { Dialog } from '@qasa/app/src/components/dialog'

import { mutations } from '../../../data/application-board-lists'

import { ActionContainer, ActionName } from './column-menu'

const EditIcon = createIcon({
  displayName: 'EditIcon',
  viewBox: '0 0 512 512',
  d: 'M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z',
})

type EditColumnProps = {
  columnId: string
  columnTitle: string
  refetch: () => void
}

export function EditColumn({ refetch, columnId, columnTitle }: EditColumnProps) {
  const [inputColumnName, setInputColumnName] = useState('')
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

  const { t } = useTranslation(['applications', 'commons'])

  const [updateApplicationBoardList] = useMutation(mutations.UPDATE_BOARD_LIST, {
    onCompleted: () => {
      setIsSettingsModalOpen(false)
      refetch()
    },
  })

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { value: name } = event.target
    setInputColumnName(name)
  }

  const onSubmit = () => {
    if (inputColumnName === '') {
      setErrorMessage(t('create_column.error_name_missing'))
    } else {
      setErrorMessage(undefined)
      updateApplicationBoardList({
        variables: { updateApplicationBoardListId: columnId, name: inputColumnName },
      })
    }
  }

  return (
    <Dialog
      isOpen={isSettingsModalOpen}
      onOpenChange={(isOpen) => setIsSettingsModalOpen(isOpen)}
      size="sm"
      trigger={
        <ActionContainer direction="row" alignItems="center" gap="4x">
          <EditIcon />
          <ActionName>{t('column_settings.change_name')}</ActionName>
        </ActionContainer>
      }
    >
      <Dialog.Body>
        <Stack gap="4x" onClick={(e) => e.stopPropagation()} alignItems="flex-start">
          <Dialog.Header>
            <Dialog.Title>{t('column_header.rename_column', { columnTitle })}</Dialog.Title>
          </Dialog.Header>
          <TextField
            label=""
            name={t('commons:name')}
            placeholder={t('create_column.name_of_column')}
            onChange={handleChange}
            errorMessage={errorMessage}
          />
        </Stack>
      </Dialog.Body>
      <Dialog.Footer>
        <Button variant="primary" onClick={onSubmit}>
          {t('commons:ctas.save')}
        </Button>
      </Dialog.Footer>
    </Dialog>
  )
}
