import { useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Spacer } from '../../../ui-atoms/help-components'
import { queries as alsoAppliedQueries } from '../../../data/applicant'
import type { HomeApplicationAlsoAppliedTo } from '../../../data/graphql/types/__generated__/HomeApplicationAlsoAppliedTo'
import { resolveStatus } from '../../../util-functions/general.util'
import { MediumHeader } from '../../../ui-atoms/header-text'
import buildingPlaceholder from '../../../assets/placeholders/building.png'
import { LoadingDots } from '../../../ui-shared/loading-dots'

import { OverviewHomeApplicationListing } from './overview-home-application-listing'

export function OverviewAlsoAppliedTo() {
  const { t } = useTranslation('applications', { keyPrefix: 'overview.also_applied_to' })
  const { GET_APPLICANT_ALSO_APPLIED_TO } = alsoAppliedQueries
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const id = params.get('applicant')

  const {
    loading: isLoadingAlsoApplied,
    error: errorAlsoApplied,
    data: alsoAppliedData,
  } = useQuery<HomeApplicationAlsoAppliedTo>(GET_APPLICANT_ALSO_APPLIED_TO, {
    variables: {
      homeApplicationId: id,
    },
  })

  if (errorAlsoApplied) {
    // eslint-disable-next-line no-console
    console.error(errorAlsoApplied.message)
    return null
  }

  if (isLoadingAlsoApplied) {
    return <LoadingDots />
  }

  const checkIfEmpty = (alsoAppliedData?: HomeApplicationAlsoAppliedTo): boolean => {
    if (alsoAppliedData) {
      if (alsoAppliedData?.homeApplication.tenant.homeApplicationsToMyHomes.length > 1) {
        return false
      }
    }
    return true
  }

  if (checkIfEmpty(alsoAppliedData))
    return (
      <>
        <MediumHeader>{t('title')}</MediumHeader>
        <Spacer factor={3} />
        <p>{t('no_other_applications')}</p>
      </>
    )
  return (
    <>
      <MediumHeader>{t('title')}</MediumHeader>
      <Spacer factor={4} />
      {alsoAppliedData?.homeApplication.tenant.homeApplicationsToMyHomes.map(
        (application) =>
          application.id !== id && (
            <div key={application.id}>
              <Spacer factor={2} />
              <OverviewHomeApplicationListing
                id={application.id}
                showStatus
                data={{
                  applicantStatus: resolveStatus(application.status, application.offering?.offeringStatus),
                  homeStreetNumber: application.home.location?.streetNumber,
                  homeSquareMeters: application.home.squareMeters,
                  homeRoute: application.home.location?.route,
                  homeRoomCount: application.home.roomCount,
                  numberOfHomes: application.home.numberOfHomes,
                  announceId: application.home.id,
                  homeApartmentNumber: application.home.apartmentNumber,
                  homeImage: {
                    url: application.home.uploads[0] ? application.home.uploads[0].url : buildingPlaceholder,
                    alt: '',
                  },
                }}
              />
            </div>
          ),
      )}
    </>
  )
}
