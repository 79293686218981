import type { ReactNode, CSSProperties, ComponentProps } from 'react'
import React, { Fragment } from 'react'
import styled from 'styled-components/macro'

import type { SpaceType } from '../../ui-styles/base.types'
import { HorizontalDivider } from '../../ui-atoms/help-components'

const SPACE_MULTIPLIER = 8

const spaces: SpaceType = {
  extraSmall: 4,
  small: 12,
  medium: 24,
  large: 48,
  extraLarge: 64,
  extraExtraLarge: 96,
}

const Wrapper = styled.div<
  Pick<PropValues, 'shouldWrap' | 'align' | 'justify' | 'direction'> & { space: number }
>(({ shouldWrap, direction, justify, align, space }) => ({
  display: 'flex',
  flexDirection: direction,
  alignItems: align,
  justifyContent: justify,
  flexWrap: shouldWrap ? 'wrap' : 'nowrap',
  gap: space,
  listStyleType: 'none',
  margin: 0,
  padding: 0,
}))

const getValueForBreakpoint = ({ value }: { value: ValueOf<ResponsivePropValues> }): ValueOf<PropValues> => {
  if (Array.isArray(value)) {
    return value[0]
  } else {
    return value
  }
}

const getPropsForBreakpoint = ({ props }: { props: Partial<ResponsivePropValues> }): Partial<PropValues> => {
  let breakpointProps: Partial<PropValues> = {}
  for (const prop in props) {
    const key = prop as keyof PropValues
    const value = props[key]
    if (value) {
      breakpointProps = { ...breakpointProps, [key]: getValueForBreakpoint({ value }) }
    }
  }
  return breakpointProps
}

type WrapperProps = ComponentProps<typeof Wrapper>
type FlexJustify = Extract<
  CSSProperties['justifyContent'],
  'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly'
>
type FlexAlign = Extract<
  CSSProperties['alignItems'],
  'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline'
>

type PropValues = {
  gap: keyof SpaceType | number
  hasDivider: boolean
  shouldWrap: boolean
  direction: CSSProperties['flexDirection']
  justify: FlexJustify
  align: FlexAlign
}

type ResponsivePropValues = {
  [key in keyof PropValues]: PropValues[key] | [mobile: PropValues[key], desktop: PropValues[key]]
}

type StackProps = {
  children: ReactNode
  className?: string
  id?: string
} & Partial<ResponsivePropValues> &
  WrapperProps

const HorizontalDividerWithoutGap = styled(HorizontalDivider)<{ space: number }>(({ space }) => ({
  marginTop: -(space / 2),
  marginBottom: -(space / 2),
}))

/**
 * @deprecated Use `Stack` from `@qasa/qds-ui` instead
 *
 * __NOTE:__ The QDS stack component doesn't reduce half the gap between the children when a divider is present.
 */
export function Stack({ children, className, as, id, ...props }: StackProps) {
  const {
    gap = 0,
    hasDivider = false,
    shouldWrap = false,
    direction = 'column',
    justify = 'flex-start',
    align = 'stretch',
  } = getPropsForBreakpoint({ props })

  const space = typeof gap === 'number' ? gap * SPACE_MULTIPLIER : spaces[gap]
  const childrenAsArray = React.Children.toArray(children).filter(Boolean)

  return (
    <Wrapper {...{ className, as, direction, align, justify, shouldWrap, space, id }} tabIndex="-1">
      {childrenAsArray.map((child, index) => {
        const isNotLastChild = index !== childrenAsArray.length - 1
        const shouldRenderDivider =
          isNotLastChild && hasDivider && (direction === 'column' || direction === 'column-reverse')
        return (
          <Fragment key={index}>
            {child}
            {shouldRenderDivider && <HorizontalDividerWithoutGap {...{ space }} />}
          </Fragment>
        )
      })}
    </Wrapper>
  )
}
