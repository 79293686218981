import type { ReactNode } from 'react'
import styled from '@emotion/styled'
import { Stack, IconButton, XIcon } from '@qasa/qds-ui'

type RemoveImageButtonProps = {
  onRemoveImage: () => void
}
type ObjectImageProps = {
  src: string
  children?: ReactNode
  isSelected?: boolean
  isActive?: boolean
  onClick?: () => void
}

export const ImagesContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing['2x'],
  rowGap: theme.spacing['4x'],
}))

const RemoveImage = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: 0,
  width: theme.spacing['6x'],
  height: theme.spacing['6x'],
  transform: 'translate(5px, -5px)',
}))

export const ImageWrapper = styled(Stack)<{
  isSelected?: boolean | undefined
  isActive?: boolean | undefined
}>(({ theme, isSelected, isActive }) => ({
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: theme.radii.xs,
  height: theme.sizes[160],
  width: theme.sizes[224],
  ...(isActive &&
    !isSelected && {
      cursor: 'pointer',
      border: `2px solid ${theme.colors.bg.brandSecondaryHover}`,
    }),
}))

export const Image = styled.img(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: theme.radii.xs,
  border: `1px solid ${theme.colors.border.default}`,
  transition: 'transform .2s',
}))

export function RemoveImageButton({ onRemoveImage }: RemoveImageButtonProps) {
  return (
    <RemoveImage size="xs" variant="secondary" label="remove-image" icon={XIcon} onClick={onRemoveImage} />
  )
}
export function ObjectImage({ src, children, isSelected, isActive = false, onClick }: ObjectImageProps) {
  return (
    <ImageWrapper onClick={onClick} isSelected={isSelected} isActive={isActive}>
      <Image src={src} />
      {children ? children : ''}
    </ImageWrapper>
  )
}
