export const blocketPalette = {
  red: '#F71414',
  purple: '#AE006E',
  orange: '#ff5800',
  peach: '#fb8375',
  pink: '#fdadae',
  pinkLight: '#fdd4d6',
  yellow: '#ffb500',
  blue: '#007ff1',
  blueLight: '#a0e5ff',
  green: '#009B42',
  brown: '#80310B',
  black: '#000000',
  greyDark: '#757575',
  grey: '#bebcbb',
  greyLight: '#e3e1df',
}

export const basePalette = {
  black100: '#000',
  black90: '#111',
  black80: '#444444',
  grey120: '#777',
  grey100: '#959595',
  grey80: '#ccc',
  grey60: '#dfdede',
  grey40: '#ebebeb',
  grey20: '#f6f6f6',
  grey10: '#f9f9f9',
  white: '#fff',
  error100: '#f27c00',
  error20: 'rgba(242, 124, 0, 0.05)',
}

/**
 * @deprecated
 */
export const palette: Palette = {
  primary: '#F71414',
  secondary: '#007FF1',

  blocketRed: '#F71414',
  blocketPurle: '#AE006E',
  blocketOrange: '#FF5800',
  blocketPeach: '#FB8375',
  blocketPink: '#FDADAE',
  blocketPinkLight: '#FDD4D6',
  blocketYellow: '#FFB500',
  blocketBlue: '#007FF1',
  blocketBlueLight: '#A0E5FF',
  blocketGreen: '#009B42',
  blocketBrown: '#80310B',

  blocketBlack: '#000000',
  blocketGreyDark: '#848282',
  blocketGrey: '#BEBCBB',
  blocketGreyLight: '#E3E1DF',

  blocketIconDisabled: '#DFDEDE',

  grey1: '#111',
  grey2: '#444444',
  grey3: '#959595',
  grey4: '#CCCCCC',
  grey5: '#DFDEDE',
  grey6: '#EBEBEB',
  grey7: '#F6F6F6',

  textPrimary: '#000000',
  textSecondary: '#959595',

  shadow: 'rgba(0, 0, 0, 0.2)',
  //Legacy colors
  red500: 'red',
  blue500: '#0083da',
  orange500: '#FFB500',
  grayAlpha20: 'rgba(0, 0, 0, 0.1)',
  darkGray: '#333333',
  grey20: '#F6F6F6',
  grey40: '#DDDDDD',
  grey60: '#EBEBEB',
  textAlpha: 'rgba(0, 0, 0, 0.4)',
  textAlpha50: 'rgba(0, 0, 0, 0.6)',
  white: '#FFF',
  black: '#000',
}

type Palette = {
  primary: '#F71414'
  secondary: '#007FF1'

  blocketRed: '#F71414'
  blocketPurle: '#AE006E'
  blocketOrange: '#FF5800'
  blocketPeach: '#FB8375'
  blocketPink: '#FDADAE'
  blocketPinkLight: '#FDD4D6'
  blocketYellow: '#FFB500'
  blocketBlue: '#007FF1'
  blocketBlueLight: '#A0E5FF'
  blocketGreen: '#009B42'
  blocketBrown: '#80310B'

  blocketBlack: '#000000'
  blocketGreyDark: '#848282'
  blocketGrey: '#BEBCBB'
  blocketGreyLight: '#E3E1DF'

  blocketIconDisabled: '#DFDEDE'

  grey1: '#111'
  grey2: '#444444'
  grey3: '#959595'
  grey4: '#CCCCCC'
  grey5: '#DFDEDE'
  grey6: '#EBEBEB'
  grey7: '#F6F6F6'

  textPrimary: '#000000'
  textSecondary: '#959595'

  shadow: 'rgba(0, 0, 0, 0.2)'

  //Lagacy colors
  red500: 'red'
  blue500: '#0083da'
  orange500: '#FFB500'
  grayAlpha20: 'rgba(0, 0, 0, 0.1)'
  darkGray: '#333333'

  grey20: '#F6F6F6'
  grey40: '#DDDDDD'
  grey60: '#EBEBEB'
  textAlpha: 'rgba(0, 0, 0, 0.4)'
  textAlpha50: 'rgba(0, 0, 0, 0.6)'
  white: '#FFF'
  black: '#000'
}
