import { library } from '@fortawesome/fontawesome-svg-core'
import { fal } from '@fortawesome/pro-light-svg-icons'
import {
  fas,
  faTimesCircle,
  faMinusCircle,
  faUserCircle,
  faGlobeAfrica,
  faCheckCircle,
  faChevronLeft,
  faInfoCircle,
  faBriefcase,
  faHeart,
  faStar,
  faLock,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faTimesCircle,
  faMinusCircle,
  faUserCircle,
  faCheckCircle,
  faGlobeAfrica,
  faChevronLeft,
  faInfoCircle,
  faBriefcase,
  faHeart,
  faStar,
  faLock,
  fas,
  fal,
)
