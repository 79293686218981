import { Textarea } from '@qasa/qds-ui'
import { Controller, useFormContext } from 'react-hook-form'

type ArrayTextareaFieldProps = {
  name: string
  label: string
  placeholder: string
}
/*
 * Used for fields of string array type where only the first element of array is used by the BE
 */
export function ArrayTextareaField({ name, label, placeholder }: ArrayTextareaFieldProps) {
  const { control } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <Textarea
            placeholder={placeholder}
            label={label}
            value={value ? value[0] : null}
            onChange={(e) => onChange(e.target.value ? [e.target.value] : null)}
          />
        )
      }}
    />
  )
}
