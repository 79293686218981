import { useState } from 'react'
import { useParams, useNavigate, Navigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { ApartmentForm } from '../ui-atoms/apartment-form'
import { queries } from '../data/edit-apartment'
import type { UpsertHomeTemplateInput } from '../data/graphql/types/__generated__/globalTypes'
import type {
  ApartmentForEditQuery,
  ApartmentForEditQuery_homeTemplate_uploads,
} from '../data/graphql/types/__generated__/ApartmentForEditQuery'
import { LoadingDots } from '../ui-shared/loading-dots'

type TParams = { apartmentId: string }

export function EditApartment() {
  const navigate = useNavigate()
  const params = useParams<TParams>()
  const { t } = useTranslation('commons', { keyPrefix: 'actions' })

  const [apartment, setApartment] = useState<UpsertHomeTemplateInput>({})
  const [uploads, setUploads] = useState<ApartmentForEditQuery_homeTemplate_uploads[]>([])

  const { error, loading: isLoading } = useQuery<ApartmentForEditQuery>(queries.GET_APARTMENT, {
    variables: { homeTemplateId: params.apartmentId },
    onCompleted: ({ homeTemplate }) => {
      setApartment({
        buildingId: homeTemplate.building?.id,
        type: homeTemplate.type,
        corporateHome: homeTemplate.corporateHome,
        firsthand: homeTemplate.firsthand,
        seniorHome: homeTemplate.seniorHome,
        studentHome: homeTemplate.studentHome,
        apartmentNumber: homeTemplate.apartmentNumber,
        roomCount: homeTemplate.roomCount,
        squareMeters: homeTemplate.squareMeters,
        tenantCount: homeTemplate.tenantCount,
        rent: homeTemplate.rent,
        description: homeTemplate.description,
        floor: homeTemplate.floor,
        kitchenRenovationYear: homeTemplate.kitchenRenovationYear,
        bathroomRenovationYear: homeTemplate.bathroomRenovationYear,
        shared: homeTemplate.shared,
        location: {
          postalCode: homeTemplate.location?.postalCode,
          route: homeTemplate.location?.route,
          streetNumber: homeTemplate.location?.streetNumber,
          locality: homeTemplate.location?.locality,
          longitude: homeTemplate.location?.longitude,
          latitude: homeTemplate.location?.latitude,
          country: homeTemplate.location?.country,
          countryCode: homeTemplate.location?.countryCode,
        },
        traits: homeTemplate.traits.map((trait) => trait.type),
      })

      setUploads(homeTemplate.uploads)
    },
  })

  if (error) {
    switch (error?.message) {
      case 'not_found':
        return <Navigate to="/homes/apartments" replace />
      default:
        // eslint-disable-next-line no-console
        console.log(error?.message)
    }
  }

  if (isLoading) {
    return <LoadingDots />
  }

  return (
    <ApartmentForm
      apartmentId={params.apartmentId}
      apartment={apartment}
      uploads={uploads}
      cancel={() => navigate(`/homes/apartments/${params.apartmentId}`, { replace: true })}
      title={t('edit_apartment')}
    />
  )
}
