import { Stack } from '@qasa/qds-ui'

import type { ApplicationBoardLists_home_applicationBoardCardInfo } from '../../../data/graphql/types/__generated__/ApplicationBoardLists'

import { CreateNewColumn } from './create-new-column'
import { BoardSettings } from './board-settings'

type BoardActionsProps = {
  applicationBoardCardInfo: ApplicationBoardLists_home_applicationBoardCardInfo | null | undefined
  refetch: () => void
}

export function BoardActions({ applicationBoardCardInfo, refetch }: BoardActionsProps) {
  return (
    <Stack direction="row" justifyContent="flex-end" alignItems="center" gap="4x">
      <BoardSettings applicationBoardCardInfo={applicationBoardCardInfo} />
      <CreateNewColumn />
    </Stack>
  )
}
