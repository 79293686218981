import { useState, useEffect } from 'react'
import styled from 'styled-components/macro'

import { palette, pixelAmount } from '../../styling'

type StatsBarProps = {
  value: number
  width?: string
  color?: string
}

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  height: '180px',
  width: '70px',
  marginRight: pixelAmount.xl8,
})

const StatsBar = styled.div<StatsBarProps>(({ value, color, width = '40px' }) => ({
  transition: 'height 0.15s ease-in',
  backgroundColor: color,
  alignSelf: 'flex-end',
  height: `${value}%`,
  width,
  borderRadius: '6px',
}))

type StatisticsBarProps = {
  value: number
  showSecondary?: boolean
  max: number
}

export function StatisticsBar(props: StatisticsBarProps) {
  const [barValue, setBarValue] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      // we default to 0, then set the actual value with a slight timeout to get the animation
      setBarValue(props.value)
    }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barValue])

  const calculateStatsBarValue = (value: number): number => {
    // returns the percentage of the relative max
    return (value / props.max) * 100
  }

  const calculateStatsChildBar = (value: number): number => {
    // returns 75% of the actual bar (think this is just comsetic?)
    return calculateStatsBarValue(value) * 0.75
  }

  return (
    <Wrapper>
      <StatsBar value={calculateStatsBarValue(barValue)} color={palette.blue500} />
      {props.showSecondary && (
        <StatsBar value={calculateStatsChildBar(barValue)} color={palette.grey40} width="20px" />
      )}
    </Wrapper>
  )
}
