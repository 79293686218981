import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { Stack } from '../../../ui-shared/_core/stack'
import { Typography } from '../../../ui-shared/_core/typography'

const Wrapper = styled(Stack).attrs({ direction: 'row', align: 'center' })({})
const Indicator = styled.span<{ hasSigned: boolean }>(({ theme, hasSigned }) => ({
  fontSize: 20,
  paddingRight: 8,
  '&::before': {
    content: '"●"',
  },
  color: hasSigned ? theme.colors.alerts.success : theme.colors.alerts.danger,
}))
export function StatusIndicator({ landlord, hasSigned }: { landlord?: boolean; hasSigned: boolean }) {
  const { t } = useTranslation('applicant', {
    keyPrefix: 'status_indicator',
  })
  const getText = () => {
    if (landlord) {
      if (hasSigned) {
        return t('signed_by_landlord')
      } else {
        return t('waiting_for_landlord')
      }
    } else {
      if (hasSigned) {
        return t('signed_by_tenant')
      } else {
        return t('waiting_for_tenant')
      }
    }
  }

  return (
    <Wrapper>
      <Indicator hasSigned={hasSigned} />
      <Typography variant="bodySmall">{getText()}</Typography>
    </Wrapper>
  )
}
