import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Stack } from '@qasa/qds-ui'

import { formatDate } from '../../../util-functions/date.util'

import type { MessageVariant } from './message'

const Wrapper = Stack

type MessageTimeStampProp = {
  variant: MessageVariant
}

const MessageTimeStamp = styled.div<MessageTimeStampProp>(({ theme, variant }) => {
  const justification = {
    received: 'flex-start',
    sent: 'flex-end',
  }
  return {
    ...theme.typography.body.xs,
    color: theme.colors.text.subtle,
    display: 'flex',
    justifyContent: justification[variant],
  }
})

type MessageWrapperProps = {
  children: ReactNode
  date: Date
  variant: MessageVariant
  isRead?: boolean
}

export function MessageWrapper({ children, isRead, date, variant }: MessageWrapperProps) {
  const { t } = useTranslation('applications', { keyPrefix: 'chat' })
  return (
    <Wrapper gap="1x">
      <MessageTimeStamp variant={variant}>{formatDate(date)}</MessageTimeStamp>
      {children}
      {isRead && <MessageTimeStamp variant={variant}>{t('read')}</MessageTimeStamp>}
    </Wrapper>
  )
}
