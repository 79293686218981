import { useState } from 'react'
import styled from 'styled-components/macro'
import { useMutation } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

import type {
  HomeApplicationOverview_homeApplication_tenant_homeApplicationsToMyHomes,
  HomeApplicationOverview_homeApplication_tenant_userNotes,
} from '../../../data/graphql/types/__generated__/HomeApplicationOverview'
import { Spacer } from '../../../ui-atoms/help-components'
import { Input } from '../../../ui-atoms/input'
import { InputOption } from '../../../ui-atoms/input-option'
import { palette } from '../../../styling'
import { mutations as applicationMutations } from '../../../data/applicant'

import { OverViewComment, UserComment } from './overview-comment'

const Form = styled.form({
  left: 0,
  bottom: 0,
  zIndex: 10,
  backgroundColor: palette.white,
})

type ConversationProps = {
  data: {
    homeApplicationsToMyHomes: HomeApplicationOverview_homeApplication_tenant_homeApplicationsToMyHomes[]
    userNotes: HomeApplicationOverview_homeApplication_tenant_userNotes[]
    homeApplicationId: string
  }
  isWritingAvailable: boolean
}
export function OverviewComments(props: ConversationProps) {
  const { t } = useTranslation('applications', { keyPrefix: 'overview.comments' })
  const [comment, setComment] = useState('')
  const { isWritingAvailable } = props
  const [createApplicationComment] = useMutation(applicationMutations.CREATE_APPLICATION_COMMENT, {
    refetchQueries: ['HomeApplicationOverview'],
    onCompleted: () => setComment(''),
    // eslint-disable-next-line no-console
    onError: (e) => console.log(e),
  })

  const handleSubmit = (event: React.FormEvent<EventTarget>) => {
    if (comment.trim().length === 0) {
      return
    }
    createApplicationComment({
      variables: { recordId: props.data.homeApplicationId, recordType: 'home_application', note: comment },
    })
  }

  return (
    <>
      <Spacer factor={6} />
      {props.data.userNotes.length ? (
        <>
          {props.data.userNotes.map((userNote, index) => {
            return (
              <div key={index}>
                <OverViewComment userNote={userNote} />
              </div>
            )
          })}
        </>
      ) : (
        <UserComment>{t('no_comments')}</UserComment>
      )}
      <Spacer factor={2} />
      {isWritingAvailable && (
        <Form
          onSubmit={(event: React.FormEvent<EventTarget>) => {
            event.preventDefault()
            handleSubmit(event)
          }}
        >
          <Input
            name="comments"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            type="text"
            placeholder={t('input_placeholder')}
            color={palette.blocketYellow + '10'}
            options={[
              <InputOption
                key="sticky-note"
                icon={<FontAwesomeIcon icon={['fal', 'sticky-note']} />}
                onClick={() => handleSubmit}
              />,
            ]}
          />
        </Form>
      )}
    </>
  )
}
