import { gql } from '@apollo/client'

export const home = gql`
  fragment BaseHome on Home {
    duration {
      id
      startOptimal
      startAsap
      endUfn
      endOptimal
      updatedAt
    }
    id
    qasaGuaranteeCost
    insuranceCost
    qasaGuarantee
    insurance
    landlord {
      uid
      professional
      companyName
      firstName
    }
    location {
      latitude
      locality
      longitude
      postalCode
      route
      streetNumber
      googleMapsPlaceId
      countryCode
      id
    }
    rent
    rentalType
    roomCount
    squareMeters
    bedCount
    bedroomCount
    tenantCount
    status
    title
    type
    safeRental
    apartmentNumber
    rentalType
    tenantBaseFee
    uploads {
      id
      url
      metadata {
        primary
        order
      }
    }
    pricingModel {
      insuranceCostFactor
      qasaGuaranteeCostFactor
    }
    landlordServices {
      insurance {
        availability
        cost {
          factor
          isFree
          total
        }
        isActive
      }
      qasaGuarantee {
        availability
        cost {
          isFree
          factor
          total
        }
        isActive
      }
    }
  }
`
