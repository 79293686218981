import { Stack } from '@qasa/ui'
import { styled } from '@qasa/ui/web'
import { Label, getFormFieldBaseStyles, theme } from '@qasa/qds-ui'
import type { ChangeEvent } from 'react'
import { useState, forwardRef, useCallback } from 'react'

import { TemporaryHelperText } from '../../checkbox'
import { useRadioGroupContext } from '../radio-group-context'
import { VisuallyHidden } from '../../visually-hidden'

import type { RadioCardProps, RadioIndicatorProps, RadioWrapperLabelProps } from './radio-card.types'

const RADIO_INDICATOR_SIZE = theme.sizes['4x']

const RadioCardWrapper = styled(Label)<RadioWrapperLabelProps>(
  ({ theme, isChecked = false, isDisabled, hasHelperText }) => ({
    ...getFormFieldBaseStyles(theme),
    paddingTop: theme.spacing['4x'],
    paddingBottom: theme.spacing['4x'],
    color: isDisabled ? theme.colors.text.disabled : theme.colors.text.default,
    cursor: 'pointer',
    display: 'flex',
    gap: theme.spacing['3x'],
    minHeight: 56,
    userSelect: 'none',
    alignItems: hasHelperText ? 'flex-start' : 'center',
    justifyContent: 'space-between',
    lineHeight: theme.typography.body.sm.lineHeight,
    ...(isChecked && {
      borderColor: theme.colors.border.defaultSelected,
      boxShadow: `0 0 0 1px ${theme.colors.border.defaultSelected}`,
    }),
  }),
)

const RadioIndicator = styled('div')<RadioIndicatorProps>(
  ({ theme, isChecked = false, isFocused, isDisabled }) => ({
    flexShrink: 0,
    background: theme.colors.core.white,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.colors.border.default,
    borderRadius: theme.radii.full,
    width: RADIO_INDICATOR_SIZE,
    height: RADIO_INDICATOR_SIZE,
    outlineStyle: isFocused ? 'solid' : 'none',
    outlineWidth: 2,
    outlineOffset: isFocused ? 2 : 0,
    transitionProperty: 'border, outline-offset',
    transitionDuration: '150ms',
    transitionTimingFunction: 'ease-in-out',
    ...(isDisabled && {
      opacity: 0.4,
    }),
    ...(isChecked && {
      borderWidth: 5,
      borderColor: theme.colors.border.defaultSelected,
    }),
  }),
)

export const RadioCard = forwardRef<HTMLInputElement, RadioCardProps>(
  ({ label, helperText, isDisabled = false, value, postValueChange, icon: Icon }, forwardRef) => {
    const { value: groupValue, onChange, name } = useRadioGroupContext()
    const [isFocused, setIsFocused] = useState(false)
    const isChecked = value === groupValue

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        onChange(event.target.value as string)
      }
    }
    const handleFocus = useCallback(() => setIsFocused(true), [])
    const handleBlur = useCallback(() => setIsFocused(false), [])

    const inputProps = {
      type: 'radio',
      onFocus: handleFocus,
      onBlur: handleBlur,
      onChange: handleChange,
      value,
      checked: isChecked,
      disabled: isDisabled,
      name,
    }

    const hasHelperText = Boolean(helperText)
    return (
      <RadioCardWrapper
        onClick={postValueChange}
        isChecked={isChecked}
        isDisabled={isDisabled}
        hasHelperText={hasHelperText}
      >
        <VisuallyHidden>
          <input
            ref={forwardRef}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...inputProps}
          />
        </VisuallyHidden>
        <Stack gap={'3x'} direction={'row'} alignItems={'center'}>
          {Icon && (
            <div style={{ alignSelf: hasHelperText ? 'flex-start' : 'initial' }}>
              <Icon />
            </div>
          )}
          <Stack gap={'1x'}>
            {label}
            {helperText && (
              <TemporaryHelperText size="sm" color={isDisabled ? 'disabled' : 'subtle'}>
                {helperText}
              </TemporaryHelperText>
            )}
          </Stack>
        </Stack>
        <RadioIndicator aria-hidden {...{ isChecked, isFocused, isDisabled }} />
      </RadioCardWrapper>
    )
  },
)
