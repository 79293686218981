import { useApplicantContext } from '../../../context/applicant-context'
import { resolveStatus } from '../../../util-functions/general.util'
import { OverviewHomeApplicationListing } from '../../applications/overview/overview-home-application-listing'
import buildingPlaceholder from '../../../assets/placeholders/building.png'

export function ContractPreviewHome() {
  const { applicantData } = useApplicantContext()
  const { status, home } = applicantData!

  return (
    <OverviewHomeApplicationListing
      data={{
        applicantStatus: resolveStatus(status),
        homeStreetNumber: home.location?.streetNumber,
        homeSquareMeters: home.squareMeters,
        homeRoute: home.location?.route,
        homeRoomCount: home.roomCount,
        numberOfHomes: home.numberOfHomes,
        announceId: home.id,
        homeApartmentNumber: home.apartmentNumber,
        homeImage: {
          url: home.uploads[0] ? home.uploads[0].url : buildingPlaceholder,
          alt: 'building',
        },
      }}
    />
  )
}
