import styled from '@emotion/styled'
import { Label, Stack } from '@qasa/qds-ui'

type TabProps = {
  onSelect: () => void
  label: string
  isSelected: boolean
}

type TabOption<T> = {
  label: string
  value: T
}

type TabsProps<T> = {
  options: TabOption<T>[]
  selectedOption: string
  onSelectChange: (newOption: T) => void
}

const TabWrapper = styled.div({
  cursor: 'pointer',
})

const TabLabel = styled(Label)<{ isSelected: boolean }>(({ theme, isSelected }) => ({
  height: theme.sizes['8x'],
  borderBottom: '3px solid transparent',
  fontWeight: 400,
  ...(isSelected && {
    borderBottomColor: theme.colors.border.defaultSelected,
    fontWeight: 600,
  }),
}))

function Tab({ onSelect, label, isSelected }: TabProps) {
  return (
    <TabWrapper onClick={onSelect}>
      <TabLabel isSelected={isSelected}>{label}</TabLabel>
    </TabWrapper>
  )
}

export function Tabs<T>({ options, selectedOption, onSelectChange }: TabsProps<T>) {
  return (
    <Stack direction="row" gap="4x">
      {options.map(({ label, value }, index) => {
        return (
          <Stack key={index} direction="row" gap="4x">
            <Tab label={label} isSelected={value === selectedOption} onSelect={() => onSelectChange(value)} />
          </Stack>
        )
      })}
    </Stack>
  )
}
