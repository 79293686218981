import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Input } from '../../ui-atoms/input'
import { Spacer } from '../../ui-atoms/help-components'
import { TableTopSection } from '../../ui-atoms/top-section'
import { Table } from '../../ui-atoms/table'
import { ContextMenu } from '../../ui-atoms/context-menu'
import { FilterDisplay } from '../filters/filter-display'
import { RoomCountFilter } from '../my-homes/room-count-filter'
import { SizeFilter } from '../my-homes/size-filter'
import { Filters } from '../my-homes/apartments-table/apartments-table'

import { useGetListingsData } from './use-get-listings-data'
import { constructListingsTableColumns, constructListingsTableRows } from './listings-table.utils'
import type { ListingsStatusFilters } from './listings-status-navigation'

const ROOM_COUNT_MIN_MAX = {
  min: 0,
  max: 8,
}

const SIZE_MIN_MAX = {
  min: 0,
  max: 150,
}

enum FilterTypes {
  ROOM_COUNT = 'ROOM_COUNT',
  SIZE = 'SIZE',
}

export type ListingFilterAttribute = 'minRoomCount' | 'maxRoomCount' | 'minSquareMeters' | 'maxSquareMeters'

type ListingsTableProps = {
  setTotalCount: React.Dispatch<React.SetStateAction<number | undefined>>
  listingStatus: ListingsStatusFilters
}

export function ListingsTable({ setTotalCount, listingStatus }: ListingsTableProps) {
  const [currentFilter, setCurrentFilter] = useState<FilterTypes | null>(null)
  const { t } = useTranslation(['listings', 'my_homes', 'commons'])
  const scrollElementRef = useRef<HTMLTableElement>(null)

  useEffect(() => {
    const scrollTop = scrollElementRef?.current?.scrollTop ?? 0
    if (scrollTop !== 0) {
      // reset scroll when moving between listing status tabs
      const scrollLeft = scrollElementRef?.current?.scrollLeft ?? 0
      scrollElementRef?.current?.scrollTo(scrollLeft, 0)
    }
  }, [listingStatus])

  const {
    resetFilterAttributes,
    addFilter,
    onSearch,
    onSort,
    listingsData: { isLoading, error, data },
    handleScroll,
    localArgs,
  } = useGetListingsData({ setTotalCount, listingStatus, scrollElementRef })

  const closeFilterWindow = () => setCurrentFilter(null)

  if (error) {
    // eslint-disable-next-line no-console
    console.log(error)
    return null
  }

  const shouldRoomCountFilterBeActive = Boolean(localArgs.minRoomCount) || Boolean(localArgs.maxRoomCount)
  const shouldSizeFilterBeActive = Boolean(localArgs.minSquareMeters) || Boolean(localArgs.maxSquareMeters)

  return (
    <>
      <TableTopSection>
        <div>
          <Input
            type="search"
            name="search"
            placeholder={t('listings_table.search_placeholder')}
            width="466px"
            value={localArgs.homesSearchString}
            onChange={onSearch}
          />
          <Spacer factor={1} />

          <Filters direction="row" gap={'2x'} justifyContent="flex-start">
            <FilterDisplay
              onClick={() => setCurrentFilter(FilterTypes.ROOM_COUNT)}
              reset={() => resetFilterAttributes(['minRoomCount', 'maxRoomCount'])}
              hasFilter={shouldRoomCountFilterBeActive}
              name={t('my_homes:filters.room_count_title')}
              activeFilter={`
              ${localArgs.minRoomCount ? localArgs.minRoomCount : 0}
              -
              ${localArgs.maxRoomCount ? localArgs.maxRoomCount : `${ROOM_COUNT_MIN_MAX.max}+`}
            `}
            />
            <FilterDisplay
              onClick={() => setCurrentFilter(FilterTypes.SIZE)}
              reset={() => resetFilterAttributes(['minSquareMeters', 'maxSquareMeters'])}
              hasFilter={shouldSizeFilterBeActive}
              name={t('my_homes:filters.size_filter_title', { unit: t('commons:square_meter') })}
              activeFilter={`
              ${localArgs.minSquareMeters ? localArgs.minSquareMeters : 0}
              -
              ${localArgs.maxSquareMeters ? localArgs.maxSquareMeters : `${SIZE_MIN_MAX.max}+`}
            `}
            />
          </Filters>
        </div>
      </TableTopSection>
      <Spacer factor={4} />
      <Table
        isLoading={isLoading}
        tableRef="/listings"
        columns={constructListingsTableColumns({ localArgs, onSort })}
        rows={constructListingsTableRows({ homes: data?.homes, isLoading })}
        handleOnScroll={handleScroll}
        scrollRef={scrollElementRef}
      />

      <ContextMenu isOpen={Boolean(currentFilter)} onClose={closeFilterWindow}>
        {(() => {
          switch (currentFilter) {
            case FilterTypes.SIZE:
              return (
                <SizeFilter
                  range={SIZE_MIN_MAX}
                  filter={{
                    minSquareMeters: localArgs.minSquareMeters,
                    maxSquareMeters: localArgs.maxSquareMeters,
                  }}
                  totalCount={data?.homes.totalCount}
                  isLoading={isLoading}
                  onChange={addFilter}
                  close={closeFilterWindow}
                  reset={() => {
                    closeFilterWindow()
                    resetFilterAttributes(['minSquareMeters', 'maxSquareMeters'])
                  }}
                />
              )
            case FilterTypes.ROOM_COUNT:
            default:
              return (
                <RoomCountFilter
                  range={ROOM_COUNT_MIN_MAX}
                  filter={{
                    minRoomCount: localArgs.minRoomCount,
                    maxRoomCount: localArgs.maxRoomCount,
                  }}
                  totalCount={data?.homes.totalCount}
                  isLoading={isLoading}
                  onChange={addFilter}
                  close={closeFilterWindow}
                  reset={() => {
                    closeFilterWindow()
                    resetFilterAttributes(['minRoomCount', 'maxRoomCount'])
                  }}
                />
              )
          }
        })()}
      </ContextMenu>
    </>
  )
}
