import { Stack, Heading } from '@qasa/qds-ui'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { GoBack } from '../../ui-atoms/go-back'
import { useListingContext } from '../../context/listing-context'

import { useListingActions } from './use-listing-actions'

const Wrapper = Stack
const LeftSection = Stack
const RightSection = Stack

export function TopSection() {
  const navigate = useNavigate()
  const { home, isBlockListing } = useListingContext()
  const listingActions = useListingActions()
  const { t } = useTranslation('listing')

  const { location, apartmentNumber } = home || {}
  const { route, streetNumber } = location || {}

  const routeString = `${route} ${streetNumber}`
  const heading = apartmentNumber ? routeString.concat(', ', apartmentNumber) : routeString
  const groupHeading = t('group_listing_title', { listingLocation: routeString })

  return (
    <Wrapper direction="row" justifyContent={'space-between'} alignItems="center">
      <LeftSection direction="row" gap="4x" alignItems="center">
        <GoBack onClick={() => navigate('/listings', { replace: true })} />
        {isBlockListing ? <Heading>{groupHeading}</Heading> : <Heading size="md">{heading}</Heading>}
      </LeftSection>
      <RightSection>
        <Stack direction="row" gap="2x">
          {listingActions.map((ListingAction, index) => {
            return <ListingAction key={index} />
          })}
        </Stack>
      </RightSection>
    </Wrapper>
  )
}
