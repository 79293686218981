import { useTranslation } from 'react-i18next'
import { Paragraph, Stack } from '@qasa/qds-ui'

import { HomeApplicationOrderEnum } from '../../data/graphql/types/__generated__/globalTypes'
import { MenuPicker } from '../../ui-atoms/menu-picker'
import { useLocalStorage } from '../../util-functions/use-local-storage'

type ApplicationListSortProps = {
  onSort: (newSort: HomeApplicationOrderEnum) => void
  columnKey: string
}
export function ApplicationListSort({ onSort, columnKey }: ApplicationListSortProps) {
  const [orderBy, setOrderBy] = useLocalStorage<HomeApplicationOrderEnum>(
    `applicationsColumnSort::${columnKey}`,
    HomeApplicationOrderEnum.BEST_MATCH,
  )
  const { t } = useTranslation('applications', {
    keyPrefix: 'column_sorting',
  })

  const handleSort = (newSort: HomeApplicationOrderEnum) => {
    setOrderBy(newSort)
    onSort(newSort)
  }

  const SORT_OPTIONS = [
    {
      id: '1',
      value: t('match'),
      isActive: orderBy === HomeApplicationOrderEnum.BEST_MATCH,
      orderValue: HomeApplicationOrderEnum.BEST_MATCH,
    },
    {
      id: '2',
      value: t('newest'),
      isActive: orderBy === HomeApplicationOrderEnum.NEWEST_FIRST,
      orderValue: HomeApplicationOrderEnum.NEWEST_FIRST,
    },
    {
      id: '3',
      value: t('oldest'),
      isActive: orderBy === HomeApplicationOrderEnum.OLDEST_FIRST,
      orderValue: HomeApplicationOrderEnum.OLDEST_FIRST,
    },
    {
      id: '4',
      value: t('starmarked'),
      isActive: orderBy === HomeApplicationOrderEnum.STARRED,
      orderValue: HomeApplicationOrderEnum.STARRED,
    },
    {
      id: '5',
      value: t('waiting_time'),
      isActive: orderBy === HomeApplicationOrderEnum.WAITING_TIME,
      orderValue: HomeApplicationOrderEnum.WAITING_TIME,
    },
    {
      id: '6',
      value: t('last_contacted'),
      isActive: orderBy === HomeApplicationOrderEnum.LAST_CHAT_ACTIVITY,
      orderValue: HomeApplicationOrderEnum.LAST_CHAT_ACTIVITY,
    },
  ]

  return (
    <Stack gap="3x">
      <Paragraph color="subtle">{t('title')}</Paragraph>
      <MenuPicker values={SORT_OPTIONS} onSort={handleSort} />
    </Stack>
  )
}
