import type { RefObject } from 'react'
import { useRef, useState, useEffect } from 'react'

/**
 * Hook for handling closing when clicking outside of an element
 */
export const useDetectOutsideClick = (refObj: RefObject<HTMLDivElement>, initialState: boolean) => {
  const [isActive, setIsActive] = useState(initialState)
  // Used to prevent the first click from closing the dropdown immediately
  const isOpeningClick = useRef(true)

  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      if (isOpeningClick.current) {
        isOpeningClick.current = false
        return
      }
      // If the active element exists and is clicked outside of
      if (refObj.current !== null && !refObj.current.contains(e.target as Node)) {
        setIsActive(!isActive)
      }
    }

    // If the item is active (ie open) then listen for clicks outside
    if (isActive) {
      window.addEventListener('click', onClick)
    }

    return () => {
      window.removeEventListener('click', onClick)
      isOpeningClick.current = true
    }
  }, [isActive, refObj])

  return [isActive, setIsActive] as const
}
