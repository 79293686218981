import { gql } from '@apollo/client'

export const UPSERT_MESSAGE_MACRO = gql`
  mutation UpsertMessageMacro($id: ID, $input: UpsertMessageMacroInput!) {
    upsertMessageMacro(id: $id, input: $input) {
      messageMacro {
        id
        title
        text
      }
    }
  }
`
