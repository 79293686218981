//TODO -> Make Thumbs go to the correct place when passed values on load

import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'

import { defaultStyles } from '../styling/defaults'
import { fontSize, palette } from '../styling'
import { getPixelAmount } from '../styling/pixel-amount'
import { formatNumber } from '../util-functions/string.util'
import { RangeValues } from '../ui-feature-modules/filters/range-values'

import { Spacer } from './help-components'

type DualSliderProps = {
  minName: string
  maxName: string

  range: {
    max: number
    min: number
  }
  value: {
    min?: number | null
    max?: number | null
  }
  allowOverMax: boolean
  allowBelowMin: boolean

  rangeNumberCount?: number

  stepSize?: number

  onChange: (event: DualSliderChangeEvent) => void
  onInputChange?: (value: string | number | null | undefined, type: 'min' | 'max') => void
  onDragStop?: (event: DualSliderChangeEvent) => void
}

const THUMB_SIZE = 20

export type DualSliderChangeEvent = {
  type: 'min' | 'max'
  value: number
  name: string
  isMax?: boolean
  isMin?: boolean
}

const Wrapper = styled.div({
  position: 'relative',
  userSelect: 'none',
})

const Range = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
})

const RangeNumber = styled.p({
  display: 'flex',
  justifyContent: 'center',
  width: getPixelAmount(7),
  fontSize: fontSize.textSm,
  color: palette.textSecondary,
  ':first-of-type': {
    justifyContent: 'flex-start',
    paddingLeft: 12,
  },
  ':last-of-type': {
    justifyContent: 'flex-end',
  },
})

const Slider = styled.div({
  display: 'flex',
  position: 'relative',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: getPixelAmount(4),
})

const Thumb = styled.div.attrs<{ type: 'max' | 'min'; position: number }>(({ position, type }) => ({
  style: {
    ...(type === 'min' && {
      left: 0,
      transform: `translateX(${position}px)`,
    }),
    ...(type === 'max' && {
      right: 0,
      transform: `translateX(${-position}px)`,
    }),
  },
}))<{ type: 'max' | 'min'; position: number }>({
  position: 'absolute',
  width: THUMB_SIZE,
  height: THUMB_SIZE,
  borderRadius: defaultStyles.borderRadiusRound,
  border: `3px solid ${palette.primary}`,
  backgroundColor: palette.white,
  cursor: 'ew-resize',
  '&:hover': {
    boxShadow: `0 0 5px ${palette.primary}`,
  },
})

const Trail = styled.div({
  position: 'absolute',
  height: '2px',
  backgroundColor: palette.grey40,
  width: `calc(100% - 24px)`,
  left: 12,
})

const BetweenTrail = styled.div.attrs<{ left: number; right: number }>(({ left, right }) => ({
  style: { left, right },
}))<{ left: number; right: number }>({
  position: 'absolute',
  height: '3px',
  backgroundColor: palette.primary,
})

export function DualSlider(props: DualSliderProps) {
  const wrapper = useRef<HTMLDivElement>(null)

  const [isDraggingMin, setDraggingMin] = useState(false)
  const [isDraggingMax, setDraggingMax] = useState(false)

  const [minPosition, setMinPosition] = useState<number>(0)
  const [maxPosition, setMaxPosition] = useState<number>(0)

  const [minInputValue, setMinInputValue] = useState<string>(props.range.min.toString())
  const [maxInputValue, setMaxInputValue] = useState<string>(props.range.max.toString())

  useEffect(() => {
    if (props.value.min) {
      setMinInputValue(props.value?.min?.toString())
      calculateMinPositionFromValue(props.value.min)
    }
    if (props.value.max) {
      setMaxInputValue(props.value?.max?.toString())
      calculateMaxPositionFromValue(props.value.max)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const calculateMinPositionFromValue = (minValue?: number | null) => {
    if (minValue) {
      if (minValue <= props.range.min) {
        setMinPosition(0)
        if (props.onDragStop) {
          props.onDragStop({
            type: 'min',
            name: props.minName,
            value: 0,
            isMin: true,
          })
        }
      } else if (minValue >= props.range.max) {
        setMinPosition(319)
      } else {
        const wrapperRect = (wrapper.current as HTMLDivElement).getBoundingClientRect()
        const stepFactor = (wrapperRect.width - THUMB_SIZE) / (props.range.max - props.range.min)
        setMinPosition(Math.floor(stepFactor * (minValue - props.range.min)))
      }
    } else {
      setMinPosition(0)
    }
  }

  const calculateMaxPositionFromValue = (maxValue?: number | null) => {
    if (maxValue !== null && maxValue !== undefined) {
      if (maxValue >= props.range.max) {
        setMaxPosition(0)
        if (props.onDragStop) {
          props.onDragStop({
            type: 'max',
            name: props.maxName,
            value: props.range.max,
            isMax: true,
          })
        }
      } else {
        const wrapperRect = (wrapper.current as HTMLDivElement).getBoundingClientRect()
        const stepFactor = (wrapperRect.width - THUMB_SIZE) / (props.range.max - props.range.min)
        const position = props.range.max - maxValue
        setMaxPosition(Math.ceil(stepFactor * (position < 0 ? 0 : position)))
      }
    } else {
      setMaxPosition(0)
    }
  }

  const mouseDown = (event: React.MouseEvent<HTMLElement>) => {
    const type = event.currentTarget.getAttribute('type')
    if (type === 'min') {
      setDraggingMin(true)
    } else {
      setDraggingMax(true)
    }
    event.stopPropagation()
    event.preventDefault()
  }

  const mouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isDraggingMin) {
      onMinMove(event)
    }

    if (isDraggingMax) {
      onMaxMove(event)
    }

    event.stopPropagation()
    event.preventDefault()
  }

  const onMinMove = (event: React.MouseEvent<HTMLDivElement>, triggerOnDragStop?: boolean) => {
    const wrapperRect = (wrapper.current as HTMLDivElement).getBoundingClientRect()
    let thumbPosition = event.pageX - wrapperRect.left - THUMB_SIZE / 2

    const minValue = 0
    const maxValue = wrapperRect.width - THUMB_SIZE - (maxPosition + 2)

    if (thumbPosition < minValue) {
      thumbPosition = 0
    }

    if (thumbPosition > maxValue) {
      thumbPosition = maxValue
    }

    if (thumbPosition !== minPosition || triggerOnDragStop) {
      if (thumbPosition >= minValue && thumbPosition <= maxValue) {
        setMinPosition(Math.floor(thumbPosition))

        const stepFactor = (wrapperRect.width - THUMB_SIZE) / (props.range.max - props.range.min)
        const value = props.range.min + Math.round(thumbPosition / stepFactor)

        const sliderEvent: DualSliderChangeEvent = {
          name: props.minName,
          type: 'min',
          value: value < props.range.min ? props.range.min : value,
          ...(props.allowBelowMin && {
            isMin: value <= props.range.min,
          }),
        }

        if (triggerOnDragStop && props.onDragStop) {
          props.onDragStop(sliderEvent)
        }

        if (props.onChange) {
          props.onChange(sliderEvent)
          setMinInputValue(
            (
              Math.round(value / (props.stepSize ? props.stepSize : 1)) *
              (props.stepSize ? props.stepSize : 1)
            ).toString(),
          )
        }
      }
    }
  }

  const onMaxMove = (event: React.MouseEvent<HTMLDivElement>, triggerOnDragStop?: boolean) => {
    const slider = wrapper.current as HTMLDivElement
    let thumbPosition =
      slider.offsetWidth + slider.getBoundingClientRect().left - event.pageX - THUMB_SIZE / 2

    const minValue = 1
    const maxValue = slider.offsetWidth - THUMB_SIZE - minPosition

    if (thumbPosition <= minValue) {
      thumbPosition = minValue
    }

    if (thumbPosition >= maxValue) {
      thumbPosition = maxValue
    }

    if (thumbPosition !== minPosition) {
      if (thumbPosition >= minValue && thumbPosition <= maxValue) {
        setMaxPosition(Math.ceil(thumbPosition))

        const stepFactor = (slider.offsetWidth - THUMB_SIZE) / (props.range.max - props.range.min)
        const value = props.range.max - Math.round(thumbPosition / stepFactor)

        const sliderEvent: DualSliderChangeEvent = {
          name: props.maxName,
          type: 'max',
          value: value < props.range.min ? props.range.min : value,
          ...(props.allowOverMax && {
            isMax: value >= props.range.max,
          }),
        }

        if (triggerOnDragStop && props.onDragStop) {
          props.onDragStop(sliderEvent)
        }

        if (props.onChange) {
          props.onChange(sliderEvent)
          setMaxInputValue(
            (
              Math.ceil(value / (props.stepSize ? props.stepSize : 1)) * (props.stepSize ? props.stepSize : 1)
            ).toString(),
          )
        }
      }
    }
  }

  const mouseUp = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isDraggingMin) {
      setDraggingMin(false)
      onMinMove(event, true)
    } else if (isDraggingMax) {
      setDraggingMax(false)
      onMaxMove(event, true)
    }

    event.stopPropagation()
    event.preventDefault()
  }

  const mouseLeave = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isDraggingMin) {
      setDraggingMin(false)
      onMinMove(event, true)
    } else if (isDraggingMax) {
      setDraggingMax(false)
      onMaxMove(event, true)
    }
  }

  const getRangeNumbers = (count: number) => {
    const numbers = Array(count)
      .fill('')
      .map((_, index) =>
        Math.floor(props.range.min + (index * (props.range.max - props.range.min)) / (count - 1)).toString(),
      )
    if (props.allowOverMax) {
      numbers[numbers.length - 1] = numbers[numbers.length - 1] + '+'
    }

    return numbers
  }

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: 'min' | 'max',
  ) => {
    if (e.key === 'Enter' && props.onInputChange) {
      if (type === 'min') {
        if (parseInt(minInputValue, 10) > parseInt(maxInputValue, 10)) {
          props.onInputChange(maxInputValue, 'min')
          setMinInputValue(maxInputValue)
        } else {
          props.onInputChange(minInputValue, 'min')
          setMinInputValue(minInputValue)
        }
        e.currentTarget.blur()
      } else {
        if (parseInt(maxInputValue, 10) < parseInt(minInputValue, 10)) {
          props.onInputChange(minInputValue + 1, 'max')
          setMaxInputValue(minInputValue)
        } else {
          props.onInputChange(maxInputValue, 'max')
          setMaxInputValue(maxInputValue)
        }
        e.currentTarget.blur()
      }
    }
  }

  const handleInputChange = (value: string, type: 'min' | 'max') => {
    if (type === 'min') {
      setMinInputValue(value)
    }
    if (type === 'max') {
      setMaxInputValue(value)
    }
  }

  const handleFocusChange = (hasFocus: boolean, type: 'min' | 'max') => {
    if (type === 'min') {
      if (!hasFocus && props.onInputChange) {
        if (parseInt(minInputValue, 10) > parseInt(maxInputValue, 10)) {
          props.onInputChange(maxInputValue, 'min')
          calculateMinPositionFromValue(parseInt(maxInputValue, 10) - 2)
        } else {
          props.onInputChange(minInputValue, 'min')
          calculateMinPositionFromValue(parseInt(minInputValue, 10))
        }
      }
    }

    if (type === 'max') {
      if (!hasFocus && props.onInputChange) {
        if (parseInt(maxInputValue, 10) < parseInt(minInputValue, 10)) {
          props.onInputChange(minInputValue, 'max')
          setMaxInputValue(minInputValue)
          calculateMaxPositionFromValue(parseInt(minInputValue, 10) + 2)
        } else {
          props.onInputChange(maxInputValue, 'max')
          setMaxInputValue(maxInputValue)
          calculateMaxPositionFromValue(parseInt(maxInputValue, 10))
        }
      }
    }
  }

  const { rangeNumberCount = 5 } = props

  return (
    <Wrapper ref={wrapper} onMouseUp={mouseUp} onMouseMove={mouseMove} onMouseLeave={mouseLeave}>
      <Range>
        {getRangeNumbers(rangeNumberCount).map((value) => (
          <RangeNumber key={value}>{formatNumber(value)}</RangeNumber>
        ))}
      </Range>
      <Spacer factor={0.5} />
      <Slider>
        <Trail />
        <BetweenTrail left={minPosition} right={maxPosition} />
        <Thumb type="min" position={minPosition} onMouseDown={mouseDown} />
        <Thumb type="max" position={maxPosition} onMouseDown={mouseDown} />
      </Slider>
      <Spacer factor={4} />
      {props.onInputChange && (
        <RangeValues
          min={minInputValue}
          max={maxInputValue}
          minAllowed={props.range.min}
          maxAllowed={props.range.max}
          minName="minSquareMeters"
          maxName="maxSquareMeters"
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onInputFocusChange={handleFocusChange}
        />
      )}
    </Wrapper>
  )
}
