import gql from 'graphql-tag'

const GET_APARTMENT = gql`
  query ApartmentForEditQuery($homeTemplateId: ID!) {
    homeTemplate(id: $homeTemplateId) {
      id
      apartmentNumber
      floor
      firsthand
      roomCount
      seniorHome
      squareMeters
      studentHome
      tenantCount
      kitchenRenovationYear
      bathroomRenovationYear
      corporateHome
      description
      shared
      rent
      type
      building {
        id
      }
      traits {
        id
        type
      }
      uploads {
        id
        url
        type
      }
      location {
        country
        countryCode
        latitude
        longitude
        locality
        postalCode
        route
        streetNumber
        id
      }
    }
  }
`

export const queries = {
  GET_APARTMENT,
}
