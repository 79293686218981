import { useState, useEffect } from 'react'

export function useMediaQuery(query: string) {
  const [isMatching, setMatches] = useState(false)

  useEffect(() => {
    const media = window.matchMedia(query)
    if (media.matches !== isMatching) {
      setMatches(media.matches)
    }
    const listener = () => {
      setMatches(media.matches)
    }
    media.addEventListener('change', listener)
    return () => media.removeEventListener('change', listener)
  }, [isMatching, query])

  return isMatching
}
