import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { Paragraph } from '@qasa/qds-ui'

import { fontSize, palette, pixelAmount } from '../../../styling'
import { VerticalSpacer } from '../../../ui-atoms/help-components/spacer'
import { translateApplicantStatus } from '../../../util-functions/general.util'
import { CurrentApplicantVariant } from '../../../types/applicant/applicant-enum'
import type { CurrentApplicantData } from '../../../types/applicant/applicant-types'
import { Spacer } from '../../../ui-atoms/help-components'
import { AddressWithApartmentDetails } from '../listings/listing-address-details'

import { OverviewHomeTemplates } from './overview-home-templates'

type Props = {
  variant?: 'secondary'
  showStatus?: boolean
  id?: string
  data: CurrentApplicantData
}

const isSecondary = CurrentApplicantVariant.Secondary
export function OverviewHomeApplicationListing({ variant, showStatus = false, id, data }: Props) {
  const navigate = useNavigate()
  const {
    homeStreetNumber,
    homeSquareMeters,
    homeRoomCount,
    announceId,
    homeImage,
    homeRoute,
    applicantStatus,
    homeApartmentNumber,
    homeTemplates,
    applicationHomeTemplates,
    numberOfHomes,
  } = data

  const handleViewClick = () => {
    return navigate(`../${id}`)
  }
  const handleClick = () => {
    return navigate(`../../../../listings/${announceId}`)
  }

  const homeTemplatesForRender =
    applicationHomeTemplates && applicationHomeTemplates.length > 1 ? applicationHomeTemplates : homeTemplates

  if (!homeRoute) return null
  return (
    <>
      <RequestWrapper onClick={handleClick}>
        <LeftPortion>
          {variant !== isSecondary && (
            <>
              <Image src={homeImage.url} alt={homeImage.alt} />
              <VerticalSpacer factor={2} />
            </>
          )}
          <AddressWithApartmentDetails
            streetNumber={homeStreetNumber}
            squareMeters={homeSquareMeters}
            roomCount={homeRoomCount}
            route={homeRoute}
            apartmentNumber={homeApartmentNumber}
            numberOfHomes={numberOfHomes}
          />
        </LeftPortion>
        <RightPortion>
          {variant === isSecondary && (
            <>
              <ViewLink onClick={handleViewClick}>{'view'}</ViewLink>
              <VerticalSpacer factor={1} />
            </>
          )}

          <VerticalSpacer factor={1} />
          {showStatus && (
            <ApplicantStatus size="sm" color="subtle">
              {translateApplicantStatus(applicantStatus)}
            </ApplicantStatus>
          )}
        </RightPortion>
      </RequestWrapper>
      {homeTemplatesForRender && homeTemplatesForRender.length > 1 && (
        <>
          <Spacer factor={4} />
          <OverviewHomeTemplates homeTemplates={homeTemplatesForRender} />
        </>
      )}
    </>
  )
}

const RequestWrapper = styled.div({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
})

const LeftPortion = styled.div({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-end',
  justifySelf: 'stretch',
})

const RightPortion = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
})

const Image = styled.img({
  width: '6em',
  objectFit: 'cover',
  aspectRatio: '12 / 9',
  borderRadius: pixelAmount.sm,
})

const ViewLink = styled.div({
  fontWeight: 400,
  fontSize: fontSize.textSm,
  borderRadius: '4px',
  color: palette.blue500,
  padding: `${pixelAmount.md} ${pixelAmount.lg}`,
  '&:hover': {
    backgroundColor: palette.grayAlpha20,
    cursor: 'pointer',
  },
})

const ApplicantStatus = styled(Paragraph)(({ theme }) => ({
  borderRadius: theme.radii.xs,
  border: '1px solid',
  borderColor: theme.colors.border.default,
  padding: theme.spacing['2x'],
}))
