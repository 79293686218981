import React, { useRef } from 'react'
import type { ApolloError } from '@apollo/client'
import { useMutation, useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { LoadingDots, Paragraph, Stack } from '@qasa/qds-ui'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'

import {
  queries as applicantChatQueries,
  mutations as applicantChatMutations,
} from '../../../data/applicant-chat'
import { scrollToBottom } from '../../../util-functions/event.util'
import type { MarkConversationMutation } from '../../../data/graphql/types/__generated__/MarkConversationMutation'
import type { HomeApplicationChatConversation } from '../../../data/graphql/types/__generated__/HomeApplicationChatConversation'
import { HomeApplicationStatusEnum } from '../../../data/graphql/types/__generated__/globalTypes'

import { Messages } from './messages'
import { MessageInputSection } from './message-input-section'

const ChatWrapper = styled(Stack)<Pick<ChatProps, 'isPreview'>>(({ theme, isPreview }) => ({
  flexGrow: 1,
  overflow: 'none',
  height: isPreview ? '35vh' : '100%',
  border: '1px solid',
  borderColor: theme.colors.border.default,
}))
const MessageBox = styled.div(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  padding: theme.spacing['4x'],
}))

type ChatProps = {
  isPreview?: boolean
}
export function Chat({ isPreview }: ChatProps) {
  const [{ applicant: homeApplicationId }] = useQueryParams({ applicant: withDefault(StringParam, null) })
  const { GET_APPLICANT_CHAT_CONVERSATION } = applicantChatQueries
  const chatRef = useRef<HTMLDivElement>(null)

  const [setConversationRead] = useMutation<MarkConversationMutation>(
    applicantChatMutations.SET_CONVERSATION_READ,
    {
      refetchQueries: [
        'UnreadMessagesCountQuery',
        'UnreadConversationsCountQuery',
        'HomeApplicationsByBoardList',
      ],
    },
  )

  const {
    loading: isLoadingConversation,
    error: errorConversation,
    data: conversationData,
  } = useQuery<HomeApplicationChatConversation>(GET_APPLICANT_CHAT_CONVERSATION, {
    variables: {
      homeApplicationId,
      messagesLast: null,
      messagesBefore: null,
    },
    pollInterval: 5000,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setTimeout(() => scrollToBottom(chatRef), 500)
      setConversationRead({
        variables: {
          markConversationInput: {
            conversationId: data.homeApplication.conversation?.id,
            read: true,
          },
        },
      })
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error)
    },
  })

  const isChatInactive = conversationData?.homeApplication.status === HomeApplicationStatusEnum.pending

  if (isLoadingConversation) {
    return (
      <ChatWrapper isPreview={isPreview} alignItems="center" justifyContent="center">
        <LoadingDots />
      </ChatWrapper>
    )
  }

  if (errorConversation) {
    return (
      <ChatWrapper isPreview={isPreview} alignItems="center" justifyContent="center">
        <Paragraph>{(errorConversation as ApolloError).message}</Paragraph>
      </ChatWrapper>
    )
  }

  return (
    <ChatWrapper isPreview={isPreview}>
      <MessageBox ref={chatRef}>
        <Messages
          homeApplication={conversationData?.homeApplication}
          isInitiator={conversationData?.homeApplication.conversation?.initiator}
        />
      </MessageBox>
      <MessageInputSection
        conversationId={conversationData?.homeApplication.conversation?.id}
        isChatInactive={isChatInactive}
        homeApplicationId={homeApplicationId}
      />
    </ChatWrapper>
  )
}
