import type { ReactNode } from 'react'
import styled from 'styled-components/macro'

import { HorizontalDivider } from '../../../ui-atoms/help-components'
import { Icon } from '../../../ui-shared/_core/icon'
import { Stack } from '../../../ui-shared/_core/stack'
import { Typography } from '../../../ui-shared/_core/typography'
import { formatDateYYYYMMDD } from '../../../util-functions/date.util'

const Wrapper = styled(Stack)(({ theme }) => ({
  padding: 16,
  borderRadius: theme.borderRadiuses.default,
  border: '1px solid',
  borderColor: theme.colors.dividers.default,
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridAutoFlow: 'row',
  gap: 16,
}))

const TopSection = styled(Stack)({
  gridColumn: '1 / 3',
  paddingRight: 16,
})
const StyledDivider = styled(HorizontalDivider)({
  gridColumn: '1 / 3',
  height: 2,
})

const VerifiedIcon = styled(Icon).attrs({ icon: 'shieldCheck' })(({ theme }) => ({
  color: theme.colors.alerts.success,
  fontSize: 20,
}))

type InfoItemType = {
  title: string
  content: string
}
function InfoItem({ title, content }: InfoItemType) {
  return (
    <Stack>
      <Typography variant="bodySmall" colorVariant="secondary">
        {title}
      </Typography>
      <Typography variant="bodySmall">{content}</Typography>
    </Stack>
  )
}

type DocumentDataCardProps = {
  infoItems: InfoItemType[]
  verifiedAt?: string
  label: string
  mainInfoItem?: ReactNode
}
export function DocumentDataCard({ infoItems, label, verifiedAt, mainInfoItem }: DocumentDataCardProps) {
  return (
    <Wrapper>
      <TopSection direction="row" align="center" justify="space-between">
        <Stack direction="row" gap={1}>
          <VerifiedIcon />
          <div>
            <Typography variant="bodySmall">{label}</Typography>
            {verifiedAt && (
              <Typography variant="bodySmall" colorVariant="secondary">
                {formatDateYYYYMMDD(new Date(verifiedAt))}
              </Typography>
            )}
          </div>
        </Stack>
        {mainInfoItem}
      </TopSection>
      <StyledDivider />
      {infoItems.map(({ title, content }) => (
        <InfoItem key={title} title={title} content={content} />
      ))}
    </Wrapper>
  )
}
