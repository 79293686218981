import { Stack } from '@qasa/qds-ui'

import { ConversationMessageTypeEnum } from '../../../data/graphql/types/__generated__/globalTypes'
import type { HomeApplicationChatConversation_homeApplication } from '../../../data/graphql/types/__generated__/HomeApplicationChatConversation'
import { useUserContext } from '../../../context/user-context'

import { SystemMessage } from './system-message'
import { Message } from './message'

type MessagesProps = {
  homeApplication?: HomeApplicationChatConversation_homeApplication
  isInitiator?: boolean
}

export function Messages({ homeApplication, isInitiator }: MessagesProps) {
  const { authBody } = useUserContext()
  const currentUserId = authBody?.uid ?? ''
  const { conversation, offering } = homeApplication || {}
  return (
    <Stack gap="6x">
      {conversation?.messages.edges?.map((message) => {
        if (
          message.node.messageType === ConversationMessageTypeEnum.text ||
          message.node.messageType === ConversationMessageTypeEnum.image
        ) {
          const isMessageSent = message.node.user.uid === currentUserId
          const isMyLastMessageSeenByOther = Boolean(
            conversation?.lastSeenMessageIds &&
              conversation?.lastSeenMessageIds.filter(({ uid }) => uid !== currentUserId)[0]?.messageId ===
                message.node.id,
          )
          return (
            <Message
              key={message.node.id}
              message={message}
              variant={isMessageSent ? 'sent' : 'received'}
              isMessageRead={isMessageSent && isMyLastMessageSeenByOther}
            />
          )
        } else {
          return (
            <SystemMessage
              key={message.node.id}
              message={message.node}
              offeringStatus={offering?.offeringStatus}
              isInitiator={isInitiator}
            />
          )
        }
      })}
    </Stack>
  )
}
