import { Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { ArrayTextareaField } from './fields/array-textarea-field'
import { SmallRepairsRadioGroup } from './fields/small-repairs-radio-group'
import { DoesLandlordHaveKeysRadioGroup } from './fields/does-landlord-have-keys-radio-group'

export function LeaseDetailsSection() {
  const { t } = useTranslation('edit_listing')

  return (
    <Stack gap="8x">
      <DoesLandlordHaveKeysRadioGroup />
      <SmallRepairsRadioGroup />
      <ArrayTextareaField
        name="contractTemplateAttributes.rentExcludes"
        placeholder={t('lease_details.included_in_rent_placeholder')}
        label={t('lease_details.not_included_in_rent_label')}
      />
      <ArrayTextareaField
        name="contractTemplateAttributes.rentIncludes"
        placeholder={t('lease_details.included_in_rent_placeholder')}
        label={t('lease_details.included_in_rent_label')}
      />
      <ArrayTextareaField
        name="contractTemplateAttributes.canUse"
        placeholder={t('lease_details.can_use_placeholder')}
        label={t('lease_details.can_use_label')}
      />
    </Stack>
  )
}
