import i18next from 'i18next'

import type { DocumentIncomeVerification_incomeVerification } from '../../../data/graphql/types/__generated__/DocumentIncomeVerification'
import { formatMoney } from '../../../util-functions/general.util'

export const formatIncomeData = (incomeData?: DocumentIncomeVerification_incomeVerification | null) => {
  if (!incomeData) {
    return null
  }
  const { salaryMean, pensionMean, benefitsMean, otherIncomesMean } = incomeData || {}

  return [
    {
      title: i18next.t('applications:documents.income_data.salary'),
      content: salaryMean ? formatMoney(salaryMean) : i18next.t('commons:placeholders.missing_information'),
    },
    {
      title: i18next.t('applications:documents.income_data.benefit'),
      content: benefitsMean
        ? formatMoney(benefitsMean)
        : i18next.t('commons:placeholders.missing_information'),
    },
    {
      title: i18next.t('applications:documents.income_data.pension'),
      content: pensionMean ? formatMoney(pensionMean) : i18next.t('commons:placeholders.missing_information'),
    },
    {
      title: i18next.t('applications:documents.income_data.other'),
      content: otherIncomesMean
        ? formatMoney(otherIncomesMean)
        : i18next.t('commons:placeholders.missing_information'),
    },
  ]
}
