import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import type { HomeApplicationOverview_homeApplication } from '../../data/graphql/types/__generated__/HomeApplicationOverview'
import { VerticalSpacer } from '../../ui-atoms/help-components/spacer'
import { getPixelAmount } from '../../styling/pixel-amount'
import { HorizontalDivider } from '../../ui-atoms/help-components'
import { palette } from '../../styling'
import { useApplicantContext, ApplicantTab } from '../../context/applicant-context'
import { useUserContext } from '../../context/user-context'

const StickyContainer = styled.div({
  position: 'sticky',
  top: 0,
  backgroundColor: 'white',
  zIndex: 15,
})
const LinkList = styled.ul({
  padding: 0,
  margin: 0,
  fontWeight: 500,
  display: 'flex',
  listStyle: 'none',
})

const Li = styled.li<{ isActive?: boolean }>(({ isActive }) => ({
  transform: 'translateY(1px)',
  fontSize: '16px',
  fontWeight: 600,
  padding: 0,
  margin: 0,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  height: getPixelAmount(8),
  textDecoration: 'none',
  position: 'relative',
  color: 'black',

  ...(isActive && {
    color: palette.primary,
    borderBottom: `3px solid ${palette.primary}`,
  }),
}))

type NestedNavigatorProps = {
  data: HomeApplicationOverview_homeApplication
}

export function ApplicantNestedNavigator({ data }: NestedNavigatorProps) {
  const { currentTab } = useApplicantContext()
  const { authBody } = useUserContext()
  const isProAgent = authBody?.isProAgent

  const { t } = useTranslation('applications', {
    keyPrefix: 'applicant_tabs',
  })

  const { openApplicant } = useApplicantContext()

  return (
    <StickyContainer>
      <LinkList>
        <Li
          onClick={() => {
            openApplicant(ApplicantTab.Overview)
          }}
          isActive={currentTab === ApplicantTab.Overview}
        >
          {t('overview')}
        </Li>
        <VerticalSpacer factor={2} />
        <Li
          onClick={() => {
            openApplicant(ApplicantTab.Document)
          }}
          isActive={currentTab === ApplicantTab.Document}
        >
          {t('tasks')}
        </Li>
        <VerticalSpacer factor={2} />
        <Li
          onClick={() => {
            openApplicant(ApplicantTab.Conversation)
          }}
          isActive={currentTab === ApplicantTab.Conversation}
        >
          {t('messages')}
        </Li>

        <VerticalSpacer factor={2} />
        <Li
          onClick={() => {
            openApplicant(ApplicantTab.Comments)
          }}
          isActive={currentTab === ApplicantTab.Comments}
        >
          {t('internal_comments')}
        </Li>
        <VerticalSpacer factor={2} />
        {isProAgent && (
          <Li
            onClick={() => {
              openApplicant(ApplicantTab.Contract)
            }}
            isActive={currentTab === ApplicantTab.Contract}
          >
            {t('contract')}
          </Li>
        )}
      </LinkList>
      <HorizontalDivider />
    </StickyContainer>
  )
}
