import type {
  ColorsPaletteType,
  BorderRadiusesType,
  BorderColorsType,
  FontWeightType,
  FontSizeType,
  BoxShadowType,
  MaxWidthType,
  SpaceType,
} from './base.types'

const palette: ColorsPaletteType = {
  black100: '#000',
  black90: '#111',
  black80: '#444444',
  grey120: '#777',
  grey100: '#959595',
  grey80: '#ccc',
  grey60: '#dfdede',
  grey40: '#ebebeb',
  grey20: '#f6f6f6',
  grey10: '#f9f9f9',
  white: '#fff',
  error100: '#f27c00',
  error20: 'rgba(242, 124, 0, 0.05)',
}

const borderRadiuses: BorderRadiusesType = {
  extraSmall: 2,
  small: 4,
  default: 8,
  large: 16,
}

const borderColors: BorderColorsType = {
  border100: palette.grey80,
  border60: palette.grey60,
  border20: palette.grey20,
}

const boxShadows: BoxShadowType = {
  big: '0px 2px 8px rgba(0, 0, 0, 0.16)',
  small: '0px 0px 5px #0000001a ',
}

const fontWeights: FontWeightType = {
  normal: 400,
  semibold: 600,
  bold: 700,
}

const maxWidths: MaxWidthType = {
  mobile: 414,
  desktop: 700,
  desktopWider: 980,
  desktopWidest: 1440,
}

const fontSizes: FontSizeType = {
  11: 11,
  12: 12,
  14: 14,
  16: 16,
  18: 18,
  20: 20,
  22: 22,
  24: 24,
  26: 26,
  32: 32,
  40: 40,
  default: 16,
}

const spaces: SpaceType = {
  extraSmall: 4,
  small: 12,
  medium: 24,
  large: 48,
  extraLarge: 64,
  extraExtraLarge: 96,
}

export type BaseTheme = {
  /**
   * @deprecated - Use @emotion/styled and the QDS theme instead
   */
  palette: ColorsPaletteType
  /**
   * @deprecated - Use @emotion/styled and the QDS theme instead
   */
  borderRadiuses: BorderRadiusesType
  /**
   * @deprecated - Use @emotion/styled and the QDS theme instead
   */
  fontWeights: FontWeightType
  /**
   * @deprecated - Use @emotion/styled and the QDS theme instead
   */
  fontSizes: FontSizeType
  /**
   * @deprecated - Use @emotion/styled and the QDS theme instead
   */
  borderColors: BorderColorsType
  /**
   * @deprecated - Use @emotion/styled and the QDS theme instead
   */
  boxShadows: BoxShadowType
  /**
   * @deprecated - Use @emotion/styled and the QDS theme instead
   */
  spaces: SpaceType
  /**
   * @deprecated - Use @emotion/styled and the QDS theme instead
   */
  maxWidths: MaxWidthType
}

export const baseTheme: BaseTheme = {
  palette,
  borderRadiuses,
  borderColors,
  boxShadows,
  fontWeights,
  fontSizes,
  spaces,
  maxWidths,
}
