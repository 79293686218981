import { useMutation, useQuery } from '@apollo/client'
import { Dialog } from '@qasa/app/src/components/dialog'
import { Button, Paragraph, Spacer, Stack, Textarea, TextField } from '@qasa/qds-ui'
import { useState, type Dispatch } from 'react'
import type { MessageMacro, MessageMacroEdge } from '@qasa/graphql'

import { queries } from '../../data/applicant-chat'

import { UPSERT_MESSAGE_MACRO } from './upsert-macro.gql'

type AddMacroDialogProps = {
  isOpen: boolean
  setIsOpen: Dispatch<boolean>
}

export function AddMacroDialog({ isOpen, setIsOpen }: AddMacroDialogProps) {
  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')
  const [upsertMessageMacro, { loading: isLoading }] = useMutation(UPSERT_MESSAGE_MACRO, {
    refetchQueries: [{ query: queries.GET_CHAT_MACROS }],
  })

  const handleUpsertMessageMacro = async () => {
    const { data } = await upsertMessageMacro({
      variables: {
        input: {
          title,
          text: message,
        },
      },
    })
    if (data?.upsertMessageMacro?.messageMacro?.id) {
      setIsOpen(false)
    }
  }

  const isMissingTitleOrMessage = !title || !message
  return (
    <Dialog isOpen={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Body>
        <Dialog.Header>
          <Dialog.Title>{'Message macro'}</Dialog.Title>
        </Dialog.Header>
        <TextField value={title} onChange={(e) => setTitle(e.target.value)} label={'Title'} />
        <Spacer size={'6x'} />
        <Textarea label={'Message'} value={message} onChange={(e) => setMessage(e.target.value)} />
      </Dialog.Body>
      <Dialog.Footer>
        <Button isDisabled={isMissingTitleOrMessage} isLoading={isLoading} onClick={handleUpsertMessageMacro}>
          {'Add macro'}
        </Button>
      </Dialog.Footer>
    </Dialog>
  )
}
