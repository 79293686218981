import styled from 'styled-components/macro'

import type { ICONS } from '../../../ui-shared/_core/icon'
import { Icon } from '../../../ui-shared/_core/icon'
import { Typography } from '../../../ui-shared/_core/typography'

const InputRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
})

const StyledIcon = styled(Icon)({
  marginRight: 8,
  marginLeft: 2,
  fontSize: 18,
})

export function ContractFormInputHeader({ label, icon }: { label: string; icon?: keyof typeof ICONS }) {
  return (
    <InputRow>
      {icon && <StyledIcon icon={icon} />}
      <Typography variant={'subhead2'}>{label}</Typography>
    </InputRow>
  )
}
