import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { Popover } from '@qasa/app/web'

import { DatePicker } from '../../ui-atoms/date-picker'
import { Radio } from '../../ui-atoms/radio'
import { formatDate, formatDateYYYYMMDD } from '../../util-functions/date.util'
import type { UpdateHomeInput } from '../../data/graphql/types/__generated__/globalTypes'

import { InputHeader, InputRow } from './input-grid-styles'

const StyledPopoverContent = styled(Popover.Content)({
  '&&': {
    width: 'fit-content',
  },
})

export function ApplicationDeadline() {
  const { control, setValue, getValues, watch } = useFormContext<UpdateHomeInput>()

  const { t } = useTranslation(['listing', 'commons'])
  const [isApplicationDeadlineOpen, setApplicationDeadlineOpen] = useState(false)

  const hasDeadline = Boolean(watch('applicationDeadline'))

  const handleOpenDatePicker = (name?: string, event?: React.MouseEvent<HTMLDivElement>) => {
    if (event) {
      if (!hasDeadline) {
        setValue('applicationDeadline', new Date().toISOString())
      }
      setApplicationDeadlineOpen(true)
    }
  }

  return (
    <InputRow isRowsFirst>
      <InputHeader isFullWidth size="2xs">
        {t('duration_information.application_deadline')}
      </InputHeader>
      <Radio
        title={t('duration_information.no_deadline')}
        name="noDeadline"
        isChecked={!hasDeadline}
        onClick={() => {
          setValue('applicationDeadline', null)
        }}
      />
      <Controller
        name="applicationDeadline"
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <Popover
            isOpen={isApplicationDeadlineOpen}
            onOpenChange={() => setApplicationDeadlineOpen(!isApplicationDeadlineOpen)}
            modal={true}
          >
            <Popover.Trigger>
              <button>
                <Radio
                  title={
                    hasDeadline
                      ? formatDate(new Date(getValues('applicationDeadline')!))
                      : t('commons:choose_date')
                  }
                  name={name}
                  isChecked={hasDeadline}
                  onClick={handleOpenDatePicker}
                  icon={<FontAwesomeIcon icon={['fal', 'calendar-alt']} />}
                />
              </button>
            </Popover.Trigger>
            <StyledPopoverContent>
              <Popover.Body>
                <DatePicker
                  name="applicationDeadline"
                  onChange={(date) => {
                    setApplicationDeadlineOpen(false)
                    onChange(formatDateYYYYMMDD(new Date(date.date)))
                  }}
                  shouldProhibitPastDate
                  activeDate={new Date(value!)}
                />
              </Popover.Body>
            </StyledPopoverContent>
          </Popover>
        )}
      />
    </InputRow>
  )
}
