import styled from '@emotion/styled'
import { forwardRef } from 'react'
import { theme } from '@qasa/qds-ui'
import { CheckIcon, Stack, Label, Paragraph } from '@qasa/ui'

import { VisuallyHidden } from '../visually-hidden'

import { useCheckbox } from './checkbox.logic'
import type { CheckboxIndicatorProps, CheckboxPlacement, CheckboxProps, CheckboxSize } from './checkbox.types'

const CHECKBOX_INDICATOR_SIZES: Record<CheckboxSize, number | string> = {
  sm: theme.sizes['4x'],
  md: theme.sizes['5x'],
}

const Wrapper = styled(Label)<{ placement: CheckboxPlacement; isDisabled: boolean }>(
  ({ placement, isDisabled }) => ({
    cursor: 'pointer',
    userSelect: 'none',
    position: 'relative',
    display: 'flex',
    gap: 12,
    alignItems: 'flex-start',
    flexDirection: placement === 'end' ? 'row-reverse' : 'row',
    justifyContent: placement === 'end' ? 'space-between' : 'flex-start',
    color: isDisabled ? theme.colors.text.disabled : theme.colors.text.default,
  }),
)

const CheckMarkContainer = styled.div<CheckboxIndicatorProps>(
  ({ isChecked, isDisabled, size, isInputFocused }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: CHECKBOX_INDICATOR_SIZES[size],
    height: CHECKBOX_INDICATOR_SIZES[size],
    flexShrink: 0,
    border: '2px solid',
    borderColor: isChecked ? theme.colors.border.defaultSelected : theme.colors.border.default,
    borderRadius: theme.radii['2xs'],
    background: isChecked ? theme.colors.border.defaultSelected : theme.colors.core.white,

    outlineStyle: isInputFocused ? 'solid' : 'none',
    outlineWidth: 2,
    outlineOffset: isInputFocused ? 2 : 0,
    transition: 'outline-offset 120ms ease-in-out',
    ...(isDisabled && {
      opacity: 0.4,
    }),

    // NOTE: used to properly align the checkbox indicator to the label
    alignSelf: 'flex-start',

    /**
     * Make tap area larget without affecting
     * its actual DOM size
     */
    ':before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: 32,
      height: 32,
      transform: 'translate(-50%, -50%)',
    },
  }),
)

// NOTE: this is a replacement for the internal HelperText component from QDS which is not exported
export const TemporaryHelperText = styled(Paragraph)({
  '&[data-disabled]': {
    opacity: 0.4,
  },
})
/**
 * @deprecated Use checkbox from qds instead
 * */
export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    { label, helperText, placement = 'start', size = 'md', disabled: isDisabled = false, ...rest },
    forwardRef,
  ) => {
    const { isChecked, handleChange, isFocused, inputProps } = useCheckbox({ ...rest })

    return (
      <Wrapper isDisabled={isDisabled} placement={placement}>
        <VisuallyHidden>
          <input
            ref={forwardRef}
            onChange={(event) => handleChange(event.target.checked)}
            checked={isChecked}
            disabled={isDisabled}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...inputProps}
          />
        </VisuallyHidden>
        <CheckMarkContainer aria-hidden {...{ isChecked, size, isDisabled, isInputFocused: isFocused }}>
          {isChecked && <CheckIcon size={16} color={'onBrandSecondary'} />}
        </CheckMarkContainer>
        <Stack gap={'1x'}>
          {label}
          {helperText && (
            <TemporaryHelperText size="sm" color={isDisabled ? 'disabled' : 'subtle'}>
              {helperText}
            </TemporaryHelperText>
          )}
        </Stack>
      </Wrapper>
    )
  },
)
